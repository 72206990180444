import { styled } from "@mui/material";

interface StyledProps {
    degrees: number;
}

export const RotateDiv = styled("div")`
    transform: rotate(${(props: StyledProps) => props.degrees}deg);
    margin-left: auto;
    display: inline-block;
`;
