import { useElasticIndicesInContext } from "@ignite-analytics/elastic-fields";
import {
    DataSource,
    Filter,
    FilterContextProvider,
    compareFilter,
    compareFilters,
    useCompanyFavouriteFilters,
    useCreateFilterCombination,
    useDefaultFilterCombinationId,
    useDeleteFilterCombination,
    useFilterCombinations,
    useFilterStateAction,
    useFilters,
    usePersonalFavouriteFilters,
    useUpdateFilterCombination,
    useUpsertCompanyFavouriteFilters,
    useUpsertDefaultFilterCombination,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { useConsistentArrayIdentity, useDebounce } from "@ignite-analytics/general-tools";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
    getCachedGlobalFilterIndex,
    getDataSourcesInWindow,
    getFiltersInWindow,
    setCachedGlobalFilterIndex,
    setFiltersInWindow,
} from "./helpers";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { useElasticFieldsWithFilterFieldMappings } from "@/hoc/withFilterContext";

interface Props {
    children: React.ReactNode;
}
interface ProviderProps {
    setRelevantDataSources: (dataSources: string[] | undefined) => void;
    children: React.ReactNode;
}

const GlobalFilterStateContext = React.createContext<{
    dispatchGlobalFilterAction?: ReturnType<typeof useFilterStateAction>;
    globalFilters?: Filter[];
    elasticIndices?: string[];
    setRelevantDataSources: (dataSources: string[] | undefined) => void;
} | null>(null);

const GlobalFilterStateProvider: React.FC<ProviderProps> = ({ children, setRelevantDataSources }) => {
    const dispatchGlobalFilterAction = useFilterStateAction();
    const globalFilters = useFilters();
    const _elasticIndices = useElasticIndicesInContext();
    const elasticIndexNames = _elasticIndices ? _elasticIndices.map(({ name }) => name) : undefined;

    const initialFiltersBeenSet = useRef(false);
    const initialized = useRef(false);

    const initialFilters: Filter[] | undefined = useMemo(() => {
        const initialFiltersString = getFiltersInWindow();
        if (initialFiltersString === undefined) return undefined;
        const initialFiltersParsed: Filter[] = JSON.parse(initialFiltersString);
        return initialFiltersParsed.length ? initialFiltersParsed : undefined;
    }, []);

    useEffect(() => {
        if (initialFiltersBeenSet.current || !initialFilters?.length || !dispatchGlobalFilterAction) return;
        dispatchGlobalFilterAction({ type: "ADD_FILTERS", filters: initialFilters });
        initialFiltersBeenSet.current = true;
    }, [dispatchGlobalFilterAction, initialFilters]);

    const debouncedSetDataSourcesInLocalStorage = useDebounce(
        useCallback((dataSources: string[] | undefined) => {
            setFiltersInWindow(undefined, dataSources);
        }, []),
        200
    );

    const handleRelevantDataSourcesChange = useCallback(
        (dataSources: string[] | undefined) => {
            debouncedSetDataSourcesInLocalStorage(dataSources);
            setRelevantDataSources(dataSources);
        },
        [debouncedSetDataSourcesInLocalStorage, setRelevantDataSources]
    );

    // Functionalty for storing global filters in local storage while changing microapps or between sessions
    const consistentIdentityGlobalFilters = useConsistentArrayIdentity<Filter>(globalFilters, compareFilter);
    useEffect(
        function storeGlobalFiltersInLocalStorage() {
            if (consistentIdentityGlobalFilters === undefined) {
                return;
            }
            // No need to store filters if we're just initializing the context
            if (
                !initialized.current &&
                (!initialFilters || compareFilters(consistentIdentityGlobalFilters, initialFilters))
            ) {
                initialized.current = true;
                return;
            }
            // The context is not initialized yet
            if (!initialized.current) {
                return;
            }
            setFiltersInWindow(consistentIdentityGlobalFilters, undefined);
        },
        [consistentIdentityGlobalFilters, initialFilters]
    );

    const contextValue = useMemo(
        () => ({
            dispatchGlobalFilterAction,
            globalFilters,
            elasticIndices: elasticIndexNames,
            setRelevantDataSources: handleRelevantDataSourcesChange,
        }),
        [dispatchGlobalFilterAction, globalFilters, elasticIndexNames, handleRelevantDataSourcesChange]
    );

    return <GlobalFilterStateContext.Provider value={contextValue}>{children}</GlobalFilterStateContext.Provider>;
};

const GlobalFilterContextProvider: React.FC<Props> = ({ children }) => {
    const [relevantDataSources, setRelevantDataSources] = useState<string[] | undefined>(getDataSourcesInWindow);

    const allElasticIndices = useAllElasticIndices();
    const elasticIndexName = getCachedGlobalFilterIndex();
    const elasticIndex = allElasticIndices?.find((index) => index.name === elasticIndexName);
    const allElasticIndicesExcludingGlobalFilterIndex: DataSource[] | undefined = allElasticIndices?.filter(
        (index) => index.name !== elasticIndexName
    );

    const indexes: readonly DataSource[] = [
        ...(elasticIndex ? [elasticIndex] : []),
        ...(allElasticIndicesExcludingGlobalFilterIndex || []),
    ];

    const relevantIndexes = indexes.filter((index) => {
        if (!relevantDataSources) return false;
        return relevantDataSources?.includes(index.name);
    });
    const createFilterCombination = useCreateFilterCombination(
        "not-used",
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );
    const updateFilterCombination = useUpdateFilterCombination(
        "not-used",
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );
    const deleteFilterCombination = useDeleteFilterCombination(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const upsertDefaultFilterCombination = useUpsertDefaultFilterCombination(
        "not-used",
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );
    const useDefaultFilterCombinationIdForContext = () => {
        const defaultFilterCombinationId = useDefaultFilterCombinationId(
            process.env.REACT_APP_GRAPHQL_ROUTER_URL as string,
            "not-used"
        );
        return defaultFilterCombinationId;
    };
    const useFilterCombinationsForRelevantIndices = (relevantIndexNames?: string[]) => {
        const filterCombinations = useFilterCombinations(
            process.env.REACT_APP_GRAPHQL_ROUTER_URL as string,
            relevantIndexNames
        );
        return filterCombinations;
    };

    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters("not-used", process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        "not-used",
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );
    const upsertCompanyFavouriteFilters = useUpsertCompanyFavouriteFilters(
        "not-used",
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    React.useEffect(
        function cacheDefaultElasticIndex() {
            if (elasticIndexName) {
                setCachedGlobalFilterIndex(elasticIndexName);
            }
        },
        [elasticIndexName]
    );

    return (
        <FilterContextProvider
            dataSources={relevantIndexes}
            useDataFields={useElasticFieldsWithFilterFieldMappings}
            debugName="Global filters"
            inherit={false}
            // Filter combination hooks
            useFilterCombinations={useFilterCombinationsForRelevantIndices}
            createFilterCombination={createFilterCombination}
            updateFilterCombination={updateFilterCombination}
            deleteFilterCombination={deleteFilterCombination}
            useDefaultFilterCombinationId={useDefaultFilterCombinationIdForContext}
            saveDefaultFilterCombinationId={upsertDefaultFilterCombination}
            // Favourite filter hooks
            allDataSources={indexes}
            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
            upsertCompanyFavouriteFilters={upsertCompanyFavouriteFilters}
        >
            <GlobalFilterStateProvider setRelevantDataSources={setRelevantDataSources}>
                {children}
            </GlobalFilterStateProvider>
        </FilterContextProvider>
    );
};

export const useGlobalFilterAction = () => React.useContext(GlobalFilterStateContext)?.dispatchGlobalFilterAction;

export const useGlobalFilterIndices = () => React.useContext(GlobalFilterStateContext)?.elasticIndices;

export const useGlobalFilters = () => React.useContext(GlobalFilterStateContext)?.globalFilters;

export const useSetRelevantDataSourcesState = () => {
    const context = React.useContext(GlobalFilterStateContext);
    if (!context) throw new Error("useSetRelevantDataSourcesState must be used within a GlobalFilterStateProvider");
    return context.setRelevantDataSources;
};

export default GlobalFilterContextProvider;
