import { ElasticIndex, useElasticIndexInContext } from "@ignite-analytics/elastic-fields";
import { usePreviousValue, useVisible } from "@ignite-analytics/general-tools";
import { CONTRACTS_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { Trash as Delete, Drag as DragIndicatorIcon } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { getFilterIndexFromWidget as getElasticIndexFromWidget } from "./helpers";
import messages from "./messages";
import { StyledCardHeader, WidgetCard } from "./style";
import WidgetHeader from "./WidgetHeader";
import { getCorrectWidth } from "./WidgetHeader/helpers";

import WidgetErrorBoundary from "@/components/ErrorBoundries/WidgetErrorBoundary";
import { OverflowTooltip } from "@/components/OverflowTooltip";
import PermissionContainer from "@/components/PermissionContainer";
import DeleteModal from "@/components/Widgets/Components/DeleteModal";
import FilterCardFooter from "@/components/Widgets/Components/FilterCardFooter";
import { Widget, WidgetPreHOCProps, WidgetProps } from "@/components/Widgets/interfaces";
import { useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { fm } from "@/contexts/IntlContext";
import { useModelGuard } from "@/entities/modelGuards";
import withFilterContext, { useElasticFieldsWithFilterFieldMappings } from "@/hoc/withFilterContext";
import { useElasticIndexInContextByName } from "@/hooks/useElasticIndexInContextByName";
import { useIndexWithGlobalType } from "@/hooks/useElasticIndexWithType";

const TEST_ID_PREFIX = "hoc-widgetWrapper-";

/**
 * HOC wrapper for Widgets
 * @param WrappedComponent The wrapped Widget component
 * @param fallbackElasticIndex Index fallback for deprecated widgets
 * @param noFilters If true, the widget will not be within a filter context
 */

export const widgetWrapper = <W extends Widget, D extends object>(
    WrappedComponent: React.ComponentType<WidgetProps<W, D>>,
    fallbackElasticIndex?: typeof CONTRACTS_GT | typeof TRANSACTIONS_GT,
    noFilters?: boolean
) => {
    type Props = WidgetPreHOCProps<W>;

    const WidgetWrapper: React.FC<Props & { safeElasticIndex: ElasticIndex | undefined }> = ({
        safeElasticIndex,
        ...props
    }) => {
        const { widget: canonicalWidget, index, hideButtons } = props;
        const widgetRef = React.useRef<HTMLDivElement>(null);
        const { visible, setElement } = useVisible();

        const navigate = useNavigate();
        const location = useLocation();

        const [activeWidget, setActiveWidget] = useState<W>(canonicalWidget);
        const [widgetHover, setWidgetHover] = useState<boolean>(false);

        /**
         * The debounced fetch ensures only one request under drill down and filtering
         */
        const prevCanonicalWidget = usePreviousValue(canonicalWidget);
        useEffect(
            function handleWidgetChange() {
                if (canonicalWidget !== prevCanonicalWidget) setActiveWidget(canonicalWidget);
                if (canonicalWidget.isNew) {
                    /** Each row in the grid system is 75px high */
                    const defaultWidgetHeight = 4 * 75;
                    const scrollTarget = (canonicalWidget.yPosition ?? 0) * 75 + defaultWidgetHeight;
                    window.scrollTo(scrollTarget, 0);
                }
            },
            [canonicalWidget, prevCanonicalWidget]
        );

        useEffect(
            function newWidgetScrollIntoView() {
                if (canonicalWidget.isNew && widgetRef.current) {
                    widgetRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            },
            [widgetRef, canonicalWidget]
        );

        useEffect(
            function removeScrollToWidgetIdWhenLoaded() {
                if (
                    location.state &&
                    typeof location.state === "object" &&
                    "scrollToWidgetId" in location.state &&
                    activeWidget?.id === location.state?.scrollToWidgetId
                ) {
                    navigate(location.pathname, {
                        replace: true,
                        state: { ...location.state, scrollToWidgetId: undefined },
                    });
                }
            },
            [activeWidget?.id, location, navigate]
        );

        const widgetProps: WidgetProps<W, D> = {
            ...props,
            data: null,
            activeWidget,
            elasticIndex: safeElasticIndex?.name,
            updateActiveWidget: setActiveWidget,
            widgetRef,
        };

        return (
            <WidgetCard
                ref={(el) => {
                    setElement(el);
                    el?.addEventListener("mouseover", () => setWidgetHover(true));
                    el?.addEventListener("mouseleave", () => setWidgetHover(false));
                }}
                data-testid={`${TEST_ID_PREFIX}widgetCard-${canonicalWidget.id}`}
                data-testtype={canonicalWidget.type}
            >
                <WidgetHeader
                    widget={canonicalWidget}
                    index={index}
                    widgetProps={widgetProps}
                    elasticIndex={safeElasticIndex?.name}
                    hideButtons={hideButtons}
                    widgetHover={widgetHover}
                />
                <Stack
                    ref={widgetRef}
                    sx={{ paddingY: 1, paddingX: 2, height: "90%" }}
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                >
                    <WidgetErrorBoundary widget={activeWidget}>
                        <WrappedComponent {...widgetProps} />
                    </WidgetErrorBoundary>
                </Stack>
                {!noFilters && visible && (
                    <>
                        <Divider variant="middle" />
                        <Box py={0.5} px={1} maxWidth="95%">
                            <FilterCardFooter index={index} analysisOptions={activeWidget} />
                        </Box>
                    </>
                )}
            </WidgetCard>
        );
    };

    const WidgetWrapperWithoutFilter: React.FC<Props> = (props) => (
        <WidgetWrapper {...props} safeElasticIndex={undefined} />
    );

    const WidgetWrapperWithFilter: React.FC<Props> = (props) => {
        const elasticIndex = useElasticIndexInContext();
        return <WidgetWrapper {...props} safeElasticIndex={elasticIndex} />;
    };

    if (noFilters) {
        return WidgetWrapperWithoutFilter;
    }

    const RenderFallbackForInvalidWidgets: React.FC<Props> = ({ widget, onDelete }) => {
        const ref = useRef<HTMLDivElement>(null);
        const [openDeleteModal, setOpenDeleteModal] = useState(false);
        const { dashboard, editing } = useDashboardContextOrThrow();
        const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
            service: "dashboards",
        });

        const handleDeleteWidget = () => {
            track("Delete widget", {
                placement: "Widget header",
                type: widget.type,
                title: widget.title,
                oldDMS: true,
            });
            onDelete();
            setOpenDeleteModal(false);
        };

        return (
            <>
                <WidgetCard variant="outlined">
                    <div ref={ref}>
                        <StyledCardHeader className={editing ? "widget-drag-handle" : undefined}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ width: "100%" }}
                            >
                                <Stack direction="row" alignItems="center" sx={{ width: "90%" }}>
                                    {editing ? <DragIndicatorIcon fontSize="small" /> : null}
                                    <Stack maxWidth={getCorrectWidth(ref.current?.offsetWidth, false)}>
                                        <OverflowTooltip title={widget.title} placement="top">
                                            <Typography variant="subtitle1" fontWeight={800} noWrap>
                                                {widget.title}
                                            </Typography>
                                        </OverflowTooltip>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <PermissionContainer
                                        requiredPermissionTypes={["change"]}
                                        equivalentUserPermission={{
                                            namespace: "dashboards",
                                            relation: { object: "general", relation: "write" },
                                        }}
                                        guard={dashboard && dashboard.guard}
                                        modelGuard={modelGuard}
                                    >
                                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                            <IconButton size="small" onClick={() => setOpenDeleteModal(true)}>
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </Stack>
                                    </PermissionContainer>
                                </Stack>
                            </Stack>
                        </StyledCardHeader>
                    </div>
                    <Stack
                        minWidth="80%"
                        minHeight="80%"
                        justifyContent="center"
                        alignContent="center"
                        padding="m"
                        textAlign="center"
                    >
                        <Typography variant="subtitle2">{fm(messages.unavailableWidgetSource)}</Typography>
                        <Typography variant="caption">{fm(messages.contactSupportIfError)}</Typography>
                    </Stack>
                </WidgetCard>
                <DeleteModal
                    open={openDeleteModal}
                    onDelete={handleDeleteWidget}
                    onClose={() => setOpenDeleteModal(false)}
                />
            </>
        );
    };

    return withFilterContext(
        WidgetWrapperWithFilter,
        {
            inherit: true,
            useDataFields: useElasticFieldsWithFilterFieldMappings,
            useDataSource: (props) => {
                const widgetIndexName = getElasticIndexFromWidget(props.widget) ?? fallbackElasticIndex;
                const widgetDataSource = useElasticIndexInContextByName(widgetIndexName);

                // Used by deprecated widgets
                const globalTypeIndex = useIndexWithGlobalType(
                    fallbackElasticIndex === TRANSACTIONS_GT ? fallbackElasticIndex : ""
                );
                return globalTypeIndex || widgetDataSource;
            },
            debugName: WrappedComponent.displayName ?? "Unknown widget",
        },
        undefined,
        RenderFallbackForInvalidWidgets
    );
};
