import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

import messages from "./messages";

import { AnalysisOptionProps } from "@/components/AnalysisOptions/Fields";
import { DISPLAY_COMPARISION } from "@/components/Widgets/constants";
import { staticFormatMessage } from "@/contexts/IntlContext";

export const displayComparison = ({ activeOptions, onChange }: AnalysisOptionProps) => (
    <FormControl fullWidth>
        <InputLabel id="display-comparison-select-label">{staticFormatMessage(messages.selectComparison)}</InputLabel>
        <Select
            onChange={(e) => onChange && onChange(e.target.value)}
            label={staticFormatMessage(messages.selectComparison)}
            placeholder={staticFormatMessage(messages.selectComparisonPH)}
            defaultValue={activeOptions[DISPLAY_COMPARISION]}
        >
            <MenuItem key="true" value="true">
                {staticFormatMessage(messages.selectComparisonYes)}
            </MenuItem>
            <MenuItem key="false" value="false">
                {staticFormatMessage(messages.selectComparisonNo)}
            </MenuItem>
        </Select>
    </FormControl>
);
