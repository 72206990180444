import React from "react";

export const EmptyDashboardSvg: React.FC<{ size: string }> = ({ size }) => {
    return (
        <svg
            width={size ?? "48"}
            height={size ?? "48"}
            viewBox="0 0 178 177"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.95 15.2417C32.95 15.2417 33.3335 15.8415 34.248 16.7118"
                stroke="#999999"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M37.434 19.0914C42.4785 22.0955 52.4151 25.2176 70.3167 21.6333C99.8167 15.7333 109.158 35.8917 109.158 35.8917C109.158 35.8917 120.958 52.6083 107.683 69.8167C99.2168 80.7907 96.1538 88.9622 95.0377 93.4462"
                stroke="#999999"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M94.6296 95.3981C94.4083 96.6813 94.4083 97.35 94.4083 97.35"
                stroke="#999999"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path d="M153.408 83.5833H112.6V150.942H153.408V83.5833Z" fill="#FF983B" />
            <path d="M153.408 83.5833H112.6L125.383 66.8667H166.192L153.408 83.5833Z" fill="#E68934" />
            <path d="M112.6 83.5833H24.1V150.942H112.6V83.5833Z" fill="#FFB261" />
            <path d="M24.1934 83.5833H65.0017L52.2184 66.8667H11.4101L24.1934 83.5833Z" fill="#FFB261" />
            <path d="M112.806 87.1971L100.515 104.405H24.1V87.1872L112.6 83.5833L112.806 87.1971Z" fill="#E6A057" />
            <path d="M100.515 100.792H11.5232L23.8148 83.5833H112.806L100.515 100.792Z" fill="#FFC990" />
            <path
                d="M88.0167 176.017C126.847 176.017 158.325 174.806 158.325 173.312C158.325 171.819 126.847 170.608 88.0167 170.608C49.1864 170.608 17.7083 171.819 17.7083 173.312C17.7083 174.806 49.1864 176.017 88.0167 176.017Z"
                fill="#CCCCCC"
            />
            <path
                d="M30.1766 5.54346C30.7578 3.48416 30.2671 1.54334 29.0807 1.2085C27.8942 0.873663 26.4613 2.27161 25.8801 4.33091C25.299 6.39021 25.7896 8.33104 26.9761 8.66588C28.1625 9.00071 29.5955 7.60276 30.1766 5.54346Z"
                fill="#CCCCCC"
            />
            <path
                d="M31.7168 13.6378C32.5445 12.2883 31.3164 10.0297 28.9738 8.59304C26.6313 7.1564 24.0614 7.08577 23.2337 8.43528C22.4061 9.78479 23.6342 12.0434 25.9767 13.48C28.3193 14.9167 30.8892 14.9873 31.7168 13.6378Z"
                fill="#808080"
            />
            <path
                d="M32.979 7.69792C34.2807 5.99968 34.5427 4.01501 33.5643 3.26505C32.5859 2.51508 30.7375 3.28382 29.4358 4.98207C28.1341 6.68031 27.872 8.66498 28.8504 9.41494C29.8289 10.1649 31.6773 9.39617 32.979 7.69792Z"
                fill="#999999"
            />
            <path
                d="M79.6583 72.7667C80.7445 72.7667 81.625 71.8862 81.625 70.8C81.625 69.7138 80.7445 68.8333 79.6583 68.8333C78.5722 68.8333 77.6917 69.7138 77.6917 70.8C77.6917 71.8862 78.5722 72.7667 79.6583 72.7667Z"
                fill="#CCCCCC"
            />
            <path
                d="M65.6458 60.475C66.3247 60.475 66.875 59.9247 66.875 59.2458C66.875 58.567 66.3247 58.0167 65.6458 58.0167C64.967 58.0167 64.4167 58.567 64.4167 59.2458C64.4167 59.9247 64.967 60.475 65.6458 60.475Z"
                fill="#CCCCCC"
            />
            <path
                d="M77.7556 41.0788C78.2634 41.0788 78.675 40.6671 78.675 40.1593C78.675 39.6516 78.2634 39.2399 77.7556 39.2399C77.2478 39.2399 76.8362 39.6516 76.8362 40.1593C76.8362 40.6671 77.2478 41.0788 77.7556 41.0788Z"
                fill="#CCCCCC"
            />
            <path
                d="M78.675 55.3666C78.675 55.3666 87.0333 51.625 88.0167 58.5083C89.0688 65.8735 79.9042 64.5165 79.9042 64.5165"
                stroke="#CCCCCC"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M59.6623 51.3743C59.6623 51.3743 62.1353 45.9069 65.6065 48.8569C69.3235 52.0134 64.2397 55.3273 64.2397 55.3273"
                stroke="#CCCCCC"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M79.8501 33.5907C79.8501 33.5907 83.5228 33.9643 82.4805 36.5653C81.3644 39.3481 78.439 37.0323 78.439 37.0323"
                stroke="#CCCCCC"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
