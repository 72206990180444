import { isDateField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { Filter, STATIC_DATE_TYPE } from "@ignite-analytics/filters";
import { TRANSACTION_DATE_GT } from "@ignite-analytics/global-types";
import { PivotResponse } from "@ignite-analytics/pivot-ts";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

import { ClientSupplier, SimpleClientSupplier, useSupplierFilter } from "@/hoc/PivotAnalysis";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { formatEpochTimestampToDateString } from "@/lib/dateHelpers";

interface FunctionProps {
    supplier?: ClientSupplier | SimpleClientSupplier;
}
export const useInitialFilters = ({ supplier }: FunctionProps): Filter[] | Error => {
    const { formatMessage: fm } = useIntl();
    const transactionIndex = useTransactionsIndex()?.name;
    const supplierFilter = useSupplierFilter(supplier, transactionIndex);

    const elasticFields = useElasticFields(transactionIndex);
    const dateField = elasticFields?.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT);
    return useMemo(() => {
        if (!dateField) {
            return new Error(fm(messages.spendDateFieldNotFound));
        }
        const maxRelativeDate =
            dateField && isDateField(dateField) && dateField.maxDateForRelativeFilters?.value
                ? formatEpochTimestampToDateString(dateField.maxDateForRelativeFilters.value)
                : undefined;

        const maxValue = maxRelativeDate;
        const minValue = isDateField(dateField) ? formatEpochTimestampToDateString(dateField.min.value) : undefined;
        const maxDate = new Date(maxValue || "");
        const maxYear = maxDate.getFullYear() - (maxDate.getDate() === 31 && maxDate.getMonth() === 11 ? 0 : 1);
        const thisYear = new Date().getFullYear();
        let startYear = maxYear < thisYear ? maxYear : thisYear - 1;

        if (new Date().getMonth() < 4 && thisYear - maxYear === 1) {
            startYear -= 1;
        }

        const minYear = minValue && new Date(minValue).getFullYear();
        if (minYear && startYear < minYear) {
            startYear = minYear;
        }
        let filters: Filter[] = [
            {
                filterType: STATIC_DATE_TYPE,
                fieldId: dateField.field,
                type: "date",
                field: dateField.field,
                start: `${startYear}-01-01`,
                end: `${startYear}-12-31`,
            },
        ];
        if (!transactionIndex) return new Error(fm(messages.couldntFindTransactionsIndex).toString());
        if (supplierFilter) {
            filters = [...filters, supplierFilter];
        }
        return filters;
    }, [dateField, fm, supplierFilter, transactionIndex]);
};
// This could be replaced by a filter on sum of spend whenever that is supported
export const matrixDataHandler = (data: PivotResponse): PivotResponse => {
    let invalidIndices: number[] = [];
    const row = data.rows?.[0];
    if (row && "values" in row) {
        // Remove values where there is no SOW
        const validValues = row.values.filter(([, sow], i) => {
            if (sow === null || typeof sow !== "number" || sow <= 0) {
                invalidIndices = [...invalidIndices, i];
                return false;
            }
            return true;
        });
        // Convert decimal values to percentages
        const convertedValues = validValues.map((values) =>
            values.map((value) => (typeof value === "number" ? value * 100 : value))
        );
        const filteredColumns = data.columns.filter((_, i) => !invalidIndices.includes(i));
        return { rows: [{ ...row, values: convertedValues }], columns: filteredColumns };
    }
    return data;
};
