import { isApolloError } from "@apollo/client";
import { ExplicitState, isBackendError } from "@ignite-analytics/api-client";
import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter, getFilterValueDescription } from "@ignite-analytics/filters";
import { SUPPLIER_GT, SUPPLIER_NAME_GT, TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import { defaultConfig as defaultChartConfig, defaultConfig } from "@ignite-analytics/pivot-charts";
import { FieldValueItem, ValueAggregationItem } from "@ignite-analytics/pivot-ts";
import { orderBy } from "lodash-es";
import { IntlShape } from "react-intl";

import messages from "./messages";

import {
    ANALYSIS_NEW,
    CATEGORY_LEVEL,
    CHART_VIEW,
    CUSTOM_PERIOD,
    DISPLAY_COMPARISION,
    INTERVAL,
    LARGEST_FIELD_NEW,
    MIN_DIFF,
    MIN_SPEND_LTM,
    PERCENTAGE_LIMIT,
    QUERY_SIZE,
    SELECTED_FIELD,
    SHARED_DEFAULT_CONFIG,
    SPEND_LIMIT,
    STATE_FIELD,
    SUPPLIER_COUNT,
    SUPPLIER_NUMBER,
    TABLE_VIEW,
    TEXT_ALIGNMENT,
    TIME_PERIOD,
    VIEW_MODE,
} from "@/components/Widgets/constants";
import { AnalysisWidget, LargestFieldWidget, Options, Widget } from "@/components/Widgets/interfaces";
import { fm, staticFormatMessage } from "@/contexts/IntlContext";
import { Labelled } from "@/lib/labelled";
import globalMessages from "@/lib/messages/globalMessages";

const getInitialValueAgg = (elasticIndex: string, elasticFields: ElasticField[]): ValueAggregationItem => {
    switch (elasticIndex) {
        case "transactions":
            return {
                field: "transaction_value",
                type: "float",
                aggregation: "sum",
                label: "Transaction value",
                visible: true,
            };
        case "mintec_master":
            return { field: "commodity_value", type: "float", aggregation: "avg", visible: true };
        case "currency_master":
            return { field: "currency_value", type: "float", aggregation: "avg", visible: true };
    }
    const globalValueTypes = [TRANSACTION_VALUE_GT];
    const relevantField: ElasticField | undefined = elasticFields.find(
        (f) => f.globalTypeKey && globalValueTypes.includes(f.globalTypeKey)
    );
    if (relevantField) {
        return {
            field: relevantField.fieldId,
            type: relevantField.type,
            label: relevantField.label,
            aggregation: "sum",
            visible: true,
        };
    }
    return {
        field: "_id",
        type: "integer",
        aggregation: "value_count",
        visible: true,
    };
};

const getInitialValueConfig = (elasticIndex: string, elasticFields: ElasticField[]): FieldValueItem => ({
    ...getInitialValueAgg(elasticIndex, elasticFields),
    filters: [],
});

export const getDefaultAnalysisWidget = (
    elasticIndex: string,
    elasticFields: ElasticField[] = []
): Omit<AnalysisWidget, "customDashboard"> => ({
    ...SHARED_DEFAULT_CONFIG,
    type: ANALYSIS_NEW,
    title: fm(messages.newAnalysis).toString(),
    [VIEW_MODE]: CHART_VIEW,
    modelName: "analysiswidget",
    chartConfiguration: defaultConfig,
    rowSplitItems: [],
    columnSplitItems: [],
    valueConfigurations: [getInitialValueConfig(elasticIndex, elasticFields)],
    elasticIndex,
});

const getValueDescription = (value: unknown) => {
    return typeof value === "boolean" ? staticFormatMessage(globalMessages[value ? "yes" : "no"]) : String(value);
};

const getFilterFooterTuple =
    (intl: IntlShape, elasticFields: ElasticField[]) =>
    (filter: Labelled<Filter>): [string, string] => {
        return [filter.label, getFilterValueDescription(filter, intl, elasticFields)];
    };

/**
 * This type is a union of `Options`-keys that shouldn't be shown in the FilterCardFooter,
 * because they are not relevant.
 */
type InvisibleOptions =
    | "query"
    | "chartConfig"
    | "type"
    | typeof VIEW_MODE
    | typeof DISPLAY_COMPARISION
    | typeof SELECTED_FIELD
    | typeof PERCENTAGE_LIMIT
    | typeof STATE_FIELD
    | typeof TEXT_ALIGNMENT;

/**
 * @returns a mapping from `Options`-keys to formatted translated messages. Does not include the hidden
 * options that are specified in the `InvisibleOptions` type.
 */
const getOptionsMapping = (): Record<Exclude<keyof Options, InvisibleOptions>, string> => ({
    [CUSTOM_PERIOD]: staticFormatMessage(messages.customPeriod),
    [QUERY_SIZE]: staticFormatMessage(messages.querySize),
    [MIN_SPEND_LTM]: staticFormatMessage(messages.minSpendLtm),
    [SUPPLIER_NUMBER]: staticFormatMessage(messages.supplierNumber),
    [CATEGORY_LEVEL]: staticFormatMessage(messages.categoryLevel),
    [TIME_PERIOD]: staticFormatMessage(messages.timePeriod),
    [SUPPLIER_COUNT]: staticFormatMessage(messages.supplierNumber),
    [SPEND_LIMIT]: staticFormatMessage(messages.minSpendLtm),
    [MIN_DIFF]: staticFormatMessage(messages.filterMinDifference),
    [INTERVAL]: staticFormatMessage(messages.interval),
});

export const getFilterFooterTuples = (
    intl: IntlShape,
    elasticFields: ElasticField[],
    filters: Labelled<Filter>[] = [],
    options: Options = {}
): [string, string][] => {
    const optionsMapping = getOptionsMapping();
    return orderBy(
        [
            ...filters.map<[string, string]>(getFilterFooterTuple(intl, elasticFields)),
            ...Object.keys(options || {})
                .filter((key): key is keyof typeof optionsMapping => key in optionsMapping)
                .map<[string, string]>((key) => [optionsMapping[key], getValueDescription(options[key])]),
        ],
        (a) => a[0]
    );
};

export const getFilterFooterHTML = (
    intl: IntlShape,
    elasticFields: ElasticField[],
    filters: Labelled<Filter>[] = [],
    options: Options = {}
) =>
    getFilterFooterTuples(intl, elasticFields, filters, options)
        .map(([title, value]) => `<strong>${title}:</strong> ${value}`)
        .join(", ");

export const getDefaultFieldDistrib = (
    transactionIndex: string,
    transactionElasticFields: ElasticField[]
): Omit<AnalysisWidget, "customDashboard"> => {
    const supplierRelationField = transactionElasticFields.find((field) => field.globalTypeKey === SUPPLIER_GT);
    const supplierNameField = transactionElasticFields.find((field) => field.globalTypeKey === SUPPLIER_NAME_GT);
    const supplierField = supplierRelationField?.field ?? supplierNameField?.field ?? "supplier_name";
    const supplierFieldType = supplierRelationField?.type ?? supplierNameField?.type ?? "text";
    return {
        ...getDefaultAnalysisWidget(transactionIndex, transactionElasticFields),
        columnSplitItems: [
            { type: supplierFieldType, field: supplierField, sortOrder: "desc", sortAggIndex: 0, size: 4 },
        ],
    };
};

export const getDefaultLargestFieldWidget = (
    transactionIndex: string,
    transactionElasticFields: ElasticField[]
): Omit<LargestFieldWidget, "customDashboard"> => {
    const supplierRelationField = transactionElasticFields.find((field) => field.globalTypeKey === SUPPLIER_GT);
    const supplierNameField = transactionElasticFields.find((field) => field.globalTypeKey === SUPPLIER_NAME_GT);
    const supplierField = supplierRelationField?.field ?? supplierNameField?.field ?? "supplier_name";
    const supplierFieldType = supplierRelationField?.type ?? supplierNameField?.type ?? "text";
    return {
        ...getDefaultAnalysisWidget(transactionIndex, transactionElasticFields),
        percentageLimit: 0.3,
        viewState: TABLE_VIEW,
        filters: [],
        columnSplitItems: [
            {
                field: supplierField,
                type: supplierFieldType,
                size: 4,
                excludeOthers: false,
                sortOrder: "desc",
                sortAggIndex: 0,
            },
        ],
        chartConfiguration: defaultChartConfig,
        type: LARGEST_FIELD_NEW,
        modelName: "largestfieldwidget",
        period: "year",
    };
};

export const widgetToExportIndex = (widget: Widget): number => {
    /**
     * Helper to make widgets export line-by-line
        |     |  ->  |     |  ->  |     |  ->
          0.0          0.4          0.8
        |     |  ->  |     |  ->  |     |
          1.0          1.4          1.8
    */
    const y = widget.yPosition ?? 0;
    const x = widget.xPosition ?? 0;
    const ColumnsInRow = 12;
    return y + x / ColumnsInRow;
};

const getErrorResponse = (error: Error) => {
    if (!error) return;
    if (isBackendError(error)) {
        return Object.values(error.response.data).flat();
    }
    if (isApolloError(error)) {
        if (error.networkError && "statusCode" in error.networkError) {
            if (error.networkError.statusCode === 504) {
                return [staticFormatMessage(messages.timeoutError)];
            }
        }
        return [error.message];
    }
    return [String(error)];
};

export const getErrorsFromPromise = (dataState: ExplicitState<unknown>) => {
    const err = "error" in dataState && (dataState.error as Error);
    const userHasViewAccess = !(isBackendError(err) && err?.response?.status === 403);
    const errors = err ? getErrorResponse(err) : [];

    const benchmarkError = errors && errors.some((error) => error?.includes("benchmarking"));
    const permissionDeniedMessage = benchmarkError && errors ? errors[0] : fm(messages.viewPermissionDenied);

    return { permissionDeniedMessage, userHasViewAccess, errors };
};
