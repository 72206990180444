import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import React from "react";
import { useBlockLayout, useResizeColumns, useTable } from "react-table";

import { RatingTableData, TableColumn } from "./DataTableCell/interfaces";

import DataTableCell from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/DataTableCell";
import { getDefaultColumn } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/DataTableCell/helpers";

const ID_PREFIX = "ratingsDataTable-";

type Props = {
    data: RatingTableData;
    pageSize: number;
    offset: number;
    total: number;
    onRef: (el: HTMLElement | null) => void;
    onPaginate?: (offset: number) => void; // Returns new page
    onPageSizeChange?: (size: number) => void; // Changes the page size
};

const PotentialAndRiskTable: React.FC<Props> = ({
    data,
    pageSize,
    offset,
    total,
    onRef,
    onPaginate,
    onPageSizeChange,
}) => {
    const columns: TableColumn[] = React.useMemo(() => data.columns, [data.columns]);
    const tableRows: RatingTableData["rows"] = React.useMemo(() => data.rows, [data.rows]);

    const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
        {
            columns,
            data: tableRows,
            defaultColumn: getDefaultColumn(0),
        },
        useBlockLayout,
        useResizeColumns
    );
    return (
        <TableContainer>
            <Table stickyHeader {...getTableProps()} ref={onRef}>
                <TableHead>
                    <TableRow>
                        {data.columns.map((column) => (
                            <TableCell key={`${ID_PREFIX}header-row-${column.accessor}`}>
                                <b>{column.header?.toString()}</b>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.slice(offset * pageSize, offset * pageSize + pageSize).map((row) => {
                        prepareRow(row);
                        return (
                            <TableRow key={`${ID_PREFIX}row-${row.id}`}>
                                {row.cells.map((cell, i) => {
                                    return (
                                        <TableCell key={`${ID_PREFIX}row${row.id}-col${i}`}>
                                            <DataTableCell cell={cell} row={row} />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {onPaginate && onPageSizeChange && total && pageSize && offset !== undefined ? (
                <TablePagination
                    component="div"
                    count={total}
                    page={offset}
                    onPageChange={(_event, page) => onPaginate(page)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(_event) => {
                        onPageSizeChange(parseInt(_event?.target?.value, 10));
                        onPaginate(0);
                    }}
                />
            ) : null}
        </TableContainer>
    );
};

export default PotentialAndRiskTable;
