import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteWidgetPermissionErrorMessage: {
        id: "dashboardManager.customDashboardPage.Dashboard.deleteWidgetPermissionError",
        defaultMessage: "You do not have permission to delete this analysis",
    },
    createWidgetPermissionErrorMessage: {
        id: "dashboardManager.customDashboardPage.Dashboard.createWidgetPermissionErrorMessage",
        defaultMessage: "You do not have permission to create this analysis",
    },
    updateWidgetPermissionErrorMessage: {
        id: "dashboardManager.customDashboardPage.Dashboard.updateWidgetPermissionErrorMessage",
        defaultMessage: "You do not have permission to update this analysis",
    },
    createdNewWidgetInformUserMessage: {
        id: "dashboardManager.customDashboardPage.Dashboard.createdNewWidgetInformUserMessage",
        defaultMessage: "Created new analysis",
    },
});

export default messages;
