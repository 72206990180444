import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import { useDashboardContextOrThrow } from "../../DashboardContext";
import { WidgetActionType } from "../../DashboardContext/helpers";
import { WidgetLoader } from "../WidgetLoader";

import DashboardWidget from "./DashboardWidget";
import { buildNewLayout, findChangedWidgets, generateLayout, onWidgetDrag, widgetToId } from "./helpers";
import messages from "./messages";
import * as Services from "./services";
import { DashboardWrapper, WidgetColumn } from "./style";

import { Widget } from "@/components/Widgets/interfaces";
import {
    BULK_UPDATE,
    CREATE_ONE,
    DELETE_ONE,
    SET_INITIAL,
    UPDATE_ONE,
} from "@/containers/CustomDashboardPage/DashboardContext/constants";
import { useEventContext } from "@/contexts/EventContext";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { DASHBOARD_WIDGET_TEST_ID, DASHBOARD_WRAPPER_TEST_ID } from "@/tests/constants";

const ReactGridLayout = WidthProvider(Responsive);

interface Props {
    scrollParentRef: React.RefObject<HTMLElement>;
}

const Dashboard: React.FC<Props> = ({ scrollParentRef }) => {
    const eventDispatch = useEventContext();
    const { editing, showWidgetMenu, widgets, widgetDispatch, layouts, setLayouts, dashboard } =
        useDashboardContextOrThrow();
    const { exporting } = useExportDashboardContextOrThrow();
    const [, setEditingState] = useState([editing, showWidgetMenu]);
    const [disableWhileUpdating, disableChanges] = useState(false);
    const [allowAutoScroll, setAllowAutoScroll] = useState<boolean>(false);
    const dashboardRef = React.useRef<HTMLDivElement>(null);
    const informUser = useInformUser();

    // Intentional temporary variable (in case of width-change)
    let enableGridChanges = !disableWhileUpdating && !showWidgetMenu && editing;

    /**
     * Function to handle widgets
     */
    const widgetHandler = useCallback(
        (type: Exclude<WidgetActionType["type"], typeof SET_INITIAL>, widgetsToHandle: Widget[]) => {
            if (!widgetsToHandle.length) return Promise.resolve();
            let widgetPromise: Promise<Widget>;
            const widgetToHandle = widgetsToHandle[0];
            switch (type) {
                case UPDATE_ONE:
                    widgetPromise = new Promise<Widget>((res, rej) =>
                        Services.updateWidget(widgetToHandle)
                            .then(res)
                            .catch((reason) => {
                                informUser({
                                    message:
                                        reason?.response?.status === 403
                                            ? fm(messages.updateWidgetPermissionErrorMessage)
                                            : reason?.response?.data?.detail ?? "",
                                    type: "error",
                                });
                                rej(reason);
                                Sentry.captureException(reason, {
                                    tags: {
                                        app: "dashboards-app",
                                        error:
                                            reason?.response?.status === 403
                                                ? fm(messages.updateWidgetPermissionErrorMessage).toString()
                                                : reason?.response?.data?.detail ?? "",
                                    },
                                });
                            })
                    );
                    break;
                case DELETE_ONE:
                    widgetPromise = new Promise<Widget>((res, rej) =>
                        Services.deleteWidget(widgetToHandle)
                            .then(() => {
                                res(widgetToHandle);
                            })
                            .catch((reason) => {
                                informUser({
                                    message:
                                        reason?.response?.status === 403
                                            ? fm(messages.deleteWidgetPermissionErrorMessage)
                                            : reason?.response?.data?.detail ?? "",
                                    type: "error",
                                });
                                rej(reason);
                                Sentry.captureException(reason, {
                                    tags: {
                                        app: "dashboards-app",
                                        error:
                                            reason?.response?.status === 403
                                                ? fm(messages.deleteWidgetPermissionErrorMessage).toString()
                                                : reason?.response?.data?.detail ?? "",
                                    },
                                });
                            })
                    );
                    break;
                case CREATE_ONE:
                    widgetPromise = new Promise<Widget>((res, rej) =>
                        Services.createWidget(widgetToHandle.customDashboard, widgetToHandle)
                            .then(res)
                            .catch((reason) => {
                                informUser({
                                    message:
                                        reason?.response?.status === 403
                                            ? fm(messages.createWidgetPermissionErrorMessage)
                                            : reason?.response?.data?.detail ?? "",
                                    type: "error",
                                });
                                rej(reason);
                                Sentry.captureException(reason, {
                                    tags: {
                                        app: "dashboards-app",
                                        error:
                                            reason?.response?.status === 403
                                                ? fm(messages.createWidgetPermissionErrorMessage).toString()
                                                : reason?.response?.data?.detail ?? "",
                                    },
                                });
                            })
                    );
                    break;
                case BULK_UPDATE: {
                    return Promise.all(widgetsToHandle.map((W) => Services.updateWidget(W))).then((newWidgets) => {
                        return widgetDispatch({ type, data: newWidgets });
                    });
                }
            }
            return widgetPromise.then((newWidget: Widget) => widgetDispatch({ type, data: newWidget }));
        },
        [informUser, widgetDispatch]
    );
    useEffect(
        function updateWidgets() {
            if (editing && layouts.length && widgets?.length) {
                disableChanges(true);
                const widgetsToUpdate = findChangedWidgets(widgets, layouts);
                const handler = widgetHandler("BULK_UPDATE", widgetsToUpdate);
                if (handler !== undefined) {
                    handler.finally(() => disableChanges(false));
                } else {
                    disableChanges(false);
                }
            }
        },
        [widgets, layouts, editing, showWidgetMenu, widgetHandler, setEditingState]
    );

    const widgetsToRender = React.useMemo(
        function renderWidgets() {
            if (!widgets || !layouts.length) return <WidgetLoader />;
            return widgets.map((widget) => (
                <WidgetColumn
                    data-testid={DASHBOARD_WIDGET_TEST_ID}
                    key={widgetToId(widget)}
                    disabledDrag={disableWhileUpdating}
                    draggable={enableGridChanges}
                    padding={0.5}
                >
                    <DashboardWidget
                        widgetHandler={widgetHandler}
                        widget={widget}
                        dashboardId={dashboard?.id ?? NaN}
                        exporting={exporting}
                    />
                </WidgetColumn>
            ));
        },
        [widgets, layouts.length, disableWhileUpdating, enableGridChanges, widgetHandler, dashboard?.id, exporting]
    );
    useEffect(
        function buildLayout() {
            if (widgets) setLayouts((currentLayouts) => buildNewLayout(currentLayouts, widgets));
        },
        [widgets, setLayouts]
    );

    useEffect(
        function attachAutoScroller() {
            const eventHandler = onWidgetDrag(allowAutoScroll, dashboardRef.current, scrollParentRef.current);
            window.document.addEventListener("mousemove", eventHandler, { passive: true });
            return () => window.document.removeEventListener("mousemove", eventHandler);
        },
        [allowAutoScroll, scrollParentRef]
    );

    return (
        <DashboardWrapper data-testid={DASHBOARD_WRAPPER_TEST_ID} ref={dashboardRef} editing={editing}>
            <ReactGridLayout
                layouts={generateLayout(layouts)}
                cols={{ lg: 12, md: 12, sm: 2, xs: 2, xxs: 2 }}
                breakpoints={{ lg: 1200, md: 800, sm: 768, xs: 480, xxs: 0 }}
                onDragStart={() => !allowAutoScroll && setAllowAutoScroll(true)}
                onDragStop={() => setAllowAutoScroll(false)}
                onWidthChange={(_, __, cols) => {
                    enableGridChanges = enableGridChanges && cols === 12;
                }}
                onResizeStop={(_, __, resizedItem) => {
                    setAllowAutoScroll(false);
                    eventDispatch?.("onWidgetResize", resizedItem);
                    window.dispatchEvent(new Event("resize"));
                }}
                onResizeStart={() => !allowAutoScroll && setAllowAutoScroll(true)}
                onLayoutChange={(newLayouts) => {
                    /** A layout should not update to a static one */
                    const isStaticLayout = newLayouts.every((l) => l.static);
                    if (!isStaticLayout) {
                        setLayouts((prevLayouts) => {
                            const oldLayoutIds = prevLayouts.map((l) => String(l.i));
                            const newLayoutIds = newLayouts.map((l) => String(l.i));
                            const hasNewLayouts = newLayoutIds.find((l) => !oldLayoutIds.includes(l));
                            /** Leave new layouts to be handled by the useEffect */
                            if (hasNewLayouts) return prevLayouts;
                            return newLayouts;
                        });
                    }
                }}
                margin={[0, 0]}
                rowHeight={75}
                compactType="vertical"
                isDraggable={enableGridChanges}
                isResizable={enableGridChanges}
                measureBeforeMount={false}
                useCSSTransforms
                draggableHandle=".widget-drag-handle"
            >
                {widgetsToRender}
            </ReactGridLayout>
        </DashboardWrapper>
    );
};
export default Dashboard;
