import { defineMessages } from "react-intl";

const messages = defineMessages({
    dashboardCreatedMsg: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardCreatedMsg",
        defaultMessage: "Dashboard Created",
    },
    createNewdashboardModalTitle: {
        id: "dashboardOverview.dashboardOverviewPage.createNewdashboardModalTitle",
        defaultMessage: "Create New Dashboard",
    },
    createDashboardNameHelper: {
        id: "dashboardOverview.dashboardOverviewPage.createDashboardNameHelper",
        defaultMessage: "What should the dashboard be called?",
    },
    createDashboardNameField: {
        id: "dashboardOverview.dashboardOverviewPage.createDashboardNameField",
        defaultMessage: "Dashboard name",
    },
    createDashboardDescriptionHelper: {
        id: "dashboardOverview.dashboardOverviewPage.createDashboardDescriptionHelper",
        defaultMessage: "Description",
    },
    createDashboardDescriptionField: {
        id: "dashboardOverview.dashboardOverviewPage.createDashboardDescriptionField",
        defaultMessage: "Dashboard Description",
    },
    addToCollection: {
        id: "dashboardOverview.dashboardOverviewPage.addToCollection",
        defaultMessage: "Add to collection",
    },
    createDashboard: {
        id: "dashboardOverview.dashboardOverviewPage.createDashboard",
        defaultMessage: "Create",
    },
    dashboardCreationFailMsg: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardCreationFailMsg",
        defaultMessage: "Something went wrong!",
    },
    noCollection: {
        id: "dashboardOverview.dashboardOverviewPage.noCollection",
        defaultMessage: "No collection",
    },
});

export default messages;
