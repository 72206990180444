import { defineMessages } from "react-intl";

const messages = defineMessages({
    hideFilters: {
        id: "DashboardOverview.DashboardCard.hideFilters",
        defaultMessage: "Hide filters",
    },
    showFilters: {
        id: "DashboardOverview.DashboardCard.showFilters",
        defaultMessage: "Show filters",
    },
    editDashboardLayout: {
        id: "DashboardOverview.DashboardCard.editDashboardLayout",
        defaultMessage: "Edit dashboard layout",
    },
    disableEditDashboardLayout: {
        id: "DashboardOverview.DashboardCard.disableEditDashboardLayout",
        defaultMessage: "Disable edit dashboard layout",
    },
    exportDashboard: {
        id: "DashboardOverview.DashboardCard.exportDashboard",
        defaultMessage: "Export dashboard",
    },
});

export default messages;
