import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";

type InfoMessage = {
    type: "info" | "success" | "warning" | "error";
    message: string | { toString: () => string } | JSX.Element;
    timeout?: number;
};

/** Message with timestamp in milliseconds, for unique identification. */
type InfoMessageWithTimestamp = InfoMessage & {
    timestamp: number;
};

type InfoMessageContextType = {
    infoMessage: InfoMessageWithTimestamp | null;
    informUser: (message: InfoMessage) => void;
    hideInfoMessage: () => void;
};

export const InfoMessageContext = createContext<InfoMessageContextType | undefined>(undefined);

type Props = { children?: ReactNode };

export const InfoMessageProvider: React.FC<Props> = ({ children }) => {
    const [infoMessage, setInfoMessage] = useState<InfoMessageWithTimestamp | null>(null);

    const informUser = useCallback((message: InfoMessage) => {
        setInfoMessage({ ...message, timestamp: new Date().getTime() });
    }, []);

    const hideInfoMessage = useCallback(() => {
        setInfoMessage(null);
    }, []);

    const contextValue = useMemo(
        () => ({ infoMessage, informUser, hideInfoMessage }),
        [infoMessage, informUser, hideInfoMessage]
    );

    return <InfoMessageContext.Provider value={contextValue}>{children}</InfoMessageContext.Provider>;
};

type InformUser = (message: InfoMessage) => void;

export function useInformUser(): InformUser {
    const messageContext = useContext(InfoMessageContext);

    if (messageContext === undefined) {
        throw new Error("Missing message context");
    }

    return messageContext.informUser;
}
