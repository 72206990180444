import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import {
    Plus as AddIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Home,
    List,
} from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, Collapse, IconButton, Link, Menu, MenuItem, Stack, Theme, Tooltip } from "@mui/material";
import { find } from "lodash-es";
import move from "lodash-move";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { DASHBOARD_OVERVIEW_MODALS } from "../constants";
import { groupDashboardsByCollection } from "../helpers";

import { DashboardCollectionItem } from "./DashboardCollectionItem";
import { DashboardList } from "./DashboardCollectionItem/DashboardList";
import { IgniteDashboardCollectionItem } from "./DashboardCollectionItem/IgniteCollection";
import { OtherDashboardCollection } from "./DashboardCollectionItem/OtherCollectionItem";
import messages from "./messages";

import PermissionContainer from "@/components/PermissionContainer";
import { fm } from "@/contexts/IntlContext";
import { useAllDashboardCollectionUserRelations } from "@/entities/dashboardCollectionRelations";
import {
    DashboardCollection,
    useAllDashboardCollections,
    useUpdateMultipleDashboardCollections,
} from "@/entities/dashboardCollections";
import {
    CustomDashboardListObject,
    useAllCustomDashboards,
    useUpdateMultipleCustomDashboards,
} from "@/entities/dashboards";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/usePermissionCheck";
import { TOPBAR_HEIGHT } from "@/lib/globalConstants";

interface Props {
    openMenu: boolean;
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenModal: React.Dispatch<React.SetStateAction<DASHBOARD_OVERVIEW_MODALS>>;
}

const DashboardOverviewMenu: React.FC<Props> = (props: Props) => {
    const MENU_TEST_ID_PREFIX = "dashboardOverviewMenu-";
    const { openMenu, setOpenMenu, setOpenModal } = props;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dashboards = useAllCustomDashboards(undefined, { service: "dashboards" });
    const collections = useAllDashboardCollections(undefined, {
        service: "dashboards",
    });
    const updateMultipleCollections = useUpdateMultipleDashboardCollections();
    const reorderDashboards = useUpdateMultipleCustomDashboards();
    const [collectionDragId, setCollectionDragId] = useState<number | undefined>(undefined);
    const [dragOverCollectionId, setDragOverCollectionId] = useState<number | undefined>(undefined);
    const [borderPosition, setBorderPosition] = useState<"top" | "bottom" | undefined>(undefined);
    const [dashboardDragId, setDashboardDragId] = useState<number | undefined>(undefined);
    const [groupList, setGroupList] = useState<
        { collection: DashboardCollection; dashboards: CustomDashboardListObject[] }[]
    >([]);
    const collectionRelations = useAllDashboardCollectionUserRelations(undefined, {
        service: "dashboards",
    });
    const hasChangePermission = useBackwardCompatiblePermissionCheck(["change"], {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });
    const showIgniteAnalytics = useFeatureToggle("show-legacy-ignite-analytics", false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openNewDashboardMenu = Boolean(anchorEl);

    useEffect(() => {
        const groupedDashboardsList = groupDashboardsByCollection(dashboards, collections);
        setGroupList(groupedDashboardsList);
    }, [collections, dashboards]);

    const handleDragStart = (dragStartEvent: React.DragEvent<HTMLDivElement>) => {
        dragStartEvent.stopPropagation();
        const currentDragId = Number(dragStartEvent.currentTarget.id.split("-")[1]);
        // * if NaN it means the dashboard was dropped on the "other" collection
        setCollectionDragId(currentDragId);
    };

    const handleSave = (
        updatedList: {
            collection: DashboardCollection;
            dashboards: CustomDashboardListObject[];
        }[]
    ) => {
        const collectionsToUpdate: DashboardCollection[] = [];
        updatedList.forEach((collectionObj, i) => {
            if (collectionObj.collection.name !== "other") {
                const updatedCollection = {
                    id: collectionObj.collection.id,
                    name: collectionObj.collection.name,
                    precedence: updatedList.length - i,
                };
                collectionsToUpdate.push(updatedCollection);
            }
        });
        updateMultipleCollections(collectionsToUpdate);
    };

    // If dropped on another collection, the dragged collection will be moved to that index of the list
    const handleDrop = (dropEvent: React.DragEvent<HTMLDivElement>) => {
        const targetId = Number(dropEvent.currentTarget.id.split("-")[1]);
        if (collectionDragId && dropEvent.currentTarget.id.split("-")[0] === "collection") {
            const targetCollection = groupList.find((collection) => collection.collection.id === targetId)?.collection;
            const dragCollection = groupList.find(
                (collection) => collection.collection.id === collectionDragId
            )?.collection;
            if (!targetCollection || !dragCollection) return;
            let newIndex;
            let oldIndex;
            // Find new indices for the swapped elements
            groupList.forEach((collectionObject, i) => {
                if (collectionObject.collection.id === collectionDragId) {
                    oldIndex = i;
                } else if (collectionObject.collection.id === targetId) {
                    if (borderPosition === "bottom") {
                        if (dragCollection?.precedence > targetCollection?.precedence) {
                            newIndex = i;
                        } else {
                            newIndex = i + 1;
                        }
                    } else if (dragCollection?.precedence > targetCollection?.precedence) {
                        newIndex = i - 1;
                    } else {
                        newIndex = i;
                    }
                    if (newIndex < 0) {
                        newIndex = 0;
                    }
                }
            });
            if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                const newList = move(groupList, oldIndex, newIndex);
                handleSave(newList);
            }
        }
        const collectionHasDashboards = groupList.find((collection) => collection.collection.id === targetId)
            ?.dashboards.length;
        // If the dragged element is a dashboard, it will be moved to the collection.
        // todo: fix so that dropping on any collecton will add to the collection
        if (dashboardDragId && !collectionHasDashboards) {
            const draggedDashboard = dashboards.find((dashboard) => dashboard.id === dashboardDragId);
            if (draggedDashboard) {
                const updatedDashboard = {
                    ...draggedDashboard,
                    dashboardCollection: targetId,
                    precedence: 0,
                };
                reorderDashboards([updatedDashboard]);
            }
        }
        setCollectionDragId(undefined);
        setDashboardDragId(undefined);
        setDragOverCollectionId(undefined);
    };

    const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        const rect = ev.currentTarget.getBoundingClientRect();
        const y = ev.clientY - rect.top;
        if (y > rect.height / 2 && borderPosition !== "bottom") {
            setBorderPosition("bottom");
        } else if (y < rect.height / 2 && borderPosition !== "top") {
            setBorderPosition("top");
        }
        if (
            collectionDragId &&
            (!dragOverCollectionId || dragOverCollectionId !== Number(ev.currentTarget.id.split("-")[1]))
        ) {
            setDragOverCollectionId(ev.currentTarget.id ? Number(ev.currentTarget.id.split("-")[1]) : undefined);
        }
    };

    const handleMenuToggle = () => {
        setOpenMenu(!openMenu);
    };

    const getDragBorder = (id: number, overId: number | undefined, theme: Theme) => {
        if (overId && id === overId) {
            if (borderPosition === "bottom") {
                return { borderBottom: `2px solid ${theme.palette.primary.light}`, pt: "2px" };
            }
            return { borderTop: `2px solid ${theme.palette.primary.light}`, pb: "2px" };
        }
        // add padding in case the border is not visible so the items don't jump
        return { pt: "2px", pb: "2px" };
    };

    const handleClickAddCollectionOrDashboard = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <Stack direction="row" sx={{ background: "#F7F7F8" }}>
            <Tooltip title={openMenu ? fm(messages.collapseMenu).toString() : fm(messages.expandMenu).toString()}>
                <IconButton
                    onClick={handleMenuToggle}
                    size="small"
                    color="secondary"
                    sx={{
                        position: "absolute",
                        marginTop: "45px",
                        zIndex: 1000,
                        marginLeft: openMenu ? "280px" : "0px",
                    }}
                >
                    {openMenu ? <ChevronLeftIcon fontSize="inherit" /> : <ChevronRightIcon fontSize="inherit" />}
                </IconButton>
            </Tooltip>
            <Collapse orientation="horizontal" in={openMenu}>
                <Box
                    sx={{
                        width: "300px",
                        height: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
                        borderRight: `2px solid`,
                        borderColor: (theme) => theme.palette.grey["300"],
                    }}
                >
                    <Stack alignItems="center" justifyContent="space-between" sx={{ height: "98%" }}>
                        <Stack pt={2} sx={{ overflowY: "auto", width: "100%" }}>
                            <Stack pb={2}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onClick={() => {
                                        track("Dashboards: Clicked home dashboard");
                                        navigate(`/dashboard/overview/home/`);
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.primary.hover,
                                        },
                                        px: 3,
                                        py: 1,
                                        width: "100%",
                                    }}
                                >
                                    <Link
                                        data-testid={`${MENU_TEST_ID_PREFIX}homeDashboardLink-home`}
                                        component="button"
                                        variant="textMd"
                                        onClick={() => {
                                            navigate(`/dashboard/overview/home/`);
                                        }}
                                        underline="none"
                                    >
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <Home fontSize="small" />
                                            {fm(messages.homeDashboard)}
                                        </Stack>
                                    </Link>
                                    <PermissionContainer
                                        requiredPermissionTypes={["add"]}
                                        equivalentUserPermission={{
                                            namespace: "dashboards",
                                            relation: { object: "general", relation: "write" },
                                        }}
                                    >
                                        <Tooltip title={fm(messages.createNewTooltip)}>
                                            <IconButton size="large" onClick={handleClickAddCollectionOrDashboard}>
                                                <AddIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </PermissionContainer>
                                    <Menu open={openNewDashboardMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenModal("CREATE_DASHBOARD");
                                                setAnchorEl(null);
                                            }}
                                        >
                                            {fm(messages.createNewDashboard)}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenModal("CREATE_COLLECTION");
                                                setAnchorEl(null);
                                            }}
                                        >
                                            {fm(messages.createNewCollection)}
                                        </MenuItem>
                                    </Menu>
                                </Stack>
                                <Link
                                    data-testid={`${MENU_TEST_ID_PREFIX}homeDashboardLink-settings`}
                                    underline="none"
                                    component="button"
                                    variant="textMd"
                                    onClick={() => {
                                        track("Dashboards: Clicked dashboard overview");
                                        navigate(`/dashboard/overview/settings/`);
                                    }}
                                    sx={(theme) => ({
                                        cursor: "pointer",
                                        "&:hover": { backgroundColor: theme.palette.primary.hover },
                                        pl: 3,
                                        width: "100%",
                                        textAlign: "left",
                                    })}
                                >
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <List fontSize="small" />
                                        {fm(messages.allDashboards)}
                                    </Stack>
                                </Link>
                            </Stack>
                            <Stack justifyContent="flex-start">
                                {showIgniteAnalytics && <IgniteDashboardCollectionItem />}
                                {dashboards !== null &&
                                    groupList.map(({ collection, dashboards: groupedDashboards }) => {
                                        const dashboardNames = groupedDashboards.map((dash) => {
                                            return (
                                                <Stack key={dash.id}>
                                                    <Link
                                                        variant="textSm"
                                                        onClick={() => {
                                                            track("Dashboards: Clicked navigate to dashboard", {
                                                                dashboardName: dash.name,
                                                            });
                                                            navigate(`/dashboard/overview/${dash.id}/`);
                                                        }}
                                                    >
                                                        {pathname === `/dashboard/overview/${dash.id}/` ? (
                                                            dash.name
                                                        ) : (
                                                            <b>{dash.name}</b>
                                                        )}
                                                    </Link>
                                                </Stack>
                                            );
                                        });
                                        return collection.name !== "other" && collection.id ? (
                                            <Box
                                                key={collection.id}
                                                sx={(theme) => ({
                                                    ...getDragBorder(collection.id, dragOverCollectionId, theme),
                                                })}
                                            >
                                                <div
                                                    key={collection.id}
                                                    draggable={hasChangePermission}
                                                    id={`collection-${collection.id}`}
                                                    onDragStart={handleDragStart}
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}
                                                >
                                                    <DashboardCollectionItem
                                                        collectionDashboards={groupedDashboards}
                                                        collection={collection}
                                                        hasChangePermission={hasChangePermission}
                                                        relation={find(collectionRelations, (rel) => {
                                                            return rel.collection === collection.id;
                                                        })}
                                                        dashboardDragId={dashboardDragId}
                                                        setDashboardDragId={setDashboardDragId}
                                                    >
                                                        {dashboardNames}
                                                    </DashboardCollectionItem>
                                                </div>
                                            </Box>
                                        ) : (
                                            (groupedDashboards.length && (
                                                <div key="other" draggable={false} id="collection-other">
                                                    <OtherDashboardCollection
                                                        key="otherCollection"
                                                        title={collection.name}
                                                        collectionType="other"
                                                        numberOfElements={groupedDashboards.length}
                                                    >
                                                        <DashboardList
                                                            data-testid="other-children"
                                                            dashboards={groupedDashboards}
                                                            dashboardDragId={dashboardDragId}
                                                            setDashboardDragId={setDashboardDragId}
                                                        />
                                                    </OtherDashboardCollection>
                                                </div>
                                            )) ||
                                                null
                                        );
                                    })}
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Collapse>
        </Stack>
    );
};

export default DashboardOverviewMenu;
