import { defineMessages } from "react-intl";

const messages = defineMessages({
    doubleCheckDelete: {
        id: "dashboardOverview.dashboardOverviewPage.doubleCheckDelete",
        defaultMessage:
            "Are you sure you want to delete this dashboard? \n\n This action is irreversible and all analyses in the dashboard will be deleted.",
    },
    delete: {
        id: "dashboardOverview.dashboardOverviewPage.delete",
        defaultMessage: "Delete",
    },
    cancel: {
        id: "dashboardOverview.dashboardOverviewPage.cancel",
        defaultMessage: "Cancel",
    },
    manageAccess: {
        id: "dashboardOverview.dashboardOverviewPage.manageAccess",
        defaultMessage: "Manage access",
    },
    editDashboard: {
        id: "dashboardOverview.dashboardOverviewPage.editDashboard",
        defaultMessage: "Edit dashboard",
    },
    chooseCollection: {
        id: "dashboardOverview.dashboardOverviewPage.chooseCollection",
        defaultMessage: "Choose collection",
    },
    other: {
        id: "dashboardOverview.dashboardOverviewPage.other",
        defaultMessage: "Other",
    },
    save: {
        id: "dashboardOverview.dashboardOverviewPage.save",
        defaultMessage: "Save",
    },
    description: {
        id: "dashboardOverview.dashboardOverviewPage.description",
        defaultMessage: "Description",
    },
    name: {
        id: "dashboardOverview.dashboardOverviewPage.name",
        defaultMessage: "Name",
    },
    duplicate: {
        id: "dashboardOverview.dashboardOverviewPage.duplicate",
        defaultMessage: "Duplicate dashboard",
    },
    dashboardCreatedMsg: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardCreated",
        defaultMessage: "Dashboard created",
    },
    areYouSureMessage: {
        id: "dashboardEditModal.DeleteConfirmation.AreYouSure",
        defaultMessage: "Are you sure?",
    },
    dashboardCreatedError: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardCreatedError",
        defaultMessage: "Something went wrong when creating the dashboard",
    },
    deleteDashboardError: {
        id: "dashboardOverview.dashboardOverviewPage.errorDeletingDashboard",
        defaultMessage: "Something went wrong when deleting the dashboard",
    },
});

export default messages;
