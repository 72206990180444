import { ElasticField } from "@ignite-analytics/elastic-fields";
import { GlobalColumnType } from "@ignite-analytics/global-types";
import * as Yup from "yup";

import { Mapping } from "./interfaces";
import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

/**
 * The validation for matching fields to an existing preserved global type
 * Currently validates:
 * (1) That a field is set for all preserved global types
 */

const globalTypeValidationV2 = Yup.mixed()
    .typeError(fm(messages.noFieldSelected))
    .required(fm(messages.noFieldSelected))
    .nullable();

const globalTypeObjectsValidationV2 = (fields: Record<number, ElasticField | number | undefined>) =>
    Yup.object().shape({
        ...Object.fromEntries(Object.keys(fields).map((preservedPk) => [preservedPk, globalTypeValidationV2])),
    });

/**
 * The validation for matching fields to an existing preserved global type
 * Currently validates:
 * (1) That a field is set for all preserved global types
 */

export const mappingValidationV2 = (initialMapping: Mapping) =>
    Yup.object().shape({
        dataTable: globalTypeValidationV2,
        dataColumns: globalTypeObjectsValidationV2(initialMapping.dataColumns),
        groupTypes: globalTypeObjectsValidationV2(initialMapping.groupTypes),
    });

export const getGlobalColumnTypeKey = (gt: GlobalColumnType) =>
    gt.dataType === "grouping" ? `${gt.id}_${gt.globalTypeSubKey}` : gt.id;
