import { getLocale, getRegion } from "@ignite-analytics/locale";
import { convertMessages } from "@ignite-analytics/pivot-charts";

import { fm } from "@/contexts/IntlContext";

function separator(n: number, decimalsArg: number | null | "auto" = "auto"): string {
    const absoluteNumber = Math.abs(n);
    const decimals =
        typeof decimalsArg === "number" ? decimalsArg : absoluteNumber < 10 ? 2 : absoluteNumber < 100 ? 1 : 0;
    return n.toLocaleString(getRegion(), {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

export const getSiUnits = () => [
    {
        value: 1e-2,
        short: fm(convertMessages.measureUnitPercentShort),
        long: fm(convertMessages.measureUnitPercentLong),
    },
    { value: 1, short: "", long: "" },
    {
        value: 1e3,
        short: fm(convertMessages.measureUnitThousandShort),
        long: fm(convertMessages.measureUnitThousandLong),
    },
    {
        value: 1e6,
        short: fm(convertMessages.measureUnitMillionShort),
        long: fm(convertMessages.measureUnitMillionLong),
    },
    {
        value: 1e9,
        short: fm(convertMessages.measureUnitBillionShort),
        long: fm(convertMessages.measureUnitBillionLong),
    },
    {
        value: 1e12,
        short: fm(convertMessages.measureUnitTrillionShort),
        long: fm(convertMessages.measureUnitTrillionLong),
    },
    {
        value: 1e15,
        short: fm(convertMessages.measureUnitQuadrillionShort),
        long: fm(convertMessages.measureUnitQuadrillionLong),
    },
    {
        value: 1e18,
        short: fm(convertMessages.measureUnitQuintillionShort),
        long: fm(convertMessages.measureUnitQuintillionLong),
    },
];

const formatBigNumber = (
    num: number,
    digits: number | "auto" | null = "auto",
    padString = true,
    unitDescription: "short" | "long" = "short"
): string => {
    const unitsWithoutPercent = getSiUnits().slice(1);
    let i = 0;
    for (i = unitsWithoutPercent.length - 1; i > 0; i -= 1) {
        if (num > 0 ? unitsWithoutPercent[i].value <= num : -unitsWithoutPercent[i].value >= num) break;
    }
    const decimals = typeof digits === "number" ? digits : num < 10 ? 2 : num < 100 ? 1 : 0;
    const toBeReturned =
        num < 0
            ? `-${formatBigNumber(-num, digits, padString, unitDescription)}`
            : (num / unitsWithoutPercent[i].value).toFixed(decimals).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
              (unitsWithoutPercent[i][unitDescription]?.toString() || "");
    return padString
        ? toBeReturned.padStart(4 + decimals - toBeReturned.length + (i === 0 ? 6 : 0), "\u00A0")
        : toBeReturned;
};

const formatMeasureUnit = (unit: number, option: "short" | "long"): string => {
    const candidate = getSiUnits().find((candidateUnit) => candidateUnit.value === unit);
    return candidate ? candidate[option].toString() : "";
};

export function formatNumber(
    n: number | undefined | null,
    decimals: number | "auto" | null = "auto",
    measureUnit: number | null | "auto" | "date" = 1,
    unitDescription: "short" | "long" = "short"
): string {
    if (n !== 0 && !n) {
        return "";
    }
    if (measureUnit === "auto") {
        return formatBigNumber(n, decimals, false, unitDescription);
    }
    if (measureUnit === "date") {
        return new Date(n).toLocaleDateString(getLocale());
    }
    return (
        separator(n / (measureUnit === null ? 1 : measureUnit), decimals) +
        formatMeasureUnit(measureUnit === null ? 1 : measureUnit, unitDescription)
    );
}

export function getLocaleMonth(date: Date) {
    const locale = getLocale();
    return date.toLocaleString(locale, {
        month: "short",
    });
}

export function toPercent(
    value: number | string | undefined | null,
    decimals: number | "auto" = "auto",
    multiplier = 100
): string {
    if (value !== 0 && !value) return "";
    return typeof value === "number" ? `${formatNumber(value * multiplier, decimals)}%` : value;
}
