import { ChartConfig } from "@ignite-analytics/pivot-charts";

import { Widget } from "@/components/Widgets/interfaces";

export const SHARED_DEFAULT_CONFIG: Pick<Widget, "filters" | "height" | "width" | "xPosition" | "yPosition"> = {
    filters: [],
    height: 4,
    width: 6,
    xPosition: 0,
    yPosition: Infinity,
};

export const FIELD_DISTRIBUTION_CHART_CONFIG: ChartConfig = {
    legends: false,
    optionsPerAgg: [
        {
            type: "bar",
            stackedDataLabels: false,
            alignLabelsInside: true,
            labelDisplayMode: "percent",
            yAxis: { disable: true },
        },
    ],
};

export const CONTRACT_LOYALTY = "CONTRACT_LOYALTY" as const;
export const CONTRACTS = "CONTRACTS" as const;
export const ANALYSIS_NEW = "ANALYSIS_NEW" as const;
export const NOTIFICATIONS = "NOTIFICATIONS" as const;
export const POTENTIAL = "POTENTIAL" as const;
export const RISK = "RISK" as const;
export const SPEND_DEVELOPMENT = "SPEND_DEVELOPMENT" as const;
export const FIELD_HISTORY = "FIELD_HISTORY" as const;
export const FIELD_DISTRIBUTION_NEW = "FIELD_DISTRIBUTION_NEW" as const;
export const LARGEST_FIELD_NEW = "LARGEST_FIELD_NEW" as const;
export const TEXT_WIDGET = "TEXT_WIDGET" as const;

// Global types

export const DATE_FORMAT = "YYYY-MM-DD";

export const DEPRECATED_WIDGET_MODEL = "deprecatedwidget";
export const ANALYSIS_WIDGET_MODEL = "analysiswidget";
export const LARGEST_FIELD_WIDGET_MODEL = "largestfieldwidget";
export const TABLE_VIEW = "table";
export const CHART_VIEW = "chart";
export const DISPLAY_COMPARISION = "displayComparison";
export const CATEGORY_LEVEL = "categoryLevel";
export const SUPPLIER_COUNT = "supplierCount";
export const SUPPLIER_NUMBER = "supplierNumber";
export const MIN_SPEND_LTM = "minSpendLtm";
export const SPEND_LIMIT = "spendLimit";
export const TIME_PERIOD = "timePeriod";
export const QUERY_SIZE = "querySize";
export const querySizes: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20];
export const PERCENTAGE_LIMIT = "percentageLimit";
export const CUSTOM_PERIOD = "period";
export const SELECTED_FIELD = "selectedField";
export const SELECTED_FILTER_FIELD = "selectedField";
export const VIEW_MODE = "viewState";
export const COL_SPLIT_ITEM = "columnSplitItems";
export const MIN_DIFF = "minDifference";
export const INTERVAL = "interval";
export const STATE_FIELD = "state";
export const TEXT_ALIGNMENT = "textAlignment";
