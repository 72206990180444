import { Box, Link, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import { getIgniteDashboardsURLs } from "../../../constants";
import { OtherDashboardCollection } from "../OtherCollectionItem";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

export const IgniteDashboardCollectionItem: React.FC = () => {
    const IGNITE_COLLECTION_PREFIX = "igniteCollection-";
    const navigate = useNavigate();
    const location = useLocation();
    const IGNITE_DASHBOARD_URLS = getIgniteDashboardsURLs();

    return (
        <OtherDashboardCollection
            title={fm(messages.igniteAnalyticsDashboards).toString()}
            collectionType="ignite"
            numberOfElements={3}
        >
            <Stack pl={2}>
                <Link
                    data-testid={`${IGNITE_COLLECTION_PREFIX}savingsOpportunitiesLink`}
                    underline="none"
                    component="button"
                    variant="textMd"
                    align="left"
                >
                    <Box
                        sx={(theme) => ({
                            "&:hover": { backgroundColor: theme.palette.primary.hover },
                            padding: 0.5,
                            borderRadius: "8px",
                            backgroundColor: location.pathname === IGNITE_DASHBOARD_URLS.savings ? "#FFF" : "",
                        })}
                        onClick={() => {
                            navigate(IGNITE_DASHBOARD_URLS.savings);
                        }}
                    >
                        {fm(messages.savingsOpportunities)}
                    </Box>
                </Link>
                <Link underline="none" component="button" variant="textMd" align="left">
                    <Box
                        sx={(theme) => ({
                            "&:hover": { backgroundColor: theme.palette.primary.hover },
                            padding: 0.5,
                            cursor: "pointer",
                            borderRadius: "8px",
                            backgroundColor: location.pathname === IGNITE_DASHBOARD_URLS.potential ? "#FFF" : "",
                        })}
                        onClick={() => {
                            navigate(IGNITE_DASHBOARD_URLS.potential);
                        }}
                    >
                        {fm(messages.potential)}
                    </Box>
                </Link>
                <Link component="button" variant="textMd" align="left" underline="none">
                    <Box
                        sx={(theme) => ({
                            "&:hover": { backgroundColor: theme.palette.primary.hover },
                            padding: 0.5,
                            cursor: "pointer",
                            backgroundColor: location.pathname === IGNITE_DASHBOARD_URLS.risk ? "#FFF" : "",
                        })}
                        onClick={() => {
                            navigate(IGNITE_DASHBOARD_URLS.risk);
                        }}
                    >
                        {fm(messages.risk)}
                    </Box>
                </Link>
            </Stack>
        </OtherDashboardCollection>
    );
};
