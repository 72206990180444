import { defineMessages } from "react-intl";

export const singularMessages = defineMessages({
    customdashboard: {
        id: "models.singular.customdashboard",
        defaultMessage: "dashboard",
    },
    dashboardcollection: {
        id: "models.singular.dashboardcollection",
        defaultMessage: "dashboard collection",
    },
    x: {
        id: "models.singular.x",
        defaultMessage: "unprotected item",
    },
    modelguard: {
        id: "models.singular.modelguard",
        defaultMessage: "object level permission",
    },
});
