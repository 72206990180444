import { Api } from "@ignite-analytics/api-client";

import { CopyWidgetRequestData } from "./interfaces";

import { Widget } from "@/components/Widgets/interfaces";

export const createWidgetCopy = <W extends Widget>(
    widgetId: number,
    widgetModel: Widget["modelName"],
    data: CopyWidgetRequestData
) => {
    const dashboardsUrl = `/dashboards/widgets/${widgetModel}/${widgetId}/copy`;
    return Api.post<W>(dashboardsUrl, data, { service: "dashboards" });
};
