import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";

import IgniteMatrix from "../analysis/prioritize/IgniteMatrix";
import LargestSuppliersWithDepartments from "../analysis/prioritize/LargestSuppliersWithDepartments";
import SuppliersByDepartmentCount from "../analysis/prioritize/SuppliersByDepartmentCount";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";

const SavingOpportunitiesDashboard: React.FC = () => {
    const transactionIndex = useTransactionsIndex()?.name;

    useSetBreadcrumbs(fm(messages.savingsOpportunities).toString());

    if (!transactionIndex) {
        return (
            <Stack>
                <Typography>{fm(messages.noSpendDataAvailable)}</Typography>
            </Stack>
        );
    }
    return (
        <Box px={4}>
            <Container maxWidth="xl">
                <Stack p={2.5} rowGap={2} sx={{ maxWidth: "2000px" }}>
                    <Stack direction="row">
                        <IgniteMatrix {...{ title: "igniteMatrix" }} height="70vh" />
                    </Stack>
                    <Stack direction="row">
                        <SuppliersByDepartmentCount {...{ title: "suppliers-by-department-count" }} height="70vh" />
                    </Stack>
                    <Stack direction="row">
                        <LargestSuppliersWithDepartments
                            {...{ title: "largest-suppliers-with-departments" }}
                            height="70vh"
                        />
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default SavingOpportunitiesDashboard;
