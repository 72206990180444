import { Session } from "@ory/kratos-client";
import { Session as SessionType } from "@/types";

export const mockOryUserId = "f8esa123-f6d7-429b-6845-e5fs4875b784";
export const mockTenant = process.env.REACT_APP_DEFAULT_TENANT || "mocktenant";
export const mockSession: Session = {
    id: "d8eaa269-fbfa-430a-9198-d5aa8203c368",
    active: true,
    expires_at: "2023-07-11T11:45:07.811272Z",
    identity: {
        id: mockOryUserId,
        traits: { email: "admin@test.com", name: { first: "Mr", last: "Admin" } },
        metadata_public: { tenant: mockTenant },
        schema_id: "",
        schema_url: "",
    },
};

export const mockSessionContext: SessionType = {
    id: mockOryUserId,
    email: mockSession.identity?.traits.email,
    tenant: mockTenant,
};
