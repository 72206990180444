import { defineMessages } from "react-intl";

const messages = defineMessages({
    chartTitle: {
        id: "analyze.prioritize.supByNumDepartmentsTitle",
        defaultMessage: "Largest suppliers by number of departments",
    },
    chartTooltip: {
        id: "analyze.prioritize.supByNumDepartmentsTooltip",
        defaultMessage: "{num} departments",
    },
    couldntFindGlobalType: {
        id: "analysis.useSpendCategoryElasticField",
        defaultMessage:
            "You don't seem to have a column with the tag `{tag}` in your Spend data table. To enable this analysis, go to settings and give one of your columns the `{tag}` tag.",
    },
    couldntFindTransactionsIndex: {
        id: "supplierDetail.spendDevelopmentChart.couldntFindTransactionsIndex",
        defaultMessage:
            "Could not find table with global type tag 'Spend data'. Please investigate 'Manage data fields' on the settings page.",
        description: "Error message in chart when no transaction index is found",
    },
});

export default messages;
