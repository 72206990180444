import { ElasticFieldType } from "@ignite-analytics/elastic-fields";
import { SUPPLIER_GT, TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import { AnalysisQuery } from "@ignite-analytics/pivot-ts";

export const getMissingFieldsText = (
    transactionValueField: string | undefined,
    supplierField: string | undefined,
    ebitMarginField: string | undefined,
    revenueField: string | undefined,
    financialYearField: string | undefined
): string => {
    let missingFields: string[] = [];
    const addFieldDescription = (desc: string) => {
        missingFields = [...missingFields, desc];
    };
    if (!transactionValueField) {
        addFieldDescription(`Transaction value (Global type: ${TRANSACTION_VALUE_GT})`);
    }
    if (!supplierField) {
        addFieldDescription(`Supplier relation (Global type: ${SUPPLIER_GT})`);
    }
    if (!ebitMarginField) {
        addFieldDescription(`Ebit margin from Enin financials`);
    }
    if (!revenueField) {
        addFieldDescription(`Revenue from Enin financials`);
    }
    if (!financialYearField) {
        addFieldDescription(`Financial year from Enin financials`);
    }
    const lastField = missingFields.pop();
    const precedingFields = missingFields.join(", ");
    return lastField ? `${precedingFields} and ${lastField}` : precedingFields;
};

export const getMatrixQuery = (
    transactionIndex: string,
    transactionValueField: string,
    ebitMarginField: string,
    revenueField: string,
    financialYearField: string,
    supplierField: string,
    supplierLabelField: string | undefined,
    supplierFieldType: ElasticFieldType
): AnalysisQuery => ({
    rowSplitItems: [],
    columnSplitItems: [
        {
            excludeOthers: true,
            field: supplierField,
            size: 1000,
            sortAggIndex: 2,
            sortOrder: "desc",
            labelField: supplierLabelField,
            type: supplierFieldType,
            unique: true,
        },
    ],
    valueAggregationItems: [
        {
            aggregation: "avg",
            field: ebitMarginField,
            filters: [],
            type: "float",
        },
        {
            id: NaN,
            expression: "params.spend/params.revenue",
            label: "Share of wallet",
            type: "script",
            params: {
                spend: { type: "float", field: transactionValueField, aggregation: "sum" },
                revenue: { type: "float", field: revenueField, aggregation: "avg" },
            },
        },
        {
            aggregation: "sum",
            field: transactionValueField,
            filters: [],
            type: "float",
        },
        {
            aggregation: "max",
            field: financialYearField,
            filters: [],
            type: "integer",
        },
    ],
    elasticIndex: transactionIndex,
});
