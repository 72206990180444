import { defineMessages } from "react-intl";

export const pluralMessages = defineMessages({
    customdashboard: {
        id: "models.plural.customdashboard",
        defaultMessage: "dashboards",
    },
    dashboardcollection: {
        id: "models.plural.dashboardcollection",
        defaultMessage: "dashboard collection",
    },
    x: {
        id: "models.plural.x",
        defaultMessage: "unprotected items",
    },
    modelguard: {
        id: "models.plural.modelguard",
        defaultMessage: "object level permissions",
    },
});
