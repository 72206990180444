import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export function useOnUnhandledRejectionReporter() {
    useEffect(() => {
        const handler = (event: PromiseRejectionEvent) => {
            Sentry.captureException(event.reason, { tags: { app: "dashboards-app" } });
        };
        window.addEventListener("unhandledrejection", handler);
        return () => {
            window.removeEventListener("unhandledrejection", handler);
        };
    }, []);
}
