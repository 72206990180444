import { defineMessages } from "react-intl";

const messages = defineMessages({
    thisPeriod: {
        id: "components.widgets.largestFieldWidget.thisPeriod",
        defaultMessage: "This period",
    },
    previousPeriod: {
        id: "components.widgets.largestFieldWidget.previousPeriod",
        defaultMessage: "Previous period",
    },
    percentageChange: {
        id: "components.widgets.largestFieldWidget.percentageChange",
        defaultMessage: "Percent change",
    },
});

export default messages;
