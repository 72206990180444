import {
    Check as CheckIcon,
    X as CloseIcon,
    Drag as DragIndicatorIcon,
    DotsVertical as MoreVertIcon,
} from "@ignite-analytics/icons";
import { IconButton, Link, Stack, TextField, Theme } from "@mui/material";
import { findIndex, orderBy } from "lodash-es";
import move from "lodash-move";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import messages from "../messages";

import DashboardOptionsButton from "./DashboardOptionsButton";

import PermissionModal from "@/components/PermissionModal";
import { DeleteDashboardsModal } from "@/containers/CustomDashboardPage/DashboardOverview/modals/DeleteDashboardModal";
import { EditDashboardModal } from "@/containers/CustomDashboardPage/DashboardOverview/modals/EditDashboardModal";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import {
    CustomDashboardListObject,
    useAllCustomDashboards,
    useReorderDashboards,
    useUpdateMultipleCustomDashboards,
} from "@/entities/dashboards";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/usePermissionCheck";
import { BACKEND_MODELS } from "@/lib/permissions";

interface DashboardListProps {
    dashboards?: CustomDashboardListObject[];
    dashboardDragId: number | undefined;
    setDashboardDragId: (id: number | undefined) => void;
}

const DASHBOARD_LIST_TESTID_PREFIX = "dashboardList-dashboardLink-";

export const DashboardList: React.FC<DashboardListProps> = (props: DashboardListProps) => {
    const { dashboards, dashboardDragId, setDashboardDragId } = props;
    const [openEditDashboard, setOpenEditDashboard] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [renameDashboardId, setRenameDashboardId] = useState<number | undefined>(undefined);
    const [newDashboardName, setNewDashboardName] = useState<string | undefined>(undefined);
    const [openPermissionModal, setOpenPermissionModal] = useState(false);
    const [clickedDashboard, setClickedDashboard] = useState<CustomDashboardListObject | undefined>(undefined);
    const [hoverOverId, setHoverOverId] = useState<number | undefined>(undefined);
    const [dragOverId, setDragOverId] = useState<number | undefined>(undefined);
    const [borderPosition, setBorderPosition] = useState<"top" | "bottom" | undefined>(undefined);

    const hasDashboardChangePermission = useBackwardCompatiblePermissionCheck(["change"], {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });
    const hasModelGuardChangePermission = useBackwardCompatiblePermissionCheck(["change"], {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });

    const hasSpecificDashboardChangePermission = useBackwardCompatiblePermissionCheck(
        ["change"],
        { namespace: "dashboards", relation: { object: "general", relation: "write" } },
        clickedDashboard?.guard
    );

    const user = useOryUserIdOrThrow();
    const reorderDashboards = useReorderDashboards();
    const updateDashboards = useUpdateMultipleCustomDashboards();
    const allDashboards = useAllCustomDashboards(undefined, {
        service: "dashboards",
    });
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const informUser = useInformUser();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [sortedDashboards, setSortedDashboards] = useState<CustomDashboardListObject[]>(
        orderBy(dashboards, ["precedence", "name"], ["desc", "asc"])
    );

    const handleClick = (event, dashboard: CustomDashboardListObject) => {
        setAnchorEl(event.currentTarget);
        setClickedDashboard(dashboard);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickRename = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        if (!clickedDashboard) return;
        setRenameDashboardId(clickedDashboard?.id);
        setAnchorEl(null);
    };

    const handleRenameDashoard = (dashboard: CustomDashboardListObject) => {
        if (newDashboardName) {
            updateDashboards([{ ...dashboard, name: newDashboardName }])
                .then(() => {
                    setRenameDashboardId(undefined);
                    setNewDashboardName(undefined);
                })
                .catch(() => {
                    informUser({ message: fm(messages.renameDashboardError), type: "error" });
                });
        }
    };

    useEffect(() => {
        setSortedDashboards(orderBy(dashboards, ["precedence", "name"], ["desc", "asc"]));
    }, [dashboards, sortedDashboards.length]);

    const handleDragStart = (dragStartEvent: React.DragEvent<HTMLDivElement>) => {
        dragStartEvent.stopPropagation();
        if (!hasDashboardChangePermission) return;
        const currentDragId = Number(dragStartEvent.currentTarget.id.split("-")[1]);
        setDashboardDragId(currentDragId);
    };

    const moveDashboardBetweenCollections = (
        dashboardToMove: CustomDashboardListObject,
        targetDashboard: CustomDashboardListObject
    ) => {
        const newDashboardList = sortedDashboards;
        newDashboardList.push(dashboardToMove);
        let targetIndex: number = findIndex(newDashboardList, (db) => {
            return db.id === targetDashboard.id;
        });
        if (borderPosition === "bottom") {
            targetIndex += 1;
        }
        const oldIndex = newDashboardList.length - 1;
        const newOrder: CustomDashboardListObject[] = move(newDashboardList, oldIndex, targetIndex);
        const dashboardsToUpdate: CustomDashboardListObject[] = newOrder.map((db, i) => {
            const newDashboard = { ...db };
            newDashboard.precedence = newOrder.length - i;
            if (newDashboard.id === dashboardToMove.id) {
                newDashboard.dashboardCollection = targetDashboard.dashboardCollection;
            }
            return newDashboard;
        });
        updateDashboards(
            [{ ...dashboardToMove, dashboardCollection: targetDashboard.dashboardCollection }],
            false
        ).then(() => {
            reorderDashboards(dashboardsToUpdate);
        });
        setSortedDashboards(dashboardsToUpdate);
    };

    const moveDashboardWithinCollection = (
        dashboardToMove: CustomDashboardListObject,
        targetDashboard: CustomDashboardListObject
    ) => {
        let newIndex: number = findIndex(sortedDashboards, (dashboard) => {
            return dashboard.id === targetDashboard.id;
        });
        const oldIndex = findIndex(sortedDashboards, (dashboard) => {
            return dashboard.id === dashboardToMove.id;
        });
        if (newIndex === oldIndex) return;
        // set index based on the position you are dragging the dashboard
        // if it is dragged to the bottom half of the target dashboard, it will be moved below it
        if (borderPosition === "bottom" && newIndex < oldIndex) {
            newIndex += 1;
        }
        if (borderPosition === "top" && newIndex > oldIndex) {
            newIndex -= 1;
        }

        // if index is less then 0, set it to 0 to avoid errors
        if (newIndex < 0) {
            newIndex = 0;
        }

        // if the dashboard is dropped on the bottom half of the target dashboard, it will be moved below it
        if (oldIndex !== newIndex) {
            const newOrder: CustomDashboardListObject[] = move(sortedDashboards, oldIndex, newIndex);
            const dashboardsToUpdate: CustomDashboardListObject[] = newOrder.map((dashboard, i) => {
                const newDashboard = { ...dashboard };
                newDashboard.precedence = newOrder.length - i;
                return newDashboard;
            });
            reorderDashboards(dashboardsToUpdate);
            setSortedDashboards(dashboardsToUpdate);
        }
    };

    // If dropped on another dashboard, the dragged dashboard will be moved to that index
    const handleDrop = (dropEvent: React.DragEvent<HTMLDivElement>) => {
        const draggedDashboard = allDashboards.find((dashboard) => dashboard.id === dashboardDragId);
        // Get the dashboard that the dragged dashboard is dropped on need to split the id e.g. dashboard-1 to get the id
        const targetDashboard = sortedDashboards.find(
            (dashboard) => dashboard.id === Number(dropEvent.currentTarget.id.split("-")[1])
        );
        if (!draggedDashboard || !targetDashboard) return;

        // if dashboard is dropped over a dashboard in a different collection, update collection and position in list
        if (draggedDashboard?.dashboardCollection !== targetDashboard?.dashboardCollection) {
            moveDashboardBetweenCollections(draggedDashboard, targetDashboard);
        } else if (dashboardDragId && dropEvent.currentTarget.id.split("-")[0] === `dashboard`) {
            moveDashboardWithinCollection(draggedDashboard, targetDashboard);
        }
        setDashboardDragId(undefined);
        setDragOverId(undefined);
        setBorderPosition(undefined);
    };

    const handleDragOver = (dragOverEvent: React.DragEvent<HTMLDivElement>) => {
        dragOverEvent.preventDefault();
        const rect = dragOverEvent.currentTarget.getBoundingClientRect();
        const y = dragOverEvent.clientY - rect.top;
        if (y > rect.height / 2 && borderPosition !== "bottom") {
            setBorderPosition("bottom");
        } else if (y < rect.height / 2 && borderPosition !== "top") {
            setBorderPosition("top");
        }
        if (dashboardDragId && (!dragOverId || dragOverId !== Number(dragOverEvent.currentTarget.id.split("-")[1]))) {
            setDragOverId(
                dragOverEvent.currentTarget.id ? Number(dragOverEvent.currentTarget.id.split("-")[1]) : undefined
            );
        }
    };

    const getDragBorder = (id: number, overId: number | undefined, theme: Theme) => {
        if (overId && id === overId) {
            if (borderPosition === "bottom") {
                return { borderBottom: `2px solid ${theme.palette.primary.light}`, pt: "2px" };
            }
            return { borderTop: `2px solid ${theme.palette.primary.light}`, pb: "2px" };
        }
        return { pt: "2px", pb: "2px" };
    };

    return dashboards && dashboards.length ? (
        <>
            <Stack>
                {sortedDashboards.map((dash) => {
                    return (
                        <div
                            key={dash.id}
                            draggable
                            id={`dashboard-${dash.id}`}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragLeave={() => {
                                setDragOverId(undefined);
                            }}
                            onDrop={handleDrop}
                            onMouseOver={(ev) => {
                                setHoverOverId(
                                    ev.currentTarget.id ? Number(ev.currentTarget.id.split("-")[1]) : undefined
                                );
                            }}
                            onMouseLeave={() => {
                                setHoverOverId(undefined);
                            }}
                            onFocus={() => null}
                        >
                            <Stack
                                direction="row"
                                key={dash.id}
                                sx={(theme) => ({
                                    "&:hover": { backgroundColor: theme.palette.primary.hover },
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    backgroundColor: pathname === `/dashboard/overview/${dash.id}/` ? "#FFF" : "",
                                    ...getDragBorder(dash.id, dragOverId, theme),
                                    overflowX: "hidden",
                                })}
                                onClick={() => {
                                    if (renameDashboardId === dash.id) return;
                                    navigate(`/dashboard/overview/${dash.id}/`);
                                }}
                                justifyContent="space-between"
                                alignItems="center"
                                pr={2}
                            >
                                {renameDashboardId === dash.id ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        sx={{ width: "100%" }}
                                    >
                                        <DragIndicatorIcon fontSize="inherit" sx={{ visibility: "hidden" }} />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{ width: "100%" }}
                                        >
                                            <TextField
                                                defaultValue={dash.name}
                                                variant="standard"
                                                onChange={(e) => setNewDashboardName(e.target.value)}
                                                sx={(theme) => ({
                                                    ".MuiInputBase-input": {
                                                        ...theme.typography.body2,
                                                    },
                                                })}
                                            />
                                            <Stack direction="row" justifyContent="flex-end">
                                                <IconButton size="small" onClick={() => handleRenameDashoard(dash)}>
                                                    <CheckIcon fontSize="inherit" color="success" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setNewDashboardName(undefined);
                                                        setRenameDashboardId(undefined);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" color="error" />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        sx={{ width: "90%" }}
                                    >
                                        {hasDashboardChangePermission && hoverOverId === dash.id ? (
                                            <DragIndicatorIcon fontSize="inherit" sx={{ cursor: "grab" }} />
                                        ) : (
                                            <DragIndicatorIcon fontSize="inherit" sx={{ visibility: "hidden" }} />
                                        )}
                                        <Link
                                            data-testid={`${DASHBOARD_LIST_TESTID_PREFIX}${dash.id}`}
                                            underline="none"
                                            variant="textMd"
                                            textOverflow="ellipsis"
                                            textAlign="left"
                                            sx={{ py: 0.5, width: "95%", overflow: "hidden" }}
                                            fontWeight={
                                                pathname === `/dashboard/overview/${dash.id}/` ? "bold" : "normal"
                                            }
                                        >
                                            {dash.name}
                                        </Link>
                                    </Stack>
                                )}
                                {(renameDashboardId !== dash.id && hoverOverId === dash.id) ||
                                (anchorEl && anchorEl?.id === `dashboard-menu-${dash.id}`) ? (
                                    <MoreVertIcon
                                        id={`dashboard-menu-${dash.id}`}
                                        fontSize="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClick(e, dash);
                                        }}
                                    />
                                ) : (
                                    <MoreVertIcon fontSize="small" sx={{ visibility: "hidden" }} />
                                )}
                            </Stack>
                        </div>
                    );
                })}
            </Stack>
            {openEditDashboard && clickedDashboard && (
                <EditDashboardModal
                    open={openEditDashboard}
                    dashboard={clickedDashboard}
                    onClose={() => {
                        setOpenEditDashboard(false);
                        setClickedDashboard(undefined);
                    }}
                    redirectOnDelete
                />
            )}
            {clickedDashboard && (
                <DeleteDashboardsModal
                    open={openDeleteModal}
                    dashboard={clickedDashboard}
                    onClose={() => {
                        setOpenDeleteModal(false);
                        setClickedDashboard(undefined);
                    }}
                    onSubmit={() => {
                        setOpenDeleteModal(false);
                        setClickedDashboard(undefined);
                    }}
                    redirectOnDelete={pathname === `/dashboard/overview/${clickedDashboard.id}/`}
                />
            )}
            {user && clickedDashboard && hasDashboardChangePermission && openPermissionModal && (
                <PermissionModal
                    childModels={clickedDashboard.childModels || []}
                    name={clickedDashboard.name}
                    model={BACKEND_MODELS.CUSTOM_DASHBOARDS}
                    open={openPermissionModal}
                    objectId={clickedDashboard.id}
                    guard={clickedDashboard.guard}
                    onClose={() => setOpenPermissionModal(false)}
                    onUpdate={async (g) => {
                        await updateDashboards([{ ...clickedDashboard, guard: g.id }]);
                    }}
                    objectOwners={[clickedDashboard.owner]}
                />
            )}
            <DashboardOptionsButton
                open={open}
                anchorEl={anchorEl}
                clickedDashboard={clickedDashboard}
                setAnchorEl={setAnchorEl}
                handleClickRename={handleClickRename}
                setOpenPermissionModal={setOpenPermissionModal}
                setOpenDeleteModal={setOpenDeleteModal}
                onClose={handleClose}
                hasDashboardChangePermission={hasDashboardChangePermission}
                hasSpecificDashboardChangePermission={hasSpecificDashboardChangePermission}
                hasModelGuardChangePermission={hasModelGuardChangePermission}
            />
        </>
    ) : null;
};
