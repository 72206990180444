import { EventDataType, EventReducerState } from "./interfaces";

/**
 * Helper function to allow symbol indexator
 */
function cleanCallback<T, K extends keyof T>(object: T, key: K): Omit<T, K> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: omitCallback, ...rest } = object;
    return rest;
}
function getCallback<T, K extends keyof T>(object: T, key: K): T[K] {
    return object[key];
}

export const eventReducer = (state: EventReducerState["state"], action: EventReducerState["action"]) => {
    switch (action.type) {
        case "attachCallback": {
            const previousCallbacks = state[action.triggerType] || [];
            return {
                ...state,
                [action.triggerType]: { ...previousCallbacks, [action.identificator]: action.data },
            };
        }
        case "cleanUpCallback": {
            const previousCallbacks = state[action.triggerType] || [];
            return { ...state, [action.triggerType]: cleanCallback(previousCallbacks, action.identificator) };
        }
        case "triggerEvent": {
            const callbackMap = state[action.triggerType];
            Object.getOwnPropertySymbols(callbackMap ?? {}).forEach((sym) => {
                const callback = getCallback(callbackMap, sym) as (data: EventDataType["data"]) => void;
                callback(action.data);
            });

            return state;
        }
        default:
            return state;
    }
};
