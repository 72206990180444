import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { Plus as AddIcon, ChartPie as Chart } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Chip,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "../../../messages";

import PermissionContainer from "@/components/PermissionContainer";
import * as Widgets from "@/components/Widgets";
import { ANALYSIS_NEW } from "@/components/Widgets/constants";
import { AvailableWidget, Widget } from "@/components/Widgets/interfaces";
import { createWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";
import { CREATE_ONE, useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { fm } from "@/contexts/IntlContext";
import { useModelGuard } from "@/entities/modelGuards";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import globalMessages from "@/lib/messages/globalMessages";

interface DisableProps {
    requiresExternalIndices: readonly string[];
    featureToggleKey: string;
}

export const NewAnalysisButton = () => {
    const navigate = useNavigate();
    const indices = useAllElasticIndices();
    const transactionIndex = useTransactionsIndex();
    const transactionElasticFields = useElasticFields(transactionIndex?.name);
    const { dashboard, widgetDispatch, setShowWidgetMenu, showWidgetMenu } = useDashboardContextOrThrow();
    const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });

    const [newWidgetMenuAnchorEl, setNewWidgetMenuAnchorEl] = useState<HTMLElement | null>(null);

    const [predefinedWidgetMenuAnchorEl, setPredefinedWidgetMenuAnchorEl] = useState<HTMLElement | null>(null);
    const openPredefinedWidgetMenu = Boolean(predefinedWidgetMenuAnchorEl);

    if (!dashboard) {
        return null;
    }
    const handleNewWidgetMenuClick = () => {
        setShowWidgetMenu((prev) => !prev);
    };

    const handleCloseNewWidgetMenu = () => {
        setShowWidgetMenu(false);
    };

    const handlePredefinedWidgetMenuClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setPredefinedWidgetMenuAnchorEl(event.currentTarget);
    };

    const handleClosePredefinedWidgetMenu = () => {
        setPredefinedWidgetMenuAnchorEl(null);
    };

    const addWidget = (widget: AvailableWidget) => {
        const obj: Partial<Widget> = {
            ...(widget.defaultConfig || {}),
            filters: [],
            customDashboard: dashboard.id,
            height: 4,
            width: 6,
        };
        if (obj.type === ANALYSIS_NEW) {
            navigate(`/analytics/dashboard/`, { state: { dashboardId: dashboard.id } });
            return;
        }
        createWidget(dashboard.id, obj).then((res) => {
            widgetDispatch({ type: CREATE_ONE, data: res });
        });
    };

    const hasTransactionsIndex = !(transactionIndex === undefined || transactionElasticFields === undefined);

    const hasAccessToIndex = (externalIndices: readonly string[]) =>
        externalIndices.every((index) => indices?.some((o) => o.name === index));

    const isWidgetDisabled = (widget: Partial<DisableProps>): boolean | Promise<boolean> => {
        if ("requiresExternalIndices" in widget) {
            return !hasAccessToIndex(widget?.requiresExternalIndices ?? []);
        }
        return false;
    };

    const isWidgetNew = (widget: Partial<{ new: boolean }>): boolean => {
        if ("new" in widget) return widget.new ?? false;
        return false;
    };

    const handleMenuItemClicked = (widget: AvailableWidget, disabled: ReturnType<typeof isWidgetDisabled>) => {
        if (disabled) return;
        addWidget(widget);
        track("Widget Menu Item Clicked", { widgetType: widget.defaultConfig.type });
        handleClosePredefinedWidgetMenu();
        handleCloseNewWidgetMenu();
    };

    const widgetMenuOptions = Object.values(
        Widgets.allWidgetsOrdered(transactionIndex?.name, transactionElasticFields)
    ).slice(0, 2);

    const predefinedWidgetMenuOptions = Object.values(
        Widgets.allWidgetsOrdered(transactionIndex?.name, transactionElasticFields)
    ).slice(2);

    return (
        <>
            <PermissionContainer
                requiredPermissionTypes={["add", "change"]}
                equivalentUserPermission={{
                    namespace: "dashboards",
                    relation: { object: "general", relation: "write" },
                }}
                guard={dashboard.guard}
                modelGuard={modelGuard}
            >
                <Button
                    id="new-widget-button"
                    ref={setNewWidgetMenuAnchorEl}
                    aria-controls={showWidgetMenu ? "widget-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={showWidgetMenu ? "true" : undefined}
                    onClick={handleNewWidgetMenuClick}
                    startIcon={<AddIcon />}
                    color="ghostGray"
                >
                    {fm(messages.newAnalysis)}
                </Button>
            </PermissionContainer>
            <Menu
                id="new-widget-menu"
                anchorEl={newWidgetMenuAnchorEl}
                open={showWidgetMenu}
                onClose={handleCloseNewWidgetMenu}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuList>
                    {!indices ? (
                        <Stack p={1} alignItems="center" gap={0.1}>
                            <Skeleton width="270px" height="56px" />
                            <Skeleton width="270px" height="56px" />
                            <Skeleton width="270px" height="56px" />
                        </Stack>
                    ) : (
                        <>
                            {widgetMenuOptions.map((widget) => {
                                const disabled = isWidgetDisabled(widget as Partial<DisableProps>);
                                const hideWidget = "hide" in widget && widget.hide;
                                const Icon = widget.icon ?? AddIcon;
                                if (hideWidget) return null;
                                return (
                                    <MenuItem
                                        key={widget.message.id}
                                        onClick={() => handleMenuItemClicked(widget, disabled)}
                                    >
                                        <Tooltip title={"tooltip" in widget ? widget.tooltip : ""}>
                                            <>
                                                <ListItemIcon>
                                                    <Icon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction="row" gap={1}>
                                                            <Typography>{fm(widget.message)}</Typography>
                                                            {isWidgetNew(widget as Partial<{ new: boolean }>) && (
                                                                <Chip
                                                                    color="primary"
                                                                    size="small"
                                                                    label={fm(globalMessages.new).toString()}
                                                                />
                                                            )}
                                                        </Stack>
                                                    }
                                                    secondary={fm(widget.description)}
                                                />
                                            </>
                                        </Tooltip>
                                    </MenuItem>
                                );
                            })}
                            {hasTransactionsIndex && (
                                <MenuItem
                                    onClick={handlePredefinedWidgetMenuClick}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            openPredefinedWidgetMenu ? theme.palette.primary.pressed : "",
                                    }}
                                >
                                    <>
                                        <ListItemIcon>
                                            <Chart fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={fm(messages.predefinedAnalyses)}
                                            secondary={fm(messages.predefinedAnalysisDescription)}
                                        />
                                    </>
                                </MenuItem>
                            )}
                        </>
                    )}
                </MenuList>
            </Menu>
            <Menu
                id="predefined-widget-menu"
                anchorEl={predefinedWidgetMenuAnchorEl}
                open={openPredefinedWidgetMenu}
                onClose={handleClosePredefinedWidgetMenu}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {predefinedWidgetMenuOptions.map((widget) => {
                    const disabled = isWidgetDisabled(widget as Partial<DisableProps>);
                    const hideWidget = "hide" in widget && widget.hide;
                    const Icon = widget.icon ?? AddIcon;
                    if (hideWidget) return null;
                    return (
                        <MenuItem
                            key={widget.message.id}
                            onClick={() => handleMenuItemClicked(widget, disabled)}
                            sx={{ width: "300px" }}
                        >
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ maxWidth: "300px" }}
                                primary={fm(widget.message)}
                                secondary={fm(widget.description)}
                                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                            />
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
