import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

export const THIS_PERIOD_DESCRIPTORS = {
    month: fm(messages.thisMonth),
    quarter: fm(messages.these3Months),
    year: fm(messages.last12Months),
};

export const PERIODS = Object.keys(THIS_PERIOD_DESCRIPTORS) as (keyof typeof THIS_PERIOD_DESCRIPTORS)[];
