import { GlobalColumnType } from "@ignite-analytics/global-types";
import { Select } from "@mui/material";
import React from "react";

import { ConfigurationRow } from "../ConfigurationRow";

import messages from "./messages";

import { fm, getGlobalTypeName } from "@/contexts/IntlContext";

interface Props {
    preservedGlobalColumnTypes: GlobalColumnType[];
}

/**
 * Skeleton for when a data table is not selected, and therefore there are no columns to choose from.
 */
export const ColumnSelectionSkeleton: React.VFC<Props> = ({ preservedGlobalColumnTypes }) => {
    return (
        <>
            {preservedGlobalColumnTypes.map((preservedGlobalColumnType) => (
                <ConfigurationRow
                    key={preservedGlobalColumnType.id}
                    fieldName={getGlobalTypeName(preservedGlobalColumnType)}
                    description={preservedGlobalColumnType.description}
                    type={preservedGlobalColumnType.dataType}
                >
                    <Select fullWidth disabled renderValue={() => fm(messages.noDataTableSelected)} />
                </ConfigurationRow>
            ))}
        </>
    );
};
