import { QuerySize } from "@/components/AnalysisOptions/Fields";
import {
    CustomPeriod,
    FIRST_COLUMN_SIZE,
    FIRST_SPLIT_ITEM_EXCLUDE_OTHERS,
    firstColumnExcludeOthers,
    firstColumnSize,
    PercentageLimit,
    querySize,
    SelectedField,
    SplitItemField,
    WidgetViewMode,
} from "@/components/Widgets/Components/EditModal/fields";
import {
    COL_SPLIT_ITEM,
    CUSTOM_PERIOD,
    DISPLAY_COMPARISION,
    PERCENTAGE_LIMIT,
    QUERY_SIZE,
    SELECTED_FIELD,
    VIEW_MODE,
} from "@/components/Widgets/constants";
import { displayComparison } from "@/components/Widgets/FieldHistoryWidget/editModalFields";
import { potentialRiskFields } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/constants";

export const EDIT_MODAL_FIELDS = {
    CONTRACTS: { [QUERY_SIZE]: QuerySize },
    CONTRACT_LOYALTY: { [PERCENTAGE_LIMIT]: PercentageLimit, [CUSTOM_PERIOD]: CustomPeriod },
    FIELD_HISTORY: {
        [SELECTED_FIELD]: SelectedField,
        [PERCENTAGE_LIMIT]: PercentageLimit,
        [DISPLAY_COMPARISION]: displayComparison,
        [CUSTOM_PERIOD]: CustomPeriod,
    },
    LARGEST_FIELD_NEW: {
        [COL_SPLIT_ITEM]: SplitItemField,
        [FIRST_COLUMN_SIZE]: firstColumnSize,
        [PERCENTAGE_LIMIT]: PercentageLimit,
        [VIEW_MODE]: WidgetViewMode,
        [FIRST_SPLIT_ITEM_EXCLUDE_OTHERS]: firstColumnExcludeOthers,
        [CUSTOM_PERIOD]: CustomPeriod,
    },
    NOTIFICATIONS: { [QUERY_SIZE]: querySize },
    POTENTIAL: { ...potentialRiskFields("potential"), [QUERY_SIZE]: QuerySize },
    RISK: { ...potentialRiskFields("risk"), [QUERY_SIZE]: QuerySize },
};
