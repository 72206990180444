import { defineMessages } from "react-intl";

const messages = defineMessages({
    chartHeader: {
        id: "analyze.prioritize.igniteMatrixChartHeader",
        defaultMessage: "Ignite Matrix",
    },
    searchSuppPH: {
        id: "analyze.prioritize.igniteMatrixSearchSuppliers",
        defaultMessage: "Search for suppliers...",
    },
    sowLabel: {
        id: "analyze.prioritize.igniteMatrixSowLabel",
        defaultMessage: "Share of Wallet (your share of supplier revenue)",
    },
    ebitLabel: {
        id: "analyze.prioritize.igniteMatrixEbitLabel",
        defaultMessage: "Supplier EBIT margin",
    },
    bubbleTooltip: {
        id: "analyze.prioritize.igniteMatrixBubbleTooltip",
        defaultMessage:
            "'<b>'{name}'</b><br>'Revenue: {revenue} '<br>'Spend: {spend} '<br>' Share of Wallet: {isLarge}{sow} '<br>' EBIT margin: {ebitIsLow}{ebit} '<b>' Financial data from: {year} ",
    },
    tableHeader: {
        id: "analyze.prioritize.igniteMatrixTop20Header",
        defaultMessage: "Top 20",
    },
    tableHeadNr: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadNumb",
        defaultMessage: "Number",
    },
    tableHeadName: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadName",
        defaultMessage: "Supplier name",
    },
    tableHeadRevenue: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadRevenue",
        defaultMessage: "Revenue",
    },
    tableHeadSpend: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadSpend",
        defaultMessage: "Spend",
    },
    tableHeadSow: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadShareOfWallet",
        defaultMessage: "Share of wallet",
    },
    tableHeadEBIT: {
        id: "analyze.prioritize.igniteMatrixTop20RowHeadEbit",
        defaultMessage: "EBIT margin (%)",
    },
    analysisNotReady: {
        id: "analyze.prioritize.igniteMatrix.analysisNotReady",
        defaultMessage: "Data for neccessary for analysis is not loaded yet",
    },
    notAllFieldsFound: {
        id: "analyze.prioritize.igniteMatrix.notAllFieldsFound",
        defaultMessage: "Not all fields for analysis were found. Missing fields: {missingFields}",
    },
    spendDateFieldNotFound: {
        id: "analyze.prioritize.igniteMatrix.spendDateFieldNotFound",
        defaultMessage: "Did not find a field for spend date in your spend data, please tag it in manage data table",
    },
    couldntFindTransactionsIndex: {
        id: "supplierDetail.spendDevelopmentChart.couldntFindTransactionsIndex",
        defaultMessage:
            "Could not find table with global type tag 'Spend data'. Please investigate 'Manage data fields' on the settings page.",
        description: "Error message in chart when no transaction index is found",
    },
});

export default messages;
