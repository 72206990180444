import { Box, css, Stack, styled } from "@mui/material";

export const DashboardWrapper = styled(Box)<{ editing?: boolean }>`
    position: relative;
    width: 100%;

    .react-grid-item {
        & > .react-resizable-handle {
            @media screen and (max-width: 799px) {
                display: none;
            }
            display: ${({ editing }) => (editing ? "block" : "none")};
            position: absolute;
            bottom: 0;
            right: 0;
            width: 3em;
            height: 3em;
            cursor: nwse-resize;
            &::after {
                display: ${({ editing }) => (editing ? "block" : "none")};
                content: "↘";
                font-weight: 700;
                font-size: 1.5em;
                position: absolute;
                bottom: 0.2em;
                right: 0.5em;
            }
        }
    }
    & .react-grid-placeholder {
        background: #eee;
        opacity: 0.2;
        transition-duration: 100ms;
        z-index: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        & .react-resizable-handle {
            opacity: 0;
        }
    }
`;

export const WidgetColumn = styled(Stack)<{ disabledDrag?: boolean }>`
    height: 100%;
    overflow: hidden;
    ${({ disabledDrag }) =>
        disabledDrag
            ? css`
                  .widget-drag-handle,
                  span.react-resizable-handle.react-resizable-handle-se {
                      cursor: wait;
                      pointer-events: none;
                  }
              `
            : null}
`;
