import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { useCallback } from "react";

import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";

export type CollectionType = "custom" | "ignite" | "other" | "favorites";
export interface DashboardCollectionUserRelation {
    id: number;
    user: string;
    collection: number | null;
    open: boolean;
    collectionType?: CollectionType;
}

const useListCreateResource = () => {
    const backwardCompatibleUserId = useOryUserIdOrThrow();
    return `/dashboards/user/${backwardCompatibleUserId}/dashboard-collection-relations/`;
};

const listCreateResource = createListResource<DashboardCollectionUserRelation>`${useListCreateResource}`;

const detailResource = createDetailResource<DashboardCollectionUserRelation>`/dashboards/dashboard-collection-relations/${(
    params
) => params.id}/`;

export const {
    Provider: DashboardCollectionUserRelationContextProvider,
    useAll: useAllDashboardCollectionUserRelations,
    useCreateAction: useCreateDashboardCollectionUserRelationAction,
    useThisContext: useDashboardCollectionUserRelationContext,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: true,
    name: "DashboardCollectionUserRelation",
});

export const useUnknownIdUpdateDashboardCollectionRelation = () => {
    const { addDetailObj } = useDashboardCollectionUserRelationContext();
    return useCallback(
        async (dashboardCollectionUserRelationsToUpdate: DashboardCollectionUserRelation) => {
            const url = `/dashboards/dashboard-collection-relations/${dashboardCollectionUserRelationsToUpdate.id}/`;
            return Api.put<DashboardCollectionUserRelation>(url, dashboardCollectionUserRelationsToUpdate, {
                service: "dashboards",
            }).then((updatedDashboardCollectionUserRelation: DashboardCollectionUserRelation) => {
                addDetailObj(updatedDashboardCollectionUserRelation, url);
                return updatedDashboardCollectionUserRelation;
            });
        },
        [addDetailObj]
    );
};
