import { Link, Tooltip, Typography } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom-v5-compat";

import messages from "./messages";

import { staticFormatMessage } from "@/contexts/IntlContext";

/**
 *  Converts the number from one range to another
 *
 *  @param {number} value:  The value that we want to convert
 *  @param {number} min:    The minimum value of the mapping function
 *  @param {number} max:    The maximum value of the mapping function
 *
 *  @return {number}: number within out range from 0 to 100
 *
 */

export function map(value: number, min: number, max: number) {
    return (value - min) / (max - min);
}

/**
 *
 * Creates a NavLink for a supplier object
 *
 * @param {string}  name: The name of the supplier
 * @param {number}  id: The id that the url should link to
 * @param {string}  type: The recommendation type
 *
 * @return {Node}
 *
 */

function supplierNavLink(name: string | JSX.Element, id: number): JSX.Element {
    return (
        <Link component={ReactRouterLink} to={`./supplier-page/${id}/financials/`}>
            {name}
        </Link>
    );
}

/**
 *
 * Returns the correct score for share of wallet used to color the graph circle
 *
 * @param {number} sow: The share of wallet value
 *
 * @returns {number} How much of the graph circle should be colored
 */
export function convertSoW(sow: number): number {
    return sow ? (22 + 17 * Math.log(sow * 100)) / 100 : 0;
}

/**
 * Generates a tooltip wrapper if some data is missing for a supplier
 *
 * @param {boolean}     oldFinancialData: Indicates if a field is missing some financial data
 * @param {boolean}     usingRoa: Indication if ROA is used in stead of ROCE because of missing financial data
 * @param {string}      name: The name of the supplier
 * @param {number}      lastYearAccountYear: The latest financial year the supplier has repported
 * @param {number}      year: The year that was initially asked for
 * @param {number}      supplierId: The id of the supplier
 * @param intl
 * @param {string}      type: The recommendation type
 *
 * @returns {Node}
 *
 */

export function getTooltip(
    oldFinancialData: boolean,
    usingRoa: boolean,
    name: string,
    lastYearAccountYear: number,
    year: number,
    supplierId: number
) {
    if (!oldFinancialData && !usingRoa && lastYearAccountYear) {
        return supplierNavLink(name, supplierId);
    }

    const errors: string[] = [];
    if (oldFinancialData && lastYearAccountYear) {
        errors.push(
            staticFormatMessage(messages.oldFinancialData, {
                year,
                lastYearAccountYear,
            })
        );
    }
    if (usingRoa) errors.push(staticFormatMessage(messages.RoaInsteadOfRoce));
    if (!lastYearAccountYear) errors.push(staticFormatMessage(messages.missingFinancialData));
    return supplierNavLink(
        <Tooltip title={errors.join(" ")} placement="bottom">
            <Typography variant="subtitle2">{name.concat("*")}</Typography>
        </Tooltip>,
        supplierId
    );
}
