import { ElasticField, ElasticIndex } from "@ignite-analytics/elastic-fields";
import { Filter } from "@ignite-analytics/filters";
import { IntlShape } from "react-intl";

import { getFilterValueDescription } from "./filters";

import { getLabel } from "@/hooks/useLabel/helpers";

export const getShortDescriptionOfFilters = (
    filters: Filter[] | undefined,
    intl: IntlShape,
    elasticFields: ElasticField[],
    dataTables: ElasticIndex[],
    elasticIndex: string | undefined,
    separator = ", "
) => {
    if (!filters || !filters.length) return "";
    return filters
        .map(
            (f) =>
                `${getLabel(intl, f, elasticFields, dataTables, elasticIndex, undefined)}: ${getFilterValueDescription(
                    f,
                    intl,
                    elasticFields,
                    dataTables,
                    elasticIndex
                )}`
        )
        .join(separator);
};
