import { GlobalColumnType } from "@ignite-analytics/global-types";
import React from "react";

import { ConfigurationRow } from "../ConfigurationRow";
import { Mapping } from "../interfaces";

import ColumnSelectComponent from "./ColumnSelectComponent";

import { getGlobalTypeName } from "@/contexts/IntlContext";

interface PropsV2 {
    dataTable: string;
    preservedGlobalColumnTypes: GlobalColumnType[];
    setMapping?: React.Dispatch<React.SetStateAction<Mapping | undefined>>;
}

/**
 * Rows of columns to select
 */
export const ColumnSelection: React.VFC<PropsV2> = ({ dataTable, preservedGlobalColumnTypes, setMapping }) => {
    return (
        <>
            {preservedGlobalColumnTypes.map((preservedGlobalColumnType) => (
                <ConfigurationRow
                    key={preservedGlobalColumnType.id}
                    fieldName={getGlobalTypeName(preservedGlobalColumnType)}
                    description={preservedGlobalColumnType.description}
                    type={preservedGlobalColumnType.dataType}
                    globalTypeSubKey={preservedGlobalColumnType.globalTypeSubKey}
                >
                    <ColumnSelectComponent
                        key={`${dataTable}-${preservedGlobalColumnType}`}
                        dataTable={dataTable}
                        preservedGlobalColumnType={preservedGlobalColumnType}
                        setMapping={setMapping}
                    />
                </ConfigurationRow>
            ))}
        </>
    );
};
