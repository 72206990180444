import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

interface Props {
    loadingLabel?: string;
}
export const WidgetLoader = ({ loadingLabel }: Props) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, margin: "auto", zIndex: 1000 }}
        >
            {" "}
            {loadingLabel && (
                <Stack minWidth="100%" justifyContent="center" alignContent="center" padding="m">
                    <Typography variant="subtitle2">{loadingLabel}</Typography>
                </Stack>
            )}
            <CircularProgress color="info" />
        </Stack>
    );
};
