import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { DASHBOARD_OVERVIEW_MODALS } from "./constants";
import { ContentView } from "./ContentView";
import DashboardOverviewMenu from "./DashboardOverviewMenu";
import { NewCollectionModal } from "./modals/NewCollectionModal";
import { NewDashboardModalWrapper } from "./modals/NewDashboardModalWrapper";
import { TOPBAR_HEIGHT } from "@/lib/globalConstants";

const NEW_LAYOUT_DATA_ATTRIBUTE = "data-new-layout";

const DashboardOverviewPage: React.FC = () => {
    // Wrapper function to have DashboardContextProvider outside all the dashboards
    const [openMenu, setOpenMenu] = useState(true);
    const [openModal, setOpenModal] = useState<DASHBOARD_OVERVIEW_MODALS>(false);
    const htmlRef = useRef<HTMLElement>(document.documentElement);
    const gridRef = useRef<HTMLDivElement>(null);

    const [isNewLayout, setIsNewLayout] = useState(document.documentElement.hasAttribute(NEW_LAYOUT_DATA_ATTRIBUTE));
    const mutationObserverRef = useRef<MutationObserver>(
        new MutationObserver((mutations) => {
            const layoutAttributeMutation = mutations.find(
                (mutation) => mutation.attributeName === NEW_LAYOUT_DATA_ATTRIBUTE
            );
            if (layoutAttributeMutation) {
                setIsNewLayout(document.documentElement.hasAttribute(NEW_LAYOUT_DATA_ATTRIBUTE));
            }
        })
    );

    /**
     * We use a MutationObserver to listen for changes to the `data-new-layout` attribute on the `html` element.
     * This determines whether the new layout is enabled or not, so that it's possible to toggle the layout by adding
     * the attribute to the `html` element. This makes it easier to toggle the layout without having to change the feature
     * toggle.
     */
    useEffect(() => {
        const htmlElement = document.documentElement;
        mutationObserverRef.current.observe(htmlElement, {
            attributeFilter: [NEW_LAYOUT_DATA_ATTRIBUTE],
        });
        return () => {
            mutationObserverRef.current.disconnect();
        };
    }, [mutationObserverRef.current]);

    useEffect(function unsetTimeTrackingVar() {
        return function cleanUp() {
            // If user moves away from the dashboard module before it has fully loaded, we need to unset the time tracking variable
            window.__analyticsAppLoadTime = undefined;
        };
    }, []);

    return (
        <>
            {openModal === "CREATE_DASHBOARD" && (
                <NewDashboardModalWrapper open={openModal === "CREATE_DASHBOARD"} onClose={() => setOpenModal(false)} />
            )}
            {openModal === "CREATE_COLLECTION" && (
                <NewCollectionModal open={openModal === "CREATE_COLLECTION"} onClose={() => setOpenModal(false)} />
            )}
            <Grid container direction="row">
                <Grid item>
                    <DashboardOverviewMenu setOpenMenu={setOpenMenu} openMenu={openMenu} setOpenModal={setOpenModal} />
                </Grid>
                <Grid
                    item
                    xs
                    ref={gridRef}
                    sx={{
                        minWidth: "0px",
                        "*:where(html:not([data-new-layout])) &": {
                            overflowY: "auto",
                            maxHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
                        },
                    }}
                >
                    <ContentView openMenu={openMenu} scrollParentRef={isNewLayout ? htmlRef : gridRef} />
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardOverviewPage;
