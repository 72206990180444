import { isAxiosError } from "@ignite-analytics/api-client";
import {
    ArrowRight as ArrowRightIcon,
    ArrowDownTray as DownloadIcon,
    Check as CheckIcon,
    X as CloseIcon,
} from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { sortBy } from "lodash-es";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { FORMATS } from "./constants";
import messages from "./messages";
import * as Services from "./services";

import { widgetToExportIndex } from "@/components/Widgets/helpers";
import { Widget } from "@/components/Widgets/interfaces";
import { useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { ExportFunc, ExportImageData } from "@/hooks/useExport/interfaces";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    open: boolean;
    onClose: () => void;
}

const STEPS = [fm(messages.stepTitle), fm(messages.stepCharts), fm(messages.stepSummary)];

export const ExportModule: React.FC<Props> = ({ open, onClose }) => {
    const { dashboard, widgets } = useDashboardContextOrThrow();
    const { exportFuncByWidgetId } = useExportDashboardContextOrThrow();

    const [unchecked, setUnchecked] = useState<string[]>([]);
    const [title, setTitle] = useState(dashboard ? dashboard.name : "");
    const [step, setStep] = useState(1);
    const [downloading, setDownloading] = useState(false);
    const [format, setFormat] = useState(FORMATS.PPTX);
    const informUser = useInformUser();

    const startedExportWidgetIds = useRef<Record<string, boolean>>({});
    const latestExportPromiseRef = useRef<Promise<void>>(Promise.resolve());
    const [exportResultsByWidgetId, setExportResultsByWidgetId] = useState<Record<string, ExportImageData>>({});

    const generateExportResults = useCallback((remainingWidgetExports: [string, ExportFunc][], _widgets: Widget[]) => {
        remainingWidgetExports.forEach(([widgetId, exportFunc]) => {
            const widget = _widgets?.find(({ id }) => id && id.toString() === widgetId);
            if (!widget) {
                Sentry.captureMessage("Could not find widget for export", {
                    tags: { widgetId, app: "dashboards-app" },
                });
                return;
            }
            const exportIndex = widgetToExportIndex(widget);
            latestExportPromiseRef.current = latestExportPromiseRef.current.then(async () => {
                return exportFunc({
                    exportType: "image",
                    options: widget && "config" in widget ? widget.config : {},
                    disableDownload: true,
                })
                    .then((exportResult) => {
                        setExportResultsByWidgetId((prevState) => ({
                            ...prevState,
                            [widgetId]: {
                                ...exportResult,
                                title: widget.title,
                                exportIndex,
                                widgetId,
                            },
                        }));
                    })
                    .catch((err) => {
                        const widgetProps = {
                            widgetId,
                            customDashboard: widget.customDashboard,
                            elasticIndex: widget.elasticIndex,
                            title: widget.title,
                            width: widget.width,
                            height: widget.height,
                            type: widget.type,
                        };
                        Sentry.captureException(err, {
                            tags: { message: "Failed to export widget", app: "dashboards-app", ...widgetProps },
                        });
                    });
            });
        });
    }, []);

    useEffect(() => {
        if (step !== 2) return;
        const widgetIsNotDeleted = (widgetId) => widgets && widgets.some(({ id }) => id && id.toString() === widgetId);
        const remainingWidgetExports = Object.entries(exportFuncByWidgetId).filter(
            ([widgetId]) => !startedExportWidgetIds.current[widgetId] && widgetIsNotDeleted(widgetId)
        );
        if (remainingWidgetExports.length && widgets) {
            remainingWidgetExports.forEach(([widgetId]) => {
                startedExportWidgetIds.current[widgetId] = true;
            });
            generateExportResults(remainingWidgetExports, widgets);
        }
    }, [generateExportResults, exportFuncByWidgetId, widgets, step]);

    const toggleShouldExport = useCallback((itemKey: string) => {
        setUnchecked((prevUnchecked) => {
            const i = prevUnchecked.indexOf(itemKey);
            return i < 0 ? [...prevUnchecked, itemKey] : prevUnchecked.slice(0, i).concat(prevUnchecked.slice(i + 1));
        });
    }, []);

    const handleDownloadClick = useCallback(() => {
        setDownloading(true);
        const toBeExported = sortBy(
            Object.values(exportResultsByWidgetId).filter(
                (item) => !unchecked.includes(item.widgetId) && item.contentURL !== ""
            ),
            ({ exportIndex }) => exportIndex
        );
        const apiFn = format === FORMATS.PDF ? Services.generatePdfExport : Services.generatePptxPresentation;

        apiFn(toBeExported, title)
            .then(() => {
                track("exportDashboardToPdfOrPptxByDownload", { microapp: true });
                onClose();
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    tags: {
                        message: "Failed to export dashboard",
                        app: "dashboards-app",
                        format,
                        title,
                        numWidgetsToExport: toBeExported.length,
                    },
                });
                if (isAxiosError(err)) {
                    if (err.response?.status === 502) {
                        informUser({ type: "error", message: fm(messages.potentiallyTooBigExportError) });
                        return;
                    }
                }
                informUser({ type: "error", message: fm(globalMessages.error) });
            })
            .finally(() => setDownloading(false));
    }, [exportResultsByWidgetId, format, informUser, onClose, title, unchecked]);

    const handleBackClick = useCallback(() => {
        setStep((prevStep) => prevStep - 1);
    }, []);

    const handleNextClick = useCallback(() => {
        setStep((prevStep) => prevStep + 1);
    }, []);

    const numLoadingExports = Object.keys(exportFuncByWidgetId).length - Object.keys(exportResultsByWidgetId).length;
    const sortedExportResults = sortBy(Object.values(exportResultsByWidgetId), ({ exportIndex }) => exportIndex);
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{fm(messages.exportModalHeader)}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={step - 1}>
                    {STEPS.map((text) => (
                        <Step key={text.toString()}>
                            <StepLabel>{text}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {step === 1 && (
                    <>
                        <Stack direction="row" padding={1}>
                            <FormControl>
                                <InputLabel>{fm(messages.chooseFormat)}</InputLabel>
                                <Select
                                    value={format}
                                    label={fm(messages.chooseFormat)}
                                    onChange={({ target: { value } }) => setFormat(value)}
                                >
                                    {Object.values(FORMATS).map((f) => (
                                        <MenuItem key={f} value={f}>
                                            {fm(messages[f]).toString()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction="row" padding={1}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={fm(messages.presentationTitle).toString()}
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                            />
                        </Stack>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Stack direction="row" pt={1}>
                            {numLoadingExports > 0 && (
                                <Typography>
                                    {fm(messages.awaitingCharts, {
                                        awaitCount: numLoadingExports,
                                    })}
                                </Typography>
                            )}
                        </Stack>
                        <Grid container>
                            {sortedExportResults.map((item) => (
                                <Grid item padding="s" md={4} key={`chart-${item.widgetId}`}>
                                    <Card
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => toggleShouldExport(item.widgetId)}
                                    >
                                        <CardContent>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="h5">{item.title}</Typography>
                                                {!unchecked.includes(item.widgetId) && (
                                                    <Stack justifyContent="flex-end">
                                                        <CheckIcon />
                                                    </Stack>
                                                )}
                                            </Stack>
                                            <Stack direction="row">
                                                <img
                                                    src={item.contentURL}
                                                    height={100}
                                                    alt={item.title}
                                                    crossOrigin="anonymous"
                                                    loading="lazy"
                                                />
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
                {step === 3 && (
                    <Stack>
                        <Stack direction="row" padding={1}>
                            <Typography>
                                {fm(messages.exportMsg, {
                                    numCharts: Object.keys(exportResultsByWidgetId).length - unchecked.length,
                                    name: title,
                                    format: fm(messages[format]).toString(),
                                })}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyItems="center">
                            {numLoadingExports > 0 && (
                                <Typography>
                                    {fm(messages.awaitingCharts, {
                                        awaitCount: numLoadingExports,
                                    })}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                {step > 1 && (
                    <Stack>
                        <Stack direction="row">
                            <Button variant="text" onClick={handleBackClick}>
                                {fm(messages.previousButton)}
                            </Button>
                        </Stack>
                    </Stack>
                )}
                {step < STEPS.length && (
                    <Stack>
                        <Stack direction="row" justifyItems="flex-end">
                            <Button endIcon={<ArrowRightIcon />} onClick={handleNextClick}>
                                {fm(messages.nextButton)}
                            </Button>
                        </Stack>
                    </Stack>
                )}
                {step === 3 && (
                    <Stack>
                        <Stack direction="row" justifyItems="flex-end">
                            {downloading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={numLoadingExports > 0 || downloading}
                                    onClick={handleDownloadClick}
                                    endIcon={<DownloadIcon />}
                                >
                                    {fm(messages.downloadButton)}
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
};
