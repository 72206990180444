import * as Sentry from "@sentry/react";
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

type OryUserContextType = {
    user: OryUserInfo | undefined;
};
const OryUserContext = createContext<OryUserContextType | undefined>(undefined);

type Props = { children?: ReactNode };

type OryUserInfo = {
    userId: string;
    email: string;
    tenant: string;
    firstName: string;
    lastName: string;
};

export const OryUserContextProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<OryUserInfo>();
    useEffect(() => {
        if (!localStorage.getItem("tenant")?.length && process.env.NODE_ENV !== "development") {
            const err = new Error("No tenant set in local storage");
            Sentry.captureException(err, {
                tags: { app: "dashboards-app" },
            });
            throw err;
        }
        let responseString: string | undefined;
        fetch(`${process.env.REACT_APP_ORY_URL}/sessions/whoami`, { credentials: "include" })
            .then(async (res) => {
                if (!res.ok) {
                    throw new Error(`Failed to fetch ory session: ${res.statusText} - ${res.status}`);
                }
                const j = await res.json();

                responseString = JSON.stringify(j);
                setUser({
                    userId: j.identity.id,
                    email: j.identity.traits.email,
                    tenant: localStorage.getItem("tenant") ?? "",
                    firstName: j.identity.traits.name.first,
                    lastName: j.identity.traits.name.last,
                });
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    tags: {
                        app: "dashboards-app",
                        message: "Failed to fetch ory session",
                        responseString: responseString?.slice(0, 199),
                    },
                });
                if (process.env.NODE_ENV === "development") {
                    if (!localStorage.getItem("tenant")) {
                        localStorage.setItem("tenant", process.env.REACT_APP_DEFAULT_TENANT ?? "");
                    }
                    setUser({
                        userId: process.env.REACT_APP_DEFAULT_USER_ID ?? "",
                        email: process.env.REACT_APP_DEFAULT_USER ?? "",
                        tenant: localStorage.getItem("tenant") ?? "",
                        firstName: "Mr.",
                        lastName: "Admin",
                    });
                }
            });
    }, []);

    const contextValue = useMemo(() => ({ user }), [user]);

    return <OryUserContext.Provider value={contextValue}>{children}</OryUserContext.Provider>;
};

export function useOryUserId(): string | undefined {
    const context = useContext(OryUserContext);

    if (context === undefined) {
        throw new Error("Missing ory session context");
    }
    const userId = context.user?.userId;
    return userId;
}

export function useOryUserIdOrThrow(): string {
    const userId = useOryUserId();
    if (!userId) throw Error(`There is currently no userId set in the ory session context`);
    return userId;
}
