export const getShouldShowFiltersFromLocalStorage = () => {
    if (localStorage.getItem("showDashboardFilters") === null) {
        return true;
    }
    return localStorage.getItem("showDashboardFilters") === "true";
};

export const setShouldShowFiltersInLocalStorage = (shouldShowFilters: boolean) => {
    localStorage.setItem("showDashboardFilters", shouldShowFilters.toString());
};
