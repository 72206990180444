import { defineMessages } from "react-intl";

const messages = defineMessages({
    modalTitle: {
        id: "dashboardOverview.dashboardOverview.modals.newDashboardModalWrapper.modalTitle",
        defaultMessage: "Create a dashboard",
    },
    modalDescription: {
        id: "dashboardOverview.dashboardOverview.modals.newDashboardModalWrapper.modalDescription",
        defaultMessage: "How would you like to create your dashboard?",
    },
    useIgnitesDashboardLibrary: {
        id: "dashboardOverview.dashboardOverview.modals.newDashboardModalWrapper.useIgnitesDashboardLibrary",
        defaultMessage: "Use Ignite's dashboard library",
    },
    createEmptyDashboard: {
        id: "dashboardOverview.dashboardOverview.modals.newDashboardModalWrapper.createEmptyDashboard",
        defaultMessage: "Create an empty dashboard",
    },
});

export default messages;
