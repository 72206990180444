import {
    Bold as FormatBoldIcon,
    Italic as FormatItalicIcon,
    Strikethrough as StrikethroughIcon,
    Underline as FormatUnderlinedIcon,
    AlignCenter as FormatAlignCenterIcon,
    AlignLeft as FormatAlignLeftIcon,
    AlignRight as FormatAlignRightIcon,
    List as FormatListBulletedIcon,
    Numberlist as FormatListNumberedIcon,
} from "@ignite-analytics/icons";
import { IconButton, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DraftBlockType, DraftInlineStyleType, EditorState, RichUtils } from "draft-js";
import React from "react";

import { Alignment } from "..";

import { HeaderMenu } from "./HeaderMenu";

type Props = {
    editorState: EditorState;
    onStateChange: (updateFn: (editorState: EditorState) => EditorState) => void;
    alignment: Alignment;
    onAlignmentChange: (alignment: Alignment) => void;
};

export const Toolbar: React.FC<Props> = ({ editorState, onStateChange, alignment, onAlignmentChange }) => {
    const handleFormatChange = (format: DraftInlineStyleType) => {
        onStateChange((prevState) => RichUtils.toggleInlineStyle(prevState, format));
    };

    const handleBlockTypeChange = (format: DraftBlockType) => {
        onStateChange((prevState) => RichUtils.toggleBlockType(prevState, format));
    };

    return (
        <Stack direction="row" alignContent="center" paddingBottom={1}>
            <HeaderMenu onChange={handleBlockTypeChange} />
            <ToggleButtonGroup sx={{ paddingRight: 1 }} value={editorState.getCurrentInlineStyle().toArray()}>
                <ToggleButton value="BOLD" onClick={(_, val) => handleFormatChange(val)}>
                    <FormatBoldIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="ITALIC" onClick={(_, val) => handleFormatChange(val)}>
                    <FormatItalicIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="UNDERLINE" onClick={(_, val) => handleFormatChange(val)}>
                    <FormatUnderlinedIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="STRIKETHROUGH" onClick={(_, val) => handleFormatChange(val)}>
                    <StrikethroughIcon fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
                sx={{ paddingRight: 1 }}
                value={alignment}
                exclusive
                onChange={(_, val: Alignment) => onAlignmentChange(val)}
            >
                <ToggleButton value="left" aria-label="left aligned">
                    <FormatAlignLeftIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="center" aria-label="centered">
                    <FormatAlignCenterIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="right" aria-label="right aligned">
                    <FormatAlignRightIcon fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>
            <IconButton onClick={() => handleBlockTypeChange("ordered-list-item")}>
                <FormatListNumberedIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleBlockTypeChange("unordered-list-item")}>
                <FormatListBulletedIcon fontSize="small" />
            </IconButton>
        </Stack>
    );
};
