/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {\n        getDataTableElasticFields(input: $input) {\n            elasticFields {\n                ...ElasticFieldItemFields\n            }\n        }\n    }\n\n    fragment ElasticFieldItemFields on ElasticFieldItem {\n        field\n        label\n        type\n        globalTypeKey\n        globalTypeSubKey\n        labelField\n        labelFieldType\n        max\n        min\n        maxDateForRelativeFilters\n        elasticIndex\n    }\n": types.GetDataTableElasticFieldsDocument,
    "\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    query GetPinnedFields($input: GetPinnedFieldsInput!) {\n        getPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n": types.PinnedFieldFieldsFragmentDoc,
    "\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    mutation UpsertPinnedFields($input: UpsertPinnedFieldsInput!) {\n        upsertPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n": types.PinnedFieldFieldsFragmentDoc,
    "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    ": types.GetPivotDocument,
    "\n    mutation exportDataTableToFile($input: ExportDataTableToFileInput!) {\n        exportDataTableToFile(input: $input) {\n            notificationId\n        }\n    }\n": types.ExportDataTableToFileDocument,
    "\n    query getManyDataTables {\n        getManyDataTables {\n            entities {\n                ...DataTableFields\n            }\n        }\n    }\n\n    fragment DataTableFields on DataTable {\n        id\n        name\n        globalTypeKey\n        elasticIndex\n    }\n": types.GetManyDataTablesDocument,
    "\n    query getUsers($input: GetUsersInput!) {\n        getUsers(input: $input) {\n            result {\n                ...User\n            }\n        }\n    }\n\n    fragment User on RoleUser {\n        id\n        firstName\n        lastName\n        email\n    }\n": types.GetUsersDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {\n        getDataTableElasticFields(input: $input) {\n            elasticFields {\n                ...ElasticFieldItemFields\n            }\n        }\n    }\n\n    fragment ElasticFieldItemFields on ElasticFieldItem {\n        field\n        label\n        type\n        globalTypeKey\n        globalTypeSubKey\n        labelField\n        labelFieldType\n        max\n        min\n        maxDateForRelativeFilters\n        elasticIndex\n    }\n"): (typeof documents)["\n    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {\n        getDataTableElasticFields(input: $input) {\n            elasticFields {\n                ...ElasticFieldItemFields\n            }\n        }\n    }\n\n    fragment ElasticFieldItemFields on ElasticFieldItem {\n        field\n        label\n        type\n        globalTypeKey\n        globalTypeSubKey\n        labelField\n        labelFieldType\n        max\n        min\n        maxDateForRelativeFilters\n        elasticIndex\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    query GetPinnedFields($input: GetPinnedFieldsInput!) {\n        getPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n"): (typeof documents)["\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    query GetPinnedFields($input: GetPinnedFieldsInput!) {\n        getPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    mutation UpsertPinnedFields($input: UpsertPinnedFieldsInput!) {\n        upsertPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n"): (typeof documents)["\n    fragment PinnedFieldFields on PinnedField {\n        dataSource\n        field\n    }\n    mutation UpsertPinnedFields($input: UpsertPinnedFieldsInput!) {\n        upsertPinnedFields(input: $input) {\n            associatedId\n            pinnedFields {\n                ...PinnedFieldFields\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "): (typeof documents)["\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation exportDataTableToFile($input: ExportDataTableToFileInput!) {\n        exportDataTableToFile(input: $input) {\n            notificationId\n        }\n    }\n"): (typeof documents)["\n    mutation exportDataTableToFile($input: ExportDataTableToFileInput!) {\n        exportDataTableToFile(input: $input) {\n            notificationId\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getManyDataTables {\n        getManyDataTables {\n            entities {\n                ...DataTableFields\n            }\n        }\n    }\n\n    fragment DataTableFields on DataTable {\n        id\n        name\n        globalTypeKey\n        elasticIndex\n    }\n"): (typeof documents)["\n    query getManyDataTables {\n        getManyDataTables {\n            entities {\n                ...DataTableFields\n            }\n        }\n    }\n\n    fragment DataTableFields on DataTable {\n        id\n        name\n        globalTypeKey\n        elasticIndex\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getUsers($input: GetUsersInput!) {\n        getUsers(input: $input) {\n            result {\n                ...User\n            }\n        }\n    }\n\n    fragment User on RoleUser {\n        id\n        firstName\n        lastName\n        email\n    }\n"): (typeof documents)["\n    query getUsers($input: GetUsersInput!) {\n        getUsers(input: $input) {\n            result {\n                ...User\n            }\n        }\n    }\n\n    fragment User on RoleUser {\n        id\n        firstName\n        lastName\n        email\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;