import React, { useCallback, useContext, useReducer } from "react";

import { eventReducer } from "./helpers";
import { CallbackType, EventDataType } from "./interfaces";

const EventContext = React.createContext<
    | ((triggerType: EventDataType["triggerType"], data: (CallbackType | EventDataType)["data"]) => void | (() => void))
    | null
>(null);

export const useEventContext = () => {
    const context = useContext(EventContext);
    if (!context) return;
    return context;
};

export const EventContextWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [, coreDispatch] = useReducer(eventReducer, {});
    const dispatch = useCallback(
        (
            triggerType: EventDataType["triggerType"],
            data: (CallbackType | EventDataType)["data"]
        ): void | (() => void) => {
            if (typeof data === "function") {
                const identificator = Symbol("callback reference");
                coreDispatch({
                    type: "attachCallback",
                    identificator,
                    triggerType,
                    data,
                });
                return function cleanupFunction() {
                    return coreDispatch({
                        type: "cleanUpCallback",
                        identificator,
                        triggerType,
                    });
                };
            }
            coreDispatch({
                type: "triggerEvent",
                triggerType,
                data,
            });
        },
        [coreDispatch]
    );

    return <EventContext.Provider value={dispatch}>{children}</EventContext.Provider>;
};
