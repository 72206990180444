import { useDebounce } from "@ignite-analytics/general-tools";
import { Plus as AddIcon } from "@ignite-analytics/icons";
import { Button, Container, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import { useDashboardContextOrThrow } from "../DashboardContext";

import Dashboard from "./Dashboard";
import { DASHBOARD_WIDGET_TEST_ID } from "./Dashboard/DashboardWidget";
import messages from "./messages";
import { useRegisterVisit } from "./useRegisterVisit";
import { WidgetLoader } from "./WidgetLoader";

import PermissionContainer from "@/components/PermissionContainer";
import { EmptyDashboardSvg } from "@/components/svgs/EmptyDashboard";
import { useSetRelevantDataSourcesState } from "@/contexts/GlobalFilterContext";
import { fm } from "@/contexts/IntlContext";
import { useModelGuard } from "@/entities/modelGuards";

/**
 *  CONTAINER - CustomDashboardPage
 * ================================================
 *
 * Container used to manage widgets for a specific package
 *
 */

const ID_PREFIX = "dashboardContainer-";

interface Props {
    scrollParentRef: React.RefObject<HTMLDivElement>;
}

const DashboardContainer: React.FC<Props> = ({ scrollParentRef }) => {
    const location = useLocation();

    const { setShowWidgetMenu, widgets, dashboard } = useDashboardContextOrThrow();
    const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });
    const tenant = localStorage.getItem("tenant");

    // Get all the elastic indices from the widgets. if the widget is a predefined widget and the elasticindex has postfix -{tenant}* then remove it
    const regex = useMemo(() => new RegExp(`-${tenant}.*`), [tenant]);
    const relevantElasticIndices: string[] = useMemo(
        () => [...new Set(widgets?.map((widget) => widget.elasticIndex?.replace(regex, "")))],
        [regex, widgets]
    );

    const setRelevantDataSources = useSetRelevantDataSourcesState();

    useEffect(() => {
        setRelevantDataSources?.(relevantElasticIndices);
    }, [relevantElasticIndices, setRelevantDataSources]);

    const debouncedScrollWidgetToView = useDebounce(
        useCallback((widgetId: string) => {
            document
                .querySelector(`[data-testid=${DASHBOARD_WIDGET_TEST_ID}-${widgetId}]`)
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, []),
        200
    );

    useEffect(() => {
        if (
            dashboard &&
            widgets &&
            location.state &&
            typeof location.state === "object" &&
            "scrollToWidgetId" in location.state &&
            typeof location.state?.scrollToWidgetId === "string"
        ) {
            debouncedScrollWidgetToView(location.state.scrollToWidgetId);
        }
    }, [dashboard, location.state, widgets, debouncedScrollWidgetToView, location]);

    useRegisterVisit(dashboard);

    return (
        <Stack alignItems="flex-start" data-testid={`${ID_PREFIX.replace("-", "")}`} id="dashboardContainer" pb={10}>
            <Container maxWidth={false} data-testid={`${ID_PREFIX}widgetContainer`}>
                {
                    /** When there is an error fetching the dashboard */
                    dashboard === undefined ? (
                        <Stack alignItems="center" justifyContent="center">
                            <WidgetLoader />
                        </Stack>
                    ) : /** When you have access to the dashboard */
                    dashboard && widgets ? (
                        /** And there are widgets in the dashboard */
                        widgets.length ? (
                            <Dashboard scrollParentRef={scrollParentRef} />
                        ) : (
                            /** There are no widgets in the dashboard */
                            <Stack alignItems="center" justifyContent="center" gap={2} pt={8}>
                                <EmptyDashboardSvg size="200px" />
                                <Typography variant="subtitle1">{fm(messages.emptyDashboardLabel)}</Typography>

                                <PermissionContainer
                                    requiredPermissionTypes={["change"]}
                                    equivalentUserPermission={{
                                        namespace: "dashboards",
                                        relation: { object: "general", relation: "write" },
                                    }}
                                    loader={null}
                                    guard={dashboard.guard}
                                    modelGuard={modelGuard}
                                >
                                    <Button startIcon={<AddIcon />} onClick={() => setShowWidgetMenu(true)}>
                                        {fm(messages.addNewAnalysis)}
                                    </Button>
                                </PermissionContainer>
                            </Stack>
                        )
                    ) : (
                        /** waiting for the dashboard (skeleton) */
                        <WidgetLoader />
                    )
                }
            </Container>
        </Stack>
    );
};

export default DashboardContainer;
