import { defineMessages } from "react-intl";

const messages = defineMessages({
    genericServerError: {
        id: "components.errorAlerts.genericServerError",
        defaultMessage: "An error occurred, hover to get more information",
    },
    networkError: {
        id: "components.errorAlerts.genericServerError",
        defaultMessage: "A network error has occurred, to reload the analysis press the refresh symbol",
    },
});

export default messages;
