import { capitalCase } from "@ignite-analytics/general-tools";
import { IntlShape } from "react-intl";

import { Model } from "./constants";
import { BackendPermissionType, ModelGuard } from "./interfaces";

import { pluralMessages } from "@/lib/messages/permissionsPlural";
import { singularMessages } from "@/lib/messages/permissionsSingular";

export function hasObjectPermission(modelGuard: ModelGuard, types: BackendPermissionType[], userId: string): boolean {
    const needsEditPermission = types.includes("change") || types.includes("add") || types.includes("delete");
    if (needsEditPermission) {
        return modelGuard.standardWrite || modelGuard.writeUsers.includes(userId);
    }

    return modelGuard.standardRead || modelGuard.readUsers.includes(userId);
}

const hasMapping = (s: string): s is Model => s in singularMessages;

export const formatModelName = (model: Model | string, intl: IntlShape, singular = false) => {
    const messages = singular ? singularMessages : pluralMessages;
    if (hasMapping(model)) return intl.formatMessage(messages[model]);
    console.error(
        `No message found for ${model}, please add it to both singularMessages and pluralMessages in lib/messages/permissionsSingular.ts and lib/messages/permissionsPlural.ts`
    );
    return capitalCase(model);
};
