import { usePromiseState } from "@ignite-analytics/api-client";
import { Stringish } from "@ignite-analytics/general-tools";
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import { ColumnSelection } from "./ColumnSelection";
import { ColumnSelectionSkeleton } from "./ColumnSelectionSkeleton";
import { ConfigurationRow } from "./ConfigurationRow";
import { getGlobalColumnTypeKey, mappingValidationV2 } from "./helpers";
import { Mapping, PreservedGlobalTypes } from "./interfaces";
import messages from "./messages";
import { getPreservedGlobalTypes, updateConfigurations } from "./services";

import SuccessDialog from "@/components/Dialog/SuccessDialog";
import { Widget } from "@/components/Widgets/interfaces";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm, getGlobalTypeName } from "@/contexts/IntlContext";
import { useGetManyDataTables } from "@/hooks/useGetManyDataTables";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    /* If the dialog is open or not */
    open: boolean;
    /* Event handler for close dialog events */
    onClose: () => void;
    /* Title of the dialog */
    title: Stringish;
    /* The widget to configure fields for */
    widget: Widget;
}

/**
 * Configuration dialog intended for configuring widgets that were imported without the necessary global types set at the time of importing.
 * Utilizes the existing preserved global type fields which are set when publishing items to the library
 * and relies on the "fields" field on CopiableField-children set to None
 *
 * @param open if the dialog is open or not
 * @param onClose event handler for close dialog events
 * @param title of the dialog
 * @param widget to configure fields for
 */
const FieldConfigurationDialog: React.VFC<Props> = ({ title, onClose, open, widget }) => {
    const [{ waiting, arrived, data }, promise] = usePromiseState<PreservedGlobalTypes>();

    const [mapping, setMapping] = useState<Mapping>();
    const [selectedDataTable, setSelectedDataTable] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const { data: result } = useGetManyDataTables();
    const dataTables = result?.getManyDataTables.entities || [];

    const dataTable = data
        ? dataTables.find((dt) => dt.globalTypeKey === data.preservedGlobalTableType.key)
        : undefined;

    const validationSchema = useMemo(() => mapping && mappingValidationV2(mapping), [mapping]);

    const informUser = useInformUser();

    /* Get the preserved global types for the widget */
    useEffect(() => {
        if (widget.id) {
            promise(getPreservedGlobalTypes(widget.modelName, widget.id));
        }
    }, [promise, widget]);

    /* After the data has arrived, set the initial mapping and the default selected data table */
    useEffect(() => {
        if (arrived && data) {
            if (dataTable) {
                setSelectedDataTable(dataTable.id);
            }
            setMapping({
                dataTable: dataTable?.id,
                dataColumns: Object.fromEntries(
                    data.preservedGlobalColumnTypes.map((preserved) => [getGlobalColumnTypeKey(preserved)])
                ),
                groupTypes: Object.fromEntries(
                    data.preservedGlobalGroupTypeTypes.map((preserved) => [
                        `${preserved.id}_${preserved.globalTypeSubKey}`,
                    ])
                ),
            });
        }
    }, [arrived, data, dataTable, setMapping]);

    const handleSubmit = (values: Mapping) => {
        setLoading(true);
        const dataTablePromise =
            values.dataTable &&
            data &&
            updateConfigurations("data-table", data.preservedGlobalTableType.id, {
                dataTable: values.dataTable,
                isDataTableV2: true,
            });
        const dataColumnPromises = Object.entries(values.dataColumns).map(
            ([preservedPk, columnPkOrField]) =>
                columnPkOrField &&
                updateConfigurations(
                    "data-column",
                    preservedPk,
                    typeof columnPkOrField === "number"
                        ? { dataColumn: columnPkOrField }
                        : {
                              field: columnPkOrField.field,
                              type: columnPkOrField.type,
                              isDataTableV2: true,
                          }
                )
        );
        const groupTypePromises = Object.entries(values.groupTypes).map(
            ([preservedPk, groupTypePkOrField]) =>
                groupTypePkOrField &&
                updateConfigurations("group-type", preservedPk, {
                    groupType: groupTypePkOrField,
                })
        );
        Promise.all([dataTablePromise, ...dataColumnPromises, ...groupTypePromises])
            .then(() => {
                setLoading(false);
                setShowSuccessDialog(true);
            })
            .catch(() => informUser({ message: fm(messages.errorUpdatingWidget), type: "error" }));
    };

    if (waiting || !arrived || !mapping) {
        return (
            <Dialog title={title.toString()} onClose={onClose} open={open} maxWidth="lg">
                <Stack direction="row" justifyContent="center" alignItems="center" width="100%" py={10}>
                    <Stack>
                        <CircularProgress />
                    </Stack>
                </Stack>
            </Dialog>
        );
    }

    return (
        <>
            <Dialog title={title.toString()} onClose={onClose} open={open} maxWidth="lg">
                <DialogTitle>
                    <Typography variant="h6">{title.toString()}</Typography>
                </DialogTitle>
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    initialValues={mapping}
                    enableReinitialize
                >
                    {(formikProps) => {
                        return (
                            <Form>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant="body1">{fm(messages.field)}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1">{fm(messages.description)}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">{fm(messages.type)}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">{fm(messages.source)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    {data && data.preservedGlobalTableType && (
                                        <ConfigurationRow
                                            fieldName={getGlobalTypeName(data.preservedGlobalTableType)}
                                            description={data.preservedGlobalTableType.description}
                                            type={fm(messages.table).toString()}
                                        >
                                            {dataTable ? (
                                                <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                                    {dataTable.name}
                                                </Typography>
                                            ) : (
                                                <Autocomplete
                                                    disablePortal
                                                    defaultValue={{
                                                        value: dataTables[0].id,
                                                        label: dataTables[0].name,
                                                    }}
                                                    options={dataTables
                                                        .filter(
                                                            (dt) =>
                                                                !dt.globalTypeKey ||
                                                                dt.globalTypeKey === data.preservedGlobalTableType.key
                                                        )
                                                        .map((dt) => ({
                                                            label: dt.name,
                                                            value: dt.id,
                                                        }))}
                                                    renderInput={(params) => (
                                                        <TextField {...params} required name="dataTable" />
                                                    )}
                                                    onChange={(_e, newValue) =>
                                                        newValue && setSelectedDataTable(newValue.value)
                                                    }
                                                />
                                            )}
                                        </ConfigurationRow>
                                    )}
                                    {data &&
                                        (selectedDataTable ? (
                                            <ColumnSelection
                                                preservedGlobalColumnTypes={data.preservedGlobalColumnTypes}
                                                dataTable={selectedDataTable}
                                                setMapping={setMapping}
                                            />
                                        ) : (
                                            <ColumnSelectionSkeleton
                                                preservedGlobalColumnTypes={data.preservedGlobalColumnTypes}
                                            />
                                        ))}
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction="row" justifyContent="flex-end" paddingTop={1} columnGap={1}>
                                        <Stack>
                                            <Button
                                                color="secondary"
                                                variant="text"
                                                disabled={loading}
                                                onClick={onClose}
                                            >
                                                {fm(globalMessages.cancelButton)}
                                            </Button>
                                        </Stack>
                                        <Stack>
                                            <Button type="submit" disabled={loading || !formikProps.isValid}>
                                                {fm(globalMessages.saveButton)}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
            {showSuccessDialog && (
                <SuccessDialog
                    open={showSuccessDialog}
                    onClose={() => {
                        setShowSuccessDialog(false);
                        onClose();
                    }}
                    successTitle={fm(messages.updatedFields).toString()}
                    onContinue={() => window.location.reload()}
                    successSubTitle={fm(messages.refreshToSeeChanges).toString()}
                    continueButtonText={fm(messages.refresh).toString()}
                />
            )}
        </>
    );
};

export default FieldConfigurationDialog;
