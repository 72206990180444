import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { typeGuards } from "@ignite-analytics/general-tools";
import { CATEGORY_GT } from "@ignite-analytics/global-types";
import { useIntl } from "react-intl";

import { useTransactionsIndex } from "../useElasticIndexWithType";

import messages from "./messages";

const findLowestCategoryField = (elasticFields: ElasticField[]) => {
    let lowestEncounteredLevel = 1;
    let lowestCategoryField;

    const regEx = new RegExp("[a-zA-Z]+_[a-zA-Z]+_[0-9]+_l[0-9]+_[a-zA-Z]+");

    elasticFields.forEach((field) => {
        const splits = field.fieldId.split("_");
        if (splits.length === 5 && field.globalTypeKey === CATEGORY_GT && regEx.test(field.fieldId)) {
            const lvl = +splits[3].slice(1);
            if (lvl >= lowestEncounteredLevel) {
                lowestCategoryField = field;
                lowestEncounteredLevel = lvl;
            }
        }
    });
    return lowestCategoryField;
};

export const useTransactionsCategoryFieldByLevel = (lvl: number | "lowest") => {
    const elasticIndex = useTransactionsIndex();
    const { formatMessage: fm } = useIntl();
    const elasticFields = useElasticFields(elasticIndex?.name);
    const categoryField =
        lvl === "lowest"
            ? findLowestCategoryField(elasticFields ?? [])
            : elasticFields?.find(
                  (field) => field.globalTypeKey === CATEGORY_GT && field.fieldId.endsWith(`_l${lvl}_id`)
              );
    if (!typeGuards.isNotNullish(categoryField))
        return new Error(fm(messages.couldntFindGlobalType, { tag: CATEGORY_GT }));

    return categoryField;
};
