import { Filter } from "@ignite-analytics/filters";

import { isAnalysisWidget, Widget } from "@/components/Widgets/interfaces";
import { updateWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";

export const commitUpdatesToCopiedWidget =
    <W extends Widget>(modifiedProperties: Partial<W>, filters?: Filter[]) =>
    (copiedWidget: W) => {
        if (isAnalysisWidget(copiedWidget)) {
            return updateWidget({
                ...copiedWidget,
                ...modifiedProperties,
                xPosition: null,
                yPosition: null,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                filters: filters?.map(({ id, ...rest }) => rest) ?? copiedWidget.filters,
            });
        }
        return new Promise<W>((res) => res(copiedWidget));
    };
