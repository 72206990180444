import { InformationCircle } from "@ignite-analytics/icons";
import { Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import React from "react";

interface Props {
    footerText: string;
    footerTooltip: TooltipProps["title"];
}

// have to make this custom width due to very long text in potential tooltip.
// couldn't get direct sx styling to work with the tooltip.
// this is also how MUI does it in there docs: https://mui.com/material-ui/react-tooltip/#variable-width
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

export const FinancialFooter: React.FC<Props> = ({ footerText, footerTooltip }) => {
    return (
        <Stack direction="row" alignItems="center">
            <CustomWidthTooltip placement="top" title={footerTooltip}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <InformationCircle fontSize="small" />
                    <Typography variant="caption">{footerText}</Typography>
                </Stack>
            </CustomWidthTooltip>
        </Stack>
    );
};
