import { defineMessages } from "react-intl";

const messages = defineMessages({
    dashboards: {
        id: "hooks.useSetBreadcrumbs.dashboards",
        defaultMessage: "Dashboards",
    },
});

export default messages;
