import { gql } from "@/__generated__/gql";
import { useQuery } from "@apollo/client";

const getManyDataTablesQuery = gql(`
    query getManyDataTables {
        getManyDataTables {
            entities {
                ...DataTableFields
            }
        }
    }

    fragment DataTableFields on DataTable {
        id
        name
        globalTypeKey
        elasticIndex
    }
`);

export function useGetManyDataTables() {
    return useQuery(getManyDataTablesQuery);
}
