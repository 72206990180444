import { formatValue } from "@ignite-analytics/locale";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { useMemo } from "react";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

export const useChartConfig = (titleKey: string, period: string, compare: boolean): ChartConfig =>
    useMemo(
        () => ({
            legends: false,
            useAggsAsColumns: period === "year" && !compare,
            optionsPerAgg: [
                {},
                {
                    type: "column",
                    stackMode: "grouped",
                    labelDisplayMode: false,
                    measureUnit: titleKey === "spend" ? 1e6 : 1,
                    tooltipPointFormatter() {
                        return `<b>${
                            this.series.userOptions?.index === 0 ? fm(messages.lastPeriod) : fm(messages.thisPeriod)
                        }:</b> ${formatValue(this.y || 0, 0) || ""}`;
                    },
                    decimals: ["contracts", "suppliers"].includes(titleKey) ? 0 : "auto",
                },
            ],
        }),
        [compare, period, titleKey]
    );
