import { defineMessages } from "react-intl";

const messages = defineMessages({
    dashboardNotFound: {
        id: "dashboardManager.dashboardNotFound",
        defaultMessage: "Dashboard not found",
    },
    dashboardCreatedMsg: {
        id: "dashboardManager.dashboardCreatedMsg",
        defaultMessage: "Dashboard created successfully",
    },
    viewPermissionDenied: {
        id: "dashboardManager.viewPermissionDenied",
        defaultMessage: "You do not have the permission to view the content of this dashboard",
        description: "",
    },
    genericError: {
        id: "dashboardManager.couldNotLoadDashboard",
        defaultMessage: "Something went wrong when loading this dashboard.",
    },
});

export default messages;
