import { Box, Typography } from "@mui/material";
import React from "react";

import { trendArrow } from "@/components/Widgets/Components";

interface Props {
    progress: number;
    showTrendArrow: boolean;
    value: string | number;
    showValue: boolean;
    align: "left" | "right" | "center";
}

/**
 * Component for rendering trend cells in DataTable.
 * Renders either a trend arrow or the value (as text) or both.
 */

const TrendCell: React.FC<Props> = ({ progress, value, showTrendArrow, showValue, align }) => {
    if (showValue && showTrendArrow) {
        return (
            <Box>
                <Box sx={{ alignItems: align }}>
                    <Typography>{value}</Typography>
                </Box>
                <Box sx={{ alignItems: align }}>{trendArrow(progress)}</Box>
            </Box>
        );
    }

    if (showTrendArrow) {
        const alignLeft = align === "left" ? "start" : "center";
        return <Box sx={{ alignItems: align === "right" ? "end" : alignLeft }}>{trendArrow(progress)}</Box>;
    }

    return (
        <Box sx={{ alignItems: align }}>
            <Typography>{value}</Typography>
        </Box>
    );
};

export default TrendCell;
