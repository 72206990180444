import { ChartConfig } from "@ignite-analytics/pivot-charts";

export const CHART_CONFIG: ChartConfig = {
    legends: true,
    optionsPerAgg: [
        { type: "scatter", labelDisplayMode: false, yAxis: { disable: true } },
        {},
        {
            type: "bar",
            labelDisplayMode: "value",
            stackedDataLabels: false,
            alignLabelsInside: true,
            measureUnit: 1e-2,
            yAxis: { disable: true },
        },
    ],
};
