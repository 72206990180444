import { X as CloseIcon } from "@ignite-analytics/icons";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";
import { useAllDashboardCollections } from "@/entities/dashboardCollections";
import { useCreatePartialCustomDashboard } from "@/entities/dashboards";

const TEST_ID_PREFIX = "createDashboardModal-";

interface Props {
    open: boolean;
    redirect?: boolean;
    onClose: () => void;
    setDashboardId?: (id: number) => void;
}

const CreateDashboardModal: React.FC<Props> = ({ open, redirect = true, onClose, setDashboardId }) => {
    const [name, setName] = useState<string | undefined>(undefined);
    const [collection, setCollection] = useState<number | null>(null);
    const collections = useAllDashboardCollections(undefined, {
        service: "dashboards",
    });
    const navigate = useNavigate();
    const createNewDashboardAction = useCreatePartialCustomDashboard();

    const createDashboard = (): void => {
        if (name !== undefined) {
            createNewDashboardAction({
                description: "",
                name,
                dashboardCollection: collection,
            })
                .then((res) => {
                    if (redirect) {
                        navigate(`/dashboard/overview/${res.id}/`);
                    }

                    if (setDashboardId) {
                        setDashboardId(res.id);
                    }
                    onClose();
                })
                .catch((err) => {
                    Sentry.captureException(err, { tags: { app: "dashboards-app" } });
                });
        }
    };

    const handleSelectCollection = (dashboardCollectionId: number) => {
        if (Number.isNaN(dashboardCollectionId)) {
            setCollection(null);
            return;
        }
        setCollection(dashboardCollectionId);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{fm(messages.createNewdashboardModalTitle)}</Typography>
                    <IconButton
                        data-testid={`${TEST_ID_PREFIX}closeButton`}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2} pt={2}>
                    <TextField
                        type="text"
                        label={fm(messages.createDashboardNameField)}
                        name="name"
                        data-testid={`${TEST_ID_PREFIX}nameInput`}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={fm(messages.createDashboardNameHelper).toString()}
                    />
                    {collections ? (
                        <FormControl>
                            <InputLabel>{fm(messages.addToCollection)}</InputLabel>
                            <Select
                                name="collection"
                                label={fm(messages.addToCollection)}
                                onChange={(e) => handleSelectCollection(Number(e.target.value))}
                                data-testid={`${TEST_ID_PREFIX}addToCollectionPicker`}
                            >
                                <MenuItem data-testid={`${TEST_ID_PREFIX}option-no-collection`} value={NaN}>
                                    <Typography>{fm(messages.noCollection)}</Typography>
                                </MenuItem>
                                {collections.map((dashboardCollection) => (
                                    <MenuItem
                                        data-testid={`${TEST_ID_PREFIX}option-${dashboardCollection.name}`}
                                        key={dashboardCollection.id}
                                        value={dashboardCollection.id}
                                    >
                                        <Typography>{dashboardCollection.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <CircularProgress color="primary" />
                    )}
                    <Button
                        disabled={!name}
                        data-testid={`${TEST_ID_PREFIX}createDashboardButton`}
                        onClick={() => createDashboard()}
                    >
                        {fm(messages.createDashboard)}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateDashboardModal;
