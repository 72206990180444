import { BULK_UPDATE, CREATE_ONE, DELETE_ONE, SET_INITIAL, UPDATE_ONE } from "./constants";

import { Widget } from "@/components/Widgets/interfaces";

interface InitialDataType {
    type: typeof SET_INITIAL | typeof BULK_UPDATE;
    data: Widget[];
}
interface WidgetUpdateType {
    type: typeof UPDATE_ONE | typeof DELETE_ONE | typeof CREATE_ONE;
    data: Widget;
    skipRedirectToAnalysis?: boolean;
}
export type WidgetActionType = InitialDataType | WidgetUpdateType;

const isSameWidget = (widget: Widget, widgetInQuestion: Widget) => {
    if (widget.modelName === widgetInQuestion.modelName) {
        if (widget.id === widgetInQuestion.id) return true;
    }
    return false;
};

export const widgetReducer = (state: Widget[], action: WidgetActionType) => {
    switch (action.type) {
        case SET_INITIAL:
            return action.data;
        case UPDATE_ONE:
            return state.map((widget) => (isSameWidget(widget, action.data) ? action.data : widget));
        case BULK_UPDATE:
            return state.map((widget) => action.data.find((W) => isSameWidget(widget, W)) ?? widget);
        case DELETE_ONE:
            return state.filter((widget) => !isSameWidget(widget, action.data));
        case CREATE_ONE:
            return [...state, action.data];
    }
};
