import { usePromiseState, WAITING } from "@ignite-analytics/api-client";
import { ElasticField, useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { hooks, useDebounce, useLastNonNullishValue } from "@ignite-analytics/general-tools";
import { defaultConfig as defaultChartConfig, pivotParser } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { Box } from "@mui/material";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { WidgetError } from "../Components/WidgetError";
import { WidgetLoader } from "../Components/WidgetLoader";
import { getErrorsFromPromise } from "../helpers";
import { AnalysisWidget, WidgetProps } from "../interfaces";

import { asEditableWidget } from "./helpers";
import { useAnalysisQuery } from "./hooks";
import messages from "./messages";
import SaveAsNewModal from "./SaveAsNewModal";

import Chart from "@/components/Charts/Chart";
import PivotTable from "@/components/PivotTable";
import { TABLE_VIEW, VIEW_MODE } from "@/components/Widgets/constants";
import { updateWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";
import { useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useGetDataFromAnalysisService } from "@/hoc/PivotAnalysis/services";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import { useExportWidget } from "@/hooks/useExport";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";
import { useColors } from "@/lib/colors";

type Props = WidgetProps<AnalysisWidget, PivotResponse>;

const AnalysisCard: React.FC<Props> = ({ updateActiveWidget, activeWidget }: Props) => {
    const [dataPromise, setDataPromise] = usePromiseState<PivotResponse>();

    const [exportReady, setExportReady] = useState(false);
    const customExportRef = React.useRef<Highcharts.Chart | HTMLTableElement | HTMLElement | null>(null);

    const fields = useElasticFieldsInContext();
    const colors = useColors();
    const intl = useIntl();
    const informUser = useInformUser();
    const [showSaveAsNewModal, setShowSaveAsNewModal] = useState(false);
    const { widgetDispatch } = useDashboardContextOrThrow();
    const { updateExportFuncForWidget } = useExportDashboardContextOrThrow();

    const analysisQuery = hooks.useLastNonNullishValue(
        useAnalysisQuery(
            activeWidget.modelName === "analysiswidget" ? activeWidget : undefined,
            activeWidget.customDashboard
        )
    );
    const query = useLabelledAnalysisQuery(analysisQuery);
    const filters = useFilters();

    const getPivotGraphql = useGetDataFromAnalysisService();

    // As we are listening for changes to both filters and analysisQuery in updateDataPromise, we need to debounce the execution. Otherwise, we will get two requests for e.g. drilldowns.
    const debouncedSetDataPromiseExecuter = useDebounce(
        useCallback(
            (
                _analysisQuery: AnalysisQuery,
                _fields: ElasticField[],
                _filters: typeof filters,
                _setDataPromise: typeof setDataPromise,
                _getPivotGraphql: typeof getPivotGraphql
            ) => _setDataPromise(_getPivotGraphql(_analysisQuery, _fields, _filters, false)),
            []
        ),
        300
    );

    const updateDataPromise = useCallback(() => {
        if (filters !== undefined && analysisQuery && fields?.length) {
            debouncedSetDataPromiseExecuter(analysisQuery, fields, filters, setDataPromise, getPivotGraphql);
        }
    }, [analysisQuery, debouncedSetDataPromiseExecuter, fields, filters, getPivotGraphql, setDataPromise]);

    useEffect(updateDataPromise, [updateDataPromise]);

    const loading = dataPromise[WAITING];
    const data = useLastNonNullishValue(dataPromise.data);
    const { permissionDeniedMessage, userHasViewAccess, errors } = getErrorsFromPromise(dataPromise);

    const handleSavingDrilldown = useCallback(
        (saveAsNew: boolean) => {
            if (saveAsNew) {
                setShowSaveAsNewModal(true);
                return Promise.resolve();
            }
            return updateWidget({ ...activeWidget, filters: filters ?? [] })
                .then((res) => {
                    widgetDispatch({ data: res, type: "UPDATE_ONE" });
                })
                .catch((error) => {
                    track("Save drilldown failed", { error: error?.response?.data?.detail ?? "" });
                    informUser({ message: fm(messages.saveDrilldownError), type: "error" });
                });
        },
        [activeWidget, filters, widgetDispatch, informUser]
    );

    const handleOnRef = useCallback((el: Highcharts.Chart | HTMLElement | HTMLTableElement | null) => {
        customExportRef.current = el;
        setExportReady(true);
    }, []);

    const parsedData = useMemo(
        () => data && query && pivotParser(intl, data, activeWidget.chartConfiguration, query, colors),
        [activeWidget.chartConfiguration, colors, data, intl, query]
    );

    const exportData = useMemo(
        () =>
            data && query && activeWidget.chartConfiguration.optionsPerAgg.some((series) => series.type === "heatmap")
                ? pivotParser(intl, data, defaultChartConfig, query, colors)
                : parsedData,
        [activeWidget.chartConfiguration.optionsPerAgg, colors, data, parsedData, query, intl]
    );

    const exportFunc = useExportWidget(
        activeWidget.title,
        activeWidget.elasticIndex,
        data ?? undefined,
        query,
        customExportRef
    );

    useEffect(
        function propagateExportFunc() {
            if (!activeWidget.id || !exportReady) return;
            updateExportFuncForWidget(exportFunc, activeWidget.id.toString());
        },
        [activeWidget.id, exportFunc, updateExportFuncForWidget, exportReady]
    );

    useEffect(
        function sendWidgetRenderedAfterLoginEvent() {
            if (data && query && parsedData && exportData) {
                if (window.__analyticsAppLoadTime) {
                    const timeFromLogin = Date.now() - window.__analyticsAppLoadTime;
                    track("Widget Rendered After Login", {
                        loadingTime: timeFromLogin,
                        ...Object.fromEntries(
                            (window.igniteAppRequestTimes ?? []).map((record) => [record.url, record.time])
                        ),
                    });
                    window.__analyticsAppLoadTime = undefined;
                }
            }
        },
        [data, exportData, parsedData, query]
    );

    const handleDrilldown = useCallback(
        (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => {
            updateActiveWidget({ ...activeWidget, ...newSplitItems });
        },
        [activeWidget, updateActiveWidget]
    );

    if (errors?.length && errors[0] !== "Cancel")
        return (
            <WidgetError
                onUpdateData={updateDataPromise}
                activeWidget={activeWidget}
                props={{ permissionDeniedMessage, userHasViewAccess, errors }}
            />
        );

    if (!query || !data) return null;

    return (
        <Box height="100%" width="100%" sx={{ position: "relative" }}>
            {loading && <WidgetLoader />}
            {data &&
                query &&
                parsedData &&
                exportData &&
                (activeWidget[VIEW_MODE] !== TABLE_VIEW ? (
                    <Chart
                        onRef={handleOnRef}
                        title={activeWidget.title}
                        hideTitle
                        analysisQuery={query}
                        data={parsedData}
                        config={activeWidget.chartConfiguration}
                        onDrilldown={handleDrilldown}
                        onSaveDrilldown={handleSavingDrilldown}
                    />
                ) : (
                    <PivotTable
                        onRef={handleOnRef}
                        title={activeWidget.title}
                        data={data}
                        config={activeWidget.chartConfiguration}
                        query={query}
                        onSplitChange={(spec) => {
                            const { rowSplitItems, columnSplitItems } = activeWidget;
                            const updated = update({ rowSplitItems, columnSplitItems }, spec);
                            updateActiveWidget(asEditableWidget({ ...activeWidget, ...updated }));
                        }}
                        onSplitChangeForInsightModal={(splits) => updateActiveWidget({ ...activeWidget, ...splits })}
                        saveWidget={handleSavingDrilldown}
                    />
                ))}
            {showSaveAsNewModal && (
                <SaveAsNewModal
                    open={showSaveAsNewModal}
                    onClose={() => setShowSaveAsNewModal(false)}
                    widgetToCopy={activeWidget}
                    filters={filters}
                    modifiedProperties={{
                        columnSplitItems: activeWidget.columnSplitItems,
                        rowSplitItems: activeWidget.rowSplitItems,
                    }}
                />
            )}
        </Box>
    );
};

export default widgetWrapper(AnalysisCard);
