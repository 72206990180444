import messages from "./messages";

import customMessages from "@/components/AnalysisOptions/Fields/messages";
import { staticFormatMessage } from "@/contexts/IntlContext";

/**
 * Percentages that can be used in {trendArrow}
 */
export const arrowPercentages: number[] = [2.5, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 100, 150, 200, 500];

/**
 * Formatted messages for the various fields.
 * Changing the keys in this object will break backward compatibility.
 */
export const fieldTitleMap = () => ({
    spend: staticFormatMessage(messages.spend),
    suppliers: staticFormatMessage(messages.suppliers),
    contracts: staticFormatMessage(messages.activeContracts),
});

export const getPeriodOptions = () => [
    { text: staticFormatMessage(customMessages.month12), value: "year" },
    { text: staticFormatMessage(customMessages.month3), value: "quarter" },
    { text: staticFormatMessage(customMessages.month1), value: "month" },
];
