import { defineMessages } from "react-intl";

const messages = defineMessages({
    presentationTitle: {
        id: "components.exportModule.presentationTitleLabel",
        defaultMessage: "Title for the presentation:",
    },
    presentationTitleDisclaimer: {
        id: "components.exportModule.presentationTitleDisclaimer",
        defaultMessage: "The filename will contain the title stripped for all special characters (non ascii letters).",
    },
    exportMsg: {
        id: "components.exportModule.exportMsg",
        defaultMessage: "You are now downloading {numCharts} charts in a {format} called {name}",
    },
    downloadButton: {
        id: "components.exportModule.downloadButton",
        defaultMessage: "Download",
    },
    previousButton: {
        id: "components.exportModule.previousButton",
        defaultMessage: "Previous",
    },
    nextButton: {
        id: "components.exportModule.nextButton",
        defaultMessage: "Next",
    },
    stepTitle: {
        id: "components.exportModule.stepTitle",
        defaultMessage: "Set title",
    },
    stepCharts: {
        id: "components.exportModule.stepCharts",
        defaultMessage: "Select analyses",
    },
    stepSummary: {
        id: "components.exportModule.stepSummary",
        defaultMessage: "Summary",
    },
    exportModalHeader: {
        id: "components.exportModule.modalHeader",
        defaultMessage: "Export dashboard",
    },
    chooseFormat: {
        id: "components.exportModule.chooseFormat",
        defaultMessage: "Choose a file format to export to",
    },
    pptx: {
        id: "components.exportModule.pptx",
        defaultMessage: "PowerPoint presentation",
    },
    pdf: {
        id: "components.exportModule.pdf",
        defaultMessage: "PDF document",
    },
    awaitingCharts: {
        id: "components.exportModule.awaitingCharts",
        defaultMessage: "Still loading some charts ({awaitCount})",
        description: "Loading message when not all charts are lodaded",
    },
    potentiallyTooBigExportError: {
        id: "components.exportModule.potentiallyTooBigExportError",
        defaultMessage:
            "This export failed and may be too big to be generated. If this is a case of a big export, please select fewer charts. If not, please contact our support.",
    },
});

export default messages;
