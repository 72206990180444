import { ExclamationTriangle } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

interface SentryFallbackProps {
    resetError: () => void;
}

const WidgetFallback: React.FC<SentryFallbackProps> = ({ resetError }) => {
    return (
        <Stack alignItems="center">
            <ExclamationTriangle />
            <Typography variant="subtitle1">{fm(messages.widgetErrorHeader)}</Typography>
            <Typography variant="body1">{fm(messages.widgetErrorDescription)}</Typography>
            <Button variant="outlined" onClick={resetError}>
                {fm(messages.retry)}
            </Button>
        </Stack>
    );
};
export default WidgetFallback;
