import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { ChartPie as Graph, Table as TableChartIcon } from "@ignite-analytics/icons";
import { SplitItem, toSplitItem } from "@ignite-analytics/pivot-ts";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, TextField } from "@mui/material";
import React, { useState } from "react";

import { arrowPercentages, fieldTitleMap, getPeriodOptions } from "./constants";
import messages from "./messages";

import { AnalysisOptionProps } from "@/components/AnalysisOptions/Fields";
import customMessages from "@/components/AnalysisOptions/Fields/messages";
import {
    CHART_VIEW,
    CUSTOM_PERIOD,
    QUERY_SIZE,
    SELECTED_FIELD,
    TABLE_VIEW,
    VIEW_MODE,
} from "@/components/Widgets/constants";
import { fm } from "@/contexts/IntlContext";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useWithLabels } from "@/hooks/useWithLabels";
import { getValRecursively } from "@/lib/objectHelpers";

export const PercentageLimit = ({ activeOptions, onChange, name }: AnalysisOptionProps) => {
    const [selectedValue, setSelectedValue] = useState<number>(getValRecursively<number>(activeOptions, name));
    return (
        <FormControl fullWidth>
            <InputLabel id="percentagelimit-select-label">{fm(messages.selectArrowPercentage)}</InputLabel>
            <Select
                onChange={(e) => {
                    setSelectedValue(e.target.value as number);
                    if (onChange) {
                        onChange(e.target.value as number);
                    }
                }}
                name={name}
                labelId="percentagelimit-select-label"
                label={fm(messages.selectArrowPercentage)}
                defaultValue={selectedValue}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: "300px",
                        },
                    },
                }}
            >
                {arrowPercentages.map((percentage: number) => {
                    return (
                        <MenuItem key={`${percentage}`} value={percentage / 100}>
                            {`\xB1 ${percentage}%`}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export const CustomPeriod = ({ activeOptions, onChange }: AnalysisOptionProps) => (
    <FormControl fullWidth>
        <InputLabel id="custom-period-select-label">{fm(customMessages.widgetPeriodLabel)}</InputLabel>
        <Select
            onChange={(e) => onChange && onChange(e.target.value)}
            defaultValue={activeOptions[CUSTOM_PERIOD]}
            labelId="custom-period-select-label"
            label={fm(customMessages.widgetPeriodLabel)}
        >
            {getPeriodOptions().map((period) => (
                <MenuItem key={period.value} value={period.value}>
                    {period.text}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const SelectedField = ({ activeOptions, onChange }: AnalysisOptionProps) => (
    <FormControl fullWidth>
        <InputLabel id="selected-field-select-label">{fm(messages.field)}</InputLabel>
        <Select
            name={SELECTED_FIELD}
            onChange={(e) => onChange && onChange(e.target.value)}
            labelId="selected-field-select-label"
            label={fm(messages.field)}
            defaultValue={activeOptions[SELECTED_FIELD]}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: "300px",
                    },
                },
            }}
        >
            {Object.keys(fieldTitleMap()).map((field) => (
                <MenuItem key={field} value={field}>
                    {fieldTitleMap()[field as keyof typeof fieldTitleMap]}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const WidgetViewMode: React.FC<AnalysisOptionProps> = ({ activeOptions, onChange }) => {
    const items = [
        {
            text: fm(messages.tableViewButton).toString(),
            value: TABLE_VIEW,
            icon: TableChartIcon,
        },
        {
            text: fm(messages.chartViewButton).toString(),
            value: CHART_VIEW,
            icon: Graph,
        },
    ].map((item) => ({ ...item, selected: item.value === activeOptions[VIEW_MODE] }));
    return (
        <FormControl fullWidth>
            <InputLabel id="widget-view-select-label">{fm(messages.chooseView)}</InputLabel>
            <Select
                onChange={(e) => onChange && onChange(e.target.value)}
                labelId="widget-view-select-label"
                label={fm(messages.chooseView)}
                defaultValue={activeOptions[VIEW_MODE]}
            >
                {items.map((item) => {
                    const Icon = item.icon;
                    return (
                        <MenuItem key={item.value} value={item.value}>
                            <Stack direction="row">
                                <Icon fontSize="small" />
                                {item.text}
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

/**
 * Render function for querySize setting field
 */
export const FIRST_COLUMN_SIZE = "columnSplitItems.0.size";
const GenericQuerySize = (configKey: string) => {
    const querySizeComponent = ({ activeOptions, onChange }: AnalysisOptionProps) => {
        const configValue = getValRecursively(activeOptions, configKey);
        return (
            <TextField
                onChange={({ target: { value } }) => onChange && onChange(Number(value))}
                type="number"
                name={`['${configKey}']`}
                defaultValue={Array.isArray(configValue) ? configValue[0] : configValue}
                label={fm(messages.numElementsLabel)}
            />
        );
    };
    querySizeComponent.displayName = "GenericQuerySize";
    return querySizeComponent;
};
export const querySize = GenericQuerySize(QUERY_SIZE);
export const firstColumnSize = GenericQuerySize(FIRST_COLUMN_SIZE);

/**
 * Render function for excludeOthers setting field
 */
export const FIRST_SPLIT_ITEM_EXCLUDE_OTHERS = "columnSplitItems.0.excludeOthers";
const SplitItemExcludeOthers = (configKey: string) =>
    function ({ activeOptions, onChange }: AnalysisOptionProps) {
        const value: boolean = getValRecursively(activeOptions, configKey);
        return (
            <FormControlLabel
                control={
                    <Switch
                        name={configKey}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        defaultChecked={value}
                    />
                }
                label={fm(messages.excludeOthers)}
            />
        );
    };

export const firstColumnExcludeOthers = SplitItemExcludeOthers(FIRST_SPLIT_ITEM_EXCLUDE_OTHERS);

export const SplitItemField: React.FC<AnalysisOptionProps> = ({ activeOptions, onChange, name }) => {
    const elasticIndex = useTransactionsIndex()?.name;
    const elasticFields = useElasticFields(elasticIndex);
    const selectedValue = getValRecursively<[SplitItem] | undefined>(activeOptions, name);
    const items =
        useWithLabels(elasticFields?.map((item) => toSplitItem(item)))?.map((item) => ({
            label: item.label,
            value: item?.field,
        })) ?? [];
    return (
        <FormControl fullWidth>
            <InputLabel id="split-item-select-label">{fm(messages.field)}</InputLabel>
            <Select
                onChange={(e) => {
                    const newField = elasticFields?.find((field) => field.field === e.target.value);
                    if (newField) {
                        if (onChange) {
                            onChange([
                                { ...toSplitItem(newField), size: (selectedValue && selectedValue[0]?.size) ?? 25 },
                            ]);
                        }
                        return;
                    }
                    if (onChange) {
                        onChange(undefined);
                    }
                }}
                labelId="split-item-select-label"
                label={fm(messages.field)}
                defaultValue={selectedValue && selectedValue[0]?.field}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: "300px",
                        },
                    },
                }}
            >
                {items.map((item) => (
                    <MenuItem key={item.label} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
