import { X as CloseIcon, Plus as AddIcon, Grid as GridViewOutlinedIcon } from "@ignite-analytics/icons";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "./messages";

import CreateDashboardModal from "@/components/CreateDashboardModal";
import { fm } from "@/contexts/IntlContext";

interface Props {
    open: boolean;
    onClose: () => void;
}

export const NewDashboardModalWrapper: React.FC<Props> = ({ open, onClose }) => {
    const [openCreateDashboardModal, setOpenCreateDashboardModal] = useState(false);

    const navigate = useNavigate();

    const handleRedirectToLibrary = () => navigate(`/library/dashboard/`);

    if (openCreateDashboardModal) {
        return (
            <CreateDashboardModal
                open={openCreateDashboardModal}
                onClose={() => {
                    setOpenCreateDashboardModal(false);
                    onClose();
                }}
            />
        );
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">{fm(messages.modalTitle)}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Stack direction="row">
                    <Typography variant="body2" color="GrayText">
                        {fm(messages.modalDescription)}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack pt={2} rowGap={0.5}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                        p={3}
                        gap={2}
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.container,
                            borderRadius: 1,
                            cursor: "pointer",
                            "&:hover": { backgroundColor: (theme) => theme.palette.primary.hover },
                        }}
                        onClick={handleRedirectToLibrary}
                    >
                        <GridViewOutlinedIcon />
                        <Typography variant="body1">{fm(messages.useIgnitesDashboardLibrary)}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                        p={3}
                        gap={2}
                        onClick={() => setOpenCreateDashboardModal(true)}
                        sx={{
                            border: "1px solid lightgray",
                            borderRadius: 1,
                            cursor: "pointer",
                            "&:hover": { backgroundColor: (theme) => theme.palette.primary.hover },
                        }}
                    >
                        <AddIcon />
                        <Typography variant="body1">{fm(messages.createEmptyDashboard)}</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
