import { FeatureToggleContainer } from "@ignite-analytics/feature-toggle";
import {
    Bookmark as BookmarkIcon,
    Clone as ContentCopyIcon,
    Trash as DeleteIcon,
    Pen as EditIcon,
    Home,
    User,
} from "@ignite-analytics/icons";
import { Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "./messages";

import { updateOrCreateHomeDashboardRelation } from "@/containers/CustomDashboardPage/DashboardOverview/services";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import { CustomDashboardListObject, copyDashboard } from "@/entities/dashboards";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    clickedDashboard: CustomDashboardListObject | undefined;
    hasDashboardChangePermission: boolean;
    hasSpecificDashboardChangePermission: boolean;
    hasModelGuardChangePermission: boolean;
    setAnchorEl: (element: HTMLElement | null) => void;
    handleClickRename: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    setOpenPermissionModal: (open: boolean) => void;
    setOpenDeleteModal: (open: boolean) => void;
    onClose?: (e) => void;
}

/**
 * The `DashboardOptionsButton` component is a menu that displays various options for managing a
 * dashboard, such as renaming, duplicating, managing access, setting as home dashboard, publishing to
 * library, and deleting.
 * Please make sure to use it only once per component or else there will extreme shadow in the boundary
 * if its rendered multile times or rendered in a loop.
 * @param  - - `open`: a boolean indicating whether the menu is open or not
 * @returns The DashboardOptionsButton component is being returned.
 */
const DashboardOptionsButton: React.FC<Props> = ({
    open,
    anchorEl,
    clickedDashboard,
    setAnchorEl,
    handleClickRename,
    setOpenPermissionModal,
    setOpenDeleteModal,
    onClose,
    hasDashboardChangePermission,
    hasSpecificDashboardChangePermission,
    hasModelGuardChangePermission,
}) => {
    const user = useOryUserIdOrThrow();
    const navigate = useNavigate();
    const informUser = useInformUser();

    const handleClickDuplicate = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        if (user && clickedDashboard) {
            copyDashboard(clickedDashboard.id)
                .then((res) => navigate(`/dashboard/overview/${res.id}/`))
                .catch(() => {
                    informUser({ message: fm(messages.copyDashboardError), type: "error" });
                });
        }
        setAnchorEl(null);
    };

    const handleClickManageAccess = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenPermissionModal(true);
        setAnchorEl(null);
    };

    const handleClickSetAsHomeDashboard = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        if (clickedDashboard) {
            updateOrCreateHomeDashboardRelation(user, clickedDashboard?.id)
                .then(() => {
                    setAnchorEl(null);
                })
                .catch(() => {
                    informUser({ message: fm(messages.failedToUpdateHomeDashboard), type: "error" });
                });
        }
    };

    const handleClickPublishToLibrary = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        if (!clickedDashboard) return;
        navigate(`/library/dashboard/publish/${clickedDashboard.id}/`);
        setAnchorEl(null);
    };

    const handleClickDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
        setAnchorEl(null);
    };
    const specificDashboardsItems = hasSpecificDashboardChangePermission
        ? [
              <MenuItem onClick={handleClickRename} key="rename">
                  <Stack direction="row" gap={1}>
                      <EditIcon fontSize="small" />
                      {fm(globalMessages.rename)}
                  </Stack>
              </MenuItem>,
              hasModelGuardChangePermission && (
                  <MenuItem onClick={handleClickManageAccess} key="share">
                      <Stack direction="row" gap={1}>
                          <User fontSize="small" />
                          {fm(globalMessages.share)}
                      </Stack>
                  </MenuItem>
              ),
              <MenuItem onClick={handleClickDelete} key="delete">
                  <Stack direction="row" gap={1}>
                      <DeleteIcon fontSize="small" />
                      {fm(globalMessages.delete)}
                  </Stack>
              </MenuItem>,
              <FeatureToggleContainer featureKey="publish-dashboards-to-the-library" key="library">
                  <MenuItem onClick={handleClickPublishToLibrary}>
                      <Stack direction="row" gap={1}>
                          <BookmarkIcon fontSize="small" />
                          {fm(messages.publishToLibrary)}
                      </Stack>
                  </MenuItem>
              </FeatureToggleContainer>,
          ]
        : [];

    const dashboardChangePermissionItems = hasDashboardChangePermission
        ? [
              <MenuItem onClick={handleClickDuplicate} key="duplicate">
                  <Stack direction="row" gap={1}>
                      <ContentCopyIcon fontSize="small" />
                      {fm(globalMessages.duplicate)}
                  </Stack>
              </MenuItem>,
          ]
        : [];
    const menuItems = [
        ...specificDashboardsItems,
        ...dashboardChangePermissionItems,
        <MenuItem onClick={handleClickSetAsHomeDashboard} key="home">
            <Stack direction="row" gap={1}>
                <Home fontSize="small" />
                {fm(messages.setAsHome)}
            </Stack>
        </MenuItem>,
    ];

    return (
        <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
            {menuItems}
        </Menu>
    );
};

export default DashboardOptionsButton;
