import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getLocale } from "@ignite-analytics/locale";
import { getDesignTokens } from "@ignite-analytics/theme";
import { track } from "@ignite-analytics/track";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { /* BrowserRouter,  */ Router } from "react-router-dom";
import { CompatRouter, Route, Routes } from "react-router-dom-v5-compat";

import { FatalErrorBoundary } from "./components/FatalErrorBoundary";
import { OryUserContextProvider } from "./contexts/oryUserContext";
import SessionContextProvider from "./contexts/SessionContextProvider";
import { EntitiesContextProvider } from "./entities";
import { errorLink } from "./errorLink";
import { useOnUnhandledRejectionReporter } from "./hooks";
import { MicroAppMountValues } from "./types";

import CustomDashboardPage from "@/containers/CustomDashboardPage";
import { BasePathProvider } from "@/contexts/basePathContext";
import { IntlContext } from "@/contexts/IntlContext";

const uri = process.env.REACT_APP_GRAPHQL_ROUTER_URL as string;
const graphqlClient = createGraphqlClient(uri, errorLink);
const queryClient = new QueryClient();

const App: React.FC<MicroAppMountValues> = ({
    sessionContext,
    history = createBrowserHistory(),
    locale = getLocale(),
    theme = "ignite-riddle",
    path,
}) => {
    useEffect(() => track("Dashboards: App loaded"), []);
    useOnUnhandledRejectionReporter();
    return (
        <IntlContext locale={locale}>
            <FatalErrorBoundary>
                <Router history={history}>
                    <CompatRouter>
                        <Routes>
                            <Route
                                path="*"
                                element={
                                    <>
                                        <CssBaseline />
                                        <OryUserContextProvider>
                                            <ReactQueryClientProvider client={queryClient}>
                                                <ApolloProvider client={graphqlClient}>
                                                    <BasePathProvider path={path ?? "/"}>
                                                        <ThemeProvider
                                                            theme={createTheme(getDesignTokens(theme, "light"))}
                                                        >
                                                            <SessionContextProvider sessionContext={sessionContext}>
                                                                <EntitiesContextProvider>
                                                                    <CustomDashboardPage />
                                                                </EntitiesContextProvider>
                                                            </SessionContextProvider>
                                                        </ThemeProvider>
                                                    </BasePathProvider>
                                                </ApolloProvider>
                                            </ReactQueryClientProvider>
                                        </OryUserContextProvider>
                                    </>
                                }
                            />
                        </Routes>
                    </CompatRouter>
                </Router>
            </FatalErrorBoundary>
        </IntlContext>
    );
};
export default App;
