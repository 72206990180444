import { useVisible } from "@ignite-analytics/general-tools";
import { Box } from "@mui/material";
import React from "react";

import { getWidgetComponentByType } from "@/components/Widgets";
import { widgetToExportIndex } from "@/components/Widgets/helpers";
import { Widget, WidgetPreHOCProps } from "@/components/Widgets/interfaces";
import { DELETE_ONE, UPDATE_ONE } from "@/containers/CustomDashboardPage/DashboardContext/constants";
import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { WidgetCard } from "@/hoc/widgetWrapper/style";

interface DashboardWidgetProps<WType> {
    widget: WType;
    dashboardId: number;
    exporting: boolean;
    widgetHandler: (
        type: "UPDATE_ONE" | "DELETE_ONE" | "CREATE_ONE" | "BULK_UPDATE",
        widgetToHandle: Widget[]
    ) => Promise<void>;
}

export const DASHBOARD_WIDGET_TEST_ID = "dashboardWidgetBox";
const DashboardWidget = <W extends Widget>({
    widget,
    dashboardId,
    widgetHandler,
    exporting,
}: DashboardWidgetProps<W>) => {
    const widgetType = getWidgetComponentByType(widget.type);
    const WidgetComponent = widgetType as unknown as React.ComponentType<WidgetPreHOCProps<W>>;
    const index = widgetToExportIndex(widget);
    const elasticIndices = useAllElasticIndices();

    const { visible, setElement } = useVisible();

    // Stop widgets from being rendered before the elastic indices are loaded
    if (elasticIndices === undefined) return null;

    const onUpdate = (newWidget: W | undefined) => {
        if (!newWidget) return;
        widgetHandler(UPDATE_ONE, [newWidget]);
    };

    const onDelete = () => {
        widgetHandler(DELETE_ONE, [widget]);
    };

    return (
        <Box
            data-testid={`${DASHBOARD_WIDGET_TEST_ID}-${widget.id}`}
            ref={setElement}
            sx={{ width: "100%", height: "100%" }}
        >
            {visible || exporting ? (
                <WidgetComponent
                    widget={widget}
                    dashboardId={dashboardId}
                    index={index}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    title={widget.title}
                    initialFilters={widget.filters}
                />
            ) : (
                <WidgetCard />
            )}
        </Box>
    );
};

export default React.memo(DashboardWidget);
