import moment from "moment";

/**
 * Returns the epoch timestamp in GMT in the format YYYY-MM-DD
 * Ignores timezone
 * This function should only be used to DATE, not DATETIME
 */
export function formatEpochTimestampToDateString(timestamp: number) {
    return moment(timestamp).utc().format("YYYY-MM-DD");
}
