import { X as CloseIcon } from "@ignite-analytics/icons";
import { Autocomplete, Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import messages from "./messages";

import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useCreateDashboardCollectionAction } from "@/entities/dashboardCollections";
import {
    CustomDashboardListObject,
    useEditableDashboards,
    useUpdateMultipleCustomDashboards,
} from "@/entities/dashboards";

interface Props {
    open: boolean;
    onClose: () => void;
}

const CREATE_COLLECTION_MODAL_TESTIS_PREFIX = "createCollectionModal-";

export const NewCollectionModal: React.FC<Props> = ({ open, onClose }) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedDashboards, setSelectedDashboards] = useState<CustomDashboardListObject[]>([]);
    const dashboards = useEditableDashboards();
    const createDashboardCollection = useCreateDashboardCollectionAction();
    const updateDashboards = useUpdateMultipleCustomDashboards();
    const informUser = useInformUser();

    // Returns the different categories/possible dashboard modules and their corresponding dashboards on the left-hand card
    const handleCreate = () => {
        createDashboardCollection({ name: inputValue, precedence: 0 }, { service: "dashboards" })
            .then((result) => {
                informUser({ message: fm(messages.success), type: "success" });
                const dashboardsToUpdate: CustomDashboardListObject[] = [];
                if (result?.id) {
                    selectedDashboards.forEach((dashboard) => {
                        const newDashboard = dashboard;
                        newDashboard.dashboardCollection = result.id;
                        dashboardsToUpdate.push(newDashboard);
                    });
                }

                setSelectedDashboards([]);
                return updateDashboards(dashboardsToUpdate).then(() => {
                    onClose();
                });
            })
            .catch(() =>
                informUser({
                    type: "error",
                    message: fm(messages.error),
                })
            );
    };

    const dashboardOptions: { label: string; dashboard: CustomDashboardListObject }[] = dashboards
        .filter((dashboard) => !selectedDashboards.map((db) => db.id).includes(dashboard.id))
        .map((dashboard) => ({
            label: dashboard.name,
            dashboard,
        }));

    return (
        <Dialog open={open} onClose={onClose}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography variant="h5">{fm(messages.createCollectionPrompt)}</Typography>
                <IconButton data-testid={`${CREATE_COLLECTION_MODAL_TESTIS_PREFIX}closeButton`} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <DialogContent>
                <Stack pt={2} gap={2}>
                    <TextField
                        data-testid={`${CREATE_COLLECTION_MODAL_TESTIS_PREFIX}nameInput`}
                        type="text"
                        label={fm(messages.collectionNameLabel)}
                        name="name"
                        fullWidth
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                    />
                    <Typography>{fm(messages.dashboardsToIncludePrompt)}</Typography>
                    <Autocomplete
                        data-testid={`${CREATE_COLLECTION_MODAL_TESTIS_PREFIX}dashboardPicker`}
                        options={dashboardOptions}
                        onChange={(_, values) => {
                            const valueDashboards = values.map((value) => value.dashboard);
                            setSelectedDashboards(valueDashboards);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={fm(messages.addDashboardToCollection)} />
                        )}
                        multiple
                    />
                    <Button
                        disabled={!inputValue}
                        data-testid={`${CREATE_COLLECTION_MODAL_TESTIS_PREFIX}createButton`}
                        color="primary"
                        onClick={handleCreate}
                    >
                        {fm(messages.createButtonLabel)}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
