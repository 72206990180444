import { defineMessages } from "react-intl";

const messages = defineMessages({
    oldFinancialData: {
        id: "automatedRecommendationsPage.oldFinancialData",
        defaultMessage: "Missing financial data for {year}, using {lastYearAccountYear} instead.",
    },
    RoaInsteadOfRoce: {
        id: "automatedRecommendationsPage.RoaInsteadOfRoce",
        defaultMessage: "Using ROA instead of ROCE.",
    },
    missingFinancialData: {
        id: "automatedRecommendationsPage.missingFinancialData",
        defaultMessage: "Missing financial data.",
    },
});

export default messages;
