import { Api } from "@ignite-analytics/api-client";

import { Widget } from "@/components/Widgets/interfaces";

/**
 *
 *  Creates a new widget for a dashboard
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {number}    dashboardId: Id of the associated dashboard
 *  @param  {Widget}    widget: The widget data
 *
 *  @returns {Promise<Widget>}
 *
 */
export function createWidget(dashboardId: number, widget: Partial<Widget>): Promise<Widget & { id: number }> {
    const url = `/dashboards/${dashboardId}/widgets/`;
    const widgetWithoutPosition = { ...widget, xPosition: undefined, yPosition: undefined };
    return Api.post(url, widgetWithoutPosition, { service: "dashboards" }).then((res) => {
        return { ...res, isNew: true };
    });
}

/**
 *
 *  Deletes a specific widget from a custom dashboard page
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {number}    dashboardId: Id of the associated dashboard
 *  @param  {number}    widgetId: The widget id
 *
 *  @returns {Promise<void>}
 *
 */
export function deleteWidget(widget: Widget): Promise<Widget> {
    const url = `/dashboards/widgets/${widget.modelName}/${widget.id}/`;
    return Api.delete(url, undefined, { service: "dashboards" });
}

/**
 *
 *  Updates a specific analysis
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {number}    dashboardId: Id of the associated dashboard
 *  @param  {Widget}    widget: The widget data
 *
 *  @returns {Promise<Widget>}
 *
 */
export function updateWidget<W extends Widget = Widget>(widget: W): Promise<W> {
    const url = `/dashboards/widgets/${widget.modelName}/${widget.id}/`;
    return Api.put(url, widget, { service: "dashboards" });
}
