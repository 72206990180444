export const readFile = (
    file: Blob,
    method: "readAsArrayBuffer" | "readAsBinaryString" | "readAsDataURL" | "readAsText" = "readAsArrayBuffer"
): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);

        reader.onerror = reject;

        reader[method](file);
    });
};
