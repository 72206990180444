import { useQuery } from "@apollo/client";
import { useState } from "react";

import { gql } from "@/__generated__/gql";
import { UserFragment } from "@/__generated__/graphql";

const getUsersQuery = gql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...User
            }
        }
    }

    fragment User on RoleUser {
        id
        firstName
        lastName
        email
    }
`);

export function useUsers(limit = 10, userIds?: string[]) {
    const [searchTerm, setSearchTerm] = useState("");
    const { data, loading, error } = useQuery(getUsersQuery, {
        variables: {
            input: {
                userIds,
                terms: searchTerm,
                limit,
            },
        },
    });

    const users: UserFragment[] = data?.getUsers.result || [];

    return {
        setSearchTerm,
        users,
        loading,
        error,
    };
}

export function useUser(id: string) {
    const { data, loading, error } = useQuery(getUsersQuery, {
        variables: {
            input: {
                userIds: [id],
                limit: 1,
            },
        },
    });

    const user = data?.getUsers.result[0];

    return { user, loading, error };
}
