import { Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { getHomeDashboardRelation } from "../../../services";

import messages from "./messages";

import CreateDashboardModal from "@/components/CreateDashboardModal";
import { GetStartedCard } from "@/components/GetStartedCard";
import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";

export const HomeCard: React.FC = () => {
    const backwardCompatibleUserId = useOryUserIdOrThrow();
    const navigate = useNavigate();

    const [displayMessage, setDisplayMessage] = useState(false);
    const [openCreateDashboardModal, setOpenCreateDashboardModal] = useState(false);

    useEffect(() => {
        let shouldRedirect = true;

        if (backwardCompatibleUserId) {
            getHomeDashboardRelation(backwardCompatibleUserId)
                .then((result) => {
                    if (shouldRedirect) navigate(`/dashboard/overview/${result.dashboard}/`, { replace: true });
                })
                .catch((e) => {
                    // if the request was cancelled, don't show the message
                    if (axios.isCancel(e)) return;
                    setDisplayMessage(true);
                });
        }

        return () => {
            // Don't redirect after the component unmounts
            // In case the request is slow and the user has clicked away before it arrives
            shouldRedirect = false;
        };
    }, [backwardCompatibleUserId, navigate]);

    return displayMessage ? (
        <Stack height="100%" alignItems="center" pt={20}>
            <Typography variant="h6">{fm(messages.getStarted)}</Typography>
            <Stack p={4} gap={4}>
                <Stack direction="row" justifyContent="start" gap={4}>
                    <GetStartedCard
                        type="emptyDashboard"
                        header={fm(messages.createEmptyDashboard).toString()}
                        description={fm(messages.createEmptyDashboardDescription).toString()}
                        onClick={() => setOpenCreateDashboardModal(true)}
                    />
                    <GetStartedCard
                        type="dashboardLibrary"
                        header={fm(messages.dashboardFromLibrary).toString()}
                        description={fm(messages.dashboardFromLibraryDescription).toString()}
                    />
                </Stack>
                <Stack direction="row" justifyContent="start" gap={4}>
                    <GetStartedCard
                        type="customAnalysis"
                        header={fm(messages.customAnalysis).toString()}
                        description={fm(messages.customAnalysisDescription).toString()}
                    />
                    <GetStartedCard
                        type="uploadData"
                        header={fm(messages.uploadDataHeader).toString()}
                        description={fm(messages.uploadDataDescription).toString()}
                    />
                </Stack>
            </Stack>
            <CreateDashboardModal open={openCreateDashboardModal} onClose={() => setOpenCreateDashboardModal(false)} />
        </Stack>
    ) : null;
};
