import { defineMessages } from "react-intl";

const messages = defineMessages({
    widgetWidthLabel: {
        id: "components.widgets.components.widgetWidthLabel",
        defaultMessage: "Set width of widget",
    },
    widgetWidthPH: {
        id: "components.widgets.components.widgetWidthPH",
        defaultMessage: "Select width here",
    },
    trendArrowInfinity: {
        id: "components.widgets.components.trendArrowInfinity",
        defaultMessage: "No spend in previous period",
    },
    trendArrowTooltip: {
        id: "components.widgets.components.trendArrowTooltip",
        defaultMessage: "Change since last period ({period} interval)",
    },
});

export default messages;
