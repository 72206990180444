import { ElasticField, isDateField } from "@ignite-analytics/elastic-fields";
import { Filter, STATIC_DATE_TYPE, StaticDateFilter, isValidDatePeriod } from "@ignite-analytics/filters";
import {
    CONTRACT_RELATION_GT,
    SUPPLIER_GT,
    TRANSACTION_DATE_GT,
    TRANSACTION_VALUE_GT,
} from "@ignite-analytics/global-types";
import { AnalysisQuery, BottomRow, PivotResponse, ValueAggregationItem } from "@ignite-analytics/pivot-ts";

import { DATE_FORMAT } from "../constants";
import { Options } from "../interfaces";

import { localeMoment } from "@/contexts/IntlContext";
import { formatEpochTimestampToDateString } from "@/lib/dateHelpers";

const getFiltersForFieldHistory = (
    maxTransactionDate: string,
    minTransactionDate: string,
    period: string,
    transactionDateField: string,
    transactionDateFilter?: StaticDateFilter
): { maxDateFilter: Filter; previousFilter: Filter; thisFilter: Filter } => {
    let to = localeMoment(transactionDateFilter?.end ?? maxTransactionDate);
    let from = localeMoment(transactionDateFilter?.start ?? minTransactionDate);
    if (period === "quarter") {
        to = to.endOf("quarter");
        from = from.startOf("quarter");
    }
    const startPrev = to.clone().subtract(23, "months").startOf("month");
    const firstDate = transactionDateFilter && from.isAfter(startPrev) ? from : startPrev;
    const thisFilter: StaticDateFilter = {
        filterType: STATIC_DATE_TYPE,
        fieldId: transactionDateField,
        field: transactionDateField,
        type: "date",
        start: to.clone().subtract(11, "months").startOf("month").format(DATE_FORMAT),
        end: to.clone().endOf("month").format(DATE_FORMAT),
    };
    const previousFilter: StaticDateFilter = {
        filterType: STATIC_DATE_TYPE,
        fieldId: transactionDateField,
        field: transactionDateField,
        type: "date",
        start: startPrev.format(DATE_FORMAT),
        end: to.clone().subtract(12, "months").endOf("month").format(DATE_FORMAT),
    };
    const maxDateFilter: StaticDateFilter = {
        filterType: STATIC_DATE_TYPE,
        fieldId: transactionDateField,
        field: transactionDateField,
        type: "date",
        start: firstDate.format(DATE_FORMAT),
        end: to.clone().endOf("month").format(DATE_FORMAT),
    };
    return { maxDateFilter, previousFilter, thisFilter };
};

const fieldToValueAggItem = (
    selectedField: string,
    supplierField: string,
    contractsField: string,
    transactionValueField: string
): ValueAggregationItem => {
    switch (selectedField) {
        case "suppliers":
            return { field: supplierField, type: "integer", aggregation: "cardinality" };
        case "contracts":
            return { field: contractsField, type: "integer", aggregation: "cardinality" };
        default:
            return { field: transactionValueField, type: "float", aggregation: "sum" };
    }
};

export const getFieldHistoryQuery = (
    filters: Filter[],
    { selectedField = "spend", period = "month", query, displayComparison = true }: Options,
    fields: ElasticField[]
): AnalysisQuery | undefined => {
    if (query) return query;
    const transactionDateField =
        fields.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT)?.field || "transaction_date";
    const transactionDateFilter = filters.find((f): f is StaticDateFilter => f.fieldId === transactionDateField);
    const transactionValueField =
        fields.find((field) => field.globalTypeKey === TRANSACTION_VALUE_GT)?.field || "transaction_value";
    const contractsField = fields.find((field) => field.globalTypeKey === CONTRACT_RELATION_GT)?.field || "contracts";
    const supplierField = fields.find((field) => field.globalTypeKey === SUPPLIER_GT)?.field || "supplier_id";
    const elasticDateField = fields.find((field) => isDateField(field) && field.field === transactionDateField);
    const maxRelativeDate =
        elasticDateField && isDateField(elasticDateField) && elasticDateField.maxDateForRelativeFilters?.value
            ? formatEpochTimestampToDateString(elasticDateField.maxDateForRelativeFilters.value)
            : new Date().toISOString();
    const minRelativeDate =
        elasticDateField && isDateField(elasticDateField) && elasticDateField.min?.value
            ? formatEpochTimestampToDateString(elasticDateField.min.value)
            : new Date(2015, 1, 1).toISOString();
    const { maxDateFilter, previousFilter, thisFilter } = getFiltersForFieldHistory(
        maxRelativeDate,
        minRelativeDate,
        period,
        transactionDateField,
        transactionDateFilter
    );
    const valueAgg: ValueAggregationItem = fieldToValueAggItem(
        selectedField,
        supplierField,
        contractsField,
        transactionValueField
    );
    return {
        columnSplitItems:
            period === "year"
                ? []
                : [
                      {
                          field: transactionDateField,
                          type: "date",
                          interval: isValidDatePeriod(period) ? period : "month",
                          unique: false,
                          sortAggIndex: 0,
                          excludeOthers: true,
                          sortOrder: "asc",
                          size: 12,
                      },
                  ],
        rowSplitItems: [],
        valueAggregationItems: [
            {
                field: transactionDateField,
                aggregation: "max",
                type: "date",
                visible: false,
                filters: [maxDateFilter],
            },
            ...(displayComparison || period === "year" ? [{ ...valueAgg, filters: [previousFilter] }] : []),
            { ...valueAgg, filters: [thisFilter] },
        ],
        elasticIndex: fields[0]?.elasticIndex,
    };
};

export const getFieldHistoryAdditionalFilter = (
    filters: Filter[],
    { period = "month" }: Options,
    fields: ElasticField[]
): Filter | undefined => {
    const transactionDateField =
        fields.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT)?.field || "transaction_date";
    const transactionDateFilter = filters.find((f): f is StaticDateFilter => f.fieldId === transactionDateField);
    if (!transactionDateFilter) return undefined;
    const elasticDateField = fields.find((field) => isDateField(field) && field.field === transactionDateField);
    const maxRelativeDate =
        elasticDateField && isDateField(elasticDateField) && elasticDateField.maxDateForRelativeFilters?.value
            ? formatEpochTimestampToDateString(elasticDateField.maxDateForRelativeFilters.value)
            : new Date().toISOString();
    const minRelativeDate =
        elasticDateField && isDateField(elasticDateField) && elasticDateField.min?.value
            ? formatEpochTimestampToDateString(elasticDateField.min.value)
            : new Date(2015, 1, 1).toISOString();

    const { maxDateFilter } = getFiltersForFieldHistory(
        maxRelativeDate,
        minRelativeDate,
        period,
        transactionDateField,
        transactionDateFilter
    );
    return maxDateFilter;
};

export const calculateTrend = (data: PivotResponse, compare: boolean, period: string) => {
    if (!data.rows || data.rows.length === 0) {
        return { trend: 0, current: 0 };
    }
    const { values } = data.rows[0] as BottomRow;

    const lastColVals = values[values.length - 1] as number[];
    const prevColVals = values[values.length - 2] as number[];

    const [current, previous] =
        compare || period === "year" ? lastColVals.slice(1).reverse() : [lastColVals[1], prevColVals && prevColVals[1]];

    const trend = (current - previous) / previous;
    return { trend, current };
};
