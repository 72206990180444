import { defineMessages } from "react-intl";

const messages = defineMessages({
    allDashboards: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardMenuTitle",
        defaultMessage: "Overview",
    },
    customAnalysis: {
        id: "components.navBar.analyzeCustom",
        defaultMessage: "New Analysis",
    },
    homeDashboard: {
        id: "dashboardOverview.dashboardOverviewPage.homeDashboard",
        defaultMessage: "Home dashboard",
    },
    expandMenu: {
        id: "dashboardOverview.dashboardOverviewPage.expandMenu",
        defaultMessage: "Expand dashboards menu",
    },
    collapseMenu: {
        id: "dashboardOverview.dashboardOverviewPage.collapseMenu",
        defaultMessage: "Collapse dashboards menu",
    },
    editAndReorderCollections: {
        id: "dashboardOverview.dashboardOverviewPage.editAndReorderCollections",
        defaultMessage: "Edit personal favourites and reorder collections and dashboards",
    },
    saveChanges: {
        id: "dashboardOverview.dashboardOverviewPage.saveChanges",
        defaultMessage: "Save changes",
    },
    createNewTooltip: {
        id: "dashboardOverview.dashboardOverviewPage.createNewTooltip",
        defaultMessage: "Create new dashboard or dashboard collection",
    },
    createNewDashboard: {
        id: "dashboardOverview.dashboardOverviewPage.createNewDashboard",
        defaultMessage: "Create new dashboard",
    },
    createNewCollection: {
        id: "dashboardOverview.dashboardOverviewPage.createNewCollection",
        defaultMessage: "Create new collection",
    },
});

export default messages;
