import { Box, Stack, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses, LinearProgressProps } from "@mui/material/LinearProgress";
import React from "react";

interface Props {
    color: string;
    progress: number;
    showProgressBar: boolean;
    value: string | number;
    showValue: boolean;
    align: "left" | "right" | "center";
}

/**
 * Component for rendering progress cells in DataTable.
 * Renders either a progress bar or the value (as text) or both.
 */

const LinearProgressWithLabel = (
    props: LinearProgressProps & { progressValue: number; displayValue: string; align: string; overrideColor: string }
) => {
    const { progressValue, displayValue, align, overrideColor } = props;

    return (
        <Stack direction="row" alignItems={align || "center"} justifyContent="center" sx={{ minWidth: "80px" }}>
            <Box sx={{ minWidth: "50%", mr: 1, marginTop: "5px" }}>
                <LinearProgress
                    color="secondary"
                    variant="determinate"
                    value={progressValue}
                    sx={{
                        [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 5,
                            backgroundColor: overrideColor,
                        },
                    }}
                />
            </Box>
            <Box sx={{ minWidth: "40%" }}>
                <Typography variant="textXs" color="text.secondary">
                    {displayValue}
                </Typography>
            </Box>
        </Stack>
    );
};

const ProgressCell: React.FC<Props> = ({ color, progress, value, showProgressBar, showValue, align }) => {
    const progressNum: number = +progress;
    if (showValue && showProgressBar && !Number.isNaN(progressNum) && progressNum !== 0) {
        return (
            <Box>
                <LinearProgressWithLabel
                    overrideColor={color}
                    progressValue={progressNum}
                    displayValue={String(value)}
                    align={align}
                />
            </Box>
        );
    }

    if (showProgressBar && !Number.isNaN(progressNum) && progressNum !== 0) {
        return (
            <Box>
                <LinearProgressWithLabel
                    overrideColor={color}
                    progressValue={progressNum}
                    displayValue={String(value)}
                    align={align}
                />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="textSm">{value}</Typography>
        </Box>
    );
};

export default ProgressCell;
