import { Box, Paper, styled } from "@mui/material";

export const StyledCardHeader = styled(Box)`
    padding-left: 16px;
    justify-content: space-between;
    &.widget-drag-handle {
        cursor: move;
    }
`;

export const WidgetCard = styled(Paper)`
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;
