import { formatValue } from "@ignite-analytics/locale";

import * as Services from "../../services";
import { AggregationBucket, ElasticResponse } from "../interfaces";
import messages from "../messages";
import { RatingTableData, TableColumn } from "../PotentialAndRiskTable/DataTableCell/interfaces";

import { getSource, structureRowObject } from "./potential";

import { fm, staticFormatMessage } from "@/contexts/IntlContext";
import { formatNumber, toPercent } from "@/lib/convert";

// Based on http://www.perbang.dk/rgbgradient/
const colors = [
    "#26AE5F",
    "#29B43F",
    "#38BA2B",
    "#61C12D",
    "#8CC72F",
    "#B9CD32",
    "#D4BF34",
    "#DA9A36",
    "#E07439",
    "#E74C3C",
];
const getColor = (index: number, reversed: boolean) => colors[reversed ? colors.length - index - 1 : index];

export function percentToColorGradient(percent: number, reversed = false) {
    if (percent > 0.9) return getColor(0, reversed);
    if (percent > 0.8) return getColor(1, reversed);
    if (percent > 0.7) return getColor(2, reversed);
    if (percent > 0.6) return getColor(3, reversed);
    if (percent > 0.5) return getColor(4, reversed);
    if (percent > 0.4) return getColor(5, reversed);
    if (percent > 0.3) return getColor(6, reversed);
    if (percent > 0.2) return getColor(7, reversed);
    if (percent > 0.1) return getColor(8, reversed);
    return getColor(9, reversed);
}

const riskHeaders: TableColumn[] = [
    {
        header: fm(messages.columnHeadName),
        accessor: "name",
        sticky: "left",
    },
    {
        header: fm(messages.riskRatingHeader),
        accessor: "rating",
        tooltip: fm(messages.riskTooltip).toString(),
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.liquidityHeader),
        accessor: "currentRatio",
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.ROCEHeader),
        accessor: "roce",
        tooltip: fm(messages.ROCETooltip).toString(),
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.solvencyHeader),
        accessor: "equityRatio",
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.columnHeadNumTransaction),
        accessor: "transactionCount",
        tooltip: fm(messages.transLTMTooltip).toString(),
    },
    {
        header: fm(messages.columnHeadSpend),
        tooltip: fm(messages.spendLTMTooltip).toString(),
        accessor: "spendLast_12Months",
    },
    {
        header: fm(messages.columnHeadLargestCategory),
        accessor: "largestCategory",
    },
];

export const snakeToCamelDataColumn = (string: string) => {
    return string.replaceAll("data_column", "dataColumn");
};
/*
 * Prepares the data for being inserted into a Table component.
 */
export function structureRiskRatings(
    inputData: ElasticResponse<Record<string, unknown>>,
    year: number,
    supplierIdFieldId: string,
    supplierLabelFieldId: string,
    hasCategoryGt?: boolean
): RatingTableData {
    const rawData: AggregationBucket = inputData.aggregations;

    return {
        columns: riskHeaders,
        total: rawData.supplierCount.value,
        rows: rawData.suppliers.buckets.map((supplier) => {
            const supplierSrc = getSource(supplier)._source;
            const supplierName = supplierSrc[snakeToCamelDataColumn(supplierLabelFieldId)];
            const supplierID = supplierSrc[snakeToCamelDataColumn(supplierIdFieldId)];

            let lastYearAccountYear = 0; // Shows star on supplier name if 0
            if (supplier.accountYear) {
                lastYearAccountYear = supplier.accountYear.value;
            }

            // Checking if using ROA
            const usingRoa = supplier.roce == null;

            // Checking if financial data is from same year as finalYear
            const oldFinancialData = lastYearAccountYear < year;

            const categorySrc =
                hasCategoryGt &&
                supplier.categories.buckets.length > 0 &&
                getSource(supplier.categories.buckets[0])._source;
            const categoryLabelField = categorySrc
                ? Object.keys(categorySrc).find((item) => {
                      return item.endsWith("Name");
                  })
                : undefined;
            const category = categoryLabelField
                ? categorySrc[categoryLabelField]
                : supplier.categories.buckets.length > 0 && supplier.categories.buckets[0].key;

            // List of objects (cells) to push to data.row
            const currentRatioScore = supplier.currentRatio?.value && Services.map(supplier.currentRatio.value, 0.5, 2);
            const roceScore = supplier.roce?.value && Services.map(supplier.roce.value, -0.05, 0.15);
            const equityRatio = supplier.equityRatio?.value && Services.map(supplier.equityRatio.value, 0.03, 0.4);
            return {
                name: Services.getTooltip(
                    oldFinancialData,
                    usingRoa,
                    supplierName,
                    lastYearAccountYear,
                    year,
                    supplierID
                ),
                rating: {
                    percent: supplier.riskRating?.value ? supplier.riskRating.value / 100 : 0,
                    value: supplier.riskRating?.value
                        ? formatValue(supplier.riskRating.value, 0, false)
                        : staticFormatMessage(messages.noDataLabel),
                },
                currentRatio: {
                    percent: currentRatioScore ?? 0,
                    value: toPercent(
                        supplier.currentRatio?.value
                            ? supplier.currentRatio.value
                            : staticFormatMessage(messages.noDataLabel),
                        0
                    ),
                    color: percentToColorGradient(currentRatioScore ?? 0),
                },
                roce: structureRowObject(supplier, roceScore, "roce"),
                equityRatio: structureRowObject(supplier, equityRatio ?? 0, "equityRatio"),
                transactionCount: formatNumber(supplier.spendLast_12Months.docCount, 0),
                spendLast_12Months: formatValue(supplier.spendLast_12Months.totalValue.value),
                largestCategory: category,
            };
        }),
    };
}
