import { orderBy } from "lodash-es";

import { DashboardCollection } from "@/entities/dashboardCollections";
import { CustomDashboardListObject } from "@/entities/dashboards";

export const groupDashboardsByCollection = (
    dashboards: CustomDashboardListObject[],
    collections: DashboardCollection[]
) => {
    const groupList: { collection: DashboardCollection; dashboards: CustomDashboardListObject[] }[] = [];
    if (!collections.length) {
        return [{ collection: { name: "other", id: -1, precedence: 0 }, dashboards }];
    }

    orderBy(collections, ["precedence", "name"], ["desc", "asc"]).forEach((collection) => {
        groupList.push({ collection, dashboards: [] });
    });

    const d: CustomDashboardListObject[] = [];
    const others = { collection: { name: "other", id: -1, precedence: 0 }, dashboards: d };

    groupList.forEach((collectionObject) => {
        dashboards.forEach((dashboard) => {
            if (dashboard.dashboardCollection === collectionObject.collection.id) {
                collectionObject.dashboards.push(dashboard);
            } else if (
                dashboard.dashboardCollection === null &&
                !others.dashboards.find((dash) => {
                    return dash.id === dashboard.id;
                })
            ) {
                others.dashboards.push(dashboard);
            }
        });
    });
    return [...groupList, others];
};
