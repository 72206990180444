import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { DASHBOARD_OVERVIEW_MODALS } from "./constants";
import { ContentView } from "./ContentView";
import DashboardOverviewMenu from "./DashboardOverviewMenu";
import { NewCollectionModal } from "./modals/NewCollectionModal";
import { NewDashboardModalWrapper } from "./modals/NewDashboardModalWrapper";

import { TOPBAR_HEIGHT } from "@/lib/globalConstants";

const DashboardOverviewPage: React.FC = () => {
    // Wrapper function to have DashboardContextProvider outside all the dashboards
    const [openMenu, setOpenMenu] = useState(true);
    const [openModal, setOpenModal] = useState<DASHBOARD_OVERVIEW_MODALS>(false);
    const gridRef = useRef<HTMLDivElement>(null);

    useEffect(function unsetTimeTrackingVar() {
        return function cleanUp() {
            // If user moves away from the dashboard module before it has fully loaded, we need to unset the time tracking variable
            window.__analyticsAppLoadTime = undefined;
        };
    }, []);

    return (
        <>
            {openModal === "CREATE_DASHBOARD" && (
                <NewDashboardModalWrapper open={openModal === "CREATE_DASHBOARD"} onClose={() => setOpenModal(false)} />
            )}
            {openModal === "CREATE_COLLECTION" && (
                <NewCollectionModal open={openModal === "CREATE_COLLECTION"} onClose={() => setOpenModal(false)} />
            )}
            <Grid container direction="row">
                <Grid item>
                    <DashboardOverviewMenu setOpenMenu={setOpenMenu} openMenu={openMenu} setOpenModal={setOpenModal} />
                </Grid>
                <Grid ref={gridRef} item xs sx={{ overflowY: "auto", maxHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)` }}>
                    <ContentView openMenu={openMenu} scrollParentRef={gridRef} />
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardOverviewPage;
