import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";

import messages from "@/components/Widgets/messages";
import { fm } from "@/contexts/IntlContext";

interface Props {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteModal: React.FC<Props> = ({ open, onClose, onDelete }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{fm(messages.deleteWidget)}</DialogTitle>
        <DialogContent>
            <Typography>{fm(messages.deleteAnalysisConfirmation)}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} variant="text" color="inherit">
                {fm(messages.cancel)}
            </Button>
            <Button onClick={onDelete} color="error">
                {fm(messages.deleteConfirm)}
            </Button>
        </DialogActions>
    </Dialog>
);

export default DeleteModal;
