import { Button, Card, CardContent, Link, Stack, Typography } from "@mui/material";
import React from "react";

import { AnalysisImage } from "./Images/analysisImage";
import { EmptyDashboardImage } from "./Images/emptyDashboardImage";
import { LibraryImage } from "./Images/libraryImage";
import { UploadImage } from "./Images/uploadImage";
import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

type GetStartedType = "emptyDashboard" | "dashboardLibrary" | "customAnalysis" | "uploadData";

interface LinkProps {
    page: GetStartedType;
    children: React.ReactNode;
}

const ContainerLink: React.FC<LinkProps> = ({ page, children }) => {
    if (page === "dashboardLibrary") {
        return <Link href="/library/dashboard/">{children}</Link>;
    }
    if (page === "customAnalysis") {
        return <Link href="/analytics">{children}</Link>;
    }
    return <Link href="/data/tables/setup/">{children}</Link>;
};

const typeToImage = {
    emptyDashboard: <EmptyDashboardImage />,
    dashboardLibrary: <LibraryImage />,
    customAnalysis: <AnalysisImage />,
    uploadData: <UploadImage />,
};
interface Props {
    type: GetStartedType;
    header: string;
    description: string;
    onClick?: () => void;
}
export const GetStartedCard: React.FC<Props> = ({ type, header, description, onClick }) => {
    return (
        <Card sx={{ width: 500, height: 150, backgroundColor: (theme) => theme.palette.background.paper }}>
            <CardContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {typeToImage[type]}
                    <Stack p={2} width="100%" height="100%">
                        <Typography variant="subtitle1">{header}</Typography>
                        <Typography>{description}</Typography>
                        <Stack direction="row" justifyContent="end">
                            <Stack>
                                {onClick ? (
                                    <Button size="small" color="secondary" onClick={onClick}>
                                        {fm(messages.getStarted)}
                                    </Button>
                                ) : (
                                    <ContainerLink page={type}>
                                        <Button size="small" color="secondary">
                                            {fm(messages.getStarted)}
                                        </Button>
                                    </ContainerLink>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
