import * as ReactGridLayout from "react-grid-layout";

import { Widget } from "@/components/Widgets/interfaces";
import { objectsDiffer } from "@/lib/objectHelpers";

export const widgetToId = (widget: Widget) => `${widget.modelName}-${widget.id}`;

export const widgetToLayout = (widget: Widget): ReactGridLayout.Layout => ({
    i: widgetToId(widget),
    x: widget.xPosition ?? 0,
    y: widget.yPosition ?? Infinity,
    w: widget.width ?? 4,
    h: widget.height ?? 3,
    minW: 2,
    minH: 2,
    resizeHandles: ["se"],
});

export const findChangedWidgets = (widgets: Widget[], layouts: ReactGridLayout.Layout[]) => {
    const layoutMapByWidgetId = layouts.reduce<Record<string, ReactGridLayout.Layout>>(
        (state, next) => ({ ...state, [next.i]: next }),
        {}
    );
    const widgetLayoutMergeList = widgets.map((widget) => {
        const layoutMatch = layoutMapByWidgetId[widgetToId(widget)];
        if (!layoutMatch) return null;
        // Assemble new widget with data from Layout
        const { x, y, w, h } = layoutMatch;
        const newWidget: Widget = { ...widget, xPosition: x, yPosition: y, width: w, height: h };
        if (objectsDiffer(newWidget, widget, "xPosition", "yPosition", "width", "height")) {
            return newWidget;
        }
        return null;
    });
    // Find which widgets have changes when taking into account layout change
    const changedWidgets = widgetLayoutMergeList.filter((newWidget): newWidget is Widget => newWidget !== null);
    return changedWidgets;
};
export const generateLayout = (layout: ReactGridLayout.Layout[]): ReactGridLayout.Layouts => {
    const smallLayout = layout.map((item) => ({ ...item, w: 2, h: 4, static: true }));
    return { lg: layout, md: layout, sm: smallLayout, xs: smallLayout, xxs: smallLayout };
};
export const buildNewLayout = (currentLayouts: ReactGridLayout.Layout[], widgets: Widget[]) => {
    const currentLayoutIds = currentLayouts.map((l) => l.i);
    const widgetIds = widgets.map((widget) => widgetToId(widget));
    const layoutsNotInWidgets = currentLayouts.filter((layout) => {
        return widgetIds.includes(layout.i);
    });
    const widgetsNotInLayout = widgets.filter((widget) => {
        return !currentLayoutIds.includes(widgetToId(widget));
    });
    return [...layoutsNotInWidgets, ...widgetsNotInLayout.map((widget) => widgetToLayout(widget))];
};

function getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (!node) {
        return null;
    }
    const { overflowY } = window.getComputedStyle(node);
    const isScrollable = overflowY !== "hidden";

    // if the node you have is scrollable, return it
    if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }
    // otherwise, recurse up the DOM until you find a node that is scrollable
    if (node.parentElement) {
        return getScrollParent(node.parentElement);
    }
    return null;
}

/**
 * Helper to handle mouse move events on top and bottom limits of a screen.
 * This way we can autoscroll downwards and upwards when dragging widgets.
 */
export function onWidgetDrag(
    allowedDrag: boolean,
    dashboardNode: HTMLElement | null,
    scrollParentNode: HTMLElement | null
) {
    if (!allowedDrag) return () => undefined;
    return (event: MouseEvent) => {
        const height = window.document.documentElement.clientHeight;
        const diff = height - event.clientY;

        const bottomLimit = diff < 90;
        const topLimit = diff > height - 90;
        if (bottomLimit || topLimit) {
            const diffDegree = Math.max(0, topLimit ? height - diff : diff);
            const distance = Math.cos(((diffDegree / 2) * Math.PI) / 180);
            const moveBy = (bottomLimit ? distance : -1 * distance) * 20;
            const scrollNode = getScrollParent(scrollParentNode);
            if (scrollNode && dashboardNode) {
                // get the total scrollable height based on the height of the dashboard and the parent container that is scrolled. added 200 to create a buffer to scroll completely to the bottom.
                const totalScrollableHeight = dashboardNode.clientHeight - scrollNode.clientHeight + 200;
                // this is to prevent scrolling past the bottom of the page.
                if (
                    (bottomLimit && scrollNode.scrollTop >= totalScrollableHeight) ||
                    (topLimit && scrollNode.scrollTop === 0)
                ) {
                    return;
                }
                scrollNode.scrollTop += moveBy;
            }
        }
    };
}
