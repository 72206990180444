import { gql, useMutation, useQuery } from "@apollo/client";
import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { InlineFilters } from "@ignite-analytics/filters";
import { track } from "@ignite-analytics/track";
import React, { useState } from "react";

import { PINNED_FIELD_ID_PREFIX_API_DASHBOARD, PINNED_FIELD_ID_PREFIX_DASHBOARDS_DASHBOARD } from "./constants";

import { useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { CustomDashboard } from "@/entities/dashboards";
import { usePermission } from "@/lib/permissions";
import { GetPinnedFieldsInput } from "@/__generated__/graphql";

type PinnedField = {
    dataSource: string;
    field: string;
};

const GET_PINNED_FIELDS_QUERY = gql`
    fragment PinnedFieldFields on PinnedField {
        dataSource
        field
    }
    query GetPinnedFields($input: GetPinnedFieldsInput!) {
        getPinnedFields(input: $input) {
            associatedId
            pinnedFields {
                ...PinnedFieldFields
            }
        }
    }
`;

const UPSERT_PINNED_FIELDS_MUTATION = gql`
    fragment PinnedFieldFields on PinnedField {
        dataSource
        field
    }
    mutation UpsertPinnedFields($input: UpsertPinnedFieldsInput!) {
        upsertPinnedFields(input: $input) {
            associatedId
            pinnedFields {
                ...PinnedFieldFields
            }
        }
    }
`;

const getPinnedFieldAssociatedId = (isPinnedFieldsMigrated: boolean, dashboard?: CustomDashboard) => {
    if (!dashboard) return undefined;
    if (dashboard.oldId && !isPinnedFieldsMigrated) {
        // Until the pinned fields are migrated in the filters service - use the old id
        return `${PINNED_FIELD_ID_PREFIX_API_DASHBOARD}${dashboard.oldId}`;
    }
    // Due to possible conflicts in with new and old ids, and to keep the ability to revert the migration, we're using a new prefix for dashboards created in the new service.
    return `${PINNED_FIELD_ID_PREFIX_DASHBOARDS_DASHBOARD}${dashboard.id}`;
};
const DashboardFilters: React.FC = () => {
    const { dashboard } = useDashboardContextOrThrow();
    const [pinnedFields, setPinnedFields] = useState<string[]>([]);
    const elasticFields = useElasticFieldsInContext();
    const isPinnedFieldsMigrated = useFeatureToggle("migratedPinnedFields");

    const hasDashboardsGeneralWritePermissions = usePermission("dashboards", { object: "general", relation: "write" });

    const input: GetPinnedFieldsInput = {
        associatedId: getPinnedFieldAssociatedId(isPinnedFieldsMigrated, dashboard) ?? "",
    };

    useQuery(GET_PINNED_FIELDS_QUERY, {
        variables: { input },
        context: {
            uri: process.env.REACT_APP_GRAPHQL_ROUTER_URL,
        },
        skip: !dashboard?.id,
        onCompleted: (data) => {
            if (data?.getPinnedFields?.pinnedFields) {
                setPinnedFields(data.getPinnedFields.pinnedFields.map((field: PinnedField) => field.field));
            }
        },
        onError: () => {
            setPinnedFields([]);
        },
    });

    const [upsertPinnedFields] = useMutation(UPSERT_PINNED_FIELDS_MUTATION, {
        context: {
            uri: process.env.REACT_APP_GRAPHQL_ROUTER_URL,
        },
        update: (cache, { data }) => {
            if (!data) return;
            const { pinnedFields: newPinnedFields } = data.upsertPinnedFields;
            // update the cached get pinned fields query with the new pinned fields
            cache.writeQuery({
                query: GET_PINNED_FIELDS_QUERY,
                variables: { input },
                data: {
                    getPinnedFields: data.upsertPinnedFields,
                },
            });
            setPinnedFields(newPinnedFields?.map((field: PinnedField) => field.field));
        },
    });

    const handlePinField = (fieldId: string, dataSource: string) => {
        const updatedPinnedFields = pinnedFields.includes(fieldId)
            ? pinnedFields.filter((field) => field !== fieldId)
            : [...pinnedFields, fieldId];

        upsertPinnedFields({
            variables: {
                input: {
                    associatedId: getPinnedFieldAssociatedId(isPinnedFieldsMigrated, dashboard) ?? "",
                    pinnedFields: updatedPinnedFields.map((field) => ({ dataSource, field })),
                },
            },
        });
        track("Pin filter to dashboard", {
            globalType: elasticFields?.find((field) => field.fieldId === fieldId)?.globalTypeKey,
            dashboardTitle: dashboard?.name,
        });
    };

    return (
        <InlineFilters
            placement="Inline dashboard filters"
            pinnedFields={pinnedFields}
            onPinField={handlePinField}
            hasEditPermission={hasDashboardsGeneralWritePermissions}
        />
    );
};

export default DashboardFilters;
