import { Client } from "@ignite-analytics/department";
import {
    ElasticField,
    ElasticIndex,
    isTermsType,
    useElasticFields,
    useElasticFieldsInContext,
} from "@ignite-analytics/elastic-fields";
import {
    DataSource,
    FilterContextProvider,
    IncludeFilter,
    UnpopulatedFilter,
    useCompanyFavouriteFilters,
    useFilters,
    usePersonalFavouriteFilters,
    useUpsertCompanyFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { useDebounce } from "@ignite-analytics/general-tools";
import { SUPPLIER_GT } from "@ignite-analytics/global-types";
import { ChartConfig, pivotParser } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { User } from "@ignite-analytics/user";
import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import Chart from "@/components/Charts/Chart";
import ChartContainer from "@/components/Charts/ChartContainer";
import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import { useGetDataFromAnalysisService } from "@/hoc/PivotAnalysis/services";
import { useElasticFieldsWithFilterFieldMappings } from "@/hoc/withFilterContext";
import { useExportWidget } from "@/hooks/useExport";
import { ExportFunc } from "@/hooks/useExport/interfaces";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";
import { useColors } from "@/lib/colors";

interface InnerProps {
    title: string;
    onExportFuncChange: (exportFunc: ExportFunc) => void;
}

export interface MinimumRequiredOuterProps extends Omit<InnerProps, "title" | "onExportFuncChange"> {
    height: string | number;
    inheritFilters?: boolean;
    disableDefaultCombination?: boolean;
}

interface Options<Props> {
    useInitialFilters?: (props: Props) => UnpopulatedFilter[] | Error;
    dataHandler?: (data: PivotResponse) => PivotResponse;
    containerProps?: Record<string, unknown>;
    disableDefaultCombination?: boolean;
}

const EMPTY_FILTERS: UnpopulatedFilter[] = [];

const pivotAnalysis = <OuterProps extends MinimumRequiredOuterProps>(
    useInitialQuery: () => AnalysisQuery | Error, // Will be called within filter context provided with the data source
    useDataSource: () => ElasticIndex | Error,
    chartConfig: ChartConfig,
    getTitle: () => string,
    { useInitialFilters, containerProps = {}, dataHandler = (data) => data }: Options<OuterProps> = {},
    /** Disable global filters and dispatchGlobalFilterAction to enable charts without opportunity to use and dispatch global filters */
    disableGlobalFilters = false
) => {
    const PivotAnalysis: React.FC<InnerProps> = ({ title, onExportFuncChange }) => {
        const customExportRef = useRef<Highcharts.Chart | HTMLElement>();
        const [exportReady, setExportReady] = useState(false);

        const initialQuery = useInitialQuery();
        const [query, setQuery] = useState(initialQuery);
        useEffect(
            function setInitialQuery() {
                if (query instanceof Error) {
                    if (!(initialQuery instanceof Error)) setQuery(initialQuery);
                    else if (initialQuery.message !== query.message) setQuery(initialQuery);
                }
            },
            [initialQuery, query]
        );

        const filters = useFilters();
        const intl = useIntl();
        const [data, setData] = useState<PivotResponse | undefined>();
        const elasticFields = useElasticFieldsInContext();
        const colors = useColors();
        const getPivotGraphql = useGetDataFromAnalysisService();

        // As we are listening for changes to both filters and analysisQuery in updateDataPromise, we need to debounce the execution. Otherwise, we will get two requests for e.g. drilldowns.
        const debouncedSetDataPromiseExecuter = useDebounce(
            useCallback(
                (
                    _analysisQuery: AnalysisQuery,
                    _fields: ElasticField[],
                    _filters: typeof filters,
                    _responseCallback: (res: PivotResponse) => void,
                    _getPivotGraphql: typeof getPivotGraphql
                ) => _getPivotGraphql(_analysisQuery, _fields || [], _filters, false).then(_responseCallback),
                []
            ),
            300
        );

        const updateDataPromise = useCallback(() => {
            if (elasticFields && filters && !(query instanceof Error)) {
                debouncedSetDataPromiseExecuter(
                    query,
                    elasticFields,
                    filters,
                    (res: PivotResponse) => setData(dataHandler(res)),
                    getPivotGraphql
                );
            }
        }, [debouncedSetDataPromiseExecuter, elasticFields, filters, getPivotGraphql, query]);

        useEffect(updateDataPromise, [updateDataPromise]);

        const labelledQuery = useLabelledAnalysisQuery(query instanceof Error ? undefined : query);
        const parsedData = useMemo(
            () => data && labelledQuery && pivotParser(intl, data, chartConfig, labelledQuery, colors),
            [colors, data, intl, labelledQuery]
        );

        const exportFunc = useExportWidget(title, labelledQuery?.elasticIndex, data, labelledQuery, customExportRef);

        useEffect(
            function propagateExportFunc() {
                if (!customExportRef.current) return;
                onExportFuncChange(exportFunc);
            },
            [exportFunc, onExportFuncChange, exportReady]
        );

        const handleOnRef = useCallback((el: Highcharts.Chart | HTMLElement) => {
            customExportRef.current = el;
            setExportReady(true);
        }, []);

        if (query instanceof Error) return <>{query.message}</>;
        return data ? (
            <Chart
                onRef={handleOnRef}
                title={title}
                hideTitle
                data={parsedData}
                config={chartConfig}
                analysisQuery={query}
                onDrilldown={(splits) => setQuery({ ...query, ...splits })}
                onSaveDrilldown={undefined} // We don't support saving drilldowns here
                disableGlobalFilters={disableGlobalFilters}
            />
        ) : (
            <CircularProgress />
        );
    };

    const WithCorrectContexts: React.FC<OuterProps> = (props) => {
        const exportFuncRef = useRef<ExportFunc>();
        const dataSource = useDataSource();
        const initialFilters = useInitialFilters?.(props);

        const title = getTitle();
        const { inheritFilters = false, height, disableDefaultCombination = true } = props;
        const userId = useOryUserIdOrThrow();

        const usePersonalFavouriteFiltersForContext = () =>
            usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
        const useCompanyFavouriteFiltersForContext = () =>
            useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

        const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
            userId,
            process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
        );
        const upsertCompanyFavouriteFilters = useUpsertCompanyFavouriteFilters(
            userId,
            process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
        );

        const allDataSources: DataSource[] | undefined | null = useAllElasticIndices();

        const handleExportFuncChange = useCallback((exportFunc: ExportFunc) => {
            exportFuncRef.current = exportFunc;
        }, []);

        if (allDataSources === null) return null;

        return (
            <FilterContextProvider
                inherit={inheritFilters}
                initialFilters={initialFilters instanceof Error ? EMPTY_FILTERS : initialFilters}
                useDataFields={useElasticFieldsWithFilterFieldMappings}
                dataSource={dataSource instanceof Error ? undefined : dataSource}
                disableDefaultCombination={disableDefaultCombination}
                debugName={title}
                // Favourite filter hooks
                allDataSources={allDataSources}
                usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                upsertCompanyFavouriteFilters={upsertCompanyFavouriteFilters}
            >
                <ChartContainer
                    title={title}
                    height={height}
                    chartConfig={chartConfig}
                    exportFuncRef={exportFuncRef}
                    {...containerProps}
                >
                    {initialFilters instanceof Error ? (
                        initialFilters.message
                    ) : dataSource instanceof Error ? (
                        dataSource.message
                    ) : (
                        <PivotAnalysis onExportFuncChange={handleExportFuncChange} title={title} />
                    )}
                </ChartContainer>
            </FilterContextProvider>
        );
    };

    return WithCorrectContexts;
};
interface Rating {
    price: number;
    quality: number;
    service: number;
    availability: number;
}

interface TotalRatingsData {
    avg: number;
    count: number;
}

interface ClientSupplierRating {
    id: number;
    user: User | null;
    clientSupplier: number;
    client: number;
    title: string;
    comment: string;
    totalRating: number;
    updatedAt: string;
    ratingFields: Rating;
}

export interface SimpleClientSupplier {
    id: number | string;
    name: string;
    orgNumber?: string;
}

export interface ClientSupplier {
    id: number;
    name: string;
    client?: Client | number;
    orgNumber: string;
    duns: string;
    country: string;
    supplier?: number;
    synced?: boolean;
    syncedSupplierSource?: string;
    ratings?: ClientSupplierRating[];
    totalRating: TotalRatingsData;
    globalRating: TotalRatingsData;
    originalNames?: string[];
    officialName?: string;
    logo?: string | null;
}

/**
 * Returns a IncludeFilter for a supplier for the elasticIndex in the context.
 * Which field to use is based on global types.
 */
export const useSupplierFilter = (
    supplier: { name: string; id: number | string } | undefined,
    elasticIndex: string | Error | undefined
) => {
    const elasticFields = useElasticFields(typeof elasticIndex === "string" ? elasticIndex : undefined);

    return React.useMemo<IncludeFilter | undefined>(() => {
        if (supplier === undefined) return undefined;
        if (elasticIndex instanceof Error) return undefined;
        const field = elasticFields?.find((f) => f.globalTypeKey === SUPPLIER_GT);
        if (field === undefined || !isTermsType(field.type)) return undefined;
        return {
            field: field.field,
            type: field.type,
            include: [`${supplier.id}`],
            idNameMap: { [supplier.id]: supplier.name },
            filterType: "includefilter",
            fieldId: field.fieldId,
        };
    }, [elasticFields, elasticIndex, supplier]);
};

export default pivotAnalysis;
