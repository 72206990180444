import { formatValue } from "@ignite-analytics/locale";
import { ChartConfig, isGroupedCategoriesPoint } from "@ignite-analytics/pivot-charts";
import { useMemo } from "react";

import messages from "../messages";

import { staticFormatMessage } from "@/contexts/IntlContext";

export const useChartConfig = (plotLines: Highcharts.XAxisPlotLinesOptions[]): ChartConfig =>
    useMemo(
        () => ({
            legends: false,
            plotLines,
            optionsPerAgg: [
                {
                    type: "line",
                    decimals: "auto",
                    lineMarkers: false,
                    stackedDataLabels: false,
                    labelDisplayMode: false,
                    tooltipPointFormatter() {
                        return `<b>${staticFormatMessage(messages.spendDevelopmentTooltip, {
                            month: isGroupedCategoriesPoint(this) ? this.category.name : "",
                        })}</b> ${formatValue(this.y || 0) || ""}`;
                    },
                },
            ],
        }),
        [plotLines]
    );
