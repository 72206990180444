import { isNumericType, isTermsType } from "@ignite-analytics/elastic-fields";
import { GlobalColumnType } from "@ignite-analytics/global-types";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";

import { getGlobalColumnTypeKey } from "../../helpers";
import { Mapping } from "../../interfaces";
import messages from "../../messages";

import { gql } from "@/__generated__/gql";
import { ElasticFieldItem } from "@/__generated__/graphql";
import { fm } from "@/contexts/IntlContext";
import { useQuery } from "@apollo/client";

interface Props {
    dataTable: string;
    preservedGlobalColumnType: GlobalColumnType;
    setMapping?: React.Dispatch<React.SetStateAction<Mapping | undefined>>;
}

const matchingElasticField = (esFieldType: string, globalTypeDataType: string) => {
    switch (globalTypeDataType) {
        case "number":
            return isNumericType(esFieldType);
        case "text":
            return isTermsType(esFieldType);
        case "grouping":
            return isTermsType(esFieldType);
        case "company":
            return isTermsType(esFieldType) || isNumericType(esFieldType);
        default:
            return esFieldType === globalTypeDataType;
    }
};

const getDataTableElasticFieldsQuery = gql(`
    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {
        getDataTableElasticFields(input: $input) {
            elasticFields {
                ...ElasticFieldItemFields
            }
        }
    }

    fragment ElasticFieldItemFields on ElasticFieldItem {
        field
        label
        type
        globalTypeKey
        globalTypeSubKey
        labelField
        labelFieldType
        max
        min
        maxDateForRelativeFilters
        elasticIndex
    }
`);
/**
 * Rows of columns to select
 */
export const ColumnSelectComponent: React.VFC<Props> = ({ dataTable, preservedGlobalColumnType, setMapping }) => {
    const [selected, setSelected] = useState<boolean>();
    const { data } = useQuery(getDataTableElasticFieldsQuery, {
        variables: { input: { dataTableId: dataTable, withEnrichments: false } },
    });

    const elasticFields = data?.getDataTableElasticFields.elasticFields;

    const getColumnSelectName = (): string => {
        if (preservedGlobalColumnType.dataType === "grouping") {
            return `dataColumns[${preservedGlobalColumnType.id}_${preservedGlobalColumnType.globalTypeSubKey}]`;
        }
        return `dataColumns[${preservedGlobalColumnType.id}]`;
    };

    const handleSelectFormValue = (field: ElasticFieldItem) => {
        if (!setMapping) return;
        setSelected(true);
        setMapping((prevState) =>
            prevState
                ? {
                      ...prevState,
                      dataColumns: {
                          ...prevState?.dataColumns,
                          [getGlobalColumnTypeKey(preservedGlobalColumnType)]: field,
                      },
                  }
                : prevState
        );
    };

    if (!elasticFields) {
        return null;
    }

    const _defaultValue = elasticFields.find((esField) => esField.globalTypeKey === preservedGlobalColumnType.key);

    if (!selected && _defaultValue) {
        handleSelectFormValue(_defaultValue);
    }

    const options = elasticFields
        .filter((esField) => {
            return !(esField.field.endsWith("_name") || esField.field.endsWith("_precedence"));
        })
        .filter((esField) => {
            return matchingElasticField(esField.type.toLowerCase(), preservedGlobalColumnType.dataType.toLowerCase());
        });

    if (options.length === 0) {
        return (
            <Select name={getColumnSelectName()} required fullWidth>
                <MenuItem value="">
                    <em>{fm(messages.noColumnsAvailable)}</em>
                </MenuItem>
            </Select>
        );
    }
    return (
        <Autocomplete
            disablePortal
            options={options.map((esField) => ({
                label: esField.label,
                value: esField.field,
            }))}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required
                    name={getColumnSelectName()}
                    label={fm(messages.column)}
                    defaultValue={_defaultValue?.field}
                />
            )}
            onChange={(_e, newValue) => {
                const field = elasticFields.find((esField) => esField.field === newValue?.value);
                if (field) {
                    handleSelectFormValue(field);
                }
            }}
            defaultValue={
                _defaultValue && {
                    label: _defaultValue.label,
                    value: _defaultValue.field,
                }
            }
        />
    );
};

export default ColumnSelectComponent;
