import { Api } from "@ignite-analytics/api-client";
import {
    createDetailResource,
    createEntityContext,
    createListResource,
    RequestOptions,
} from "@ignite-analytics/entities";
import { useCallback } from "react";

import { ModelGuard } from "@/lib/permissions";

const listCreateResource = createListResource<ModelGuard>`/permissions/model-guards/`;
const detailResource = createDetailResource<ModelGuard>`/permissions/model-guards/${(params) => params.id}/`;

export const {
    Provider: ModelGuardContextProvider,
    useObject: useModelGuard,
    useUpdateAction: useUpdateModelGuardAction,
    useThisContext: useModelGuardContext,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: true,
    name: "modelGuard",
});

export const useCreateActionWithGetParams = () => {
    if (!listCreateResource) throw Error(`The modelGuard entity has no list/create view`);
    const urlPromise = listCreateResource.useUrl({});
    const { addDetailObj } = useModelGuardContext();
    return useCallback(
        async (instance: Omit<ModelGuard, "id">, getParams: NonNullable<RequestOptions["getParams"]>) => {
            const url = await urlPromise;
            if (!url) return;
            const getParameters = Api.valuesToGetParams(getParams);
            return Api.post<ModelGuard>(`${url}${getParameters}`, instance, {
                service: "dashboards",
            }).then((data) => {
                addDetailObj(data, url);
                return data;
            });
        },
        [addDetailObj, urlPromise]
    );
};
