import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { FilterModal, useFilters } from "@ignite-analytics/filters";
import { debounce } from "@ignite-analytics/general-tools";
import { SUPPLIER_GT, TRANSACTION_DATE_GT } from "@ignite-analytics/global-types";
import {
    ArrowsUpDown,
    DotsVertical,
    ExclamationCircle,
    ArrowDownTray as FileDownloadIcon,
} from "@ignite-analytics/icons";
import { UserMessage } from "@ignite-analytics/user";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";

import { FinancialFooter } from "../FinancialFooter";
import { SortField } from "../interfaces";

import { potentialOptions, potentialRiskFields, QUERY_SIZE, riskOptions, TABLE_SIZE } from "./constants";
import { RatingType } from "./interfaces";
import messages from "./messages";
import PotentialAndRiskTable from "./PotentialAndRiskTable";
import { RatingTableData } from "./PotentialAndRiskTable/DataTableCell/interfaces";
import { getMaxRelativeDate } from "./PotentialAndRiskTable/helpers";
import * as Services from "./services";

import AnalysisOptions from "@/components/AnalysisOptions";
import FilterCardFooter from "@/components/Widgets/Components/FilterCardFooter";
import { POTENTIAL, RISK } from "@/components/Widgets/constants";
import { Options } from "@/components/Widgets/interfaces";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import withFilterContext, { useElasticFieldsWithFilterFieldMappings } from "@/hoc/withFilterContext";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useExportWidget } from "@/hooks/useExport";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { useTransactionsCategoryFieldByLevel } from "@/hooks/useSpendCategoryElasticField";

const divider = ": ";

const RiskTooltip = (
    <>
        <Typography variant="body2" component="div" gutterBottom>
            <strong>{fm(messages.liquidityHeader)}</strong>
            {divider}
            {fm(messages.liquidityTooltip)}
        </Typography>
        <Typography variant="body2" component="div" gutterBottom>
            <strong>{fm(messages.solvencyHeader)}</strong>
            {divider}
            {fm(messages.solvencyTooltip)}
        </Typography>
        <Typography variant="body2" component="div">
            <strong>{fm(messages.roce)}</strong>
            {divider}
            {fm(messages.roceTooltip)}
        </Typography>
    </>
);

const PotentialTooltip = (
    <>
        <Typography variant="body2" component="div" gutterBottom>
            <strong>{fm(messages.spendGrowthHeader)}</strong>
            {divider}
            {fm(messages.spendGrowthTooltip)}
        </Typography>
        <Typography variant="body2" component="div" gutterBottom>
            <strong>{fm(messages.shareOfWalletHeader)}</strong>
            {divider}
            {fm(messages.shareOfWalletTooltip)}
        </Typography>
        <Typography variant="body2" component="div" gutterBottom>
            <strong>{fm(messages.roce)}</strong>
            {divider}
            {fm(messages.roceTooltip)}
        </Typography>
        <Typography variant="body2" component="div">
            <strong>{fm(messages.ebitHeader)}</strong>
            {divider}
            {fm(messages.ebitMarginTooltip)}
        </Typography>
    </>
);

/* TODO: much to fix
 *
 * Container for Automated Recommendation that lists suppliers
 * with highest potential rating into a table
 *
 * This container is based on the container for the supplier list
 *
 */
interface OptionalProps {
    specifiedRatingType?: "risk" | "potential";
}
const PotentialRatingPage: React.FC<OptionalProps> = (props: OptionalProps) => {
    const exportElRef = useRef<HTMLElement | null>();
    const [exporting, setExporting] = useState(false);

    const transactionIndex = useTransactionsIndex();
    const elasticFields = useElasticFields(transactionIndex?.name);
    const lowestCategoryField = useTransactionsCategoryFieldByLevel("lowest");
    const splits = !(lowestCategoryField instanceof Error) ? lowestCategoryField.fieldId.split("_") : undefined;
    const lowestCategorylevel = splits && +splits[3].slice(1);

    const rating = useParams<{ ratingType: RatingType }>().ratingType;
    const { specifiedRatingType } = props;
    const ratingType = specifiedRatingType || rating;
    const filters = useFilters();

    // There are some slight changes in the default options between the risk table and the potential table
    const defaultOptions = useMemo(
        () => (ratingType === "risk" ? riskOptions(lowestCategorylevel) : potentialOptions(lowestCategorylevel)),
        [ratingType, lowestCategorylevel]
    );
    const [options, setOptions] = useState<Options>(defaultOptions);

    const [data, setData] = useState<RatingTableData>();
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState<SortField>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [offset, setOffset] = useState(0);
    const [querySize, setQuerySize] = useState(QUERY_SIZE);
    const [totalResults, setTotalResults] = useState<number>(0);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const informUser = useInformUser();

    useSetBreadcrumbs(
        specifiedRatingType === "risk" ? fm(messages.risk).toString() : fm(messages.potential).toString()
    );

    const updateData = () => {
        const findFieldIdByGtKey = (key: string): ElasticField | undefined =>
            elasticFields?.find((field) => field.globalTypeKey === key);
        const dateField = findFieldIdByGtKey(TRANSACTION_DATE_GT);
        const maxRelativeDate = getMaxRelativeDate(dateField);
        const supplierElasticField = findFieldIdByGtKey(SUPPLIER_GT);
        const supplierFieldId: string | undefined = supplierElasticField?.fieldId;
        const supplierLabelFieldId: string | undefined =
            supplierElasticField && "labelField" in supplierElasticField ? supplierElasticField.labelField : undefined;

        if (filters && maxRelativeDate && elasticFields) {
            Services.getRatings(
                filters,
                options,
                ratingType === "potential" ? POTENTIAL : RISK,
                maxRelativeDate,
                sorting,
                TABLE_SIZE,
                searchTerm,
                undefined,
                supplierFieldId,
                supplierLabelFieldId,
                !!lowestCategorylevel
            )
                .then((resp) => {
                    setLoading(false);
                    setData(resp);
                    setTotalResults(resp.rows.length);
                    if (!lowestCategorylevel) {
                        informUser({
                            type: "info",
                            message: fm(messages.missingCategoryGt).toString(),
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    let errorType: UserMessage["type"] = "error";
                    if (
                        error?.response?.data?.error?.some(
                            (err: string) =>
                                err.startsWith("Could not find") || err.startsWith("Related supplier table")
                        )
                    ) {
                        errorType = "warning";
                    }
                    informUser({
                        type: errorType,
                        message: Object.values(error.response.data).join(", "),
                        timeout: 15000,
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    const handleOnSort = () => {
        setSorting({
            sortPath: "spend_last_12_months>total_value",
            order: !sorting || sorting.order !== "desc" ? "desc" : "asc",
        });
    };

    const debouncedChangeHandler = useMemo(() => debounce(setSearchTerm, 500), []);

    const handleSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            debouncedChangeHandler(e.target.value);
        },
        [debouncedChangeHandler]
    );

    useEffect(updateData, [
        filters,
        options,
        sorting,
        ratingType,
        searchTerm,
        elasticFields,
        lowestCategorylevel,
        informUser,
    ]);

    useEffect(() => {
        setOptions(defaultOptions);
    }, [defaultOptions]);

    const showMorePopup = Boolean(moreMenuAnchorEl);
    const id = "show-more-popup";
    const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMoreMenuAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setMoreMenuAnchorEl(null);
    };

    const handleApplyOptions = (analysisOptions: Options) => {
        setOptions(analysisOptions);
        handleMoreClose();
    };

    const exportFunc = useExportWidget(specifiedRatingType ?? "", undefined, undefined, undefined, exportElRef);

    const handleExport = () => {
        if (exporting) return;
        setExporting(true);
        exportFunc({
            exportType: "image",
            options: options ?? {},
        })
            .catch((errMessage) => {
                if (typeof errMessage === "string") {
                    informUser({ type: "error", message: errMessage });
                }
            })
            .finally(() => {
                setExporting(false);
                handleMoreClose();
            });
    };

    const handleOnRef = useCallback((el: HTMLElement | null) => {
        exportElRef.current = el;
    }, []);

    return (
        <Box px={4} py={2}>
            <Container maxWidth="xl">
                <Card variant="outlined" sx={{ margin: "5px" }}>
                    <CardContent>
                        <Grid container justifyContent="space-between" alignItems="center" pb={2}>
                            <TextField
                                size="small"
                                placeholder={fm(messages.placeholderSearch).toString()}
                                onChange={handleSearch}
                            />
                            <Typography variant="h4">
                                {fm(ratingType === "potential" ? messages.potentialHeader : messages.riskHeader)}
                            </Typography>
                            <Grid container gap={0.2} md={3} justifyContent="flex-end" alignItems="center">
                                <Tooltip title={fm(messages.sortBySpend)}>
                                    <ArrowsUpDown fontSize="small" onClick={handleOnSort} />
                                </Tooltip>
                                <Tooltip title={fm(messages.filterBtn)}>
                                    <Box>
                                        <FilterModal
                                            placement="Potential rating page"
                                            title={fm(
                                                ratingType === "potential"
                                                    ? messages.potentialHeader
                                                    : messages.riskHeader
                                            ).toString()}
                                        />
                                    </Box>
                                </Tooltip>
                                <IconButton aria-describedby={id} size="small" onClick={handleMoreClick}>
                                    <DotsVertical fontSize="inherit" />
                                </IconButton>
                                <Menu
                                    id={id}
                                    open={showMorePopup}
                                    anchorEl={moreMenuAnchorEl}
                                    onClose={handleMoreClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <MenuItem>
                                        <Tooltip title={fm(messages.exportBtn)} placement="top">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyItems="center"
                                                spacing={1}
                                                onClick={handleExport}
                                            >
                                                <FileDownloadIcon fontSize="small" />
                                                <Typography variant="body2">{fm(messages.exportData)}</Typography>
                                            </Stack>
                                        </Tooltip>
                                    </MenuItem>

                                    <MenuItem>
                                        <AnalysisOptions
                                            fields={potentialRiskFields(ratingType)}
                                            options={options}
                                            onSubmit={(analysisOptions: Options) => handleApplyOptions(analysisOptions)}
                                        />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        <Stack direction="row">
                            {data ? (
                                <PotentialAndRiskTable
                                    onRef={handleOnRef}
                                    data={data}
                                    pageSize={querySize}
                                    offset={offset}
                                    total={totalResults}
                                    onPaginate={setOffset}
                                    onPageSizeChange={setQuerySize}
                                />
                            ) : (
                                loading && (
                                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                                        <CircularProgress />
                                    </Stack>
                                )
                            )}
                        </Stack>
                    </CardContent>
                    <FilterCardFooter analysisOptions={options} />
                </Card>
                <Grid container padding={1} gap={2} justifyContent="space-between">
                    <Stack direction="column">
                        <FinancialFooter
                            footerText={fm(
                                ratingType === "potential"
                                    ? messages.howDoWeAnalysePotential
                                    : messages.howDoWeAnalyseRisk
                            ).toString()}
                            footerTooltip={ratingType === "potential" ? PotentialTooltip : RiskTooltip}
                        />

                        <Stack direction="row" spacing={0.5} alignItems="center">
                            {data && data.total && data.rows.length > 0 && data.total > TABLE_SIZE && (
                                <>
                                    <ExclamationCircle fontSize="small" />
                                    <Typography variant="caption">
                                        {fm(messages.displayingNumberOfSuppliers, {
                                            length: data.rows.length,
                                            total: data.total,
                                        })}
                                    </Typography>
                                    <Typography variant="caption">{fm(messages.supplierCountExceeded)}</Typography>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Container>
        </Box>
    );
};

export default withFilterContext(PotentialRatingPage, {
    inherit: false,
    debugName: "Risk/Potential page",
    useDataSource: useTransactionsIndex,
    useDataFields: useElasticFieldsWithFilterFieldMappings,
});
