import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import { useModelGuard } from "@/entities/modelGuards";
import { BackendPermissionType, PermissionRelation, usePermission } from "@/lib/permissions";

export const useBackwardCompatiblePermissionCheck = (
    apiPermissionType: BackendPermissionType | BackendPermissionType[],
    equivalentUserPermission: { namespace: string; relation: PermissionRelation },
    guard?: number | null
) => {
    const modelGuard = useModelGuard(guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });

    const backwardCompatibleUserId = useOryUserIdOrThrow();

    const hasOryPermission = usePermission(equivalentUserPermission.namespace, equivalentUserPermission.relation);

    const objectPermission = guard && modelGuard;

    const objectHasStandardPerms =
        !objectPermission || objectPermission[apiPermissionType === "view" ? "standardRead" : "standardWrite"];
    const invitedUsers =
        objectPermission && objectPermission[apiPermissionType === "view" ? "readUsers" : "writeUsers"];

    const hasObjectPerm = invitedUsers && backwardCompatibleUserId && invitedUsers.includes(backwardCompatibleUserId);

    return Boolean(objectHasStandardPerms ? hasOryPermission || hasObjectPerm : hasObjectPerm);
};
