import { Api, toSnakeCase } from "@ignite-analytics/api-client";
import { Filter } from "@ignite-analytics/filters";
import { Options } from "../interfaces";

// TODO: Find better location
export const parseFilterInput = (filters: Filter[], customFilters: Options = {}, partial = false) =>
    Api.valuesToGetParams(
        toSnakeCase(
            {
                filters: filters.filter((filter) =>
                    "include" in filter
                        ? filter.include.length || filter.includeBlanks
                        : "exclude" in filter
                        ? filter.exclude.length || filter.excludeBlanks
                        : true
                ),
                customFilters,
            },
            false
        ),
        partial
    );
