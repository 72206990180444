import { isApolloError, useMutation } from "@apollo/client";
import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { Filter, useFilters } from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { LabelledAnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { createPng, exportDataToFile, mapFromFrontendFiltersToAnalysisFilter } from "./helpers";
import { ExportFileProps, ExportFunc, ExportImageProps, ExportableDomElements } from "./interfaces";
import messages from "./messages";

import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useWithLabels } from "@/hooks/useWithLabels";
import { gql } from "@/__generated__/gql";

export const useExportWidget: (
    title: string,
    elasticIndex: string | undefined,
    data: PivotResponse | undefined,
    query: LabelledAnalysisQuery | undefined,
    domElRef: React.RefObject<ExportableDomElements>
) => ExportFunc = (title, elasticIndex, data, query, domElRef) => {
    const consistentLabelledFilters = useWithLabels(useFilters(), false);
    const intl = useIntl();
    const elasticFields = useElasticFields(elasticIndex);

    const exportFunc = useCallback(
        (exportData: ExportImageProps | ExportFileProps) => {
            const voidPromise = Promise.resolve();
            if (exportData.exportType === "csv" || exportData.exportType === "excel") {
                if (!data) return voidPromise;
                return exportDataToFile(
                    exportData.exportType,
                    title,
                    data,
                    elasticFields,
                    query,
                    exportData.chartConfig,
                    consistentLabelledFilters
                );
            }
            if (exportData.exportType === "image") {
                if (!domElRef.current) {
                    return voidPromise;
                }
                return createPng(
                    domElRef.current,
                    title,
                    consistentLabelledFilters || [],
                    exportData.options,
                    intl,
                    elasticFields || [],
                    exportData.disableDownload
                );
            }
            return voidPromise;
        },
        [title, data, query, domElRef, elasticFields, consistentLabelledFilters, intl]
    );
    return exportFunc;
};

const getDataTableIdFromIndexName = (indexName: string): string | undefined => {
    const dataTableRegexp = new RegExp(
        "^datatable_v2_([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})",
        "g"
    );
    const result = dataTableRegexp.exec(indexName);
    if (result === null || !result[1]) {
        return undefined;
    }
    return result[1];
};

const exportDataTableToFileMutation = gql(`
    mutation exportDataTableToFile($input: ExportDataTableToFileInput!) {
        exportDataTableToFile(input: $input) {
            notificationId
        }
    }
`);

export const useExportData = (analysisName: string) => {
    const [exportDataTable] = useMutation(exportDataTableToFileMutation);

    const informUser = useInformUser();
    return useCallback(
        (elasticIndex: string, filters: Filter[], elasticFields: ElasticField[]): Promise<void> => {
            const dataTableId = getDataTableIdFromIndexName(elasticIndex);
            if (!dataTableId) {
                informUser({
                    message: fm(messages.exportToExcelFailure),
                    type: "error",
                });
                return Promise.reject();
            }
            return exportDataTable({
                variables: {
                    input: {
                        dataTableId,
                        filters: filters.map(mapFromFrontendFiltersToAnalysisFilter).filter(isNotNullish),
                        exportFormat: "XLSX",
                        fields: elasticFields.map(({ fieldId }) => fieldId),
                        notificationTitle: `Selected data from the analysis '${analysisName}' to file`,
                    },
                },
            })
                .then(() =>
                    informUser({
                        message: fm(messages.exportToExcelSuccess),
                        type: "success",
                    })
                )
                .catch((err) => {
                    if (isApolloError(err) && err.graphQLErrors.length) {
                        const fullMessage = err.graphQLErrors[0].message;
                        const messageWithoutErrorPrefix = fullMessage.split(
                            `${err.graphQLErrors[0].extensions?.code}: `
                        )[1];
                        informUser({
                            message:
                                messageWithoutErrorPrefix ??
                                fullMessage ??
                                fm(messages.exportToExcelFailure).toString(),
                            type: "error",
                        });
                        return;
                    }

                    informUser({
                        message: fm(messages.exportToExcelFailure),
                        type: "error",
                    });
                });
        },
        [exportDataTable, informUser, analysisName]
    );
};
