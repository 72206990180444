import { usePromiseState, WAITING } from "@ignite-analytics/api-client";
import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { capitalCase, useDebounce, useLastNonNullishValue } from "@ignite-analytics/general-tools";
import { TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { formatValue } from "@ignite-analytics/locale";
import { pivotParser } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { Stack, Tooltip, Typography } from "@mui/material";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { trendTag } from "../Components";
import { fieldTitleMap } from "../Components/EditModal/constants";
import { WidgetError } from "../Components/WidgetError";
import { WidgetLoader } from "../Components/WidgetLoader";
import { getErrorsFromPromise } from "../helpers";
import { DeprecatedWidget, WidgetProps } from "../interfaces";

import { THIS_PERIOD_DESCRIPTORS } from "./constants";
import { calculateTrend, getFieldHistoryAdditionalFilter, getFieldHistoryQuery } from "./helpers";
import { useChartConfig } from "./hooks";
import { isValidPeriod, Period } from "./interfaces";
import messages from "./messages";

import Chart from "@/components/Charts/Chart";
import { CUSTOM_PERIOD, DISPLAY_COMPARISION, PERCENTAGE_LIMIT, SELECTED_FIELD } from "@/components/Widgets/constants";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { staticFormatMessage } from "@/contexts/IntlContext";
import { useGetDataFromAnalysisService } from "@/hoc/PivotAnalysis/services";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useExportWidget } from "@/hooks/useExport";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";
import { useColors } from "@/lib/colors";
import globalMessages from "@/lib/messages/globalMessages";

const FieldHistoryWidget: React.FC<WidgetProps<DeprecatedWidget, PivotResponse>> = ({
    widget,
    activeWidget,
    updateActiveWidget,
}) => {
    const filters = useFilters();
    const intl = useIntl();
    const globalTypeIndex = useTransactionsIndex()?.name;

    const options = activeWidget.config;

    const [exportReady, setExportReady] = useState(false);
    const customExportRef = React.useRef<Highcharts.Chart | HTMLElement>();
    const { updateExportFuncForWidget } = useExportDashboardContextOrThrow();

    const [dataPromise, setDataPromise] = usePromiseState<PivotResponse | undefined>();
    const fields = useElasticFields(globalTypeIndex);

    const unlabelledQuery = useMemo(
        () => (filters && options && fields?.length ? getFieldHistoryQuery(filters, options, fields) : undefined),
        [fields, filters, options]
    );

    const query = useLabelledAnalysisQuery(unlabelledQuery);
    const colors = useColors();
    const getPivotGraphql = useGetDataFromAnalysisService();

    // As we are listening for changes to both filters and analysisQuery in updateDataPromise, we need to debounce the execution. Otherwise, we will get two requests for e.g. drilldowns.
    const debouncedSetDataPromiseExecuter = useDebounce(
        useCallback(
            (
                _analysisQuery: AnalysisQuery,
                _fields: ElasticField[],
                _filters: typeof filters,
                _setDataPromise: typeof setDataPromise,
                _getPivotGraphql: typeof getPivotGraphql
            ) => _setDataPromise(_getPivotGraphql(_analysisQuery, _fields, _filters, false)),
            []
        ),
        300
    );

    const updateDataPromise = useCallback(() => {
        if (fields?.length && filters !== undefined && query) {
            const additionalDateFilter = getFieldHistoryAdditionalFilter(filters, activeWidget.config, fields);
            const filtersWithDateFilter = additionalDateFilter ? [...filters, additionalDateFilter] : filters;
            debouncedSetDataPromiseExecuter(query, fields, filtersWithDateFilter, setDataPromise, getPivotGraphql);
        }
    }, [activeWidget, debouncedSetDataPromiseExecuter, fields, filters, getPivotGraphql, query, setDataPromise]);

    useEffect(updateDataPromise, [updateDataPromise]);

    const loading = dataPromise[WAITING];
    const data = useLastNonNullishValue(dataPromise.data);
    const { permissionDeniedMessage, userHasViewAccess, errors } = getErrorsFromPromise(dataPromise);

    const compare = options[DISPLAY_COMPARISION] ?? true;
    const _selectedPeriod = options[CUSTOM_PERIOD] ?? "month";
    const period: Period = isValidPeriod(_selectedPeriod) ? _selectedPeriod : "month";
    const { trend, current } = data ? calculateTrend(data, compare, period) : { trend: NaN, current: NaN };
    const titleMap = fieldTitleMap();
    const titleKey = options[SELECTED_FIELD] as keyof typeof titleMap;

    const header = capitalCase(`${titleMap[titleKey]} ${THIS_PERIOD_DESCRIPTORS[period].toString()}`.toLowerCase());

    const nonComparisonTooltip =
        data && data.columns.length > 1
            ? staticFormatMessage(messages.trendDescriptionTooltip, {
                  prev: data.columns[data.columns.length - 2].name,
                  curr: data.columns[data.columns.length - 1].name,
              })
            : "";
    const chartConfig = useChartConfig(titleKey, period, compare);
    const parsedData = useMemo(
        () => query && data && pivotParser(intl, data, chartConfig, query, colors),
        [chartConfig, colors, data, intl, query]
    );

    const handleOnRef = useCallback((el: Highcharts.Chart | HTMLElement) => {
        customExportRef.current = el;
        setExportReady(true);
    }, []);

    const exportFunc = useExportWidget(
        activeWidget.title,
        activeWidget.elasticIndex,
        data ?? undefined,
        query,
        customExportRef
    );

    useEffect(
        function propagateExportFunc() {
            if (!activeWidget.id || !customExportRef.current) return;
            updateExportFuncForWidget(exportFunc, activeWidget.id.toString());
        },
        [activeWidget.id, activeWidget.title, exportFunc, updateExportFuncForWidget, exportReady]
    );

    const handleDrilldown = useCallback(
        (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) =>
            query &&
            updateActiveWidget(
                update(activeWidget, {
                    config: {
                        query: { $set: { ...query, ...newSplitItems } },
                    },
                })
            ),
        [activeWidget, query, updateActiveWidget]
    );

    if (errors?.length && errors[0] !== "Cancel")
        return (
            <WidgetError
                onUpdateData={updateDataPromise}
                activeWidget={activeWidget}
                props={{ permissionDeniedMessage, userHasViewAccess, errors }}
            />
        );

    if ((!data || !query) && !loading) {
        return (
            <Stack justifyContent="center">
                <Typography align="center">{staticFormatMessage(globalMessages.noDataLabel)}</Typography>
            </Stack>
        );
    }

    return (
        <Stack justifyContent="flex-start" alignItems="stretch" sx={{ width: "100%", height: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
                <Stack>
                    <Typography variant="overline">{header}</Typography>
                    <Typography variant="subtitle1">{formatValue(current, 0)}</Typography>
                </Stack>
                <Tooltip
                    title={
                        compare
                            ? staticFormatMessage(messages.compareWithPreviousTooltip, {
                                  widgetPeriod: options[CUSTOM_PERIOD],
                              })
                            : nonComparisonTooltip
                    }
                >
                    {trendTag(trend, options[PERCENTAGE_LIMIT])}
                </Tooltip>
            </Stack>
            {loading && <WidgetLoader />}
            {parsedData && (
                <Chart
                    onRef={handleOnRef}
                    title={widget.title}
                    hideTitle
                    data={parsedData}
                    config={chartConfig}
                    onDrilldown={handleDrilldown}
                    analysisQuery={query}
                    onSaveDrilldown={undefined}
                />
            )}
        </Stack>
    );
};

export default widgetWrapper(FieldHistoryWidget, TRANSACTIONS_GT);
