import React from "react";

import { EDIT_MODAL_FIELDS } from "./constants";

import EditModal from "@/components/Widgets/Components/EditModal";
import { Widget, WidgetProps } from "@/components/Widgets/interfaces";

interface Props<W extends Widget> extends WidgetProps<W, object> {
    onClose: () => void;
    isNew?: boolean;
}

const WidgetEditor: React.FC<Props<Widget>> = (props) => {
    // Render correct editor based on type
    switch (props.activeWidget.type) {
        case "LARGEST_FIELD_NEW":
            return (
                <EditModal
                    {...props}
                    fields={EDIT_MODAL_FIELDS[props.activeWidget.type]}
                    initialFilters={props.widget.filters}
                />
            );
        case "CONTRACTS":
        case "CONTRACT_LOYALTY":
        case "FIELD_HISTORY":
        case "NOTIFICATIONS":
        case "POTENTIAL":
        case "RISK":
            return <EditModal {...props} fields={EDIT_MODAL_FIELDS[props.activeWidget.type]} />;
        case "SPEND_DEVELOPMENT":
            return <EditModal {...props} fields={{}} initialFilters={props.widget.filters} />;
        case "ANALYSIS_NEW":
        case "FIELD_DISTRIBUTION_NEW":
        case "TEXT_WIDGET":
            return null;
        default:
            return null;
    }
};

type ExportType = <W extends Widget>(props: Props<W>) => JSX.Element;

export default WidgetEditor as ExportType;
