import { Stringish } from "@ignite-analytics/general-tools";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import FieldConfigurationModal from "../FieldConfigurationDialog";
import ErrorAlertOrConfiguration from "../WidgetAlertOrConfiguration";

import messages from "./messages";

import { Widget } from "@/components/Widgets/interfaces";
import { fm } from "@/contexts/IntlContext";

interface WidgetErrorProps {
    permissionDeniedMessage: Stringish;
    userHasViewAccess: boolean;
    errors: string[];
}

export const WidgetError: React.FC<{ activeWidget: Widget; props: WidgetErrorProps; onUpdateData: () => void }> = ({
    activeWidget,
    props,
    onUpdateData,
}) => {
    const { permissionDeniedMessage, userHasViewAccess, errors } = props;
    const [showConfigureModal, setShowConfigureModal] = useState(false);
    if (showConfigureModal) {
        return (
            <FieldConfigurationModal
                widget={activeWidget}
                title={fm(messages.analysisConfigurationTitle)}
                open={showConfigureModal}
                onClose={() => setShowConfigureModal(false)}
            />
        );
    }
    return userHasViewAccess ? (
        <ErrorAlertOrConfiguration
            setShowConfigureModal={setShowConfigureModal}
            errs={errors}
            onUpdateData={onUpdateData}
        />
    ) : (
        <Stack>
            <Typography variant="h4" align="center">
                {permissionDeniedMessage.toString()}
            </Typography>
        </Stack>
    );
};
