import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { mockSessionContext } from "./mocks/data/authentication";
import { setupProxyAuth } from "./setupProxyAuth";
import { MicroAppMountValues } from "./types";

const useMockServer = process.env.REACT_APP_INITIALIZE_MOCK;
const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";

let root: ReturnType<typeof createRoot> | null = null;

function renderApp(rootNodeId: string, props: MicroAppMountValues) {
    const container = document.getElementById(rootNodeId);
    if (container) {
        root = createRoot(container);
        root.render(
            <React.StrictMode>
                <App {...props} />
            </React.StrictMode>
        );
    }
}

window.renderDashboards = (containerId, props: MicroAppMountValues) => {
    renderApp(containerId, props);
};

window.unmountDashboards = () => {
    root?.unmount();
};

async function main() {
    if (useMockServer) {
        const { worker } = await import("./mocks/browser");
        localStorage.setItem("tenant", process.env.REACT_APP_DEFAULT_TENANT || "");

        await worker.start({ onUnhandledRequest: "warn" });
        worker.listHandlers();
    }

    // This is only here very temporarily, hence I didn't write a helper or hook or whatever for it:
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            // Returns installed service workers
            if (registrations.length > 0) {
                Sentry.captureMessage("Service worker found, unregistering...", { tags: { app: "dashboards-app" } });
                registrations.forEach((registration: ServiceWorkerRegistration) => {
                    registration.unregister();
                });
            }
        });
    }
    caches?.delete("workbox-precache-v2-https://app.igniteprocurement.com/");

    if (!document.getElementById("Dashboards-container")) {
        if (isProxyToProductionBackend) {
            const sessionContext = await setupProxyAuth();
            renderApp("root", { sessionContext });
            return;
        }
        renderApp("root", {
            sessionContext: useMockServer
                ? mockSessionContext
                : {
                      id: process.env.REACT_APP_DEFAULT_USER_ID as string,
                      email: process.env.REACT_APP_DEFAULT_USER as string,
                      tenant: process.env.REACT_APP_DEFAULT_TENANT as string,
                  },
        });
    }
}
main().catch((err) => {
    console.error(err);
});
