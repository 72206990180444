import { ElasticField, isDateField } from "@ignite-analytics/elastic-fields";

import { formatEpochTimestampToDateString } from "@/lib/dateHelpers";

export function getMaxRelativeDate(dateField?: ElasticField): string | undefined {
    if (!dateField) {
        return undefined;
    }
    if (isDateField(dateField)) {
        const minDate = formatEpochTimestampToDateString(dateField.min.value);
        const maxDate = formatEpochTimestampToDateString(dateField.max.value);

        // If min and max is the same year, we bump the date to the first date of the next year.
        if (minDate.substring(0, 4) === maxDate.substring(0, 4)) {
            try {
                return `${parseInt(maxDate.substring(0, 4), 10) + 1}-01-01`;
            } catch {
                return undefined;
            }
        }
        if (dateField.maxDateForRelativeFilters) {
            return formatEpochTimestampToDateString(dateField.maxDateForRelativeFilters?.value);
        }
    }
}
