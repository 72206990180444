import { ElasticIndex } from "@ignite-analytics/elastic-fields";
import { TRANSACTIONS_GT } from "@ignite-analytics/global-types";

import { DataTable } from "@/__generated__/graphql";
import { useGetManyDataTables } from "../useGetManyDataTables";

const getIndexNameForDataTableV2 = (dataTable?: DataTable | null) => {
    if (!dataTable) return undefined;
    return `datatable_v2_${dataTable.id}`;
};

export const useIndexWithGlobalType = (globalTypeKey: string): ElasticIndex | undefined => {
    const { data } = useGetManyDataTables();
    const entities = data?.getManyDataTables?.entities;
    if (entities) {
        const v2Table = entities.find((table) => table.globalTypeKey === globalTypeKey);
        if (v2Table) {
            const dataSourceName = getIndexNameForDataTableV2(v2Table);
            return dataSourceName
                ? {
                      name: dataSourceName,
                      label: v2Table.name,
                      globalTypeKey: v2Table.globalTypeKey ?? undefined,
                  }
                : undefined;
        }
    }
    return undefined;
};

export const useTransactionsIndex = () => useIndexWithGlobalType(TRANSACTIONS_GT);
