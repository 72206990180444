import { defineMessages } from "react-intl";

const messages = defineMessages({
    saveButton: {
        id: "dashboardManager.customDashboardPage.saveButton",
        defaultMessage: "Save",
    },
    emptyDashboardLabel: {
        id: "dashboardManager.customDashboardPage.emptyDashboardLabel",
        defaultMessage: "There are currently no analyses on this dashboard",
    },
    addNewAnalysis: {
        id: "dashboardManager.customDashboardPage.addNewAnalysis",
        defaultMessage: "New analysis",
    },
    savingFailed: {
        id: "dashboardManager.customDashboardPage.savingFailed",
        defaultMessage: "An error occurred while trying to save Default Dashboard",
    },
    loading: {
        id: "dashboardManager.customDashboardPage.loading",
        defaultMessage: "Loading...",
    },
    globalFiltersMessage: {
        id: "dashboardManager.customDashboardPage.globalFiltersMEssage",
        defaultMessage: "Global filters are reset in edit mode for consitent results in analysis",
    },
});

export default messages;
