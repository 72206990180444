import { Trash as Delete, Pen as Edit, Eye as Show } from "@ignite-analytics/icons";
import { Avatar, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useCallback } from "react";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";
import { useUser } from "@/hooks/useUsers";

interface Props {
    userId: string;
    isOwner: boolean;
    value: "read" | "write";
    onChange: (userId: string, newPermission: "read" | "write") => void;
    onRemove: (userId: string) => void;
    disabled?: boolean;
}

const UserListItem: React.FC<Props> = ({ userId, isOwner, value, onChange, onRemove, disabled }) => {
    const { user } = useUser(userId);

    const handleChange = useCallback(
        (_: React.MouseEvent<HTMLElement>, newAlignment: "read" | "write") => {
            if (newAlignment === null) return;
            onChange(userId, newAlignment);
        },
        [onChange, userId]
    );
    return (
        <>
            <Grid item p={0} xs={8}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack gap={2} direction="row">
                        <Avatar alt={`${user?.firstName ?? ""} ${user?.lastName ?? ""}`} />
                        <Stack sx={{ textOverflow: "ellipsis", overflow: "hidden" }} justifyContent="center" gap={0.5}>
                            <Typography noWrap sx={{ minHeight: "1rem" }} variant="subtitle2">
                                {user?.firstName ?? ""} {user?.lastName ?? ""}
                            </Typography>
                            <Typography noWrap color="grey.600" variant="body2">
                                {user?.email ?? ""}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item p={0} xs={3}>
                {!isOwner && (
                    <Stack direction="row" alignItems="center" gap={2}>
                        <ToggleButtonGroup
                            value={value}
                            exclusive
                            disabled={isOwner || disabled}
                            onChange={handleChange}
                        >
                            <ToggleButton value="read" size="small">
                                <Show fontSize="small" />
                            </ToggleButton>
                            <ToggleButton value="write" size="small">
                                <Edit fontSize="small" />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography alignContent="center" variant="body2">
                            {value === "write" ? fm(messages.canModify) : fm(messages.canView)}
                        </Typography>
                    </Stack>
                )}
            </Grid>
            <Grid item xs={1}>
                <Stack alignItems="center" direction="row" justifyItems="center">
                    {isOwner ? (
                        <Typography sx={{ ml: "auto" }} variant="body2" color="GrayText">
                            {fm(messages.owner)}
                        </Typography>
                    ) : (
                        <IconButton sx={{ ml: "auto" }} color="primary" onClick={() => onRemove(userId)} size="small">
                            <Delete fontSize="inherit" />
                        </IconButton>
                    )}
                </Stack>
            </Grid>
        </>
    );
};
export default UserListItem;
