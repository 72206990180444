import * as AuReServices from "../../services";
import { AggregationBucket, ElasticResponse } from "../interfaces";
import messages from "../messages";
import { RatingTableData, TableColumn } from "../PotentialAndRiskTable/DataTableCell/interfaces";

import { percentToColorGradient, snakeToCamelDataColumn } from "./risk";

import { fm, staticFormatMessage } from "@/contexts/IntlContext";
import { formatNumber, toPercent } from "@/lib/convert";

export const getSource = (bucket: AggregationBucket) => {
    return bucket.source.hits.hits[0];
};

const potentialHeaders: TableColumn[] = [
    {
        header: fm(messages.columnHeadSupplier),
        accessor: "name",
        sticky: "left",
    },
    {
        header: fm(messages.columnHeadRating),
        accessor: "rating",
        tooltip: fm(messages.potentialTooltip).toString(),
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.columnHeadShareOfWallet),
        accessor: "sow",
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.columnHeadCagr),
        tooltip: fm(messages.CAGRTooltip).toString(),
        accessor: "cagr",
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.columnHeadEBIT),
        accessor: "ebitPercent",
        visual: { type: "progress", showVisual: true, showValue: true },
    },
    {
        header: fm(messages.columnHeadRoce),
        accessor: "roce",
        visual: { type: "progress", showVisual: true, showValue: true },
        tooltip: fm(messages.ROCETooltip).toString(),
    },
    {
        header: fm(messages.columnHeadNumTransaction),
        accessor: "transactionCount",
        tooltip: fm(messages.transLTMTooltip).toString(),
        align: "right",
    },
    {
        header: fm(messages.columnHeadSpend),
        tooltip: fm(messages.spendLTMTooltip).toString(),
        accessor: "spendLast_12Months",
        align: "right",
    },
    {
        header: fm(messages.columnHeadCategory),
        accessor: "largestCategory",
    },
];

export const structureRowObject = (
    supplier: AggregationBucket,
    rating: number,
    supplierKey: "carg" | "ebitPercent" | "roce" | "sow" | "equityRatio"
) => {
    return {
        percent: rating,
        value: supplier[supplierKey]?.value
            ? toPercent(supplier[supplierKey].value, 1)
            : staticFormatMessage(messages.noDataLabel),
        color: percentToColorGradient(rating),
    };
};

/*
 * Prepares the data for being inserted into a Table component
 */
export function structurePotentialRatings(
    inputData: ElasticResponse<Record<string, unknown>>,
    year: number,
    supplierIdFieldId: string,
    supplierLabelFieldId: string,
    hasCategoryGt?: boolean
): RatingTableData {
    const rawData: AggregationBucket = inputData.aggregations;
    const formatCagr = (cagrValue: number) => {
        return cagrValue !== 3 ? toPercent(cagrValue, 1) : "∞";
    };

    return {
        columns: potentialHeaders,
        total: rawData.supplierCount.value,
        rows: rawData.suppliers.buckets.map((supplier) => {
            const supplierSrc = getSource(supplier)._source;
            const supplierName = supplierSrc[snakeToCamelDataColumn(supplierLabelFieldId)];
            const supplierID = supplierSrc[snakeToCamelDataColumn(supplierIdFieldId)];
            const lastYearAccountYear = supplier.accountYear.value;

            // Checking if using ROA
            const usingRoa = supplier.capitalEmployed.value < 0 || supplier.roce == null;
            // Checking if financial data is from same year as finalYear
            const oldFinancialData = lastYearAccountYear < year;

            const categorySrc =
                hasCategoryGt &&
                supplier.categories.buckets.length > 0 &&
                getSource(supplier.categories.buckets[0])._source;
            const categoryLabelField = categorySrc
                ? Object.keys(categorySrc).find((item) => {
                      return item.endsWith("Name");
                  })
                : undefined;
            const category = categoryLabelField
                ? categorySrc[categoryLabelField]
                : supplier.categories.buckets.length > 0 && supplier.categories.buckets[0].key;

            // List of objects (cells) to push to data.rows
            const sowScore = AuReServices.convertSoW(supplier.sow ? supplier.sow.value : 0);
            const cagrScore = supplier.cagr?.value && AuReServices.map(supplier.cagr.value, -0.2, 0.4);
            const ebitMarginScore =
                supplier.ebitPercent?.value && AuReServices.map(supplier.ebitPercent.value, -0.05, 0.3);
            const roceScore = supplier.roce?.value && AuReServices.map(supplier.roce.value, -0.05, 0.3);

            return {
                name: AuReServices.getTooltip(
                    oldFinancialData,
                    usingRoa,
                    supplierName,
                    lastYearAccountYear,
                    year,
                    supplierID
                ),
                rating: {
                    percent: supplier.potentialRating?.value ? supplier.potentialRating.value / 100 : 0,
                    value: supplier.potentialRating?.value
                        ? formatNumber(supplier.potentialRating.value, 0)
                        : staticFormatMessage(messages.noDataLabel),
                },
                sow: structureRowObject(supplier, sowScore, "sow"),
                cagr: {
                    percent: cagrScore,
                    value: supplier.cagr?.value
                        ? formatCagr(supplier.cagr.value)
                        : staticFormatMessage(messages.noDataLabel),
                    color: percentToColorGradient(cagrScore),
                },
                ebitPercent: structureRowObject(supplier, ebitMarginScore, "ebitPercent"),
                roce: structureRowObject(supplier, roceScore, "roce"),
                transactionCount: formatNumber(
                    supplier.spendLast_12Months ? supplier.spendLast_12Months.docCount : 0,
                    0
                ),
                spendLast_12Months: formatNumber(
                    supplier.spendLast_12Months ? supplier.spendLast_12Months.totalValue.value : 0
                ),
                largestCategory: category,
            };
        }),
    };
}
