import { ChartConfig } from "@ignite-analytics/pivot-charts";

import messages from "./messages";

import { fm } from "@/contexts/IntlContext";
import { formatNumber, toPercent } from "@/lib/convert";

export const CHART_CONFIG: ChartConfig = {
    legends: false,
    xAxis: {
        title: fm(messages.tableHeadSow),
        range: {
            min: 0.1,
            max: 120,
        },
        labels: {
            format: "{value} %",
        },
        type: "logarithmic",
    },
    optionsPerAgg: [
        {
            type: "bubble",
            labelDisplayMode: false,
            yAxis: {
                title: fm(messages.tableHeadEBIT),
                range: { min: -50, max: 100 },
            },
            stackMode: "percent",
            tooltipFormatter() {
                const { x, y, name, zValue, pointMeta } = this.point;
                // Values needs to get converted back from percentage-adjustments
                const zVal = (zValue ?? 0) / 100;
                const sow = x / 100;
                const ebit = y && y / 100;
                const revenue = zVal / sow;
                const financialYearKey = pointMeta && Object.keys(pointMeta).find((key) => key.includes("Year"));
                const financialYear = pointMeta && financialYearKey && pointMeta[financialYearKey] / 100;

                return fm(messages.bubbleTooltip, {
                    name,
                    revenue: formatNumber(revenue),
                    spend: formatNumber(zVal),
                    isLarge: sow === 120 ? " > " : "",
                    sow: toPercent(sow, 2),
                    ebitIsLow: ebit ? (ebit <= -100 ? " < " : "") : "",
                    ebit: toPercent(ebit, 2),
                    year: financialYear,
                }).toString();
            },
        },
    ],
};
