import { RequestOptions } from "@ignite-analytics/entities";
import { isNullOrUndefined } from "@ignite-analytics/general-tools";
import { X as CloseIcon } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";

import { DEFAULT_GUARD } from "./constants";
import { getPropagationDescription } from "./helpers";
import messages from "./messages";
import UserListItem from "./UserListItem";
import UserSearch from "./UserSearch";

import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import { useCreateActionWithGetParams, useModelGuard, useUpdateModelGuardAction } from "@/entities/modelGuards";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/usePermissionCheck";
import { useUsers } from "@/hooks/useUsers";
import globalMessages from "@/lib/messages/globalMessages";
import { Model, ModelGuard } from "@/lib/permissions";

interface Props {
    modelGuard: ModelGuard | null;
    objectId: number;
    objectOwners: string[]; // List of user ids for users who own the object
    model: Model;
    onClose: () => void;
    onUpdate: (guard: ModelGuard) => Promise<void>;
    open: boolean;
    name: string;
    childModels: string[];
}

function unique<T>(list: T[]): T[] {
    return [...new Set(list.filter((l) => !isNullOrUndefined(l)))];
}

const permissionModes = [
    { label: "Unrestricted", value: "unprotected", description: fm(messages.unprotectedDetails) },
    { label: "Read-only", value: "write-protected", description: fm(messages.limitedWriteDetails) },
    { label: "Private", value: "read-protected", description: fm(messages.individualsOnlyDetails) },
] as const;

const PermissionModal: React.FC<Props> = ({
    objectId,
    objectOwners,
    open,
    model,
    onClose,
    modelGuard: _modelGuard,
    childModels,
    onUpdate,
    name,
}) => {
    const [saving, setSaving] = useState(false);
    const currentUserId = useOryUserIdOrThrow();
    const [guard, setGuard] = useState<ModelGuard | Omit<ModelGuard, "id">>(_modelGuard ?? DEFAULT_GUARD);

    const currentPerm: (typeof permissionModes)[number]["value"] | undefined = useMemo(() => {
        const { standardWrite, standardRead } = guard;
        if (standardWrite) {
            return "unprotected";
        }
        if (standardRead) {
            return "write-protected";
        }
        return "read-protected";
    }, [guard]);

    const createModelGuard = useCreateActionWithGetParams();
    const updateModelGuard = useUpdateModelGuardAction({ id: _modelGuard?.id ?? NaN });

    const populateIndividualPermissions = useCallback(
        (readUsers: string[], writeUsers: string[]) => {
            const owners = objectOwners.map((userId) => ({ userId, read: true, write: true }));
            return [
                ...owners,
                ...writeUsers
                    .filter((userId) => !objectOwners.includes(userId))
                    .map((userId) => ({ userId, read: true, write: true })),
                ...readUsers
                    .filter((userId) => !writeUsers.includes(userId) && !objectOwners.includes(userId))
                    .map((userId) => ({ userId, read: true, write: false })),
            ];
        },
        [objectOwners]
    );

    const [individualPermissions, setIndividualPermissions] = useState<
        undefined | { userId: string; read: boolean; write: boolean }[]
    >(() =>
        populateIndividualPermissions(
            (_modelGuard ?? DEFAULT_GUARD).readUsers,
            (_modelGuard ?? DEFAULT_GUARD).writeUsers
        )
    );

    const onStandardPermissionChange = (level: (typeof permissionModes)[number]["value"]) => {
        if (!guard) return;
        const newPerms = update(guard, {
            standardWrite: { $set: level === "unprotected" },
            standardRead: { $set: level !== "read-protected" },
            ...(currentUserId && {
                readUsers: (prevs) => unique([...prevs, ...objectOwners, currentUserId]),
                writeUsers: (prevs) => unique([...prevs, ...objectOwners, currentUserId]),
            }),
        });
        setGuard(newPerms);
        populateIndividualPermissions(newPerms.readUsers, newPerms.writeUsers);
    };

    const onUserPermissionChange = useCallback(
        (userId: string, read: boolean, write: boolean) => {
            if (!guard) return;
            const newPerms = update(guard, {
                writeUsers: (previousIds) =>
                    write ? unique([...previousIds, userId]) : previousIds.filter((u) => u !== userId),
                readUsers: (previousIds) =>
                    read ? unique([...previousIds, userId]) : previousIds.filter((u) => u !== userId),
            });
            setGuard(newPerms);

            // update separately so new rows are added to the bottom
            setIndividualPermissions((lst) => {
                if (!lst) return undefined;
                if (!read && !write) {
                    return lst.filter((u) => u.userId !== userId);
                }
                if (lst.some((u) => u.userId === userId)) {
                    return lst.map((u) => (u.userId !== userId ? u : { ...u, read, write }));
                }
                return [...lst, { userId, read, write }];
            });
        },
        [guard]
    );

    const onSave = async () => {
        setSaving(true);
        const options: RequestOptions = { service: "dashboards" };
        const promise = "id" in guard ? updateModelGuard(guard, options) : createModelGuard(guard, { objectId, model });

        await promise.then(async (res) => {
            if (!res) return;
            await onUpdate(res);
        });
        setSaving(false);
        onClose();
    };

    const canEditAfterSave =
        guard && (guard.standardWrite || (currentUserId && guard.writeUsers.includes(currentUserId)));
    const canWriteToGuard = useBackwardCompatiblePermissionCheck("change", {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });

    return (
        <Dialog scroll="body" disablePortal maxWidth="md" fullWidth open={open} onClose={onClose}>
            <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
                <Typography pl={1} variant="h4">
                    {fm(messages.permissionModalTitle, { name })}
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <DialogContent sx={{ overflow: "hidden" }}>
                <Stack gap={2} p={2}>
                    <RadioGroup
                        sx={{ gap: 2 }}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (!canWriteToGuard) return;
                            onStandardPermissionChange(value as (typeof permissionModes)[number]["value"]);
                        }}
                        value={currentPerm}
                    >
                        {permissionModes.map((p) => (
                            <FormControlLabel
                                sx={{
                                    py: 1,
                                    "&:has(> .MuiRadio-root.Mui-checked)": {
                                        backgroundColor: (theme) => theme.palette.primary.hover,
                                        borderRadius: 1,
                                    },
                                }}
                                key={p.value}
                                disabled={!canWriteToGuard}
                                value={p.value}
                                label={
                                    <Stack>
                                        <Typography variant="h6">{p.label.toString()}</Typography>
                                        <Typography sx={{ color: "GrayText" }} variant="body2">
                                            {p.description}
                                        </Typography>
                                    </Stack>
                                }
                                control={<Radio />}
                            />
                        ))}
                    </RadioGroup>
                    {childModels.length > 0 && getPropagationDescription(childModels, model) && (
                        <Stack py={1}>
                            <FormGroup>
                                <FormControlLabel
                                    label={getPropagationDescription(childModels, model)}
                                    control={
                                        <Checkbox
                                            value={guard.propagate}
                                            onChange={(_, value) => setGuard({ ...guard, propagate: value })}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Stack>
                    )}
                    <Collapse in={currentPerm !== "unprotected"}>
                        <Stack gap={2}>
                            <Divider />
                            <Typography variant="h6">{fm(messages.headerIndividualPermissions)}</Typography>
                            <Stack gap={0.5}>
                                <Grid
                                    container
                                    rowGap={2}
                                    direction="row"
                                    alignItems="stretch"
                                    justifyContent="stretch"
                                >
                                    {(individualPermissions || []).map(({ write, userId }) => (
                                        <UserListItem
                                            key={userId}
                                            disabled={!canWriteToGuard}
                                            isOwner={objectOwners.includes(userId)}
                                            userId={userId}
                                            value={write ? "write" : "read"}
                                            onChange={(id, value) => {
                                                onUserPermissionChange(id, value === "read", value === "write");
                                            }}
                                            onRemove={(id) => onUserPermissionChange(id, false, false)}
                                        />
                                    ))}
                                    <UserSearch
                                        disabled={!canWriteToGuard}
                                        selected={[...(guard.writeUsers || []), ...(guard.readUsers || [])]}
                                        onAdd={(user) => {
                                            onUserPermissionChange(user.id, true, false);
                                        }}
                                    />
                                </Grid>
                            </Stack>
                        </Stack>
                    </Collapse>
                </Stack>
                <Stack gap={1} pt={2}>
                    {!canWriteToGuard && <Alert severity="info">{fm(messages.restrictedAccess)}</Alert>}
                    {!canEditAfterSave && <Alert severity="warning">{fm(messages.youWillLoseAccess)}</Alert>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack justifyContent="flex-end" direction="row" gap={2}>
                    <Button variant="text" color="secondary" onClick={onClose}>
                        {fm(globalMessages.cancelButton)}
                    </Button>
                    {/*  eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <LoadingButton loading={saving} disabled={!canWriteToGuard} color="primary" onClick={onSave}>
                        {fm(globalMessages.saveButton)}
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const PermissionModalWrapper: React.FC<Omit<Props, "modelGuard"> & { guard: number | undefined | null }> = ({
    guard,
    ...props
}) => {
    // Checking if all users are fetched to await rendering the permission modal
    const { users } = useUsers();
    const modelGuard = useModelGuard(guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });

    if ((guard && !modelGuard) || users.length === 0) {
        return (
            <Dialog scroll="body" disablePortal maxWidth="md" fullWidth open={props.open} onClose={props.onClose}>
                <Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
                    <Typography pl={1} variant="h4">
                        {fm(messages.permissionModalTitle, { name: props.name })}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <DialogContent sx={{ overflow: "hidden" }}>
                    <Stack alignItems="center" py={10} gap={3}>
                        <CircularProgress />
                        <Typography variant="body1">{fm(messages.loadingDashboardPermissions)}</Typography>
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }

    // We want to remount the permission modal if it is opened and closed.
    if (!props.open) return null;
    return <PermissionModal {...props} modelGuard={guard && modelGuard ? modelGuard : null} />;
};
export default PermissionModalWrapper;
