import { defineMessages } from "react-intl";

const messages = defineMessages({
    columnOwner: {
        defaultMessage: "Owner",
        id: "CustomDashboardPage.DashboardOverview.columnOwner",
    },
    columnCollection: {
        defaultMessage: "Collection",
        id: "CustomDashboardPage.DashboardOverview.columnCollection",
    },
    columnLastUpdated: {
        defaultMessage: "Last updated",
        id: "CustomDashboardPage.DashboardOverview.columnLastUpdated",
    },
    columnTitle: {
        defaultMessage: "Title",
        id: "CustomDashboardPage.DashboardOverview.columnTitle",
    },
    searchDashboardLabel: {
        defaultMessage: "Search dashboards",
        id: "CustomDashboardPage.DashboardOverview.searchDashboardLabel",
    },
    filterUsersPlaceholder: {
        defaultMessage: "Creator",
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.filterUsersPlaceholder",
    },
    filterUsersLabel: {
        defaultMessage: "Filter by creator...",
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.filterUsersLabel",
    },
    sharedWith: {
        defaultMessage: "Shared with",
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.sharedWith",
    },
    manageDashboardTableTitle: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.manageDashboardTableTitle",
        defaultMessage: "Title",
    },
    manageDashboardTableCollection: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.manageDashboardTableCollection",
        defaultMessage: "Collection",
    },
    manageDashboardTableUpdated: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.manageDashboardTableUpdated",
        defaultMessage: "Updated",
    },
    manageDashboardTableCreated: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.manageDashboardTableCreated",
        defaultMessage: "Created",
    },
    manageDashboardTableUsersWithAccess: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.usersWithAccess",
        defaultMessage: "Access",
    },
    dashboardOverviewHeader: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.dashboardOverviewHeader",
        defaultMessage: "Dashboard Overview",
    },
    home: {
        id: "CustomDashboardPage.DashboardOverview.home",
        defaultMessage: "Home",
    },
    noDashboardDescription: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.noDashboardDescription",
        defaultMessage: "No description",
    },
    standardPermission: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.standardPermission",
        defaultMessage: "Standard permissions",
    },
    standardPermissionDescription: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.standardPermissionDescription",
        defaultMessage: "All",
    },
    other: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.otherCollection",
        defaultMessage: "None",
    },
    created: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.created",
        defaultMessage: "Created {at}",
    },
    owner: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.owner",
        defaultMessage: "Owner",
    },
    ownedBy: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.ownedBy",
        defaultMessage: "Owned by {name}",
    },
    everyone: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.everyone",
        defaultMessage: "Everyone with access to this workspace",
    },
    unknownOwner: {
        id: "CustomDashboardPage.DashboardOverview.SettingsPage.unknownOwner",
        defaultMessage: "Unknown",
    },
    changeHome: {
        id: "dashboardOverview.dashboardOverviewPage.modals.setHomeDashboardModal.title.",
        defaultMessage: "Change home dashboard?",
    },
    search: {
        id: "dashboardOverview.dashboardOverviewPage.search.",
        defaultMessage: "Search",
    },
});

export default messages;
