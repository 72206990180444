import { usePromiseState, WAITING } from "@ignite-analytics/api-client";
import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { Filter, useFilters } from "@ignite-analytics/filters";
import { useLastNonNullishValue } from "@ignite-analytics/general-tools";
import { SUPPLIER_GT, TRANSACTION_DATE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";

import { WidgetError } from "../Components/WidgetError";
import { WidgetLoader } from "../Components/WidgetLoader";
import { POTENTIAL, QUERY_SIZE, RISK } from "../constants";
import { getErrorsFromPromise } from "../helpers";
import { DeprecatedWidget, Widget, WidgetProps } from "../interfaces";
import { TruncatedText } from "../style";

import messages from "./messages";

import { RatingTableData } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/DataTableCell/interfaces";
import { getMaxRelativeDate } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/helpers";
import * as RatingService from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/services";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { fm } from "@/contexts/IntlContext";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useExportWidget } from "@/hooks/useExport";

interface ProgressOrTrendCell {
    percent: number;
    value: string | number;
    color?: string;
}
interface Props extends WidgetProps<DeprecatedWidget, object> {
    activeWidget: DeprecatedWidget;
}

/**
 *  CONTAINER - PotentialRiskWidget
 * ================================================
 * Container for widget with potential or risk
 */
const dataFetchingFn = ({
    filters,
    activeWidget,
    cancelKey,
    maxRelativeDate,
    supplierFieldId,
    supplierLabelFieldId,
}: {
    activeWidget: Widget;
    filters: Filter[];
    cancelKey: string;
    maxRelativeDate: string;
    supplierFieldId?: string;
    supplierLabelFieldId?: string;
}) => {
    if (!("config" in activeWidget)) return new Promise<undefined>(() => undefined);
    const { config, type } = activeWidget;
    if (type === POTENTIAL || type === RISK) {
        return RatingService.getRatings(
            filters,
            config,
            type,
            maxRelativeDate,
            undefined,
            config[QUERY_SIZE],
            undefined,
            cancelKey,
            supplierFieldId,
            supplierLabelFieldId,
            undefined
        );
    }
    return new Promise<undefined>(() => undefined);
};

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    const { value } = props;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const PotentialRiskWidget: React.FC<Props> = ({ activeWidget }) => {
    const { type } = activeWidget;
    const [dataPromise, setDataPromise] = usePromiseState<RatingTableData | undefined>();

    const [exportReady, setExportReady] = useState(false);
    const customExportRef = React.useRef<HTMLTableElement>();
    const { updateExportFuncForWidget } = useExportDashboardContextOrThrow();

    const transactionIndex = useTransactionsIndex()?.name;
    const elasticFields = useElasticFields(transactionIndex);
    const findFieldIdByGtKey = (key: string): ElasticField | undefined =>
        elasticFields?.find((field) => field.globalTypeKey === key);
    const supplierElasticField = findFieldIdByGtKey(SUPPLIER_GT);
    const supplierFieldId: string | undefined = supplierElasticField?.fieldId;
    const supplierLabelFieldId: string | undefined =
        supplierElasticField && "labelField" in supplierElasticField ? supplierElasticField.labelField : undefined;
    const dateField = findFieldIdByGtKey(TRANSACTION_DATE_GT);
    const maxRelativeDate = getMaxRelativeDate(dateField);

    const filters = useFilters();
    const cancelKey = `PotentialWidget-${activeWidget.id}`;

    useEffect(() => {
        if (maxRelativeDate && filters !== undefined) {
            setDataPromise(
                dataFetchingFn({
                    filters: filters || [],
                    activeWidget,
                    cancelKey,
                    supplierFieldId,
                    supplierLabelFieldId,
                    maxRelativeDate,
                })
            );
        }
    }, [activeWidget, cancelKey, filters, setDataPromise, supplierFieldId, supplierLabelFieldId, maxRelativeDate]);
    const loading = dataPromise[WAITING];
    const data = useLastNonNullishValue(dataPromise.data);
    const { permissionDeniedMessage, userHasViewAccess, errors } = getErrorsFromPromise(dataPromise);

    const exportFunc = useExportWidget(
        activeWidget.title,
        activeWidget.elasticIndex,
        undefined,
        undefined,
        customExportRef
    );
    useEffect(
        function propagateExportFunc() {
            if (!activeWidget.id || !customExportRef.current) return;
            updateExportFuncForWidget(exportFunc, activeWidget.id.toString());
        },
        [activeWidget.id, activeWidget.title, exportFunc, updateExportFuncForWidget, exportReady]
    );

    const handleOnRef = useCallback((el: HTMLTableElement) => {
        customExportRef.current = el;
        setExportReady(true);
    }, []);

    if (errors?.length && maxRelativeDate && errors[0] !== "Cancel")
        return (
            <WidgetError
                onUpdateData={() =>
                    setDataPromise(
                        dataFetchingFn({
                            filters: filters || [],
                            activeWidget,
                            cancelKey,
                            supplierFieldId,
                            supplierLabelFieldId,
                            maxRelativeDate,
                        })
                    )
                }
                activeWidget={activeWidget}
                props={{ permissionDeniedMessage, userHasViewAccess, errors }}
            />
        );
    if (loading) {
        return <WidgetLoader />;
    }

    return data?.rows.length ? (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="Potential Risk widget table" ref={handleOnRef}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", m: 1 }}>
                                {fm(messages.supplier)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", m: 1 }}>
                                {fm(messages.spendLTM)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", m: 1 }}>
                                {fm(messages.columnHeadRating, {
                                    typeOf: fm(messages[type === POTENTIAL ? "potential" : "risk"]).toString(),
                                })}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.rows.map((supplier) => {
                        const rating = supplier.rating as ProgressOrTrendCell;
                        return (
                            <TableRow key={v4()}>
                                <TableCell>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "medium", m: 1 }}>
                                        {supplier.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "medium", m: 1 }}>
                                        <TruncatedText>{supplier.spendLast_12Months}</TruncatedText>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: "30%" }}>
                                    <Box>
                                        <LinearProgressWithLabel value={rating.percent * 100} />
                                    </Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", m: 1 }}>
                                        {rating.value}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <Grid alignItems="center" justifyContent="center">
            <Typography component="div">
                <Box sx={{ fontWeight: "bold", m: 1 }}>{fm(messages.noMatchingData)}</Box>
            </Typography>
        </Grid>
    );
};

const MemorizedComponent = React.memo(PotentialRiskWidget, (props, nextProps) => {
    return props.data === nextProps.data;
});

export default widgetWrapper(MemorizedComponent, TRANSACTIONS_GT);
