import { AGG_TYPES, asAnalysisItem, SplitItem, ValueConfiguration } from "@ignite-analytics/pivot-ts";

import { AnalysisWidget } from "../interfaces";

import { EditableAnalysisWidget } from "./intefaces";

/**
 * Function that ensures all items in an analysis query has a `uuid` property, which is used when editing.
 * It only creates new objects for the parts of the input that needs to be changed, so you can safely use strict equal (===)
 * on the output and parts of the output to check equality.
 */
export const asEditableWidget = <T extends AnalysisWidget | EditableAnalysisWidget>(
    widget: T
): EditableAnalysisWidget => {
    const { rowSplitItems: rows, columnSplitItems: cols, valueConfigurations: vals, ...rest } = widget;
    type InvalidSplit = SplitItem & { uuid?: string };
    type InvalidVal = ValueConfiguration & { uuid?: string };
    const [rowsAreValid, colsAreValid, valsAreValid] = [rows, cols, vals].map(
        (items) => !items || (items as (InvalidSplit | InvalidVal)[]).every((item) => "uuid" in item)
    );
    if (rowsAreValid && colsAreValid && valsAreValid) return widget as EditableAnalysisWidget;
    type ValidSplit = SplitItem & { uuid: string };
    type ValidVal = ValueConfiguration & { uuid: string };
    const rowSplitItems: ValidSplit[] = rowsAreValid
        ? (rows as ValidSplit[])
        : (rows as InvalidSplit[])
              .map((r) => ({ filters: [], aggregation: AGG_TYPES[r.type][0], ...r }))
              .map(asAnalysisItem);
    const columnSplitItems: ValidSplit[] = colsAreValid
        ? (cols as ValidSplit[])
        : (cols as InvalidSplit[])
              .map((r) => ({ filters: [], aggregation: AGG_TYPES[r.type][0], ...r }))
              .map(asAnalysisItem);
    return {
        ...rest,
        rowSplitItems,
        columnSplitItems,
        valueConfigurations: valsAreValid ? (vals as ValidVal[]) : (vals as InvalidVal[]).map(asAnalysisItem),
    };
};
