import { defineMessages } from "react-intl";

const messages = defineMessages({
    setAsHome: {
        id: "dashboardOverview.dashboardOverviewPage.DashboardList.setDashboardAsHome",
        defaultMessage: "Set as home dashboard",
    },
    publishToLibrary: {
        id: "dashboardOverview.dashboardOverviewPage.DashboardList.publishToLibrary",
        defaultMessage: "Publish to library",
    },
    copyDashboardError: {
        id: "dashboardOverview.dashboardOverviewPage.copyDashboardError",
        defaultMessage: "Something went wrong when copying the dashboard",
    },
    failedToUpdateHomeDashboard: {
        id: "dashboardOverview.dashboardOverviewPage.failedToUpdateHomeDashboard",
        defaultMessage: "Something went wrong when updating home dashboard",
    },
});

export default messages;
