import { ExclamationTriangle as ReportProblemIcon } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

import messages from "./messages";

import ErrorAlerts from "@/components/ErrorAlerts";
import { fm } from "@/contexts/IntlContext";

interface Props {
    /** Errors to dusplay */
    errs: string[];
    /** Action to display configurationmodal */
    setShowConfigureModal: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateData: () => void;
}

export const fieldConfigurationError = "Field configuration required";

const ErrorAlertOrConfiguration: React.VFC<Props> = ({ errs, setShowConfigureModal, onUpdateData }) => {
    const showConfiguration = errs.some((error) => error.includes(fieldConfigurationError));
    return showConfiguration ? (
        <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
            <Stack justifyContent="center" alignItems="center">
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <ReportProblemIcon color="warning" />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Typography variant="subtitle1" color="warning">
                        {fm(messages.noDataAvailable)}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Typography variant="body2" color="warning">
                        {fm(messages.couldNotLoad)}
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" py={2}>
                    <Button onClick={() => setShowConfigureModal(true)}>{fm(messages.configureButton)}</Button>
                </Stack>
            </Stack>
        </Stack>
    ) : (
        <ErrorAlerts errors={errs} onUpdateData={onUpdateData} />
    );
};
export default ErrorAlertOrConfiguration;
