import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { useCallback } from "react";

import { Widget } from "@/components/Widgets/interfaces";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import { useScriptsForDashboard } from "@/entities/scriptFields";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/usePermissionCheck";
import { Guarded, ModelGuard } from "@/lib/permissions";

export interface CustomDashboard extends Guarded {
    id: number;
    name: string;
    description: string;
    module: "home" | "analyze" | "prioritize" | "implement" | "followup" | "sourcing";
    owner: string;
    dashboardCollection: number | null;
    precedence: number;
    createdAt: string;
    updatedAt: string;
    widgets: Widget[];
    oldId?: number;
}

/**
 * More restricted version of CustomDashboard used in list endpoint.
 */
export type CustomDashboardListObject = Omit<CustomDashboard, "widgets">;

const listCreateResource = createListResource<CustomDashboardListObject>`/dashboards/user/${useOryUserIdOrThrow}/`;
const detailResource = createDetailResource<CustomDashboard>``;

export const {
    Provider: CustomDashboardContextProvider,
    useAll: useAllCustomDashboards,
    useDeleteAction: useDeleteCustomDashboardAction,
    useRefetchAction: useRefetchDashboardAction,
    useThisContext: useCustomDashboardContext,
    uncache: uncacheCustomDashboard,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: false,
    name: "Custom dashboards",
});

// We need to update the entities cache when using the addDetailObj does not, so the dashboard will fetch from the cache if changing micro apps
export const useUpdateDashboardDetailObjAndCache = () => {
    const { addDetailObj, addListObjs } = useCustomDashboardContext();

    return useCallback(
        (updatedDashboard: CustomDashboard, url: string) => {
            if (window._igniteEntitiesCache) {
                addDetailObj(updatedDashboard, url);
                addListObjs([updatedDashboard], url);
                window._igniteEntitiesCache[url] = Promise.resolve(updatedDashboard);
            }
        },
        [addDetailObj, addListObjs]
    );
};

export const useFetchDashboardWidgetsAction = (customDashboardId: number) => {
    const { detailObjs } = useCustomDashboardContext();
    const updateDashboardInEntities = useUpdateDashboardDetailObjAndCache();
    // Fetch relevant scripts for the dashboard
    useScriptsForDashboard(customDashboardId);

    // if dashboard already exists in the store, return it instead of fetching.
    const dashboard = detailObjs[customDashboardId];

    return useCallback(() => {
        if (dashboard) {
            return Promise.resolve(dashboard);
        }
        const url = `/dashboards/${customDashboardId}/`;
        if (!window._igniteEntitiesCache) {
            window._igniteEntitiesCache = {};
        }
        if (!window._igniteEntitiesCache[url]) {
            return Api.get<CustomDashboard>(url, { service: "dashboards" }).then((result: CustomDashboard) => {
                updateDashboardInEntities(result, url);
                return result;
            });
        }
        return window._igniteEntitiesCache[url];
    }, [customDashboardId, dashboard, updateDashboardInEntities]);
};

export const useUpdateMultipleCustomDashboards = () => {
    const { addListObjs } = useCustomDashboardContext();
    const userId = useOryUserIdOrThrow();
    return useCallback(
        (CustomDashboardsToUpdate: CustomDashboardListObject[], updateEntities = true) => {
            const url = `/dashboards/user/${userId}/`;
            return Api.patch<CustomDashboardListObject[]>(url, CustomDashboardsToUpdate, {
                service: "dashboards",
            }).then((updatedCustomDashboards: CustomDashboardListObject[]) => {
                updatedCustomDashboards.forEach(({ id }) => uncacheCustomDashboard(id));
                if (updateEntities) {
                    addListObjs(updatedCustomDashboards, url);
                }
                return updatedCustomDashboards;
            });
        },
        [addListObjs, userId]
    );
};

export const useReorderDashboards = () => {
    // Since the API request expect the request and response data to be of the same type need to assert the type of CustomDashboardListObject so typescript is happy
    function isCustomDashboardListObject(customDashboard: {
        id: number;
    }): customDashboard is CustomDashboardListObject {
        return customDashboard && customDashboard.id !== undefined;
    }

    const { addListObjs } = useCustomDashboardContext();
    const userId = useOryUserIdOrThrow();

    return useCallback(
        (customDashboardsToUpdate: CustomDashboardListObject[]) => {
            const url = `/dashboards/reorder-dashboards/`;
            return Api.patch(
                url,
                customDashboardsToUpdate.map(({ id, precedence }) => ({ id, precedence })),
                { service: "dashboards" }
            ).then((updatedCustomDashboards) => {
                updatedCustomDashboards.forEach(({ id }) => uncacheCustomDashboard(id));
                if (updatedCustomDashboards.every(isCustomDashboardListObject)) {
                    const entityUrl = `/dashboards/user/${userId}/`;
                    addListObjs(updatedCustomDashboards, entityUrl);
                    return updatedCustomDashboards;
                }
                throw new Error("Reorder did not return a list of CustomDashboardListObject");
            });
        },
        [addListObjs, userId]
    );
};

export const useDuplicateDashboardAction = () => {
    const { addListObjs } = useCustomDashboardContext();
    const userId = useOryUserIdOrThrow();
    return useCallback(
        (dashboard: CustomDashboardListObject) => {
            const url = `/dashboards/${dashboard.id}/copy/`;
            return Api.post(url, undefined, { service: "dashboards" }).then(
                (duplicatedDashboard: CustomDashboardListObject) => {
                    const entityUrl = `/dashboards/user/${userId}/`;
                    addListObjs([duplicatedDashboard], entityUrl);
                    return duplicatedDashboard;
                }
            );
        },
        [addListObjs, userId]
    );
};

export const useCreatePartialCustomDashboard = () => {
    const { addListObjs } = useCustomDashboardContext();
    const userId = useOryUserIdOrThrow();
    return useCallback(
        (dashboard: Pick<CustomDashboard, "name" | "description" | "dashboardCollection">) => {
            const url = `/dashboards/user/${userId}/`;
            return Api.post<CustomDashboard>(url, dashboard, {
                service: "dashboards",
            }).then((createdDashboard: CustomDashboard) => {
                addListObjs([createdDashboard], url);
                return createdDashboard;
            });
        },
        [addListObjs, userId]
    );
};

export const useEditableDashboards = () => {
    const backwardCompatibleUserId = useOryUserIdOrThrow();
    const hasModelPermission = useBackwardCompatiblePermissionCheck("change", {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });

    // TODO: Fix model guards for custom dashboards
    const modelGuards: ModelGuard[] = [];
    const dashboards = useAllCustomDashboards();

    return dashboards.filter((dashboard) => {
        const modelGuard = dashboard.guard && modelGuards[dashboard.guard];
        if (modelGuard && backwardCompatibleUserId) {
            return (
                (modelGuard.standardWrite && hasModelPermission) ||
                modelGuard.writeUsers.includes(backwardCompatibleUserId)
            );
        }

        return hasModelPermission;
    });
};

/**
 *
 * Copy a Custom Dashboard
 *
 * customDashboard possibleFields = ['name', 'client']
 *
 * @param {CustomDashboard} dashboardId: Id of the dashboard
 * @returns {Promise<CustomDashboard>}
 */

export const copyDashboard = (dashboardId: number): Promise<CustomDashboard> => {
    const url = `/dashboards/${dashboardId}/copy/`;
    return Api.post(url, undefined, { service: "dashboards" });
};
