import { isNotNullish } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { LabelledAnalysisQuery, PivotColumnData } from "@ignite-analytics/pivot-ts";

// Should match the regEx used in api-client toCamelCase
export const getAccessorKey = (str: string) =>
    str.replaceAll(".", "_").replace(/(?!^)_([a-z])/g, (_, char) => char.toUpperCase());

export const getAllBottomColumnFields = (
    columns: PivotColumnData[],
    query: LabelledAnalysisQuery,
    config: ChartConfig,
    key: string
): string[] => {
    return columns.reduce<string[]>((acc, column) => {
        if (column.children) {
            return [...acc, ...getAllBottomColumnFields(column.children, query, config, `${key}_${column.name}`)];
        }
        const bottomFields = query.valueAggregationItems
            .map((_, aggIndex) => {
                if (config.optionsPerAgg[aggIndex]?.isHidden) {
                    return null;
                }
                return getAccessorKey(`${key}_${column.name}_${aggIndex}`);
            })
            .filter(isNotNullish);
        return [...acc, ...bottomFields];
    }, []);
};

export const getAllSplitFields = (query: LabelledAnalysisQuery) => {
    if (query.rowSplitItems) {
        return query.rowSplitItems.map((splitItem) => splitItem.field);
    }
    return [query.columnSplitItems[query.columnSplitItems.length - 1].field];
};

export const getColumnWidth = (config: ChartConfig, key: string) => {
    return config.columnWidths?.[key];
};
