import { ElasticField } from "@ignite-analytics/elastic-fields";
import {
    ChartSimple as AddChartIcon,
    ChartArea,
    ChartBulletSolid,
    ChartLine,
    ChartSimpleHorizontal,
    ChartPie as Graph,
    Notes as TextFieldIcon,
} from "@ignite-analytics/icons";

import AnalysisCard from "./AnalysisCard";
import {
    ANALYSIS_NEW,
    CONTRACTS,
    CONTRACT_LOYALTY,
    CUSTOM_PERIOD,
    DEPRECATED_WIDGET_MODEL,
    DISPLAY_COMPARISION,
    FIELD_DISTRIBUTION_CHART_CONFIG,
    FIELD_DISTRIBUTION_NEW,
    FIELD_HISTORY,
    LARGEST_FIELD_NEW,
    NOTIFICATIONS,
    PERCENTAGE_LIMIT,
    POTENTIAL,
    QUERY_SIZE,
    RISK,
    SELECTED_FIELD,
    SHARED_DEFAULT_CONFIG,
    SPEND_DEVELOPMENT,
    TEXT_WIDGET,
} from "./constants";
import DeletedWidgetWarning from "./DeletedWidgetWarning";
import FieldHistoryWidget from "./FieldHistoryWidget";
import { getDefaultAnalysisWidget, getDefaultFieldDistrib, getDefaultLargestFieldWidget } from "./helpers";
import { AvailableWidget } from "./interfaces";
import LargestFieldWidget from "./LargestFieldWidget";
import messages from "./messages";
import PotentialRiskWidget from "./PotentialRiskWidget";
import SpendDevelopmentWidget from "./SpendDevelopmentWidget";
import TextWidget from "./TextWidget/index";

import {
    potentialOptions,
    riskOptions,
} from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/constants";
import { staticFormatMessage as formatMessage } from "@/contexts/IntlContext";

export const allWidgetsOrdered = (transactionIndex = "transactions", transactionElasticFields: ElasticField[] = []) =>
    ({
        // If an object in this array has the `editor` property, then an instance of the editor component will be rendered if
        //   the user chooses this widget in the WidgetMenu
        ANALYSIS_NEW: {
            message: messages.customAnalysis,
            component: AnalysisCard,
            defaultConfig: getDefaultAnalysisWidget(transactionIndex, transactionElasticFields),
            excelExport: true,
            icon: ChartArea,
            description: messages.customAnalysisDescription,
            redirectToAnalysis: true,
        },
        TEXT_WIDGET: {
            component: TextWidget,
            message: messages.textWidget,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: TEXT_WIDGET,
                modelName: DEPRECATED_WIDGET_MODEL,
                title: "Text",
                config: {},
            },
            icon: TextFieldIcon,
            description: messages.textWidgetDescription,
        },
        POTENTIAL: {
            component: PotentialRiskWidget,
            message: messages.potential,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: POTENTIAL,
                title: formatMessage(messages.potential),
                modelName: DEPRECATED_WIDGET_MODEL,
                config: { ...potentialOptions(), [QUERY_SIZE]: 4 },
            },
            icon: ChartBulletSolid,
            description: messages.potentialDescription,
        },
        RISK: {
            component: PotentialRiskWidget,
            message: messages.risk,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: RISK,
                title: formatMessage(messages.risk),
                modelName: DEPRECATED_WIDGET_MODEL,
                config: { ...riskOptions(), [QUERY_SIZE]: 4 },
            },
            icon: ChartBulletSolid,
            description: messages.riskDescription,
        },
        FIELD_DISTRIBUTION_NEW: {
            component: AnalysisCard,
            message: messages.fieldDistributions,
            defaultConfig: {
                ...getDefaultFieldDistrib(transactionIndex, transactionElasticFields),
                type: FIELD_DISTRIBUTION_NEW,
                title: formatMessage(messages.fieldDistributions),
                chartConfiguration: FIELD_DISTRIBUTION_CHART_CONFIG,
            },
            excelExport: true,
            icon: Graph,
            description: messages.fieldDistributionsDescription,
            redirectToAnalysis: true,
        },
        LARGEST_FIELD_NEW: {
            component: LargestFieldWidget,
            message: messages.largestFields,
            defaultConfig: {
                ...getDefaultLargestFieldWidget(transactionIndex, transactionElasticFields),
                title: formatMessage(messages.largestFields),
            },
            excludeFilterFields: ["transaction_date"],
            excelExport: true,
            icon: AddChartIcon,
            description: messages.largestFieldsDescription,
        },
        FIELD_HISTORY: {
            component: FieldHistoryWidget,
            message: messages.fieldHistory,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: FIELD_HISTORY,
                modelName: DEPRECATED_WIDGET_MODEL,
                title: formatMessage(messages.fieldHistory),
                config: {
                    [SELECTED_FIELD]: "spend",
                    [PERCENTAGE_LIMIT]: 0.3,
                    [DISPLAY_COMPARISION]: true,
                    [CUSTOM_PERIOD]: "month",
                },
            },
            excelExport: true,
            icon: ChartSimpleHorizontal,
            description: messages.fieldHistoryDescription,
        },
        SPEND_DEVELOPMENT: {
            component: SpendDevelopmentWidget,
            message: messages.spendDevelopment,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: SPEND_DEVELOPMENT,
                modelName: DEPRECATED_WIDGET_MODEL,
                title: formatMessage(messages.spendDevelopment),
                config: {},
            },
            excelExport: false,
            icon: ChartLine,
            description: messages.spendDevelopmentDescription,
        },
        NOTIFICATIONS: {
            component: DeletedWidgetWarning,
            message: messages.notifications,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: NOTIFICATIONS,
                title: formatMessage(messages.notifications),
                modelName: DEPRECATED_WIDGET_MODEL,
                config: { [QUERY_SIZE]: 4 },
            },
            icon: AddChartIcon,
            description: messages.notificationsDescription,
            hide: true,
        },
        CONTRACT_LOYALTY: {
            component: DeletedWidgetWarning,
            message: messages.contractCompliance,
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: CONTRACT_LOYALTY,
                title: formatMessage(messages.contractCompliance),
                modelName: DEPRECATED_WIDGET_MODEL,
                config: { [PERCENTAGE_LIMIT]: 0.2, [CUSTOM_PERIOD]: "month" },
            },
            icon: AddChartIcon,
            description: messages.contractComplianceDescription,
            hide: true,
        },
        CONTRACTS: {
            component: DeletedWidgetWarning,
            message: messages.contracts,
            tooltip: formatMessage(messages.contractsWidgetTooltip),
            defaultConfig: {
                ...SHARED_DEFAULT_CONFIG,
                type: CONTRACTS,
                title: formatMessage(messages.contracts),
                modelName: DEPRECATED_WIDGET_MODEL,
                config: { [QUERY_SIZE]: 4 },
            },
            icon: AddChartIcon,
            description: messages.contractsDescription,
            hide: true,
        },
    } as const);

export const getWidgetByType = (
    widgetType: keyof ReturnType<typeof allWidgetsOrdered>,
    transactionIndex = "transactions",
    transactionElasticFields: ElasticField[] = []
): AvailableWidget => {
    const widgetsByType = allWidgetsOrdered(transactionIndex, transactionElasticFields);
    return widgetsByType[widgetType];
};

export const getWidgetComponentByType = (widgetType: keyof ReturnType<typeof allWidgetsOrdered>) => {
    switch (widgetType) {
        case TEXT_WIDGET:
            return TextWidget;
        case POTENTIAL:
        case RISK:
            return PotentialRiskWidget;
        case LARGEST_FIELD_NEW:
            return LargestFieldWidget;
        case FIELD_HISTORY:
            return FieldHistoryWidget;
        case SPEND_DEVELOPMENT:
            return SpendDevelopmentWidget;
        case NOTIFICATIONS:
            return DeletedWidgetWarning;
        case CONTRACT_LOYALTY:
            return DeletedWidgetWarning;
        case CONTRACTS:
            return DeletedWidgetWarning;
        case ANALYSIS_NEW:
        case FIELD_DISTRIBUTION_NEW:
            return AnalysisCard;
        default:
            return AnalysisCard;
    }
};
