import { UserFragment } from "@/__generated__/graphql";

export const parseUsers = (unparsedUsers: UserFragment[], selected?: string | string[]) => {
    const updatedUsers = unparsedUsers.map((user) => ({
        text: `${user.firstName ?? ""} ${user.lastName ?? ""} ${user.email ? `(${user.email})` : ""}`,
        value: user,
        selected: Array.isArray(selected) ? selected.includes(user.id) : selected === user.id,
    }));
    return updatedUsers;
};
