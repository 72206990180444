import { Avatar } from "@mui/material";
import { useMemo } from "react";

import { UserFragment } from "@/__generated__/graphql";

export const UserAvatar = ({ user, size }: { user: UserFragment; size: "sm" | "md" }) => {
    const width = { sm: "1rem", md: "2rem" }[size];

    const initials = useMemo(() => {
        if (!user) return "";
        let text = "";
        if (user.firstName && user.firstName.length > 0) {
            text += user.firstName[0].toUpperCase();
        }
        if (user.lastName && user.lastName.length > 0) {
            text += user.lastName[0].toUpperCase();
        }
        return text;
    }, [user]);
    return <Avatar sx={{ bgColor: (theme) => theme.palette.primary.dark, width, height: width }}>{initials}</Avatar>;
};
