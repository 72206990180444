import { defineMessages } from "react-intl";

const messages = defineMessages({
    dashboardsToIncludePrompt: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.dashboardsToIncludePrompt",
        defaultMessage: "What dashboards should be included in this collection? *",
    },
    createCollectionPrompt: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.createCollectionPrompt",
        defaultMessage: "Let's create a new collection",
    },
    createButtonLabel: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.createButtonLabel",
        defaultMessage: "Create",
    },
    nameCollectionPrompt: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.nameCollectionPrompt",
        defaultMessage: "Name your Collection *",
    },
    selectedDashboards: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.selectedDashboards",
        defaultMessage: "Selected Dashboards:",
    },
    success: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.success",
        defaultMessage: "Successfully created collection",
    },
    error: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.error",
        defaultMessage: "Something went wrong in creating the dashboard collection",
    },
    collectionNameLabel: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.collection",
        defaultMessage: "Collection Name",
    },
    addDashboardToCollection: {
        id: "dashboardOverview.dashboardOverviewPage.newCollectionModal.addDashboardToCollection",
        defaultMessage: "Add dashboards to collection",
    },
});

export default messages;
