import { Skeleton, Stack } from "@mui/material";
import React from "react";

export const WidgetLoader = () =>
    React.useMemo(
        () => (
            <Stack direction="column" gap={2}>
                <Stack direction="row" alignItems="stretch" height="300px" gap={2}>
                    <Skeleton variant="rounded" width="50%" height="300px" />
                    <Skeleton variant="rounded" width="50%" height="300px" />
                </Stack>
                <Stack direction="row" alignItems="stretch" height="300px" gap={2}>
                    <Skeleton variant="rounded" width="50%" height="300px" />
                    <Skeleton variant="rounded" width="50%" height="300px" />
                </Stack>
            </Stack>
        ),
        []
    );
