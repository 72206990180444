import { Api, toSnakeCase } from "@ignite-analytics/api-client";
import { DUMMY_DEPARTMENT_ID } from "@ignite-analytics/department";
import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter } from "@ignite-analytics/filters";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { LabelledAnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import domtoimage from "dom-to-image-more";
import { saveAs } from "file-saver";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import { IntlShape } from "react-intl";

import { ExportImageResult, ExportMode } from "./interfaces";

import { getAggLabels, getTopLeftCellsLabel } from "@/components/PivotTable/helpers/headers";
import { getFilterFooterHTML, getFilterFooterTuples } from "@/components/Widgets/helpers";
import { Options } from "@/components/Widgets/interfaces";
import { ensureRelevantSplitItemsHaveLabelFields, updateFilters } from "@/hoc/PivotAnalysis/services";
import { Labelled } from "@/lib/labelled";
import { AnalysisFilterInput } from "@/__generated__/graphql";

exporting(Highcharts);
offlineExporting(Highcharts);

const stringToFilename = (title: string) => {
    // Creates a suitable title
    const formattedTitle = title.toLowerCase().replace(".", "").split(" ").join("-");
    const date = new Date().toISOString().slice(0, 10);
    return `${formattedTitle}-${date}`;
};

const appendFilterStringToElement = (
    element: HTMLElement | SVGSVGElement,
    filters: Labelled<Filter>[],
    options: Options,
    intl: IntlShape,
    elasticFields: ElasticField[]
) => {
    const containerWidth = element.clientWidth;
    const container = document.createElement("div");
    const textContainer = document.createElement("div");

    const computedStyle = window.getComputedStyle(element);
    Array.from(computedStyle).forEach((key) =>
        container.style.setProperty(key, computedStyle.getPropertyValue(key), "important")
    );
    container.style.setProperty("background", "white");
    container.style.setProperty("width", `${containerWidth}px`);
    textContainer.style.setProperty("padding", "10px");
    textContainer.style.setProperty("width", "100%;");

    textContainer.innerHTML += getFilterFooterHTML(intl, elasticFields, filters, options);

    const elementCopy = element.cloneNode(true) as HTMLElement;
    Array.from(computedStyle).forEach((key) =>
        elementCopy.style.setProperty(key, computedStyle.getPropertyValue(key), "important")
    );

    container.append(elementCopy);
    container.append(textContainer);
    document.body.appendChild(container);
    return container;
};

const isHighchart = (element: HTMLElement | Highcharts.Chart): element is Highcharts.Chart => {
    return element instanceof Highcharts.Chart;
};

export const createPng = async (
    element: HTMLElement | Highcharts.Chart,
    title: string,
    filters: Labelled<Filter>[],
    options: Options,
    intl: IntlShape,
    elasticFields: ElasticField[],
    disableDownload?: boolean
): Promise<ExportImageResult> => {
    const filterFooterTuples = getFilterFooterTuples(intl, elasticFields, filters, options);
    if (isHighchart(element)) {
        const prevTitle = element?.options?.title?.text;
        const prevSubtitle = element?.options?.subtitle?.text;
        element.setTitle({ text: title }, { text: getFilterFooterHTML(intl, elasticFields, filters, options) });

        const svg = element.getSVG();
        const blob = new Blob([svg], { type: "image/svg+xml" });
        const contentURL = URL.createObjectURL(blob);

        if (!disableDownload) {
            element.exportChartLocal({
                width: 0,
                scale: 4,
                type: "image/png",
                filename: stringToFilename(title),
                fallbackToExportServer: false,
                error: (opt, err) => {
                    console.error(`Export for ${opt} failed: ${err}`);
                },
            });
        }

        element.setTitle({ text: prevTitle }, { text: prevSubtitle });
        return {
            contentURL,
            mimeType: "image/svg+xml",
            filterFooterTuples,
        };
    }
    const container = appendFilterStringToElement(element, filters, options, intl, elasticFields);
    const blob = await domtoimage.toBlob(container, {
        filter: (node) => !node.getAttribute || !node.getAttribute("data-hide-from-export"),
        scale: 2,
    });
    const contentURL = URL.createObjectURL(blob);
    if (!disableDownload) {
        saveAs(blob, stringToFilename(title));
    }

    container.remove();
    return {
        contentURL,
        mimeType: "image/png",
        filterFooterTuples,
    };
};

const updateQuery = (
    query: LabelledAnalysisQuery | undefined,
    queryContainsValueAggs: boolean,
    elasticFields: ElasticField[] | undefined
) => {
    return query && queryContainsValueAggs && elasticFields
        ? {
              valueAggregationItems: query.valueAggregationItems.map((item) => updateFilters(item, elasticFields)),
              rowSplitItems: ensureRelevantSplitItemsHaveLabelFields(query.rowSplitItems, elasticFields),
              columnSplitItems: ensureRelevantSplitItemsHaveLabelFields(query.columnSplitItems, elasticFields),
              elasticIndex: toSnakeCase(query.elasticIndex, true),
          }
        : query;
};

export const exportDataToFile = (
    exportType: Exclude<ExportMode, "image" | "svg">,
    title: string,
    data: PivotResponse,
    elasticFields?: ElasticField[],
    query?: LabelledAnalysisQuery,
    chartConfig?: ChartConfig,
    filters?: Labelled<Filter>[]
): Promise<void> => {
    const url = `/departments/${DUMMY_DEPARTMENT_ID}/transactions/export/`;

    const exportTypeMappings = {
        excel: {
            extension: "xlsx",
            acceptHeader: "application/ms-excel",
        },
        csv: {
            extension: "csv",
            acceptHeader: "text/csv",
        },
    };

    const topLeft = query ? getTopLeftCellsLabel(query) : undefined;
    const aggLabels = query ? getAggLabels(query, data.columns, chartConfig) : undefined;
    const queryContainsValueAggs = query ? query.valueAggregationItems.length > 0 : false;
    const populatedQuery = updateQuery(query, queryContainsValueAggs, elasticFields);
    const formattedResponseData = queryContainsValueAggs
        ? {
              query: toSnakeCase(populatedQuery, false),
              filters,
              aggLabels,
              topLeft,
              shouldMergeCells: !chartConfig?.deNormalizeHeaders,
          }
        : {
              leftCells: topLeft ?? null,
              ...data,
              aggregationHeaders: aggLabels ?? null,
              numAgg: query?.valueAggregationItems.length,
              shouldMergeCells: !chartConfig?.deNormalizeHeaders,
          };

    return Api.export(
        url,
        { data: toSnakeCase(formattedResponseData, false), title },
        { accept: exportTypeMappings[exportType].acceptHeader, raw: true, convertCase: false }
    ).then((resp) => {
        const blob = new Blob([resp], { type: exportTypeMappings[exportType].acceptHeader });
        saveAs(blob, `${stringToFilename(title)}.${exportTypeMappings[exportType].extension}`);
    });
};

const base = {
    exclude: [],
    include: [],
    max: null,
    min: null,
    offset: null,
    periodUnit: null,
    periodLength: null,
    start: null,
    end: null,
    boolean: null,
    exists: null,
};

// Copied from apps/data-tables
export function mapFromFrontendFiltersToAnalysisFilter(filter: Filter): AnalysisFilterInput | undefined {
    switch (filter.filterType) {
        case "datefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                start: filter.start,
                end: filter.end,
            };
        case "relativedatefilter":
            return {
                ...base,
                field: filter.field,
                start: filter.start,
                end: filter.end,
                filterType: filter.filterType,
                offset: filter.offset,
                periodUnit: filter.periodUnit,
                periodLength: filter.periodLength,
            };
        case "rangefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                min: filter.min,
                max: filter.max,
            };
        case "includefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                include: filter.include,
                includeBlanks: filter.includeBlanks,
            };
        case "excludefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exclude: filter.exclude,
                excludeBlanks: filter.excludeBlanks,
            };
        case "searchtermfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                searchTerm: filter.searchTerm,
            };
        case "existsfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exists: filter.exists,
            };
        case "booleanfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                boolean: filter.value,
            };
        default:
            throw new Error(`Unsupported filter type - ${filter.filterType}`);
    }
}
