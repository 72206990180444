import messages from "./messages";

import { currentIntl, fm, staticFormatMessage } from "@/contexts/IntlContext";
import gm from "@/lib/messages/globalMessages";
import { BACKEND_MODELS, formatModelName, Model } from "@/lib/permissions";

const formatChildModels = (models: Model[]) => {
    if (models.length === 0) return "";
    if (models.length === 1) return formatModelName(models[0], currentIntl());
    return `${models
        .slice(0, -1)
        .map((m) => formatModelName(m, currentIntl()))
        .join(",")} ${fm(gm.and)} ${formatModelName(models[models.length - 1], currentIntl())}`;
};

export const getPropagationDescription = (models: string[], parentModel: Model) => {
    const displayModels = models.filter((model): model is Model =>
        Object.values(BACKEND_MODELS).includes(model as Model)
    );
    if (!displayModels.length) return "";
    return staticFormatMessage(messages.propagate, {
        childModels: formatChildModels(displayModels),
        typeSingular: formatModelName(parentModel, currentIntl(), true),
    });
};
