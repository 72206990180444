import { toCamelCase } from "@ignite-analytics/api-client";
import { arrayHelpers } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import {
    AggregationItem,
    LabelledAnalysisQuery,
    PivotColumnData,
    ValueConfiguration,
} from "@ignite-analytics/pivot-ts";

import messages from "../messages";

import { staticFormatMessage } from "@/contexts/IntlContext";
import { Labelled } from "@/lib/labelled";
import customAnalysisMessages from "@/lib/messages/customAnalysis";

const getAggDescription = (aggItem: Labelled<AggregationItem | ValueConfiguration>) => {
    if (aggItem) {
        const aggMessageKey = toCamelCase(
            "aggregation" in aggItem && aggItem.aggregation
                ? aggItem.aggregation
                : aggItem.type === "script"
                ? "script"
                : "sum",
            true
        ) as keyof typeof customAnalysisMessages;
        const messageKey = customAnalysisMessages[aggMessageKey];
        const aggregation = messageKey ? staticFormatMessage(messageKey).toLowerCase() : aggMessageKey;
        if (aggregation === "script") {
            return aggItem.label.toString().toLowerCase();
        }
        return `${aggregation} ${staticFormatMessage(messages.of)} ${aggItem.label.toString().toLowerCase()}`;
    }
    return "";
};

export const hasParent = (column: PivotColumnData): boolean => {
    return !!column.parentPivot;
};

export const getAggLabels = (
    query: LabelledAnalysisQuery,
    columns?: PivotColumnData[],
    config?: ChartConfig
): string[] => {
    return columns && columns.length
        ? columns.reduce((accumulator: string[], col) => {
              const stringD = getAggLabels(query, col.children, config);
              return [...stringD, ...accumulator];
          }, [])
        : query.valueAggregationItems.reduce((accumulator: string[], agg, i) => {
              const aggDesc: string = getAggDescription(agg);
              const headerName =
                  config?.optionsPerAgg[i]?.tableHeaderName && config?.optionsPerAgg[i]?.tableHeaderName !== undefined
                      ? config.optionsPerAgg[i].tableHeaderName
                      : "";
              return config?.optionsPerAgg[i]?.tableHeaderName && headerName !== undefined
                  ? [...accumulator, headerName]
                  : [...accumulator, aggDesc];
          }, []);
};

export const getTopLeftCellsLabel = ({
    columnSplitItems: colSplits,
    rowSplitItems: rowSplits,
}: LabelledAnalysisQuery) => {
    const labels: (string | string[])[] =
        colSplits.length || rowSplits.length
            ? [
                  ...arrayHelpers.range(colSplits.length).map((index) => {
                      const splitItem = colSplits[index];
                      const { label } = splitItem;
                      return label;
                  }),
                  rowSplits.map((item) => {
                      return item.label;
                  }),
              ]
            : [""];
    return labels.reduce(
        (accumulator: { column: string[]; row: string[] }, element) => {
            return element instanceof Array
                ? {
                      ...accumulator,
                      row: element.reduce((innerAcc: string[], innerElement: string) => {
                          return [...innerAcc, innerElement];
                      }, []),
                  }
                : { ...accumulator, column: [...accumulator.column, element] };
        },
        { column: [], row: [] }
    );
};
