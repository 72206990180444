import { Widget } from "@/components/Widgets/interfaces";
/**
 * Type W is a helping hand for the generic type W found in widgetWrapper
 * which is passed to getFilterIndex
 */
type W = Widget & {
    query?: {
        elasticIndex?: string;
    };
    elasticIndex?: string;
};

export const getFilterIndexFromWidget = (activeWidget: W | undefined) => {
    if (!activeWidget) return undefined;
    return (activeWidget.query && activeWidget.query.elasticIndex) || activeWidget.elasticIndex;
};
