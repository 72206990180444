import { usePromiseState, WAITING } from "@ignite-analytics/api-client";
import { ElasticField, useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { useDebounce, useLastNonNullishValue } from "@ignite-analytics/general-tools";
import { TRANSACTION_DATE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { pivotParser } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { WidgetError } from "../Components/WidgetError";
import { WidgetLoader } from "../Components/WidgetLoader";
import { getErrorsFromPromise } from "../helpers";
import { LargestFieldWidget, WidgetProps } from "../interfaces";

import { CHART_CONFIG } from "./constants";
import { getLargestFieldsQuery } from "./helpers";
import LargestFieldsTable from "./LargestFieldsTable";

import Chart from "@/components/Charts/Chart";
import { CHART_VIEW, VIEW_MODE } from "@/components/Widgets/constants";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { staticFormatMessage } from "@/contexts/IntlContext";
import { useGetDataFromAnalysisService } from "@/hoc/PivotAnalysis/services";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import { useExportWidget } from "@/hooks/useExport";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";
import { useColors } from "@/lib/colors";
import globalMessages from "@/lib/messages/globalMessages";

type Props = WidgetProps<LargestFieldWidget, PivotResponse>;

const LargestFieldCard: React.FC<Props> = ({ updateActiveWidget, activeWidget }: Props) => {
    const filters = useFilters();
    const fields = useElasticFieldsInContext();

    const [exportReady, setExportReady] = useState(false);
    const customExportRef = React.useRef<Highcharts.Chart | HTMLTableElement | HTMLElement>();
    const { updateExportFuncForWidget } = useExportDashboardContextOrThrow();

    const unlabelledQuery = useMemo(
        () => (filters && !!fields?.length ? getLargestFieldsQuery(activeWidget, fields) : undefined),
        [activeWidget, fields, filters]
    );
    const query = useLabelledAnalysisQuery(unlabelledQuery);
    const [dataPromise, setDataPromise] = usePromiseState<PivotResponse | undefined>();
    const colors = useColors();
    const intl = useIntl();

    const handleDrilldown = useCallback(
        (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => {
            updateActiveWidget({ ...activeWidget, ...newSplitItems });
        },
        [activeWidget, updateActiveWidget]
    );

    const getPivotGraphql = useGetDataFromAnalysisService();

    // As we are listening for changes to both filters and analysisQuery in updateDataPromise, we need to debounce the execution. Otherwise, we will get two requests for e.g. drilldowns.
    const debouncedSetDataPromiseExecuter = useDebounce(
        useCallback(
            (
                _analysisQuery: AnalysisQuery,
                _fields: ElasticField[],
                _filters: typeof filters,
                _setDataPromise: typeof setDataPromise,
                _getPivotGraphql: typeof getPivotGraphql
            ) => _setDataPromise(_getPivotGraphql(_analysisQuery, _fields, _filters, false)),
            []
        ),
        300
    );

    const updateDataPromise = useCallback(() => {
        if (fields?.length && filters !== undefined && query) {
            const transactionDateField =
                fields.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT)?.field || "transaction_date";

            debouncedSetDataPromiseExecuter(
                query,
                fields,
                // Remove transaction date filter because it is added to each value aggregation
                filters.filter((f) => !("field" in f) || f.field !== transactionDateField),
                setDataPromise,
                getPivotGraphql
            );
        }
    }, [debouncedSetDataPromiseExecuter, fields, filters, getPivotGraphql, query, setDataPromise]);

    useEffect(updateDataPromise, [updateDataPromise]);

    const loading = dataPromise[WAITING];
    const data = useLastNonNullishValue(dataPromise.data);
    const { permissionDeniedMessage, userHasViewAccess, errors } = getErrorsFromPromise(dataPromise);

    const parsedData = useMemo(
        () => query && data && pivotParser(intl, data, CHART_CONFIG, query, colors),
        [colors, data, intl, query]
    );

    const exportFunc = useExportWidget(
        activeWidget.title,
        activeWidget.elasticIndex,
        data ?? undefined,
        query,
        customExportRef
    );

    useEffect(
        function propagateExportFunc() {
            if (!activeWidget.id || !customExportRef.current) return;
            updateExportFuncForWidget(exportFunc, activeWidget.id.toString());
        },
        [activeWidget.id, activeWidget.title, exportFunc, updateExportFuncForWidget, exportReady]
    );

    const handleExportRefChange = useCallback((el: Highcharts.Chart | HTMLTableElement | HTMLElement) => {
        customExportRef.current = el;
        setExportReady(true);
    }, []);

    if (errors?.length && errors[0] !== "Cancel")
        return (
            <WidgetError
                onUpdateData={updateDataPromise}
                activeWidget={activeWidget}
                props={{ permissionDeniedMessage, userHasViewAccess, errors }}
            />
        );

    if (!query && !loading) return null;
    if (!data && !loading) {
        return <Box>{staticFormatMessage(globalMessages.noDataLabel)}</Box>;
    }
    if (activeWidget[VIEW_MODE] === CHART_VIEW) {
        return (
            <>
                {loading && <WidgetLoader />}
                {parsedData && (
                    <Chart
                        onRef={handleExportRefChange}
                        data={parsedData}
                        title={activeWidget.title}
                        hideTitle
                        config={CHART_CONFIG}
                        onDrilldown={handleDrilldown}
                        analysisQuery={query}
                        onSaveDrilldown={undefined}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {loading && <WidgetLoader />}
            {parsedData && query && data && (
                <LargestFieldsTable onRef={handleExportRefChange} activeWidget={activeWidget} data={data} />
            )}
        </>
    );
};

export default widgetWrapper(LargestFieldCard, TRANSACTIONS_GT);
