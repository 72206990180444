import { Api, toSnakeCase } from "@ignite-analytics/api-client";
import { Filter } from "@ignite-analytics/filters";

import { SortField } from "../interfaces";

import { structurePotentialRatings } from "./helpers/potential";
import { structureRiskRatings } from "./helpers/risk";
import { ElasticResponse } from "./interfaces";
import { RatingTableData } from "./PotentialAndRiskTable/DataTableCell/interfaces";

import { POTENTIAL, RISK } from "@/components/Widgets/constants";
import { parseFilterInput } from "@/components/Widgets/ContractLoyaltyWidget/services";
import { Options } from "@/components/Widgets/interfaces";

/**
 * Retrieves ratings of ratingType associated with a client
 */
const generateSearchFilterV2 = (searchterm: string, fieldIdV2: string) => {
    const searchfilter: Filter = {
        fieldId: fieldIdV2,
        field: fieldIdV2,
        filterType: "searchtermfilter",
        searchTerm: searchterm,
        type: "keyword",
    };
    return [searchfilter];
};

export function getRatings(
    filters: Filter[],
    customFilters: Options,
    ratingType: typeof RISK | typeof POTENTIAL,
    maxRelativeDate: string,
    sort?: SortField,
    size?: number,
    term?: string,
    cancelKey?: string,
    supplierIdFieldId?: string,
    supplierLabelFieldId?: string,
    hasCategoryGt?: boolean
): Promise<RatingTableData> {
    const searchTermFilter = supplierLabelFieldId && term ? generateSearchFilterV2(term, supplierLabelFieldId) : [];
    const filterCopy = [...filters, ...searchTermFilter];
    const customFilterCopy: Options = { ...customFilters };

    if (term) {
        customFilterCopy.minSpendLtm = 0;
    }

    const paramsString = `${parseFilterInput(filterCopy, customFilterCopy)}${Api.valuesToGetParams(
        { sort: JSON.stringify(toSnakeCase(sort, false)), size, maxRelativeDate },
        true
    )}`;
    const url = `/potential-and-risk/${ratingType.toLowerCase()}/${paramsString}`;
    return Api.get(url, { cancelKey, service: "dashboards" }).then((resp: ElasticResponse<Record<string, unknown>>) => {
        const year = new Date(maxRelativeDate).getFullYear() - 1;
        const func = ratingType === POTENTIAL ? structurePotentialRatings : structureRiskRatings;
        return func(
            resp,
            year,
            supplierIdFieldId ?? "supplierId",
            supplierLabelFieldId ?? "supplierName",
            hasCategoryGt
        );
    });
}
