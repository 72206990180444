import { X as CloseIcon, Trash as DeleteIcon, FloppyDisk as SaveIcon, User } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { clone } from "lodash-es";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { DeleteDashboardsModal } from "../DeleteDashboardModal";

import messages from "./messages";

import PermissionContainer from "@/components/PermissionContainer";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useAllDashboardCollections } from "@/entities/dashboardCollections";
import {
    CustomDashboardListObject,
    useDuplicateDashboardAction,
    useRefetchDashboardAction,
    useUpdateMultipleCustomDashboards,
} from "@/entities/dashboards";

interface EditDashboardModalProps {
    open: boolean;
    dashboard: CustomDashboardListObject;
    onManageAccessClick?: () => void;
    redirectOnDelete?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<"EDIT" | "SHARING" | false>>;
    setDashboard?: React.Dispatch<React.SetStateAction<CustomDashboardListObject | undefined>>;
    onClose?: () => void;
    onUpdate?: (newCustomDashboard: CustomDashboardListObject) => void;
}

const EDIT_DASHBOARD_MODAL_TESTID_PREFIX = "editDashboardModal-";
const COLLECTION_PICKER_TESTID = `${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}collectionPicker`;
const COLLECTION_PICKER_OPTION_PREFIX = `${COLLECTION_PICKER_TESTID}-option`;

export const EditDashboardModal: React.FC<EditDashboardModalProps> = ({
    open,
    setOpen,
    dashboard,
    setDashboard,
    onClose,
    onUpdate,
    redirectOnDelete,
    onManageAccessClick,
}) => {
    const collections = useAllDashboardCollections(undefined, {
        service: "dashboards",
    });
    const refetchDashboard = useRefetchDashboardAction(dashboard.id, undefined);
    const [editedDashboard, setEditedDashboard] = useState<CustomDashboardListObject | undefined>(undefined);
    const updateDashboards = useUpdateMultipleCustomDashboards();
    const [deleteDoubleCheck, setDeleteDoubleCheck] = useState(false);
    const navigate = useNavigate();
    const duplicateDashboard = useDuplicateDashboardAction();
    const informUser = useInformUser();

    const handleEdit = (
        property: "name" | "description" | "dashboardCollection",
        textValue: string,
        numberValue: number | null | undefined
    ) => {
        const edited = editedDashboard === undefined ? clone(dashboard) : clone(editedDashboard);
        if (property === "dashboardCollection") {
            edited[property] = numberValue ?? null;
        } else {
            edited[property] = textValue;
        }
        setEditedDashboard(edited);
    };

    const handleClose = () => {
        if (editedDashboard !== undefined) {
            refetchDashboard({ service: "dashboards" });
        }
        setEditedDashboard(undefined);
        if (setDashboard) {
            setDashboard(undefined);
        }
        if (onClose !== undefined) {
            onClose();
        } else if (setOpen) {
            setOpen(false);
        }
    };

    const handleAction = () => {
        if (editedDashboard !== undefined) {
            updateDashboards([editedDashboard]).then(() => {
                if (onUpdate) {
                    onUpdate(editedDashboard);
                }
                handleClose();
            });
        } else {
            handleClose();
        }
    };

    if (deleteDoubleCheck) {
        return (
            <DeleteDashboardsModal
                open={deleteDoubleCheck}
                dashboard={dashboard}
                redirectOnDelete={redirectOnDelete}
                onClose={() => {
                    setDeleteDoubleCheck(false);
                }}
                onSubmit={() => {
                    handleClose();
                }}
            />
        );
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Stack p={2} direction="row" justifyContent="space-between">
                <Typography pl={1} variant="h4">
                    {fm(messages.editDashboard)}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <DialogContent>
                <Stack>
                    {setOpen && (
                        <Button
                            startIcon={<User />}
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                if (onManageAccessClick) {
                                    onManageAccessClick();
                                }
                            }}
                        >
                            {fm(messages.manageAccess)}
                        </Button>
                    )}
                </Stack>
                <Stack alignItems="stretch" gap={2} pt={1}>
                    <TextField
                        name="nameInput"
                        data-testid={`${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}nameInput`}
                        variant="standard"
                        label={fm(messages.name)}
                        defaultValue={dashboard.name}
                        onChange={(e) => handleEdit("name", e.target.value, undefined)}
                    />
                    <TextField
                        data-testid={`${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}descriptionInput`}
                        label={fm(messages.description)}
                        name="descriptionInput"
                        defaultValue={dashboard.description}
                        onChange={(e) => handleEdit("description", e.target.value, undefined)}
                        multiline
                        rows={4}
                    />
                    <Stack>
                        <Typography variant="body1" color="primary">
                            {fm(messages.chooseCollection)}
                        </Typography>
                        <Select
                            data-testid={COLLECTION_PICKER_TESTID}
                            label=""
                            value={
                                editedDashboard ? editedDashboard.dashboardCollection : dashboard.dashboardCollection
                            }
                            name="collectionPicker"
                            color="primary"
                            onChange={(e) =>
                                handleEdit("dashboardCollection", "", e.target.value ? Number(e.target.value) : null)
                            }
                            displayEmpty
                            renderValue={(value) => {
                                const collection = collections.find((c) => c.id === value);
                                return collection ? collection.name : fm(messages.other);
                            }}
                        >
                            {collections.map((collection) => {
                                return (
                                    <MenuItem
                                        key={collection.id}
                                        value={collection.id}
                                        data-testid={`${COLLECTION_PICKER_OPTION_PREFIX}${collection.name}`}
                                    >
                                        {collection.name}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={undefined} key="otherOption">
                                {fm(messages.other)}
                            </MenuItem>
                        </Select>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                    <Stack direction="row" justifyContent="flex-start">
                        <Button
                            data-testid={`${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}duplicateButton`}
                            variant="outlined"
                            onClick={() => {
                                duplicateDashboard(dashboard)
                                    .then((res) => {
                                        informUser({
                                            message: fm(messages.dashboardCreatedMsg),
                                            type: "success",
                                        });
                                        navigate(`/dashboard/overview/${res.id}`);
                                    })
                                    .then(() => {
                                        informUser({
                                            message: fm(messages.dashboardCreatedError),
                                            type: "error",
                                        });
                                    });
                                handleClose();
                            }}
                        >
                            {fm(messages.duplicate)}
                        </Button>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" gap={2}>
                        <PermissionContainer
                            requiredPermissionTypes={["delete"]}
                            equivalentUserPermission={{
                                namespace: "dashboards",
                                relation: { object: "general", relation: "write" },
                            }}
                        >
                            <Button
                                data-testid={`${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}deleteButton`}
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    setDeleteDoubleCheck(true);
                                }}
                            >
                                {fm(messages.delete)}
                            </Button>
                        </PermissionContainer>
                        <Button
                            data-testid={`${EDIT_DASHBOARD_MODAL_TESTID_PREFIX}saveButton`}
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={handleAction}
                        >
                            {fm(messages.save)}
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
