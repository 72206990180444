import { BackendError } from "@ignite-analytics/api-client";
import { DUMMY_DEPARTMENT_ID } from "@ignite-analytics/department";
import { useDebounce } from "@ignite-analytics/general-tools";
import { track } from "@ignite-analytics/track";
import { Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { DASHBOARD_WIDGET_TEST_ID } from "../DashboardContainer/Dashboard/DashboardWidget";
import { buildNewLayout, widgetToId, widgetToLayout } from "../DashboardContainer/Dashboard/helpers";

import { WidgetActionType, widgetReducer } from "./helpers";
import messages from "./messages";

import { getWidgetByType } from "@/components/Widgets";
import { FIELD_DISTRIBUTION_NEW } from "@/components/Widgets/constants";
import { Widget } from "@/components/Widgets/interfaces";
import { useEventContext } from "@/contexts/EventContext";
import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import {
    CustomDashboard,
    useCustomDashboardContext,
    useFetchDashboardWidgetsAction,
    useUpdateDashboardDetailObjAndCache,
} from "@/entities/dashboards";

export * from "./constants";

const DashboardContext = React.createContext<{
    showWidgetMenu: boolean;
    dashboard?: CustomDashboard;
    widgets?: Widget[];
    layouts: Layout[];
    editing: boolean;
    dashboardError?: BackendError;
    setLayouts: React.Dispatch<React.SetStateAction<Layout[]>>;
    setShowWidgetMenu: React.Dispatch<React.SetStateAction<boolean>>;
    widgetDispatch: React.Dispatch<WidgetActionType>;
    setEditing: (value: boolean) => void;
} | null>(null);

export const useDashboardContextOrThrow = () => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw Error("useDashboardContext can only be used within a DashboardContextProvider");
    }
    return context;
};

export const useDashboardContextIfInDashboard = () => useContext(DashboardContext);

/**
 * Get the active widget state of a specific widget.
 * Allows us to provide a correct size and position when we want to dupliacte
 * an unsaved widget.
 */
export const useActiveDashboardWidget = <W extends Widget>(widget: W) => {
    const context = useContext(DashboardContext);
    return React.useMemo(() => {
        if (!context) return widget;

        /* Find layout matching the widget */
        const layoutId = widgetToId(widget);
        const matchingLayout = context.layouts.find((layout) => layout.i === layoutId);
        if (!matchingLayout) return widget;

        /* Extract and return merged information */
        const { w: width, h: height, x: xPosition, y: yPosition } = matchingLayout;
        return { ...widget, width, height, xPosition, yPosition };
    }, [context, widget]);
};

const dashboardErrorToMessage = (error: BackendError) => {
    if (!error.response) {
        return fm(messages.genericError);
    }
    switch (error.response.status) {
        case 403:
            return fm(messages.viewPermissionDenied);
        case 404:
            return fm(messages.dashboardNotFound);
        default:
            // Display each error message separated by a space
            // TODO: Make something more user friendly
            return Object.values(error.response?.data).flat().join(" ");
    }
};

export const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { dashboardId } = useParams<{ dashboardId?: string }>();
    /* Dashboard with null means an error occured while fetching */

    const lastViewedDashboardIdRef = useRef<string>();
    const navigate = useNavigate();
    const eventDispatch = useEventContext();
    const fetchDashboardWithWidgets = useFetchDashboardWidgetsAction(Number(dashboardId));
    const [dashboard, setDashboard] = useState<CustomDashboard>();
    const userId = useOryUserIdOrThrow();
    const [dashboardError, setDashboardError] = useState<BackendError>();
    const [layouts, setLayouts] = useState<Layout[]>([]);
    const [showWidgetMenu, setShowWidgetMenu] = useState(false);
    const [editing, _setEditing] = useState(false);
    const { listObjs, addListObjs } = useCustomDashboardContext();
    const updateDashboardInEntitites = useUpdateDashboardDetailObjAndCache();

    const debouncedScrollWidgetToView = useDebounce(
        useCallback((selector: string) => {
            document.querySelector(selector)?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, []),
        200
    );

    const widgetDispatch = useCallback(
        (action: WidgetActionType) => {
            const newWidgetState = widgetReducer(dashboard?.widgets || [], action);
            if (dashboard) {
                const url = `/dashboards/${dashboardId}/`;

                updateDashboardInEntitites({ ...dashboard, widgets: newWidgetState }, url);
            }
            if (action.type === "CREATE_ONE") {
                if (
                    action.data.type !== FIELD_DISTRIBUTION_NEW &&
                    action.data.modelName === getWidgetByType("ANALYSIS_NEW").defaultConfig?.modelName
                ) {
                    if (action.skipRedirectToAnalysis) return;
                    navigate(`/analytics/dashboard/${dashboardId}/edit-widget/${action.data.id}/`);
                    return;
                }
                debouncedScrollWidgetToView(`[data-testid=${DASHBOARD_WIDGET_TEST_ID}-${action.data.id}]`);
            }
        },
        [dashboard, updateDashboardInEntitites, dashboardId, debouncedScrollWidgetToView, navigate]
    );

    const setEditing = useCallback(
        (value: boolean) => {
            _setEditing(value);
            const widgets = dashboard?.widgets;
            if (!value || !widgets) return;

            const isExpanded = (l: Layout, w: Widget) => l.h !== w.height && l.w !== w.width;
            const compressExpandedWidgetLayoutsWhenEditing = (ls: Layout[]) =>
                ls.map((l) => {
                    const widget = widgets.find((w) => widgetToId(w) === l.i);
                    if (!widget || !isExpanded(l, widget)) return l;

                    // Resizing widget to regular size and dispatching resizing event
                    const resizedLayout = widgetToLayout(widget);
                    setTimeout(() => {
                        eventDispatch?.("onWidgetResize", resizedLayout);
                        window.dispatchEvent(new Event("resize"));
                    }, 100);
                    return resizedLayout;
                });
            setLayouts((prevLayouts) => buildNewLayout(compressExpandedWidgetLayoutsWhenEditing(prevLayouts), widgets));
        },
        [dashboard?.widgets, eventDispatch, setLayouts]
    );

    /**
     * Fetches inital data about a dashboard and its widgets
     */
    useEffect(
        function loadDashboardWithWidgets() {
            if (dashboardId) {
                const MAX_RETRIES = 1;
                const fetchDashboardWidgetsWithRetry = async (attempt = 0) => {
                    if (lastViewedDashboardIdRef.current !== dashboardId) {
                        setDashboard(undefined);
                        setLayouts([]);
                    }
                    return fetchDashboardWithWidgets()
                        .then((res) => {
                            /* Update entities cache if dashboard is fetched but not added til list objects */
                            if (!Object.keys(listObjs).includes(dashboardId.toString())) {
                                addListObjs([res], `/departments/${DUMMY_DEPARTMENT_ID}/dashboards/user/${userId}/`);
                            }
                            setDashboard(res);
                            setDashboardError(undefined);
                            if (lastViewedDashboardIdRef.current !== dashboardId) {
                                track("Dashboards: View", {
                                    dashboardId,
                                    name: res.name,
                                    numWidgets: res.widgets.length,
                                });
                                lastViewedDashboardIdRef.current = dashboardId;
                            }
                        })
                        .catch((err: BackendError) => {
                            if (err !== undefined) {
                                if (!err.response) {
                                    Sentry.captureException(err, {
                                        tags: {
                                            error: "Something went wrong when loading this dashboard.",
                                            errJSON: JSON.stringify(err).slice(0, 200),
                                            dashboardId,
                                            attempt,
                                            app: "dashboards-app",
                                        },
                                    });
                                    if (attempt < MAX_RETRIES) {
                                        return new Promise<void>((resolve) =>
                                            setTimeout(() => resolve(fetchDashboardWidgetsWithRetry(attempt + 1)), 500)
                                        );
                                    }
                                }
                                setDashboardError(err);
                            }
                        });
                };
                fetchDashboardWidgetsWithRetry();
            }
        },
        [dashboardId, fetchDashboardWithWidgets, listObjs, addListObjs, userId]
    );

    const values = useMemo(
        () => ({
            layouts,
            setLayouts,
            showWidgetMenu,
            setShowWidgetMenu,
            editing,
            setEditing,
            widgets: dashboard?.widgets,
            widgetDispatch,
            dashboard,
            dashboardError,
        }),
        [
            showWidgetMenu,
            editing,
            setShowWidgetMenu,
            setEditing,
            widgetDispatch,
            dashboard,
            layouts,
            setLayouts,
            dashboardError,
        ]
    );
    return dashboardError ? (
        <Stack justifyContent="center" alignItems="center">
            <Typography>{dashboardErrorToMessage(dashboardError)}</Typography>
        </Stack>
    ) : (
        <DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>
    );
};
