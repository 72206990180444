import { FilterCombinations } from "@ignite-analytics/filters";
import {
    ArrowDownTray as DownloadIcon,
    Pen as EditIcon,
    PenSlash as EditOffIcon,
    Eye as VisibilityIcon,
    EyeSlash as VisibilityOffIcon,
} from "@ignite-analytics/icons";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { debounce } from "lodash-es";
import React, { useCallback, useEffect, useState } from "react";

import DashboardContainer from "../../../DashboardContainer";
import { useDashboardContextOrThrow } from "../../../DashboardContext";

import DashboardFilters from "./DashboardFilters";
import { getShouldShowFiltersFromLocalStorage, setShouldShowFiltersInLocalStorage } from "./helpers";
import messages from "./messages";
import { NewAnalysisButton } from "./NewAnalysisButton";

import { ExportModule } from "@/components/ExportModule";
import PermissionContainer from "@/components/PermissionContainer";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { fm } from "@/contexts/IntlContext";
import { useCustomDashboardContext } from "@/entities/dashboards";
import { useModelGuard } from "@/entities/modelGuards";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/permissions";
import { INLINE_DASHBOARD_FILTERS_TEST_ID } from "@/tests/constants";

export const DASHBOARD_CARD_TEST_ID = "dashboardCard";

interface Prop {
    scrollParentRef: React.RefObject<HTMLDivElement>;
}

export const DashboardCard: React.FC<Prop> = ({ scrollParentRef }) => {
    const { dashboard, editing, setEditing } = useDashboardContextOrThrow();
    const { setExporting } = useExportDashboardContextOrThrow();
    const { listObjs } = useCustomDashboardContext();
    useSetBreadcrumbs(listObjs[dashboard?.id ?? NaN]?.name ?? "");

    const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });
    const hasPermission = usePermission("dashboards", { object: "general", relation: "write" });

    const [exportModuleOpen, setExportModuleOpen] = useState(false);
    const initialShowFilters = getShouldShowFiltersFromLocalStorage();
    const [showFilters, setShowFilters] = useState<boolean>(initialShowFilters);

    // Floating top bar
    const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
    const [containerElementWidth, setContainerElementWidth] = useState<number | null>(null);

    const handleClickShowFilters = () => {
        setShowFilters((prevState) => {
            const newShowFilters = !prevState;
            setShouldShowFiltersInLocalStorage(newShowFilters);
            return newShowFilters;
        });
    };

    const handleExportOpen = useCallback(() => {
        setExporting(true);
        setExportModuleOpen(true);
    }, [setExporting]);

    const handleExportClose = useCallback(() => {
        setExporting(false);
        setExportModuleOpen(false);
    }, [setExporting]);

    useEffect(
        function updateContainerElementWidthAndCheckScrolling() {
            if (!containerElement) return;
            setContainerElementWidth(containerElement.getBoundingClientRect().width);
            const resizeObserver = new ResizeObserver(
                debounce(() => {
                    window.dispatchEvent(new Event("resize"));
                    setContainerElementWidth(containerElement.getBoundingClientRect().width);
                }, 100)
            );
            resizeObserver.observe(containerElement, { box: "content-box" });
            return () => resizeObserver.disconnect();
        },
        [containerElement]
    );

    return (
        <Stack ref={setContainerElement} data-testid={`${DASHBOARD_CARD_TEST_ID}-${dashboard?.id}`}>
            {containerElementWidth !== null && (
                <Stack
                    sx={{
                        width: `${containerElementWidth}px`,
                        position: "sticky",
                        zIndex: 10,
                        display: "flex",
                        top: 0,
                        transition: "box-shadow 0.2s ease-in-out",
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        p={1}
                        pl={3}
                        sx={{ background: (theme) => theme.palette.background.paper }}
                    >
                        <Stack>{showFilters && <FilterCombinations hasEditPermission={hasPermission} />}</Stack>
                        <Stack>
                            <Stack direction="row" justifyContent="flex-end" gap={2} alignItems="center">
                                <PermissionContainer
                                    requiredPermissionTypes={["change"]}
                                    equivalentUserPermission={{
                                        namespace: "dashboards",
                                        relation: { object: "general", relation: "write" },
                                    }}
                                    guard={dashboard?.guard}
                                    modelGuard={modelGuard}
                                >
                                    <Button
                                        startIcon={
                                            editing ? (
                                                <EditOffIcon fontSize="inherit" />
                                            ) : (
                                                <EditIcon fontSize="inherit" />
                                            )
                                        }
                                        size="small"
                                        color="ghostGray"
                                        onClick={() => setEditing(!editing)}
                                    >
                                        {editing
                                            ? fm(messages.disableEditDashboardLayout)
                                            : fm(messages.editDashboardLayout)}
                                    </Button>
                                </PermissionContainer>
                                <Button
                                    startIcon={
                                        showFilters ? (
                                            <VisibilityOffIcon fontSize="inherit" />
                                        ) : (
                                            <VisibilityIcon fontSize="inherit" />
                                        )
                                    }
                                    size="small"
                                    color="ghostGray"
                                    onClick={handleClickShowFilters}
                                >
                                    {showFilters ? fm(messages.hideFilters) : fm(messages.showFilters)}
                                </Button>
                                <Tooltip title={fm(messages.exportDashboard)}>
                                    <IconButton color="secondary" size="small" onClick={handleExportOpen}>
                                        <DownloadIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                                <NewAnalysisButton />
                            </Stack>
                        </Stack>
                    </Stack>
                    {showFilters && (
                        <Stack
                            data-testid={INLINE_DASHBOARD_FILTERS_TEST_ID}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            p={1}
                            pl={3}
                            sx={{ backgroundColor: (theme) => theme.palette.background.default }}
                        >
                            <DashboardFilters />
                        </Stack>
                    )}
                </Stack>
            )}
            <Stack gap={1} pt={2} sx={{ maxWidth: "2000px" }}>
                <Stack p={1}>
                    <DashboardContainer scrollParentRef={scrollParentRef} />
                </Stack>
            </Stack>
            {exportModuleOpen && (
                <ExportModule
                    key={`exportModule-${dashboard?.id}`}
                    open={exportModuleOpen}
                    onClose={handleExportClose}
                />
            )}
        </Stack>
    );
};
