import { defineMessages } from "react-intl";

const messages = defineMessages({
    widgetSettingsHeader: {
        id: "components.widgets.components.editModal.modalHeader",
        defaultMessage: "Analysis settings",
    },
    openEditModal: {
        id: "components.widgets.components.editModal.openEditModal",
        defaultMessage: "Change analysis settings",
    },
    editTitle: {
        id: "components.widgets.components.editModal.editTitle",
        defaultMessage: "Edit title",
    },
    selectArrowPercentage: {
        id: "components.widgets.fieldHistoryWidget.selectArrowPercentage",
        defaultMessage: "Adjust arrow change range",
    },
    selectArrowPercentagePH: {
        id: "components.widgets.fieldHistoryWidget.selectArrowPercentagePH",
        defaultMessage: "Select percentage",
    },
    spend: {
        id: "components.widgets.fieldHistoryWidget.spend",
        defaultMessage: "Spend",
    },
    suppliers: {
        id: "components.widgets.fieldHistoryWidget.suppliers",
        defaultMessage: "Suppliers",
    },
    activeContracts: {
        id: "components.widgets.fieldHistoryWidget.activeContracts",
        defaultMessage: "Active contracts",
    },
    field: {
        id: "components.widgets.fieldHistoryWidget.field",
        defaultMessage: "Field",
    },
    value: {
        id: "components.widgets.fieldHistoryWidget.value",
        defaultMessage: "Value",
    },
    selectAll: {
        id: "components.widgets.fieldHistoryWidget.selectAll",
        defaultMessage: "Select all",
    },
    unselectAll: {
        id: "components.widgets.fieldHistoryWidget.unselectAll",
        defaultMessage: "Unselect all",
    },
    selectFieldPH: {
        id: "components.widgets.fieldHistoryWidget.selectFieldPH",
        defaultMessage: "Select field",
    },
    selectFieldsBenchmarkPH: {
        id: "components.widgets.fieldHistoryWidget.selectFieldsBenchmarkPH",
        defaultMessage: "Select fields",
    },
    chooseView: {
        id: "components.widgets.largestFieldWidget.chooseView",
        defaultMessage: "Choose view",
    },
    tableViewButton: {
        id: "components.widgets.largestFieldWidget.tableViewButton",
        defaultMessage: "Table view",
    },
    chartViewButton: {
        id: "components.widgets.largestFieldWidget.chartViewButton",
        defaultMessage: "Chart view",
    },
    excludeOthers: {
        id: "components.widgets.largestFieldWidget.excludeOthers",
        defaultMessage: "Exclude Others",
    },
    numElementsLabel: {
        id: "components.filter.customFields.numElementsLabel",
        defaultMessage: "Number of elements",
    },
    numElementsPH: {
        id: "components.filter.customFields.numElementsPH",
        defaultMessage: "Select number of elements",
    },
    filters: {
        id: "components.widgets.components.editModal.filtersToWidget",
        defaultMessage: "Apply filters to this analysis",
    },
    commodityNameLabel: {
        id: "components.widgets.components.mintecCommodityWidget.commodityNameLabel",
        defaultMessage: "Commodity Name",
    },
    benchmarkNameLabel: {
        id: "components.widgets.components.datalakeBenchmarkWidget.benchmarkNameLabel",
        defaultMessage: "Benchmark Name",
    },
});

export default messages;
