import { Gear as SettingsIcon } from "@ignite-analytics/icons";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import React, { FormEvent, useState } from "react";

import messages from "./messages";

import { AnalysisOptionProps } from "@/components/AnalysisOptions/Fields";
import { Options } from "@/components/Widgets/interfaces";
import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/lib/messages/globalMessages";
import { updateOptions } from "@/lib/objectHelpers";

interface Props {
    fields?: Partial<Record<string, React.FC<AnalysisOptionProps>>>;
    options: Options;
    onSubmit: (options: Options) => void;
    widgetType?: string;
}

const WIDGET_HEADER_ANALYSIS_OPTIONS_PREFIX = "hoc-widgetHeader-analysisOptions-";

const AnalysisOptions: React.FC<Props> = ({ fields, options, onSubmit, widgetType }) => {
    const [open, setOpen] = useState(false);
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newOptions: Record<string, string | number | boolean> = {};
        for (const [key, value] of data) {
            let newValue: string | number | boolean;
            switch (typeof options[key]) {
                case "number": {
                    newValue = +value;
                    break;
                }
                default: {
                    newValue = value.toString();
                    break;
                }
            }
            newOptions[key] = newValue;
        }
        setOpen(false);
        onSubmit(updateOptions(options, newOptions));
    };

    return fields ? (
        <>
            {open && (
                <Dialog maxWidth="xs" open={open} onClose={() => setOpen(false)}>
                    <Box sx={{ width: "92%", margin: 2 }}>
                        <Stack direction="column" spacing={2} component="form" onSubmit={handleSubmit}>
                            {Object.entries(fields).map(
                                ([name, Field]) =>
                                    Field && (
                                        <Stack
                                            key={name}
                                            data-testid={`${WIDGET_HEADER_ANALYSIS_OPTIONS_PREFIX}field-${name}`}
                                        >
                                            <Field activeOptions={options} name={name} />
                                        </Stack>
                                    )
                            )}
                            <Stack>
                                <Button
                                    data-testid={`${WIDGET_HEADER_ANALYSIS_OPTIONS_PREFIX}applyButton`}
                                    type="submit"
                                >
                                    {fm(globalMessages.apply)}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Dialog>
            )}
            <Stack direction="row" alignItems="center" gap={1} onClick={() => setOpen(true)}>
                <SettingsIcon
                    fontSize="small"
                    data-testid={`${WIDGET_HEADER_ANALYSIS_OPTIONS_PREFIX}optionButton`}
                    data-testtype={`${widgetType}`}
                />
                <Typography variant="body2">{fm(messages.options)}</Typography>
            </Stack>
        </>
    ) : null;
};

export default AnalysisOptions;
