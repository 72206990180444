 
import { TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { formatValue } from "@ignite-analytics/locale";
import { BottomRow, PivotResponse } from "@ignite-analytics/pivot-ts";
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom-v5-compat";

import { trendArrow } from "../../Components";
import parentMessages from "../messages";

import messages from "./messages";

import { LargestFieldWidget } from "@/components/Widgets/interfaces";
import { fm } from "@/contexts/IntlContext";
import { useIndexWithGlobalType } from "@/hooks/useElasticIndexWithType";
import { useLabel } from "@/hooks/useLabel";
import { toPercent } from "@/lib/convert";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    data: PivotResponse;
    activeWidget: LargestFieldWidget;
    onRef: (el: HTMLTableElement) => void;
}

const LargestFieldsTable: React.FC<Props> = ({ data, activeWidget, onRef }) => {
    const globalTypeIndex = useIndexWithGlobalType(TRANSACTIONS_GT);

    const globalTypeIndexHeader = useLabel(
        {
            field: activeWidget.columnSplitItems[0]?.field || "",
        },
        globalTypeIndex?.name
    );
    const spendHeader = fm(messages.spend);
    const previousSpendHeader = fm(messages.previousSpend);
    const percentageChangeHeader = fm(parentMessages.percentageChange);
    const trendHeader = fm(messages.trend);

    return (
        <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
            <Table size="small" ref={(el: HTMLTableElement) => el && onRef && onRef(el)}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Box sx={{ fontWeight: "bold" }}>{globalTypeIndexHeader}</Box>
                        </TableCell>
                        <TableCell align="right">
                            <Box sx={{ fontWeight: "bold" }}>{spendHeader}</Box>
                        </TableCell>
                        <TableCell align="right">
                            <Box sx={{ fontWeight: "bold" }}>{previousSpendHeader}</Box>
                        </TableCell>
                        <TableCell align="right">
                            <Box sx={{ fontWeight: "bold" }}>{percentageChangeHeader}</Box>
                        </TableCell>
                        <TableCell align="right">
                            <Tooltip title={fm(messages.trendInSpendTooltip)}>
                                <Box sx={{ fontWeight: "bold" }}>{trendHeader}</Box>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!data || data.columns.length === 0 ? (
                        <TableCell align="right">
                            <Box sx={{ fontWeight: "medium" }}>{fm(globalMessages.noDataLabel)}</Box>
                        </TableCell>
                    ) : (
                        data.columns.map((column, i) => {
                            const [spend, prevSpend, trend] = (data.rows[0] as BottomRow).values[i] as (
                                | number
                                | null
                            )[];
                            return (
                                <TableRow key={column.key}>
                                    <TableCell component="th" scope="row">
                                        <Box sx={{ fontWeight: "medium" }}>
                                            {column.meta.supplierId ? (
                                                <Link
                                                    component={ReactRouterLink}
                                                    to={`./supplier-page/${column.meta.supplierId}/overview/`}
                                                >
                                                    {column.name}
                                                </Link>
                                            ) : (
                                                column.name
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ fontWeight: "medium" }}>{spend && formatValue(spend)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ fontWeight: "medium" }}>{prevSpend && formatValue(prevSpend)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ fontWeight: "medium" }}>
                                            {trend === Infinity ? fm(messages.infinityLabel) : toPercent(trend)}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ fontWeight: "medium" }}>
                                            {trend !== null && trendArrow(trend, activeWidget.percentageLimit)}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LargestFieldsTable;
