import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";

/**
 * A tooltip that only shows if the text overflows.
 */
interface Props extends TooltipProps {
    disablePortal?: boolean;
}

export const OverflowTooltip: React.FC<Props> = ({ disablePortal = false, children, ...props }) => {
    const [textOverflowing, setTextOverflowing] = useState(false);
    const initializedRef = React.useRef(false);
    const divRef = React.useRef(null);

    const checkIfTextIsOverflowing = () => {
        if (initializedRef.current || !divRef.current) return;
        const { scrollWidth, clientWidth } = divRef.current;
        if (scrollWidth > clientWidth) {
            setTextOverflowing(true);
        }
        initializedRef.current = true;
    };
    useEffect(() => {
        checkIfTextIsOverflowing();
    }, []);

    return (
        <div ref={divRef} onMouseEnter={checkIfTextIsOverflowing}>
            <Tooltip
                disableHoverListener={!textOverflowing}
                PopperProps={{
                    disablePortal,
                }}
                {...props}
            >
                {textOverflowing ? (
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {children}
                    </div>
                ) : (
                    children
                )}
            </Tooltip>
        </div>
    );
};
