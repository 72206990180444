import { defineMessages } from "react-intl";

const messages = defineMessages({
    contractCompliance: {
        id: "components.widgets.contractCompliance",
        defaultMessage: "Contract compliance",
        description: "At least part of the new widget menu",
    },
    contracts: {
        id: "components.widgets.contracts",
        defaultMessage: "Contracts",
        description: "At least part of the new widget menu",
    },
    textWidget: {
        id: "components.widgets.textWidget",
        defaultMessage: "Text",
    },
    customAnalysis: {
        id: "components.widgets.customAnalysis",
        defaultMessage: "New analysis",
        description: "At least part of the new widget menu",
    },
    fieldDistributions: {
        id: "components.widgets.LargetsItemsDistributions",
        defaultMessage: "Top items distribution",
        description: "At least part of the new widget menu",
    },
    fieldHistory: {
        id: "components.widgets.compareDevelopment",
        defaultMessage: "Compare development",
        description: "At least part of the new widget menu",
    },
    largestFields: {
        id: "components.widgets.largestItemsSpendDevelopment",
        defaultMessage: "Top items",
        description: "At least part of the new widget menu",
    },
    notifications: {
        id: "components.widgets.notifications",
        defaultMessage: "Notifications",
        description: "At least part of the new widget menu",
    },
    potential: {
        id: "components.widgets.potential",
        defaultMessage: "Potential",
        description: "At least part of the new widget menu",
    },
    risk: {
        id: "components.widgets.risk",
        defaultMessage: "Risk",
        description: "At least part of the new widget menu",
    },
    spendDevelopment: {
        id: "components.widgets.spendDevelopment",
        defaultMessage: "Spend development",
        description: "At least part of the new widget menu",
    },
    copyWidget: {
        id: "components.widgets.copyWidget",
        defaultMessage: "Make copy of analysis",
    },
    copy: {
        id: "components.widgets.copy",
        defaultMessage: "Copy",
    },
    viewPermissionDenied: {
        id: "hoc.widgetWrapper.viewPermissionDenied",
        defaultMessage: "You do not have the permission to view the content of this analysis",
        description: "",
    },
    timeoutError: {
        id: "components.errorAlerts.timeoutError",
        defaultMessage:
            "The request has timed out, due to network issues or the size of the analysis. If you suspect that the analysis could be too big, please try reducing the number of splits or results per split.",
    },
    editSettings: {
        id: "components.widgets.editSettings",
        defaultMessage: "Edit analysis settings",
    },
    contractsWidgetTooltip: {
        id: "components.widgets.contractsWidgetTooltip",
        defaultMessage: "This analysis does not show contracts stored in the data management system.",
    },
    customPeriod: {
        id: "components.filter.customPeriod",
        defaultMessage: "Aggregation period",
    },
    querySize: {
        id: "components.filter.querySize",
        defaultMessage: "Number of elements",
    },
    minSpendLtm: {
        id: "components.filter.minSpendLtm",
        defaultMessage: "Min spend LTM",
    },
    supplierNumber: {
        id: "components.filter.supplierNumber",
        defaultMessage: "Max number of suppliers",
    },
    categoryLevel: {
        id: "components.filter.categoryLevel",
        defaultMessage: "Category level",
    },
    timePeriod: {
        id: "components.filter.timePeriod",
        defaultMessage: "Time period CAGR (years)",
    },
    interval: {
        id: "components.filter.interval",
        defaultMessage: "Date interval",
    },
    spendDevelopmentTooltip: {
        id: "globals.messages.widgets.spendDevelopmentWidget.chartTooltip",
        defaultMessage: "Spend last 12 months including {month}: ",
        description: "",
    },
    filterMinDifference: {
        id: "analyze.prioritize.largestDeviationFilterMinDifference",
        defaultMessage: "Minimum difference",
    },
    newAnalysis: {
        id: "components.widgets.newAnalysis",
        defaultMessage: "New analysis",
    },
    customAnalysisDescription: {
        id: "components.widgets.customAnalysisDescription",
        defaultMessage: "Create your own analysis",
    },
    textWidgetDescription: {
        id: "components.widgets.textWidgetDescription",
        defaultMessage: "Tell a story using plain text",
    },
    potentialDescription: {
        id: "components.widgets.potentialDescription",
        defaultMessage: "Explore your top suppliers for renegotiation",
    },
    riskDescription: {
        id: "components.widgets.riskDescription",
        defaultMessage: "Explore your highest risk suppliers",
    },
    fieldDistributionsDescription: {
        id: "components.widgets.fieldDistributionsDescription",
        defaultMessage: "Explore largest items and the spend distribution",
    },
    fieldHistoryDescription: {
        id: "components.widgets.fieldHistoryDescription",
        defaultMessage: "Compare values by months, quarter or year",
    },
    largestFieldsDescription: {
        id: "components.widgets.largestFieldsDescription",
        defaultMessage: "Identify the top items and the spend development",
    },
    spendDevelopmentDescription: {
        id: "components.widgets.spendDevelopmentDescription",
        defaultMessage: "Explore your historical spend development",
    },
    notificationsDescription: {
        id: "components.widgets.notificationsDescription",
        defaultMessage: "Get an overview of your ongoing tasks",
    },
    contractComplianceDescription: {
        id: "components.widgets.contractComplianceDescription",
        defaultMessage: "Distribution of contracted vs non-contracted spend",
    },
    contractsDescription: {
        id: "components.widgets.contractsDescription",
        defaultMessage: "Contracts description, very good",
    },
    deleteWidget: {
        id: "components.widgets.deleteWidget",
        defaultMessage: "Delete analysis",
    },
    deleteWidgetError: {
        id: "components.widgets.deleteWidgetError",
        defaultMessage: "Could not delete analysis",
    },
    deleteAnalysisConfirmation: {
        id: "components.widgets.deleteAnalysisConfirmation",
        defaultMessage: "Are you sure you want to delete this analysis?",
    },
    cancel: {
        id: "components.widgets.cancel",
        defaultMessage: "Cancel",
    },
    deleteConfirm: {
        id: "components.widgets.deleteConfirm",
        defaultMessage: "Delete",
    },
    widgetNotInDepartment: {
        id: "components.widgets.editModals.customAnalysisModal.widgetNotInDepartment",
        defaultMessage:
            "You are not allowed to edit this analysis. The analysis and dashboard was not created in this department or a sub-department. Create a copy of the dashboard and edit the copy to be able to save.",
    },
    somethingWentWrongSaving: {
        id: "components.widgets.editModals.customAnalysisModal.somethingWentWrongSaving",
        defaultMessage: "Something went wrong when saving this analysis!",
    },
    somethingWentWrongExportingAnalysis: {
        id: "components.widgets.somethingWentWrongExportingAnalysis",
        defaultMessage:
            "Something went wrong when exporting this analysis! Make sure to wait until the analysis has loaded before trying to export. Please contact our customer support if the issue happens again.",
    },
    deletedWidgetWarning: {
        id: "components.widgets.deletedWidgetWarning",
        defaultMessage: "This analysis type has been deleted, please remove it from the dashboard.",
    },
});

export default messages;
