import { defineMessages } from "react-intl";

const messages = defineMessages({
    heading1: {
        id: "textWidget.heading1",
        defaultMessage: "Heading 1",
    },
    heading2: {
        id: "textWidget.heading2",
        defaultMessage: "Heading 2",
    },
    heading3: {
        id: "textWidget.heading3",
        defaultMessage: "Heading 3",
    },
    section: {
        id: "textWidget.section",
        defaultMessage: "Section",
    },
    clickToEdit: {
        id: "textWidget.clickToEdit",
        defaultMessage: "Click to edit",
    },
    cancelEditing: {
        id: "textWidget.cancelEditing",
        defaultMessage: "Cancel editing",
    },
});

export default messages;
