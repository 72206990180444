import { Stack, Alert } from "@mui/material";

import messages from "../messages";

import { fm } from "@/contexts/IntlContext";
import { widgetWrapper } from "@/hoc/widgetWrapper";

const DeletedWidgetWarning = () => {
    return (
        <Stack>
            <Alert severity="warning">{fm(messages.deletedWidgetWarning)}</Alert>
        </Stack>
    );
};

export default widgetWrapper(DeletedWidgetWarning, undefined, true);
