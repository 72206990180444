import { defineMessages } from "react-intl";

const messages = defineMessages({
    placeholderSearch: {
        id: "potentialRatingPage.Search",
        defaultMessage: "Search for suppliers",
    },
    exportMessage: {
        id: "potentialRatingPage.exportMessage",
        defaultMessage: "Exported file will be available at `Data -> File management` when is has finished",
    },
    filterBtn: {
        id: "potentialRatingPage.filterBtn",
        defaultMessage: "Filter",
    },
    sortBySpend: {
        id: "potentialRatingPage.sortBySpend",
        defaultMessage: "Sort by spend",
    },
    exportBtn: {
        id: "potentialRatingPage.exportBtn",
        defaultMessage: "Export to image",
    },
    exportData: {
        id: "potentialRatingPage.exportData",
        defaultMessage: "Export data",
    },
    potentialHeader: {
        id: "potentialRatingPage.tableHeader",
        defaultMessage: "Potential analysis",
    },
    riskHeader: {
        id: "riskRatingPage.tableHeader",
        defaultMessage: "Risk analysis",
    },
    displayingNumberOfSuppliers: {
        id: "potentialRatingPage.displayingNumberOfSuppliers",
        defaultMessage: "Showing {length} of {total} suppliers. ",
    },
    missingCategoryGt: {
        id: "potentialRatingPage.missingCategoryGt",
        defaultMessage: "Could not find column with global tag spend category",
    },
    supplierCountExceeded: {
        id: "potentialRatingPage.supplierCountExceeded",
        defaultMessage:
            "The table is only able to show 1000 suppliers, please use the filter to find your desired supplier.",
    },
    shareOfWalletHeader: {
        id: "supplierPage.riskAndPotential.shareOfWalletHeader",
        defaultMessage: "Share of wallet",
    },
    risk: {
        id: "supplierPage.riskAndPotential.risk",
        defaultMessage: "Risk",
    },
    potential: {
        id: "supplierPage.riskAndPotential.potential",
        defaultMessage: "Potential",
    },
    shareOfWalletText: {
        id: "supplierPage.riskAndPotential.shareOfWalletText",
        defaultMessage: "The share of wallet measures your share of the suppliers revenue",
    },
    spendGrowthHeader: {
        id: "supplierPage.riskAndPotential.spendGrowthHeader",
        defaultMessage: "Spend growth",
    },
    spendGrowthText: {
        id: "supplierPage.riskAndPotential.spendGrowthText",
        defaultMessage: "Spend growth indicates how your overall spend has developed towards a supplier.",
    },
    ebitHeader: {
        id: "supplierPage.riskAndPotential.ebitHeader",
        defaultMessage: "EBIT margin",
    },
    ebitText: {
        id: "supplierPage.riskAndPotential.ebitText",
        defaultMessage: "EBIT margin measures the supplier's profitability.",
    },
    roceHeader: {
        id: "supplierPage.riskAndPotential.roceHeader",
        defaultMessage: "Return on capital employed  (ROCE)",
    },
    roceText: {
        id: "supplierPage.riskAndPotential.roceText",
        defaultMessage:
            "ROCE is a profitability ratio that measures how efficiently the supplier is using its capital.",
    },
    liquidityHeader: {
        id: "supplierPage.riskAndPotential.liquidityHeader",
        defaultMessage: "Liquidity",
    },
    roce: {
        id: "supplierPage.riskAndPotential.roce",
        defaultMessage: "ROCE",
    },
    liquidityText: {
        id: "supplierPage.riskAndPotential.liquidityText",
        defaultMessage: "The liquidity ratio considers the current assets of a company.",
    },
    solvencyHeader: {
        id: "supplierPage.riskAndPotential.solvencyHeader",
        defaultMessage: "Solvency",
    },
    solvencyText: {
        id: "supplierPage.riskAndPotential.solvencyText",
        defaultMessage:
            "The companies solvency indicates whether a company's cash flow is sufficient to meet its long-term liabilities and thus is a measure of its financial health.",
    },
    howDoWeAnalysePotential: {
        id: "supplierCard.potentialRating.howDoWeAnalysePotential",
        defaultMessage: "How do we analyse the potential?",
    },
    howDoWeAnalyseRisk: {
        id: "supplierCard.riskRating.howDoWeAnalyseRisk",
        defaultMessage: "How do we analyse the risk?",
    },
    riskAnalysis: {
        id: "supplierCard.riskRating.riskAnalysis",
        defaultMessage: "Risk analysis",
    },
    potentialRating: {
        id: "supplierCard.potentialRating.potentialRating",
        defaultMessage: "Potential rating",
    },
    company: {
        id: "supplierCard.financialChart.company",
        defaultMessage: "Company",
    },
    corporateGroup: {
        id: "supplierCard.financialChart.corporateGroup",
        defaultMessage: "Corporate Group",
    },
    financialData: {
        id: "supplierCard.financialChart.financialData",
        defaultMessage: "Financial data",
    },
    financialDataFooter: {
        id: "supplierCard.financialChart.financialDataFooter",
        defaultMessage: "Where is the data originated?",
    },
    financialDataFooterTooltip: {
        id: "supplierCard.financialChart.financialDataFooterTooltip",
        defaultMessage:
            "Latest financial data from Enin. Missing data is due to financial statements not reported yet this year or not uploaded to Enin's database.",
    },
    revenueHeader: {
        id: "supplierCard.potentialRating.revenueHeader",
        defaultMessage: "Revenue",
    },
    riskTooltip: {
        id: "supplierCard.riskRating.riskTooltip",
        defaultMessage: "Risk is a calculated score based on Liquidity, ROCE and Solvency",
    },
    riskBody: {
        id: "supplierCard.riskRating.riskBody",
        defaultMessage:
            "The risk analysis rates your suppliers through a risk rating based on three different metrics: liquidity (current ratio), return on capital employed (ROCE), and Solvency (equity ratio).",
    },
    spendGrowthTooltip: {
        id: "supplierCard.riskRating.spendGrowthTooltip",
        defaultMessage:
            "Spend growth indicates how your overall spend has developed towards the supplier. Growth in overall spend can indicate stronger possibilities for renegotiation of prices. Spend growth is measured by the compound annual growth rate (CAGR) within the selected time period. CAGR is calculated by comparing spend the last 12 months to the spend 12 months before",
    },
    solvencyTooltip: {
        id: "supplierCard.riskRating.solvencyTooltip",
        defaultMessage:
            "The equity ratio is a solvency ratio indicating the relative proportion of equity used to finance a company's assets, and a good indicator of the level of leverage used by a company. The ratio indicates a company's ability to withstand losses and to fulfil financial commitments in the long run.",
    },
    potentialBody: {
        id: "supplierCard.potentialRating.potentialBody",
        defaultMessage:
            "The potential analysis rates your suppliers through a potential rating based on four different metrics: share of wallet, spend development (CAGR), EBIT margin and return on capital employed (ROCE).",
    },
    potentialTooltip: {
        id: "supplierCard.potentialRating.potentialTooltip",
        defaultMessage: "Potential is a calculated score based on Liquidity, ROCE, Share of wallet and EBIT margin.",
    },
    liquidityTooltip: {
        id: "supplierCard.potentialRating.liquidityTooltip",
        defaultMessage:
            "The current ratio measures the ability to pay short-term (and long-term obligations). The liquidity ratio considers the current assets of a company (both liquid and illiquid) relative to that company's current liabilities.",
    },
    ebitMarginTooltip: {
        id: "supplierCard.potentialRating.ebitMarginTooltip",
        defaultMessage:
            "EBIT margin measures the supplier's profitability, and is calculated by dividing earnings before interest and taxes (EBIT or operating profit) to revenue for the latest available financial data. The profit margin can be compared against relevant competitors and help determine if the margin is in line with the competetive landscape. A more profitable supplier indicates a stronger possibility for renegotiation of prices, all other things being equal.",
    },
    shareOfWalletTooltip: {
        id: "supplierCard.potentialRating.shareOfWalletTooltip",
        defaultMessage:
            "The share of wallet measures your share of the supplier's revenue, and is calculated by dividing your spend with the supplier's revenue for the latest available financial data. A high share of wallet indicates that you are an important customer for the supplier and implies a more critical customer (and supplier) dependency. The share of wallet highlights your relative bargaining power towards the supplier.",
    },
    roceTooltip: {
        id: "supplierCard.potentialRating.roceTooltip",
        defaultMessage:
            "Return on capital employed (ROCE) is a profitability ratio that measures how efficiently the supplier is using its capital. ROCE is calculated by dividing operating profit (EBIT) to capital employed for the latest available financial data. The rate is typically compared to the required rate of return, often expressed by the weighted average cost of capital (WACC).",
    },
    columnHeadNumTransaction: {
        id: "riskRatingPage.columnHeadNumTransaction",
        defaultMessage: "Num transactions LTM",
    },
    columnHeadSpend: {
        id: "riskRatingPage.columnHeadSpend",
        defaultMessage: "Spend LTM",
    },
    financialDataFromYear: {
        id: "potentialRatingPage.financialDataFromYear",
        defaultMessage: "Financial data from {year}",
    },
    auditorComments: {
        id: "riskRatingPage.auditorComments",
        defaultMessage: "Auditor comments",
    },
    columnHeadSupplier: {
        id: "potentialRatingPage.columnHeadSupplier",
        defaultMessage: "Supplier",
    },
    columnHeadRating: {
        id: "potentialRatingPage.columnHeadRating",
        defaultMessage: "Potential rating",
    },
    columnHeadShareOfWallet: {
        id: "potentialRatingPage.columnHeadShareOfWallet",
        defaultMessage: "Share of wallet",
    },
    columnHeadCagr: {
        id: "potentialRatingPage.columnHeadCagr",
        defaultMessage: "Spend growth (CAGR)",
    },
    columnHeadEBIT: {
        id: "potentialRatingPage.columnHeadEBIT",
        defaultMessage: "EBIT margin",
    },
    columnHeadRoce: {
        id: "potentialRatingPage.columnHeadRoce",
        defaultMessage: "ROCE",
    },
    columnHeadCategory: {
        id: "potentialRatingPage.columnHeadCategory",
        defaultMessage: "Largest category",
    },
    ROCETooltip: {
        id: "supplierPage.potentialPage.ROCETooltip",
        defaultMessage: "Return on capital employed",
    },
    spendLTMTooltip: {
        id: "supplierPage.potentialPage.spendLTMTooltip",
        defaultMessage: "Spend last twelve months",
    },
    CAGRTooltip: {
        id: "supplierPage.potentialPage.CAGRTooltip",
        defaultMessage: "Spend growth (Compound annual growth rate)",
    },
    EBITTooltip: {
        id: "supplierPage.potentialPage.EBITTooltip",
        defaultMessage: "Earnings before interest and taxes margin",
    },
    transLTMTooltip: {
        id: "supplierPage.potentialPage.transLTMTooltip",
        defaultMessage: "Number of transactions last twelve months",
    },
    columnHeadName: {
        id: "riskRatingPage.columnHeadName",
        defaultMessage: "Suppliers",
    },
    columnHeadRiskRating: {
        id: "riskRatingPage.columnHeadRiskRating",
        defaultMessage: "Risk rating",
    },
    currentRatio: {
        id: "riskRatingPage.columnHead.currentRatio",
        defaultMessage: "Current ratio",
    },
    columnHeadROCE: {
        id: "riskRatingPage.columnHeadROCE",
        defaultMessage: "ROCE",
    },
    columnHeadEquityRatio: {
        id: "riskRatingPage.columnHeadEquityRatio",
        defaultMessage: "Equity ratio",
    },
    columnHeadComments: {
        id: "riskRatingPage.columnHeadComments",
        defaultMessage: "Auditor comments",
    },
    columnHeadLargestCategory: {
        id: "riskRatingPage.columnHeadLargestCategory",
        defaultMessage: "Largest category",
    },
    riskRatingHeader: {
        id: "supplierPage.riskPage.riskRatingHeader",
        defaultMessage: "Risk analysis",
    },
    ROCEHeader: {
        id: "supplierPage.riskPage.ROCEHeader",
        defaultMessage: "ROCE",
    },
    solidityHeader: {
        id: "supplierPage.riskPage.solidityHeader",
        defaultMessage: "Solidity",
    },
    noDataLabel: {
        id: "riskPage.general.noData",
        defaultMessage: "No data",
    },
});

export default messages;
