import { defineMessages } from "react-intl";

export default defineMessages({
    currentField: {
        id: "components.widgets.fieldHistoryWidget.currentField",
        defaultMessage: "Current {field}:",
    },
    selectComparison: {
        id: "components.widgets.fieldHistoryWidget.selectComparison",
        defaultMessage: "Compare with previous twelve months:",
    },
    selectComparisonPH: {
        id: "components.widgets.fieldHistoryWidget.selectComparisonPH",
        defaultMessage: "Select an alternative",
    },
    selectComparisonYes: {
        id: "components.widgets.fieldHistoryWidget.selectComparisonYes",
        defaultMessage: "Yes",
    },
    selectComparisonNo: {
        id: "components.widgets.fieldHistoryWidget.selectComparisonNo",
        defaultMessage: "No",
    },
    prev12Months: {
        id: "components.widgets.fieldHistoryWidget.prev12Months",
        defaultMessage: "Previous 12 months",
    },
    last12Months: {
        id: "components.widgets.fieldHistoryWidget.last12Months",
        defaultMessage: "Last 12 months",
    },
    trendDescriptionTooltip: {
        id: "components.widgets.fieldHistoryWidget.trendDescriptionTooltip",
        defaultMessage: "Change in percent from `{prev}` to `{curr}`",
    },
    compareWithPreviousTooltip: {
        id: "components.widgets.fieldHistoryWidget.compareWithPreviousTooltips",
        defaultMessage:
            "Spend this {widgetPeriod, select, month {month} year {year} quarter {three month period} other {}} compared to previous {widgetPeriod, select, month {month} year {year} quarter {three month period} other {}}",
    },
    thisMonth: {
        id: "components.widgets.fieldHistoryWidget.thisMonth",
        defaultMessage: "this month",
    },
    these3Months: {
        id: "components.widgets.fieldHistoryWidget.these3Months",
        defaultMessage: "these 3 months",
    },
    thisYear: {
        id: "components.widgets.fieldHistoryWidget.thisYear",
        defaultMessage: "this year",
    },
    thisPeriod: {
        id: "components.widgets.fieldHistoryWidget.thisPeriod",
        defaultMessage: "This period",
    },
    lastPeriod: {
        id: "components.widgets.fieldHistoryWidget.lastPeriod",
        defaultMessage: "Last period",
    },
});
