// Takes in width of widget container and gives the correct percentage for for the header text
export const getCorrectWidth = (width?: number, editmode?: boolean) => {
    if (width && width < 170) {
        return editmode ? "40%" : "45%";
    }
    if (width && width < 200) {
        return editmode ? "50%" : "55%";
    }
    if (width && width < 350) {
        return editmode ? "70%" : "75%";
    }
    if (width && width < 450) {
        return editmode ? "80%" : "85%";
    }
    if (width && width < 1000) {
        return editmode ? "90%" : "95%";
    }
    return "90%";
};
