import {
    CagrTimePeriod,
    CategoryLevel,
    MinimumSpendLTM,
    SupplierCountOrNumber,
} from "@/components/AnalysisOptions/Fields";
import { CATEGORY_LEVEL, MIN_SPEND_LTM, SUPPLIER_NUMBER, TIME_PERIOD } from "@/components/Widgets/constants";

export const riskOptions = (treeHeight?: number) => ({
    [SUPPLIER_NUMBER]: 1000,
    [MIN_SPEND_LTM]: 500000,
    [CATEGORY_LEVEL]: treeHeight ?? 2,
});

export const potentialOptions = (treeHeight?: number) => ({
    ...riskOptions(treeHeight),
    [TIME_PERIOD]: 1,
});

export const potentialRiskFields = (type: "risk" | "potential" | undefined) => ({
    [SUPPLIER_NUMBER]: SupplierCountOrNumber,
    [MIN_SPEND_LTM]: MinimumSpendLTM,
    [CATEGORY_LEVEL]: CategoryLevel,
    ...(type === "potential" ? { [TIME_PERIOD]: CagrTimePeriod } : {}),
});

export const QUERY_SIZE = 10;

export const TABLE_SIZE = 1000;
