import { defineMessages } from "react-intl";

const messages = defineMessages({
    analysisConfigurationTitle: {
        id: "components.widgets.components.widgetError.analysisConfiguration",
        defaultMessage: "Analysis configuration",
    },
});

export default messages;
