import { Api } from "@ignite-analytics/api-client";

import { PreservedGlobalTypes } from "./interfaces";

export const getPreservedGlobalTypes = (modelName: string, widgetId: number): Promise<PreservedGlobalTypes> => {
    const url = `/global-types/widgets/${modelName}/${widgetId}/preserved-global-types/`;
    return Api.get(url, { service: "dashboards" });
};

export const updateConfigurations = (
    type: "data-table" | "data-column" | "group-type",
    preservedId: number | string,
    body: Record<string, number | string | boolean>
) => {
    const url = `/dashboards/fields/${type}/${preservedId}/`;
    return Api.put(url, body, { service: "dashboards" });
};
