import { defineMessages } from "react-intl";

const messages = defineMessages({
    igniteAnalyticsDashboards: {
        id: "dashboardOverview.dashboardOverviewPage.igniteAnalyticsDashboards",
        defaultMessage: "Ignite Analytics",
    },
    savingsOpportunities: {
        id: "dashboardOverview.contentView.savingsOpportunities",
        defaultMessage: "Savings Opportunities",
    },

    risk: {
        id: "dashboardOverview.contentView.risk",
        defaultMessage: "Risk",
    },
    potential: {
        id: "dashboardOverview.contentView.potential",
        defaultMessage: "Potential",
    },
    benchmark: {
        id: "dashboardOverview.contentView.benchmark",
        defaultMessage: "Benchmark",
    },
});

export default messages;
