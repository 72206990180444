import { Api, toSnakeCase } from "@ignite-analytics/api-client";
import { DUMMY_DEPARTMENT_ID } from "@ignite-analytics/department";
import { saveAs } from "file-saver";

import { readFile } from "./helpers";

import { ExportImageData } from "@/hooks/useExport/interfaces";

interface ExportChartRequestData {
    title: string;
    content: string | object;
    filterFooterTuples: [string, string][];
    mimeType?: string;
}

const generateExportImageData = (data: ExportImageData[]): Promise<ExportChartRequestData[]> =>
    Promise.all(
        data.map(async ({ title: chartTitle, contentURL, filterFooterTuples, mimeType }) => {
            if (!contentURL.startsWith("blob:")) {
                const content = mimeType === "image/svg+xml" ? contentURL : JSON.parse(contentURL);
                return {
                    title: chartTitle,
                    content,
                    filterFooterTuples,
                    mimeType,
                };
            }
            const blobResponse = await fetch(contentURL);
            const blob = await blobResponse.blob();
            const dataUrl = (await readFile(blob, "readAsDataURL"))?.toString() ?? "";
            const content = mimeType === "application/json" ? await (await fetch(dataUrl)).json() : dataUrl;
            return {
                title: chartTitle,
                content,
                filterFooterTuples,
                mimeType,
            };
        })
    );

const generateExport =
    (mimeType: string, fileExtension: string, endPoint: string) =>
    /**
     *
     * @param {Array}  data:            Array of objects where each object has two properties: title (a string) and content (data url string) // TODO: Change from data url string to svg string
     * @param {string} title:           String that will be used as a title for the powerpoint
     * @param {number} departmentId:    Client ID
     *
     * @returns {Promise}               Promise that will be resolved with the HTTP response
     */
    (data: ExportImageData[], title: string): Promise<void> => {
        const url = `/departments/${DUMMY_DEPARTMENT_ID}/${endPoint}/`;
        return generateExportImageData(data).then((convertedData) => {
            return Api.export(
                url,
                { data: toSnakeCase(convertedData, false), title },
                { accept: mimeType, raw: true, convertCase: false }
            ).then((resp) => {
                const blob = new Blob([resp], { type: mimeType });
                // Creates a suitable filename
                const formattedTitle = title.toLowerCase().split(" ").join("-");
                const date = new Date().toISOString().slice(0, 10);
                const fileName = `${formattedTitle}-${date}`;

                // Download the file
                saveAs(blob, `${fileName}.${fileExtension}`);
            });
        });
    };

export const generatePptxPresentation = generateExport(
    "application/ms-powerpoint",
    "pptx",
    "transactions/generate-pptx"
);

export const generatePdfExport = generateExport("application/pdf", "pdf", "transactions/generate-pdf");
