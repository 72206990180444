import { Box, Checkbox } from "@mui/material";
import React from "react";

interface Props {
    isSelected: boolean;
    onSelectionChange: (isSelected: boolean) => void;
}

const SelectCell: React.FC<Props> = ({ isSelected, onSelectionChange }) => {
    return (
        <Box>
            <Checkbox
                checked={isSelected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, value: boolean) => onSelectionChange(value)}
            />
        </Box>
    );
};

export default SelectCell;
