import { useConsistentIdentity } from "@ignite-analytics/general-tools";
import {
    AggregationItem,
    AnalysisQuery,
    BucketScript,
    MetricsScript,
    ScriptField,
    SplitItem,
    ValueConfiguration,
} from "@ignite-analytics/pivot-ts";
import { useMemo } from "react";

import { AnalysisWidget } from "../interfaces";

import { useScriptsForDashboard } from "@/entities/scriptFields";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

/**
 * Takes in a ValueConfiguration and returns an AggregationItem.
 * If the ValueConfiguration refers to a script that does not exist in redux, `undefined` will be returned,
 * that usually means that the scripts are not loaded yet.
 */
const toAggregationItem = (
    valueConf: ValueConfiguration,
    scriptFields: Partial<Record<number, ScriptField>>
): AggregationItem | undefined => {
    if (!(valueConf.type === "scripted_metric" || valueConf.type === "script")) {
        return valueConf;
    }
    const { script: scriptId } = valueConf;
    const match = scriptFields[scriptId];
    if (!match) return;
    const params = match.valueConfigurations.reduce<Record<string, AggregationItem> | undefined>((res, val) => {
        if (!res) return undefined;
        const param = toAggregationItem(val, scriptFields);
        if (!param) return undefined;
        return { ...res, [val.key]: param };
    }, {});
    if (!params) return;
    if (match.type === "scripted_metric") {
        if (valueConf.type === "scripted_metric") {
            return {
                ...match,
                ...valueConf,
                type: "scripted_metric",
                id: scriptId,
                params,
                aggregation: valueConf.aggregation,
                visible: valueConf.visible,
            };
        }
        const asScript: MetricsScript = {
            ...match,
            type: "scripted_metric",
            id: scriptId,
            params,
            aggregation: "avg",
            visible: valueConf.visible,
        };
        return asScript;
    }
    const asScript: BucketScript = {
        ...match,
        type: "script",
        id: scriptId,
        params,
        visible: valueConf.visible,
    };
    return asScript;
};

const emptySplitItemArray: SplitItem[] = [];
const emptyValueConfigArray: ValueConfiguration[] = [];

/**
 * Takes in an AnalysisWidget and returns an AnalysisQuery.
 * If the widget contains scripts that are not loaded yet, the function will return undefined.
 */
export const useAnalysisQuery = (
    widget:
        | Pick<AnalysisWidget, "columnSplitItems" | "rowSplitItems" | "valueConfigurations" | "elasticIndex">
        | undefined,
    dashboardId: number | undefined
): AnalysisQuery | undefined => {
    const scriptFields = useScriptsForDashboard(dashboardId);
    const transactionIndex = useTransactionsIndex()?.name;
    const {
        rowSplitItems = emptySplitItemArray,
        columnSplitItems = emptySplitItemArray,
        valueConfigurations = emptyValueConfigArray,
        elasticIndex = transactionIndex,
    } = widget ?? {};
    return useConsistentIdentity(
        useMemo(() => {
            if (!valueConfigurations.length || !elasticIndex) {
                return undefined;
            }
            const valueAggregationItems = valueConfigurations
                .map((v) => toAggregationItem(v, scriptFields))
                .filter((v): v is AggregationItem => v !== undefined);
            if (valueAggregationItems.length !== valueConfigurations.length) {
                return undefined;
            }
            return { rowSplitItems, columnSplitItems, valueAggregationItems, elasticIndex };
        }, [rowSplitItems, columnSplitItems, valueConfigurations, elasticIndex, scriptFields])
    );
};
