import { capitalCase } from "@ignite-analytics/general-tools";
import { ChevronUp as ExpandLessIcon, ChevronDown as ExpandMoreIcon } from "@ignite-analytics/icons";
import { Box, Chip, Collapse, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

import messages from "../messages";

import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import {
    CollectionType,
    useAllDashboardCollectionUserRelations,
    useCreateDashboardCollectionUserRelationAction,
    useUnknownIdUpdateDashboardCollectionRelation,
} from "@/entities/dashboardCollectionRelations";

interface OtherDashboardCollectionProps {
    title: string;
    children: JSX.Element[] | JSX.Element;
    collectionType: CollectionType;
    numberOfElements: number;
}

export const OtherDashboardCollection: React.FC<PropsWithChildren<OtherDashboardCollectionProps>> = (
    props: OtherDashboardCollectionProps
) => {
    // Returns the different categories/possible dashboard modules and their corresponding dashboards on the left-hand card
    const { title, children, collectionType, numberOfElements } = props;
    const otherRelation = useAllDashboardCollectionUserRelations(undefined, {
        service: "dashboards",
    }).find((relation) => relation.collectionType === collectionType);
    const open = otherRelation ? otherRelation.open : true;
    const user = useOryUserIdOrThrow();
    const createCollectionRelation = useCreateDashboardCollectionUserRelationAction();
    const updateCollectionRelation = useUnknownIdUpdateDashboardCollectionRelation();

    const handleCollectionClick = () => {
        if (otherRelation) {
            updateCollectionRelation({ ...otherRelation, open: !open });
        } else {
            createCollectionRelation({ user, collection: null, collectionType, open: true }, { service: "dashboards" });
        }
    };

    const lengthString = ` ${numberOfElements}`;

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                onClick={handleCollectionClick}
                sx={{
                    cursor: "pointer",
                    "&:hover": { color: (theme) => theme.palette.primary.main },
                }}
                pl={2}
            >
                <IconButton onClick={handleCollectionClick}>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <>
                    {(title === "other" && (
                        <Tooltip title={fm(messages.otherExplanation)}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                spacing={2}
                            >
                                <Typography variant="textMd" noWrap fontWeight={700}>
                                    {fm(messages.otherCollection)}
                                </Typography>
                                <Box pr={5}>
                                    <Chip size="small" color="primary" label={lengthString} sx={{ fontWeight: 700 }} />
                                </Box>
                            </Stack>
                        </Tooltip>
                    )) || (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            spacing={2}
                        >
                            <Typography variant="textMd" noWrap fontWeight={700}>
                                {capitalCase(title)}
                            </Typography>
                            <Box pr={5}>
                                <Chip size="small" color="primary" label={lengthString} sx={{ fontWeight: 700 }} />
                            </Box>
                        </Stack>
                    )}
                </>
            </Stack>
            <Divider variant="middle" />
            <Collapse in={open}>{children}</Collapse>
        </Stack>
    );
};
