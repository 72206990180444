import { usePreviousValue } from "@ignite-analytics/general-tools";
import { X as Cross, Pen as Edit, Trash } from "@ignite-analytics/icons";
import { Box, Button, Dialog, DialogContent, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { DraftEditorCommand, Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { STATE_FIELD, TEXT_ALIGNMENT } from "../constants";
import { DeprecatedWidget, WidgetProps } from "../interfaces";

import { Toolbar } from "./components/Toolbar";
import messages from "./messages";

import PermissionContainer from "@/components/PermissionContainer";
import { updateWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";
import { useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useModelGuard } from "@/entities/modelGuards";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import globalMessages from "@/lib/messages/globalMessages";

export type Alignment = "left" | "center" | "right";

interface IErrorData {
    detail?: string;
}

const TextWidget: React.FC<WidgetProps<DeprecatedWidget, object>> = ({ activeWidget, onDelete }) => {
    const { editing, dashboard, widgetDispatch } = useDashboardContextOrThrow();
    const previousEditing = usePreviousValue(editing);
    const [edited, setEdited] = useState(false);
    const [editWidget, setEditWidget] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });
    const informUser = useInformUser();

    const [alignment, setAlignment] = useState<Alignment>(activeWidget.config[TEXT_ALIGNMENT] ?? "left");

    const getStateFromWidget = useCallback(() => {
        if (activeWidget.config[STATE_FIELD]) {
            const contentStateJSON = activeWidget.config[STATE_FIELD] ?? "";
            const contentState = convertFromRaw(JSON.parse(contentStateJSON));
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    }, [activeWidget.config]);

    const [editorState, setEditorState] = useState(() => getStateFromWidget());

    const ref = useRef<Editor>(null);

    useEffect(() => {
        if (previousEditing && !editing) {
            setEditorState(getStateFromWidget());
            setEdited(false);
        }
    }, [editing, getStateFromWidget, previousEditing]);

    const handleToolbarChange = useCallback(
        (updateFn: (newState: EditorState) => EditorState) => {
            if (!edited) {
                setEdited(true);
            }
            ref.current?.focus();
            setEditorState(updateFn);
        },
        [edited]
    );

    const handleEditorChange = useCallback(
        (newState: EditorState) => {
            if (!edited) {
                setEdited(true);
            }
            setEditorState(newState);
        },
        [edited]
    );

    const handleKeyCommand = (command: DraftEditorCommand, recvEditorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(recvEditorState, command);
        if (newState) {
            setEdited(true);
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const handleExceptions = (error: AxiosError) => {
        if (error.response) {
            switch (error.response.status) {
                case 403: {
                    informUser({ message: fm(globalMessages.permissionDenied), type: "error" });
                    break;
                }
                default: {
                    const errorData: IErrorData = error.response.data ?? {};
                    if (errorData.detail) {
                        informUser({ message: errorData.detail.toString(), type: "error" });
                    } else {
                        informUser({ message: fm(globalMessages.saveWidgetErrorMessage), type: "error" });
                    }
                    break;
                }
            }
        }
    };

    const handleSave = () => {
        const contentStateJSON = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        return updateWidget({
            ...activeWidget,
            config: {
                [STATE_FIELD]: contentStateJSON,
                [TEXT_ALIGNMENT]: alignment,
            },
        })
            .then((res) => {
                setEdited(false);
                widgetDispatch({ data: res, type: "UPDATE_ONE" });
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    handleExceptions(err);
                } else {
                    informUser({ message: fm(globalMessages.saveWidgetErrorMessage), type: "error" });
                }
            });
    };

    const handleCancel = () => {
        setEditWidget(false);
        setEdited(false);
        setEditorState(getStateFromWidget());
    };

    const handleDelete = () => {
        setOpenDeleteDialog(true);
    };

    return (
        <Box
            sx={{ width: "100%", height: "100%" }}
            onMouseEnter={(e) => {
                e.stopPropagation();
                setHovering(true);
            }}
            onMouseLeave={(e) => {
                e.stopPropagation();
                setHovering(false);
            }}
        >
            <PermissionContainer
                requiredPermissionTypes={["change"]}
                equivalentUserPermission={{
                    namespace: "dashboards",
                    relation: { object: "general", relation: "write" },
                }}
                loader={null}
                guard={dashboard?.guard}
                modelGuard={modelGuard}
            >
                {hovering && !editing && (
                    <Stack direction="row" justifyContent="flex-end">
                        <Tooltip title={!editWidget ? fm(messages.clickToEdit) : fm(messages.cancelEditing)}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (!editWidget) {
                                        setEditWidget(true);
                                    } else {
                                        handleCancel();
                                    }
                                }}
                                sx={{ position: "fixed", top: 20, right: 20, zIndex: 10 }}
                            >
                                {!editWidget ? <Edit fontSize="inherit" /> : <Cross fontSize="inherit" />}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
                {edited && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        sx={{ position: "fixed", top: 20, right: 20, zIndex: 10 }}
                    >
                        <Button variant="outlined" color="secondary" onClick={handleCancel}>
                            {fm(globalMessages.cancelButton)}
                        </Button>
                        <Button variant="contained" color="error" onClick={handleDelete}>
                            {fm(globalMessages.deleteButton)}
                        </Button>
                        <Button variant="contained" color="success" onClick={handleSave}>
                            {fm(globalMessages.saveButton)}
                        </Button>
                    </Stack>
                )}
            </PermissionContainer>
            <Box
                sx={{ width: "100%", height: "90%", overflowY: "auto" }}
                pt={1.5}
                pr={0.5}
                pl={0.5}
                onDoubleClick={() => {
                    setEditWidget(true);
                }}
            >
                {(editing || editWidget) && (
                    <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                        <Stack alignItems="center">
                            <Toolbar
                                editorState={editorState}
                                onStateChange={handleToolbarChange}
                                alignment={alignment}
                                onAlignmentChange={setAlignment}
                            />
                        </Stack>
                    </Stack>
                )}
                <Editor
                    ref={ref}
                    editorState={editorState}
                    onChange={handleEditorChange}
                    handleKeyCommand={handleKeyCommand}
                    textAlignment={alignment}
                    readOnly={!editing && !editWidget}
                    preserveSelectionOnBlur
                />
            </Box>
            {openDeleteDialog && (
                <Dialog
                    open
                    onClose={() => {
                        setOpenDeleteDialog(false);
                    }}
                >
                    <DialogContent>
                        <Stack alignItems="center" spacing={4}>
                            <Stack alignItems="center" spacing={2}>
                                <Trash fontSize="medium" color="error" />
                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                    <Typography variant="textLg" fontWeight={500}>
                                        {fm(globalMessages.deleteWidgetDialogTitle)}
                                    </Typography>
                                    <Typography variant="textSm">
                                        {fm(globalMessages.deleteWidgetDialogMessage)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack width="100%" direction="row" justifyContent="space-between" spacing={2}>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setOpenDeleteDialog(false);
                                    }}
                                    fullWidth
                                >
                                    {fm(globalMessages.cancelButton)}
                                </Button>
                                <Button color="destructive" onClick={onDelete} fullWidth>
                                    {fm(globalMessages.deleteButton)}
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default widgetWrapper(TextWidget, undefined, true);
