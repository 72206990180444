import { openSocketConnection } from "@ignite-analytics/notifications";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";

type Entity = "DataTable" | "DataColumn";
type Event = {
    id: string;
    model: Entity;
    type: "CREATED" | "UPDATED" | "DELETED";
};
type Handler = (event: Event) => void;
type ContextValue = {
    addListener: (entity: Entity, handler: Handler) => void;
};
const Context = React.createContext<ContextValue | null>(null);

type Props = {
    userId: string;
    channel: number;
    children: React.ReactNode;
};
export const EntityChangeEventContextProvider: React.FC<Props> = ({ userId, channel, children }) => {
    const listeners = useRef<Record<Entity, Handler[]>>({ DataTable: [], DataColumn: [] });
    useEffect(() => {
        return openSocketConnection(
            `${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                process.env.REACT_APP_NOTIFICATIONS_URL || ""
            }/ws/v2/entity-change-event/${localStorage.tenant}/${channel}/${userId}`,
            (message) => {
                const event: Event = JSON.parse(message);
                listeners.current[event.model]?.forEach((fn) => fn(event));
            }
        );
    }, [userId, channel]);

    const addListener = useCallback(
        (entity: Entity, handler: Handler) => {
            listeners.current[entity] = listeners.current[entity].concat(handler);
            return () => {
                listeners.current[entity] = listeners.current[entity].filter((fn) => fn !== handler);
            };
        },
        [listeners]
    );

    const contextValue = useMemo(() => ({ addListener }), [addListener]);
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useEntityEventListener = (entity: Entity, handler: Handler) => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("Must use useEntityEventListener within an EntityChangeEventContextProvider");
    }
    useEffect(() => context.addListener(entity, handler), [entity, context, handler]);
};
