import { Stringish } from "@ignite-analytics/general-tools";
import { ChartConfig, ChartData, Chart as StandardChart, getValidAggOption } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { Chart } from "highcharts";
import React, { useCallback, useEffect, useState } from "react";

import { useGlobalFilterAction, useGlobalFilterIndices, useGlobalFilters } from "@/contexts/GlobalFilterContext";
import { useElasticFieldsForFilterContextProvider } from "@/hoc/withFilterContext";
import { useExportData } from "@/hooks/useExport";
import { useColors } from "@/lib/colors";

interface Props {
    title: Stringish;
    subTitle?: string;
    config: ChartConfig;
    data: ChartData | undefined;
    hideTitle?: boolean;
    analysisQuery?: AnalysisQuery;
    disableRefresh?: boolean;
    /** Callback to handle updates to query when drilling down. Pass `undefined` if not possible to support. */
    onDrilldown: ((newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => void) | undefined;
    /** Callback to handle saving of drilldown. Pass `undefined` if not possible to support. */
    onSaveDrilldown: ((saveAsNew: boolean) => Promise<void>) | undefined;

    /** Disable global filters and dispatchGlobalFilterAction to enable charts without opportunity to use and dispatch global filters */
    disableGlobalFilters?: boolean;

    // Used for exposing chart ref
    onRef?: (el: Chart | HTMLElement) => void;
}

/**
 * This component wraps the Chart component from charts package and provides required props based on the contexts
 * in this app.
 */
const ChartWithWebContext: React.FC<Props> = (extendedProps) => {
    const { config, analysisQuery, title } = extendedProps;
    const { disableGlobalFilters, onRef, ...chartProps } = extendedProps;
    const [chartRef, setChartRef] = useState<Chart | HTMLElement | null>(null);
    const exportFn = useExportData(title.toString());
    const chartType = getValidAggOption(config)?.type;
    const trackingFn = useCallback(
        (eventName: string, additionalProperties?: Record<string, unknown>) => {
            track(eventName, {
                chartType,
                title,
                config,
                ...additionalProperties,
            });
        },
        [chartType, config, title]
    );
    const indicesInGlobalFilterContext = useGlobalFilterIndices();
    const safeElasticIndexInGlobalFilterContext =
        indicesInGlobalFilterContext &&
        analysisQuery &&
        indicesInGlobalFilterContext.includes(analysisQuery.elasticIndex)
            ? analysisQuery.elasticIndex
            : indicesInGlobalFilterContext?.[0];

    const dispatchGlobalFilterAction = useGlobalFilterAction();

    useEffect(
        function propagateChartRef() {
            if (chartRef && onRef) {
                onRef(chartRef);
            }
        },
        [chartRef, onRef]
    );

    return (
        <StandardChart
            {...chartProps}
            onRef={setChartRef}
            dispatchGlobalFilterAction={disableGlobalFilters ? null : dispatchGlobalFilterAction}
            globalFilterIndex={disableGlobalFilters ? undefined : safeElasticIndexInGlobalFilterContext}
            globalFilters={useGlobalFilters()}
            colors={useColors()}
            trackingFn={trackingFn}
            analysisQuery={analysisQuery}
            exportFn={exportFn}
            useDataFields={useElasticFieldsForFilterContextProvider}
        />
    );
};

export default ChartWithWebContext;
