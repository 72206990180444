import { getMessages as getFilterMessages } from "@ignite-analytics/filters";
import { memoize } from "@ignite-analytics/general-tools";
import { getMessages as getChartMessages } from "@ignite-analytics/pivot-charts";
import { getMessages as getPivotTsMessages } from "@ignite-analytics/pivot-ts";

import localeDataDeDE from "./de-DE.json";
import localeDataEnUS from "./en-US.json";
import localeDataNbNO from "./nb-NO.json";

const getDashboardsMessages = (locale: string) => {
    if (locale === "nb-NO") return localeDataNbNO;
    if (locale === "de-DE") return localeDataDeDE;
    return localeDataEnUS;
};

export const getMessages = memoize((locale: string) => ({
    ...getDashboardsMessages(locale),
    ...getFilterMessages(locale),
    ...getChartMessages(locale),
    ...getPivotTsMessages(locale),
}));
