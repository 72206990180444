import { defineMessages } from "react-intl";

const messages = defineMessages({
    noDataTableSelected: {
        id: "components.widgets.components.fieldConfigurationDialog.columnSelectionSkeleton.noDataTableSelected",
        defaultMessage: "No data table selected",
    },
});

export default messages;
