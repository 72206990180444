import { defineMessages } from "react-intl";

const messages = defineMessages({
    dashboardOverviewPageHeader: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewPageHeader",
        defaultMessage: "Analytics",
    },

    igniteAnalyticsDashboards: {
        id: "dashboardOverview.dashboardOverviewPage.igniteAnalyticsDashboards",
        defaultMessage: "Ignite Analytics",
    },
    dashboardCreatedMsg: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardCreatedMsg",
        defaultMessage: "Dashboard Created",
    },
    save: {
        id: "dashboardOverview.dashboardOverviewPage.save",
        defaultMessage: "Save",
    },
    newAnalysis: {
        id: "dashboardOverview.dashboardOverviewPage.newAnalysis",
        defaultMessage: "New analysis",
    },
    predefinedAnalyses: {
        id: "dashboardOverview.dashboardOverviewPage.predefinedAnalyses",
        defaultMessage: "Predefined analyses",
    },
    predefinedAnalysisDescription: {
        id: "dashboardOverview.dashboardOverviewPage.predefinedAnalysisDescription",
        defaultMessage: "Choose among available analyses.",
    },
    createWidgetError: {
        id: "dashboardOverview.dashboardOverviewPage.createWidgetError",
        defaultMessage: "Something went wrong when trying to create analysis",
    },
});

export default messages;
