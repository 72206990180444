import { Filter } from "@ignite-analytics/filters";
import { Plus as AddIcon, X as CloseIcon } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import CreateDashboardModal from "../../../CreateDashboardModal";

import { commitUpdatesToCopiedWidget } from "./helpers";
import messages from "./messages";
import { createWidgetCopy } from "./services";

import PermissionContainer from "@/components/PermissionContainer";
import { getWidgetByType } from "@/components/Widgets";
import { Widget } from "@/components/Widgets/interfaces";
import { createWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";
import { useDashboardContextIfInDashboard } from "@/containers/CustomDashboardPage/DashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useCustomDashboardContext, useEditableDashboards } from "@/entities/dashboards";

const CREATE_WIDGET_PREFIX = "createWidgetModal-";

interface Props<W extends Widget> {
    open: boolean;
    widgetToCopy: W;
    onClose: () => void;
    filters?: Filter[];
    /** The `widgetToCopy` will be copied as-is in the backend. If some properties should be overwritten after copying, include them here. */
    modifiedProperties: Partial<W>;
}

/**
 * Modal for saving a copy of a widget, either with some changes or without any changes.
 * This is used when saving a drilldown, or when using the duplication button in edit dashboard mode.
 */
const SaveAsNewModal = <W extends Widget>({
    open,
    widgetToCopy: newWidget,
    onClose,
    filters,
    modifiedProperties,
}: Props<W>) => {
    const navigate = useNavigate();
    const dashboards = useEditableDashboards();
    const [openDashboardModal, setOpenDashboardModal] = useState(false);
    const [dashboardId, setDashboardId] = useState<number | undefined>();
    const dashboardCtx = useDashboardContextIfInDashboard();
    const currentDashboardId = dashboardCtx?.dashboard?.id;
    const { removeObj } = useCustomDashboardContext();
    const informUser = useInformUser();

    useEffect(
        function selectCurrentDashboardByDefault() {
            if (currentDashboardId) setDashboardId(currentDashboardId);
        },
        [currentDashboardId]
    );

    const createWidgetToDashboard = (title?: string) => {
        if (newWidget.id && dashboardId) {
            const { width, height } = newWidget;
            createWidgetCopy<W>(newWidget.id, newWidget.modelName, {
                dashboard: dashboardId,
                title,
                width,
                height,
            })
                .then(commitUpdatesToCopiedWidget(modifiedProperties, filters))
                .then((widget) => {
                    if (dashboardId === currentDashboardId && dashboardCtx) {
                        // Dispatching create action to add to dashboard after updating with widget history and filters
                        dashboardCtx.widgetDispatch({ data: widget, type: "CREATE_ONE", skipRedirectToAnalysis: true });
                    } else {
                        removeObj(dashboardId);
                        navigate(`/dashboard/overview/${dashboardId}/`, {
                            state: { scrollToWidgetId: widget.id },
                        });
                    }
                    onClose();
                })
                .catch((err) => {
                    informUser({ message: fm(messages.copyWidgetError), type: "error" });
                    Sentry.captureException(err, {
                        tags: {
                            error: fm(messages.copyWidgetError).toString(),
                            dashboardId,
                            app: "dashboards-app",
                        },
                    });
                });
        } else {
            const availableWidget = getWidgetByType(newWidget.type);
            if (!availableWidget || !dashboardId) return;

            const widget: W = {
                // Default config for type of widget
                ...availableWidget.defaultConfig,
                // The partial widget from props
                ...newWidget,
                // Any properties to overwrite
                ...modifiedProperties,
                title: (title || availableWidget.defaultConfig.title).toString(),
                customDashboard: dashboardId,
            };

            createWidget(dashboardId, widget)
                .then(() => {
                    track("newWidgetCreated", { widgetType: newWidget.type });
                    onClose();
                    if (dashboardId === currentDashboardId && dashboardCtx)
                        dashboardCtx.widgetDispatch({ data: widget, type: "CREATE_ONE" });
                    else navigate(`/dashboard/overview/${dashboardId}/`);
                })
                .catch(() => {
                    informUser({ message: fm(messages.createWidgetError), type: "error" });
                });
        }
    };
    const [title, setTitle] = useState<string | undefined>(newWidget.title);

    const handleSubmit = () => {
        if (!title) return;
        createWidgetToDashboard(title);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">{fm(messages.createWidget)}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <PermissionContainer
                    requiredPermissionTypes={["add"]}
                    equivalentUserPermission={{
                        namespace: "dashboards",
                        relation: { object: "general", relation: "write" },
                    }}
                >
                    <CreateDashboardModal
                        open={openDashboardModal}
                        onClose={() => setOpenDashboardModal(false)}
                        setDashboardId={setDashboardId}
                    />
                </PermissionContainer>
                <Stack gap={2} pt={1}>
                    <TextField
                        label={fm(messages.widgetTitle)}
                        name="title"
                        defaultValue={newWidget.title}
                        data-testid={`${CREATE_WIDGET_PREFIX}titleInput`}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Stack direction="row">
                        <FormControl fullWidth>
                            <InputLabel id="chooseDashboardLabel">{fm(messages.chooseDashboard)}</InputLabel>
                            <Select
                                fullWidth
                                labelId="chooseDashboardLabel"
                                label={fm(messages.chooseDashboard)}
                                onChange={(e) => {
                                    if (typeof e.target.value === "number") {
                                        setDashboardId(e.target.value);
                                    } else {
                                        setDashboardId(undefined);
                                    }
                                }}
                                defaultValue={currentDashboardId}
                                data-testid={`${CREATE_WIDGET_PREFIX}selectDashboard`}
                                MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
                            >
                                {[
                                    ...(currentDashboardId
                                        ? [
                                              {
                                                  text: <i>{fm(messages.currentDashboard)}</i>,
                                                  value: currentDashboardId,
                                              },
                                          ]
                                        : []),
                                    ...(dashboards
                                        ? dashboards
                                              .filter((dashboard) => dashboard.id !== currentDashboardId)
                                              .map((dashboard) => ({
                                                  text: dashboard.name,
                                                  value: dashboard.id,
                                              }))
                                        : []),
                                ].map((dashboard) => (
                                    <MenuItem key={dashboard.value} value={dashboard.value}>
                                        {dashboard.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title={fm(messages.createDashboard)}>
                            <IconButton
                                data-testid={`${CREATE_WIDGET_PREFIX}createDashboardButton`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenDashboardModal(true);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <DialogActions>
                        <Button data-testid={`${CREATE_WIDGET_PREFIX}submitButton`} onClick={() => handleSubmit()}>
                            {fm(messages.createWidget)}
                        </Button>
                    </DialogActions>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default SaveAsNewModal;
