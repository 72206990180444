import { useEffect } from "react";

import { CustomDashboard } from "@/entities/dashboards";

export function useRegisterVisit(dashboard: CustomDashboard | undefined) {
    useEffect(() => {
        if (!process.env.REACT_APP_QUICK_ACCESS_URL || !dashboard) {
            return;
        }

        const url = process.env.REACT_APP_QUICK_ACCESS_URL;
        const link = `/dashboard/overview/${dashboard.id}/`;
        const tenant = localStorage.getItem("tenant");

        // Data to be sent in the request body
        const data = {
            entityId: `dash-${dashboard.id}`,
            title: dashboard.name,
            type: "DASHBOARD",
            date: new Date().toISOString(),
            link,
        };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...(tenant ? { "x-tenant-id": tenant } : {}),
            },
            credentials: "include",
            body: JSON.stringify(data),
        }).catch(() => console.log("Not able to register visit"));
    }, [dashboard]);
}
