import { Stringish } from "@ignite-analytics/general-tools";
import { ChevronDown as ArrowDropDownIcon, Text as TitleIcon } from "@ignite-analytics/icons";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { DraftBlockType } from "draft-js";
import React, { useState } from "react";

import messages from "../messages";

import { fm } from "@/contexts/IntlContext";

type Props = {
    onChange: (value: DraftBlockType) => void;
};

export const HeaderMenu: React.FC<Props> = ({ onChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const textTypes: { value: DraftBlockType; text: Stringish }[] = [
        { value: "header-one", text: fm(messages.heading1) },
        { value: "header-two", text: fm(messages.heading2) },
        { value: "header-three", text: fm(messages.heading3) },
        { value: "section", text: fm(messages.section) },
    ];

    const handleSelectBlockType = (value: DraftBlockType) => {
        onChange(value);
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <TitleIcon />
                <ArrowDropDownIcon />
            </IconButton>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {textTypes.map(({ value, text }) => (
                    <MenuItem key={value} onClick={() => handleSelectBlockType(value)}>
                        {text.toString()}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
