import { X as CloseIcon } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";

import { fm } from "@/contexts/IntlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    /* If the dialog is open */
    open: boolean;
    /* The title of the success modal, should describe what has been done successfully */
    successTitle: string;
    /* The subtitle of the success modal, could go into further detail */
    successSubTitle?: string;
    /* Event handler for close actions, e.g. go back to a previous location, close the dialog */
    onClose: () => void;
    /* Event handler for continue actions, e.g. where should we go next? */
    onContinue: () => void;
    /* Button text for the continue button, defaults to "Continue" */
    continueButtonText?: string;
    /* Button text for the close button, defaults to "Close" */
    closeButtonText?: string;
}

const SuccessDialog: React.VFC<Props> = ({
    open,
    successTitle,
    successSubTitle,
    onClose,
    onContinue,
    continueButtonText,
    closeButtonText,
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">{successTitle}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">{successSubTitle}</Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="flex-end" paddingTop={1} columnGap={1}>
                    <Stack>
                        <Button variant="text" color="secondary" onClick={onClose}>
                            {closeButtonText || fm(globalMessages.close)}
                        </Button>
                    </Stack>
                    <Stack>
                        <Button color="primary" onClick={onContinue}>
                            {continueButtonText || fm(globalMessages.continueButton)}
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessDialog;
