import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import {
    FilterContextHocConfig,
    withFilterContext as generalWithFilterContext,
    useCompanyFavouriteFilters,
    useFilterFieldMappingDataFields,
    usePersonalFavouriteFilters,
    useUpsertCompanyFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import React, { useMemo } from "react";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";

/**
 * Returns elastic fields without filter field mappings
 */
export const useElasticFieldsForFilterContextProvider = (
    indexNames: string[] | undefined
): ElasticField[] | undefined => {
    return useElasticFields(indexNames);
};

/**
 * Uses useElasticFields to get the elastic fields, and then adds the filter field mappings to the result if the feature toggle returns true.
 */
export const useElasticFieldsWithFilterFieldMappings = (
    indexNames: string[] | undefined
): ElasticField[] | undefined => {
    const elasticFields = useElasticFields(indexNames);

    const filterFieldMappingElasticFields = useFilterFieldMappingDataFields(
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string,
        indexNames
    );

    return useMemo(() => {
        if (!indexNames?.length) return [];
        if (!elasticFields) return undefined;
        return elasticFields.concat(filterFieldMappingElasticFields);
    }, [elasticFields, filterFieldMappingElasticFields, indexNames?.length]);
};

/**
 * Wraps the filter context hoc, such that common hooks does not need to be provided for each usage.
 */
const withFilterContext = <InnerProps extends object, Config extends FilterContextHocConfig<InnerProps>>(
    WrappedComponent: React.FC<InnerProps>,
    config: Config,
    reactFallback?: JSX.Element,
    RenderFallback?: React.FC<InnerProps>
) =>
    generalWithFilterContext(
        WrappedComponent,
        {
            ...config,
            // Filter favourite hooks
            useAllDataSources: useAllElasticIndices,
            usePersonalFavouriteFilters: () =>
                usePersonalFavouriteFilters(useOryUserIdOrThrow(), process.env.REACT_APP_GRAPHQL_ROUTER_URL as string),
            useCompanyFavouriteFilters: () =>
                useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string),
            useUpsertPersonalFavouriteFilters: () =>
                useUpsertPersonalFavouriteFilters(
                    useOryUserIdOrThrow(),
                    process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
                ),
            useUpsertCompanyFavouriteFilters: () =>
                useUpsertCompanyFavouriteFilters(
                    useOryUserIdOrThrow(),
                    process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
                ),
        },
        reactFallback,
        RenderFallback
    );

export default withFilterContext;
