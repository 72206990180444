import { defineMessages } from "react-intl";

const messages = defineMessages({
    getStarted: {
        id: "Components.GetStartedCard.getStarted",
        defaultMessage: "Get started",
    },
});

export default messages;
