import { getLocale } from "@ignite-analytics/locale";
import { Box, CircularProgress, Divider, Paper, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { orderBy } from "lodash-es";
import React, { useMemo, useState } from "react";

import { UserAvatar } from "../../UserAvatar";
import messages from "../messages";

import { UserFragment } from "@/__generated__/graphql";
import { fm } from "@/contexts/IntlContext";
import { useDashboardCollection } from "@/entities/dashboardCollections";
import { CustomDashboardListObject } from "@/entities/dashboards";
import { useModelGuard } from "@/entities/modelGuards";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/usePermissionCheck";
import { useUser, useUsers } from "@/hooks/useUsers";

type UserList = {
    userId: string;
    read: boolean;
    write: boolean;
}[];

interface DashboardRowProps {
    dashboard: CustomDashboardListObject;
    onRowClick?: () => void;
}

type DetailProps = {
    title: string;
    footer?: string;
    anchorEl: React.ReactNode;
    ContentComponent: React.ReactNode;
};

const DetailBox: React.FC<DetailProps> = ({ title, footer, anchorEl, ContentComponent }) => {
    const [open, setOpen] = useState(false);
    return (
        <Stack onMouseLeave={() => setOpen(false)} onMouseEnter={() => setOpen(true)}>
            {anchorEl}
            {open && (
                <Box>
                    <Paper
                        square
                        sx={{
                            borderRadius: "4px",
                            background: (theme) => theme.palette.secondary.dark,
                            transform: "translate(-89%, 0%)",
                            position: "absolute",
                            zIndex: 10,
                            display: open ? "block" : "none",
                        }}
                    >
                        <Box sx={{ width: "300px" }} px={2} py={1}>
                            <Typography color={(theme) => theme.palette.grey[400]} variant="subtitle1">
                                {title}
                            </Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                {ContentComponent}
                            </Stack>
                            {footer !== undefined && (
                                <>
                                    <Divider
                                        variant="fullWidth"
                                        light
                                        sx={{ background: (theme) => theme.palette.primary.light }}
                                    />
                                    <Typography color={(theme) => theme.palette.grey[400]} variant="subtitle1">
                                        {footer}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            )}
        </Stack>
    );
};

const textDark = (text: string) => (
    <Typography color={(theme) => theme.palette.grey[200]} variant="body2">
        {text}
    </Typography>
);

const getName = (u: UserFragment | undefined): string => {
    if (!u) return fm(messages.unknownOwner).toString();
    return `${u.firstName} ${u.lastName}`;
};

const SharedWithList: React.FC<{ user: UserFragment }> = ({ user }) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <UserAvatar user={user} size="sm" />
            {textDark(getName(user))}
        </Stack>
    );
};

const SharedWithListComponent: React.FC<{ users: UserList }> = ({ users }) => {
    const userIds = users.map((u) => u.userId);
    const { users: loadedUsers } = useUsers(userIds.length, userIds);
    if (users.length === 0) {
        return textDark(fm(messages.everyone).toString());
    }

    return (
        <Stack>
            {loadedUsers.map((user) => (
                <SharedWithList key={user.id} user={user} />
            ))}
        </Stack>
    );
};

export const DashboardRow: React.FC<DashboardRowProps> = (props: DashboardRowProps) => {
    const { dashboard, onRowClick } = props;

    const collection = useDashboardCollection(
        dashboard.dashboardCollection === null ? undefined : dashboard.dashboardCollection,
        undefined,
        true,
        { service: "dashboards" }
    );
    const hasGuardViewPermission = useBackwardCompatiblePermissionCheck("view", {
        namespace: "dashboards",
        relation: { object: "general", relation: "read" },
    });
    const modelGuard = useModelGuard((hasGuardViewPermission && dashboard.guard) || NaN, undefined, undefined, {
        service: "dashboards",
    });

    const { user: owner } = useUser(dashboard.owner);

    const isModelGuardLoading = hasGuardViewPermission && dashboard.guard && !modelGuard;
    const users: UserList | undefined = useMemo(() => {
        if (!modelGuard) return [];
        const { readUsers, writeUsers } = modelGuard;
        return orderBy(
            [
                ...readUsers
                    .filter((userId) => !writeUsers.includes(userId))
                    .map((userId) => ({ userId, read: true, write: false })),
                ...writeUsers.map((userId) => ({ userId, read: true, write: true })),
            ],
            "userId"
        );
    }, [modelGuard]);

    const formatTimestamp = (date: string) => {
        return new Date(date).toLocaleString(getLocale(), {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    };

    const textLight = (text: string) => (
        <Typography
            sx={{ overflow: "inherit", borderRight: 0, whiteSpace: "inherit", textOverflow: "inherit" }}
            variant="body2"
        >
            {text}
        </Typography>
    );

    const sharedWithComponent = useMemo(() => {
        if (isModelGuardLoading) {
            return (
                <Stack alignItems="center" justifyItems="center" sx={{ width: "32px" }}>
                    <CircularProgress />
                </Stack>
            );
        }
        if (users.length === 0 || (modelGuard?.standardRead && modelGuard?.standardWrite)) {
            return (
                <Stack alignItems="center" justifyItems="center" sx={{ width: "32px" }}>
                    <Typography sx={{ flexGrow: 1 }} noWrap variant="body2" component="span">
                        {fm(messages.standardPermissionDescription)}
                    </Typography>
                </Stack>
            );
        }
        return (
            <Stack alignItems="center" justifyItems="center" sx={{ width: "32px" }}>
                <Typography noWrap variant="body2" component="span" sx={{ display: "inline-block" }}>
                    {`+ ${users.length}`}
                </Typography>
            </Stack>
        );
    }, [isModelGuardLoading, users.length, modelGuard?.standardRead, modelGuard?.standardWrite]);

    return (
        <TableRow key={dashboard.id} onClick={onRowClick}>
            <TableCell
                sx={{
                    maxWidth: "10px",
                    overflow: "hidden",
                    borderRight: 0,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
            >
                {textLight(dashboard.name)}
            </TableCell>
            <TableCell>{textLight(formatTimestamp(dashboard.updatedAt))}</TableCell>
            <TableCell>{textLight(collection?.name || "")}</TableCell>
            <TableCell>
                {/* excess size will be shrunk, so keep this at a random, high value to ensure table fills all space of parent */}
                <Stack pr={2} columnGap={1} direction="row-reverse" alignItems="center">
                    <DetailBox
                        title={fm(messages.sharedWith).toString()}
                        anchorEl={sharedWithComponent}
                        ContentComponent={<SharedWithListComponent users={users} />}
                    />
                    <DetailBox
                        title={fm(messages.owner).toString()}
                        footer={fm(messages.created, { at: formatTimestamp(dashboard.createdAt) }).toString()}
                        anchorEl={owner ? <UserAvatar size="md" user={owner} /> : null}
                        ContentComponent={
                            <Typography color={(theme) => theme.palette.grey[200]} variant="subtitle1">
                                {getName(owner)}
                            </Typography>
                        }
                    />
                </Stack>
            </TableCell>
        </TableRow>
    );
};
