import { defineMessages } from "react-intl";

const messages = defineMessages({
    filterInfoFrom: {
        id: "components.chart.filterInfoFrom",
        defaultMessage: "From {start}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoTo: {
        id: "components.chart.filterInfoTo",
        defaultMessage: "To {end}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoMin: {
        id: "components.chart.filterInfoMin",
        defaultMessage: "Min {min}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoMax: {
        id: "components.chart.filterInfoMax",
        defaultMessage: "Max {max}",
        description: "Filter information at the bottom of the chart",
    },
    noActiveFilters: {
        id: "components.chart.noActiveFilters",
        defaultMessage: "No active filters",
        description: "Filter information at the bottom of the chart",
    },
});

export default messages;
