import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";

/**
 * Takes in the name of an ElasticSearch index and returns the correct name for the corresponding table.
 */
export function useTableName(indexName: undefined): undefined;
export function useTableName(indexName: string): string;
export function useTableName(indexName: string | undefined): string | undefined;
export function useTableName(indexName: string | undefined) {
    const elasticIndexes = useAllElasticIndices();
    if (!indexName) return;
    const elasticLabel = elasticIndexes?.find((index) => index.name === indexName)?.label;
    return elasticLabel || indexName;
}
