/* eslint-disable @typescript-eslint/no-explicit-any */
import { objectHelpers } from "@ignite-analytics/general-tools";
import update from "immutability-helper";
import { sortBy } from "lodash-es";

import { Options } from "@/components/Widgets/interfaces";

/**
 * Check if some keys in object has changed
 * @param obj1 Old object
 * @param obj2 New object
 * @param keys The keys that should be checked
 * @returns {boolean}
 */
export const objectsDiffer = (
    obj1: { [key: string]: any },
    obj2: { [key: string]: any },
    ...argKeys: (keyof typeof obj1 | keyof typeof obj2)[]
): boolean => {
    const keys = argKeys.length ? argKeys : Object.keys(obj2);
    let differs = false;
    keys.forEach((key) => {
        if (differs) return;
        if (obj1[key] !== obj2[key]) differs = true;
    });
    return differs;
};

// Gets requested value from object, or returns fallback argument if object or value is falsy
const getVal = <R>(
    object: Record<string, any> | undefined,
    key: string,
    fallback?: R
): typeof fallback extends undefined ? R | typeof fallback : R =>
    object ? (object[key] !== undefined ? object[key] : fallback) : fallback;

// Gets requested value from object recursively (key can contain dots and will be parsed)
const getValByPath = <R>(
    object: Record<string, any>,
    path: string[],
    fallback?: R
): typeof fallback extends undefined ? R | typeof fallback : R =>
    path.reduce(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TS ignore since the compiler complains about the generic types, should be fixed but not prioritized todo: fix this or check if function actually still is needed
        (value: Record<string, any> | undefined, keyValue) => getVal(value, keyValue, fallback),
        object
    ) as typeof fallback extends undefined ? R | typeof fallback : R;

// Gets requested value from object recursively (key can contain dots and will be parsed)
export const getValRecursively = <R>(
    object: Record<string, any>,
    key: string,
    fallback?: R
): typeof fallback extends undefined ? R | typeof fallback : R => getValByPath(object, key.split("."), fallback);

// Creates a spec for setting a field to a value using a recursive key (key that can contain dots)
const createSpecRecursively = (key: string, value: any) =>
    key.split(".").reduceRight((s: Record<string, any>, k) => ({ [k]: s }), { $set: value });

// Updates an option object with a new options with nested keys on the format level1.level2
export const updateOptions = (options: Options, newOptions: Record<string, string | number | boolean>) => {
    // Apply update specs, in order of how specific the key is (most specific last)
    return sortBy(
        Object.keys(newOptions).filter((k) => newOptions[k] !== undefined),
        (key) => key.split(".").length
    ).reduce((res, key) => update(res, createSpecRecursively(key, newOptions[key])), options);
};

/** Typed version of Object.entries */
export const { getEntries, fromEntries } = objectHelpers;
