import { Api } from "@ignite-analytics/api-client";

export interface HomeDashboardRelation {
    id: number;
    user: string;
    dashboard: number;
}

export function updateOrCreateHomeDashboardRelation(userId: string, dashboard: number): Promise<HomeDashboardRelation> {
    const url = `dashboards/user/${userId}/home-dashboard-relations/`;
    return Api.put(url, { dashboard }, { service: "dashboards" });
}

export function getHomeDashboardRelation(userId: string): Promise<HomeDashboardRelation> {
    const url = `dashboards/user/${userId}/home-dashboard-relations/`;
    const cancelKey = `$home-dashboard-relation-${userId}`;
    return Api.get(url, { cancelKey, service: "dashboards" });
}
