import { useFilters } from "@ignite-analytics/filters";
import { Plus as AddIcon, FloppyDisk as SaveIcon, Undo as UndoIcon } from "@ignite-analytics/icons";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, InsightModal, PivotResponse, useInsightModalContext } from "@ignite-analytics/pivot-ts";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";

import { InnerTable } from "../InnerTable";

import { SplitChangeSpec } from "@/components/PivotTable/interfaces";
import saveWidgetMessages from "@/components/Widgets/AnalysisCard/SaveAsNewModal/messages";
import { staticFormatMessage } from "@/contexts/IntlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onRef: (el: HTMLTableElement) => void;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const TableWrapper: React.FC<Props> = ({
    data,
    query,
    config,
    editable,
    onSplitChange,
    onHeaderChange,
    onRef,
}) => {
    const { modalState, onSave, savingIsSupported, revertDrilldown, dirty } = useInsightModalContext();
    const filters = useFilters();
    return (
        <>
            <InnerTable
                onRef={onRef}
                data={data}
                query={query}
                config={config}
                editable={editable}
                onSplitChange={onSplitChange}
                onHeaderChange={onHeaderChange}
            />
            {dirty && (
                <Stack
                    sx={{
                        position: "absolute",
                        zIndex: 1,
                        right: 0,
                        top: 0,
                        marginRight: "1.5em",
                    }}
                    direction="row"
                >
                    {savingIsSupported && (
                        <Tooltip title={staticFormatMessage(saveWidgetMessages.createWidget)}>
                            <IconButton onClick={() => onSave(true)} sx={{ padding: 0 }}>
                                <AddIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {savingIsSupported && (
                        <Tooltip title={staticFormatMessage(saveWidgetMessages.updateWidget)}>
                            <IconButton onClick={() => onSave(false)}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={staticFormatMessage(globalMessages.undoButton)}>
                        <IconButton onClick={revertDrilldown}>
                            <UndoIcon color="warning" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )}
            {modalState && <InsightModal activeFilters={filters} />}
        </>
    );
};
