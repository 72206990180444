import { darken, lighten, styled } from "@mui/material";

interface AlignmentProps {
    alignment?: string;
}
interface ThProps extends AlignmentProps {
    horizontalPadding: number;
    hover?: boolean;
    totalRow?: boolean;
    label?: string;
    totalColumn?: boolean;
    clickable?: boolean;
    wrapLabels?: boolean;
}

interface TdProps extends AlignmentProps {
    horizontalPadding: number;
    clickable?: boolean;
}

interface TableProps {
    fillAvailable?: boolean;
}

interface TrProps {
    headerRow?: boolean;
}

export const Th = styled("th")<ThProps>`
    color: black;
    position: relative;
    text-align: ${(props) => (props.alignment ? props.alignment : "center")};
    line-height: 1.6;

    padding: 5px ${(props) => props.horizontalPadding}px;
    background: #eaeaea;
    border: 0.5px solid #ffffff;

    cursor: ${(props) => (props.hover ? "pointer" : "default")};
    ${({ wrapLabels }) => {
        if (wrapLabels)
            return `
        white-space: normal;
        overflow: hidden;
        `;
        return `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        `;
    }}
    ${({ clickable }) => {
        if (!clickable) return "";
        return `
        &:hover {
            cursor: pointer;
            background: ${darken("#EAEAEA", 0.05)};
        }`;
    }};
`;

export const Td = styled("td")<TdProps>`
    color: #000;
    padding: ${(props) => props.horizontalPadding}px;
    line-height: 1.6;

    border: 0.5px solid #eaeaea;

    text-align: ${(props) => (props.alignment ? props.alignment : "right")};

    ${({ clickable }) => {
        if (!clickable) return "";
        return `
        &:hover {
            cursor: pointer;
            background: ${lighten("#EAEAEA", 0.05)};
        }`;
    }};
`;

export const AggHeader = styled(Th)`
    position: relative;

    text-align: ${(p) => p.alignment};
    background: #f6f6f7;
    border: 0.5px solid #eaeaea;
`;

export const Tr = styled("tr")<TrProps>`
    color: #000;
    padding: 2px;
    background: #fff;
    border: 0;
    transition: all 0.3s ease;
    ${(props) => {
        if (props.headerRow) {
            return `&:nth-child(odd) {
            background: linear-gradient(90deg, #f7f7f7, #efefef);
        }`;
        }
    }}
`;

export const TBody = styled("tbody")`
    overflow: hidden;
    border-radius: 5px;
    color: #000;
    text-align: center;
    padding: 20px;
    vertical-align: center;
    border-top: 0;
    font-variant-numeric: lining-nums tabular-nums;
`;

export const Table = styled("table")<TableProps>`
    color: #000;
    font-size: 10pt;
    border: 0;

    border-collapse: separate;
    border-spacing: 1.5px;

    height: ${(props) => (props.fillAvailable ? "100%" : "initial")};
`;

export const THead = styled("thead")<{ sticky?: boolean }>`
    color: #000;
    padding: 5px;
    vertical-align: center;
    border-top: 0;
    text-align: center;

    ${({ sticky }) => (sticky ? "position: sticky; top: 0; z-index: 1;" : "")}
`;

export const ResizeDiv = styled("div")`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    opacity: 0;
    background: #d7d9da;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    &:hover {
        opacity: 0.8;
    }
`;

export const ScrollableDiv = styled("div")`
    overflow: auto;
    width: 100%;
    height: 100%;
`;
