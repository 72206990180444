import { defineMessages } from "react-intl";

const messages = defineMessages({
    widget: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.widget",
        defaultMessage: "Analysis",
    },
    field: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.field",
        defaultMessage: "Field",
    },
    description: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.description",
        defaultMessage: "Description",
    },
    source: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.source",
        defaultMessage: "Source",
    },
    noFieldSelected: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.noFieldSelected",
        defaultMessage: "You must select a field.",
    },
    table: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.table",
        defaultMessage: "Table",
    },
    column: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.column",
        defaultMessage: "Column",
    },
    group: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.group",
        defaultMessage: "Group",
    },
    updatedFields: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.updatedFields",
        defaultMessage: "Successfully updated the fields",
    },
    refreshToSeeChanges: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.refreshToSeeChanges",
        defaultMessage: "Refresh to see the changes",
    },
    refresh: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.refresh",
        defaultMessage: "Refresh",
    },
    selectATable: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.selectATable",
        defaultMessage: "Select a table",
    },
    type: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.type",
        defaultMessage: "Type",
    },
    noColumnsAvailable: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.noColumnsAvailable",
        defaultMessage: "No columns available",
    },
    errorUpdatingWidget: {
        id: "hoc.widgetWrapper.fieldConfigurationModal.errorUpdatingWidget",
        defaultMessage: "Error updating analysis",
    },
});
export default messages;
