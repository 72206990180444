import { FilterModal } from "@ignite-analytics/filters";
import { Stringish, useVisible } from "@ignite-analytics/general-tools";
import { ArrowDownTray as FileDownloadIcon } from "@ignite-analytics/icons";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { track } from "@ignite-analytics/track";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

import AnalysisOptions from "@/components/AnalysisOptions";
import { AnalysisOptionProps } from "@/components/AnalysisOptions/Fields";
import chartContainerMessages from "@/components/Charts/ChartContainer/messages";
import FilterCardFooter from "@/components/Widgets/Components/FilterCardFooter";
import { Options } from "@/components/Widgets/interfaces";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { ExportFunc, ExportMode } from "@/hooks/useExport/interfaces";

interface Props {
    title: Stringish;
    optionConfig?: Record<string, React.FC<AnalysisOptionProps>>;
    options?: Options;
    onOptionsUpdate?: (opts: Options) => void;
    chartConfig: ChartConfig;
    children: React.ReactNode;
    exportFuncRef: React.MutableRefObject<ExportFunc | undefined>;
    height: string | number;
    disableSingleExport?: boolean;
    disableExcelExport?: boolean;
    disableCsvExport?: boolean;
    disableImageExport?: boolean;
}

const ChartContainer: React.FC<Props> = ({
    title,
    children,
    chartConfig,
    options,
    height,
    onOptionsUpdate,
    optionConfig,
    exportFuncRef,
    disableSingleExport = false,
    disableExcelExport = false,
    disableCsvExport = false,
    disableImageExport = false,
}) => {
    const [exporting, setExporting] = useState(false);
    const [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openExportMenu = Boolean(exportMenuAnchorEl);
    const informUser = useInformUser();
    const { visible, setElement } = useVisible(200);

    const handleSelect = (mode: ExportMode) => {
        track("Export data", { mode, placement: "Widget header" });
        setExportMenuAnchorEl(null);
        const exportFunc = exportFuncRef.current;
        if (!exportFunc) return;
        setExporting(true);
        switch (mode) {
            case "excel":
            case "csv":
                exportFunc({
                    exportType: mode,
                    chartConfig,
                })
                    .catch((errMessage) => {
                        if (typeof errMessage === "string") {
                            informUser({ type: "error", message: errMessage });
                        }
                    })
                    .finally(() => setExporting(false));
                break;
            case "image":
                exportFunc({
                    exportType: mode,
                    options: options ?? {},
                })
                    .catch((errMessage) => {
                        if (typeof errMessage === "string") {
                            informUser({ type: "error", message: errMessage });
                        }
                    })
                    .finally(() => setExporting(false));
                break;
        }
    };

    const handleOpenExportMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setExportMenuAnchorEl(e.currentTarget);
    };

    const SHARE_OPTIONS: { value: ExportMode; message: Stringish; active: boolean }[] = [
        {
            value: "excel",
            message: fm(chartContainerMessages.tooltipSaveDataAsExcel),
            active: !disableExcelExport,
        },
        {
            value: "csv",
            message: fm(chartContainerMessages.tooltipSaveDataAsCsv),
            active: !disableCsvExport,
        },
        {
            value: "image",
            message: fm(chartContainerMessages.tooltipSaveImg),
            active: !disableImageExport,
        },
    ];

    return (
        <Card variant="outlined" data-testid="chartContainer" ref={setElement} sx={{ height: "100%", width: "100%" }}>
            <CardHeader
                title={
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">{title.toString()}</Typography>
                            {visible && (
                                <Stack
                                    sx={{ flexWrap: "nowrap" }}
                                    direction="row"
                                    alignItems="center"
                                    data-hide-from-export
                                >
                                    <FilterModal title={title.toString()} placement="Chart container" />
                                    {optionConfig && options && onOptionsUpdate && (
                                        <AnalysisOptions
                                            fields={optionConfig}
                                            options={options}
                                            onSubmit={onOptionsUpdate}
                                        />
                                    )}
                                    {!disableSingleExport && (
                                        <>
                                            <Tooltip title={fm(chartContainerMessages.tooltipExport)}>
                                                <IconButton onClick={handleOpenExportMenu} size="small">
                                                    <FileDownloadIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={exportMenuAnchorEl}
                                                onClose={() => setExportMenuAnchorEl(null)}
                                                open={openExportMenu}
                                            >
                                                {SHARE_OPTIONS.filter((option) => option.active).map((option) => (
                                                    <MenuItem
                                                        key={`option-${option.value}`}
                                                        value={option.value}
                                                        onClick={() => handleSelect(option.value)}
                                                        disabled={exporting}
                                                    >
                                                        {option.message.toString()}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </>
                }
            />
            <CardContent>
                <Stack height={height} width="100%" sx={{ position: "relative" }}>
                    {visible && children}
                </Stack>
            </CardContent>
            <CardActions>{visible && <FilterCardFooter analysisOptions={options} />}</CardActions>
        </Card>
    );
};

export default ChartContainer;
