import { FilterModal, useFilters } from "@ignite-analytics/filters";
import { X as CloseIcon } from "@ignite-analytics/icons";
import { Button, Dialog, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React from "react";

import { getWidgetByType } from "../../index";
import { Widget, WidgetProps } from "../../interfaces";
import sharedMessages from "../../messages";

import messages from "./messages";

import { AnalysisOptionProps } from "@/components/AnalysisOptions/Fields";
import { updateWidget } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/services";
import { UPDATE_ONE, useDashboardContextOrThrow } from "@/containers/CustomDashboardPage/DashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import withFilterContext, { useElasticFieldsForFilterContextProvider } from "@/hoc/withFilterContext";
import { useElasticIndexInContextByName } from "@/hooks/useElasticIndexInContextByName";
import globalMessages from "@/lib/messages/globalMessages";
import { updateOptions } from "@/lib/objectHelpers";

const TEST_ID_PREFIX = "editModal-";

interface Props extends WidgetProps<Widget, object> {
    hidden?: boolean;
    fields: Partial<Record<string, React.FC<AnalysisOptionProps>>>;
    onClose: () => void;
}

/**
 * Edit modal for widgets
 */

const EditModal: React.FC<Props> = ({ hidden, widget, elasticIndex, onClose, onDelete, fields }) => {
    const filters = useFilters();
    const { widgetDispatch } = useDashboardContextOrThrow();
    const [loading, setLoading] = React.useState(false);
    const informUser = useInformUser();

    /**
     * Closes the modal and informs the parent that the save button was clicked
     */
    const onSave = (values: FormikValues) => {
        const { title, ...config } = values;
        const widgetData = {
            ...widget,
            title: title || getWidgetByType(widget.type)?.defaultConfig.title,
            // In deprecated widgets, options are nested under "config", in other widgets, they should be added at top level
            ...("config" in widget ? { config: updateOptions(widget.config, config) } : updateOptions(widget, values)),
            filters: filters || [],
        };
        setLoading(true);
        updateWidget(widgetData as Widget)
            .then((data) => {
                widgetDispatch({ type: UPDATE_ONE, data });
                onClose();
            })
            .catch((error) => {
                const errorMessage =
                    error.response && error.response.status === 404
                        ? fm(sharedMessages.widgetNotInDepartment)
                        : fm(sharedMessages.somethingWentWrongSaving);

                informUser({ message: errorMessage, type: "error" });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Dialog open={!hidden} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">{fm(messages.widgetSettingsHeader)}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Formik initialValues={{}} onSubmit={onSave}>
                {(formikProps) => (
                    <Form>
                        <Stack gap={2} p={2}>
                            {elasticIndex && (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography noWrap variant="subtitle1">
                                        {fm(messages.filters)}
                                    </Typography>
                                    <FilterModal placement="Edit widget modal" />
                                </Stack>
                            )}
                            <TextField
                                data-testid={`${TEST_ID_PREFIX}nameInput`}
                                name="title"
                                label={fm(messages.editTitle)}
                                defaultValue={widget.title}
                                onChange={formikProps.handleChange}
                            />
                            {Object.entries(fields).map(
                                ([name, Field]) =>
                                    Field && (
                                        <Field
                                            key={name}
                                            activeOptions={"config" in widget ? widget.config : widget}
                                            name={name}
                                            onChange={(value) => formikProps.setFieldValue(`['${name}']`, value)}
                                        />
                                    )
                            )}
                            <Stack direction="row" justifyContent="flex-end" gap={2}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        onDelete();
                                        onClose();
                                    }}
                                    disabled={loading}
                                    data-testid={`${TEST_ID_PREFIX}deleteButton`}
                                >
                                    {fm(globalMessages.deleteButton)}
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                    data-testid={`${TEST_ID_PREFIX}saveButton`}
                                >
                                    {fm(globalMessages.saveButton)}
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

// Optimizing performance
const MemoizedComponent = React.memo(EditModal, (props, nextProps) => {
    return props.hidden !== nextProps.hidden;
});

export default withFilterContext(MemoizedComponent, {
    inherit: false,
    useDataFields: useElasticFieldsForFilterContextProvider,
    useDataSource: ({ elasticIndex }) => useElasticIndexInContextByName(elasticIndex),
    disableDefaultCombination: true,
    useInitialFilters: ({ widget }) => widget.filters,
});
