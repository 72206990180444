import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { SUPPLIER_GT, TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import { useMemo } from "react";

import { CHART_CONFIG } from "./constants";
import { getMatrixQuery, getMissingFieldsText } from "./helpers";
import { matrixDataHandler, useInitialFilters } from "./hooks";
import messages from "./messages";

import { staticFormatMessage } from "@/contexts/IntlContext";
import pivotAnalysis, { ClientSupplier, MinimumRequiredOuterProps, SimpleClientSupplier } from "@/hoc/PivotAnalysis";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

interface Props extends MinimumRequiredOuterProps {
    supplier?: ClientSupplier | SimpleClientSupplier;
}

export default pivotAnalysis<Props>(
    function useInitialQuery() {
        const transactionIndex = useTransactionsIndex()?.name;
        const elasticFields = useElasticFields(transactionIndex);
        return useMemo(() => {
            if (!elasticFields || !transactionIndex) return new Error(staticFormatMessage(messages.analysisNotReady));

            const findFieldByGtKey = (key: string): ElasticField | undefined =>
                elasticFields?.find((field) => field.globalTypeKey === key);
            const findFieldIdByPostfix = (postfix: string): string | undefined =>
                elasticFields?.find((field) => field.fieldId.endsWith(postfix))?.fieldId;

            const ebitMarginField = findFieldIdByPostfix("latest_ebit_margin");
            const revenueField = findFieldIdByPostfix("latest_revenue");
            const financialYearField = findFieldIdByPostfix("latest_year");

            const supplierElasticField = findFieldByGtKey(SUPPLIER_GT);
            const transactionElasticField = findFieldByGtKey(TRANSACTION_VALUE_GT);

            const transactionValueField = transactionElasticField?.field;

            const supplierField = supplierElasticField?.fieldId;
            const supplierFieldType = supplierElasticField?.type;
            const supplierLabelField =
                supplierElasticField && "labelField" in supplierElasticField
                    ? supplierElasticField.labelField
                    : undefined;

            if (!transactionValueField || !supplierField || !ebitMarginField || !revenueField || !financialYearField) {
                const missingFields = getMissingFieldsText(
                    transactionValueField,
                    supplierField,
                    ebitMarginField,
                    revenueField,
                    financialYearField
                );
                return new Error(staticFormatMessage(messages.notAllFieldsFound, { missingFields }));
            }
            return getMatrixQuery(
                transactionIndex,
                transactionValueField,
                ebitMarginField,
                revenueField,
                financialYearField,
                supplierField,
                supplierLabelField,
                supplierFieldType || "text"
            );
        }, [elasticFields, transactionIndex]);
    },
    () => {
        const dataSource = useTransactionsIndex();
        if (dataSource === undefined) return new Error(staticFormatMessage(messages.couldntFindTransactionsIndex));
        return dataSource;
    },
    CHART_CONFIG,
    () => staticFormatMessage(messages.chartHeader),
    {
        useInitialFilters,
        dataHandler: matrixDataHandler,
    },
    true
);
