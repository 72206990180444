import { defineMessages } from "react-intl";

const globalMessages = defineMessages({
    saveButton: {
        id: "general.buttons.save",
        defaultMessage: "Save",
        description: "Used for save buttons",
    },
    updateButton: {
        id: "general.buttons.update",
        defaultMessage: "Update",
        description: "Used for update buttons",
    },
    deleteButton: {
        id: "general.buttons.delete",
        defaultMessage: "Delete",
        description: "Used for delete buttons",
    },
    duplicate: {
        id: "general.buttons.duplicate",
        defaultMessage: "Duplicate",
        description: "Used for duplication-buttons",
    },
    removeButton: {
        id: "general.buttons.remove",
        defaultMessage: "Remove",
        description: "Used for remove buttons",
    },
    backButton: {
        id: "general.buttons.back",
        defaultMessage: "Back",
        description: "Used for back buttons(steps and others)",
    },
    undoButton: {
        id: "general.buttons.undo",
        defaultMessage: "Undo",
        description: "Used for undo buttons",
    },
    continueButton: {
        id: "general.buttons.continue",
        defaultMessage: "Continue",
        description: "Used for continue buttons on steps",
    },
    cancelButton: {
        id: "general.buttons.cancel",
        defaultMessage: "Cancel",
        description: "Used for cancel buttons",
    },
    confirmButton: {
        id: "general.buttons.confirm",
        defaultMessage: "Confirm",
        description: "Used for confirm buttons",
    },
    resetButton: {
        id: "general.buttons.reset",
        defaultMessage: "Reset",
        description: "Used for reset buttons",
    },
    submitButton: {
        id: "general.buttons.submit",
        defaultMessage: "Submit",
    },
    doneButton: {
        id: "general.buttons.done",
        defaultMessage: "Done",
        description: "",
    },
    searchButton: {
        id: "general.buttons.search",
        defaultMessage: "Search",
    },
    loadMoreButton: {
        id: "general.buttons.loadMore",
        defaultMessage: "Load more",
    },
    selectAllButton: {
        id: "general.buttons.selectAll",
        defaultMessage: "Select all",
    },
    supplier: {
        id: "general.labels.supplier",
        defaultMessage: "Supplier",
        description: "Used for headers, field labels etc.",
    },
    spend: {
        id: "general.labels.spend",
        defaultMessage: "Spend",
        description: "Used for headers, field labels etc.",
    },
    spendLTM: {
        id: "general.labels.spendLTM",
        defaultMessage: "Spend last 12 months",
        description: "Used for headers, field labels etc.",
    },
    edit: {
        id: "general.labels.edit",
        defaultMessage: "Edit",
        description: "Used for headers, field labels, tooltips etc.",
    },
    close: {
        id: "general.labels.close",
        defaultMessage: "Close",
        description: "Used for headers, field labels, tooltips etc.",
    },
    delete: {
        id: "general.labels.delete",
        defaultMessage: "Delete",
        description: "Used for headers, field labels, tooltips etc.",
    },
    noDataLabel: {
        id: "general.noData",
        defaultMessage: "No data",
        description: "Used for display that there is no data",
    },
    noItems: {
        id: "general.noItems",
        defaultMessage: "No items",
        description: "Used to display that there is no items",
    },
    addNew: {
        id: "general.buttons.addNew",
        defaultMessage: "Add new",
    },
    add: {
        id: "general.buttons.add",
        defaultMessage: "Add",
    },
    error: {
        id: "general.error",
        defaultMessage: "Error",
    },
    info: {
        id: "general.info",
        defaultMessage: "Info",
    },
    no: {
        id: "general.no",
        defaultMessage: "No",
    },
    yes: {
        id: "general.yes",
        defaultMessage: "Yes",
    },
    or: {
        id: "general.or",
        defaultMessage: "or",
    },
    min: {
        id: "general.min",
        defaultMessage: "Minimum",
    },
    max: {
        id: "general.max",
        defaultMessage: "Maximum",
    },
    name: {
        id: "general.name",
        defaultMessage: "Name",
    },
    number: {
        id: "general.number",
        defaultMessage: "Number",
    },
    date: {
        id: "general.date",
        defaultMessage: "Date",
    },
    text: {
        id: "general.text",
        defaultMessage: "Text",
    },
    email: {
        id: "general.email",
        defaultMessage: "E-mail",
    },
    phone: {
        id: "general.phone",
        defaultMessage: "Phone",
    },
    value: {
        id: "general.value",
        defaultMessage: "Value",
    },
    next: {
        id: "general.next",
        defaultMessage: "Next",
    },
    previous: {
        id: "general.previous",
        defaultMessage: "Previous",
    },
    permissionDenied: {
        id: "general.permissionDenied",
        defaultMessage: "You do not have permission to perform this action. Contact admin user to get access.",
    },
    accessDenied: {
        id: "general.accessDenied",
        defaultMessage: "You do not have access to this part of the platform. Contact admin user to get access.",
    },
    from: {
        id: "general.from",
        defaultMessage: "From",
    },
    to: {
        id: "general.to",
        defaultMessage: "To",
    },
    applyAndSave: {
        id: "general.applyAndSave",
        defaultMessage: "Apply & Save",
    },
    apply: {
        id: "general.apply",
        defaultMessage: "Apply",
    },
    baseline: {
        id: "general.baseline",
        defaultMessage: "Baseline",
        description: "",
    },
    effectiveDate: {
        id: "general.effectiveDate",
        defaultMessage: "Effective date",
    },
    totalSavings: {
        id: "general.totalSavings",
        defaultMessage: "Total savings",
    },
    annualizedSavings: {
        id: "general.annualizedSavings",
        defaultMessage: "Annualized savings",
    },
    notAvailable: {
        id: "general.notAvailable",
        defaultMessage: "Not available",
    },
    searchPlaceholder: {
        id: "general.searchPlaceholder",
        defaultMessage: "Search",
    },
    advancedMode: {
        id: "general.advancedMode",
        defaultMessage: "Advanced mode",
    },
    waitForTask: {
        id: "general.waitForTask",
        defaultMessage: "Please wait until the task `{task}` is finished",
    },
    and: {
        id: "general.and",
        defaultMessage: "and",
        description: "Use to connect items with an and",
    },
    enabled: {
        id: "general.enabled",
        defaultMessage: "Enabled",
        description: "Text to describe something which is active",
    },
    disabled: {
        id: "general.disabled",
        defaultMessage: "Disabled",
        description: "Text to describe something which is inactive",
    },
    empty: {
        id: "general.empty",
        defaultMessage: "Empty",
        description: "Text that says empty",
    },
    fields: {
        id: "general.fields",
        defaultMessage: "Fields",
        description: "Used as header",
    },
    selectUser: {
        id: "general.selectUser",
        defaultMessage: "Select a user",
        description: "Used in user selection",
    },
    selectContract: {
        id: "general.selectContract",
        defaultMessage: "Select a contract",
    },
    loadingLabel: {
        id: "general.loadingLabel",
        defaultMessage: "Loading...",
    },
    with: {
        id: "general.with",
        defaultMessage: "with",
    },
    required: {
        id: "general.required",
        defaultMessage: "This field is required",
    },
    discard: {
        id: "general.discard",
        defaultMessage: "Discard",
    },
    view: {
        id: "general.view",
        defaultMessage: "View",
    },
    remove: {
        id: "general.remove",
        defaultMessage: "Remove",
    },
    newAnalysis: {
        id: "general.newAnalysis",
        defaultMessage: "New Analysis",
    },
    new: {
        id: "general.new",
        defaultMessage: "New",
    },
    rename: {
        id: "general.rename",
        defaultMessage: "Rename",
    },
    share: {
        id: "general.share",
        defaultMessage: "Share",
    },
    saveWidgetErrorMessage: {
        id: "general.saveWidgetErrorMessage",
        defaultMessage: "Something went wrong when trying to save analysis",
    },
    deleteWidgetDialogTitle: {
        id: "general.deleteWidgetDialogTitle",
        defaultMessage: "Delete widget",
    },
    deleteWidgetDialogMessage: {
        id: "general.deleteWidgetDialogMessage",
        defaultMessage: "Are you sure you want to delete this text widget? This action cannot be undone.",
    },
});

export default globalMessages;
