import { defineMessages } from "react-intl";

const messages = defineMessages({
    permissionModalTitle: {
        defaultMessage: "Edit permissions for {name}",
        id: "components.permissionhandler.permissionModalTitle",
    },
    addPermission: {
        defaultMessage: "Add individual permission",
        id: "components.permissionhandler.addPermission",
    },
    headerDefaultPermissions: {
        defaultMessage: "Default permissions",
        id: "components.permissionhandler.headerDefaultPermissions",
    },
    headerIndividualPermissions: {
        defaultMessage: "Individual permissions",
        id: "components.permissionhandler.headerIndividualPermissions",
    },
    owner: {
        defaultMessage: "Owner",
        id: "components.permissionhandler.modal.owner",
    },
    canModify: {
        defaultMessage: "Can edit",
        id: "components.permissionhandler.modal.canModify",
    },
    canView: {
        defaultMessage: "Can view",
        id: "components.permissionhandler.modal.canView",
    },
    permissionHeader: {
        id: "components.permissionhandler.modal.permissionHeader",
        defaultMessage: "Permissions",
    },
    youWillLoseAccess: {
        id: "components.permissionhandler.modal.youWillLoseAccess",
        defaultMessage: "If you save these changes you will no longer be able to edit this dashboard",
    },
    restrictedAccess: {
        id: "components.permissionhandler.modal.restrictedAccess",
        defaultMessage:
            "You do not have permissions to modify permissions for this dashboard. Only administrators have access to edit permissions.",
        description: "tooltip on save button if disabled on permission modal",
    },
    unprotectedDetails: {
        id: "components.permissionhandler.modal.unprotectedDetails",
        defaultMessage: "All users who can view and edit dashboards by default, can view and edit this dashboard.",
    },
    limitedWriteDetails: {
        id: "components.permissionhandler.modal.limitedWriteDetails",
        defaultMessage:
            "Read-only access is given to all users. Individual users with edit permissions can edit this dashboard.",
    },
    individualsOnlyDetails: {
        id: "components.permissionhandler.modal.individualsOnlyDetails",
        defaultMessage: "Hidden from all users except those you specify below.",
    },
    labelStandard: {
        id: "components.permissionhandler.modal.labelStandard",
        defaultMessage: "Standard",
    },
    labelLimited: {
        id: "components.permissionhandler.modal.labelLimited",
        defaultMessage: "Limited",
    },
    labelIndividual: {
        id: "components.permissionhandler.modal.labelIndividual",
        defaultMessage: "Individual",
    },
    propagate: {
        id: "components.permissionhandler.modal.propagate",
        defaultMessage: "Use these settings for {childModels} that belong to this {typeSingular}.",
        description: "",
    },
    loadingDashboardPermissions: {
        id: "components.permissionhandler.modal.loadingDashboardPermissions",
        defaultMessage: "Loading dashboard permissions...",
    },
});
export default messages;
