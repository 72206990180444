import { defineMessages } from "react-intl";

const messages = defineMessages({
    couldntFindGlobalType: {
        id: "analysis.useSpendCategoryElasticField",
        defaultMessage:
            "You don't seem to have a column with the tag `Spend Category` in your Spend data table. To enable this analysis, go to settings and give one of your columns the `Spend category` tag.",
    },
});

export default messages;
