import { Grid, Typography } from "@mui/material";
import React from "react";

interface Props {
    fieldName: string;
    description: string;
    type: string;
    globalTypeSubKey?: string;
    children: JSX.Element;
}

const upperCaseFirstLetter = (str: string) => {
    if (str.length === 0) return "";
    return `${str[0].toUpperCase()}${str.slice(1)}`;
};

/**
 * Helper for providing a pseudo-table structure to the configuration dialog.
 * Using a table has some unfortunate side-effects, including aggresive resizing with helper texts
 * horizontal dividers, etc.
 */
export const ConfigurationRow: React.VFC<Props> = ({
    fieldName: field,
    description,
    type,
    globalTypeSubKey,
    children,
}) => {
    return (
        <Grid container py={1} alignItems="center">
            <Grid item xs={3}>
                <Typography variant="body1">{upperCaseFirstLetter(field)}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1">{upperCaseFirstLetter(description)}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1">{`${upperCaseFirstLetter(type)} ${globalTypeSubKey || ""}`}</Typography>
            </Grid>
            <Grid item xs={4}>
                {children}
            </Grid>
        </Grid>
    );
};
