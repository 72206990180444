import { X as CloseIcon, Trash as DeleteIcon } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "../EditDashboardModal/messages";

import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import {
    CustomDashboardListObject,
    useCustomDashboardContext,
    useDeleteCustomDashboardAction,
} from "@/entities/dashboards";

interface Props {
    open: boolean;
    dashboard: CustomDashboardListObject;
    redirectOnDelete: boolean | undefined;
    onClose: () => void;
    onSubmit: () => void;
}

export const DeleteDashboardsModal: React.FC<Props> = ({ open, dashboard, redirectOnDelete, onClose, onSubmit }) => {
    const navigate = useNavigate();
    const informUser = useInformUser();

    const [loading, setLoading] = useState(false);

    const deleteDashboard = useDeleteCustomDashboardAction(dashboard);
    const { removeObj } = useCustomDashboardContext();

    const handleClose = () => {
        onClose();
    };
    const handleDelete = () => {
        setLoading(true);
        deleteDashboard({ service: "dashboards" })
            .then(() => {
                onSubmit();
                removeObj(dashboard.id);
            })
            .catch(() => {
                informUser({
                    type: "error",
                    message: fm(messages.deleteDashboardError),
                });
            })
            .finally(() => {
                setLoading(false);
                // Wait for the old dashboard to be deleted before navigating
                // This is in case the old dashboard was our home
                if (redirectOnDelete) navigate(`/dashboard/overview/home/`);
            });
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <Stack p={2} direction="row" justifyContent="space-between">
                <Typography pl={1} variant="h6">
                    {fm(messages.areYouSureMessage)}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <DialogContent>
                <Stack alignItems="center">
                    <Typography variant="body1">{fm(messages.doubleCheckDelete)}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" gap={2}>
                    <Button
                        data-testid="deleteDashboardCancelDeleteButton"
                        onClick={handleClose}
                        color="inherit"
                        variant="text"
                        disabled={loading}
                    >
                        {fm(messages.cancel)}
                    </Button>
                    <LoadingButton
                        data-testid="deleteDashboardConfirmDeleteButton"
                        color="error"
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        onClick={handleDelete}
                        loading={loading}
                    >
                        {fm(messages.delete)}
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
