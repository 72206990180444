import { defineMessages } from "react-intl";

const messages = defineMessages({
    unavailableWidgetSource: {
        id: "hoc.widgetWrapper.unavailableWidgetSource",
        defaultMessage:
            "The data source for this analysis is unavailable. It may have been deleted or have been from the old data management system.",
    },
    contactSupportIfError: {
        id: "hoc.widgetWrapper.contactSupportIfError",
        defaultMessage: "Please contact our support if you believe this is an error.",
    },
});

export default messages;
