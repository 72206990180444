import { defineMessages } from "react-intl";

const customAnalysisMessages = defineMessages({
    sum: {
        id: "customAnalysis.components.draggableFieldAggTypesSum",
        defaultMessage: "Sum",
    },
    avg: {
        id: "customAnalysis.components.draggableFieldAggTypesAvg",
        defaultMessage: "Average",
    },
    max: {
        id: "customAnalysis.components.draggableFieldAggTypesMax",
        defaultMessage: "Maximum",
    },
    valueCount: {
        id: "customAnalysis.components.draggableFieldAggTypesValueCount",
        defaultMessage: "Count",
    },
    percentiles: {
        id: "customAnalysis.components.draggableFieldAggTypesPercentiles",
        defaultMessage: "Median",
    },
    min: {
        id: "customAnalysis.components.draggableFieldAggTypesMin",
        defaultMessage: "Minimum",
    },
    cardinality: {
        id: "customAnalysis.components.draggableFieldAggTypesCardinality",
        defaultMessage: "Count of unique values",
    },
    year: {
        id: "customAnalysis.components.draggableFieldIntervalOptionYear",
        defaultMessage: "Year",
    },
    quarter: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionQuarter",
        defaultMessage: "Quarter",
    },
    month: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionMonth",
        defaultMessage: "Month",
    },
    week: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionWeek",
        defaultMessage: "Week",
    },
    day: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionDay",
        defaultMessage: "Day",
    },
    selectInterval: {
        id: "customAnalysis.components.draggableFieldSelectInterval",
        defaultMessage: "Select interval",
    },
    selectUnique: {
        id: "customAnalysis.components.selectUnique",
        defaultMessage: "Select unique",
        description: "Display the value as unique or generalized. I.e 01.02.19 or 02",
    },
    dateInFormulaWarning: {
        id: "customAnalysis.components.dateInFormulaWarning",
        defaultMessage: "Formulas doesn't support operations on date fields",
    },
    createFunctionScript: {
        id: "customAnalysis.components.createFunction",
        defaultMessage: "Create function",
    },
    twoConsecutiveOperatorsMsg: {
        id: "customAnalysis.components.twoConsecutiveOperatorsMsg",
        defaultMessage: "You can't use two operators consecutively",
    },
    twoConsecutiveValuesMsg: {
        id: "customAnalysis.components.twoConsecutiveValuesMsg",
        defaultMessage: "You can't use two values consecutively",
    },
    cantStartOrEndWith: {
        id: "customAnalysis.components.cantStartOrEndWith",
        defaultMessage: "You can't start or end with an operator",
    },
    missingFunctionMsg: {
        id: "customAnalysis.components.missingFunctionMsg",
        defaultMessage: "You must first define the function",
    },

    aggregation: {
        id: "customAnalysis.components.aggregation",
        defaultMessage: "Aggregation",
    },
    add: {
        id: "customAnalysis.components.add",
        defaultMessage: "Add",
        description: "Button text",
    },
    operators: {
        id: "customAnalysis.components.operators",
        defaultMessage: "Operators",
    },
    function: {
        id: "customAnalysis.components.function",
        defaultMessage: "Function",
    },
    measureUnit: {
        id: "customAnalysis.components.measureUnit",
        defaultMessage: "Measure unit",
    },
    constantNumber: {
        id: "customAnalysis.components.constantNumber",
        defaultMessage: "Constant number",
    },
    querySizePH: {
        id: "customAnalysis.querySizePH",
        defaultMessage: "Select size",
    },
    querySizeLabel: {
        id: "customAnalysis.querySizeLabel",
        defaultMessage: "Number of results",
    },
    customRangeLabel: {
        id: "customAnalysis.customRangeLabel",
        defaultMessage: "Select custom size intervals",
    },
    splitSettings: {
        id: "customAnalysis.splitSettings",
        defaultMessage: "{item} settings",
    },
    items: {
        id: "customAnalysis.items",
        defaultMessage: "items",
    },
});

export default customAnalysisMessages;
