import { Api } from "@ignite-analytics/api-client";
import { Filter } from "@ignite-analytics/filters";
import { ChartData, XAxisItem } from "@ignite-analytics/pivot-charts";

import { parseFilterInput } from "../ContractLoyaltyWidget/services";

import {
    AggregationBucket,
    ElasticResponse,
} from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/interfaces";
import { staticFormatMessage } from "@/contexts/IntlContext";
import { getLocaleMonth } from "@/lib/convert";
import globalMessages from "@/lib/messages/globalMessages";

/**
 *  Plugins for Chart
 * ==================================
 *
 * Returnes a list of indices for drawing years in chart
 * @param dates: list of dates
 * @param shift: left (negative) or right(positive) shift of lines
 * @return {Array<number>}
 *
 */
export const getPlotLines = (dates: Date[] | undefined, shift = 0): Highcharts.XAxisPlotLinesOptions[] => {
    if (!dates || dates.length === 0) {
        return [];
    }
    const indices: Highcharts.XAxisPlotLinesOptions[] = [];
    let currYear = dates[0].getFullYear();
    for (let i = 0; i < dates.length; i += 1) {
        const year: number = dates[i].getFullYear();
        if (year !== currYear) {
            indices.push({
                value: i + shift,
                color: "#DEDEDE",
                width: 2,
                label: {
                    style: { color: "#787878" },
                    align: "left",
                    text: year.toString(),
                },
            });
            currYear = year;
        }
    }
    return indices;
};

function spendDevelopmentParser(inputData: ElasticResponse<Record<string, unknown>>): ChartData {
    const rawData: AggregationBucket = inputData.aggregations.valuePerMonth;
    const labels: XAxisItem[] = [];
    const dataValues: number[] = [];
    const dates: Date[] = [];

    rawData.buckets.splice(0, 12);
    rawData.buckets.forEach((month: AggregationBucket) => {
        // Get the labels for the data
        const date: Date = new Date(month.key);
        date.setMonth(date.getMonth() - 1);
        const shortMonth: string = getLocaleMonth(date);

        if (month.rollingSpend || month.movavgVal) {
            const value: number = month.rollingSpend ? month.rollingSpend.value : month.movavgVal.value * 12;

            dates.push(date);
            labels.push({ name: shortMonth });
            dataValues.push(value);
        }
    });

    return {
        xAxisItems: labels,
        dataSeries: [
            {
                name: staticFormatMessage(globalMessages.spend),
                data: dataValues,
                type: "line",
                plotLineDates: dates,
                id: "spend",
            },
        ],
    };
}

/**
 * Retrieves the spend per month for the last 12 months
 */
export function getSpendDevelopment(
    filterArgs: Filter[],
    maxRelativeDate: string | undefined,
    cancelKey: string
): Promise<ChartData> {
    const filters: Filter[] = [...filterArgs];
    const paramString: string = parseFilterInput(filters);
    const url = `/analysis/kpi-moving-average/${paramString}${
        maxRelativeDate ? `&max_relative_date=${maxRelativeDate}` : ""
    }`;
    return Api.get(url, { cancelKey, service: "dashboards" }).then(spendDevelopmentParser);
}
