/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type AccessRequest = {
  __typename: 'AccessRequest';
  approvedAt?: Maybe<Scalars['String']['output']>;
  consentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  durationHours?: Maybe<Scalars['Int']['output']>;
  grantedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  requireApproval: Scalars['Boolean']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  tenant: Scalars['String']['output'];
  user: User;
};

export type ActiveContractsSpendResult = {
  __typename: 'ActiveContractsSpendResult';
  count: Scalars['Int']['output'];
};

export type ActivitiesResponse = {
  __typename: 'ActivitiesResponse';
  activities: Array<Activity>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Activity = {
  __typename: 'Activity';
  activityL1?: Maybe<Scalars['String']['output']>;
  activityL2?: Maybe<Scalars['String']['output']>;
  businessUnitId?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  emissions: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  spendCategoryId?: Maybe<Scalars['String']['output']>;
  supplierId?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type ActivityResponse = {
  __typename: 'ActivityResponse';
  activities?: Maybe<Array<Activity>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AddAccessRequestInput = {
  durationHours?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  requireApproval: Scalars['Boolean']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  tenant: Scalars['String']['input'];
  user: UserIdentifier;
};

export type AddAccessRequestResponse = {
  __typename: 'AddAccessRequestResponse';
  eligible: Scalars['Boolean']['output'];
  request: AccessRequest;
};

export type AddActivityType =
  | 'ACTIVITY_LAST_YEAR'
  | 'MISSING_ACTIVITY'
  | 'MISSING_ELECTRICITY_ACTIVITY';

export type AddDataPipelineSourceInput = {
  dataPipelineId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
};

export type AddDataPipelineSourceResponse = {
  __typename: 'AddDataPipelineSourceResponse';
  dataPipeline: DataPipeline;
};

export type AddDataRepositoryFieldInput = {
  dataRepositoryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddDataRepositoryFieldResponse = {
  __typename: 'AddDataRepositoryFieldResponse';
  dataRepository: DataRepository;
};

export type AddDataTableConnectionCriterionInput = {
  dataTableConnectionId: Scalars['String']['input'];
  ignoreOnBlankTarget?: InputMaybe<Scalars['Boolean']['input']>;
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type AddDataTableConnectionCriterionResponse = {
  __typename: 'AddDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type AddEmissionActivitySuggestion = ISuggestion & {
  __typename: 'AddEmissionActivitySuggestion';
  addActivityType: AddActivityType;
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  l1Category?: Maybe<Scalars['String']['output']>;
  l2Category?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  status: SuggestionStatus;
  supplierId?: Maybe<Supplier>;
  type: SuggestionType;
  unitOfMeasurement?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type AddImportFieldMappingInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type AddImportFieldMappingResponse = {
  __typename: 'AddImportFieldMappingResponse';
  updatedImportConfiguration: ImportConfiguration;
};

export type AddLookupOperationCriteriaInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type AddLookupOperationCriteriaResponse = {
  __typename: 'AddLookupOperationCriteriaResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type AddLookupOperationFieldOutputsInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type AddLookupOperationFieldOutputsResponse = {
  __typename: 'AddLookupOperationFieldOutputsResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type AddManyDataRepositoryFieldsInput = {
  dataRepositoryId: Scalars['String']['input'];
  fieldNames: Array<Scalars['String']['input']>;
};

export type AddManyDataRepositoryFieldsResponse = {
  __typename: 'AddManyDataRepositoryFieldsResponse';
  dataRepository: DataRepository;
};

export type AddManyImportFieldMappingsInput = {
  fieldMappings: Array<ImportFieldMappingInput>;
  importConfigurationId: Scalars['String']['input'];
};

export type AddManyImportFieldMappingsResponse = {
  __typename: 'AddManyImportFieldMappingsResponse';
  updatedImportConfiguration: ImportConfiguration;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationInput = {
  addOrRemove: AddOrRemoveInput;
  dataTableId: Scalars['String']['input'];
  fields: Array<Scalars['String']['input']>;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationResponse = {
  __typename: 'AddOrRemoveFieldsFromDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type AddOrRemoveInput =
  | 'ADD'
  | 'REMOVE';

export type AddSelectColumnChoiceInput = {
  choice: Scalars['String']['input'];
  columnId: Scalars['String']['input'];
};

export type AddSelectColumnChoiceResponse = {
  __typename: 'AddSelectColumnChoiceResponse';
  choices: Array<Scalars['String']['output']>;
};

export type AddSuppliersToExternalCampaignInput = {
  campaignId: Scalars['String']['input'];
  supplierIds: Array<Scalars['String']['input']>;
};

export type AddSuppliersToExternalCampaignResponse = {
  __typename: 'AddSuppliersToExternalCampaignResponse';
  assessments: Array<Assessment>;
};

export type AddTagValueToColumnInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type AddTagValueToColumnResponse = {
  __typename: 'AddTagValueToColumnResponse';
  tag: TagConfiguration;
};

export type AffectedSupplier = {
  __typename: 'AffectedSupplier';
  id: Scalars['ID']['output'];
  mergeRuleId: Scalars['Int']['output'];
  mergeType: MergeType;
  mergedBy: Scalars['String']['output'];
  mergedOn: Scalars['String']['output'];
  normalizedID: Scalars['String']['output'];
  normalizedName: Scalars['String']['output'];
  originalID: Scalars['String']['output'];
  originalName: Scalars['String']['output'];
};

export type AggregatedView = {
  __typename: 'AggregatedView';
  aggregationConfigurations: Array<AggregationConfiguration>;
  filters: Array<AnalysisFilter>;
  groupingConfigurations: Array<GroupingConfiguration>;
  id: Scalars['ID']['output'];
  resultDataTableId: Scalars['String']['output'];
  sourceDataTableId: Scalars['String']['output'];
};

export type AggregationConfiguration = {
  __typename: 'AggregationConfiguration';
  field: Scalars['String']['output'];
  filters: Array<AnalysisFilter>;
  id: Scalars['ID']['output'];
  type: AggregationType;
};

export type AggregationType =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AggregationTypeInput =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AnalysisFilter = {
  __typename: 'AnalysisFilter';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  exclude: Array<Scalars['String']['output']>;
  excludeBlanks?: Maybe<Scalars['Boolean']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  field: Scalars['String']['output'];
  filterType: Scalars['String']['output'];
  include: Array<Scalars['String']['output']>;
  includeBlanks?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  periodLength?: Maybe<Scalars['Int']['output']>;
  periodUnit?: Maybe<Scalars['String']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type AnalysisFilterInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  exclude: Array<Scalars['String']['input']>;
  excludeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  include: Array<Scalars['String']['input']>;
  includeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  periodLength?: InputMaybe<Scalars['Int']['input']>;
  periodUnit?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type Answer = {
  __typename: 'Answer';
  assessmentId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  fileNames: Array<Scalars['String']['output']>;
  /** @deprecated Use fileNames instead for new file handling */
  files?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  migrated?: Maybe<Scalars['Boolean']['output']>;
  question: Question;
  questionId: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  value: Array<Scalars['String']['output']>;
};

export type ApplyChangesInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type ApplyChangesResponse = {
  __typename: 'ApplyChangesResponse';
  notificationIds: Array<Scalars['String']['output']>;
};

export type ApplyDataTableConnectionChangesInput = {
  changedConnectionColumns: Array<Scalars['String']['input']>;
  columnsWithChangedCubeConfiguration: Array<Scalars['String']['input']>;
  createdConnectionColumns: Array<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesResponse = {
  __typename: 'ApplyDataTableConnectionChangesResponse';
  notificationId: Scalars['String']['output'];
};

export type ApproveSupplierInput = {
  approved: Scalars['Boolean']['input'];
  supplierID: Scalars['String']['input'];
};

export type ApproveSupplierResponse = {
  __typename: 'ApproveSupplierResponse';
  ok: Scalars['Boolean']['output'];
};

export type Assessment = {
  __typename: 'Assessment';
  answers: Array<Answer>;
  campaign: Campaign;
  campaignId: Scalars['String']['output'];
  contactId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  receiverEmail?: Maybe<Scalars['String']['output']>;
  score: Scalars['Int']['output'];
  sentAt?: Maybe<Scalars['String']['output']>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Supplier>;
  supplierId: Scalars['String']['output'];
  type: AssessmentType;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AssessmentInput = {
  campaignId: Scalars['String']['input'];
  contactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  receiverEmail?: InputMaybe<Scalars['String']['input']>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  submittedAt?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['String']['input'];
  type: AssessmentTypeInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type AssessmentType =
  | 'external'
  | 'internal';

export type AssessmentTypeInput =
  | 'external'
  | 'internal';

export type AssessmentsFile = {
  __typename: 'AssessmentsFile';
  createdAt: Scalars['String']['output'];
  metaJson?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  referenceId: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type AssessmentsFollowUpSuggestion = ISuggestion & {
  __typename: 'AssessmentsFollowUpSuggestion';
  campaign: Campaign;
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  type: SuggestionType;
};

export type AsyncDataStatus =
  | 'FINISHED'
  | 'IN_PROGRESS';

export type AuditorComment = {
  __typename: 'AuditorComment';
  auditorComment?: Maybe<Scalars['String']['output']>;
  commentType?: Maybe<AuditorCommentType>;
  rating?: Maybe<Scalars['Float']['output']>;
};

export type AuditorCommentType =
  | 'CLARIFICATION'
  | 'DISCLAIMER'
  | 'OTHER';

export type AutofillGroupStructureFieldInputInput = {
  fieldKey: Scalars['String']['input'];
};

export type AutomaticRule = {
  __typename: 'AutomaticRule';
  createdBy: Scalars['String']['output'];
  createdOn: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mergeSelection: MergeSelection;
  name: Scalars['String']['output'];
  normalizationQuery: NormalizationQuery;
  precedence: Scalars['Int']['output'];
  type: RuleType;
  unMergedSuppliers: Array<Scalars['String']['output']>;
};

export type AutomaticRuleInputInput = {
  createdBy: Scalars['String']['input'];
  mergeSelection: MergeSelectionInput;
  name: Scalars['String']['input'];
  normalizationQuery: NormalizationQueryInput;
  type: RuleTypeInput;
};

export type BusinessUnit = {
  __typename: 'BusinessUnit';
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Campaign = {
  __typename: 'Campaign';
  assessments: Array<Assessment>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initiatorId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questionnaire: Questionnaire;
  questionnaireId: Scalars['String']['output'];
  remindedAt?: Maybe<Scalars['String']['output']>;
  subjectLine?: Maybe<Scalars['String']['output']>;
  type: CampaignType;
};


export type CampaignAssessmentsArgs = {
  excludeUnsubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  onlySubmitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  name: Scalars['String']['input'];
  questionnaireId: Scalars['String']['input'];
  subjectLine?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CampaignType>;
};

export type CampaignType =
  | 'external'
  | 'internal';

export type CardinalityObject = {
  __typename: 'CardinalityObject';
  cardinality: Scalars['Int']['output'];
  field: Scalars['String']['output'];
};

export type Category = {
  __typename: 'Category';
  categoryType?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type CategoryComparison = {
  __typename: 'CategoryComparison';
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  totalSpend: TotalSpendComparison;
};

export type CategoryResult = {
  __typename: 'CategoryResult';
  contractCoverage: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  spend: Scalars['Float']['output'];
  spendChange?: Maybe<Scalars['Float']['output']>;
  supplierCount: Scalars['Int']['output'];
  supplierCountChange?: Maybe<Scalars['Float']['output']>;
};

export type ChangeLogRule = {
  __typename: 'ChangeLogRule';
  ruleId: Scalars['Int']['output'];
  supplierIdFrom: Scalars['String']['output'];
  supplierIdTo: Scalars['String']['output'];
  transferData: Scalars['Boolean']['output'];
};

export type ChangeOperationTypeInput =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type CheckNewDataColumnRulesInput = {
  rules: Array<CheckRulePayloadInput>;
  targetDataColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type CheckNewDataColumnRulesResponse = {
  __typename: 'CheckNewDataColumnRulesResponse';
  match: Array<DataColumnRule>;
};

export type CheckRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  value: Scalars['String']['input'];
};

export type CheckSpendColumnAvailabilityResponse = {
  __typename: 'CheckSpendColumnAvailabilityResponse';
  hasConnectedSpend?: Maybe<Scalars['Boolean']['output']>;
  hasDateColumn?: Maybe<Scalars['Boolean']['output']>;
};

export type ClassificationDataColumn = {
  __typename: 'ClassificationDataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type ClassificationFieldSamples = {
  __typename: 'ClassificationFieldSamples';
  fieldKey: Scalars['String']['output'];
  samples: Array<Scalars['String']['output']>;
};

export type ClassificationGroup = {
  __typename: 'ClassificationGroup';
  id: Scalars['String']['output'];
  level: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ClassificationModeInput =
  | 'AFTER_EXISTING_RULES'
  | 'BEFORE_EXISTING_RULES';

export type ClassificationOptions = {
  __typename: 'ClassificationOptions';
  groups?: Maybe<Array<ClassificationGroup>>;
};

export type Clause =
  | 'and'
  | 'or';

export type ClauseInput =
  | 'and'
  | 'or';

export type Column = SelectColumn | TableColumn;

export type ColumnType =
  | 'AGGREGATION'
  | 'ASSESSMENT_SCORE'
  | 'ASSESSMENT_STATUS'
  | 'BOOLEAN'
  | 'CLASSIFICATION'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NACE'
  | 'NUMBER'
  | 'RISK'
  | 'SELECT'
  | 'SPEND'
  | 'TABLE_RELATION'
  | 'TEXT'
  | 'USER';

export type Company = {
  __typename: 'Company';
  bankrupt?: Maybe<Scalars['Boolean']['output']>;
  country: Scalars['String']['output'];
  domesticCompany?: Maybe<HierarchyCompany>;
  enrichment?: Maybe<Array<EnrichmentType>>;
  financialStatements?: Maybe<Array<Maybe<FinancialStatement>>>;
  globalCompany?: Maybe<HierarchyCompany>;
  id: Scalars['ID']['output'];
  internationalCategory?: Maybe<Category>;
  latestFinancialStatement?: Maybe<FinancialStatement>;
  localCategory?: Maybe<Category>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberOfEmployees?: Maybe<Scalars['String']['output']>;
  orgNumber: Scalars['String']['output'];
  parentCompany?: Maybe<HierarchyCompany>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postLocation?: Maybe<Scalars['String']['output']>;
  previousFinancialSync?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompanyFavouriteFilters = {
  __typename: 'CompanyFavouriteFilters';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  fields: Array<FavouriteField>;
  id: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};

export type Comparison =
  | 'equal'
  | 'hasValue'
  | 'in'
  | 'isNotSame'
  | 'isSame'
  | 'notEqual'
  | 'notHaveValue'
  | 'notIn';

export type ComparisonInput =
  | 'equal'
  | 'hasValue'
  | 'in'
  | 'isNotSame'
  | 'isSame'
  | 'notEqual'
  | 'notHaveValue'
  | 'notIn';

export type CompleteSetupInput = {
  fields: Array<FileFieldInput>;
  fileSettings: FileSettingsInput;
  tableName?: InputMaybe<Scalars['String']['input']>;
  tableType?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteSetupResponse = {
  __typename: 'CompleteSetupResponse';
  dataTableId: Scalars['String']['output'];
  notificationId: Scalars['String']['output'];
};

export type CompressionType =
  | 'GZIP';

export type CompressionTypeInput =
  | 'GZIP';

export type ConflictingRule = {
  __typename: 'ConflictingRule';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  oldGroupPath: Array<Scalars['String']['output']>;
};

export type ConflictingRuleFromFile = {
  __typename: 'ConflictingRuleFromFile';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
};

export type ConnectedFieldAndColumn = {
  __typename: 'ConnectedFieldAndColumn';
  dataColumnId: Scalars['String']['output'];
  dataColumnName: Scalars['String']['output'];
  fieldId: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  globalType?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename: 'Contact';
  companyId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone: Phone;
  position?: Maybe<Scalars['String']['output']>;
};

export type ContactFieldsInput =
  | 'companyId'
  | 'description'
  | 'email'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'phone'
  | 'position';

export type ContactInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone: PhoneInput;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type ContactTermsInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ContentType =
  | 'CSV'
  | 'JSON'
  | 'NJSON'
  | 'XLSX';

export type ContentTypeInput =
  | 'CSV'
  | 'JSON'
  | 'NJSON'
  | 'XLSX';

export type Contract = {
  __typename: 'Contract';
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrivate: Scalars['Boolean']['output'];
  renewalDate?: Maybe<Scalars['String']['output']>;
  responsibles: Array<Maybe<RoleUser>>;
  sourcingLink?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Supplier>;
  title: Scalars['String']['output'];
  totalSpend?: Maybe<Scalars['Float']['output']>;
};

export type ContractCoverageDevelopmentResponse = {
  __typename: 'ContractCoverageDevelopmentResponse';
  results: Array<ContractCoverageDevelopmentResult>;
};

export type ContractCoverageDevelopmentResult = {
  __typename: 'ContractCoverageDevelopmentResult';
  contractedSpend: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  uncontractedSpend: Scalars['Float']['output'];
};

export type ContractExpiringSoonSuggestion = ISuggestion & {
  __typename: 'ContractExpiringSoonSuggestion';
  contract: Contract;
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  type: SuggestionType;
};

export type ContractMissingResponsibleSuggestion = ISuggestion & {
  __typename: 'ContractMissingResponsibleSuggestion';
  contract: Contract;
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  type: SuggestionType;
};

export type ContractStatistics = {
  __typename: 'ContractStatistics';
  active: SpendAndCount;
  expiring: SpendAndCount;
  renewing: SpendAndCount;
};

export type ContributorType =
  | 'COMPANY_STRUCTURE'
  | 'SPEND_CATEGORY'
  | 'SUPPLIERS';

export type CreateAggregatedDataColumnInput = {
  aggregationField: Scalars['String']['input'];
  aggregationType: AggregationTypeInput;
  dataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sourceDataTable: Scalars['String']['input'];
};

export type CreateAggregatedDataColumnResponse = {
  __typename: 'CreateAggregatedDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateAggregatedViewInput = {
  aggregations: Array<CreateAggregationInput>;
  baseDataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAggregatedViewResponse = {
  __typename: 'CreateAggregatedViewResponse';
  entity: AggregatedView;
};

export type CreateAggregationInput = {
  field: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  name: Scalars['String']['input'];
  type: AggregationTypeInput;
};

export type CreateAssessmentColumnsInput = {
  campaignID?: InputMaybe<Scalars['String']['input']>;
  referenceID?: InputMaybe<Scalars['String']['input']>;
  viewId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssessmentColumnsResponse = {
  __typename: 'CreateAssessmentColumnsResponse';
  columns: Array<TableColumn>;
};

export type CreateAssessmentsDownloadUrlInput = {
  fileName: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
};

export type CreateAssessmentsDownloadUrlResponse = {
  __typename: 'CreateAssessmentsDownloadURLResponse';
  url: Scalars['String']['output'];
};

export type CreateAssessmentsUploadUrlInput = {
  fileName: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  referenceId: Scalars['String']['input'];
};

export type CreateAssessmentsUploadUrlResponse = {
  __typename: 'CreateAssessmentsUploadURLResponse';
  uniqueFileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CreateCompanySettingsInput = {
  companyColor: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgNumber: Scalars['Int']['input'];
};

export type CreateCompanySettingsResponse = {
  __typename: 'CreateCompanySettingsResponse';
  companyColor: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNumber: Scalars['Int']['output'];
};

export type CreateContactsFromColumnsInput = {
  emailColumnId: Scalars['String']['input'];
  firstNameColumnId?: InputMaybe<Scalars['String']['input']>;
  lastNameColumnId?: InputMaybe<Scalars['String']['input']>;
  phoneNumberColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactsFromColumnsResponse = {
  __typename: 'CreateContactsFromColumnsResponse';
  ok: Scalars['Boolean']['output'];
};

export type CreateConvertCountryDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateConvertCountryDataPipelineOperationResponse = {
  __typename: 'CreateConvertCountryDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateCustomerInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscription?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerResponse = {
  __typename: 'CreateCustomerResponse';
  result: Customer;
};

export type CreateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType: DataColumnTypeInput;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnPayloadInput = {
  dataType: DataColumnTypeInput;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnResponse = {
  __typename: 'CreateDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateDataColumnRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  groupName: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureInput = {
  classificationMode: ClassificationModeInput;
  fieldsWithLevel: Array<FieldWithLevelInput>;
  groupStructureId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureResponse = {
  __typename: 'CreateDataColumnRuleSetFromGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateDataColumnsInput = {
  dataColumns: Array<CreateDataColumnPayloadInput>;
  dataTableId: Scalars['String']['input'];
};

export type CreateDataColumnsResponse = {
  __typename: 'CreateDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type CreateDataPipelineInput = {
  name: Scalars['String']['input'];
  sourceDataRepositoryIds: Array<Scalars['String']['input']>;
  targetDataTableId: Scalars['String']['input'];
};

export type CreateDataPipelineResponse = {
  __typename: 'CreateDataPipelineResponse';
  dataPipeline: DataPipeline;
};

export type CreateDataRepositoryCollectionInput = {
  name: Scalars['String']['input'];
};

export type CreateDataRepositoryCollectionResponse = {
  __typename: 'CreateDataRepositoryCollectionResponse';
  dataRepositoryCollection: DataRepositoryCollection;
};

export type CreateDataRepositoryInput = {
  name: Scalars['String']['input'];
  repositoryCollectionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRepositoryResponse = {
  __typename: 'CreateDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type CreateDataRowInput = {
  dataJson: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRowResponse = {
  __typename: 'CreateDataRowResponse';
  dataTableRow: DataTableRow;
};

export type CreateDataTableCollectionInput = {
  DataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDataTableCollectionResponse = {
  __typename: 'CreateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type CreateDataTableConnectionCriterionInputInput = {
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type CreateDataTableConnectionInput = {
  criteria: Array<CreateDataTableConnectionCriterionInputInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  targetDataSourceIdentifier: Scalars['String']['input'];
  targetDataSourceType: DataTableConnectionSourceTypeInput;
};

export type CreateDataTableConnectionResponse = {
  __typename: 'CreateDataTableConnectionResponse';
  dataTableConnection: DataTableConnection;
};

export type CreateDataTableInput = {
  name: Scalars['String']['input'];
};

export type CreateDataTableResponse = {
  __typename: 'CreateDataTableResponse';
  entity: DataTable;
};

export type CreateDateParserDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  dateFormat: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateDateParserDataPipelineOperationResponse = {
  __typename: 'CreateDateParserDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateDownloadUrlsInput = {
  fileIds: Array<Scalars['String']['input']>;
};

export type CreateDownloadUrlsResponse = {
  __typename: 'CreateDownloadUrlsResponse';
  urls: Array<FileUrl>;
};

export type CreateEmptyCampaignInput = {
  campaign: CampaignInput;
};

export type CreateEmptyCampaignResponse = {
  __typename: 'CreateEmptyCampaignResponse';
  campaign: Campaign;
};

export type CreateExternalCampaignInput = {
  campaign: CampaignInput;
  supplierIds: Array<Scalars['String']['input']>;
};

export type CreateExternalCampaignResponse = {
  __typename: 'CreateExternalCampaignResponse';
  campaign: Campaign;
};

export type CreateFillBlanksDataPipelineOperationInput = {
  blankValue?: InputMaybe<Scalars['String']['input']>;
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  withColumn: Scalars['Boolean']['input'];
};

export type CreateFillBlanksDataPipelineOperationResponse = {
  __typename: 'CreateFillBlanksDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateFilterCombinationInput = {
  filterCombination: FilterCombinationInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFilterCombinationResponse = {
  __typename: 'CreateFilterCombinationResponse';
  filterCombination: FilterCombination;
};

export type CreateFilterFieldMappingInput = {
  filterFieldMapping: FilterFieldMappingInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFilterFieldMappingResponse = {
  __typename: 'CreateFilterFieldMappingResponse';
  filterFieldMapping: FilterFieldMapping;
};

export type CreateGetOrCreateDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  getOrCreateCriteria: Array<GetOrCreateCriterionInput>;
  name: Scalars['String']['input'];
  targetDataRepositoryId: Scalars['String']['input'];
  upsert: Scalars['Boolean']['input'];
};

export type CreateGetOrCreateDataPipelineOperationResponse = {
  __typename: 'CreateGetOrCreateDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupResponse = {
  __typename: 'CreateGroupResponse';
  group: Group;
};

export type CreateGroupStructureFromUploadFileInput = {
  dataTableId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  groupStructureName: Scalars['String']['input'];
};

export type CreateGroupStructureFromUploadFileResponse = {
  __typename: 'CreateGroupStructureFromUploadFileResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  externallyManaged?: InputMaybe<Scalars['Boolean']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type CreateGroupStructureResponse = {
  __typename: 'CreateGroupStructureResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateImportConfigurationInput = {
  compressionType?: InputMaybe<CompressionTypeInput>;
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  importType: ImportTypeInput;
  name: Scalars['String']['input'];
  targetDataRepository: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type CreateImportConfigurationResponse = {
  __typename: 'CreateImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
  integrationToken?: Maybe<Scalars['String']['output']>;
};

export type CreateInputOutputConnectionInput = {
  dataPipelineId: Scalars['String']['input'];
  from: InputOutputNodeInput;
  to: InputOutputNodeInput;
};

export type CreateInputOutputConnectionResponse = {
  __typename: 'CreateInputOutputConnectionResponse';
  inputOutputConnection: InputOutputConnection;
};

export type CreateIntegrationInput = {
  integrationToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateIntegrationResponse = {
  __typename: 'CreateIntegrationResponse';
  id: Scalars['String']['output'];
};

export type CreateInternalCampaignInput = {
  campaign: CampaignInput;
  invitees: Array<InternalInviteeInput>;
  supplierIds: Array<Scalars['String']['input']>;
};

export type CreateInternalCampaignResponse = {
  __typename: 'CreateInternalCampaignResponse';
  campaign: Campaign;
};

export type CreateKeywordTaggerDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateKeywordTaggerDataPipelineOperationResponse = {
  __typename: 'CreateKeywordTaggerDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLetterCasingDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  letterCasing: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateLetterCasingDataPipelineOperationResponse = {
  __typename: 'CreateLetterCasingDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateListParserDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  splitString: Scalars['String']['input'];
};

export type CreateListParserDataPipelineOperationResponse = {
  __typename: 'CreateListParserDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLookupCriterionInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type CreateLookupDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  lookupCriteria: Array<CreateLookupCriterionInput>;
  lookupFieldOutputs: Array<CreateLookupFieldOutputInput>;
  name: Scalars['String']['input'];
  targetDataRepositoryId: Scalars['String']['input'];
};

export type CreateLookupDataPipelineOperationResponse = {
  __typename: 'CreateLookupDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLookupFieldOutputInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type CreateManyInputOutputConnectionsInput = {
  connections: Array<InputOutputConnectionCreationInputInput>;
  dataPipelineId: Scalars['String']['input'];
};

export type CreateManyInputOutputConnectionsResponse = {
  __typename: 'CreateManyInputOutputConnectionsResponse';
  inputOutputConnections: Array<InputOutputConnection>;
};

export type CreateMappingDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMappingDataPipelineOperationResponse = {
  __typename: 'CreateMappingDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMatchCompanyDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMatchCompanyDataPipelineOperationResponse = {
  __typename: 'CreateMatchCompanyDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMathematicalExpressionDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  expressionJson: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMathematicalExpressionDataPipelineOperationResponse = {
  __typename: 'CreateMathematicalExpressionDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMonetaryAmountDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMonetaryAmountDataPipelineOperationResponse = {
  __typename: 'CreateMonetaryAmountDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreatePipelineFilterCombinationInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type CreatePipelineFilterCombinationResponse = {
  __typename: 'CreatePipelineFilterCombinationResponse';
  dataPipeline: DataPipeline;
};

export type CreatePipelineFilterInput = {
  filterCombinationId: Scalars['String']['input'];
  filterDataType: FilterDataTypeInput;
  operator: FilterOperatorTypeInput;
  sourceId: Scalars['String']['input'];
  sourceType: InputOutputNodeTypeInput;
  value: Scalars['String']['input'];
};

export type CreatePipelineFilterResponse = {
  __typename: 'CreatePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type CreateQuestionInput = {
  attachedFileNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** @deprecated Use attachedFileNames instead for new file handling */
  attachedFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileUpload: FileUpload;
  maxChars?: InputMaybe<Scalars['Int']['input']>;
  maxNum?: InputMaybe<Scalars['Int']['input']>;
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  minNum?: InputMaybe<Scalars['Int']['input']>;
  options: Array<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  sectionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: QuestionType;
};

export type CreateQuestionResponse = {
  __typename: 'CreateQuestionResponse';
  created?: Maybe<Scalars['Boolean']['output']>;
  question: Question;
};

export type CreateQuestionnaireInput = {
  default: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateQuestionnaireResponse = {
  __typename: 'CreateQuestionnaireResponse';
  questionnaire: Questionnaire;
};

export type CreateRegexDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regex: Scalars['String']['input'];
};

export type CreateRegexDataPipelineOperationResponse = {
  __typename: 'CreateRegexDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateReplaceDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  isRegex: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  replaceString: Scalars['String']['input'];
  withString: Scalars['String']['input'];
};

export type CreateReplaceDataPipelineOperationResponse = {
  __typename: 'CreateReplaceDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateRepositoryRowDeleteQueryInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  numberOfPeriods?: InputMaybe<Scalars['Int']['input']>;
  periodGranularity?: InputMaybe<PeriodGranularityInput>;
  queryType: QueryTypeInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRepositoryRowDeleteQueryResponse = {
  __typename: 'CreateRepositoryRowDeleteQueryResponse';
  repositoryRowDeleteQuery: RepositoryRowDeleteQuery;
};

export type CreateRulesInput = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesResponse = {
  __typename: 'CreateRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateRulesV2Input = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesV2Response = {
  __typename: 'CreateRulesV2Response';
  insertedIds: Array<IdKeyPair>;
  notificationId: Scalars['String']['output'];
};

export type CreateSectionInput = {
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  questionnaireId: Scalars['String']['input'];
};

export type CreateSectionResponse = {
  __typename: 'CreateSectionResponse';
  section: Section;
};

export type CreateSpendColumnInput = {
  displayName: Scalars['String']['input'];
  variant?: InputMaybe<SpendColumnVariant>;
  viewId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSpendColumnResponse = {
  __typename: 'CreateSpendColumnResponse';
  column: TableColumn;
};

export type CreateSplitDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  splitString: Scalars['String']['input'];
};

export type CreateSplitDataPipelineOperationResponse = {
  __typename: 'CreateSplitDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateSupplierFileDownloadUrlInput = {
  fileName: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type CreateSupplierFileDownloadUrlResponse = {
  __typename: 'CreateSupplierFileDownloadUrlResponse';
  url: Scalars['String']['output'];
};

export type CreateSupplierFileUploadUrlInput = {
  fileMetaJson?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type CreateSupplierFileUploadUrlResponse = {
  __typename: 'CreateSupplierFileUploadUrlResponse';
  url: Scalars['String']['output'];
};

export type CreateSupplierInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  customFields: Array<SupplierCustomFieldInput>;
  name: Scalars['String']['input'];
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  orgNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSupplierNoteInput = {
  note: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type CreateSupplierNoteResponse = {
  __typename: 'CreateSupplierNoteResponse';
  note: Note;
};

export type CreateSupplierResponse = {
  __typename: 'CreateSupplierResponse';
  supplier: Supplier;
};

export type CreateSupplierTableColumnInput = {
  choices?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  type: ColumnType;
  viewId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSupplierTableColumnResponse = {
  __typename: 'CreateSupplierTableColumnResponse';
  supplierTableColumn: SupplierTableColumn;
};

export type CreateSupplierTableConfigInput = {
  displayName: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateSupplierTableConfigResponse = {
  __typename: 'CreateSupplierTableConfigResponse';
  supplierTableConfig: SupplierTableConfig;
};

export type CreateTagColumnInput = {
  dataTableId: Scalars['String']['input'];
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  tagStringValues: Array<Scalars['String']['input']>;
};

export type CreateTagColumnResponse = {
  __typename: 'CreateTagColumnResponse';
  columnId: Scalars['String']['output'];
  tag: TagConfiguration;
};

export type CreateTaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatus>;
  supplierID: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTaskResponse = {
  __typename: 'CreateTaskResponse';
  task: Task;
};

export type CreateTemplateStringDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  templateString: Scalars['String']['input'];
};

export type CreateTemplateStringDataPipelineOperationResponse = {
  __typename: 'CreateTemplateStringDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateTranslationDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateTranslationDataPipelineOperationResponse = {
  __typename: 'CreateTranslationDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateUniqueIdentifierConfigurationInput = {
  partialReplace: Scalars['Boolean']['input'];
  repositoryId: Scalars['String']['input'];
  retroactive: Scalars['Boolean']['input'];
  uniqueIdentifierFields: Array<Scalars['String']['input']>;
};

export type CreateUniqueIdentifierConfigurationResponse = {
  __typename: 'CreateUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type CreateUploadUrlsInput = {
  files: Array<FileDetailsInput>;
  serviceId: Scalars['String']['input'];
};

export type CreateUploadUrlsResponse = {
  __typename: 'CreateUploadUrlsResponse';
  urls: Array<FileUrl>;
};

export type CriterionType =
  | 'MATCH'
  | 'ON_CREATE';

export type CriterionTypeInput =
  | 'MATCH'
  | 'ON_CREATE';

export type CsvConfiguration = {
  __typename: 'CsvConfiguration';
  delimiter?: Maybe<DelimiterType>;
  encoding?: Maybe<EncodingType>;
  escapeCharacter?: Maybe<EscapeCharacterType>;
  quoteCharacter?: Maybe<QuoteCharacterType>;
};

export type CsvConfigurationInput = {
  delimiter?: InputMaybe<DelimiterTypeInput>;
  encoding?: InputMaybe<EncodingTypeInput>;
  escapeCharacter?: InputMaybe<EscapeCharacterTypeInput>;
  quoteCharacter?: InputMaybe<QuoteCharacterTypeInput>;
};

export type CustomField = {
  __typename: 'CustomField';
  data?: Maybe<Scalars['String']['output']>;
  dataType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Customer = {
  __typename: 'Customer';
  currency: Scalars['String']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  initializing?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireSSOLogin?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerInput = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  initializing?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requireSSOLogin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DataColumn = {
  __typename: 'DataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type DataColumnElasticFieldMap = {
  __typename: 'DataColumnElasticFieldMap';
  dataColumn: DataColumn;
  fields: Array<Scalars['String']['output']>;
};

export type DataColumnGroupStructure = {
  __typename: 'DataColumnGroupStructure';
  dataColumn: ClassificationDataColumn;
  groupStructure: GroupStructure;
};

export type DataColumnRule = {
  __typename: 'DataColumnRule';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  dataColumnRuleSetId: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  filters: Array<Filter>;
  groupLevel?: Maybe<Scalars['Int']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  precedence?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type DataColumnRuleSet = {
  __typename: 'DataColumnRuleSet';
  fieldsWithFilterType: Array<FieldWithFilterType>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  precedence: Scalars['Int']['output'];
  targetDataColumnId: Scalars['String']['output'];
};

export type DataColumnSelectionInput =
  | 'INCLUDE_RELATED'
  | 'ONLY_INDEXED';

export type DataColumnType =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataColumnTypeInput =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataCubeConfiguration = {
  __typename: 'DataCubeConfiguration';
  dataTableId: Scalars['String']['output'];
  fieldsToInclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DataPipeline = {
  __typename: 'DataPipeline';
  dataFilterCombinations: Array<DataPipelineFilterCombination>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceDataRepositoryIds: Array<Scalars['String']['output']>;
  targetDataTableId: Scalars['String']['output'];
};

export type DataPipelineExecution = {
  __typename: 'DataPipelineExecution';
  batchIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  dataPipelineId: Scalars['String']['output'];
  dryRun?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type DataPipelineFilter = {
  __typename: 'DataPipelineFilter';
  filterDataType: FilterDataType;
  id: Scalars['ID']['output'];
  operator: FilterOperatorType;
  sourceId: Scalars['String']['output'];
  sourceType: InputOutputNodeType;
  value: Scalars['String']['output'];
};

export type DataPipelineFilterCombination = {
  __typename: 'DataPipelineFilterCombination';
  filters: Array<DataPipelineFilter>;
  id: Scalars['ID']['output'];
};

export type DataPipelineLayout = {
  __typename: 'DataPipelineLayout';
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  layoutJson: Scalars['String']['output'];
};

export type DataPipelineOperation = {
  __typename: 'DataPipelineOperation';
  dataPipelineId: Scalars['String']['output'];
  dateParserOperationAdapter?: Maybe<DateParserOperationAdapter>;
  fillBlanksOperationAdapter?: Maybe<FillBlanksOperationAdapter>;
  getOrCreateOperationAdapter?: Maybe<GetOrCreateOperationAdapter>;
  id: Scalars['ID']['output'];
  input: Array<DataPipelineOperationInputOutput>;
  letterCasingOperationAdapter?: Maybe<LetterCasingOperationAdapter>;
  listParserOperationAdapter?: Maybe<ListParserOperationAdapter>;
  lookupOperationAdapter?: Maybe<LookupOperationAdapter>;
  mappingOperationAdapter?: Maybe<MappingOperationAdapter>;
  mathematicalExpressionOperationAdapter?: Maybe<MathematicalExpressionOperationAdapter>;
  name: Scalars['String']['output'];
  operationType: OperationType;
  output: Array<DataPipelineOperationInputOutput>;
  regexOperationAdapter?: Maybe<RegexOperationAdapter>;
  replaceOperationAdapter?: Maybe<ReplaceOperationAdapter>;
  splitOperationAdapter?: Maybe<SplitOperationAdapter>;
  templateStringOperationAdapter?: Maybe<TemplateStringOperationAdapter>;
};

export type DataPipelineOperationInputOutput = {
  __typename: 'DataPipelineOperationInputOutput';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type DataRepository = {
  __typename: 'DataRepository';
  autoTriggerPipelines: Scalars['Boolean']['output'];
  fields: Array<DataRepositoryField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  repositoryCollectionId?: Maybe<Scalars['String']['output']>;
  uniqueIdentifierConfiguration?: Maybe<UniqueIdentifierConfiguration>;
};

export type DataRepositoryCollection = {
  __typename: 'DataRepositoryCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataRepositoryField = {
  __typename: 'DataRepositoryField';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DataRepositoryRow = {
  __typename: 'DataRepositoryRow';
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId: Scalars['String']['output'];
};

export type DataTable = {
  __typename: 'DataTable';
  DataTableCollectionId?: Maybe<Scalars['String']['output']>;
  elasticIndex: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  hasPendingConnectionChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateSetting?: Maybe<RelativeDateSetting>;
  tableType?: Maybe<Scalars['String']['output']>;
};

export type DataTableCollection = {
  __typename: 'DataTableCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataTableCollectionOrderItem = {
  __typename: 'DataTableCollectionOrderItem';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type DataTableCollectionOrderItemInput = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};

export type DataTableConnection = {
  __typename: 'DataTableConnection';
  criteria: Array<DataTableConnectionCriterion>;
  dataColumnId: Scalars['String']['output'];
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  targetDataSource: DataTableConnectionSource;
};

export type DataTableConnectionCriterion = {
  __typename: 'DataTableConnectionCriterion';
  id: Scalars['ID']['output'];
  ignoreOnBlankTarget: Scalars['Boolean']['output'];
  sourceField: Scalars['String']['output'];
  targetField: Scalars['String']['output'];
  type: DataTableConnectionCriterionType;
};

export type DataTableConnectionCriterionInput = {
  id: Scalars['ID']['input'];
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type DataTableConnectionCriterionType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionCriterionTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionInput = {
  criteria: Array<DataTableConnectionCriterionInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  targetDataSource: DataTableConnectionSourceInput;
};

export type DataTableConnectionSource = {
  __typename: 'DataTableConnectionSource';
  identifier: Scalars['String']['output'];
  type: DataTableConnectionSourceType;
};

export type DataTableConnectionSourceInput = {
  identifier: Scalars['String']['input'];
  type: DataTableConnectionSourceTypeInput;
};

export type DataTableConnectionSourceType =
  | 'DATA_TABLE';

export type DataTableConnectionSourceTypeInput =
  | 'DATA_TABLE';

export type DataTableExport = {
  __typename: 'DataTableExport';
  exportDate: Scalars['String']['output'];
  exportFormat: ExportFormats;
  id: Scalars['ID']['output'];
};

export type DataTableRow = {
  __typename: 'DataTableRow';
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DataTableRowWithLabel = {
  __typename: 'DataTableRowWithLabel';
  dataJsonWithLabel: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DateFilter = {
  fromYear: Scalars['Int']['input'];
  toYear: Scalars['Int']['input'];
};

export type DateInterval =
  | 'MONTH'
  | 'QUARTER'
  | 'YEAR';

export type DateParserOperationAdapter = {
  __typename: 'DateParserOperationAdapter';
  dateFormat: Scalars['String']['output'];
};

export type DatePeriodInput = {
  relative?: InputMaybe<RelativePeriodSelection>;
  static?: InputMaybe<StaticPeriodSelection>;
};

export type DefaultFilterCombination = {
  __typename: 'DefaultFilterCombination';
  filterCombinationId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type DeleteAssessmentInput = {
  assessmentId: Scalars['String']['input'];
};

export type DeleteAssessmentResponse = {
  __typename: 'DeleteAssessmentResponse';
  deletedAssessmentId: Scalars['String']['output'];
};

export type DeleteAssessmentsFileInput = {
  fileName: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
};

export type DeleteAssessmentsFileResponse = {
  __typename: 'DeleteAssessmentsFileResponse';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteAutomaticRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteAutomaticRuleResponse = {
  __typename: 'DeleteAutomaticRuleResponse';
  status: Scalars['String']['output'];
};

export type DeleteCampaignInput = {
  id: Scalars['String']['input'];
};

export type DeleteCampaignResponse = {
  __typename: 'DeleteCampaignResponse';
  id: Scalars['String']['output'];
};

export type DeleteCompanySettingsInput = {
  orgNumber: Scalars['Int']['input'];
};

export type DeleteCompanySettingsResponse = {
  __typename: 'DeleteCompanySettingsResponse';
  orgNumber: Scalars['Int']['output'];
  result: Scalars['Boolean']['output'];
};

export type DeleteContactsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteContactsResponse = {
  __typename: 'DeleteContactsResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type DeleteCustomerResponse = {
  __typename: 'DeleteCustomerResponse';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteDataColumnRuleSetsInput = {
  dataColumnRuleSetIds: Array<Scalars['String']['input']>;
  isDeletingDataColumn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteDataColumnRuleSetsResponse = {
  __typename: 'DeleteDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type DeleteDataColumnRulesResponse = {
  __typename: 'DeleteDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnsInput = {
  dataColumnIds: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type DeleteDataColumnsResponse = {
  __typename: 'DeleteDataColumnsResponse';
  dataTable: DataTable;
  notificationId: Scalars['String']['output'];
};

export type DeleteDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type DeleteDataPipelineOperationInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataPipelineOperationResponse = {
  __typename: 'DeleteDataPipelineOperationResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataPipelineResponse = {
  __typename: 'DeleteDataPipelineResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRepositoryCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataRepositoryCollectionResponse = {
  __typename: 'DeleteDataRepositoryCollectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataRepositoryInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataRepositoryResponse = {
  __typename: 'DeleteDataRepositoryResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRowInput = {
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId: Scalars['String']['input'];
};

export type DeleteDataRowResponse = {
  __typename: 'DeleteDataRowResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableCollectionResponse = {
  __typename: 'DeleteDataTableCollectionResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableConnectionInput = {
  dataTableConnectionId: Scalars['String']['input'];
};

export type DeleteDataTableConnectionResponse = {
  __typename: 'DeleteDataTableConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataTableInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableResponse = {
  __typename: 'DeleteDataTableResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteFilesInput = {
  fileIds: Array<Scalars['String']['input']>;
};

export type DeleteFilesResponse = {
  __typename: 'DeleteFilesResponse';
  deletedFileKeys: Array<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type DeleteFilterCombinationInput = {
  id: Scalars['String']['input'];
};

export type DeleteFilterCombinationResponse = {
  __typename: 'DeleteFilterCombinationResponse';
  id: Scalars['String']['output'];
};

export type DeleteFilterFieldMappingInput = {
  id: Scalars['String']['input'];
};

export type DeleteFilterFieldMappingResponse = {
  __typename: 'DeleteFilterFieldMappingResponse';
  id: Scalars['String']['output'];
};

export type DeleteGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteGroupResponse = {
  __typename: 'DeleteGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteImportConfigurationInput = {
  id: Scalars['String']['input'];
};

export type DeleteImportConfigurationResponse = {
  __typename: 'DeleteImportConfigurationResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteImportsInput = {
  dataRepositoryId: Scalars['String']['input'];
  importIDs: Array<Scalars['String']['input']>;
};

export type DeleteImportsResponse = {
  __typename: 'DeleteImportsResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteInputOutputConnectionInput = {
  inputOutputConnectionId: Scalars['String']['input'];
};

export type DeleteInputOutputConnectionResponse = {
  __typename: 'DeleteInputOutputConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteInvitesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteInvitesResponse = {
  __typename: 'DeleteInvitesResponse';
  ids: Array<Scalars['ID']['output']>;
};

export type DeleteManualRuleInput = {
  doneBy: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type DeleteManualRuleResponse = {
  __typename: 'DeleteManualRuleResponse';
  status: Scalars['String']['output'];
};

export type DeletePipelineFilterCombinationInput = {
  id: Scalars['String']['input'];
};

export type DeletePipelineFilterCombinationResponse = {
  __typename: 'DeletePipelineFilterCombinationResponse';
  dataPipeline: DataPipeline;
};

export type DeletePipelineFilterInput = {
  id: Scalars['String']['input'];
};

export type DeletePipelineFilterResponse = {
  __typename: 'DeletePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type DeleteQuestionInput = {
  questionId: Scalars['String']['input'];
};

export type DeleteQuestionResponse = {
  __typename: 'DeleteQuestionResponse';
  remainingQuestions: Array<Question>;
};

export type DeleteQuestionnairesInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteQuestionnairesResponse = {
  __typename: 'DeleteQuestionnairesResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type DeleteRepositoryRowDeleteQueryInput = {
  id: Scalars['String']['input'];
};

export type DeleteRepositoryRowDeleteQueryResponse = {
  __typename: 'DeleteRepositoryRowDeleteQueryResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteRolesInput = {
  roleNames: Array<Scalars['String']['input']>;
};

export type DeleteRolesResponse = {
  __typename: 'DeleteRolesResponse';
  count: Scalars['Int']['output'];
  roleNames: Array<Scalars['String']['output']>;
};

export type DeleteRowsByDeleteQueryInput = {
  deleteQueryId: Scalars['String']['input'];
};

export type DeleteRowsByDeleteQueryResponse = {
  __typename: 'DeleteRowsByDeleteQueryResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteSectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteSectionResponse = {
  __typename: 'DeleteSectionResponse';
  reorderedSections: Array<Section>;
};

export type DeleteSelectColumnChoiceInput = {
  choice: Scalars['String']['input'];
  columnId: Scalars['String']['input'];
};

export type DeleteSelectColumnChoiceResponse = {
  __typename: 'DeleteSelectColumnChoiceResponse';
  choices: Array<Scalars['String']['output']>;
};

export type DeleteSupplierFileInput = {
  fileName: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type DeleteSupplierFileResponse = {
  __typename: 'DeleteSupplierFileResponse';
  ok: Scalars['Boolean']['output'];
};

export type DeleteSupplierNoteInput = {
  id: Scalars['String']['input'];
};

export type DeleteSupplierNoteResponse = {
  __typename: 'DeleteSupplierNoteResponse';
  deleted: Scalars['Boolean']['output'];
  deletedId: Scalars['String']['output'];
};

export type DeleteSupplierTableColumnInput = {
  id: Scalars['String']['input'];
};

export type DeleteSupplierTableColumnResponse = {
  __typename: 'DeleteSupplierTableColumnResponse';
  id: Scalars['String']['output'];
};

export type DeleteSupplierTableConfigInput = {
  id: Scalars['String']['input'];
};

export type DeleteSupplierTableConfigResponse = {
  __typename: 'DeleteSupplierTableConfigResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteSuppliersInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteSuppliersResponse = {
  __typename: 'DeleteSuppliersResponse';
  ids: Array<Scalars['String']['output']>;
};

export type DeleteTagValueByValueInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type DeleteTagValueByValueResponse = {
  __typename: 'DeleteTagValueByValueResponse';
  tag: TagConfiguration;
};

export type DeleteTaskInput = {
  id: Scalars['String']['input'];
};

export type DeleteTaskResponse = {
  __typename: 'DeleteTaskResponse';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
};

export type DeleteUniqueIdentifierConfigurationResponse = {
  __typename: 'DeleteUniqueIdentifierConfigurationResponse';
  deletedId: Scalars['String']['output'];
};

export type DelimiterType =
  | 'COMMA'
  | 'SEMICOLON'
  | 'TAB';

export type DelimiterTypeInput =
  | 'COMMA'
  | 'SEMICOLON'
  | 'TAB';

export type DirectionsInput =
  | 'asc'
  | 'desc';

export type EditRolesInput = {
  id: Scalars['ID']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditRolesResponse = {
  __typename: 'EditRolesResponse';
  result: Invite;
};

export type ElasticData = {
  __typename: 'ElasticData';
  dataJson: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  index: Scalars['String']['output'];
  sortScore?: Maybe<Scalars['Float']['output']>;
};

export type ElasticFieldInput = {
  field: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ElasticFieldItem = {
  __typename: 'ElasticFieldItem';
  elasticIndex: Scalars['String']['output'];
  field: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  globalTypeSubKey?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
  labelFieldType?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  maxDateForRelativeFilters?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type ElasticFieldWithOptionalRelationalFieldInput = {
  field: Scalars['String']['input'];
  relationalLabelField?: InputMaybe<Scalars['String']['input']>;
  relationalLabelType?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type ElectricityBasis =
  | 'LOCATION_BASED'
  | 'MARKET_BASED';

export type EmissionsFilter = {
  businessUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dateFilter?: InputMaybe<DateFilter>;
  sourceFilter?: InputMaybe<SourceAndActivityFilter>;
  spendCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tagFilters?: InputMaybe<Array<TagFilter>>;
};

export type EmissionsSetupInput = {
  yearColumnId: Scalars['String']['input'];
};

export type EmissionsSetupResponse = {
  __typename: 'EmissionsSetupResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type EncodingType =
  | 'ISO88591'
  | 'UTF';

export type EncodingTypeInput =
  | 'ISO88591'
  | 'UTF';

export type EnrichmentType =
  | 'ENIN_ENTITY_INSIGHTS'
  | 'INFOBEL_BUSINESS_SEARCH';

export type EnsureSupplierTableSpendColumnsInput = {
  numberOfYears?: InputMaybe<Scalars['Int']['input']>;
};

export type EnsureSupplierTableSpendColumnsResponse = {
  __typename: 'EnsureSupplierTableSpendColumnsResponse';
  updated: Scalars['Boolean']['output'];
};

export type EnsureValidSupplierUploadStateInput = {
  checkOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnsureValidSupplierUploadStateResponse = {
  __typename: 'EnsureValidSupplierUploadStateResponse';
  isValid: Scalars['Boolean']['output'];
};

export type EnumElement = {
  __typename: 'EnumElement';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type EscapeCharacterType =
  | 'BACKSLASH'
  | 'DOUBLE_QUOTE'
  | 'VERTICAL_BAR';

export type EscapeCharacterTypeInput =
  | 'BACKSLASH'
  | 'DOUBLE_QUOTE'
  | 'VERTICAL_BAR';

export type ExportDataTableToFileInput = {
  dataTableId: Scalars['String']['input'];
  exportFormat: ExportFormatsInput;
  fields: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  notificationTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDataTableToFileResponse = {
  __typename: 'ExportDataTableToFileResponse';
  notificationId: Scalars['String']['output'];
};

export type ExportFormats =
  | 'CSV'
  | 'XLSX';

export type ExportFormatsInput =
  | 'CSV'
  | 'XLSX';

export type FavouriteField = {
  __typename: 'FavouriteField';
  dataSource: Scalars['String']['output'];
  field: Scalars['String']['output'];
};

export type Field = {
  __typename: 'Field';
  fieldType: FieldType;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type FieldInput = {
  fieldType: FieldTypeInput;
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type FieldSamples = {
  __typename: 'FieldSamples';
  fieldKey: Scalars['String']['output'];
  samples: Array<Scalars['String']['output']>;
};

export type FieldType =
  | 'boolean'
  | 'date'
  | 'float'
  | 'integer'
  | 'keyword'
  | 'long'
  | 'short'
  | 'text';

export type FieldTypeInput =
  | 'boolean'
  | 'date'
  | 'float'
  | 'integer'
  | 'keyword'
  | 'long'
  | 'short'
  | 'text';

export type FieldWithFilterType = {
  __typename: 'FieldWithFilterType';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
};

export type FieldWithLevelInput = {
  field: Scalars['String']['input'];
  level: Scalars['Int']['input'];
};

export type FieldsInput = {
  dataSource: Scalars['String']['input'];
  field: Scalars['String']['input'];
};

export type File = {
  __typename: 'File';
  fileDetails: FileDetails;
  fileId: Scalars['String']['output'];
};

export type FileDetails = {
  __typename: 'FileDetails';
  fileName: Scalars['String']['output'];
  fileSize: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
};

export type FileDetailsInput = {
  fileName: Scalars['String']['input'];
  fileSize: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};

export type FileFieldInput = {
  dataType: PrimitiveDataColumnTypeInput;
  fieldName: Scalars['String']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type FileFieldsInput =
  | 'fileName'
  | 'fileSize';

export type FileInfo = {
  __typename: 'FileInfo';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FileSettingsInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type FileUpload =
  | 'disabled'
  | 'optional'
  | 'required';

export type FileUploadInput =
  | 'disabled'
  | 'optional'
  | 'required';

export type FileUploadStatusInput = {
  fileId: Scalars['String']['input'];
  uploadStatus: Scalars['String']['input'];
};

export type FileUrl = {
  __typename: 'FileUrl';
  fileId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FillBlanksOperationAdapter = {
  __typename: 'FillBlanksOperationAdapter';
  blankValue?: Maybe<Scalars['String']['output']>;
  withColumn: Scalars['Boolean']['output'];
};

export type Filter = {
  __typename: 'Filter';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
  labelValue?: Maybe<Scalars['String']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterCombination = {
  __typename: 'FilterCombination';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  dataSources?: Maybe<Scalars['String']['output']>;
  filtersJSON: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};

export type FilterCombinationInput = {
  dataSources?: InputMaybe<Scalars['String']['input']>;
  filtersJSON: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type FilterDataType =
  | 'DATE'
  | 'NUMBER'
  | 'TEXT';

export type FilterDataTypeInput =
  | 'DATE'
  | 'NUMBER'
  | 'TEXT';

export type FilterField = {
  __typename: 'FilterField';
  dataSource: Scalars['String']['output'];
  field: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
};

export type FilterFieldInput = {
  dataSource: Scalars['String']['input'];
  field: Scalars['String']['input'];
  labelField?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFieldMapping = {
  __typename: 'FilterFieldMapping';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  fields: Array<FilterField>;
  id: Scalars['ID']['output'];
  labelFieldType?: Maybe<FilterFieldType>;
  name: Scalars['String']['output'];
  type: FilterFieldType;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};

export type FilterFieldMappingInput = {
  fields: Array<FilterFieldInput>;
  id: Scalars['ID']['input'];
  labelFieldType?: InputMaybe<FilterFieldType>;
  name: Scalars['String']['input'];
  type: FilterFieldType;
};

export type FilterFieldType =
  | 'boolean'
  | 'date'
  | 'float'
  | 'integer'
  | 'keyword'
  | 'long'
  | 'short'
  | 'text'
  | 'unknown';

export type FilterInput = {
  field: Scalars['String']['input'];
  filterType: RuleFilterTypeInput;
  labelValue?: InputMaybe<Scalars['String']['input']>;
  numericValue?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FilterOperatorType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'NOT_EQUAL';

export type FilterOperatorTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'NOT_EQUAL';

export type FindBlankCellsInColumnInput = {
  columnIndex: Scalars['Int']['input'];
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type FindBlankCellsInColumnResponse = {
  __typename: 'FindBlankCellsInColumnResponse';
  dummyOutput: Scalars['String']['output'];
};

export type FindDuplicatesInFileInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  uniqueColumnIndicies: Array<Scalars['Int']['input']>;
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type FindDuplicatesInFileResponse = {
  __typename: 'FindDuplicatesInFileResponse';
  dummyOutput: Scalars['String']['output'];
};

export type GetActiveContractsInput = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  selection: SelectionFilter;
};

export type GetActiveContractsResponse = {
  __typename: 'GetActiveContractsResponse';
  contracts: Array<Contract>;
  totalCount: Scalars['Int']['output'];
};

export type GetActiveContractsSummaryInput = {
  selection: SelectionFilter;
};

export type GetActiveContractsSummaryResponse = {
  __typename: 'GetActiveContractsSummaryResponse';
  active: ActiveContractsSpendResult;
  expiring: ActiveContractsSpendResult;
  renewing: ActiveContractsSpendResult;
};

export type GetActiveSuppliersCountInput = {
  selection: SelectionFilter;
};

export type GetActiveSuppliersResponse = {
  __typename: 'GetActiveSuppliersResponse';
  previousPeriod: PeriodValue;
  thisPeriod: PeriodValue;
};

export type GetAllAffectedSuppliersInput = {
  offset: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
};

export type GetAllAffectedSuppliersResponse = {
  __typename: 'GetAllAffectedSuppliersResponse';
  affectedSuppliers: Array<AffectedSupplier>;
  total: Scalars['Int']['output'];
};

export type GetAllAutomaticRulesResponse = {
  __typename: 'GetAllAutomaticRulesResponse';
  automaticRules: Array<AutomaticRule>;
};

export type GetAllDataColumnRuleSetsInput = {
  targetDataColumnId: Scalars['String']['input'];
};

export type GetAllDataColumnRuleSetsResponse = {
  __typename: 'GetAllDataColumnRuleSetsResponse';
  dataColumnRuleSets: Array<DataColumnRuleSet>;
};

export type GetAllDataColumnsResponse = {
  __typename: 'GetAllDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetAllDataPipelineExecutionsInput = {
  dataPipelineId: Scalars['String']['input'];
  dryRunsOnly: Scalars['Boolean']['input'];
};

export type GetAllDataPipelineExecutionsResponse = {
  __typename: 'GetAllDataPipelineExecutionsResponse';
  dataPipelineExecutions: Array<DataPipelineExecution>;
};

export type GetAllDataPipelinesResponse = {
  __typename: 'GetAllDataPipelinesResponse';
  dataPipelines: Array<DataPipeline>;
};

export type GetAllDataRepositoriesResponse = {
  __typename: 'GetAllDataRepositoriesResponse';
  dataRepositories: Array<DataRepository>;
};

export type GetAllDataRepositoryCollectionsResponse = {
  __typename: 'GetAllDataRepositoryCollectionsResponse';
  dataRepositoryCollections: Array<DataRepositoryCollection>;
};

export type GetAllDataTableCollectionsResponse = {
  __typename: 'GetAllDataTableCollectionsResponse';
  dataTableCollections: Array<DataTableCollection>;
};

export type GetAllDataTableConnectionsResponse = {
  __typename: 'GetAllDataTableConnectionsResponse';
  dataTableConnections: Array<DataTableConnection>;
};

export type GetAllElasticFieldsResponse = {
  __typename: 'GetAllElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetAllGroupStructuresResponse = {
  __typename: 'GetAllGroupStructuresResponse';
  groupStructures: Array<GroupStructure>;
};

export type GetAllImportConfigurationsResponse = {
  __typename: 'GetAllImportConfigurationsResponse';
  importConfigurations: Array<ImportConfiguration>;
};

export type GetAllImportsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetAllImportsByDataTableResponse = {
  __typename: 'GetAllImportsByDataTableResponse';
  imports: Array<Import>;
};

export type GetAllManualRulesInput = {
  offset: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type GetAllManualRulesResponse = {
  __typename: 'GetAllManualRulesResponse';
  manualRules: Array<ManualRule>;
  total: Scalars['Int']['output'];
};

export type GetAllPendingChangesInput = {
  includeAffectedColumns?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetAllPendingChangesResponse = {
  __typename: 'GetAllPendingChangesResponse';
  pendingChanges: Array<PendingChange>;
};

export type GetAllRunningRulesResponse = {
  __typename: 'GetAllRunningRulesResponse';
  runningRules: Array<RunningRules>;
};

export type GetApiImportConfigurationInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetApiImportConfigurationResponse = {
  __typename: 'GetApiImportConfigurationResponse';
  importConfiguration?: Maybe<ImportConfiguration>;
};

export type GetAssessmentInput = {
  id: Scalars['String']['input'];
};

export type GetAssessmentResponse = {
  __typename: 'GetAssessmentResponse';
  assessment: Assessment;
};

export type GetAssessmentsByCampaignInput = {
  campaignId: Scalars['String']['input'];
};

export type GetAssessmentsByCampaignResponse = {
  __typename: 'GetAssessmentsByCampaignResponse';
  assessments: Array<Assessment>;
};

export type GetAssessmentsInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AssessmentType>;
};

export type GetAssessmentsResponse = {
  __typename: 'GetAssessmentsResponse';
  assessments: Array<Assessment>;
};

export type GetAutomaticRuleInput = {
  id: Scalars['String']['input'];
};

export type GetAutomaticRuleResponse = {
  __typename: 'GetAutomaticRuleResponse';
  automaticRule: AutomaticRule;
};

export type GetAvailableCustomersResponse = {
  __typename: 'GetAvailableCustomersResponse';
  result: Array<Customer>;
};

export type GetAvailableSpendFieldsResponse = {
  __typename: 'GetAvailableSpendFieldsResponse';
  fields: Array<SpendField>;
};

export type GetBusinessUnitInput = {
  id: Scalars['String']['input'];
};

export type GetBusinessUnitResponse = {
  __typename: 'GetBusinessUnitResponse';
  businessUnit: BusinessUnit;
};

export type GetBusinessUnitsInput = {
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetBusinessUnitsResponse = {
  __typename: 'GetBusinessUnitsResponse';
  businessUnits: Array<BusinessUnit>;
};

export type GetCampaignInput = {
  id: Scalars['String']['input'];
};

export type GetCampaignResponse = {
  __typename: 'GetCampaignResponse';
  campaign: Campaign;
};

export type GetCampaignsResponse = {
  __typename: 'GetCampaignsResponse';
  campaigns: Array<Campaign>;
};

export type GetCardinalitiesInput = {
  elasticIndex: Scalars['String']['input'];
  fields: Array<Scalars['String']['input']>;
  filtersJSON: Scalars['String']['input'];
};

export type GetCardinalitiesResponse = {
  __typename: 'GetCardinalitiesResponse';
  cardinalities: Array<CardinalityObject>;
};

export type GetCategoryComparisonListInput = {
  categoryLevel: Scalars['Int']['input'];
  filterSelection: SelectionFilter;
};

export type GetCategoryComparisonListResponse = {
  __typename: 'GetCategoryComparisonListResponse';
  categories: Array<CategoryResult>;
};

export type GetCompaniesInput = {
  enrichmentPrecedence: Array<EnrichmentType>;
  ids: Array<Scalars['ID']['input']>;
  includeFinancialStatements?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetCompaniesResponse = {
  __typename: 'GetCompaniesResponse';
  companies?: Maybe<Array<Company>>;
};

export type GetCompanyFavoriteFiltersResponse = {
  __typename: 'GetCompanyFavoriteFiltersResponse';
  favorites?: Maybe<CompanyFavouriteFilters>;
};

export type GetCompanyFavouriteFiltersResponse = {
  __typename: 'GetCompanyFavouriteFiltersResponse';
  favourites: CompanyFavouriteFilters;
};

export type GetCompanyInput = {
  enrichmentPrecedence?: InputMaybe<Array<EnrichmentType>>;
  id: Scalars['ID']['input'];
};

export type GetCompanyResponse = {
  __typename: 'GetCompanyResponse';
  company: Company;
};

export type GetCompanySettingsInput = {
  orgNumber: Scalars['Int']['input'];
};

export type GetCompanySettingsResponse = {
  __typename: 'GetCompanySettingsResponse';
  companyColor: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNumber: Scalars['Int']['output'];
};

export type GetCompleteStructureByPredefinedTypeInput = {
  predefinedType: GroupStructurePredefinedTypeInput;
};

export type GetCompleteStructureByPredefinedTypeResponse = {
  __typename: 'GetCompleteStructureByPredefinedTypeResponse';
  groups: Array<GroupNode>;
};

export type GetContactsBySupplierIdInput = {
  supplierId: Scalars['String']['input'];
};

export type GetContactsBySupplierIdResponse = {
  __typename: 'GetContactsBySupplierIdResponse';
  result: SupplierContacts;
};

export type GetContactsBySupplierIdsInput = {
  supplierIds: Array<Scalars['String']['input']>;
};

export type GetContactsBySupplierIdsResponse = {
  __typename: 'GetContactsBySupplierIdsResponse';
  result: Array<SupplierContacts>;
};

export type GetContactsInput = {
  contacts: Array<ContactInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: SortInput;
};

export type GetContactsResponse = {
  __typename: 'GetContactsResponse';
  count: Scalars['Int']['output'];
  next: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  prev: Scalars['Int']['output'];
  result: Array<Contact>;
  total: Scalars['Int']['output'];
};

export type GetContractCoverageDevelopmentInput = {
  filterSelection: SelectionFilter;
  interval: DateInterval;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetContractStatisticsInput = {
  responsibleId?: InputMaybe<Scalars['String']['input']>;
};

export type GetContractedSpendInput = {
  selection: SelectionFilter;
};

export type GetContractedSpendResponse = {
  __typename: 'GetContractedSpendResponse';
  previousPeriod: PeriodValue;
  thisPeriod: PeriodValue;
};

export type GetContractsInput = {
  endDateAfter?: InputMaybe<Scalars['String']['input']>;
  endDateBefore?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  renewalDateAfter?: InputMaybe<Scalars['String']['input']>;
  renewalDateBefore?: InputMaybe<Scalars['String']['input']>;
  responsibles?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  spendAbove?: InputMaybe<Scalars['Float']['input']>;
  spendBelow?: InputMaybe<Scalars['Float']['input']>;
  spendNull?: InputMaybe<Scalars['Boolean']['input']>;
  startDateAfter?: InputMaybe<Scalars['String']['input']>;
  startDateBefore?: InputMaybe<Scalars['String']['input']>;
  supplierIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetContractsResponse = {
  __typename: 'GetContractsResponse';
  data: Array<Contract>;
  total: Scalars['Int']['output'];
};

export type GetCredentialStateResponse = {
  __typename: 'GetCredentialStateResponse';
  passwordSet: Scalars['Boolean']['output'];
  providers: Array<Scalars['String']['output']>;
  requireSso: Scalars['Boolean']['output'];
};

export type GetDataColumnDependenciesInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDataColumnDependenciesResponse = {
  __typename: 'GetDataColumnDependenciesResponse';
  hasDeps: Scalars['Boolean']['output'];
  messageToUser: Scalars['String']['output'];
};

export type GetDataColumnElasticFieldMapInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnElasticFieldMapResponse = {
  __typename: 'GetDataColumnElasticFieldMapResponse';
  dataColumnElasticFieldMaps: Array<DataColumnElasticFieldMap>;
};

export type GetDataColumnRulesByRuleSetInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataColumnRulesByRuleSetInput;
};

export type GetDataColumnRulesByRuleSetResponse = {
  __typename: 'GetDataColumnRulesByRuleSetResponse';
  count: Scalars['Int']['output'];
  dataColumnRules: Array<DataColumnRule>;
};

export type GetDataColumnsInput = {
  columnSelection?: InputMaybe<DataColumnSelectionInput>;
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnsResponse = {
  __typename: 'GetDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDataCubeConfigurationInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataCubeConfigurationResponse = {
  __typename: 'GetDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type GetDataPipelineInput = {
  id: Scalars['String']['input'];
};

export type GetDataPipelineLayoutInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataPipelineLayoutResponse = {
  __typename: 'GetDataPipelineLayoutResponse';
  dataPipelineLayout: DataPipelineLayout;
};

export type GetDataPipelineOperationInput = {
  id: Scalars['String']['input'];
};

export type GetDataPipelineOperationResponse = {
  __typename: 'GetDataPipelineOperationResponse';
  operation: DataPipelineOperation;
};

export type GetDataPipelineOperationsInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type GetDataPipelineOperationsResponse = {
  __typename: 'GetDataPipelineOperationsResponse';
  dataPipelineOperations: Array<DataPipelineOperation>;
};

export type GetDataPipelineResponse = {
  __typename: 'GetDataPipelineResponse';
  dataPipeline: DataPipeline;
};

export type GetDataRepositoryInput = {
  id: Scalars['String']['input'];
};

export type GetDataRepositoryResponse = {
  __typename: 'GetDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type GetDataRepositoryRowCountInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetDataRepositoryRowCountResponse = {
  __typename: 'GetDataRepositoryRowCountResponse';
  rowCount: Scalars['Int']['output'];
};

export type GetDataRepositoryRowsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataRepositoryRowsInput;
};

export type GetDataRepositoryRowsResponse = {
  __typename: 'GetDataRepositoryRowsResponse';
  rows: Array<DataRepositoryRow>;
};

export type GetDataSourcesByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataSourcesByDataTableResponse = {
  __typename: 'GetDataSourcesByDataTableResponse';
  enrichmentSources: Array<DataRepository>;
  mainSources: Array<DataRepository>;
};

export type GetDataTableByGlobalTypeInput = {
  globalTypeKey: Scalars['String']['input'];
};

export type GetDataTableByGlobalTypeResponse = {
  __typename: 'GetDataTableByGlobalTypeResponse';
  dataTable?: Maybe<DataTable>;
};

export type GetDataTableElasticFieldsInput = {
  dataTableId: Scalars['String']['input'];
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeLabelFields?: InputMaybe<Scalars['Boolean']['input']>;
  withEnrichments: Scalars['Boolean']['input'];
};

export type GetDataTableElasticFieldsResponse = {
  __typename: 'GetDataTableElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetDataTableFieldLabelsInput = {
  dataTableId: Scalars['String']['input'];
  includePostfix: Scalars['Boolean']['input'];
};

export type GetDataTableFieldLabelsResponse = {
  __typename: 'GetDataTableFieldLabelsResponse';
  fieldLabelsJson: Scalars['String']['output'];
};

export type GetDataTableInput = {
  id: Scalars['String']['input'];
};

export type GetDataTableResponse = {
  __typename: 'GetDataTableResponse';
  entity: DataTable;
};

export type GetDataTableRowCountInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableRowCountResponse = {
  __typename: 'GetDataTableRowCountResponse';
  count: Scalars['Int']['output'];
};

export type GetDataTableRowInput = {
  dataTableId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type GetDataTableRowResponse = {
  __typename: 'GetDataTableRowResponse';
  dataTableRow: DataTableRow;
};

export type GetDataTableRowsInput = {
  dataTableId: Scalars['String']['input'];
  rowIds: Array<Scalars['String']['input']>;
};

export type GetDataTableRowsResponse = {
  __typename: 'GetDataTableRowsResponse';
  rows: Array<DataTableRow>;
};

export type GetDataTableRowsSearchInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataTableRowsSearchInput;
};

export type GetDataTableRowsSearchResponse = {
  __typename: 'GetDataTableRowsSearchResponse';
  dataTableRows: Array<DataTableRow>;
  total: Scalars['Int']['output'];
};

export type GetDataTableValidationSummaryInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableValidationSummaryResponse = {
  __typename: 'GetDataTableValidationSummaryResponse';
  cubeConfigurationCount: Scalars['Int']['output'];
  indexFieldCount: Scalars['Int']['output'];
};

export type GetDatePeriodResponse = {
  __typename: 'GetDatePeriodResponse';
  periodEnd: Scalars['String']['output'];
  periodStart: Scalars['String']['output'];
};

export type GetDefaultFilterCombinationInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetDefaultFilterCombinationResponse = {
  __typename: 'GetDefaultFilterCombinationResponse';
  filterCombinationId?: Maybe<Scalars['String']['output']>;
};

export type GetDependantColumnsInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDependantColumnsResponse = {
  __typename: 'GetDependantColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDependentRulesByGroupIdInput = {
  groupId: Scalars['String']['input'];
};

export type GetDependentRulesByGroupIdResponse = {
  __typename: 'GetDependentRulesByGroupIdResponse';
  dependentRules: Array<DataColumnRule>;
};

export type GetEchoInput = {
  echo: Scalars['String']['input'];
};

export type GetEchoResponse = {
  __typename: 'GetEchoResponse';
  echo: Scalars['String']['output'];
};

export type GetExportDownloadUrlsInput = {
  exportId: Scalars['String']['input'];
};

export type GetExportDownloadUrlsResponse = {
  __typename: 'GetExportDownloadUrlsResponse';
  downloadUrls: Array<Scalars['String']['output']>;
};

export type GetExportsByTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetExportsByTableIdResponse = {
  __typename: 'GetExportsByTableIdResponse';
  exports: Array<DataTableExport>;
};

export type GetFilesByIdsInput = {
  fileIds: Array<Scalars['String']['input']>;
};

export type GetFilesByIdsResponse = {
  __typename: 'GetFilesByIdsResponse';
  files: Array<File>;
};

export type GetFilesInput = {
  serviceId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type GetFilesResponse = {
  __typename: 'GetFilesResponse';
  files: Array<File>;
};

export type GetFilterCombinationsResponse = {
  __typename: 'GetFilterCombinationsResponse';
  filterCombinations: Array<FilterCombination>;
};

export type GetFilterFieldMappingsResponse = {
  __typename: 'GetFilterFieldMappingsResponse';
  filterFieldMappings: Array<FilterFieldMapping>;
};

export type GetFilteredSpendOpportunitiesInput = {
  filters: SpendQueryFilters;
};

export type GetFilteredSpendOpportunitiesResponse = {
  __typename: 'GetFilteredSpendOpportunitiesResponse';
  opportunities: Array<ISpendOpportunity>;
};

export type GetGlobalRolesResponse = {
  __typename: 'GetGlobalRolesResponse';
  result: Array<Role>;
};

export type GetGroupHasChildrenInput = {
  groupId: Scalars['String']['input'];
};

export type GetGroupHasChildrenResponse = {
  __typename: 'GetGroupHasChildrenResponse';
  hasChildren: Scalars['Boolean']['output'];
};

export type GetGroupInput = {
  id: Scalars['String']['input'];
};

export type GetGroupPredictionInput = {
  groupStructureId: Scalars['String']['input'];
  keyValuePairs: Array<KeyValuePairInput>;
};

export type GetGroupPredictionResponse = {
  __typename: 'GetGroupPredictionResponse';
  group: Group;
};

export type GetGroupPredictionsInput = {
  groupStructureId: Scalars['String']['input'];
  predictionInputs: Array<GroupPredictionInputInput>;
};

export type GetGroupPredictionsResponse = {
  __typename: 'GetGroupPredictionsResponse';
  predictions: Array<GroupPredictionOutput>;
};

export type GetGroupResponse = {
  __typename: 'GetGroupResponse';
  group: Group;
};

export type GetGroupStructureInput = {
  id: Scalars['String']['input'];
};

export type GetGroupStructureResponse = {
  __typename: 'GetGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type GetGroupStructuresInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetGroupStructuresResponse = {
  __typename: 'GetGroupStructuresResponse';
  dataColumnGroupStructures: Array<DataColumnGroupStructure>;
};

export type GetGroupsByGroupStructureInput = {
  groupStructureId: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetGroupsByGroupStructureResponse = {
  __typename: 'GetGroupsByGroupStructureResponse';
  groups: Array<GroupWithHasChildren>;
};

export type GetGroupsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetGroupsResponse = {
  __typename: 'GetGroupsResponse';
  groups: Array<Group>;
};

export type GetImportConfigurationInput = {
  id: Scalars['String']['input'];
};

export type GetImportConfigurationResponse = {
  __typename: 'GetImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
};

export type GetImportsByDataRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetImportsByDataRepositoryResponse = {
  __typename: 'GetImportsByDataRepositoryResponse';
  imports: Array<Import>;
};

export type GetImportsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetImportsResponse = {
  __typename: 'GetImportsResponse';
  imports: Array<Import>;
};

export type GetInputOutputConnectionsInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type GetInputOutputConnectionsResponse = {
  __typename: 'GetInputOutputConnectionsResponse';
  inputOutputConnections: Array<InputOutputConnection>;
};

export type GetInvitationUrlInput = {
  assessmentId: Scalars['String']['input'];
};

export type GetInvitationUrlResponse = {
  __typename: 'GetInvitationURLResponse';
  url: Scalars['String']['output'];
};

export type GetInvitesInput = {
  _cacheKey?: InputMaybe<Scalars['String']['input']>;
};

export type GetInvitesResponse = {
  __typename: 'GetInvitesResponse';
  result: Array<Invite>;
};

export type GetLinkedProvidersResponse = {
  __typename: 'GetLinkedProvidersResponse';
  providers: Array<Scalars['String']['output']>;
};

export type GetManyAggregatedViewsResponse = {
  __typename: 'GetManyAggregatedViewsResponse';
  entities: Array<AggregatedView>;
};

export type GetManyDataTablesResponse = {
  __typename: 'GetManyDataTablesResponse';
  entities: Array<DataTable>;
};

export type GetManyUniqueIdentifierConfigurationsResponse = {
  __typename: 'GetManyUniqueIdentifierConfigurationsResponse';
  entities: Array<UniqueIdentifierConfiguration>;
};

export type GetMatchingFieldValuesInput = {
  elasticIndex: Scalars['String']['input'];
  outputField: ElasticFieldInput;
  sourceField: ElasticFieldInput;
  sourceValues: Array<Scalars['String']['input']>;
};

export type GetMatchingFieldValuesResponse = {
  __typename: 'GetMatchingFieldValuesResponse';
  sourceFieldOutputFieldMapJSON: Scalars['String']['output'];
};

export type GetMaxMinForFieldInput = {
  elasticIndex: Scalars['String']['input'];
  field: Scalars['String']['input'];
  filtersJSON?: InputMaybe<Scalars['String']['input']>;
};

export type GetMaxMinForFieldResponse = {
  __typename: 'GetMaxMinForFieldResponse';
  max: ValueObject;
  min: ValueObject;
};

export type GetMetricDevelopmentQueryInput = {
  filterSelection: SelectionFilter;
  groupBy: GroupByField;
  interval: DateInterval;
  metrics: Array<PredefinedMetric>;
};

export type GetMetricDevelopmentQueryResponse = {
  __typename: 'GetMetricDevelopmentQueryResponse';
  intervalResults: Array<IntervalResult>;
};

export type GetMinMaxForSupplierTableNumberColumn = {
  columnId: Scalars['String']['input'];
};

export type GetMinMaxForSupplierTableNumberColumnResponse = {
  __typename: 'GetMinMaxForSupplierTableNumberColumnResponse';
  columnId: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type GetMissingConfigurationsResponse = {
  __typename: 'GetMissingConfigurationsResponse';
  missingConfigurations: Array<MissingConfiguration>;
};

export type GetOperationOutputUsageInput = {
  id: Scalars['String']['input'];
};

export type GetOperationOutputUsageResponse = {
  __typename: 'GetOperationOutputUsageResponse';
  dataColumnIds: Array<Scalars['String']['output']>;
  dataPipelineOperationIds: Array<Scalars['String']['output']>;
  usedInFilters: Scalars['Boolean']['output'];
};

export type GetOrCreateCriterion = {
  __typename: 'GetOrCreateCriterion';
  criterionType: CriterionType;
  dataRepositoryFieldId: Scalars['String']['output'];
};

export type GetOrCreateCriterionInput = {
  criterionType: CriterionTypeInput;
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type GetOrCreateDataTableCollectionOrderResponse = {
  __typename: 'GetOrCreateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type GetOrCreateOperationAdapter = {
  __typename: 'GetOrCreateOperationAdapter';
  dataRepositoryId: Scalars['String']['output'];
  getOrCreateCriteria: Array<GetOrCreateCriterion>;
  upsert: Scalars['Boolean']['output'];
};

export type GetOverviewInfoResponse = {
  __typename: 'GetOverviewInfoResponse';
  numberOfNormalized: Scalars['String']['output'];
  numberOfRules: Scalars['String']['output'];
};

export type GetPaginatedConflictingRulesForUploadInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedConflictingRulesForUploadInput;
};

export type GetPaginatedConflictingRulesForUploadResponse = {
  __typename: 'GetPaginatedConflictingRulesForUploadResponse';
  conflictingRules: Array<ConflictingRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDanglingRulesInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDanglingRulesInput;
};

export type GetPaginatedDanglingRulesResponse = {
  __typename: 'GetPaginatedDanglingRulesResponse';
  rules: Array<DataColumnRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDuplicateRulesInFileInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDuplicateRulesInFileInput;
};

export type GetPaginatedDuplicateRulesInFileResponse = {
  __typename: 'GetPaginatedDuplicateRulesInFileResponse';
  duplicateRulesInFile: Array<ConflictingRuleFromFile>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedImportsByDataRepositoryInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedImportsByDataRepositoryInput;
};

export type GetPaginatedImportsByDataRepositoryResponse = {
  __typename: 'GetPaginatedImportsByDataRepositoryResponse';
  imports: Array<Import>;
  totalImports: Scalars['Int']['output'];
};

export type GetParentIdsBySearchTermInput = {
  groupStructureId: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
};

export type GetParentIdsBySearchTermResponse = {
  __typename: 'GetParentIdsBySearchTermResponse';
  ids: Array<Scalars['String']['output']>;
};

export type GetParentNamesByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentNamesByChildIdResponse = {
  __typename: 'GetParentNamesByChildIdResponse';
  names: Array<Scalars['String']['output']>;
};

export type GetParentsByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentsByChildIdResponse = {
  __typename: 'GetParentsByChildIdResponse';
  groups: Array<Group>;
};

export type GetPersonalFavoriteFiltersResponse = {
  __typename: 'GetPersonalFavoriteFiltersResponse';
  favorites?: Maybe<PersonalFavouriteFilters>;
};

export type GetPersonalFavouriteFiltersInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetPersonalFavouriteFiltersResponse = {
  __typename: 'GetPersonalFavouriteFiltersResponse';
  favourites: PersonalFavouriteFilters;
};

export type GetPinnedFieldsInput = {
  associatedId: Scalars['String']['input'];
};

export type GetPipelineOperationExecutionRowsByBatchIdInput = {
  batchId?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['String']['input'];
  pipelineExecutionId: Scalars['String']['input'];
};

export type GetPipelineOperationExecutionRowsByBatchIdResponse = {
  __typename: 'GetPipelineOperationExecutionRowsByBatchIdResponse';
  dataPipelineExecutionRows: Array<PipelineExecutionRow>;
};

export type GetPivotInput = {
  elasticIndex: Scalars['String']['input'];
  filters: Scalars['String']['input'];
  includeHidden: Scalars['Boolean']['input'];
  pivotQuery: Scalars['String']['input'];
};

export type GetPivotResponse = {
  __typename: 'GetPivotResponse';
  pivotResponse: Scalars['String']['output'];
};

export type GetPreviewFileInput = {
  fileId: Scalars['String']['input'];
};

export type GetPreviewFileResponse = {
  __typename: 'GetPreviewFileResponse';
  fields: Array<ClassificationFieldSamples>;
  fileId: Scalars['String']['output'];
};

export type GetProcurementEntityQueryInput = {
  distributions?: InputMaybe<Array<ProcurementEntityDistributionInput>>;
  field: ProcurementEntityType;
  filterSelection: SelectionFilter;
  metrics?: InputMaybe<Array<PredefinedMetric>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  subfield?: InputMaybe<Scalars['String']['input']>;
};

export type GetProcurementEntityQueryResponse = {
  __typename: 'GetProcurementEntityQueryResponse';
  entities: Array<ProcurementEntityResult>;
  entitiesTotalCount: Scalars['Int']['output'];
  metricTotals: Array<PredefinedMetricResult>;
};

export type GetQueryMultipleFieldsInput = {
  input: SearchInputInput;
};

export type GetQueryMultipleFieldsResponse = {
  __typename: 'GetQueryMultipleFieldsResponse';
  results: Array<ElasticData>;
  status: Status;
  total: Scalars['Int']['output'];
};

export type GetQueryMultipleIndicesInput = {
  input: Array<SearchInputInput>;
};

export type GetQueryMultipleIndicesResponse = {
  __typename: 'GetQueryMultipleIndicesResponse';
  indexStatus: Array<IndexStatus>;
  results: Array<ElasticData>;
  status: Status;
};

export type GetQuestionnaireInput = {
  id: Scalars['String']['input'];
};

export type GetQuestionnaireResponse = {
  __typename: 'GetQuestionnaireResponse';
  questionnaire: Questionnaire;
};

export type GetQuestionnairesResponse = {
  __typename: 'GetQuestionnairesResponse';
  questionnaires: Array<Questionnaire>;
};

export type GetRegexSuggestionInput = {
  descriptionOfRegex: Scalars['String']['input'];
};

export type GetRegexSuggestionResponse = {
  __typename: 'GetRegexSuggestionResponse';
  response: Scalars['String']['output'];
};

export type GetRelatedDataColumnsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetRelatedDataColumnsByDataTableResponse = {
  __typename: 'GetRelatedDataColumnsByDataTableResponse';
  dataColumns: Array<DataColumn>;
};

export type GetRepositoryRowDeleteQueriesByRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetRepositoryRowDeleteQueriesByRepositoryResponse = {
  __typename: 'GetRepositoryRowDeleteQueriesByRepositoryResponse';
  repositoryRowDeleteQueries: Array<RepositoryRowDeleteQuery>;
};

export type GetRiskColumnConfigResponse = {
  __typename: 'GetRiskColumnConfigResponse';
  countryColumn: Scalars['String']['output'];
  geographyRiskColumn: Scalars['String']['output'];
  id: Scalars['String']['output'];
  industryRiskColumn: Scalars['String']['output'];
  naceColumn: Scalars['String']['output'];
  nameColumn: Scalars['String']['output'];
  orgNumberColumn: Scalars['String']['output'];
  socialRiskColumn: Scalars['String']['output'];
};

export type GetRolesInput = {
  includeGlobal: Scalars['Boolean']['input'];
};

export type GetRolesResponse = {
  __typename: 'GetRolesResponse';
  result: Array<Role>;
};

export type GetRowsSearchWithLabelsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetRowsSearchWithLabelsInput;
};

export type GetRowsSearchWithLabelsResponse = {
  __typename: 'GetRowsSearchWithLabelsResponse';
  dataTableRowsWithLabel: Array<DataTableRowWithLabel>;
  total: Scalars['Int']['output'];
};

export type GetRuleCountPerRuleSetByDataColumnInput = {
  dataColumnId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type GetRuleCountPerRuleSetByDataColumnResponse = {
  __typename: 'GetRuleCountPerRuleSetByDataColumnResponse';
  ruleSetIdsWithRuleCounts: Array<RuleSetIdWithRuleCount>;
};

export type GetRulesGeneratedInput = {
  from: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type GetRulesGeneratedResponse = {
  __typename: 'GetRulesGeneratedResponse';
  rules: Array<ChangeLogRule>;
};

export type GetSavedCategoriesResponse = {
  __typename: 'GetSavedCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSavingsEstimatesInput = {
  includeIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSpendCategoriesInput = {
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetSpendCategoriesResponse = {
  __typename: 'GetSpendCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSpendCategoryInput = {
  id: Scalars['String']['input'];
};

export type GetSpendCategoryResponse = {
  __typename: 'GetSpendCategoryResponse';
  category: SpendCategory;
};

export type GetSpendDataColumnsResponse = {
  __typename: 'GetSpendDataColumnsResponse';
  dataColumns: Array<SpendDataColumn>;
};

export type GetSpendDevelopmentForSupplierInput = {
  interval: SpendIntervalType;
  supplierId: Scalars['String']['input'];
};

export type GetSpendDevelopmentInput = {
  interval?: InputMaybe<DateInterval>;
  selection: SelectionFilter;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSpendDevelopmentPeriodComparisonInput = {
  filterSelection: SelectionFilter;
  interval: DateInterval;
};

export type GetSpendDevelopmentPeriodComparisonResponse = {
  __typename: 'GetSpendDevelopmentPeriodComparisonResponse';
  results: Array<SpendDevelopmentPeriodComparisonResult>;
};

export type GetSpendDevelopmentResponse = {
  __typename: 'GetSpendDevelopmentResponse';
  labels: Array<Scalars['String']['output']>;
  previousPeriod: Array<Scalars['Float']['output']>;
  results: Array<SpendDevelopmentPeriodResult>;
  thisPeriod: Array<Scalars['Float']['output']>;
};

export type GetSpendDistributionForSupplierInput = {
  split: SpendSplitType;
  supplierId: Scalars['String']['input'];
};

export type GetSpendQueryByPromptInput = {
  prompt: Scalars['String']['input'];
};

export type GetSpendQueryByPromptResponse = {
  __typename: 'GetSpendQueryByPromptResponse';
  description: Scalars['String']['output'];
  result: SpendQueryResult;
};

export type GetSupplierFieldsByColumnResponse = {
  __typename: 'GetSupplierFieldsByColumnResponse';
  fields: Array<ConnectedFieldAndColumn>;
};

export type GetSupplierFieldsMappingSuggestionInput = {
  fileColumns: Array<Scalars['String']['input']>;
  supplierColumns: Array<SupplierColumnInput>;
};

export type GetSupplierFieldsMappingSuggestionResponse = {
  __typename: 'GetSupplierFieldsMappingSuggestionResponse';
  suggestions: Array<MappingSuggestion>;
};

export type GetSupplierFilesInput = {
  supplierId: Scalars['String']['input'];
};

export type GetSupplierFilesResponse = {
  __typename: 'GetSupplierFilesResponse';
  files: Array<SupplierFile>;
};

export type GetSupplierIdsInput = {
  id: Scalars['String']['input'];
};

export type GetSupplierIdsResponse = {
  __typename: 'GetSupplierIdsResponse';
  allIds: Array<Scalars['String']['output']>;
  inputId: Scalars['String']['output'];
  rootId: Scalars['String']['output'];
};

export type GetSupplierInput = {
  fallbackToOldDMS?: InputMaybe<Scalars['Boolean']['input']>;
  getNormalized?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};

export type GetSupplierResponse = {
  __typename: 'GetSupplierResponse';
  supplier: Supplier;
};

export type GetSupplierTableConfigsResponse = {
  __typename: 'GetSupplierTableConfigsResponse';
  supplierTableConfigs?: Maybe<Array<SupplierTableConfig>>;
};

export type GetSupplierTableDataSourcesResponse = {
  __typename: 'GetSupplierTableDataSourcesResponse';
  dataPipelineIds: Array<Scalars['String']['output']>;
  dataTableId?: Maybe<Scalars['String']['output']>;
  sources: Array<DataRepository>;
};

export type GetSupplierTableMetaResponse = {
  __typename: 'GetSupplierTableMetaResponse';
  columns: Array<SupplierTableColumn>;
  elasticIndex: Scalars['String']['output'];
  nameColumnId?: Maybe<Scalars['String']['output']>;
};

export type GetSuppliersByIdsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetSuppliersByIdsResponse = {
  __typename: 'GetSuppliersByIdsResponse';
  suppliers: Array<Supplier>;
};

export type GetSuppliersInput = {
  fallbackToOldDMS?: InputMaybe<Scalars['Boolean']['input']>;
  filterJson?: InputMaybe<Scalars['String']['input']>;
  nameSearch?: InputMaybe<Scalars['String']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageRange?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SupplierSortInput>;
};

export type GetSuppliersResponse = {
  __typename: 'GetSuppliersResponse';
  suppliers: Array<Supplier>;
  total: Scalars['Int']['output'];
};

export type GetTagValuesByColumnIdInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetTagValuesByColumnIdResponse = {
  __typename: 'GetTagValuesByColumnIdResponse';
  tagValues: Array<EnumElement>;
};

export type GetTopCategoriesBySpendInput = {
  selection: SelectionFilter;
};

export type GetTopCategoriesBySpendResponse = {
  __typename: 'GetTopCategoriesBySpendResponse';
  categories: Array<CategoryComparison>;
  field: SpendField;
};

export type GetTopSuppliersComparison = {
  __typename: 'GetTopSuppliersComparison';
  suppliers: Array<SupplierComparison>;
};

export type GetTopSuppliersComparisonInput = {
  selection: SelectionFilter;
};

export type GetTopSuppliersInput = {
  by: SortMetric;
  count: Scalars['Int']['input'];
};

export type GetTopSuppliersResponse = {
  __typename: 'GetTopSuppliersResponse';
  suppliers: Array<Supplier>;
};

export type GetTotalSocialRiskResponse = {
  __typename: 'GetTotalSocialRiskResponse';
  blankRiskShare: Scalars['Float']['output'];
  highRiskShare: Scalars['Float']['output'];
  lowRiskShare: Scalars['Float']['output'];
  mediumRiskShare: Scalars['Float']['output'];
};

export type GetTotalSpendInput = {
  selection: SelectionFilter;
};

export type GetTotalSpendResponse = {
  __typename: 'GetTotalSpendResponse';
  totalSpendCurrentPeriod: Scalars['Float']['output'];
  totalSpendPreviousPeriod: Scalars['Float']['output'];
};

export type GetUniqueIdentifierConfigurationByRepositoryInput = {
  repositoryId: Scalars['String']['input'];
};

export type GetUniqueIdentifierConfigurationByRepositoryResponse = {
  __typename: 'GetUniqueIdentifierConfigurationByRepositoryResponse';
  uniqueIdentifierConfiguration: UniqueIdentifierConfiguration;
};

export type GetUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
};

export type GetUniqueIdentifierConfigurationResponse = {
  __typename: 'GetUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type GetUniqueValuesForSupplierTableColumnInput = {
  columnId: Scalars['String']['input'];
};

export type GetUniqueValuesForSupplierTableColumnResponse = {
  __typename: 'GetUniqueValuesForSupplierTableColumnResponse';
  columnId: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type GetUnmergedSuppliersInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetUnmergedSuppliersInput;
};

export type GetUnmergedSuppliersResponse = {
  __typename: 'GetUnmergedSuppliersResponse';
  total: Scalars['Int']['output'];
  unMergedSuppliersJSON: Array<UnMergedSupplier>;
};

export type GetUsersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  terms?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetUsersResponse = {
  __typename: 'GetUsersResponse';
  result: Array<RoleUser>;
  total: Scalars['Int']['output'];
};

export type GetValuesForFieldInput = {
  elasticIndex: Scalars['String']['input'];
  field: ElasticFieldInput;
  filtersJSON?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  labelField?: InputMaybe<ElasticFieldInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
};

export type GetValuesForFieldResponse = {
  __typename: 'GetValuesForFieldResponse';
  fieldItemsJSON: Scalars['String']['output'];
  idNameMapJSON?: Maybe<Scalars['String']['output']>;
  isMissingValues: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type Group = {
  __typename: 'Group';
  groupStructureId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupByField = {
  field: ProcurementEntityType;
  level?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupNode = {
  __typename: 'GroupNode';
  children: Array<GroupNode>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GroupPredictionInputInput = {
  fieldValuePair: Array<KeyValuePairInput>;
};

export type GroupPredictionOutput = {
  __typename: 'GroupPredictionOutput';
  fieldValuePairs: Array<KeyValuePair>;
  predictedGroup?: Maybe<Group>;
  predictedGroupPath: Array<Scalars['String']['output']>;
};

export type GroupStructure = {
  __typename: 'GroupStructure';
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  predefinedType?: Maybe<GroupStructurePredefinedType>;
};

export type GroupStructurePredefinedType =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupStructurePredefinedTypeInput =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupWithHasChildren = {
  __typename: 'GroupWithHasChildren';
  groupStructureId: Scalars['String']['output'];
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupingConfiguration = {
  __typename: 'GroupingConfiguration';
  dataColumnId: Scalars['String']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type HasConnectedSpendResponse = {
  __typename: 'HasConnectedSpendResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type HierarchyCompany = {
  __typename: 'HierarchyCompany';
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNumber?: Maybe<Scalars['String']['output']>;
};

export type IColumn = {
  globalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ColumnType;
};

export type ISpendOpportunity = {
  id: Scalars['String']['output'];
  status: SpendOpportunityStatus;
  type: SpendOpportunityType;
};

export type ISuggestion = {
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  type: SuggestionType;
};

export type IdKeyPair = {
  __typename: 'IdKeyPair';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
};

export type IdLevelPair = {
  id: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
};

export type Import = {
  __typename: 'Import';
  dataRepositoryId: Scalars['String']['output'];
  file: FileInfo;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importConfigurationId: Scalars['String']['output'];
  importInfo: ImportInfo;
  isBeingDeleted: Scalars['Boolean']['output'];
};

export type ImportConfiguration = {
  __typename: 'ImportConfiguration';
  compressionType?: Maybe<CompressionType>;
  contentType: ContentType;
  csvConfiguration?: Maybe<CsvConfiguration>;
  fieldMappings: Array<ImportFieldMapping>;
  id: Scalars['ID']['output'];
  importType: ImportType;
  name: Scalars['String']['output'];
  targetDataRepositoryId: Scalars['String']['output'];
  xlsxConfiguration?: Maybe<XlsxConfiguration>;
};

export type ImportFieldMapping = {
  __typename: 'ImportFieldMapping';
  dataRepositoryFieldId: Scalars['String']['output'];
  fileFieldKey: Scalars['String']['output'];
};

export type ImportFieldMappingInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  fileFieldKey: Scalars['String']['input'];
};

export type ImportInfo = {
  __typename: 'ImportInfo';
  alreadyExisting: Scalars['Int']['output'];
  ignoredEmpty: Scalars['Int']['output'];
  ignoredMissing: Scalars['Int']['output'];
  importedAt: Scalars['String']['output'];
  importedBy?: Maybe<Scalars['String']['output']>;
  importedRows: Scalars['Int']['output'];
  updatedRows: Scalars['Int']['output'];
};

export type ImportType =
  | 'FILE'
  | 'PUSH_ENDPOINT';

export type ImportTypeInput =
  | 'FILE'
  | 'PUSH_ENDPOINT';

export type IndexDataTableToElasticInput = {
  dataTableId: Scalars['String']['input'];
};

export type IndexDataTableToElasticResponse = {
  __typename: 'IndexDataTableToElasticResponse';
  notificationId: Scalars['String']['output'];
};

export type IndexStatus = {
  __typename: 'IndexStatus';
  tableId: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type InputFieldType =
  | 'AGGREGATION'
  | 'ASSESSMENT'
  | 'BOOLEAN'
  | 'CLASSIFICATION'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NACE'
  | 'NUMBER'
  | 'RISK'
  | 'SELECT'
  | 'SPEND'
  | 'TABLE_RELATION'
  | 'TEXT'
  | 'USER';

export type InputOutputConnection = {
  __typename: 'InputOutputConnection';
  dataPipelineId: Scalars['String']['output'];
  from: InputOutputNode;
  id: Scalars['ID']['output'];
  to: InputOutputNode;
};

export type InputOutputConnectionCreationInputInput = {
  from: InputOutputNodeInput;
  to: InputOutputNodeInput;
};

export type InputOutputNode = {
  __typename: 'InputOutputNode';
  referencedId: Scalars['String']['output'];
  type: InputOutputNodeType;
};

export type InputOutputNodeInput = {
  referencedId: Scalars['String']['input'];
  type: InputOutputNodeTypeInput;
};

export type InputOutputNodeType =
  | 'DATA_REPOSITORY_FIELD'
  | 'DATA_TABLE_COLUMN'
  | 'OPERATION';

export type InputOutputNodeTypeInput =
  | 'DATA_REPOSITORY_FIELD'
  | 'DATA_TABLE_COLUMN'
  | 'OPERATION';

export type InternalInviteeInput = {
  email: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type IntervalResult = {
  __typename: 'IntervalResult';
  intervalLabel: Scalars['String']['output'];
  intervalStart: Scalars['String']['output'];
  results: Array<MetricDevelopmentResult>;
};

export type Invite = {
  __typename: 'Invite';
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expiredAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitedBy: Scalars['String']['output'];
  invitedByDisplayName: Scalars['String']['output'];
  lastSentAt?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  status: InviteStatus;
};

export type InviteStatus =
  | 'COMPLETE'
  | 'EMAIL_SENT'
  | 'EXPIRED'
  | 'PROCESSING';

export type InviteUserInput = {
  email: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InviteUserResponse = {
  __typename: 'InviteUserResponse';
  invite: Invite;
};

export type InviteUsersResponse = {
  __typename: 'InviteUsersResponse';
  alreadyExists: Array<User>;
  invites: Array<Invite>;
};

export type KeyValuePair = {
  __typename: 'KeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LetterCasingOperationAdapter = {
  __typename: 'LetterCasingOperationAdapter';
  letterCasing: Scalars['String']['output'];
};

export type ListAccessRequestsResponse = {
  __typename: 'ListAccessRequestsResponse';
  data: Array<AccessRequest>;
  total: Scalars['Int']['output'];
};

export type ListParserOperationAdapter = {
  __typename: 'ListParserOperationAdapter';
  splitString: Scalars['String']['output'];
};

export type LookupCriterion = {
  __typename: 'LookupCriterion';
  dataRepositoryFieldId: Scalars['String']['output'];
  operationInputId: Scalars['String']['output'];
};

export type LookupFieldOutput = {
  __typename: 'LookupFieldOutput';
  dataRepositoryFieldId: Scalars['String']['output'];
  operationOutputId: Scalars['String']['output'];
};

export type LookupOperationAdapter = {
  __typename: 'LookupOperationAdapter';
  dataRepositoryId: Scalars['String']['output'];
  lookupCriteria: Array<LookupCriterion>;
  lookupFieldOutputs: Array<LookupFieldOutput>;
};

export type MakeAutomaticRuleInput = {
  automaticRule: AutomaticRuleInputInput;
};

export type MakeAutomaticRuleResponse = {
  __typename: 'MakeAutomaticRuleResponse';
  status: Scalars['String']['output'];
};

export type MakeManualRuleInput = {
  createdBy: Scalars['String']['input'];
  mergeFrom: Array<Scalars['String']['input']>;
  mergeTo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatesOnMergeToSupplier?: InputMaybe<Scalars['String']['input']>;
};

export type MakeManualRuleResponse = {
  __typename: 'MakeManualRuleResponse';
  status: Scalars['String']['output'];
};

export type ManualRule = {
  __typename: 'ManualRule';
  id: Scalars['ID']['output'];
  mergeFrom: Array<Scalars['String']['output']>;
  mergeTo: Scalars['String']['output'];
  mergedBy: Scalars['String']['output'];
  mergedOn: Scalars['String']['output'];
  newSupplier: Scalars['String']['output'];
  shouldCreateNew: Scalars['Boolean']['output'];
};

export type MappedFileFieldInput = {
  dataColumnId?: InputMaybe<Scalars['String']['input']>;
  dataColumnName?: InputMaybe<Scalars['String']['input']>;
  dataType: PrimitiveDataColumnTypeInput;
  fieldName: Scalars['String']['input'];
  globalType?: InputMaybe<Scalars['String']['input']>;
  repositoryFieldName?: InputMaybe<Scalars['String']['input']>;
};

export type MappingElement = {
  __typename: 'MappingElement';
  elementId: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type MappingOperationAdapter = {
  __typename: 'MappingOperationAdapter';
  mappingElements: Array<MappingElement>;
};

export type MappingSuggestion = {
  __typename: 'MappingSuggestion';
  columnId: Scalars['String']['output'];
  fileColumn: Scalars['String']['output'];
};

export type MarkSavingsEstimateAsIrrelevantInput = {
  supplierId: Scalars['ID']['input'];
};

export type MathematicalExpressionOperationAdapter = {
  __typename: 'MathematicalExpressionOperationAdapter';
  expressionJson: Scalars['String']['output'];
};

export type MergeGroupInput = {
  groupStructureId: Scalars['String']['input'];
  mergeFromGroupId: Scalars['String']['input'];
  mergeIntoGroupId: Scalars['String']['input'];
};

export type MergeGroupResponse = {
  __typename: 'MergeGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type MergeSelection = {
  __typename: 'MergeSelection';
  mergeIntoId?: Maybe<Scalars['String']['output']>;
  tieBreak: Array<TieBreak>;
  transferData: Scalars['Boolean']['output'];
};

export type MergeSelectionInput = {
  mergeIntoId?: InputMaybe<Scalars['String']['input']>;
  tieBreak: Array<TieBreakInput>;
  transferData: Scalars['Boolean']['input'];
};

export type MergeType =
  | 'automatic'
  | 'manual';

export type MergeTypeInput =
  | 'automatic'
  | 'manual';

export type MetricDevelopmentResult = {
  __typename: 'MetricDevelopmentResult';
  entityId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  metrics: Array<PredefinedMetricResult>;
};

export type MissingConfiguration = {
  __typename: 'MissingConfiguration';
  dataType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MissingSocialRiskScoreSuggestion = ISuggestion & {
  __typename: 'MissingSocialRiskScoreSuggestion';
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  supplier: Supplier;
  type: SuggestionType;
};

export type MoveDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  skipApplyClassification?: InputMaybe<Scalars['Boolean']['input']>;
  targetRuleSetId: Scalars['String']['input'];
};

export type MoveDataColumnRulesResponse = {
  __typename: 'MoveDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type MoveGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveGroupResponse = {
  __typename: 'MoveGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addAccessRequest: AddAccessRequestResponse;
  addDataPipelineSource: AddDataPipelineSourceResponse;
  addDataRepositoryField: AddDataRepositoryFieldResponse;
  addDataTableConnectionCriterion: AddDataTableConnectionCriterionResponse;
  addImportFieldMapping: AddImportFieldMappingResponse;
  addLookupOperationCriteria: AddLookupOperationCriteriaResponse;
  addLookupOperationFieldOutputs: AddLookupOperationFieldOutputsResponse;
  addManyDataRepositoryFields: AddManyDataRepositoryFieldsResponse;
  addManyImportFieldMappings: AddManyImportFieldMappingsResponse;
  addNoteReaction: AddNoteReactionResponse;
  addOrRemoveFieldsFromDataCubeConfiguration: AddOrRemoveFieldsFromDataCubeConfigurationResponse;
  addSelectColumnChoice: AddSelectColumnChoiceResponse;
  addSuppliersToExternalCampaign: AddSuppliersToExternalCampaignResponse;
  addTagValueToColumn: AddTagValueToColumnResponse;
  applyChanges: ApplyChangesResponse;
  applyDataTableConnectionChanges: ApplyDataTableConnectionChangesResponse;
  approveSupplier: ApproveSupplierResponse;
  checkNewDataColumnRules: CheckNewDataColumnRulesResponse;
  completeSetup: CompleteSetupResponse;
  createAggregatedDataColumn: CreateAggregatedDataColumnResponse;
  createAggregatedView: CreateAggregatedViewResponse;
  createAssessmentColumns: CreateAssessmentColumnsResponse;
  createAssessmentsDownloadURL: CreateAssessmentsDownloadUrlResponse;
  createAssessmentsUploadURL: CreateAssessmentsUploadUrlResponse;
  createCompanySettings: CreateCompanySettingsResponse;
  createContactsFromColumns: CreateContactsFromColumnsResponse;
  createConvertCountryDataPipelineOperation: CreateConvertCountryDataPipelineOperationResponse;
  createCustomer: CreateCustomerResponse;
  createDataColumn: CreateDataColumnResponse;
  createDataColumnRuleSetFromGroupStructure: CreateDataColumnRuleSetFromGroupStructureResponse;
  createDataColumns: CreateDataColumnsResponse;
  createDataPipeline: CreateDataPipelineResponse;
  createDataRepository: CreateDataRepositoryResponse;
  createDataRepositoryCollection: CreateDataRepositoryCollectionResponse;
  createDataRow: CreateDataRowResponse;
  createDataTable: CreateDataTableResponse;
  createDataTableCollection: CreateDataTableCollectionResponse;
  createDataTableConnection: CreateDataTableConnectionResponse;
  createDateParserDataPipelineOperation: CreateDateParserDataPipelineOperationResponse;
  createDownloadUrls: CreateDownloadUrlsResponse;
  createEmissionsSetup?: Maybe<EmissionsSetupResponse>;
  createEmptyCampaign: CreateEmptyCampaignResponse;
  createExternalCampaign: CreateExternalCampaignResponse;
  createFillBlanksDataPipelineOperation: CreateFillBlanksDataPipelineOperationResponse;
  createFilterCombination: CreateFilterCombinationResponse;
  createFilterFieldMapping: CreateFilterFieldMappingResponse;
  createGetOrCreateDataPipelineOperation: CreateGetOrCreateDataPipelineOperationResponse;
  createGroup: CreateGroupResponse;
  createGroupStructure: CreateGroupStructureResponse;
  createGroupStructureFromUploadFile: CreateGroupStructureFromUploadFileResponse;
  createImportConfiguration: CreateImportConfigurationResponse;
  createInputOutputConnection: CreateInputOutputConnectionResponse;
  createIntegration: CreateIntegrationResponse;
  createInternalCampaign: CreateInternalCampaignResponse;
  createKeywordTaggerDataPipelineOperation: CreateKeywordTaggerDataPipelineOperationResponse;
  createLetterCasingDataPipelineOperation: CreateLetterCasingDataPipelineOperationResponse;
  createListParserDataPipelineOperation: CreateListParserDataPipelineOperationResponse;
  createLookupDataPipelineOperation: CreateLookupDataPipelineOperationResponse;
  createManyInputOutputConnections: CreateManyInputOutputConnectionsResponse;
  createMappingDataPipelineOperation: CreateMappingDataPipelineOperationResponse;
  createMatchCompanyDataPipelineOperation: CreateMatchCompanyDataPipelineOperationResponse;
  createMathematicalExpressionDataPipelineOperation: CreateMathematicalExpressionDataPipelineOperationResponse;
  createMonetaryAmountDataPipelineOperation: CreateMonetaryAmountDataPipelineOperationResponse;
  createPipelineFilter: CreatePipelineFilterResponse;
  createPipelineFilterCombination: CreatePipelineFilterCombinationResponse;
  createQuestion: CreateQuestionResponse;
  createQuestionnaire: CreateQuestionnaireResponse;
  createRegexDataPipelineOperation: CreateRegexDataPipelineOperationResponse;
  createReplaceDataPipelineOperation: CreateReplaceDataPipelineOperationResponse;
  createRepositoryRowDeleteQuery: CreateRepositoryRowDeleteQueryResponse;
  createRules: CreateRulesResponse;
  createRulesV2: CreateRulesV2Response;
  createSection: CreateSectionResponse;
  createSpendColumn: CreateSpendColumnResponse;
  createSplitDataPipelineOperation: CreateSplitDataPipelineOperationResponse;
  createSupplier: CreateSupplierResponse;
  createSupplierFileDownloadUrl: CreateSupplierFileDownloadUrlResponse;
  createSupplierFileUploadUrl: CreateSupplierFileUploadUrlResponse;
  createSupplierNote: CreateSupplierNoteResponse;
  createSupplierTableColumn: CreateSupplierTableColumnResponse;
  createSupplierTableConfig: CreateSupplierTableConfigResponse;
  createTagColumn: CreateTagColumnResponse;
  createTask: CreateTaskResponse;
  createTemplateStringDataPipelineOperation: CreateTemplateStringDataPipelineOperationResponse;
  createTranslationDataPipelineOperation: CreateTranslationDataPipelineOperationResponse;
  createUniqueIdentifierConfiguration: CreateUniqueIdentifierConfigurationResponse;
  createUploadUrls: CreateUploadUrlsResponse;
  deleteAccessRequest: AccessRequest;
  deleteAssessment: DeleteAssessmentResponse;
  deleteAssessmentsFile: DeleteAssessmentsFileResponse;
  deleteAutomaticRule: DeleteAutomaticRuleResponse;
  deleteCampaign: DeleteCampaignResponse;
  deleteCompanySettings: DeleteCompanySettingsResponse;
  deleteContacts: DeleteContactsResponse;
  deleteCustomer: DeleteCustomerResponse;
  deleteDataColumnRuleSets: DeleteDataColumnRuleSetsResponse;
  deleteDataColumnRules: DeleteDataColumnRulesResponse;
  deleteDataColumns: DeleteDataColumnsResponse;
  deleteDataPipeline: DeleteDataPipelineResponse;
  deleteDataPipelineOperation: DeleteDataPipelineOperationResponse;
  deleteDataRepository: DeleteDataRepositoryResponse;
  deleteDataRepositoryCollection: DeleteDataRepositoryCollectionResponse;
  deleteDataRow: DeleteDataRowResponse;
  deleteDataTable: DeleteDataTableResponse;
  deleteDataTableCollection: DeleteDataTableCollectionResponse;
  deleteDataTableConnection: DeleteDataTableConnectionResponse;
  deleteFiles: DeleteFilesResponse;
  deleteFilterCombination: DeleteFilterCombinationResponse;
  deleteFilterFieldMapping: DeleteFilterFieldMappingResponse;
  deleteGroup: DeleteGroupResponse;
  deleteImportConfiguration: DeleteImportConfigurationResponse;
  deleteImports: DeleteImportsResponse;
  deleteInputOutputConnection: DeleteInputOutputConnectionResponse;
  deleteInvites: DeleteInvitesResponse;
  deleteManualRule: DeleteManualRuleResponse;
  deletePipelineFilter: DeletePipelineFilterResponse;
  deletePipelineFilterCombination: DeletePipelineFilterCombinationResponse;
  deleteQuestion: DeleteQuestionResponse;
  deleteQuestionnaires: DeleteQuestionnairesResponse;
  deleteRepositoryRowDeleteQuery: DeleteRepositoryRowDeleteQueryResponse;
  deleteRoles: DeleteRolesResponse;
  deleteRowsByDeleteQuery: DeleteRowsByDeleteQueryResponse;
  deleteSection: DeleteSectionResponse;
  deleteSelectColumnChoice: DeleteSelectColumnChoiceResponse;
  deleteSupplierFile: DeleteSupplierFileResponse;
  deleteSupplierNote: DeleteSupplierNoteResponse;
  deleteSupplierTableColumn: DeleteSupplierTableColumnResponse;
  deleteSupplierTableConfig: DeleteSupplierTableConfigResponse;
  deleteSuppliers: DeleteSuppliersResponse;
  deleteTagValueByValue: DeleteTagValueByValueResponse;
  deleteTask: DeleteTaskResponse;
  deleteUniqueIdentifierConfiguration: DeleteUniqueIdentifierConfigurationResponse;
  editRoles: EditRolesResponse;
  ensureSupplierTableSpendColumns: EnsureSupplierTableSpendColumnsResponse;
  ensureValidSupplierUploadState: EnsureValidSupplierUploadStateResponse;
  exportDataTableToFile: ExportDataTableToFileResponse;
  findBlankCellsInColumn: FindBlankCellsInColumnResponse;
  findDuplicatesInFile: FindDuplicatesInFileResponse;
  grantSupportAccess: SupportConsent;
  indexDataTableToElastic: IndexDataTableToElasticResponse;
  inviteUser: InviteUserResponse;
  inviteUsers: InviteUsersResponse;
  makeAutomaticRule: MakeAutomaticRuleResponse;
  makeManualRule: MakeManualRuleResponse;
  markSavingsEstimateAsIrrelevant: SavingsEstimate;
  mergeGroup: MergeGroupResponse;
  moveDataColumnRules: MoveDataColumnRulesResponse;
  moveGroup: MoveGroupResponse;
  optimizeDataTable: OptimizeDataTableResponse;
  parseFile: ParseFileResponse;
  parseFileSamples: ParseFileSamplesResponse;
  reapplyDataColumnRules: ReapplyDataColumnRulesResponse;
  reapplyDataTableConnections: ReapplyDataTableConnectionsResponse;
  removeDataPipelineSource: RemoveDataPipelineSourceResponse;
  removeDataTableConnectionCriterion: RemoveDataTableConnectionCriterionResponse;
  removeGhostRows: RemoveGhostRowsResponse;
  removeLookupOperationCriteria: RemoveLookupOperationCriteriaResponse;
  removeLookupOperationFieldOutputs: RemoveLookupOperationFieldOutputsResponse;
  removeNoteReaction: RemoveNoteReactionResponse;
  removeUsers: RemoveUsersResponse;
  reorderDataColumnRuleSets: ReorderDataColumnRuleSetsResponse;
  reorderQuestion: ReorderQuestionResponse;
  reorderSection: ReorderSectionResponse;
  replaceDataColumnWithNewDataType: ReplaceDataColumnWithNewDataTypeResponse;
  requestSpendSetup: RequestSpendSetupResponse;
  resendInvites: ResendInvitesResponse;
  revokeAccessRequest: AccessRequest;
  revokeSupportAccess: SupportConsent;
  saveCategory: SaveCategoryResponse;
  sendCampaignInvitations: SendCampaignInvitationsResponse;
  sendCampaignReminders: SendCampaignRemindersResponse;
  sendFeedback: SendFeedbackResponse;
  setActiveTenant: SetActiveTenantResponse;
  setQuestionScoring: SetQuestionScoringResponse;
  setSpendDataConfigurations?: Maybe<SetSpendDataConfigurationsResponse>;
  setSuppliersToOnboarding: SetSuppliersToOnboardingResponse;
  setUserRoles: SetUserRolesResponse;
  setupSupplierTable: SetupSupplierTableResponse;
  startAggregatedView: StartAggregatedViewResponse;
  startAutofillGroupStructure: StartAutofillGroupStructureResponse;
  startDataPipelinesByDataTableId: StartDataPipelinesByDataTableIdResponse;
  startDefaultSetup: StartDefaultSetupResponse;
  startFileImportByFileId: StartFileImportByFileIdResponse;
  startFindConflictingRulesForUpload: StartFindConflictingRulesForUploadResponse;
  startFindDanglingRulesTask: StartFindDanglingRulesTaskResponse;
  startPartialDataPipeline: StartPartialDataPipelineResponse;
  startSocialRiskAssessment: StartSocialRiskAssessmentResponse;
  startSqlImport: StartSqlImportResponse;
  startTestDataPipeline: StartTestDataPipelineResponse;
  testDataTableConnection: TestDataTableConnectionResponse;
  unsaveCategory: UnsaveCategoryResponse;
  updateAggregatedView: UpdateAggregatedViewResponse;
  updateAssessmentContact: UpdateAssessmentContactResponse;
  updateAssessmentsFileMetadata: UpdateAssessmentsFileMetadataResponse;
  updateAutomaticRule: UpdateAutomaticRuleResponse;
  updateCampaign: UpdateCampaignResponse;
  updateCompanySettings: UpdateCompanySettingsResponse;
  updateCustomer: UpdateCustomerResponse;
  updateDataColumn: UpdateDataColumnResponse;
  updateDataColumnRuleSet: UpdateDataColumnRuleSetResponse;
  updateDataColumnRules: UpdateDataColumnRulesResponse;
  updateDataPipelineOperation: UpdateDataPipelineOperationResponse;
  updateDataRepository: UpdateDataRepositoryResponse;
  updateDataRepositoryCollection: UpdateDataRepositoryCollectionResponse;
  updateDataTable: UpdateDataTableResponse;
  updateDataTableCollection: UpdateDataTableCollectionResponse;
  updateDataTableCollectionOrder: UpdateDataTableCollectionOrderResponse;
  updateDataTableRow: UpdateDataTableRowResponse;
  updateFilesUploadStatus: UpdateFilesUploadStatusResponse;
  updateFilterCombination: UpdateFilterCombinationResponse;
  updateFilterFieldMapping: UpdateFilterFieldMappingResponse;
  updateGroup: UpdateGroupResponse;
  updateGroupStructure: UpdateGroupStructureResponse;
  updateImportConfiguration: UpdateImportConfigurationResponse;
  updateManualMergeAllSuppliers: UpdateManualMergeAllSuppliersResponse;
  updateMappingOperationElement: UpdateMappingOperationElementResponse;
  updateOrCreateDataPipelineLayout: UpdateOrCreateDataPipelineLayoutResponse;
  updatePipelineFilter: UpdatePipelineFilterResponse;
  updateQuestion: UpdateQuestionResponse;
  updateQuestionnaire: UpdateQuestionnaireResponse;
  updateSavingsEstimateStatus: SavingsEstimate;
  updateSection: UpdateSectionResponse;
  updateSuggestionStatus: UpdateSuggestionStatusResponse;
  updateSupplierField: UpdateSupplierFieldResponse;
  updateSupplierFileMetadata: UpdateSupplierFileMetadataResponse;
  updateSupplierNote: UpdateSupplierNoteResponse;
  updateSupplierTableColumn: UpdateSupplierTableColumnResponse;
  updateSupplierTableConfig: UpdateSupplierTableConfigResponse;
  updateSuppliers: UpdateSuppliersResponse;
  updateTask: UpdateTaskResponse;
  updateUnMergeAllSuppliers: UpdateUnMergeAllSuppliersResponse;
  updateUnMergeSuppliers: UpdateUnMergeSuppliersResponse;
  updateUniqueIdentifierConfiguration: UpdateUniqueIdentifierConfigurationResponse;
  uploadRules: UploadRulesResponse;
  upsertCompanyFavouriteFilters: UpsertCompanyFavouriteFiltersResponse;
  upsertContacts: UpsertContactsResponse;
  upsertDefaultFilterCombination: UpsertDefaultFilterCombinationResponse;
  upsertFilteredSpendOpportunity: UpsertFilteredSpendOpportunityResponse;
  upsertPersonalFavouriteFilters: UpsertPersonalFavouriteFiltersResponse;
  upsertPinnedFields: PinnedFieldsResponse;
  upsertRoles: UpsertRolesResponse;
  validateFile: ValidateFileResponse;
  whoAmI: WhoAmIResponse;
};


export type MutationAddAccessRequestArgs = {
  input: AddAccessRequestInput;
};


export type MutationAddDataPipelineSourceArgs = {
  input: AddDataPipelineSourceInput;
};


export type MutationAddDataRepositoryFieldArgs = {
  input: AddDataRepositoryFieldInput;
};


export type MutationAddDataTableConnectionCriterionArgs = {
  input: AddDataTableConnectionCriterionInput;
};


export type MutationAddImportFieldMappingArgs = {
  input: AddImportFieldMappingInput;
};


export type MutationAddLookupOperationCriteriaArgs = {
  input: AddLookupOperationCriteriaInput;
};


export type MutationAddLookupOperationFieldOutputsArgs = {
  input: AddLookupOperationFieldOutputsInput;
};


export type MutationAddManyDataRepositoryFieldsArgs = {
  input: AddManyDataRepositoryFieldsInput;
};


export type MutationAddManyImportFieldMappingsArgs = {
  input: AddManyImportFieldMappingsInput;
};


export type MutationAddNoteReactionArgs = {
  input: AddNoteReactionInput;
};


export type MutationAddOrRemoveFieldsFromDataCubeConfigurationArgs = {
  input: AddOrRemoveFieldsFromDataCubeConfigurationInput;
};


export type MutationAddSelectColumnChoiceArgs = {
  input: AddSelectColumnChoiceInput;
};


export type MutationAddSuppliersToExternalCampaignArgs = {
  input: AddSuppliersToExternalCampaignInput;
};


export type MutationAddTagValueToColumnArgs = {
  input: AddTagValueToColumnInput;
};


export type MutationApplyChangesArgs = {
  input: ApplyChangesInput;
};


export type MutationApplyDataTableConnectionChangesArgs = {
  input: ApplyDataTableConnectionChangesInput;
};


export type MutationApproveSupplierArgs = {
  input: ApproveSupplierInput;
};


export type MutationCheckNewDataColumnRulesArgs = {
  input: CheckNewDataColumnRulesInput;
};


export type MutationCompleteSetupArgs = {
  input: CompleteSetupInput;
};


export type MutationCreateAggregatedDataColumnArgs = {
  input: CreateAggregatedDataColumnInput;
};


export type MutationCreateAggregatedViewArgs = {
  input: CreateAggregatedViewInput;
};


export type MutationCreateAssessmentColumnsArgs = {
  input: CreateAssessmentColumnsInput;
};


export type MutationCreateAssessmentsDownloadUrlArgs = {
  input: CreateAssessmentsDownloadUrlInput;
};


export type MutationCreateAssessmentsUploadUrlArgs = {
  input: CreateAssessmentsUploadUrlInput;
};


export type MutationCreateCompanySettingsArgs = {
  input: CreateCompanySettingsInput;
};


export type MutationCreateContactsFromColumnsArgs = {
  input: CreateContactsFromColumnsInput;
};


export type MutationCreateConvertCountryDataPipelineOperationArgs = {
  input: CreateConvertCountryDataPipelineOperationInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateDataColumnArgs = {
  input: CreateDataColumnInput;
};


export type MutationCreateDataColumnRuleSetFromGroupStructureArgs = {
  input: CreateDataColumnRuleSetFromGroupStructureInput;
};


export type MutationCreateDataColumnsArgs = {
  input: CreateDataColumnsInput;
};


export type MutationCreateDataPipelineArgs = {
  input: CreateDataPipelineInput;
};


export type MutationCreateDataRepositoryArgs = {
  input: CreateDataRepositoryInput;
};


export type MutationCreateDataRepositoryCollectionArgs = {
  input: CreateDataRepositoryCollectionInput;
};


export type MutationCreateDataRowArgs = {
  input: CreateDataRowInput;
};


export type MutationCreateDataTableArgs = {
  input: CreateDataTableInput;
};


export type MutationCreateDataTableCollectionArgs = {
  input: CreateDataTableCollectionInput;
};


export type MutationCreateDataTableConnectionArgs = {
  input: CreateDataTableConnectionInput;
};


export type MutationCreateDateParserDataPipelineOperationArgs = {
  input: CreateDateParserDataPipelineOperationInput;
};


export type MutationCreateDownloadUrlsArgs = {
  input: CreateDownloadUrlsInput;
};


export type MutationCreateEmissionsSetupArgs = {
  input: EmissionsSetupInput;
};


export type MutationCreateEmptyCampaignArgs = {
  input: CreateEmptyCampaignInput;
};


export type MutationCreateExternalCampaignArgs = {
  input: CreateExternalCampaignInput;
};


export type MutationCreateFillBlanksDataPipelineOperationArgs = {
  input: CreateFillBlanksDataPipelineOperationInput;
};


export type MutationCreateFilterCombinationArgs = {
  input: CreateFilterCombinationInput;
};


export type MutationCreateFilterFieldMappingArgs = {
  input: CreateFilterFieldMappingInput;
};


export type MutationCreateGetOrCreateDataPipelineOperationArgs = {
  input: CreateGetOrCreateDataPipelineOperationInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateGroupStructureArgs = {
  input: CreateGroupStructureInput;
};


export type MutationCreateGroupStructureFromUploadFileArgs = {
  input: CreateGroupStructureFromUploadFileInput;
};


export type MutationCreateImportConfigurationArgs = {
  input: CreateImportConfigurationInput;
};


export type MutationCreateInputOutputConnectionArgs = {
  input: CreateInputOutputConnectionInput;
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};


export type MutationCreateInternalCampaignArgs = {
  input: CreateInternalCampaignInput;
};


export type MutationCreateKeywordTaggerDataPipelineOperationArgs = {
  input: CreateKeywordTaggerDataPipelineOperationInput;
};


export type MutationCreateLetterCasingDataPipelineOperationArgs = {
  input: CreateLetterCasingDataPipelineOperationInput;
};


export type MutationCreateListParserDataPipelineOperationArgs = {
  input: CreateListParserDataPipelineOperationInput;
};


export type MutationCreateLookupDataPipelineOperationArgs = {
  input: CreateLookupDataPipelineOperationInput;
};


export type MutationCreateManyInputOutputConnectionsArgs = {
  input: CreateManyInputOutputConnectionsInput;
};


export type MutationCreateMappingDataPipelineOperationArgs = {
  input: CreateMappingDataPipelineOperationInput;
};


export type MutationCreateMatchCompanyDataPipelineOperationArgs = {
  input: CreateMatchCompanyDataPipelineOperationInput;
};


export type MutationCreateMathematicalExpressionDataPipelineOperationArgs = {
  input: CreateMathematicalExpressionDataPipelineOperationInput;
};


export type MutationCreateMonetaryAmountDataPipelineOperationArgs = {
  input: CreateMonetaryAmountDataPipelineOperationInput;
};


export type MutationCreatePipelineFilterArgs = {
  input: CreatePipelineFilterInput;
};


export type MutationCreatePipelineFilterCombinationArgs = {
  input: CreatePipelineFilterCombinationInput;
};


export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationCreateRegexDataPipelineOperationArgs = {
  input: CreateRegexDataPipelineOperationInput;
};


export type MutationCreateReplaceDataPipelineOperationArgs = {
  input: CreateReplaceDataPipelineOperationInput;
};


export type MutationCreateRepositoryRowDeleteQueryArgs = {
  input: CreateRepositoryRowDeleteQueryInput;
};


export type MutationCreateRulesArgs = {
  input: CreateRulesInput;
};


export type MutationCreateRulesV2Args = {
  input: CreateRulesV2Input;
};


export type MutationCreateSectionArgs = {
  input: CreateSectionInput;
};


export type MutationCreateSpendColumnArgs = {
  input: CreateSpendColumnInput;
};


export type MutationCreateSplitDataPipelineOperationArgs = {
  input: CreateSplitDataPipelineOperationInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateSupplierFileDownloadUrlArgs = {
  input: CreateSupplierFileDownloadUrlInput;
};


export type MutationCreateSupplierFileUploadUrlArgs = {
  input: CreateSupplierFileUploadUrlInput;
};


export type MutationCreateSupplierNoteArgs = {
  input: CreateSupplierNoteInput;
};


export type MutationCreateSupplierTableColumnArgs = {
  input: CreateSupplierTableColumnInput;
};


export type MutationCreateSupplierTableConfigArgs = {
  input: CreateSupplierTableConfigInput;
};


export type MutationCreateTagColumnArgs = {
  input: CreateTagColumnInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTemplateStringDataPipelineOperationArgs = {
  input: CreateTemplateStringDataPipelineOperationInput;
};


export type MutationCreateTranslationDataPipelineOperationArgs = {
  input: CreateTranslationDataPipelineOperationInput;
};


export type MutationCreateUniqueIdentifierConfigurationArgs = {
  input: CreateUniqueIdentifierConfigurationInput;
};


export type MutationCreateUploadUrlsArgs = {
  input: CreateUploadUrlsInput;
};


export type MutationDeleteAccessRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAssessmentArgs = {
  input: DeleteAssessmentInput;
};


export type MutationDeleteAssessmentsFileArgs = {
  input: DeleteAssessmentsFileInput;
};


export type MutationDeleteAutomaticRuleArgs = {
  input: DeleteAutomaticRuleInput;
};


export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


export type MutationDeleteCompanySettingsArgs = {
  input: DeleteCompanySettingsInput;
};


export type MutationDeleteContactsArgs = {
  input: DeleteContactsInput;
};


export type MutationDeleteDataColumnRuleSetsArgs = {
  input: DeleteDataColumnRuleSetsInput;
};


export type MutationDeleteDataColumnRulesArgs = {
  input: DeleteDataColumnRulesInput;
};


export type MutationDeleteDataColumnsArgs = {
  input: DeleteDataColumnsInput;
};


export type MutationDeleteDataPipelineArgs = {
  input: DeleteDataPipelineInput;
};


export type MutationDeleteDataPipelineOperationArgs = {
  input: DeleteDataPipelineOperationInput;
};


export type MutationDeleteDataRepositoryArgs = {
  input: DeleteDataRepositoryInput;
};


export type MutationDeleteDataRepositoryCollectionArgs = {
  input: DeleteDataRepositoryCollectionInput;
};


export type MutationDeleteDataRowArgs = {
  input: DeleteDataRowInput;
};


export type MutationDeleteDataTableArgs = {
  input: DeleteDataTableInput;
};


export type MutationDeleteDataTableCollectionArgs = {
  input: DeleteDataTableCollectionInput;
};


export type MutationDeleteDataTableConnectionArgs = {
  input: DeleteDataTableConnectionInput;
};


export type MutationDeleteFilesArgs = {
  input: DeleteFilesInput;
};


export type MutationDeleteFilterCombinationArgs = {
  input: DeleteFilterCombinationInput;
};


export type MutationDeleteFilterFieldMappingArgs = {
  input: DeleteFilterFieldMappingInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteImportConfigurationArgs = {
  input: DeleteImportConfigurationInput;
};


export type MutationDeleteImportsArgs = {
  input: DeleteImportsInput;
};


export type MutationDeleteInputOutputConnectionArgs = {
  input: DeleteInputOutputConnectionInput;
};


export type MutationDeleteInvitesArgs = {
  input: DeleteInvitesInput;
};


export type MutationDeleteManualRuleArgs = {
  input: DeleteManualRuleInput;
};


export type MutationDeletePipelineFilterArgs = {
  input: DeletePipelineFilterInput;
};


export type MutationDeletePipelineFilterCombinationArgs = {
  input: DeletePipelineFilterCombinationInput;
};


export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};


export type MutationDeleteQuestionnairesArgs = {
  input: DeleteQuestionnairesInput;
};


export type MutationDeleteRepositoryRowDeleteQueryArgs = {
  input: DeleteRepositoryRowDeleteQueryInput;
};


export type MutationDeleteRolesArgs = {
  input: DeleteRolesInput;
};


export type MutationDeleteRowsByDeleteQueryArgs = {
  input: DeleteRowsByDeleteQueryInput;
};


export type MutationDeleteSectionArgs = {
  input: DeleteSectionInput;
};


export type MutationDeleteSelectColumnChoiceArgs = {
  input: DeleteSelectColumnChoiceInput;
};


export type MutationDeleteSupplierFileArgs = {
  input: DeleteSupplierFileInput;
};


export type MutationDeleteSupplierNoteArgs = {
  input: DeleteSupplierNoteInput;
};


export type MutationDeleteSupplierTableColumnArgs = {
  input: DeleteSupplierTableColumnInput;
};


export type MutationDeleteSupplierTableConfigArgs = {
  input: DeleteSupplierTableConfigInput;
};


export type MutationDeleteSuppliersArgs = {
  input: DeleteSuppliersInput;
};


export type MutationDeleteTagValueByValueArgs = {
  input: DeleteTagValueByValueInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};


export type MutationDeleteUniqueIdentifierConfigurationArgs = {
  input: DeleteUniqueIdentifierConfigurationInput;
};


export type MutationEditRolesArgs = {
  input: EditRolesInput;
};


export type MutationEnsureSupplierTableSpendColumnsArgs = {
  input: EnsureSupplierTableSpendColumnsInput;
};


export type MutationEnsureValidSupplierUploadStateArgs = {
  input: EnsureValidSupplierUploadStateInput;
};


export type MutationExportDataTableToFileArgs = {
  input: ExportDataTableToFileInput;
};


export type MutationFindBlankCellsInColumnArgs = {
  input: FindBlankCellsInColumnInput;
};


export type MutationFindDuplicatesInFileArgs = {
  input: FindDuplicatesInFileInput;
};


export type MutationGrantSupportAccessArgs = {
  validHours?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationIndexDataTableToElasticArgs = {
  input: IndexDataTableToElasticInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationInviteUsersArgs = {
  emails: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};


export type MutationMakeAutomaticRuleArgs = {
  input: MakeAutomaticRuleInput;
};


export type MutationMakeManualRuleArgs = {
  input: MakeManualRuleInput;
};


export type MutationMarkSavingsEstimateAsIrrelevantArgs = {
  input: MarkSavingsEstimateAsIrrelevantInput;
};


export type MutationMergeGroupArgs = {
  input: MergeGroupInput;
};


export type MutationMoveDataColumnRulesArgs = {
  input: MoveDataColumnRulesInput;
};


export type MutationMoveGroupArgs = {
  input: MoveGroupInput;
};


export type MutationOptimizeDataTableArgs = {
  input: OptimizeDataTableInput;
};


export type MutationParseFileArgs = {
  input: ParseFileInput;
};


export type MutationParseFileSamplesArgs = {
  input: ParseFileSamplesInput;
};


export type MutationReapplyDataColumnRulesArgs = {
  input: ReapplyDataColumnRulesInput;
};


export type MutationReapplyDataTableConnectionsArgs = {
  input: ReapplyDataTableConnectionsInput;
};


export type MutationRemoveDataPipelineSourceArgs = {
  input: RemoveDataPipelineSourceInput;
};


export type MutationRemoveDataTableConnectionCriterionArgs = {
  input: RemoveDataTableConnectionCriterionInput;
};


export type MutationRemoveGhostRowsArgs = {
  input: RemoveGhostRowsInput;
};


export type MutationRemoveLookupOperationCriteriaArgs = {
  input: RemoveLookupOperationCriteriaInput;
};


export type MutationRemoveLookupOperationFieldOutputsArgs = {
  input: RemoveLookupOperationFieldOutputsInput;
};


export type MutationRemoveNoteReactionArgs = {
  input: RemoveNoteReactionInput;
};


export type MutationRemoveUsersArgs = {
  input: RemoveUsersInput;
};


export type MutationReorderDataColumnRuleSetsArgs = {
  input: ReorderDataColumnRuleSetsInput;
};


export type MutationReorderQuestionArgs = {
  input: ReorderQuestionInput;
};


export type MutationReorderSectionArgs = {
  input: ReorderSectionInput;
};


export type MutationReplaceDataColumnWithNewDataTypeArgs = {
  input: ReplaceDataColumnWithNewDataTypeInput;
};


export type MutationResendInvitesArgs = {
  input: ResendInvitesInput;
};


export type MutationRevokeAccessRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationSaveCategoryArgs = {
  input: SaveCategoryInput;
};


export type MutationSendCampaignInvitationsArgs = {
  input: SendCampaignInvitationsInput;
};


export type MutationSendCampaignRemindersArgs = {
  input: SendCampaignRemindersInput;
};


export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};


export type MutationSetActiveTenantArgs = {
  input: SetActiveTenantInput;
};


export type MutationSetQuestionScoringArgs = {
  input: SetQuestionScoringInput;
};


export type MutationSetSpendDataConfigurationsArgs = {
  input: SetSpendDataConfigurationsInput;
};


export type MutationSetSuppliersToOnboardingArgs = {
  input: SetSuppliersToOnboardingInput;
};


export type MutationSetUserRolesArgs = {
  input: SetUserRolesInput;
};


export type MutationSetupSupplierTableArgs = {
  input: SetupSupplierTableInput;
};


export type MutationStartAggregatedViewArgs = {
  input: StartAggregatedViewInput;
};


export type MutationStartAutofillGroupStructureArgs = {
  input: StartAutofillGroupStructureInput;
};


export type MutationStartDataPipelinesByDataTableIdArgs = {
  input: StartDataPipelinesByDataTableIdInput;
};


export type MutationStartDefaultSetupArgs = {
  input: StartDefaultSetupInput;
};


export type MutationStartFileImportByFileIdArgs = {
  input: StartFileImportByFileIdInput;
};


export type MutationStartFindConflictingRulesForUploadArgs = {
  input: StartFindConflictingRulesForUploadInput;
};


export type MutationStartFindDanglingRulesTaskArgs = {
  input: StartFindDanglingRulesTaskInput;
};


export type MutationStartPartialDataPipelineArgs = {
  input: StartPartialDataPipelineInput;
};


export type MutationStartSocialRiskAssessmentArgs = {
  input: StartSocialRiskAssessmentInput;
};


export type MutationStartSqlImportArgs = {
  input: StartSqlImportInput;
};


export type MutationStartTestDataPipelineArgs = {
  input: StartTestDataPipelineInput;
};


export type MutationTestDataTableConnectionArgs = {
  input: TestDataTableConnectionInput;
};


export type MutationUnsaveCategoryArgs = {
  input: UnsaveCategoryInput;
};


export type MutationUpdateAggregatedViewArgs = {
  input: UpdateAggregatedViewInput;
};


export type MutationUpdateAssessmentContactArgs = {
  input: UpdateAssessmentContactInput;
};


export type MutationUpdateAssessmentsFileMetadataArgs = {
  input: UpdateAssessmentsFileMetadataInput;
};


export type MutationUpdateAutomaticRuleArgs = {
  input: UpdateAutomaticRuleInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCompanySettingsArgs = {
  input: UpdateCompanySettingsInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateDataColumnArgs = {
  input: UpdateDataColumnInput;
};


export type MutationUpdateDataColumnRuleSetArgs = {
  input: UpdateDataColumnRuleSetInput;
};


export type MutationUpdateDataColumnRulesArgs = {
  input: UpdateDataColumnRulesInput;
};


export type MutationUpdateDataPipelineOperationArgs = {
  input: UpdateDataPipelineOperationInput;
};


export type MutationUpdateDataRepositoryArgs = {
  input: UpdateDataRepositoryInput;
};


export type MutationUpdateDataRepositoryCollectionArgs = {
  input: UpdateDataRepositoryCollectionInput;
};


export type MutationUpdateDataTableArgs = {
  input: UpdateDataTableInput;
};


export type MutationUpdateDataTableCollectionArgs = {
  input: UpdateDataTableCollectionInput;
};


export type MutationUpdateDataTableCollectionOrderArgs = {
  input: UpdateDataTableCollectionOrderInput;
};


export type MutationUpdateDataTableRowArgs = {
  input: UpdateDataTableRowInput;
};


export type MutationUpdateFilesUploadStatusArgs = {
  input: UpdateFilesUploadStatusInput;
};


export type MutationUpdateFilterCombinationArgs = {
  input: UpdateFilterCombinationInput;
};


export type MutationUpdateFilterFieldMappingArgs = {
  input: UpdateFilterFieldMappingInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateGroupStructureArgs = {
  input: UpdateGroupStructureInput;
};


export type MutationUpdateImportConfigurationArgs = {
  input: UpdateImportConfigurationInput;
};


export type MutationUpdateMappingOperationElementArgs = {
  input: UpdateMappingOperationElementInput;
};


export type MutationUpdateOrCreateDataPipelineLayoutArgs = {
  input: UpdateOrCreateDataPipelineLayoutInput;
};


export type MutationUpdatePipelineFilterArgs = {
  input: UpdatePipelineFilterInput;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationUpdateQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};


export type MutationUpdateSavingsEstimateStatusArgs = {
  input: UpdateSavingsEstimateStatusInput;
};


export type MutationUpdateSectionArgs = {
  input: UpdateSectionInput;
};


export type MutationUpdateSuggestionStatusArgs = {
  input: UpdateSuggestionStatusInput;
};


export type MutationUpdateSupplierFieldArgs = {
  input: UpdateSupplierFieldInput;
};


export type MutationUpdateSupplierFileMetadataArgs = {
  input: UpdateSupplierFileMetadataInput;
};


export type MutationUpdateSupplierNoteArgs = {
  input: UpdateSupplierNoteInput;
};


export type MutationUpdateSupplierTableColumnArgs = {
  input: UpdateSupplierTableColumnInput;
};


export type MutationUpdateSupplierTableConfigArgs = {
  input: UpdateSupplierTableConfigInput;
};


export type MutationUpdateSuppliersArgs = {
  input: UpdateSuppliersInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateUnMergeSuppliersArgs = {
  input: UpdateUnMergeSuppliersInput;
};


export type MutationUpdateUniqueIdentifierConfigurationArgs = {
  input: UpdateUniqueIdentifierConfigurationInput;
};


export type MutationUploadRulesArgs = {
  input: UploadRulesInput;
};


export type MutationUpsertCompanyFavouriteFiltersArgs = {
  input: UpsertCompanyFavouriteFiltersInput;
};


export type MutationUpsertContactsArgs = {
  input: UpsertContactsInput;
};


export type MutationUpsertDefaultFilterCombinationArgs = {
  input: UpsertDefaultFilterCombinationInput;
};


export type MutationUpsertFilteredSpendOpportunityArgs = {
  input: UpsertFilteredSpendOpportunityInput;
};


export type MutationUpsertPersonalFavouriteFiltersArgs = {
  input: UpsertPersonalFavouriteFiltersInput;
};


export type MutationUpsertPinnedFieldsArgs = {
  input: UpsertPinnedFieldsInput;
};


export type MutationUpsertRolesArgs = {
  input: UpsertRolesInput;
};


export type MutationValidateFileArgs = {
  input: ValidateFileInput;
};

export type Namespace = {
  __typename: 'Namespace';
  name: Scalars['String']['output'];
  permissions: Array<PermissionTuple>;
};

export type NamespaceInput = {
  name: Scalars['String']['input'];
  permissions: Array<PermissionTupleInput>;
};

export type NewColumnConfigInput = {
  dataColumnName: Scalars['String']['input'];
  dataType: PrimitiveDataColumnTypeInput;
  globalType?: InputMaybe<Scalars['String']['input']>;
  repositoryFieldName: Scalars['String']['input'];
};

export type NormalizationQuery = {
  __typename: 'NormalizationQuery';
  innerRelation?: Maybe<Relation>;
  nextId: Scalars['Int']['output'];
  operation: Operation;
  outerRelation?: Maybe<Relation>;
  queryId: Scalars['Int']['output'];
  ruleType: RuleType;
};

export type NormalizationQueryInput = {
  innerRelation?: InputMaybe<RelationInput>;
  nextId: Scalars['Int']['input'];
  operation: OperationInput;
  outerRelation?: InputMaybe<RelationInput>;
  queryId: Scalars['Int']['input'];
  ruleType: RuleTypeInput;
};

export type Note = {
  __typename: 'Note';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  reactions: Array<Reaction>;
  supplierId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type Onboarding = {
  __typename: 'Onboarding';
  approverId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  evaluatedAt?: Maybe<Scalars['String']['output']>;
  status: OnboardingStatus;
};

export type OnboardingStatus =
  | 'APPROVED'
  | 'IN_ONBOARDING'
  | 'REJECTED';

export type Operation = {
  __typename: 'Operation';
  comparison: Comparison;
  field: Field;
  value?: Maybe<Scalars['String']['output']>;
};

export type OperationAdapterFieldValueTupleInput = {
  field: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OperationInput = {
  comparison: ComparisonInput;
  field: FieldInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OperationType =
  | 'CONVERT_COUNTRY'
  | 'DATE_PARSER'
  | 'FILL_BLANKS'
  | 'GET_OR_CREATE'
  | 'KEYWORD_TAGGER'
  | 'LETTER_CASING'
  | 'LIST_PARSER'
  | 'LOOKUP'
  | 'MAPPING'
  | 'MATCH_COMPANY'
  | 'MATHEMATICAL_EXPRESSION'
  | 'MONETARY_AMOUNT'
  | 'REGEX'
  | 'REPLACE'
  | 'SPLIT'
  | 'TEMPLATE_STRING'
  | 'TRANSLATION';

export type OperationTypeInput =
  | 'CONVERT_COUNTRY'
  | 'DATE_PARSER'
  | 'FILL_BLANKS'
  | 'GET_OR_CREATE'
  | 'KEYWORD_TAGGER'
  | 'LETTER_CASING'
  | 'LIST_PARSER'
  | 'LOOKUP'
  | 'MAPPING'
  | 'MATCH_COMPANY'
  | 'MATHEMATICAL_EXPRESSION'
  | 'MONETARY_AMOUNT'
  | 'REGEX'
  | 'REPLACE'
  | 'SPLIT'
  | 'TEMPLATE_STRING'
  | 'TRANSLATION';

export type OptimizeDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type OptimizeDataTableResponse = {
  __typename: 'OptimizeDataTableResponse';
  notificationId: Scalars['String']['output'];
};

export type OverwriteExistingRulesInput =
  | 'CONTINUE_WITH_CONFLICTS'
  | 'KEEP_EXISTING'
  | 'OVERWRITE_EXISTING';

export type PaginatedGetDataColumnRulesByRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  igniteFiltersJSON?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetDataRepositoryRowsInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type PaginatedGetDataTableRowsSearchInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type PaginatedGetPaginatedConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDanglingRulesInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDuplicateRulesInFileInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedImportsByDataRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type PaginatedGetRowsSearchWithLabelsInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type PaginatedGetUnmergedSuppliersInput = {
  automaticRuleId: Scalars['String']['input'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type ParseFileInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type ParseFileResponse = {
  __typename: 'ParseFileResponse';
  fields: Array<FieldSamples>;
};

export type ParseFileSamplesInput = {
  fileId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
};

export type ParseFileSamplesResponse = {
  __typename: 'ParseFileSamplesResponse';
  errors: Array<Scalars['String']['output']>;
  fields: Array<FieldSamples>;
  fileId: Scalars['String']['output'];
  importConfiguration: ImportConfiguration;
  warnings: Array<Scalars['String']['output']>;
};

export type PaymentTermsOpportunity = ISpendOpportunity & {
  __typename: 'PaymentTermsOpportunity';
  averagePaymentTerms: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  paymentTerms: Scalars['Float']['output'];
  spendLTM: Scalars['Float']['output'];
  status: SpendOpportunityStatus;
  supplier: Supplier;
  type: SpendOpportunityType;
};

export type PaymentTermsOpportunityInput = {
  averagePaymentTerms: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  paymentTerms: Scalars['Float']['input'];
  spendLTM: Scalars['Float']['input'];
  status: SpendOpportunityStatus;
  supplier: SupplierInput;
  type: SpendOpportunityType;
};

export type PaymentTermsSuggestion = ISuggestion & {
  __typename: 'PaymentTermsSuggestion';
  averagePaymentTerms: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  paymentTerms: Scalars['Float']['output'];
  spendLTM: Scalars['Float']['output'];
  status: SuggestionStatus;
  supplier: Supplier;
  type: SuggestionType;
};

export type PendingChange = {
  __typename: 'PendingChange';
  createdAt: Scalars['String']['output'];
  dataColumnId?: Maybe<Scalars['String']['output']>;
  dataPipelineId: Scalars['String']['output'];
  from?: Maybe<InputOutputNode>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
  isBeingProcessed: Scalars['Boolean']['output'];
  pipelineOperationId?: Maybe<Scalars['String']['output']>;
  to?: Maybe<InputOutputNode>;
  type: PendingChangeType;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PendingChangeType =
  | 'IMPORT_CHANGE'
  | 'INPUT_OUTPUT_CONNECTION_CHANGE'
  | 'NEW_DATA_PIPELINE'
  | 'OPERATION_CHANGE'
  | 'PIPELINE_FILTER_CHANGE';

export type PeriodGranularity =
  | 'DAY'
  | 'MONTH'
  | 'WEEK'
  | 'YEAR';

export type PeriodGranularityInput =
  | 'DAY'
  | 'MONTH'
  | 'WEEK'
  | 'YEAR';

export type PeriodValue = {
  __typename: 'PeriodValue';
  fromDateString: Scalars['String']['output'];
  toDateString: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type PermissionTuple = {
  __typename: 'PermissionTuple';
  object: Scalars['String']['output'];
  relation: Scalars['String']['output'];
};

export type PermissionTupleInput = {
  object: Scalars['String']['input'];
  relation: Scalars['String']['input'];
};

export type PersonalFavouriteFilters = {
  __typename: 'PersonalFavouriteFilters';
  fields: Array<FavouriteField>;
  userId: Scalars['String']['output'];
};

export type Phone = {
  __typename: 'Phone';
  countryCode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type PhoneInput = {
  countryCode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export type PinnedField = {
  __typename: 'PinnedField';
  dataSource: Scalars['String']['output'];
  field: Scalars['String']['output'];
};

export type PinnedFieldInput = {
  dataSource: Scalars['String']['input'];
  field: Scalars['String']['input'];
};

export type PinnedFields = {
  __typename: 'PinnedFields';
  associatedId: Scalars['String']['output'];
  fields: Array<PinnedField>;
};

export type PinnedFieldsResponse = {
  __typename: 'PinnedFieldsResponse';
  associatedId: Scalars['String']['output'];
  pinnedFields: Array<PinnedField>;
};

export type PipelineExecutionRow = {
  __typename: 'PipelineExecutionRow';
  dataRepositoryFieldsJson: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operationInputsJson: Scalars['String']['output'];
  outputResultsJson: Scalars['String']['output'];
};

export type Potential = {
  __typename: 'Potential';
  potential?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  weight: Scalars['Float']['output'];
};

export type PotentialInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  referenceDate?: InputMaybe<Scalars['String']['input']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PredefinedMetric =
  | 'CONTRACT_COVERAGE'
  | 'SPEND'
  | 'SPEND_CHANGE_PREVIOUS_PERIOD'
  | 'SUPPLIER_COUNT'
  | 'SUPPLIER_COUNT_CHANGE_PREVIOUS_PERIOD'
  | 'TRANSACTION_COUNT'
  | 'TRANSACTION_COUNT_CHANGE_PREVIOUS_PERIOD';

export type PredefinedMetricResult = {
  __typename: 'PredefinedMetricResult';
  metric: PredefinedMetric;
  value: Scalars['Float']['output'];
};

export type PrimitiveDataColumnTypeInput =
  | 'BOOLEAN'
  | 'DATE'
  | 'NUMBER'
  | 'TEXT';

export type ProcurementEntityDistributionElement = {
  __typename: 'ProcurementEntityDistributionElement';
  entityId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ProcurementEntityDistributionInput = {
  field: ProcurementEntityType;
  subfield?: InputMaybe<Scalars['String']['input']>;
};

export type ProcurementEntityDistributionResult = {
  __typename: 'ProcurementEntityDistributionResult';
  elements: Array<ProcurementEntityDistributionElement>;
  procurementEntity: ProcurementEntityType;
  totalCount: Scalars['Int']['output'];
  totalValue: Scalars['Float']['output'];
};

export type ProcurementEntityResult = {
  __typename: 'ProcurementEntityResult';
  distributions: Array<ProcurementEntityDistributionResult>;
  entityId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  metrics: Array<PredefinedMetricResult>;
};

export type ProcurementEntityType =
  | 'CATEGORY'
  | 'DEPARTMENT'
  | 'SUPPLIER';

export type Query = {
  __typename: 'Query';
  checkSpendColumnAvailability: CheckSpendColumnAvailabilityResponse;
  getActiveContracts: GetActiveContractsResponse;
  getActiveContractsSummary: GetActiveContractsSummaryResponse;
  getActiveSuppliersCount: GetActiveSuppliersResponse;
  getActivities?: Maybe<ActivityResponse>;
  getAllAffectedSuppliers: GetAllAffectedSuppliersResponse;
  getAllAutomaticRules: GetAllAutomaticRulesResponse;
  getAllDataColumnRuleSets: GetAllDataColumnRuleSetsResponse;
  getAllDataColumns: GetAllDataColumnsResponse;
  getAllDataPipelineExecutions: GetAllDataPipelineExecutionsResponse;
  getAllDataPipelines: GetAllDataPipelinesResponse;
  getAllDataRepositories: GetAllDataRepositoriesResponse;
  getAllDataRepositoryCollections: GetAllDataRepositoryCollectionsResponse;
  getAllDataTableCollections: GetAllDataTableCollectionsResponse;
  getAllDataTableConnections: GetAllDataTableConnectionsResponse;
  getAllElasticFields: GetAllElasticFieldsResponse;
  getAllGroupStructures: GetAllGroupStructuresResponse;
  getAllImportConfigurations: GetAllImportConfigurationsResponse;
  getAllImportsByDataTable: GetAllImportsByDataTableResponse;
  getAllManualRules: GetAllManualRulesResponse;
  getAllPendingChanges: GetAllPendingChangesResponse;
  getAllRunningRules: GetAllRunningRulesResponse;
  getAllSupplierTableConfigs: GetSupplierTableConfigsResponse;
  getApiImportConfiguration: GetApiImportConfigurationResponse;
  getAssessment: GetAssessmentResponse;
  getAssessments: GetAssessmentsResponse;
  getAssessmentsByCampaign: GetAssessmentsByCampaignResponse;
  getAvailableCustomers: GetAvailableCustomersResponse;
  getAvailableSpendFields: GetAvailableSpendFieldsResponse;
  getBusinessUnit: GetBusinessUnitResponse;
  getBusinessUnits: GetBusinessUnitsResponse;
  getCampaign: GetCampaignResponse;
  getCampaigns: GetCampaignsResponse;
  getCardinalities: GetCardinalitiesResponse;
  getCategoryComparisonList: GetCategoryComparisonListResponse;
  getCompanies: GetCompaniesResponse;
  getCompany: GetCompanyResponse;
  getCompanyFavoriteFilters: GetCompanyFavoriteFiltersResponse;
  getCompanyFavouriteFilters: GetCompanyFavouriteFiltersResponse;
  getCompanySettings: GetCompanySettingsResponse;
  getCompleteStructureByPredefinedType: GetCompleteStructureByPredefinedTypeResponse;
  getContacts: GetContactsResponse;
  getContactsBySupplierId: GetContactsBySupplierIdResponse;
  getContactsBySupplierIds: GetContactsBySupplierIdsResponse;
  getContractCoverageDevelopment: ContractCoverageDevelopmentResponse;
  getContractStatistics: ContractStatistics;
  getContractedSpend: GetContractedSpendResponse;
  getContracts: GetContractsResponse;
  getCredentialState: GetCredentialStateResponse;
  getDataColumnDependencies: GetDataColumnDependenciesResponse;
  getDataColumnElasticFieldMap: GetDataColumnElasticFieldMapResponse;
  getDataColumnRulesByRuleSet: GetDataColumnRulesByRuleSetResponse;
  getDataColumns: GetDataColumnsResponse;
  getDataCubeConfiguration: GetDataCubeConfigurationResponse;
  getDataPipeline: GetDataPipelineResponse;
  getDataPipelineLayout: GetDataPipelineLayoutResponse;
  getDataPipelineOperation: GetDataPipelineOperationResponse;
  getDataPipelineOperations: GetDataPipelineOperationsResponse;
  getDataRepository: GetDataRepositoryResponse;
  getDataRepositoryRowCount: GetDataRepositoryRowCountResponse;
  getDataRepositoryRows: GetDataRepositoryRowsResponse;
  getDataSourcesByDataTable: GetDataSourcesByDataTableResponse;
  getDataTable: GetDataTableResponse;
  getDataTableByGlobalType: GetDataTableByGlobalTypeResponse;
  getDataTableElasticFields: GetDataTableElasticFieldsResponse;
  getDataTableFieldLabels: GetDataTableFieldLabelsResponse;
  getDataTableRow: GetDataTableRowResponse;
  getDataTableRowCount: GetDataTableRowCountResponse;
  getDataTableRows: GetDataTableRowsResponse;
  getDataTableRowsSearch: GetDataTableRowsSearchResponse;
  getDataTableValidationSummary: GetDataTableValidationSummaryResponse;
  getDatePeriod: GetDatePeriodResponse;
  getDefaultFilterCombination: GetDefaultFilterCombinationResponse;
  getDependantColumns: GetDependantColumnsResponse;
  getDependentRulesByGroupId: GetDependentRulesByGroupIdResponse;
  getEcho: GetEchoResponse;
  getExportDownloadUrls: GetExportDownloadUrlsResponse;
  getExportsByTableId: GetExportsByTableIdResponse;
  getFiles: GetFilesResponse;
  getFilesByIds: GetFilesByIdsResponse;
  getFilterCombinations: GetFilterCombinationsResponse;
  getFilterFieldMappings: GetFilterFieldMappingsResponse;
  getFilteredSpendOpportunities: GetFilteredSpendOpportunitiesResponse;
  getGlobalRoles: GetGlobalRolesResponse;
  getGroup: GetGroupResponse;
  getGroupHasChildren: GetGroupHasChildrenResponse;
  getGroupPrediction: GetGroupPredictionResponse;
  getGroupPredictions: GetGroupPredictionsResponse;
  getGroupStructure: GetGroupStructureResponse;
  getGroupStructures: GetGroupStructuresResponse;
  getGroups: GetGroupsResponse;
  getGroupsByGroupStructure: GetGroupsByGroupStructureResponse;
  getImportConfiguration: GetImportConfigurationResponse;
  getImports: GetImportsResponse;
  getImportsByDataRepository: GetImportsByDataRepositoryResponse;
  getInputOutputConnections: GetInputOutputConnectionsResponse;
  getInvitationURL: GetInvitationUrlResponse;
  getInvites: GetInvitesResponse;
  getLinkedProviders: GetLinkedProvidersResponse;
  getManyAggregatedViews: GetManyAggregatedViewsResponse;
  getManyDataTables: GetManyDataTablesResponse;
  getManyUniqueIdentifierConfigurations: GetManyUniqueIdentifierConfigurationsResponse;
  getMatchingFieldValues: GetMatchingFieldValuesResponse;
  getMaxMinForField: GetMaxMinForFieldResponse;
  getMetricDevelopmentQuery: GetMetricDevelopmentQueryResponse;
  getMinMaxForSupplierTableNumberColumn: GetMinMaxForSupplierTableNumberColumnResponse;
  getMissingConfigurations?: Maybe<GetMissingConfigurationsResponse>;
  getOperationOutputUsage: GetOperationOutputUsageResponse;
  getOrCreateDataTableCollectionOrder: GetOrCreateDataTableCollectionOrderResponse;
  getOverviewInfo: GetOverviewInfoResponse;
  getPaginatedConflictingRulesForUpload: GetPaginatedConflictingRulesForUploadResponse;
  getPaginatedDanglingRules: GetPaginatedDanglingRulesResponse;
  getPaginatedDuplicateRulesInFile: GetPaginatedDuplicateRulesInFileResponse;
  getPaginatedImportsByDataRepository: GetPaginatedImportsByDataRepositoryResponse;
  getParentIdsBySearchTerm: GetParentIdsBySearchTermResponse;
  getParentNamesByChildId: GetParentNamesByChildIdResponse;
  getParentsByChildId: GetParentsByChildIdResponse;
  getPersonalFavoriteFilters: GetPersonalFavoriteFiltersResponse;
  getPersonalFavouriteFilters: GetPersonalFavouriteFiltersResponse;
  getPinnedFields: PinnedFieldsResponse;
  getPipelineOperationExecutionRowsByBatchId: GetPipelineOperationExecutionRowsByBatchIdResponse;
  getPivot: GetPivotResponse;
  getPreviewFile: GetPreviewFileResponse;
  getProcurementEntityQuery: GetProcurementEntityQueryResponse;
  getQueryMultipleFields: GetQueryMultipleFieldsResponse;
  getQueryMultipleIndices: GetQueryMultipleIndicesResponse;
  getQuestionnaire: GetQuestionnaireResponse;
  getQuestionnaires: GetQuestionnairesResponse;
  getRegexSuggestion: GetRegexSuggestionResponse;
  getRelatedDataColumnsByDataTable: GetRelatedDataColumnsByDataTableResponse;
  getRepositoryRowDeleteQueriesByRepository: GetRepositoryRowDeleteQueriesByRepositoryResponse;
  getRiskColumnConfig: GetRiskColumnConfigResponse;
  getRoles: GetRolesResponse;
  getRowsSearchWithLabels: GetRowsSearchWithLabelsResponse;
  getRuleCountPerRuleSetByDataColumn: GetRuleCountPerRuleSetByDataColumnResponse;
  getSavedCategories: GetSavedCategoriesResponse;
  getSavingsEstimate?: Maybe<SavingsEstimate>;
  getSavingsEstimates?: Maybe<Array<SavingsEstimate>>;
  getSpendCategories: GetSpendCategoriesResponse;
  getSpendCategory: GetSpendCategoryResponse;
  getSpendDataColumns: GetSpendDataColumnsResponse;
  getSpendDevelopment: GetSpendDevelopmentResponse;
  getSpendDevelopmentForSupplier: SupplierSpendDevelopment;
  getSpendDevelopmentPeriodComparison: GetSpendDevelopmentPeriodComparisonResponse;
  getSpendDistributionForSupplier?: Maybe<SupplierSpendDistribution>;
  getSpendQueryByPrompt: GetSpendQueryByPromptResponse;
  getSupplier: GetSupplierResponse;
  getSupplierFieldsByColumn: GetSupplierFieldsByColumnResponse;
  getSupplierFieldsMappingSuggestion: GetSupplierFieldsMappingSuggestionResponse;
  getSupplierFiles: GetSupplierFilesResponse;
  getSupplierIds: GetSupplierIdsResponse;
  getSupplierTableConfigs: GetSupplierTableConfigsResponse;
  getSupplierTableDataSources: GetSupplierTableDataSourcesResponse;
  getSupplierTableMeta: GetSupplierTableMetaResponse;
  getSuppliers: GetSuppliersResponse;
  getSuppliersByIds: GetSuppliersByIdsResponse;
  getTagValuesByColumnId: GetTagValuesByColumnIdResponse;
  getTopCategoriesBySpend: GetTopCategoriesBySpendResponse;
  getTopContributorEmissions?: Maybe<TopContributorsResponse>;
  getTopSuppliers: GetTopSuppliersResponse;
  getTopSuppliersComparison: GetTopSuppliersComparison;
  getTotalEmissions?: Maybe<Scalars['Float']['output']>;
  getTotalEmissionsByYear?: Maybe<TotalEmissionsResponse>;
  getTotalSavingsEstimate?: Maybe<TotalSavingsEstimate>;
  getTotalSocialRisk: GetTotalSocialRiskResponse;
  getTotalSpend: GetTotalSpendResponse;
  getUniqueIdentifierConfiguration: GetUniqueIdentifierConfigurationResponse;
  getUniqueIdentifierConfigurationByRepository: GetUniqueIdentifierConfigurationByRepositoryResponse;
  getUniqueValuesForSupplierTableColumn: GetUniqueValuesForSupplierTableColumnResponse;
  getUnmergedSuppliers: GetUnmergedSuppliersResponse;
  getUsers: GetUsersResponse;
  getValuesForField: GetValuesForFieldResponse;
  hasConnectedSpend: HasConnectedSpendResponse;
  listAccessRequests: ListAccessRequestsResponse;
  listConsents: Array<SupportConsent>;
  searchCompany: SearchCompanyResponse;
  searchContacts: SearchContactsResponse;
  searchFiles: SearchFilesResponse;
  searchSpendFieldValues: SearchSpendFieldValuesResponse;
  suggestions: SuggestionsResponse;
};


export type QueryGetActiveContractsArgs = {
  input: GetActiveContractsInput;
};


export type QueryGetActiveContractsSummaryArgs = {
  input: GetActiveContractsSummaryInput;
};


export type QueryGetActiveSuppliersCountArgs = {
  input: GetActiveSuppliersCountInput;
};


export type QueryGetActivitiesArgs = {
  electricityBasis: ElectricityBasis;
  filter?: InputMaybe<EmissionsFilter>;
  paginationInput: PaginationInput;
};


export type QueryGetAllAffectedSuppliersArgs = {
  input: GetAllAffectedSuppliersInput;
};


export type QueryGetAllDataColumnRuleSetsArgs = {
  input: GetAllDataColumnRuleSetsInput;
};


export type QueryGetAllDataPipelineExecutionsArgs = {
  input: GetAllDataPipelineExecutionsInput;
};


export type QueryGetAllImportsByDataTableArgs = {
  input: GetAllImportsByDataTableInput;
};


export type QueryGetAllManualRulesArgs = {
  input: GetAllManualRulesInput;
};


export type QueryGetAllPendingChangesArgs = {
  input: GetAllPendingChangesInput;
};


export type QueryGetApiImportConfigurationArgs = {
  input: GetApiImportConfigurationInput;
};


export type QueryGetAssessmentArgs = {
  input: GetAssessmentInput;
};


export type QueryGetAssessmentsArgs = {
  input?: InputMaybe<GetAssessmentsInput>;
};


export type QueryGetAssessmentsByCampaignArgs = {
  input: GetAssessmentsByCampaignInput;
};


export type QueryGetBusinessUnitArgs = {
  input: GetBusinessUnitInput;
};


export type QueryGetBusinessUnitsArgs = {
  input: GetBusinessUnitsInput;
};


export type QueryGetCampaignArgs = {
  input: GetCampaignInput;
};


export type QueryGetCardinalitiesArgs = {
  input: GetCardinalitiesInput;
};


export type QueryGetCategoryComparisonListArgs = {
  input: GetCategoryComparisonListInput;
};


export type QueryGetCompaniesArgs = {
  input: GetCompaniesInput;
};


export type QueryGetCompanyArgs = {
  input: GetCompanyInput;
};


export type QueryGetCompanySettingsArgs = {
  input: GetCompanySettingsInput;
};


export type QueryGetCompleteStructureByPredefinedTypeArgs = {
  input: GetCompleteStructureByPredefinedTypeInput;
};


export type QueryGetContactsArgs = {
  input: GetContactsInput;
};


export type QueryGetContactsBySupplierIdArgs = {
  input: GetContactsBySupplierIdInput;
};


export type QueryGetContactsBySupplierIdsArgs = {
  input: GetContactsBySupplierIdsInput;
};


export type QueryGetContractCoverageDevelopmentArgs = {
  input: GetContractCoverageDevelopmentInput;
};


export type QueryGetContractStatisticsArgs = {
  input?: InputMaybe<GetContractStatisticsInput>;
};


export type QueryGetContractedSpendArgs = {
  input: GetContractedSpendInput;
};


export type QueryGetContractsArgs = {
  input?: InputMaybe<GetContractsInput>;
};


export type QueryGetDataColumnDependenciesArgs = {
  input: GetDataColumnDependenciesInput;
};


export type QueryGetDataColumnElasticFieldMapArgs = {
  input: GetDataColumnElasticFieldMapInput;
};


export type QueryGetDataColumnRulesByRuleSetArgs = {
  input: GetDataColumnRulesByRuleSetInput;
};


export type QueryGetDataColumnsArgs = {
  input: GetDataColumnsInput;
};


export type QueryGetDataCubeConfigurationArgs = {
  input: GetDataCubeConfigurationInput;
};


export type QueryGetDataPipelineArgs = {
  input: GetDataPipelineInput;
};


export type QueryGetDataPipelineLayoutArgs = {
  input: GetDataPipelineLayoutInput;
};


export type QueryGetDataPipelineOperationArgs = {
  input: GetDataPipelineOperationInput;
};


export type QueryGetDataPipelineOperationsArgs = {
  input: GetDataPipelineOperationsInput;
};


export type QueryGetDataRepositoryArgs = {
  input: GetDataRepositoryInput;
};


export type QueryGetDataRepositoryRowCountArgs = {
  input: GetDataRepositoryRowCountInput;
};


export type QueryGetDataRepositoryRowsArgs = {
  input: GetDataRepositoryRowsInput;
};


export type QueryGetDataSourcesByDataTableArgs = {
  input: GetDataSourcesByDataTableInput;
};


export type QueryGetDataTableArgs = {
  input: GetDataTableInput;
};


export type QueryGetDataTableByGlobalTypeArgs = {
  input: GetDataTableByGlobalTypeInput;
};


export type QueryGetDataTableElasticFieldsArgs = {
  input: GetDataTableElasticFieldsInput;
};


export type QueryGetDataTableFieldLabelsArgs = {
  input: GetDataTableFieldLabelsInput;
};


export type QueryGetDataTableRowArgs = {
  input: GetDataTableRowInput;
};


export type QueryGetDataTableRowCountArgs = {
  input: GetDataTableRowCountInput;
};


export type QueryGetDataTableRowsArgs = {
  input: GetDataTableRowsInput;
};


export type QueryGetDataTableRowsSearchArgs = {
  input: GetDataTableRowsSearchInput;
};


export type QueryGetDataTableValidationSummaryArgs = {
  input: GetDataTableValidationSummaryInput;
};


export type QueryGetDatePeriodArgs = {
  input: DatePeriodInput;
};


export type QueryGetDefaultFilterCombinationArgs = {
  input: GetDefaultFilterCombinationInput;
};


export type QueryGetDependantColumnsArgs = {
  input: GetDependantColumnsInput;
};


export type QueryGetDependentRulesByGroupIdArgs = {
  input: GetDependentRulesByGroupIdInput;
};


export type QueryGetEchoArgs = {
  input: GetEchoInput;
};


export type QueryGetExportDownloadUrlsArgs = {
  input: GetExportDownloadUrlsInput;
};


export type QueryGetExportsByTableIdArgs = {
  input: GetExportsByTableIdInput;
};


export type QueryGetFilesArgs = {
  input: GetFilesInput;
};


export type QueryGetFilesByIdsArgs = {
  input: GetFilesByIdsInput;
};


export type QueryGetFilteredSpendOpportunitiesArgs = {
  input: GetFilteredSpendOpportunitiesInput;
};


export type QueryGetGroupArgs = {
  input: GetGroupInput;
};


export type QueryGetGroupHasChildrenArgs = {
  input: GetGroupHasChildrenInput;
};


export type QueryGetGroupPredictionArgs = {
  input: GetGroupPredictionInput;
};


export type QueryGetGroupPredictionsArgs = {
  input: GetGroupPredictionsInput;
};


export type QueryGetGroupStructureArgs = {
  input: GetGroupStructureInput;
};


export type QueryGetGroupStructuresArgs = {
  input: GetGroupStructuresInput;
};


export type QueryGetGroupsArgs = {
  input: GetGroupsInput;
};


export type QueryGetGroupsByGroupStructureArgs = {
  input: GetGroupsByGroupStructureInput;
};


export type QueryGetImportConfigurationArgs = {
  input: GetImportConfigurationInput;
};


export type QueryGetImportsArgs = {
  input: GetImportsInput;
};


export type QueryGetImportsByDataRepositoryArgs = {
  input: GetImportsByDataRepositoryInput;
};


export type QueryGetInputOutputConnectionsArgs = {
  input: GetInputOutputConnectionsInput;
};


export type QueryGetInvitationUrlArgs = {
  input: GetInvitationUrlInput;
};


export type QueryGetInvitesArgs = {
  input?: InputMaybe<GetInvitesInput>;
};


export type QueryGetMatchingFieldValuesArgs = {
  input: GetMatchingFieldValuesInput;
};


export type QueryGetMaxMinForFieldArgs = {
  input: GetMaxMinForFieldInput;
};


export type QueryGetMetricDevelopmentQueryArgs = {
  input: GetMetricDevelopmentQueryInput;
};


export type QueryGetMinMaxForSupplierTableNumberColumnArgs = {
  input: GetMinMaxForSupplierTableNumberColumn;
};


export type QueryGetOperationOutputUsageArgs = {
  input: GetOperationOutputUsageInput;
};


export type QueryGetPaginatedConflictingRulesForUploadArgs = {
  input: GetPaginatedConflictingRulesForUploadInput;
};


export type QueryGetPaginatedDanglingRulesArgs = {
  input: GetPaginatedDanglingRulesInput;
};


export type QueryGetPaginatedDuplicateRulesInFileArgs = {
  input: GetPaginatedDuplicateRulesInFileInput;
};


export type QueryGetPaginatedImportsByDataRepositoryArgs = {
  input: GetPaginatedImportsByDataRepositoryInput;
};


export type QueryGetParentIdsBySearchTermArgs = {
  input: GetParentIdsBySearchTermInput;
};


export type QueryGetParentNamesByChildIdArgs = {
  input: GetParentNamesByChildIdInput;
};


export type QueryGetParentsByChildIdArgs = {
  input: GetParentsByChildIdInput;
};


export type QueryGetPersonalFavoriteFiltersArgs = {
  input: GetPersonalFavouriteFiltersInput;
};


export type QueryGetPersonalFavouriteFiltersArgs = {
  input: GetPersonalFavouriteFiltersInput;
};


export type QueryGetPinnedFieldsArgs = {
  input: GetPinnedFieldsInput;
};


export type QueryGetPipelineOperationExecutionRowsByBatchIdArgs = {
  input: GetPipelineOperationExecutionRowsByBatchIdInput;
};


export type QueryGetPivotArgs = {
  input: GetPivotInput;
};


export type QueryGetPreviewFileArgs = {
  input: GetPreviewFileInput;
};


export type QueryGetProcurementEntityQueryArgs = {
  input: GetProcurementEntityQueryInput;
};


export type QueryGetQueryMultipleFieldsArgs = {
  input: GetQueryMultipleFieldsInput;
};


export type QueryGetQueryMultipleIndicesArgs = {
  input: GetQueryMultipleIndicesInput;
};


export type QueryGetQuestionnaireArgs = {
  input: GetQuestionnaireInput;
};


export type QueryGetRegexSuggestionArgs = {
  input: GetRegexSuggestionInput;
};


export type QueryGetRelatedDataColumnsByDataTableArgs = {
  input: GetRelatedDataColumnsByDataTableInput;
};


export type QueryGetRepositoryRowDeleteQueriesByRepositoryArgs = {
  input: GetRepositoryRowDeleteQueriesByRepositoryInput;
};


export type QueryGetRolesArgs = {
  input: GetRolesInput;
};


export type QueryGetRowsSearchWithLabelsArgs = {
  input: GetRowsSearchWithLabelsInput;
};


export type QueryGetRuleCountPerRuleSetByDataColumnArgs = {
  input: GetRuleCountPerRuleSetByDataColumnInput;
};


export type QueryGetSavingsEstimateArgs = {
  supplierId: Scalars['ID']['input'];
};


export type QueryGetSavingsEstimatesArgs = {
  input: GetSavingsEstimatesInput;
};


export type QueryGetSpendCategoriesArgs = {
  input: GetSpendCategoriesInput;
};


export type QueryGetSpendCategoryArgs = {
  input: GetSpendCategoryInput;
};


export type QueryGetSpendDevelopmentArgs = {
  input: GetSpendDevelopmentInput;
};


export type QueryGetSpendDevelopmentForSupplierArgs = {
  input: GetSpendDevelopmentForSupplierInput;
};


export type QueryGetSpendDevelopmentPeriodComparisonArgs = {
  input: GetSpendDevelopmentPeriodComparisonInput;
};


export type QueryGetSpendDistributionForSupplierArgs = {
  input?: InputMaybe<GetSpendDistributionForSupplierInput>;
};


export type QueryGetSpendQueryByPromptArgs = {
  input: GetSpendQueryByPromptInput;
};


export type QueryGetSupplierArgs = {
  input: GetSupplierInput;
};


export type QueryGetSupplierFieldsMappingSuggestionArgs = {
  input: GetSupplierFieldsMappingSuggestionInput;
};


export type QueryGetSupplierFilesArgs = {
  input: GetSupplierFilesInput;
};


export type QueryGetSupplierIdsArgs = {
  input: GetSupplierIdsInput;
};


export type QueryGetSuppliersArgs = {
  input: GetSuppliersInput;
};


export type QueryGetSuppliersByIdsArgs = {
  input: GetSuppliersByIdsInput;
};


export type QueryGetTagValuesByColumnIdArgs = {
  input: GetTagValuesByColumnIdInput;
};


export type QueryGetTopCategoriesBySpendArgs = {
  input: GetTopCategoriesBySpendInput;
};


export type QueryGetTopContributorEmissionsArgs = {
  contributorType: ContributorType;
  electricityBasis: ElectricityBasis;
  filter?: InputMaybe<EmissionsFilter>;
  level?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTopSuppliersArgs = {
  input: GetTopSuppliersInput;
};


export type QueryGetTopSuppliersComparisonArgs = {
  input: GetTopSuppliersComparisonInput;
};


export type QueryGetTotalEmissionsArgs = {
  year: Scalars['Int']['input'];
};


export type QueryGetTotalEmissionsByYearArgs = {
  electricityBasis: ElectricityBasis;
  endYear: Scalars['Int']['input'];
  filter?: InputMaybe<EmissionsFilter>;
  startYear: Scalars['Int']['input'];
};


export type QueryGetTotalSpendArgs = {
  input: GetTotalSpendInput;
};


export type QueryGetUniqueIdentifierConfigurationArgs = {
  input: GetUniqueIdentifierConfigurationInput;
};


export type QueryGetUniqueIdentifierConfigurationByRepositoryArgs = {
  input: GetUniqueIdentifierConfigurationByRepositoryInput;
};


export type QueryGetUniqueValuesForSupplierTableColumnArgs = {
  input: GetUniqueValuesForSupplierTableColumnInput;
};


export type QueryGetUnmergedSuppliersArgs = {
  input: GetUnmergedSuppliersInput;
};


export type QueryGetUsersArgs = {
  input?: InputMaybe<GetUsersInput>;
};


export type QueryGetValuesForFieldArgs = {
  input: GetValuesForFieldInput;
};


export type QueryListAccessRequestsArgs = {
  _cacheKey?: InputMaybe<Scalars['String']['input']>;
  grantedAfter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  revokedAfter?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchCompanyArgs = {
  input: SearchCompanyInput;
};


export type QuerySearchContactsArgs = {
  input: SearchContactsInput;
};


export type QuerySearchFilesArgs = {
  input: SearchFilesInput;
};


export type QuerySearchSpendFieldValuesArgs = {
  input: SearchSpendFieldValuesInput;
};


export type QuerySuggestionsArgs = {
  input: SuggestionsInput;
};

export type QueryType =
  | 'FIELD_VALUE'
  | 'RELATIVE_DATE';

export type QueryTypeInput =
  | 'FIELD_VALUE'
  | 'RELATIVE_DATE';

export type Question = {
  __typename: 'Question';
  attachedFileNames: Array<Scalars['String']['output']>;
  /** @deprecated Use attachedFileNames instead for new file handling */
  attachedFiles?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  fileUpload: FileUpload;
  id: Scalars['ID']['output'];
  maxChars?: Maybe<Scalars['Int']['output']>;
  maxNum?: Maybe<Scalars['Int']['output']>;
  maxRating?: Maybe<Scalars['Int']['output']>;
  minNum?: Maybe<Scalars['Int']['output']>;
  options: Array<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  scoring: Array<QuestionScore>;
  sectionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: QuestionType;
};

export type QuestionInput = {
  attachedFileNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** @deprecated Use attachedFileNames instead for new file handling */
  attachedFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileUpload: FileUploadInput;
  id: Scalars['ID']['input'];
  maxChars?: InputMaybe<Scalars['Int']['input']>;
  maxNum?: InputMaybe<Scalars['Int']['input']>;
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  minNum?: InputMaybe<Scalars['Int']['input']>;
  options: Array<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  sectionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: QuestionTypeInput;
};

export type QuestionScore = {
  __typename: 'QuestionScore';
  key: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export type QuestionScoreInput = {
  key: Scalars['String']['input'];
  score: Scalars['Int']['input'];
};

export type QuestionType =
  | 'boolean'
  | 'date'
  | 'multiSelect'
  | 'number'
  | 'rating'
  | 'singleSelect'
  | 'text';

export type QuestionTypeInput =
  | 'boolean'
  | 'date'
  | 'multiSelect'
  | 'number'
  | 'rating'
  | 'singleSelect'
  | 'text';

export type Questionnaire = {
  __typename: 'Questionnaire';
  campaigns?: Maybe<Array<Campaign>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sections: Array<Section>;
  status: QuestionnaireStatus;
};

export type QuestionnaireInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  default: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  sections: Array<SectionInput>;
  status: QuestionnaireStatus;
};

export type QuestionnaireStatus =
  | 'archived'
  | 'draft'
  | 'published';

export type QuoteCharacterType =
  | 'DISABLED_QUOTING'
  | 'DOUBLE_QUOTE_QUOTING'
  | 'SINGLE_QUOTE_QUOTING';

export type QuoteCharacterTypeInput =
  | 'DISABLED_QUOTING'
  | 'DOUBLE_QUOTE_QUOTING'
  | 'SINGLE_QUOTE_QUOTING';

export type Reaction = {
  __typename: 'Reaction';
  emojiCode: Scalars['String']['output'];
  userIDs: Array<Scalars['String']['output']>;
};

export type ReapplyDataColumnRulesInput = {
  targetDataColumnId: Scalars['String']['input'];
  whatGroupStructures?: InputMaybe<Scalars['String']['input']>;
  whatRows?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyDataColumnRulesResponse = {
  __typename: 'ReapplyDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type ReapplyDataTableConnectionsInput = {
  dataColumns: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type ReapplyDataTableConnectionsResponse = {
  __typename: 'ReapplyDataTableConnectionsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReferenceSpend = {
  __typename: 'ReferenceSpend';
  amount: Scalars['Float']['output'];
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type RegexOperationAdapter = {
  __typename: 'RegexOperationAdapter';
  regex: Scalars['String']['output'];
};

export type Relation = {
  __typename: 'Relation';
  clause: Clause;
  normalizationQuery: NormalizationQuery;
};

export type RelationInput = {
  clause: ClauseInput;
  normalizationQuery: NormalizationQueryInput;
};

export type RelativeDateSetting =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RelativeDateSettingInput =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RelativePeriodSelection = {
  length: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  period: DateInterval;
};

export type RemoveDataPipelineSourceInput = {
  dataPipelineId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
};

export type RemoveDataPipelineSourceResponse = {
  __typename: 'RemoveDataPipelineSourceResponse';
  dataPipeline: DataPipeline;
};

export type RemoveDataTableConnectionCriterionInput = {
  dataTableConnectionCriterionId: Scalars['String']['input'];
  dataTableConnectionId: Scalars['String']['input'];
};

export type RemoveDataTableConnectionCriterionResponse = {
  __typename: 'RemoveDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type RemoveGhostRowsInput = {
  dataTableId: Scalars['String']['input'];
};

export type RemoveGhostRowsResponse = {
  __typename: 'RemoveGhostRowsResponse';
  notificationId: Scalars['String']['output'];
};

export type RemoveLookupOperationCriteriaInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type RemoveLookupOperationCriteriaResponse = {
  __typename: 'RemoveLookupOperationCriteriaResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type RemoveLookupOperationFieldOutputsInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type RemoveLookupOperationFieldOutputsResponse = {
  __typename: 'RemoveLookupOperationFieldOutputsResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type RemoveUsersInput = {
  userIds: Array<Scalars['String']['input']>;
};

export type RemoveUsersResponse = {
  __typename: 'RemoveUsersResponse';
  count: Scalars['Int']['output'];
  userIds: Array<Scalars['String']['output']>;
};

export type ReorderDataColumnRuleSetsInput = {
  dataColumnId: Scalars['String']['input'];
  ruleSetChanges: Array<RuleSetChangeInput>;
};

export type ReorderDataColumnRuleSetsResponse = {
  __typename: 'ReorderDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReorderQuestionInput = {
  id: Scalars['String']['input'];
  toIndex: Scalars['Int']['input'];
  toSectionId: Scalars['String']['input'];
};

export type ReorderQuestionResponse = {
  __typename: 'ReorderQuestionResponse';
  sections: Array<Section>;
};

export type ReorderSectionInput = {
  id: Scalars['String']['input'];
  toIndex: Scalars['Int']['input'];
};

export type ReorderSectionResponse = {
  __typename: 'ReorderSectionResponse';
  sections: Array<Section>;
};

export type ReplaceDataColumnWithNewDataTypeInput = {
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  newDataType: DataColumnTypeInput;
  toList: Scalars['Boolean']['input'];
};

export type ReplaceDataColumnWithNewDataTypeResponse = {
  __typename: 'ReplaceDataColumnWithNewDataTypeResponse';
  newDataColumn: DataColumn;
  notificationIds: Array<Scalars['String']['output']>;
};

export type ReplaceOperationAdapter = {
  __typename: 'ReplaceOperationAdapter';
  isRegex: Scalars['Boolean']['output'];
  replaceString: Scalars['String']['output'];
  withString: Scalars['String']['output'];
};

export type RepositoryRowDeleteQuery = {
  __typename: 'RepositoryRowDeleteQuery';
  dataRepositoryFieldId: Scalars['String']['output'];
  dataRepositoryId: Scalars['String']['output'];
  dateFormat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  numberOfPeriods?: Maybe<Scalars['Int']['output']>;
  periodGranularity?: Maybe<PeriodGranularity>;
  queryType: QueryType;
  value?: Maybe<Scalars['String']['output']>;
};

export type RequestSpendSetupResponse = {
  __typename: 'RequestSpendSetupResponse';
  ok: Scalars['Boolean']['output'];
};

export type ResendInvitesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type ResendInvitesResponse = {
  __typename: 'ResendInvitesResponse';
  ids: Array<Scalars['ID']['output']>;
};

export type Risk = {
  __typename: 'Risk';
  geography?: Maybe<RiskScore>;
  industry?: Maybe<RiskScore>;
  social?: Maybe<RiskScore>;
};

export type RiskScore =
  | 'HIGH'
  | 'LOW'
  | 'MEDIUM';

export type Role = {
  __typename: 'Role';
  name: Scalars['String']['output'];
  namespaces: Array<Namespace>;
};

export type RoleInput = {
  name: Scalars['String']['input'];
  namespaces: Array<NamespaceInput>;
};

export type RoleUser = {
  __typename: 'RoleUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastActivity: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  scimmed: Scalars['Boolean']['output'];
};

export type RuleFilterType =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleFilterTypeInput =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleSetChangeInput = {
  newPrecedence: Scalars['Int']['input'];
  ruleSetId: Scalars['String']['input'];
};

export type RuleSetIdWithRuleCount = {
  __typename: 'RuleSetIdWithRuleCount';
  ruleCount: Scalars['Int']['output'];
  ruleSetId: Scalars['String']['output'];
};

export type RuleType =
  | 'Generic'
  | 'Specific';

export type RuleTypeInput =
  | 'Generic'
  | 'Specific';

export type RunningRules = {
  __typename: 'RunningRules';
  dataColumnId: Scalars['String']['output'];
  selection: Array<Filter>;
};

export type SaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type SaveCategoryResponse = {
  __typename: 'SaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type SavingsContractStatus = {
  __typename: 'SavingsContractStatus';
  expiresAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SavingsEstimate = {
  __typename: 'SavingsEstimate';
  contractStatus?: Maybe<SavingsContractStatus>;
  estimatedSavingsPercentage: Scalars['Float']['output'];
  factors: SavingsFactors;
  potential: Scalars['Float']['output'];
  referenceSpend: ReferenceSpend;
  status: SavingsEstimateStatus;
  supplier: Supplier;
};

export type SavingsEstimateStatus =
  | 'MARKED_IRRELEVANT'
  | 'MARKED_RELEVANT'
  | 'NOT_RUN'
  | 'PRESENTED'
  | 'SYSTEM_IRRELEVANT_MISSING_FINANCIALS'
  | 'SYSTEM_IRRELEVANT_MISSING_INDUSTRY_CODE'
  | 'SYSTEM_IRRELEVANT_NOT_ADDRESSABLE_CATEGORY'
  | 'SYSTEM_IRRELEVANT_NOT_ADDRESSABLE_SPEND';

export type SavingsFactor = {
  __typename: 'SavingsFactor';
  potential: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type SavingsFactors = {
  __typename: 'SavingsFactors';
  ebitMargin?: Maybe<SavingsFactor>;
  roce?: Maybe<SavingsFactor>;
  shareOfWallet?: Maybe<SavingsFactor>;
  spendGrowth?: Maybe<SavingsFactor>;
};

export type SearchCompanyInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgNumber?: InputMaybe<Scalars['String']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchCompanyResponse = {
  __typename: 'SearchCompanyResponse';
  companies?: Maybe<Array<SimpleCompany>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchContactsInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: SortInput;
  terms: ContactTermsInput;
};

export type SearchContactsResponse = {
  __typename: 'SearchContactsResponse';
  count: Scalars['Int']['output'];
  next: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  prev: Scalars['Int']['output'];
  result: Array<Contact>;
  total: Scalars['Int']['output'];
};

export type SearchFilesInput = {
  fileName: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: SortFilesInput;
};

export type SearchFilesResponse = {
  __typename: 'SearchFilesResponse';
  count: Scalars['Int']['output'];
  next: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  prev: Scalars['Int']['output'];
  result: Array<File>;
  total: Scalars['Int']['output'];
};

export type SearchInputInput = {
  excludeColumn?: InputMaybe<Scalars['String']['input']>;
  fields: Array<Scalars['String']['input']>;
  filtersJSON?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  sortingList: Array<SortingInput>;
  tableId: Scalars['String']['input'];
};

export type SearchSpendFieldValuesInput = {
  field: SpendFieldInput;
  filters?: InputMaybe<SpendQueryFilters>;
  searchTerm: Scalars['String']['input'];
};

export type SearchSpendFieldValuesResponse = {
  __typename: 'SearchSpendFieldValuesResponse';
  searchTerm: Scalars['String']['output'];
  values: Array<SpendFieldValue>;
};

export type Section = {
  __typename: 'Section';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  questionnaireId: Scalars['String']['output'];
  questions: Array<Question>;
};

export type SectionInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  questionnaireId: Scalars['String']['input'];
  questions: Array<QuestionInput>;
};

export type SelectColumn = IColumn & {
  __typename: 'SelectColumn';
  /** The available choices for this select column */
  choices: Array<Scalars['String']['output']>;
  globalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ColumnType;
};

export type SelectOptions = {
  __typename: 'SelectOptions';
  choices: Array<Scalars['String']['output']>;
};

export type SelectionFilter = {
  filters?: InputMaybe<SpendQueryFilters>;
  period: DatePeriodInput;
};

export type SendCampaignInvitationsInput = {
  campaignID: Scalars['String']['input'];
};

export type SendCampaignInvitationsResponse = {
  __typename: 'SendCampaignInvitationsResponse';
  campaign: Campaign;
};

export type SendCampaignRemindersInput = {
  id: Scalars['ID']['input'];
};

export type SendCampaignRemindersResponse = {
  __typename: 'SendCampaignRemindersResponse';
  remindedAt: Scalars['String']['output'];
};

export type SendFeedbackInput = {
  canContact?: InputMaybe<Scalars['Boolean']['input']>;
  feedback: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};

export type SendFeedbackResponse = {
  __typename: 'SendFeedbackResponse';
  ok: Scalars['Boolean']['output'];
};

export type SetActiveTenantInput = {
  tenant: Scalars['String']['input'];
};

export type SetActiveTenantResponse = {
  __typename: 'SetActiveTenantResponse';
  oldTenant: Scalars['String']['output'];
  tenant: Scalars['String']['output'];
};

export type SetQuestionScoringInput = {
  questionId: Scalars['ID']['input'];
  scoring: Array<QuestionScoreInput>;
};

export type SetQuestionScoringResponse = {
  __typename: 'SetQuestionScoringResponse';
  question: Question;
};

export type SetSpendDataConfigurationsInput = {
  newSpendDataConfigurations: Array<SpendColumnConfiguration>;
};

export type SetSpendDataConfigurationsResponse = {
  __typename: 'SetSpendDataConfigurationsResponse';
  dataColumns: Array<SpendDataColumn>;
};

export type SetSuppliersToOnboardingInput = {
  supplierIDs: Array<Scalars['String']['input']>;
};

export type SetSuppliersToOnboardingResponse = {
  __typename: 'SetSuppliersToOnboardingResponse';
  ok: Scalars['Boolean']['output'];
};

export type SetUserRolesInput = {
  roleNames: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type SetUserRolesResponse = {
  __typename: 'SetUserRolesResponse';
  roleNames: Array<Scalars['String']['output']>;
};

export type SetupSupplierTableInput = {
  contact: NewColumnConfigInput;
  country: NewColumnConfigInput;
  name: NewColumnConfigInput;
  orgno: NewColumnConfigInput;
};

export type SetupSupplierTableResponse = {
  __typename: 'SetupSupplierTableResponse';
  updated: Scalars['Boolean']['output'];
};

export type SimpleCompany = {
  __typename: 'SimpleCompany';
  companyMasterId?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNumber: Scalars['String']['output'];
  source: EnrichmentType;
};

export type SocialRiskReductionSuggestion = ISuggestion & {
  __typename: 'SocialRiskReductionSuggestion';
  id: Scalars['ID']['output'];
  status: SuggestionStatus;
  supplier: Supplier;
  type: SuggestionType;
};

export type SortAssessmentsInput = {
  by: Scalars['String']['input'];
  dir: SortDirectionsInput;
};

export type SortDirection =
  | 'ASC'
  | 'DESC';

export type SortDirectionsInput =
  | 'asc'
  | 'desc';

export type SortFilesInput = {
  by: FileFieldsInput;
  dir: DirectionsInput;
};

export type SortInput = {
  by: ContactFieldsInput;
  dir: DirectionsInput;
};

export type SortMetric =
  | 'EMISSIONS'
  | 'MISSING_RISK'
  | 'RISK'
  | 'SPEND';

export type SortingArrayInput = {
  dataColumnId: Scalars['String']['input'];
  order: SortDirectionsInput;
};

export type SortingInput = {
  field: Scalars['String']['input'];
  order: SortDirectionsInput;
};

export type SourceAndActivityFilter = {
  activityFilterActive?: InputMaybe<Scalars['Boolean']['input']>;
  sourceIdFilter?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SpecificationInput =
  | 'ACCENTURE';

export type Spend = {
  __typename: 'Spend';
  lastAvailableYear: YearlySpend;
  lastFiveYears: Array<YearlySpend>;
  total: Scalars['Float']['output'];
};

export type SpendAndCount = {
  __typename: 'SpendAndCount';
  count: Scalars['Int']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type SpendCategory = {
  __typename: 'SpendCategory';
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  spend: SpendForCategory;
  topEmittingDepartments: Array<SpendCategoryDepartmentEmissions>;
  topEmittingSubCategories: Array<SpendCategorySubCategoriesEmissions>;
  topEmittingSuppliers: SpendCategorySupplierEmissionsResponse;
  totalEmissionsPerYear: Array<TotalEmissions>;
};


export type SpendCategoryTopEmittingDepartmentsArgs = {
  electricityBasis: ElectricityBasis;
  year: Scalars['Int']['input'];
};


export type SpendCategoryTopEmittingSubCategoriesArgs = {
  electricityBasis: ElectricityBasis;
  year: Scalars['Int']['input'];
};


export type SpendCategoryTopEmittingSuppliersArgs = {
  electricityBasis: ElectricityBasis;
  year: Scalars['Int']['input'];
};


export type SpendCategoryTotalEmissionsPerYearArgs = {
  electricityBasis: ElectricityBasis;
  endYear: Scalars['Int']['input'];
  startYear: Scalars['Int']['input'];
};

export type SpendCategoryDepartmentEmissions = {
  __typename: 'SpendCategoryDepartmentEmissions';
  activityBasedShare: Scalars['Float']['output'];
  businessUnit?: Maybe<BusinessUnit>;
  changeFromLastYear?: Maybe<Scalars['Float']['output']>;
  emissions: Scalars['Float']['output'];
};

export type SpendCategorySubCategoriesEmissions = {
  __typename: 'SpendCategorySubCategoriesEmissions';
  activityBasedShare: Scalars['Float']['output'];
  category?: Maybe<SpendCategory>;
  changeFromLastYear?: Maybe<Scalars['Float']['output']>;
  emissions: Scalars['Float']['output'];
};

export type SpendCategorySubCategoriesEmissionsResponse = {
  __typename: 'SpendCategorySubCategoriesEmissionsResponse';
  categoryEmissions: Array<SpendCategorySubCategoriesEmissions>;
};

export type SpendCategorySupplierEmission = {
  __typename: 'SpendCategorySupplierEmission';
  activityBasedShare: Scalars['Float']['output'];
  changeFromLastYear?: Maybe<Scalars['Float']['output']>;
  emissions: Scalars['Float']['output'];
  supplier?: Maybe<Supplier>;
};

export type SpendCategorySupplierEmissionsResponse = {
  __typename: 'SpendCategorySupplierEmissionsResponse';
  supplierEmissions: Array<SpendCategorySupplierEmission>;
};

export type SpendColumnConfiguration = {
  dataColumnId: Scalars['String']['input'];
  globalTypeKey: Scalars['String']['input'];
};

export type SpendColumnVariant =
  | 'LAST_YEAR'
  | 'TOTAL'
  | 'YEAR';

export type SpendDataColumn = {
  __typename: 'SpendDataColumn';
  dataType: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SpendDevelopmentPeriodComparisonResult = {
  __typename: 'SpendDevelopmentPeriodComparisonResult';
  dateIntervalNumber: Scalars['Int']['output'];
  interval: DateInterval;
  valuePerYear: Array<SpendDevelopmentPeriodComparisonValue>;
};

export type SpendDevelopmentPeriodComparisonValue = {
  __typename: 'SpendDevelopmentPeriodComparisonValue';
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type SpendDevelopmentPeriodResult = {
  __typename: 'SpendDevelopmentPeriodResult';
  label: Scalars['String']['output'];
  previousPeriod: SpendDevelopmentPeriodValue;
  thisPeriod: SpendDevelopmentPeriodValue;
};

export type SpendDevelopmentPeriodValue = {
  __typename: 'SpendDevelopmentPeriodValue';
  periodStartDate: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type SpendField = {
  __typename: 'SpendField';
  field: Scalars['String']['output'];
  label: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
};

export type SpendFieldInput = {
  field: Scalars['String']['input'];
  label: Scalars['String']['input'];
  labelField?: InputMaybe<Scalars['String']['input']>;
};

export type SpendFieldValue = {
  __typename: 'SpendFieldValue';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SpendForCategory = {
  __typename: 'SpendForCategory';
  contractCoverage: Scalars['Float']['output'];
  spend: Scalars['Float']['output'];
  spendChange?: Maybe<Scalars['Float']['output']>;
};

export type SpendInterval = {
  __typename: 'SpendInterval';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type SpendIntervalType =
  | 'month'
  | 'year';

export type SpendOpportunityStatus =
  | 'ACTIVE'
  | 'DISMISSED'
  | 'SAVED';

export type SpendOpportunityType =
  | 'PAYMENT_TERMS'
  | 'UNCONTRACTED_SUPPLIER_OPPORTUNITY_SUGGESTION';

export type SpendQueryDataPoint = {
  __typename: 'SpendQueryDataPoint';
  label: Scalars['String']['output'];
  values: Array<Scalars['Float']['output']>;
};

export type SpendQueryFilters = {
  categories?: InputMaybe<Array<IdLevelPair>>;
  contracted?: InputMaybe<Scalars['Boolean']['input']>;
  departments?: InputMaybe<Array<IdLevelPair>>;
  exclude?: InputMaybe<Array<SpendTextFilter>>;
  include?: InputMaybe<Array<SpendTextFilter>>;
  suppliers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SpendQueryResult = {
  __typename: 'SpendQueryResult';
  columns: Array<Scalars['String']['output']>;
  rows: Array<SpendQueryDataPoint>;
};

export type SpendSplit = {
  __typename: 'SpendSplit';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type SpendSplitType =
  | 'category'
  | 'department';

export type SpendTextFilter = {
  field: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type SplitOperationAdapter = {
  __typename: 'SplitOperationAdapter';
  splitString: Scalars['String']['output'];
};

export type SqlDialectInput =
  | 'mssql'
  | 'mysql';

export type StartAggregatedViewInput = {
  id: Scalars['String']['input'];
};

export type StartAggregatedViewResponse = {
  __typename: 'StartAggregatedViewResponse';
  notificationId: Scalars['String']['output'];
};

export type StartAutofillGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  fields: Array<AutofillGroupStructureFieldInputInput>;
  groupStructureId: Scalars['String']['input'];
};

export type StartAutofillGroupStructureResponse = {
  __typename: 'StartAutofillGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type StartDataPipelinesByDataTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type StartDataPipelinesByDataTableIdResponse = {
  __typename: 'StartDataPipelinesByDataTableIdResponse';
  notificationIds: Array<Scalars['String']['output']>;
};

export type StartDefaultSetupInput = {
  relate: Scalars['Boolean']['input'];
  specification: SpecificationInput;
};

export type StartDefaultSetupResponse = {
  __typename: 'StartDefaultSetupResponse';
  success: Scalars['Boolean']['output'];
};

export type StartFileImportByFileIdInput = {
  deleteQueryId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
  importedBy?: InputMaybe<Scalars['String']['input']>;
};

export type StartFileImportByFileIdResponse = {
  __typename: 'StartFileImportByFileIdResponse';
  notificationId: Scalars['String']['output'];
};

export type StartFindConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindConflictingRulesForUploadResponse = {
  __typename: 'StartFindConflictingRulesForUploadResponse';
  conflictingRulesStatus: AsyncDataStatus;
  duplicateRulesStatus: AsyncDataStatus;
};

export type StartFindDanglingRulesTaskInput = {
  dataTableId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindDanglingRulesTaskResponse = {
  __typename: 'StartFindDanglingRulesTaskResponse';
  status: AsyncDataStatus;
};

export type StartPartialDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
  includedDataColumnIds: Array<Scalars['String']['input']>;
};

export type StartPartialDataPipelineResponse = {
  __typename: 'StartPartialDataPipelineResponse';
  notificationId?: Maybe<Scalars['String']['output']>;
};

export type StartSocialRiskAssessmentInput = {
  countryColumnId: Scalars['String']['input'];
  nameColumnId: Scalars['String']['input'];
  orgNumberColumnId: Scalars['String']['input'];
};

export type StartSocialRiskAssessmentResponse = {
  __typename: 'StartSocialRiskAssessmentResponse';
  ok: Scalars['Boolean']['output'];
};

export type StartSqlImportInput = {
  dataRepositoryId: Scalars['String']['input'];
  database: Scalars['String']['input'];
  dialect: SqlDialectInput;
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type StartSqlImportResponse = {
  __typename: 'StartSqlImportResponse';
  dataRepositoryId: Scalars['String']['output'];
};

export type StartTestDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type StartTestDataPipelineResponse = {
  __typename: 'StartTestDataPipelineResponse';
  dummyOutput: Scalars['String']['output'];
};

export type StaticPeriodSelection = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type Status = {
  __typename: 'Status';
  meta: Scalars['String']['output'];
  responseType: Scalars['String']['output'];
};

export type Suggestion = AddEmissionActivitySuggestion | AssessmentsFollowUpSuggestion | ContractExpiringSoonSuggestion | ContractMissingResponsibleSuggestion | MissingSocialRiskScoreSuggestion | PaymentTermsSuggestion | SocialRiskReductionSuggestion | UncontractedSupplierOpportunitySuggestion;

export type SuggestionStatus =
  | 'ACTIVE'
  | 'DISMISSED'
  | 'SAVED';

export type SuggestionType =
  | 'ADD_EMISSIONS_ACTIVITY'
  | 'ASSESSMENTS_FOLLOW_UP'
  | 'CONTRACT_EXPIRING_SOON'
  | 'CONTRACT_MISSING_RESPONSIBLE'
  | 'MISSING_SOCIAL_RISK_SCORE'
  | 'PAYMENT_TERMS'
  | 'SOCIAL_RISK_REDUCTION'
  | 'UNCONTRACTED_SUPPLIER_OPPORTUNITY_SUGGESTION';

export type SuggestionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<SuggestionStatus>>;
  types?: InputMaybe<Array<SuggestionType>>;
};

export type SuggestionsResponse = {
  __typename: 'SuggestionsResponse';
  suggestions: Array<Maybe<ISuggestion>>;
  total: Scalars['Int']['output'];
};

export type Supplier = {
  __typename: 'Supplier';
  activities: ActivitiesResponse;
  assessmentsFiles: Array<AssessmentsFile>;
  categories?: Maybe<Array<SupplierSpendCategory>>;
  contacts: Array<Contact>;
  contracts: Array<Contract>;
  country?: Maybe<Scalars['String']['output']>;
  customFields: Array<SupplierCustomField>;
  emissionFactors: Array<SupplierEmissionsForYear>;
  emissions: Array<TotalEmissions>;
  enrichments: Company;
  enrichmentsId: Scalars['String']['output'];
  files: Array<SupplierFile>;
  id: Scalars['ID']['output'];
  nace?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  normalizedSuppliers: Array<Supplier>;
  notes: Array<Note>;
  onboarding?: Maybe<Onboarding>;
  orgNumber?: Maybe<Scalars['String']['output']>;
  risk?: Maybe<Risk>;
  savingsEstimate?: Maybe<SavingsEstimate>;
  spend: Spend;
  tasks?: Maybe<Array<Task>>;
};


export type SupplierActivitiesArgs = {
  activitiesInput: SupplierActivitiesInput;
};


export type SupplierEmissionFactorsArgs = {
  emissionFactorsInput: SupplierEmissionFactorsInput;
};


export type SupplierEmissionsArgs = {
  emissionsInput: SupplierEmissionsInput;
};


export type SupplierEnrichmentsArgs = {
  enrichmentPrecedence?: InputMaybe<Array<EnrichmentType>>;
};

export type SupplierActivitiesInput = {
  electricityBasis: ElectricityBasis;
  filter?: InputMaybe<SupplierEmissionsFilter>;
  paginationInput: PaginationInput;
};

export type SupplierAssessment = {
  __typename: 'SupplierAssessment';
  assessment: Assessment;
  campaignDueDate: Scalars['String']['output'];
  campaignInitiatorId: Scalars['String']['output'];
  campaignName: Scalars['String']['output'];
};

export type SupplierColumnInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SupplierComparison = {
  __typename: 'SupplierComparison';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  totalSpend: TotalSpendComparison;
};

export type SupplierContacts = {
  __typename: 'SupplierContacts';
  contacts: Array<Contact>;
  supplierId: Scalars['String']['output'];
};

export type SupplierCustomField = {
  __typename: 'SupplierCustomField';
  dataJson: Scalars['String']['output'];
  dataType: Scalars['String']['output'];
  fieldId: Scalars['String']['output'];
  fieldType: SupplierCustomFieldType;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SupplierCustomFieldInput = {
  dataJson: Scalars['String']['input'];
  dataType?: InputMaybe<Scalars['String']['input']>;
  fieldId: Scalars['String']['input'];
  fieldType: InputFieldType;
  name: Scalars['String']['input'];
};

export type SupplierCustomFieldType =
  | 'AGGREGATION'
  | 'ASSESSMENT'
  | 'BOOLEAN'
  | 'CLASSIFICATION'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NACE'
  | 'NUMBER'
  | 'RISK'
  | 'SELECT'
  | 'SPEND'
  | 'TABLE_RELATION'
  | 'TEXT'
  | 'USER';

export type SupplierEmissionFactorsInput = {
  year: Scalars['Int']['input'];
};

export type SupplierEmissionsFilter = {
  businessUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  spendCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupplierEmissionsForYear = {
  __typename: 'SupplierEmissionsForYear';
  emissionFactorCategory: Array<Scalars['String']['output']>;
  emissionFactorInDisplayCurrency: Scalars['Float']['output'];
  emissionFactorRegion: Scalars['String']['output'];
  emissionFactorYear: Scalars['Int']['output'];
  relatedSpend: Scalars['Float']['output'];
};

export type SupplierEmissionsInput = {
  electricityBasis: ElectricityBasis;
  endYear: Scalars['Int']['input'];
  filter?: InputMaybe<SupplierEmissionsFilter>;
  startYear: Scalars['Int']['input'];
};

export type SupplierFile = {
  __typename: 'SupplierFile';
  createdAt?: Maybe<Scalars['String']['output']>;
  metaJson: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  supplierId: Scalars['String']['output'];
};

export type SupplierInput = {
  id: Scalars['String']['input'];
};

export type SupplierSortInput = {
  columnId: Scalars['String']['input'];
  direction: SortDirection;
};

export type SupplierSpendCategory = {
  __typename: 'SupplierSpendCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  spend: Scalars['Float']['output'];
};

export type SupplierSpendDevelopment = {
  __typename: 'SupplierSpendDevelopment';
  type: SpendIntervalType;
  values?: Maybe<Array<SpendInterval>>;
};

export type SupplierSpendDistribution = {
  __typename: 'SupplierSpendDistribution';
  type: SpendSplitType;
  values?: Maybe<Array<SpendSplit>>;
};

export type SupplierTableColumn = {
  __typename: 'SupplierTableColumn';
  globalType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ColumnType;
  typeOptions?: Maybe<TypeOptions>;
};

export type SupplierTableConfig = {
  __typename: 'SupplierTableConfig';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type SupplierWithNumberOfNormalized = {
  __typename: 'SupplierWithNumberOfNormalized';
  id: Scalars['ID']['output'];
  labeledDataJson: Scalars['String']['output'];
  numberOfNormalized: Scalars['Int']['output'];
};

export type SupportConsent = {
  __typename: 'SupportConsent';
  approvedAt: Scalars['String']['output'];
  approvedBy: User;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
};

export type TableColumn = IColumn & {
  __typename: 'TableColumn';
  globalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ColumnType;
};

export type TagConfiguration = {
  __typename: 'TagConfiguration';
  enumValues: Array<EnumElement>;
  id: Scalars['ID']['output'];
};

export type TagFilter = {
  tagId: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type Task = {
  __typename: 'Task';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: TaskStatus;
  supplierID: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TaskStatus =
  | 'DONE'
  | 'PENDING';

export type TemplateStringOperationAdapter = {
  __typename: 'TemplateStringOperationAdapter';
  templateString: Scalars['String']['output'];
};

export type TestDataTableConnectionInput = {
  dataTableConnection: DataTableConnectionInput;
  filtersJSON: Scalars['String']['input'];
};

export type TestDataTableConnectionResponse = {
  __typename: 'TestDataTableConnectionResponse';
  baseTableRowsWithMatch: Scalars['Int']['output'];
  matchesJSON: Scalars['String']['output'];
  numberOfTargetRowsTested: Scalars['Int']['output'];
  targetRowsWithNoMatch: Scalars['Int']['output'];
};

export type TieBreak = {
  __typename: 'TieBreak';
  field?: Maybe<Field>;
  index: Scalars['Int']['output'];
  name: TieBreakNames;
  value?: Maybe<Scalars['String']['output']>;
};

export type TieBreakInput = {
  field?: InputMaybe<FieldInput>;
  index: Scalars['Int']['input'];
  name: TieBreakNamesInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TieBreakNames =
  | 'equalTieBreak'
  | 'hasField'
  | 'inTieBreak'
  | 'larger'
  | 'max'
  | 'min'
  | 'mostFields'
  | 'mostRecent'
  | 'noHasField'
  | 'notEqualTieBreak'
  | 'notInTieBreak'
  | 'oldest'
  | 'smaller';

export type TieBreakNamesInput =
  | 'equalTieBreak'
  | 'hasField'
  | 'inTieBreak'
  | 'larger'
  | 'max'
  | 'min'
  | 'mostFields'
  | 'mostRecent'
  | 'noHasField'
  | 'notEqualTieBreak'
  | 'notInTieBreak'
  | 'oldest'
  | 'smaller';

export type TopContributorEmissionFromYear = {
  __typename: 'TopContributorEmissionFromYear';
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type TopContributorEmissions = {
  __typename: 'TopContributorEmissions';
  businessUnit?: Maybe<BusinessUnit>;
  emissionsByYear: Array<TopContributorEmissionFromYear>;
  spendCategory?: Maybe<SpendCategory>;
  supplier?: Maybe<Supplier>;
  totalEmissions: Scalars['Float']['output'];
};

export type TopContributorsResponse = {
  __typename: 'TopContributorsResponse';
  results: Array<TopContributorEmissions>;
};

export type TotalEmissions = {
  __typename: 'TotalEmissions';
  activityBasedEmissions: Scalars['Float']['output'];
  activityBasedShare: TotalEmissions_ActivityBasedShare;
  spendBasedEmissions: Scalars['Float']['output'];
  totalEmissions: TotalEmissions_TotalEmissions;
  year: Scalars['Int']['output'];
};

export type TotalEmissionsResponse = {
  __typename: 'TotalEmissionsResponse';
  results: Array<TotalEmissions>;
};

export type TotalEmissions_ActivityBasedShare = {
  __typename: 'TotalEmissions_ActivityBasedShare';
  percentChange?: Maybe<Scalars['Float']['output']>;
  previousPeriodValue?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export type TotalEmissions_TotalEmissions = {
  __typename: 'TotalEmissions_TotalEmissions';
  percentChange?: Maybe<Scalars['Float']['output']>;
  previousPeriodValue?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export type TotalSavingsEstimate = {
  __typename: 'TotalSavingsEstimate';
  count: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export type TotalSpendComparison = {
  __typename: 'TotalSpendComparison';
  previousPeriod: Scalars['Float']['output'];
  thisPeriod: Scalars['Float']['output'];
};

export type TypeOptions = ClassificationOptions | SelectOptions;

export type UnMergeInformationInput = {
  rowId: Scalars['String']['input'];
  ruleId: Scalars['Int']['input'];
  ruleType: MergeTypeInput;
};

export type UnMergedSupplier = {
  __typename: 'UnMergedSupplier';
  dataJson: Scalars['String']['output'];
  supplierID: Scalars['String']['output'];
};

export type UncontractedSupplierOpportunity = ISpendOpportunity & {
  __typename: 'UncontractedSupplierOpportunity';
  id: Scalars['String']['output'];
  largestCategoryId?: Maybe<Scalars['String']['output']>;
  largestCategoryLabel?: Maybe<Scalars['String']['output']>;
  status: SpendOpportunityStatus;
  supplier: Supplier;
  type: SpendOpportunityType;
  uncontractedSpendLTM: Scalars['Float']['output'];
};

export type UncontractedSupplierOpportunityInput = {
  id: Scalars['String']['input'];
  largestCategoryId?: InputMaybe<Scalars['String']['input']>;
  largestCategoryLabel?: InputMaybe<Scalars['String']['input']>;
  status: SpendOpportunityStatus;
  supplier: SupplierInput;
  type: SpendOpportunityType;
  uncontractedSpendLTM: Scalars['Float']['input'];
};

export type UncontractedSupplierOpportunitySuggestion = ISuggestion & {
  __typename: 'UncontractedSupplierOpportunitySuggestion';
  id: Scalars['ID']['output'];
  largestCategoryId?: Maybe<Scalars['String']['output']>;
  largestCategoryLabel?: Maybe<Scalars['String']['output']>;
  status: SuggestionStatus;
  supplier: Supplier;
  type: SuggestionType;
  uncontractedSpendLTM: Scalars['Float']['output'];
};

export type UniqueIdentifierConfiguration = {
  __typename: 'UniqueIdentifierConfiguration';
  id: Scalars['ID']['output'];
  partialReplace: Scalars['Boolean']['output'];
  repositoryId: Scalars['String']['output'];
  retroactive: Scalars['Boolean']['output'];
  uniqueIdentifierFields: Array<Scalars['String']['output']>;
};

export type UnsaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type UnsaveCategoryResponse = {
  __typename: 'UnsaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type UpdatableAggregatedViewFieldsInput = {
  aggregationsToAdd: Array<CreateAggregationInput>;
  aggregationsToDelete: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFieldsToAdd: Array<Scalars['String']['input']>;
  groupByFieldsToDelete: Array<Scalars['String']['input']>;
};

export type UpdatableDataTableFieldsInput = {
  dataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateSetting?: InputMaybe<RelativeDateSettingInput>;
};

export type UpdatableUniqueIdentifierConfigurationFieldsInput = {
  uniqueIdentifierFields: Array<Scalars['String']['input']>;
};

export type UpdateAggregatedViewInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableAggregatedViewFieldsInput;
};

export type UpdateAggregatedViewResponse = {
  __typename: 'UpdateAggregatedViewResponse';
  entity: AggregatedView;
};

export type UpdateAssessmentContactInput = {
  assessmentId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
};

export type UpdateAssessmentContactResponse = {
  __typename: 'UpdateAssessmentContactResponse';
  assessment: Assessment;
};

export type UpdateAssessmentsFileMetadataInput = {
  fileMetaJson: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
};

export type UpdateAssessmentsFileMetadataResponse = {
  __typename: 'UpdateAssessmentsFileMetadataResponse';
  fileMetaJson: Scalars['String']['output'];
};

export type UpdateAutomaticRuleInput = {
  id: Scalars['String']['input'];
  mergeSelection: MergeSelectionInput;
  name: Scalars['String']['input'];
  normalizationQuery: NormalizationQueryInput;
  precedence: Scalars['Int']['input'];
  type: RuleTypeInput;
};

export type UpdateAutomaticRuleResponse = {
  __typename: 'UpdateAutomaticRuleResponse';
  status: Scalars['String']['output'];
};

export type UpdateCampaignInput = {
  description: Scalars['String']['input'];
  dueDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subjectLine: Scalars['String']['input'];
  type: CampaignType;
};

export type UpdateCampaignResponse = {
  __typename: 'UpdateCampaignResponse';
  campaign: Campaign;
};

export type UpdateCompanySettingsInput = {
  companyColor: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgNumber: Scalars['Int']['input'];
};

export type UpdateCompanySettingsResponse = {
  __typename: 'UpdateCompanySettingsResponse';
  companyColor: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNumber: Scalars['Int']['output'];
};

export type UpdateCustomerInput = {
  customer: CustomerInput;
};

export type UpdateCustomerResponse = {
  __typename: 'UpdateCustomerResponse';
  result: Customer;
};

export type UpdateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType?: InputMaybe<DataColumnTypeInput>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnResponse = {
  __typename: 'UpdateDataColumnResponse';
  dataColumn: DataColumn;
};

export type UpdateDataColumnRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnRuleSetResponse = {
  __typename: 'UpdateDataColumnRuleSetResponse';
  dataColumnRuleSet: DataColumnRuleSet;
};

export type UpdateDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  newGroupId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type UpdateDataColumnRulesResponse = {
  __typename: 'UpdateDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateDataPipelineOperationInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  operationSpecific: Array<OperationAdapterFieldValueTupleInput>;
  operationType: OperationTypeInput;
};

export type UpdateDataPipelineOperationResponse = {
  __typename: 'UpdateDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type UpdateDataRepositoryCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataRepositoryCollectionResponse = {
  __typename: 'UpdateDataRepositoryCollectionResponse';
  dataRepositoryCollection: DataRepositoryCollection;
};

export type UpdateDataRepositoryInput = {
  autoTriggerPipelines: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  repositoryCollectionId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataRepositoryResponse = {
  __typename: 'UpdateDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type UpdateDataTableCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataTableCollectionOrderInput = {
  collectionOrder: Array<DataTableCollectionOrderItemInput>;
};

export type UpdateDataTableCollectionOrderResponse = {
  __typename: 'UpdateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type UpdateDataTableCollectionResponse = {
  __typename: 'UpdateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type UpdateDataTableInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableDataTableFieldsInput;
};

export type UpdateDataTableResponse = {
  __typename: 'UpdateDataTableResponse';
  entity: DataTable;
};

export type UpdateDataTableRowInput = {
  data: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  rowId?: InputMaybe<Scalars['String']['input']>;
  type: ChangeOperationTypeInput;
};

export type UpdateDataTableRowResponse = {
  __typename: 'UpdateDataTableRowResponse';
  id: Scalars['String']['output'];
};

export type UpdateFileMetadataInput = {
  fileMetaJson: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type UpdateFilesUploadStatusInput = {
  files: Array<FileUploadStatusInput>;
};

export type UpdateFilesUploadStatusResponse = {
  __typename: 'UpdateFilesUploadStatusResponse';
  status: Scalars['String']['output'];
};

export type UpdateFilterCombinationInput = {
  filterCombination: FilterCombinationInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFilterCombinationResponse = {
  __typename: 'UpdateFilterCombinationResponse';
  filterCombination: FilterCombination;
};

export type UpdateFilterFieldMappingInput = {
  filterFieldMapping: FilterFieldMappingInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFilterFieldMappingResponse = {
  __typename: 'UpdateFilterFieldMappingResponse';
  filterFieldMapping: FilterFieldMapping;
};

export type UpdateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupResponse = {
  __typename: 'UpdateGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateGroupStructureInput = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type UpdateGroupStructureResponse = {
  __typename: 'UpdateGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type UpdateImportConfigurationInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  id: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type UpdateImportConfigurationResponse = {
  __typename: 'UpdateImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
};

export type UpdateManualMergeAllSuppliersResponse = {
  __typename: 'UpdateManualMergeAllSuppliersResponse';
  status: Scalars['String']['output'];
};

export type UpdateMappingOperationElementInput = {
  elementId?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['String']['input'];
  operationId: Scalars['String']['input'];
  to: Scalars['String']['input'];
  updateType: UpdateMappingOperationElementTypeInput;
};

export type UpdateMappingOperationElementResponse = {
  __typename: 'UpdateMappingOperationElementResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type UpdateMappingOperationElementTypeInput =
  | 'ADD'
  | 'EDIT'
  | 'REMOVE';

export type UpdateOrCreateDataPipelineLayoutInput = {
  dataTableId: Scalars['String']['input'];
  layoutJson: Scalars['String']['input'];
};

export type UpdateOrCreateDataPipelineLayoutResponse = {
  __typename: 'UpdateOrCreateDataPipelineLayoutResponse';
  dataPipelineLayout: DataPipelineLayout;
};

export type UpdatePipelineFilterInput = {
  filterDataType: FilterDataTypeInput;
  id: Scalars['String']['input'];
  operator: FilterOperatorTypeInput;
  sourceId: Scalars['String']['input'];
  sourceType: InputOutputNodeTypeInput;
  value: Scalars['String']['input'];
};

export type UpdatePipelineFilterResponse = {
  __typename: 'UpdatePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type UpdateQuestionInput = {
  question: QuestionInput;
};

export type UpdateQuestionResponse = {
  __typename: 'UpdateQuestionResponse';
  question: Question;
};

export type UpdateQuestionnaireInput = {
  questionnaire: QuestionnaireInput;
};

export type UpdateQuestionnaireResponse = {
  __typename: 'UpdateQuestionnaireResponse';
  questionnaire: Questionnaire;
};

export type UpdateSavingsEstimateStatusInput = {
  status: SavingsEstimateStatus;
  supplierId: Scalars['ID']['input'];
};

export type UpdateSectionInput = {
  section: SectionInput;
};

export type UpdateSectionResponse = {
  __typename: 'UpdateSectionResponse';
  section: Section;
};

export type UpdateSuggestionStatusInput = {
  id: Scalars['String']['input'];
  status: SuggestionStatus;
};

export type UpdateSuggestionStatusResponse = {
  __typename: 'UpdateSuggestionStatusResponse';
  suggestion: ISuggestion;
};

export type UpdateSupplierFieldInput = {
  fieldData: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdateSupplierFieldResponse = {
  __typename: 'UpdateSupplierFieldResponse';
  supplier: Supplier;
};

export type UpdateSupplierFileMetadataInput = {
  fileMetaJson: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};

export type UpdateSupplierFileMetadataResponse = {
  __typename: 'UpdateSupplierFileMetadataResponse';
  fileMetaJson: Scalars['String']['output'];
};

export type UpdateSupplierNoteInput = {
  id: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type UpdateSupplierNoteResponse = {
  __typename: 'UpdateSupplierNoteResponse';
  updatedNote: Note;
};

export type UpdateSupplierTableColumnInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateSupplierTableColumnResponse = {
  __typename: 'UpdateSupplierTableColumnResponse';
  column: SupplierTableColumn;
};

export type UpdateSupplierTableConfigInput = {
  displayName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type UpdateSupplierTableConfigResponse = {
  __typename: 'UpdateSupplierTableConfigResponse';
  supplierTableConfig: SupplierTableConfig;
};

export type UpdateSuppliersInput = {
  fields: Array<MappedFileFieldInput>;
  fileSettings: FileSettingsInput;
  userId: Scalars['String']['input'];
};

export type UpdateSuppliersResponse = {
  __typename: 'UpdateSuppliersResponse';
  error?: Maybe<Scalars['String']['output']>;
  notificationId?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type UpdateTaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskResponse = {
  __typename: 'UpdateTaskResponse';
  task: Task;
};

export type UpdateUnMergeAllSuppliersResponse = {
  __typename: 'UpdateUnMergeAllSuppliersResponse';
  status: Scalars['String']['output'];
};

export type UpdateUnMergeSuppliersInput = {
  rules: Array<UnMergeInformationInput>;
};

export type UpdateUnMergeSuppliersResponse = {
  __typename: 'UpdateUnMergeSuppliersResponse';
  status: Scalars['String']['output'];
};

export type UpdateUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableUniqueIdentifierConfigurationFieldsInput;
};

export type UpdateUniqueIdentifierConfigurationResponse = {
  __typename: 'UpdateUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type UploadRulesInput = {
  caseSensitiveGroupMatch: Scalars['Boolean']['input'];
  classificationMode: ClassificationModeInput;
  createUnmatchedGroups: Scalars['Boolean']['input'];
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  groupStructureId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  overwriteExistingRules: OverwriteExistingRulesInput;
  targetDataColumnId: Scalars['String']['input'];
  tempUploadToMigrationRuleset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UploadRulesResponse = {
  __typename: 'UploadRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UpsertCompanyFavouriteFiltersInput = {
  fields: Array<FilterFieldInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCompanyFavouriteFiltersResponse = {
  __typename: 'UpsertCompanyFavouriteFiltersResponse';
  favourites: CompanyFavouriteFilters;
};

export type UpsertContactsInput = {
  contacts: Array<ContactInput>;
};

export type UpsertContactsResponse = {
  __typename: 'UpsertContactsResponse';
  result: Array<Contact>;
};

export type UpsertDefaultFilterCombinationInput = {
  filterCombinationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertDefaultFilterCombinationResponse = {
  __typename: 'UpsertDefaultFilterCombinationResponse';
  filterCombinationId?: Maybe<Scalars['String']['output']>;
};

export type UpsertFilteredSpendOpportunityInput = {
  paymentTermsOpportunity?: InputMaybe<PaymentTermsOpportunityInput>;
  uncontractedSupplierOpportunity?: InputMaybe<UncontractedSupplierOpportunityInput>;
};

export type UpsertFilteredSpendOpportunityResponse = {
  __typename: 'UpsertFilteredSpendOpportunityResponse';
  id: Scalars['String']['output'];
};

export type UpsertPersonalFavouriteFiltersInput = {
  fields: Array<FilterFieldInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertPersonalFavouriteFiltersResponse = {
  __typename: 'UpsertPersonalFavouriteFiltersResponse';
  favourites: PersonalFavouriteFilters;
};

export type UpsertPinnedFieldsInput = {
  associatedId: Scalars['String']['input'];
  pinnedFields: Array<PinnedFieldInput>;
};

export type UpsertRolesInput = {
  roles: Array<RoleInput>;
};

export type UpsertRolesResponse = {
  __typename: 'UpsertRolesResponse';
  result: Array<Role>;
};

export type User = {
  __typename: 'User';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type UserIdentifier = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateFileInput = {
  fileId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
};

export type ValidateFileResponse = {
  __typename: 'ValidateFileResponse';
  fileId: Scalars['String']['output'];
};

export type ValueObject = {
  __typename: 'ValueObject';
  value: Scalars['Float']['output'];
  valueAsString: Scalars['String']['output'];
};

export type WhoAmIResponse = {
  __typename: 'WhoAmIResponse';
  customer: Customer;
};

export type XlsxConfiguration = {
  __typename: 'XlsxConfiguration';
  dataStartIndex?: Maybe<Scalars['Int']['output']>;
  headerRowIndex?: Maybe<Scalars['Int']['output']>;
  numberOfEndLinesToDrop?: Maybe<Scalars['Int']['output']>;
};

export type XlsxConfigurationInput = {
  dataStartIndex?: InputMaybe<Scalars['Int']['input']>;
  headerRowIndex?: InputMaybe<Scalars['Int']['input']>;
  numberOfEndLinesToDrop?: InputMaybe<Scalars['Int']['input']>;
};

export type AddNoteReactionInput = {
  emojiCode: Scalars['String']['input'];
  noteId: Scalars['String']['input'];
};

export type AddNoteReactionResponse = {
  __typename: 'addNoteReactionResponse';
  note: Note;
};

export type FinancialStatement = {
  __typename: 'financialStatement';
  accountType?: Maybe<Scalars['String']['output']>;
  auditorComments?: Maybe<Array<Maybe<AuditorComment>>>;
  cashDeposits?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currentAssets?: Maybe<Scalars['Float']['output']>;
  currentLiabilities?: Maybe<Scalars['Float']['output']>;
  currentRatio?: Maybe<Scalars['Float']['output']>;
  debtRatio?: Maybe<Scalars['Float']['output']>;
  ebit?: Maybe<Scalars['Float']['output']>;
  ebitMargin?: Maybe<Scalars['Float']['output']>;
  ebitda?: Maybe<Scalars['Float']['output']>;
  ebt?: Maybe<Scalars['Float']['output']>;
  equityProfitability?: Maybe<Scalars['Float']['output']>;
  equityRatio?: Maybe<Scalars['Float']['output']>;
  financialIncome?: Maybe<Scalars['Float']['output']>;
  fixedAssets?: Maybe<Scalars['Float']['output']>;
  intangibleAssets?: Maybe<Scalars['Float']['output']>;
  netIncome?: Maybe<Scalars['Float']['output']>;
  operatingExpenses?: Maybe<Scalars['Float']['output']>;
  operatingProfit?: Maybe<Scalars['Float']['output']>;
  profitMargin?: Maybe<Scalars['Float']['output']>;
  quickRatio?: Maybe<Scalars['Float']['output']>;
  returnOnAssets?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  totalAssets?: Maybe<Scalars['Float']['output']>;
  totalEquity?: Maybe<Scalars['Float']['output']>;
  totalLiabilities?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Join__Graph =
  | 'ANALYSIS'
  | 'ANALYSIS_LEGACY'
  | 'ASSESSMENTS'
  | 'AUTH_SIDECAR'
  | 'CARBON'
  | 'CLASSIFICATION'
  | 'COLLABORATION'
  | 'COMPANY_MASTER'
  | 'CONTACTS'
  | 'CONTRACTS'
  | 'CUSTOMERS'
  | 'DATA_INTEGRATION'
  | 'DATA_MANAGEMENT'
  | 'DATA_MANAGEMENT_SETUP'
  | 'FILTERS'
  | 'OLD_GATEWAY'
  | 'REDUCE'
  | 'SPEND'
  | 'SUPPLIERS'
  | 'SUPPLIER_NORMALIZATION';

export type Link__Purpose =
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  | 'EXECUTION'
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  | 'SECURITY';

export type RemoveNoteReactionInput = {
  emojiCode: Scalars['String']['input'];
  noteId: Scalars['String']['input'];
};

export type RemoveNoteReactionResponse = {
  __typename: 'removeNoteReactionResponse';
  note: Note;
};

export type YearlySpend = {
  __typename: 'yearlySpend';
  columnId: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type GetDataTableElasticFieldsQueryVariables = Exact<{
  input: GetDataTableElasticFieldsInput;
}>;


export type GetDataTableElasticFieldsQuery = { __typename: 'Query', getDataTableElasticFields: { __typename: 'GetDataTableElasticFieldsResponse', elasticFields: Array<{ __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, max?: number | null, min?: number | null, maxDateForRelativeFilters?: number | null, elasticIndex: string }> } };

export type ElasticFieldItemFieldsFragment = { __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, max?: number | null, min?: number | null, maxDateForRelativeFilters?: number | null, elasticIndex: string };

export type PinnedFieldFieldsFragment = { __typename: 'PinnedField', dataSource: string, field: string };

export type GetPinnedFieldsQueryVariables = Exact<{
  input: GetPinnedFieldsInput;
}>;


export type GetPinnedFieldsQuery = { __typename: 'Query', getPinnedFields: { __typename: 'PinnedFieldsResponse', associatedId: string, pinnedFields: Array<{ __typename: 'PinnedField', dataSource: string, field: string }> } };

export type UpsertPinnedFieldsMutationVariables = Exact<{
  input: UpsertPinnedFieldsInput;
}>;


export type UpsertPinnedFieldsMutation = { __typename: 'Mutation', upsertPinnedFields: { __typename: 'PinnedFieldsResponse', associatedId: string, pinnedFields: Array<{ __typename: 'PinnedField', dataSource: string, field: string }> } };

export type GetPivotQueryVariables = Exact<{
  input: GetPivotInput;
}>;


export type GetPivotQuery = { __typename: 'Query', getPivot: { __typename: 'GetPivotResponse', pivotResponse: string } };

export type ExportDataTableToFileMutationVariables = Exact<{
  input: ExportDataTableToFileInput;
}>;


export type ExportDataTableToFileMutation = { __typename: 'Mutation', exportDataTableToFile: { __typename: 'ExportDataTableToFileResponse', notificationId: string } };

export type GetManyDataTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyDataTablesQuery = { __typename: 'Query', getManyDataTables: { __typename: 'GetManyDataTablesResponse', entities: Array<{ __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string }> } };

export type DataTableFieldsFragment = { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string };

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;


export type GetUsersQuery = { __typename: 'Query', getUsers: { __typename: 'GetUsersResponse', result: Array<{ __typename: 'RoleUser', id: string, firstName: string, lastName: string, email: string }> } };

export type UserFragment = { __typename: 'RoleUser', id: string, firstName: string, lastName: string, email: string };

export const ElasticFieldItemFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ElasticFieldItemFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ElasticFieldItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeKey"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeSubKey"}},{"kind":"Field","name":{"kind":"Name","value":"labelField"}},{"kind":"Field","name":{"kind":"Name","value":"labelFieldType"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"maxDateForRelativeFilters"}},{"kind":"Field","name":{"kind":"Name","value":"elasticIndex"}}]}}]} as unknown as DocumentNode<ElasticFieldItemFieldsFragment, unknown>;
export const PinnedFieldFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PinnedFieldFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PinnedField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSource"}},{"kind":"Field","name":{"kind":"Name","value":"field"}}]}}]} as unknown as DocumentNode<PinnedFieldFieldsFragment, unknown>;
export const DataTableFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DataTableFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DataTable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeKey"}},{"kind":"Field","name":{"kind":"Name","value":"elasticIndex"}}]}}]} as unknown as DocumentNode<DataTableFieldsFragment, unknown>;
export const UserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RoleUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]} as unknown as DocumentNode<UserFragment, unknown>;
export const GetDataTableElasticFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getDataTableElasticFields"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetDataTableElasticFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getDataTableElasticFields"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"elasticFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ElasticFieldItemFields"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ElasticFieldItemFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ElasticFieldItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeKey"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeSubKey"}},{"kind":"Field","name":{"kind":"Name","value":"labelField"}},{"kind":"Field","name":{"kind":"Name","value":"labelFieldType"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"maxDateForRelativeFilters"}},{"kind":"Field","name":{"kind":"Name","value":"elasticIndex"}}]}}]} as unknown as DocumentNode<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>;
export const GetPinnedFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPinnedFields"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPinnedFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPinnedFields"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"associatedId"}},{"kind":"Field","name":{"kind":"Name","value":"pinnedFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PinnedFieldFields"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PinnedFieldFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PinnedField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSource"}},{"kind":"Field","name":{"kind":"Name","value":"field"}}]}}]} as unknown as DocumentNode<GetPinnedFieldsQuery, GetPinnedFieldsQueryVariables>;
export const UpsertPinnedFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpsertPinnedFields"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertPinnedFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertPinnedFields"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"associatedId"}},{"kind":"Field","name":{"kind":"Name","value":"pinnedFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PinnedFieldFields"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PinnedFieldFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PinnedField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSource"}},{"kind":"Field","name":{"kind":"Name","value":"field"}}]}}]} as unknown as DocumentNode<UpsertPinnedFieldsMutation, UpsertPinnedFieldsMutationVariables>;
export const GetPivotDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPivot"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPivotInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPivot"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pivotResponse"}}]}}]}}]} as unknown as DocumentNode<GetPivotQuery, GetPivotQueryVariables>;
export const ExportDataTableToFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"exportDataTableToFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExportDataTableToFileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportDataTableToFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationId"}}]}}]}}]} as unknown as DocumentNode<ExportDataTableToFileMutation, ExportDataTableToFileMutationVariables>;
export const GetManyDataTablesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getManyDataTables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getManyDataTables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DataTableFields"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DataTableFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DataTable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"globalTypeKey"}},{"kind":"Field","name":{"kind":"Name","value":"elasticIndex"}}]}}]} as unknown as DocumentNode<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>;
export const GetUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"User"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RoleUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;