import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";

import { ExportFunc } from "@/hooks/useExport/interfaces";

const ExportDashboardContext = React.createContext<{
    exportFuncByWidgetId: Record<string, ExportFunc>;
    exporting: boolean;
    setExporting: (val: boolean) => void;
    updateExportFuncForWidget: (exportFunc: ExportFunc, widgetId: string) => void;
} | null>(null);

export const ExportDashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { dashboardId } = useParams<{ dashboardId?: string }>();

    const [exporting, setExporting] = useState(false);
    const [exportFuncByWidgetId, setExportFuncByWidgetId] = useState<Record<string, ExportFunc>>({});

    useEffect(
        function resetState() {
            if (dashboardId) {
                setExportFuncByWidgetId({});
            }
        },
        [dashboardId]
    );

    const updateExportFuncForWidget = useCallback(
        (exportFunc: ExportFunc, widgetId: string) =>
            setExportFuncByWidgetId((prevState) => ({
                ...prevState,
                [widgetId]: exportFunc,
            })),
        []
    );

    const values = useMemo(
        () => ({
            exportFuncByWidgetId,
            exporting,
            setExporting,
            updateExportFuncForWidget,
        }),
        [exportFuncByWidgetId, exporting, setExporting, updateExportFuncForWidget]
    );

    return <ExportDashboardContext.Provider value={values}>{children}</ExportDashboardContext.Provider>;
};

export const useExportDashboardContextOrThrow = () => {
    const context = useContext(ExportDashboardContext);
    if (!context) {
        throw Error("useExportDashboardContextOrThrow can only be used within a ExportDashboardProvider");
    }
    return context;
};
