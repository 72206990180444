import { ElasticField, isDateField } from "@ignite-analytics/elastic-fields";
import { StaticDateFilter } from "@ignite-analytics/filters";
import { TRANSACTION_DATE_GT, TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import { AnalysisQuery, ValueAggregationItem } from "@ignite-analytics/pivot-ts";
import moment from "moment";

import { DATE_FORMAT } from "../constants";

import messages from "./messages";

import { LargestFieldWidget } from "@/components/Widgets/interfaces";
import { fm } from "@/contexts/IntlContext";
import { formatEpochTimestampToDateString } from "@/lib/dateHelpers";

const getInaccurateDates = (period: string, maxDate: moment.Moment) => {
    const currentDate = maxDate;

    if (period === "month") {
        const prev = currentDate.clone().subtract(1, "months");
        return {
            currentTo: currentDate,
            currentFrom: currentDate,
            previousTo: prev,
            previousFrom: prev,
        };
    }
    if (period === "quarter") {
        return {
            currentTo: currentDate,
            currentFrom: currentDate.clone().subtract(2, "months"),
            previousTo: currentDate.clone().subtract(3, "months"),
            previousFrom: currentDate.clone().subtract(5, "months"),
        };
    }
    if (period === "year") {
        return {
            currentTo: currentDate,
            currentFrom: currentDate.clone().subtract(11, "months"),
            previousTo: currentDate.clone().subtract(1, "years"),
            previousFrom: currentDate.clone().subtract(23, "months"),
        };
    }
    return undefined;
};

const getDateRanges = (period: string, maxDate: moment.Moment) => {
    const dates = getInaccurateDates(period, maxDate);
    if (!dates) return;
    const { currentTo, currentFrom, previousTo, previousFrom } = dates;
    return {
        currentFrom: currentFrom.startOf("month").format(DATE_FORMAT),
        currentTo: currentTo.endOf("month").format(DATE_FORMAT),
        previousFrom: previousFrom.startOf("month").format(DATE_FORMAT),
        previousTo: previousTo.endOf("month").format(DATE_FORMAT),
    };
};

export const getLargestFieldsQuery = (
    widget: LargestFieldWidget,
    fields: ElasticField[]
): AnalysisQuery | undefined => {
    const transactionDateField =
        fields.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT)?.field || "transaction_date";
    const transactionValueField =
        fields.find((field) => field.globalTypeKey === TRANSACTION_VALUE_GT)?.field || "transaction_value";
    const elasticDateField = fields.find((field) => isDateField(field) && field.field === transactionDateField);
    const maxRelativeDate =
        elasticDateField && isDateField(elasticDateField) && elasticDateField.maxDateForRelativeFilters?.value
            ? formatEpochTimestampToDateString(elasticDateField.maxDateForRelativeFilters.value)
            : new Date().toISOString();
    const maxDate = moment(maxRelativeDate);

    const ranges = getDateRanges(widget.period, maxDate);
    if (!ranges) return undefined;
    const { currentTo, currentFrom, previousTo, previousFrom } = ranges;

    const previousPeriodFilter: StaticDateFilter = {
        field: transactionDateField,
        fieldId: transactionDateField,
        filterType: "datefilter",
        start: previousFrom,
        end: previousTo,
        type: "date",
    };
    const thisPeriodFilter: StaticDateFilter = {
        field: transactionDateField,
        fieldId: transactionDateField,
        filterType: "datefilter",
        start: currentFrom,
        end: currentTo,
        type: "date",
    };

    const [previous, current] = [previousPeriodFilter, thisPeriodFilter].map(
        (f): ValueAggregationItem => ({
            field: transactionValueField || "transaction_value",
            type: "float",
            filters: [f],
            aggregation: "sum",
        })
    );

    return {
        columnSplitItems: widget.columnSplitItems,
        rowSplitItems: [],
        valueAggregationItems: [
            current,
            { ...previous, visible: false },
            {
                id: NaN,
                type: "script",
                params: { current, previous },
                expression: "(params.current-params.previous)/params.previous",
                label: fm(messages.percentageChange).toString(),
            },
        ],
        elasticIndex: fields[0]?.elasticIndex,
    };
};
