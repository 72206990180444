import { isNotNullish } from "@ignite-analytics/general-tools";
import { Chip, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import messages from "./messages";

import { UserFragment } from "@/__generated__/graphql";
import { fm } from "@/contexts/IntlContext";
import { useUsers } from "@/hooks/useUsers";

type Props = {
    userIds: string[];
    onChange?: (users: UserFragment[]) => void;
};

const UserFilter = ({ userIds, onChange }: Props) => {
    const { users, setSearchTerm } = useUsers(10, userIds);
    return (
        <Stack sx={{ width: "300px" }}>
            <Autocomplete
                multiple
                options={users.map((option) => ({
                    label: `${option.firstName} ${option.lastName}`,
                    id: option.id,
                }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                defaultValue={[]}
                onInputChange={(_, value) => setSearchTerm(value)}
                onChange={(_, value) => {
                    const matches = value.map((v) => users.find((u) => u.id === v.id)).filter(isNotNullish);
                    onChange?.(matches);
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index: number) => (
                        <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={fm(messages.filterUsersLabel)}
                        placeholder={fm(messages.filterUsersPlaceholder).toString()}
                    />
                )}
            />
        </Stack>
    );
};

export default UserFilter;
