import { defineMessages } from "react-intl";

const messages = defineMessages({
    tooltipTitle: {
        id: "components.analysisOptions.tooltipTitle",
        defaultMessage: "Options",
    },
    options: {
        id: "components.analysisOptions.options",
        defaultMessage: "Options",
    },
});

export default messages;
