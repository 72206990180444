import { defineMessages } from "react-intl";

const messages = defineMessages({
    chartTitle: {
        id: "prioritize.supWithMultDepartmentsChartHeader",
        defaultMessage: "Largest suppliers by department spend",
    },
});

export default messages;
