import React, { ReactNode } from "react";

import { DEFAULT_LOADER } from "./constants";

import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import {
    BackendPermissionType,
    hasObjectPermission,
    ModelGuard,
    PermissionRelation,
    usePermission,
} from "@/lib/permissions";

interface Props {
    requiredPermissionTypes: BackendPermissionType[];
    equivalentUserPermission: { namespace: string; relation: PermissionRelation };
    guard?: number | null;
    modelGuard?: ModelGuard;
    renderOnPermissionDenied?: ReactNode;
    children: ReactNode;
    loader?: JSX.Element | null;
}

const PermissionContainer: React.FC<Props> = ({
    requiredPermissionTypes,
    equivalentUserPermission,
    children,
    renderOnPermissionDenied = null,
    guard,
    loader = DEFAULT_LOADER,
    modelGuard,
}) => {
    const userId = useOryUserIdOrThrow();

    const hasOryPermission = usePermission(equivalentUserPermission.namespace, equivalentUserPermission.relation);
    const isPrivilegedUser = usePermission("dashboards", { object: "privileged", relation: "write" });

    if (!hasOryPermission) return renderOnPermissionDenied as JSX.Element;
    if (!guard) {
        return children as JSX.Element;
    }

    /** Return loader if objectPermissions is not loaded yet */
    if (!modelGuard || !userId) return loader;

    // If users have a privileged write permission it will bypass the object permission check
    if (isPrivilegedUser) {
        return children as JSX.Element;
    }

    return hasObjectPermission(modelGuard, requiredPermissionTypes, userId)
        ? (children as JSX.Element)
        : (renderOnPermissionDenied as JSX.Element);
};

export default PermissionContainer;
