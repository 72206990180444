import { defineMessages } from "react-intl";

const messages = defineMessages({
    categoryLevelPH: {
        id: "components.filter.customFields.categoryLevelPH",
        defaultMessage: "Select a value",
    },
    categoryLevelLabel: {
        id: "components.filter.customFields.categoryLevel",
        defaultMessage: "Category level",
    },
    numSuppliersLabel: {
        id: "components.filter.customFields.numSuppliersLabel",
        defaultMessage: "Number of suppliers to evaluate (by spend)",
        description: "Filter options field",
    },
    placeholderSupNum: {
        id: "riskRatingPage.numSuppliersField",
        defaultMessage: "Set the number of suppliers",
        description: "Placeholder for 'number of supplier' field",
    },
    minimumSpendLTM: {
        id: "components.filter.customFields.MinimumSpendLTM",
        defaultMessage: "Supplier spend limit (minimum) LTM",
        description: "Filter options field",
    },
    MinSpendLTMMillions: {
        id: "components.filter.customFields.MinSpendLTMMillions",
        defaultMessage: "Minimum supplier spend LTM",
        description: "Filter options field",
    },
    placeholderMinSpend: {
        id: "riskRatingPage.minSpendField",
        defaultMessage: "Set value",
        description: "Placeholder for 'min spend final year' field",
    },
    placeholderNumYears: {
        id: "potentialRatingPage.CagrYears",
        defaultMessage: "Set the number of years",
        description: "Placeholder for 'time period cagr (years)' field",
    },
    cagrYearsLabel: {
        id: "potentialRatingPage.cagrYearsLabel",
        defaultMessage: "Time period CAGR (years)",
    },
    month12: {
        id: "components.widgets.fieldHistoryWidget.month12",
        defaultMessage: "12 months",
    },
    month3: {
        id: "components.widgets.fieldHistoryWidget.month3",
        defaultMessage: "3 months",
    },
    month1: {
        id: "components.widgets.fieldHistoryWidget.month1",
        defaultMessage: "1 month",
    },
    week: {
        id: "components.widgets.fieldHistoryWidget.week",
        defaultMessage: "1 week",
    },
    day: {
        id: "components.widgets.fieldHistoryWidget.day",
        defaultMessage: "1 day",
    },
    widgetPeriodLabel: {
        id: "components.widgets.fieldHistoryWidget.widgetPeriodLabel",
        defaultMessage: "Analysis period",
    },
    widgetPeriodPH: {
        id: "components.widgets.fieldHistoryWidget.widgetPeriodPH",
        defaultMessage: "Select period",
    },
    widgetDateIntervalLabel: {
        id: "components.widgets.mintecCommodityWidget.widgetDateIntervalLabel",
        defaultMessage: "Date interval",
    },
    widgetDateIntervalPH: {
        id: "components.widgets.mintecCommodityWidget.widgetDateIntervalPH",
        defaultMessage: "Select interval",
    },
    numElementsLabel: {
        id: "components.filter.customFields.numElementsLabel",
        defaultMessage: "Number of elements",
    },
    numElementsPH: {
        id: "components.filter.customFields.numElementsPH",
        defaultMessage: "Select number of elements",
    },
});

export default messages;
