import { Undo as RefreshIcon } from "@ignite-analytics/icons";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import { NETWORK_ERROR, NETWORK_ERROR_STRING } from "./constants";
import messages from "./messages";

import { fm } from "@/contexts/IntlContext";

/**
 * ErrorAlerts
 *
 * Display a column with alerts for each error in the error list
 * See `helpers.ts` for creating the error list from a caught error, or
 * from an error object ({ [key: string]: string[] })
 *
 */

interface Props {
    errors: string[];
    onUpdateData: () => void;
}

const ErrorAlerts: React.FC<Props> = ({ errors, onUpdateData }) => {
    const isNetworkError =
        errors.join().includes(NETWORK_ERROR) ||
        errors
            .reduce((acc, error) => {
                return `${acc} ${error.toLowerCase()}`.trim();
            }, "")
            .includes(NETWORK_ERROR_STRING);

    const getErrorMessage = () => {
        if (isNetworkError) {
            return fm(messages.networkError);
        }
        if (errors.length > 0) {
            return errors.join(", ");
        }
        return fm(messages.genericServerError);
    };

    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="center" sx={{ width: "100%" }}>
            <Stack direction="row" alignItems="center">
                <Typography variant="caption" color="error">
                    <b>{getErrorMessage()}</b>
                </Typography>
                {isNetworkError && (
                    <IconButton onClick={() => onUpdateData()}>
                        <RefreshIcon />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
};

export default ErrorAlerts;
