import { FilterModal } from "@ignite-analytics/filters";
import {
    Clone as ContentCopyIcon,
    Trash as DeleteIcon,
    DotsVertical,
    Drag as DragIndicatorIcon,
    ArrowDownTray as FileDownloadIcon,
    Filter,
    Compress as FullscreenExitIcon,
    Expand as FullscreenIcon,
    Gear,
} from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import DeleteModal from "../../../components/Widgets/Components/DeleteModal";
import { StyledCardHeader } from "../style";

import { getCorrectWidth } from "./helpers";
import WidgetEditor from "./WidgetEditor";

import chartContainerMessages from "@/components/Charts/ChartContainer/messages";
import { OverflowTooltip } from "@/components/OverflowTooltip";
import PermissionContainer from "@/components/PermissionContainer";
import { getWidgetByType } from "@/components/Widgets";
import SaveAsNewModal from "@/components/Widgets/AnalysisCard/SaveAsNewModal";
import { ANALYSIS_NEW, FIELD_DISTRIBUTION_NEW, TEXT_WIDGET } from "@/components/Widgets/constants";
import { isDeprecatedWidget, Widget, WidgetProps } from "@/components/Widgets/interfaces";
import messages from "@/components/Widgets/messages";
import { widgetToId, widgetToLayout } from "@/containers/CustomDashboardPage/DashboardContainer/Dashboard/helpers";
import {
    useActiveDashboardWidget,
    useDashboardContextOrThrow,
} from "@/containers/CustomDashboardPage/DashboardContext";
import { useEventContext } from "@/contexts/EventContext";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/IntlContext";
import { useModelGuard } from "@/entities/modelGuards";
import { ExportImageResult, ExportMode } from "@/hooks/useExport/interfaces";

interface Props<W extends Widget> {
    widget: W;
    widgetProps: WidgetProps<W, object>;
    elasticIndex?: string;
    index: number;
    hideButtons?: boolean;
    widgetHover?: boolean;
}

const WIDGET_HEADER_PREFIX = "hoc-widgetHeader-";

const WidgetHeader = <W extends Widget>({ widget, widgetProps, elasticIndex, index, widgetHover }: Props<W>) => {
    const [openModal, setOpenModal] = useState<boolean>();
    const ref = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const navigate = useNavigate();
    const eventContextDispatch = useEventContext();
    const { dashboard, editing, setLayouts } = useDashboardContextOrThrow();
    const { exportFuncByWidgetId } = useExportDashboardContextOrThrow();
    const modelGuard = useModelGuard(dashboard?.guard ?? NaN, undefined, undefined, {
        service: "dashboards",
    });
    const [showCopyModal, setShowCopyModal] = useState(false);
    const widgetType = getWidgetByType(widget.type);
    const { openConfig, isNew } = widget;
    const activeWidgetState = useActiveDashboardWidget(widget);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const informUser = useInformUser();

    useEffect(
        function resetExpandedStateWhenEditing() {
            if (editing) {
                setExpanded(false);
            }
        },
        [editing, setExpanded]
    );

    const toggleExpanded = () => {
        const shouldExpand = !expanded;
        const layoutId = widgetToId(widget);
        if (shouldExpand) track("Widget: Expand widget", { placement: "Widget header HOC", title: widget.title });

        setLayouts((prevLayouts) =>
            prevLayouts.map((layout) => {
                if (layout.i !== layoutId) return layout;
                const resizedLayout = shouldExpand
                    ? {
                          ...layout,
                          w: 12,
                          h: 10,
                      }
                    : widgetToLayout(widget);
                setTimeout(() => {
                    eventContextDispatch?.("onWidgetResize", resizedLayout);
                    window.dispatchEvent(new Event("resize"));
                }, 100);
                return resizedLayout;
            })
        );
        setExpanded((prev) => !prev);
    };

    useEffect(
        function setInitialState() {
            if (openModal === undefined && (openConfig || isNew)) {
                setOpenModal(true);
            }
        },
        [openConfig, openModal, isNew]
    );

    const SHARE_OPTIONS: { value: ExportMode; message: string; active: boolean }[] = [
        {
            value: "excel",
            message: fm(chartContainerMessages.tooltipSaveDataAsExcel).toString(),
            active: widgetType && "excelExport" in widgetType && widgetType.excelExport,
        },
        {
            value: "csv",
            message: fm(chartContainerMessages.tooltipSaveDataAsCsv).toString(),
            active: widgetType && "excelExport" in widgetType && widgetType.excelExport,
        },
        {
            value: "image",
            message: fm(chartContainerMessages.tooltipSaveImg).toString(),
            active: true,
        },
    ];

    const handleCopyWidgetClicked = useCallback(() => {
        track("Copy widget", { placement: "Widget header", type: widget.type, title: widget.title });
    }, [widget.title, widget.type]);

    const [widgetMenuAnchorEl, setWidgetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openWidgetMenu = Boolean(widgetMenuAnchorEl);
    const openExportMenu = Boolean(exportMenuAnchorEl);

    const handleOpenWidgetMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setWidgetMenuAnchorEl(e.currentTarget);
    };
    const handleOpenExportMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setExportMenuAnchorEl(e.currentTarget);
    };

    const handleCloseWidgetMenu = () => setWidgetMenuAnchorEl(null);

    const handleCloseExportMenu = () => setExportMenuAnchorEl(null);

    const handleSelect = (mode: ExportMode) => {
        track("Export data", { mode, placement: "Widget header HOC", title: widget.title });
        let exportPromise: Promise<void> | Promise<ExportImageResult> = Promise.resolve();
        const widgetId = widget.id?.toString();
        const exportFunc = widgetId ? exportFuncByWidgetId[widgetId] : undefined;
        if (!exportFunc) {
            Sentry.captureMessage("User tried to export a widget that had not propagated exportFunc", {
                tags: {
                    mode,
                    widgetId,
                    app: "dashboards-app",
                },
            });
            informUser({ type: "error", message: fm(messages.somethingWentWrongExportingAnalysis), timeout: 10000 });
            return;
        }
        switch (mode) {
            case "excel":
            case "csv":
                exportPromise = exportFunc({
                    exportType: mode,
                    chartConfig: !isDeprecatedWidget(widget) ? widget.chartConfiguration : undefined,
                });
                break;
            case "image":
                exportPromise = exportFunc({
                    exportType: mode,
                    options: "config" in widget ? widget.config : {},
                });
                break;
        }
        exportPromise
            .then(() => {
                handleCloseExportMenu();
                handleCloseWidgetMenu();
            })
            .catch((errMessage) => {
                if (typeof errMessage === "string") {
                    informUser({ type: "error", message: errMessage });
                    return;
                }
                Sentry.captureException(errMessage, {
                    tags: {
                        message: "Unhandled error while exporting widget",
                        mode,
                        widgetId,
                        app: "dashboards-app",
                    },
                });
                informUser({
                    type: "error",
                    message: fm(messages.somethingWentWrongExportingAnalysis),
                    timeout: 10000,
                });
            });
    };

    const handleDeleteWidget = () => {
        track("Delete widget", { placement: "Widget header", type: widget.type, title: widget.title });
        widgetProps.onDelete();
        setOpenDeleteModal(false);
        handleCloseWidgetMenu();
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        handleCloseWidgetMenu();
    };

    const handleEditWidget = (placement: string) => {
        track("Edit Widget", { type: widget.type, title: widget.title, placement });
        window.__editAnalysisWidgetClickedTime = Date.now();
        window.__analysisLoadTimeSource = "Edit Widget";
        if (widget.type === ANALYSIS_NEW || widget.type === FIELD_DISTRIBUTION_NEW) {
            navigate(`/analytics/dashboard/${dashboard?.id}/edit-widget/${widget.id}/`, {
                state: { dashboardId: dashboard?.id },
            });
        } else {
            setOpenModal(true);
            handleCloseWidgetMenu();
        }
    };

    const getTruncatedWidgetHeader = (editable?: boolean): JSX.Element => (
        <OverflowTooltip title={widget.title} placement="top">
            <Typography
                variant="textSm"
                fontWeight={800}
                data-testid={`${WIDGET_HEADER_PREFIX}title-${index}`}
                noWrap
                sx={editable ? { cursor: "pointer", "&:hover": { textDecoration: "underline" } } : {}}
            >
                {widget.title}
            </Typography>
        </OverflowTooltip>
    );
    if (widget.type === "TEXT_WIDGET" && !editing) {
        return null;
    }

    const widgetTypesWithoutEditModal = ["TEXT_WIDGET"];
    return (
        <div ref={ref}>
            <StyledCardHeader className={editing ? "widget-drag-handle" : undefined} pt={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                    <Stack direction="row" alignItems="center" sx={{ width: "80%" }}>
                        {editing ? <DragIndicatorIcon fontSize="small" /> : null}
                        <Stack maxWidth={getCorrectWidth(ref.current?.offsetWidth, editing)}>
                            {widgetTypesWithoutEditModal.includes(widget.type) ? (
                                getTruncatedWidgetHeader(false)
                            ) : (
                                <PermissionContainer
                                    requiredPermissionTypes={["change"]}
                                    equivalentUserPermission={{
                                        namespace: "dashboards",
                                        relation: { object: "general", relation: "write" },
                                    }}
                                    renderOnPermissionDenied={getTruncatedWidgetHeader()}
                                    loader={getTruncatedWidgetHeader()}
                                    guard={dashboard && dashboard.guard}
                                    modelGuard={modelGuard}
                                >
                                    <Box onClick={() => handleEditWidget("Widget title")}>
                                        {getTruncatedWidgetHeader(!editing)}
                                    </Box>
                                </PermissionContainer>
                            )}
                        </Stack>
                    </Stack>
                    <Stack data-hide-from-export sx={{ width: "10%" }}>
                        {!editing && (
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                {widgetHover ? (
                                    <IconButton
                                        data-testid={`${WIDGET_HEADER_PREFIX}expandButton`}
                                        size="small"
                                        onClick={toggleExpanded}
                                    >
                                        {expanded ? (
                                            <FullscreenExitIcon fontSize="inherit" />
                                        ) : (
                                            <FullscreenIcon fontSize="inherit" />
                                        )}
                                    </IconButton>
                                ) : null}
                                {elasticIndex && widget.type !== TEXT_WIDGET && (
                                    <FilterModal
                                        data-testtype={widget.type}
                                        placement="Widget header"
                                        title={widget.title}
                                        button={
                                            <IconButton
                                                data-testid={`${WIDGET_HEADER_PREFIX}filterButton-${index}`}
                                                size="small"
                                                onClick={() => {
                                                    track("Widget: FilterModal opened", {
                                                        placement: "Widget header",
                                                        title: widget.title,
                                                    });
                                                }}
                                            >
                                                <Filter fontSize="inherit" />
                                            </IconButton>
                                        }
                                    />
                                )}
                                {widget.type !== TEXT_WIDGET && (
                                    <IconButton
                                        data-testid={`${WIDGET_HEADER_PREFIX}menuButton-${index}`}
                                        size="small"
                                        onClick={handleOpenWidgetMenu}
                                    >
                                        <DotsVertical fontSize="inherit" />
                                    </IconButton>
                                )}
                                <Menu
                                    open={openWidgetMenu}
                                    anchorEl={widgetMenuAnchorEl}
                                    onClose={handleCloseWidgetMenu}
                                >
                                    <PermissionContainer
                                        requiredPermissionTypes={["change"]}
                                        equivalentUserPermission={{
                                            namespace: "dashboards",
                                            relation: { object: "general", relation: "write" },
                                        }}
                                        loader={null}
                                        guard={dashboard && dashboard.guard}
                                        modelGuard={modelGuard}
                                    >
                                        <MenuItem onClick={() => handleEditWidget("widget header menu")}>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Gear fontSize="small" />
                                                <Typography variant="textSm">{fm(messages.editSettings)}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </PermissionContainer>
                                    <MenuItem
                                        data-testid={`${WIDGET_HEADER_PREFIX}exportButton-${index}`}
                                        onClick={handleOpenExportMenu}
                                    >
                                        {!("disableExport" in widgetType) && (
                                            <Tooltip
                                                title={fm(chartContainerMessages.tooltipExport).toString()}
                                                placement="right"
                                            >
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <FileDownloadIcon fontSize="small" />
                                                    <Typography variant="textSm">
                                                        {fm(chartContainerMessages.exportData)}
                                                    </Typography>
                                                </Stack>
                                            </Tooltip>
                                        )}
                                    </MenuItem>
                                    <PermissionContainer
                                        requiredPermissionTypes={["add", "change"]}
                                        equivalentUserPermission={{
                                            namespace: "dashboards",
                                            relation: { object: "general", relation: "write" },
                                        }}
                                    >
                                        <MenuItem
                                            data-testid={`${WIDGET_HEADER_PREFIX}copyButton-${index}`}
                                            onClick={() => {
                                                handleCopyWidgetClicked();
                                                setShowCopyModal(true);
                                                handleCloseWidgetMenu();
                                            }}
                                        >
                                            <Tooltip title={fm(messages.copyWidget).toString()} placement="right">
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <ContentCopyIcon fontSize="small" />
                                                    <Typography variant="textSm">{fm(messages.copy)}</Typography>
                                                </Stack>
                                            </Tooltip>
                                        </MenuItem>
                                    </PermissionContainer>
                                    <PermissionContainer
                                        requiredPermissionTypes={["add", "change"]}
                                        equivalentUserPermission={{
                                            namespace: "dashboards",
                                            relation: { object: "general", relation: "write" },
                                        }}
                                        guard={dashboard && dashboard.guard}
                                        modelGuard={modelGuard}
                                    >
                                        <MenuItem onClick={() => setOpenDeleteModal(true)}>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <DeleteIcon fontSize="small" />
                                                <Typography variant="textSm">{fm(messages.deleteWidget)}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </PermissionContainer>
                                </Menu>
                                <Menu
                                    anchorEl={exportMenuAnchorEl}
                                    open={openExportMenu}
                                    onClose={handleCloseExportMenu}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "right",
                                    }}
                                >
                                    {SHARE_OPTIONS.filter((option) => option.active).map((option) => (
                                        <MenuItem
                                            key={`option-${option.value}`}
                                            value={option.value}
                                            selected={false}
                                            onClick={() => handleSelect(option.value)}
                                            data-testid={`${WIDGET_HEADER_PREFIX}${option.value}Icon`}
                                        >
                                            {option.message}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </StyledCardHeader>
            <DeleteModal open={openDeleteModal} onDelete={handleDeleteWidget} onClose={handleCloseDeleteModal} />
            {showCopyModal && (
                <SaveAsNewModal
                    open={showCopyModal}
                    widgetToCopy={activeWidgetState}
                    onClose={() => setShowCopyModal(false)}
                    modifiedProperties={{}}
                />
            )}
            <PermissionContainer
                requiredPermissionTypes={["change"]}
                equivalentUserPermission={{
                    namespace: "dashboards",
                    relation: { object: "general", relation: "write" },
                }}
                guard={dashboard && dashboard.guard}
                modelGuard={modelGuard}
            >
                {openModal ? (
                    <WidgetEditor
                        {...widgetProps}
                        onClose={() => {
                            setOpenModal(false);
                        }}
                        isNew={isNew}
                    />
                ) : null}
            </PermissionContainer>
        </div>
    );
};

export default WidgetHeader;
