import { DEPARTMENT_GT, SUPPLIER_GT, TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";

import { useTransactionFieldsWithType } from "../SuppliersByDepartmentCount/hooks";
import errorMessages from "../SuppliersByDepartmentCount/messages";

import messages from "./messages";

import { staticFormatMessage } from "@/contexts/IntlContext";
import pivotAnalysis from "@/hoc/PivotAnalysis";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

export default pivotAnalysis(
    function useInitialQuery() {
        const fields = useTransactionFieldsWithType(TRANSACTION_VALUE_GT, SUPPLIER_GT, DEPARTMENT_GT);
        if (fields instanceof Error) return fields;
        const [[spendField, supplierField, departmentField], elasticIndex] = fields;
        return {
            rowSplitItems: [
                {
                    type: departmentField.type,
                    field: departmentField.fieldId,
                    sortOrder: "desc",
                    sortAggIndex: 0,
                    size: 15,
                    excludeOthers: false,
                    labelField: "labelField" in departmentField ? departmentField.labelField : undefined,
                },
            ],
            columnSplitItems: [
                // Use old `supplier_name` field if client is using old `transactions` index
                {
                    type: supplierField.type,
                    field: supplierField.fieldId,
                    size: 15,
                    excludeOthers: true,
                    labelField: "labelField" in supplierField ? supplierField.labelField : undefined,
                    sortAggIndex: 0,
                    sortOrder: "desc",
                },
            ],
            valueAggregationItems: [
                {
                    type: spendField.type,
                    field: spendField.fieldId,
                    aggregation: "sum",
                },
            ],
            elasticIndex,
        };
    },
    () => {
        const dataSource = useTransactionsIndex();
        if (dataSource === undefined) return new Error(staticFormatMessage(errorMessages.couldntFindTransactionsIndex));
        return dataSource;
    },
    {
        legends: false,
        optionsPerAgg: [
            {
                type: "bar",
                stackedDataLabels: true,
                labelDisplayMode: false,
                allowOverlap: true,
                fontSize: "10",
            },
        ],
    },
    () => staticFormatMessage(messages.chartTitle),
    {},
    true
);
