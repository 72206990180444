import { isNotNullish } from "@ignite-analytics/general-tools";
import { formatValue } from "@ignite-analytics/locale";
import { ChartConfig, getValidAggOption } from "@ignite-analytics/pivot-charts";
import {
    BottomRow,
    LabelledAnalysisQuery,
    NotBottomRow,
    PivotColumnData,
    PivotResponse,
    PivotRow,
    PivotValues,
    isBottomRow,
    isBottomValues,
    isParentValues,
} from "@ignite-analytics/pivot-ts";
import { CellValue } from "react-table";

import { PivotTableRow } from "../interfaces";

import { getAccessorKey, getAllBottomColumnFields, getAllSplitFields } from "./columns";
import { EMPTY_CELL_VALUE, TOTAL_FIELD } from "./constants";

import { formatNumber } from "@/lib/convert";

const getDescendantsCount = (row: PivotRow): number =>
    isBottomRow(row) ? 1 : (row.children as PivotRow[]).reduce((sum, r) => sum + getDescendantsCount(r), 0);

const formatValueForCell = (value: number | Date | null, chartConfig: ChartConfig, aggIndex: number) => {
    const isPercent = chartConfig?.optionsPerAgg[aggIndex]?.labelDisplayMode === "percent";
    if (typeof value === "number") {
        if (isPercent) {
            return formatValue(
                value,
                chartConfig ? getValidAggOption(chartConfig, aggIndex)?.decimals || 0 : 0,
                !isPercent,
                isPercent
            );
        }
        return formatNumber(
            value,
            chartConfig ? getValidAggOption(chartConfig, aggIndex)?.decimals || 0 : 0,
            chartConfig ? getValidAggOption(chartConfig, aggIndex)?.measureUnit || 0 : 0
        );
    }
    return value;
};

const getInnerValues = (
    values: PivotValues,
    config: ChartConfig,
    column: PivotColumnData,
    parentColumns?: PivotColumnData[]
): { value: CellValue; columnPath: PivotColumnData[] }[] => {
    if (isBottomValues(values)) {
        return values
            .map((val, aggIndex) => {
                if (config.optionsPerAgg[aggIndex]?.isHidden) {
                    return null;
                }
                return {
                    value: formatValueForCell(val, config, aggIndex) || EMPTY_CELL_VALUE,
                    columnPath: parentColumns ? [...parentColumns, column] : [column],
                };
            })
            .filter(isNotNullish);
    }

    const childColumns = column.children;
    if (isParentValues(values) && childColumns?.length) {
        return values.reduce((acc: CellValue[], val, innerColIndex) => {
            const nextColumn = childColumns[innerColIndex];
            const nextParentColumns = parentColumns ? [...parentColumns, column] : [column];
            return [...acc, ...getInnerValues(val, config, nextColumn, nextParentColumns)];
        }, []);
    }
    throw Error(
        "Expected values to either be bottom values or parent values. Something must be wrong with the logic here."
    );
};

const getBottomRowValues = (
    valuesList: PivotValues[],
    config: ChartConfig,
    columns: PivotColumnData[]
): { value: CellValue; columnPath: PivotColumnData[] }[] => {
    return valuesList.reduce((acc: CellValue[], values, innerColIndex) => {
        const column = columns[innerColIndex];
        return [...acc, ...getInnerValues(values, config, column)];
    }, []);
};

const getBottomRowValuesList = (
    columns: PivotColumnData[],
    query: LabelledAnalysisQuery,
    row: BottomRow,
    config: ChartConfig,
    rowPath: PivotRow[]
): PivotTableRow => {
    const allBottomFields = getAllBottomColumnFields(columns, query, config, "");
    let bottomFieldsIndex = 0;
    const bottomRowValues = getBottomRowValues(row.values, config, columns);
    return bottomRowValues.reduce<PivotTableRow>((acc, { value, columnPath }) => {
        const field = allBottomFields[bottomFieldsIndex];
        if (!field) return acc;
        const innerKey = getAccessorKey(field);
        bottomFieldsIndex += 1;
        return {
            ...acc,
            [innerKey]: {
                value,
                colSpan: 1,
                columnPath,
                rowPath,
            },
        };
    }, {});
};

const constructRowsFromNode = (
    row: PivotRow,
    parentRow: PivotTableRow,
    splitFields: (string | undefined)[],
    index: number,
    columns: PivotColumnData[],
    query: LabelledAnalysisQuery,
    config: ChartConfig,
    parentRowPath?: PivotRow[]
): PivotTableRow[] => {
    let rowList: PivotTableRow[] = [];
    const colSpan = row.meta.isTotalColumn ? splitFields.length : 1;
    const rowPath: PivotRow[] = [...(parentRowPath ?? []), row];
    if (isBottomRow(row)) {
        const key = splitFields[index];
        if (splitFields.length && key) {
            const splitFieldsKey = getAccessorKey(`${key}_${index}`);
            return [
                ...rowList,
                {
                    ...parentRow,
                    [splitFieldsKey]: {
                        value: row.name,
                        colSpan,
                        isHeader: true,
                        rowPath,
                        rowSpan: getDescendantsCount(row),
                    },
                    ...getBottomRowValuesList(columns, query, row, config, rowPath),
                },
            ];
        }
        const splitFieldsKey = query.columnSplitItems.length
            ? getAccessorKey(`${query.columnSplitItems[query.columnSplitItems.length - 1].field}_${index}`)
            : TOTAL_FIELD;
        return [
            ...rowList,
            {
                ...parentRow,
                [splitFieldsKey || ""]: {
                    value: row.name,
                    isHeader: true,
                    colSpan,
                    rowPath,
                    rowSpan: getDescendantsCount(row),
                },
                ...getBottomRowValuesList(columns, query, row, config, rowPath),
            },
        ];
    }
    const splitFieldsKey = getAccessorKey(`${splitFields[index] || ""}_${index}`);
    const currentRow: PivotTableRow = {
        ...parentRow,
        [splitFieldsKey]: {
            value: row.name,
            colSpan,
            isHeader: true,
            rowPath,
            rowSpan: getDescendantsCount(row),
        },
    };
    let usedChildren = 0;
    row.children.forEach((innerRow: BottomRow | NotBottomRow) => {
        const innerParentRow = usedChildren === 0 ? currentRow : {};
        if (isBottomRow(innerRow) || innerRow.children.length > 0) {
            usedChildren += 1;
        }
        const newRows = constructRowsFromNode(
            innerRow,
            innerParentRow,
            splitFields,
            index + 1,
            columns,
            query,
            config,
            rowPath
        );
        rowList = [...rowList, ...newRows];
    });
    return rowList;
};

export const getAllRows = (
    response: PivotResponse,
    query: LabelledAnalysisQuery,
    config: ChartConfig
): PivotTableRow[] => {
    const splitFields = getAllSplitFields(query);
    return response.rows.reduce((acc: PivotTableRow[], row) => {
        const rowList = constructRowsFromNode(row, {}, splitFields, 0, response.columns, query, config);
        return [...acc, ...rowList];
    }, []);
};
