import * as Sentry from "@sentry/react";
import React from "react";

import { FatalErrorFallback } from "./FatalErrorFallback";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 *
 */
export const FatalErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Sentry.ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("app", "dashboards-app");
            }}
            fallback={<FatalErrorFallback />}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
