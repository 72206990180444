import { defineMessages } from "react-intl";

const messages = defineMessages({
    removedValues: {
        id: "components.charts.chart.removedValues",
        defaultMessage: "Removed values: ",
        description: "Text used in tooltip showing all values that are removed",
    },
    infoButton: {
        id: "components.charts.chart.infoButton",
        defaultMessage: "Info",
        description: "Button text to display the removed values info",
    },
    noData: {
        id: "components.charts.chart.noData",
        defaultMessage: "No data was found with the current configuration. Try changing some of the parameters.",
    },
    updateWidget: {
        id: "components.charts.chart.updateWidget",
        defaultMessage: "Save changes to analysis",
    },
    createWidget: {
        id: "components.charts.chart.createWidget",
        defaultMessage: "Save as new analysis",
    },
    customized: {
        id: "components.charts.chart.customized",
        defaultMessage: "Customized",
    },
    currentDashboard: {
        id: "components.charts.chart.currentDashboard",
        defaultMessage: "This dashboard",
    },
    defaultAnalysisTitle: {
        id: "customAnalysis.defaultAnalysisTitle",
        defaultMessage: "New analysis",
    },
    widgetTitle: {
        id: "customAnalysis.widgetTitle",
        defaultMessage: "analysis title",
    },
    chooseDashboard: {
        id: "components.charts.chart.chooseDashboard",
        defaultMessage: "Choose dashboard to save analysis to",
    },
    undoButton: {
        id: "components.charts.chart.undoButton",
        defaultMessage: "Revert drilldown",
    },
    createDashboard: {
        id: "components.charts.chart.createDashboard",
        defaultMessage: "Create new dashboard",
    },
    copyWidgetError: {
        id: "components.charts.chart.copyWidgetError",
        defaultMessage: "Something went wrong when trying to copy analysis",
    },
    createWidgetError: {
        id: "components.charts.chart.createWidgetError",
        defaultMessage: "Something went wrong when trying to create analysis",
    },
});

export default messages;
