import { getCombinedProvider } from "@ignite-analytics/entities";

import { DashboardCollectionUserRelationContextProvider } from "./dashboardCollectionRelations";
import { DashboardCollectionContextProvider } from "./dashboardCollections";
import { CustomDashboardContextProvider } from "./dashboards";
import { ModelGuardContextProvider } from "./modelGuards";
import { ScriptFieldContextProvider } from "./scriptFields";

export const EntitiesContextProvider = getCombinedProvider(
    DashboardCollectionUserRelationContextProvider,
    DashboardCollectionContextProvider,
    CustomDashboardContextProvider,
    ModelGuardContextProvider,
    ScriptFieldContextProvider
);
