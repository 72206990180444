import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { typeGuards } from "@ignite-analytics/general-tools";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

/**
 * Returns name for the transactions index, and the fields with the requested global types.
 * If a field cannot be found, an Error-object is returned instead.
 */

export const useTransactionFieldsWithType = (
    ...globalTypeKeys: string[]
): readonly [ElasticField[], string] | Error => {
    const elasticIndex = useTransactionsIndex()?.name;
    const { formatMessage: fm } = useIntl();
    const elasticFields = useElasticFields(elasticIndex);
    const fields = useMemo(
        () => globalTypeKeys.map((key) => elasticFields?.find((item) => item.globalTypeKey === key)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...globalTypeKeys, elasticFields]
    );

    const notNull = useMemo(() => fields.filter(typeGuards.isNotNullish), [fields]);
    const withIndex = useMemo(
        () => elasticIndex !== undefined && ([notNull, elasticIndex] as const),
        [notNull, elasticIndex]
    );
    const cannotFindGlobalTypeError = useMemo(() => {
        return new Error(fm(messages.couldntFindGlobalType, { tag: globalTypeKeys.find((_, i) => !fields[i]) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, fm, ...globalTypeKeys]);
    const cannotFindIndexError = useMemo(() => {
        return new Error(fm(messages.couldntFindTransactionsIndex));
    }, [fm]);
    if (!elasticIndex || !withIndex) return cannotFindIndexError;
    if (notNull.length !== fields.length) return cannotFindGlobalTypeError;
    return withIndex;
};
