import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, InsightModalContextProvider, PivotResponse } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import React, { useCallback } from "react";

import { SplitChangeSpec } from "../interfaces";

import { TableWrapper } from "./components/TableWrapper";

import { useGlobalFilterAction, useGlobalFilterIndices, useGlobalFilters } from "@/contexts/GlobalFilterContext";
import { useElasticFieldsForFilterContextProvider } from "@/hoc/withFilterContext";
import { useExportData } from "@/hooks/useExport";

interface Props {
    title: string;
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onRef: (el: HTMLTableElement) => void;
    onSaveDrilldown: ((saveAsNew: boolean) => Promise<void>) | undefined;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onDrilldown?: (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const PivotTableV2: React.FC<Props> = ({
    title,
    data,
    query,
    config,
    editable,
    onSaveDrilldown,
    onSplitChange,
    onDrilldown,
    onHeaderChange,
    onRef,
}) => {
    const exportFn = useExportData(title);
    const trackingFn = useCallback(
        (event: string, properties?: Record<string, unknown>) => {
            track(event, {
                chartType: "table",
                config,
                ...properties,
            });
        },
        [config]
    );
    const indicesInGlobalFilterContext = useGlobalFilterIndices();
    const safeElasticIndexInGlobalFilterContext =
        indicesInGlobalFilterContext && indicesInGlobalFilterContext.includes(query.elasticIndex)
            ? query.elasticIndex
            : indicesInGlobalFilterContext?.[0];

    return (
        <InsightModalContextProvider
            analysisQuery={query}
            onlyAllowRowSplits={false}
            useAggsAsColumns={false}
            onDrilldown={onDrilldown}
            onSave={onSaveDrilldown}
            globalFilters={useGlobalFilters()}
            dispatchGlobalFilterAction={useGlobalFilterAction()}
            trackingFn={trackingFn}
            globalFilterIndex={safeElasticIndexInGlobalFilterContext}
            exportFn={exportFn}
            useDataFields={useElasticFieldsForFilterContextProvider}
        >
            <TableWrapper
                onRef={onRef}
                data={data}
                query={query}
                config={config}
                editable={editable}
                onSplitChange={onSplitChange}
                onHeaderChange={onHeaderChange}
            />
        </InsightModalContextProvider>
    );
};
