import { useEffect } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

import { useBasePath } from "@/contexts/basePathContext";
import { fm } from "@/contexts/IntlContext";

export function useSetBreadcrumbs(title: string) {
    const { formatMessage } = useIntl();
    const path = useBasePath();

    useEffect(() => {
        const data = {
            source: "ignite-breadcrumbs",
            payload: [
                { text: "Analytics", href: `${path}home/` },
                { text: fm(messages.dashboards).toString(), href: `${path}home/` },
                { text: title },
            ],
        };
        window.postMessage(data, window.location.origin);
    }, [title, path, formatMessage]);
}
