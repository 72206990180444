import { toCamelCase } from "@ignite-analytics/api-client";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { orderBy } from "lodash-es";
import { useMemo } from "react";

import { getCurrentDomain } from "../getCurrentDomain";

interface Color {
    id: number;
    order: number;
    colorType: number;
    hex: string;
}

export const useColors = () => {
    const customersUrl =
        getCurrentDomain() === "ignite"
            ? process.env.REACT_APP_CUSTOMERS_API_IGNITE_URL
            : process.env.REACT_APP_CUSTOMERS_API_IGNITEPROCUREMENT_URL;
    const { data: colors } = useQuery({
        queryKey: ["colors"],
        staleTime: 60 * 1000 * 30, // 30 minutes
        queryFn: () =>
            axios
                .get(`${customersUrl}/colors`, {
                    withCredentials: true,
                })
                .then((res) => {
                    const { status } = res;
                    if (status === 200) {
                        return toCamelCase(res.data as Color[], false);
                    }
                    Sentry.captureMessage("Unexpected status code on fetch colors", {
                        tags: { app: "dashboards-app" },
                        extra: { status },
                    });
                    return [];
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 404) {
                        return [];
                    }
                    if (axios.isCancel(err)) {
                        return [];
                    }
                    Sentry.captureException(err, {
                        tags: { app: "dashboards-app" },
                    });
                    return [];
                }),
    });

    return useMemo(() => orderBy(colors, ["order"], ["asc"]), [colors]);
};
