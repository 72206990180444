import { Autocomplete, Button, Grid, MenuItem, Stack, TextField } from "@mui/material";
import React, { useMemo } from "react";

import messages from "../messages";
import ownMessages from "./messages";

import { parseUsers } from "./helpers";

import { UserFragment } from "@/__generated__/graphql";
import { useUsers } from "@/hooks/useUsers";
import globalMessages from "@/lib/messages/globalMessages";
import { useIntl } from "react-intl";

interface Props {
    onAdd: (user: UserFragment) => void;
    selected?: string[];
    disabled?: boolean;
}

const UserSearch = ({ selected, onAdd, disabled }: Props) => {
    const { users, setSearchTerm } = useUsers();
    const { formatMessage } = useIntl();

    const [value, setValue] = React.useState<{ text: string; value: UserFragment; selected: boolean } | null>(null);

    const userItems = useMemo(
        () => parseUsers(users?.filter((u) => !selected?.includes(u.id)) ?? [], selected),
        [users, selected]
    );

    return (
        <>
            <Grid item p={0} xs={10}>
                <Autocomplete
                    value={value}
                    filterOptions={(options, state) => {
                        return options.filter((o) => o.text.toLowerCase().includes(state.inputValue.toLowerCase()));
                    }}
                    disabled={disabled}
                    options={userItems || []}
                    getOptionLabel={(option) => option.text}
                    renderOption={(props, option) => <MenuItem {...props}>{option.text}</MenuItem>}
                    onChange={(_, option) => {
                        setValue(option);
                    }}
                    onInputChange={(_, newInputValue) => {
                        setSearchTerm(newInputValue);
                    }}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={formatMessage(ownMessages.searchPlaceholder)}
                            size="small"
                            variant="outlined"
                            label={formatMessage(messages.addPermission)}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Stack direction="row-reverse">
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            if (value?.value) {
                                onAdd(value.value);
                            }
                            setValue(null);
                        }}
                        variant="outlined"
                        color="secondary"
                        size="medium"
                    >
                        {formatMessage(globalMessages.add)}
                    </Button>
                </Stack>
            </Grid>
        </>
    );
};

export default UserSearch;
