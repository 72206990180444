import { defineMessages } from "react-intl";

const messages = defineMessages({
    noSpendDataAvailable: {
        id: "dashboardOverview.dashboardOverviewPage.savingsOpportunities.noSpendDataAvailable",
        defaultMessage: "No spend data available, please set one of your data tables as 'Spend data'",
    },
    savingsOpportunities: {
        id: "dashboardOverview.contentView.savingsOpportunities",
        defaultMessage: "Savings Opportunities",
    },
});

export default messages;
