import { useDebounce } from "@ignite-analytics/general-tools";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";

import { DashboardContextProvider } from "../../DashboardContext";
import { getIgniteDashboardsURLs } from "../constants";

import { DashboardCard } from "./DashboardCard";
import { HomeCard } from "./DashboardCard/HomeCard";
import { DashboardOverviewSettingsPage } from "./DashboardOverviewSettingsPage";
import SavingOpportunitiesDashboard from "./IgniteAnalyses/SavingsOpportunities";

import PotentialRatingPage from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage";
import { ExportDashboardProvider } from "@/contexts/exportDashboardContext";

interface ContentViewProps {
    openMenu: boolean;
    scrollParentRef: React.RefObject<HTMLDivElement>;
}

export const ContentView: React.FC<ContentViewProps> = (props: ContentViewProps) => {
    const { openMenu, scrollParentRef } = props;
    const IGNITE_DASHBOARD_URLS = getIgniteDashboardsURLs();

    const delayedResizeEvent = useDebounce(() => {
        window.dispatchEvent(new Event("resize"));
        // dispatch on menu open delayed 250 ms to await collapse animation
    }, 260);

    useEffect(
        function sendResizeEventOnMenuOpen() {
            // dispatch once initially
            window.dispatchEvent(new Event("resize"));
            delayedResizeEvent();
        },
        [openMenu, delayedResizeEvent]
    );

    return (
        <Routes>
            <Route path="/dashboard/overview/settings/" element={<DashboardOverviewSettingsPage />} />
            <Route path={IGNITE_DASHBOARD_URLS.risk} element={<PotentialRatingPage specifiedRatingType="risk" />} />
            <Route
                path={IGNITE_DASHBOARD_URLS.potential}
                element={<PotentialRatingPage specifiedRatingType="potential" />}
            />
            <Route path={IGNITE_DASHBOARD_URLS.savings} element={<SavingOpportunitiesDashboard />} />
            {/** No DashboardContextProvider because it conditionally renders its children.
             *   We want HomeCard to render unconditionally so we can be redirected.
             */}
            <Route path="/dashboard/overview/home/" element={<HomeCard />} />
            <Route
                path="/dashboard/overview/:dashboardId/"
                element={
                    <ExportDashboardProvider>
                        <DashboardContextProvider>
                            <DashboardCard scrollParentRef={scrollParentRef} />
                        </DashboardContextProvider>
                    </ExportDashboardProvider>
                }
            />
        </Routes>
    );
};
