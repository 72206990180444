import { defineMessages } from "react-intl";

const messages = defineMessages({
    noDataAvailable: {
        id: "hoc.widgetWrapper.noDataAvailable",
        defaultMessage: "No data available",
    },
    couldNotLoad: {
        id: "hoc.widgetWrapper.couldNotLoad",
        defaultMessage: "Could not automatically load the required fields.",
    },
    configureButton: {
        id: "hoc.widgetWrapper.configureButton",
        defaultMessage: "Configure manually",
    },
});
export default messages;
