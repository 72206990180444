import { isNullOrUndefined } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AggregatedFilter, AggregationItem, SplitItem } from "@ignite-analytics/pivot-ts";
import { IntlShape } from "react-intl";

import { getAggDescription } from "../InnerTable/columnGenerators/aggregation";

import messages from "./messages";

import { staticFormatMessage } from "@/contexts/IntlContext";
import { Labelled } from "@/lib/labelled";

export const getSortDescription = (
    aggItems: Labelled<AggregationItem>[],
    { sortAggIndex, sortOrder }: SplitItem,
    intl: IntlShape,
    config: ChartConfig
) => {
    if (!sortOrder) return "";
    const aggDescriptionName =
        !isNullOrUndefined(sortAggIndex) && !isNullOrUndefined(aggItems[sortAggIndex])
            ? getAggDescription(aggItems[sortAggIndex], sortAggIndex, intl, config)
            : undefined;
    return staticFormatMessage(messages.sorting, {
        order: staticFormatMessage(messages[sortOrder]),
        field: aggDescriptionName
            ? aggDescriptionName.customHeaderName ?? aggDescriptionName.headerName
            : staticFormatMessage(messages.name),
    });
};

const aggregatedFilterIsReliable = (aggFilter: AggregatedFilter, splitItem: SplitItem) => {
    // If the split item is sorted by something else than the operand in the agg filter, it cannot return correct
    // results reliably.
    if (aggFilter.leftAggIndex !== splitItem.sortAggIndex) return false;
    switch (aggFilter.operator) {
        // When using equality operators we cannot know if the results are reliable
        case "!=":
        case "==":
            return false;
        // If the split item is sorted by the aggFilter operand, and the sort direction mathces the filter operator,
        // the results can be reliable.
        case "<":
        case "<=":
            return splitItem.sortOrder === "asc";
        case ">":
        case ">=":
            return splitItem.sortOrder === "desc";
    }
};

const isSplitItemSizeReliable = (item: SplitItem) =>
    item.aggregatedFilters?.every((aggFilter) => aggregatedFilterIsReliable(aggFilter, item)) ?? true;

// TODO: write to use new icons
export const getIconForSortingRule = ({ sortOrder, sortAggIndex }: SplitItem) =>
    !isNullOrUndefined(sortAggIndex)
        ? sortOrder === "asc"
            ? "sort-numeric-up-alt"
            : "sort-numeric-down-alt"
        : sortOrder === "asc"
        ? "sort-alpha-down"
        : "sort-alpha-up";

export const createSplitItemSortUpdater =
    (aggs: number) =>
    ({ sortAggIndex: prevIndex, sortOrder: prevOrder = "desc", ...otherProps }: SplitItem): SplitItem => {
        const order = prevOrder === "desc" ? "asc" : "desc";
        const switchIndex = order === "desc";
        const aggIndex = !isNullOrUndefined(prevIndex) ? (prevIndex < aggs - 1 ? prevIndex + 1 : null) : 0;
        const nextIndex = switchIndex ? aggIndex : prevIndex;

        const newItem: SplitItem = {
            ...otherProps,
            sortAggIndex: nextIndex,
            sortOrder: order,
        };
        const sizeIsReliable = isSplitItemSizeReliable(newItem);
        return {
            ...newItem,
            searchSize: sizeIsReliable ? null : newItem.searchSize ?? 1000,
        };
    };
