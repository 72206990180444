import { defineMessages } from "react-intl";

const messages = defineMessages({
    columnHeadRating: {
        id: "components.widgets.potentialRiskWidget.columnHeadRating",
        defaultMessage: "{typeOf} rating",
    },
    widgetHeader: {
        id: "components.widgets.potentialRiskWidget.widgetHeader",
        defaultMessage: "Suppliers with {typeOf}",
    },
    potential: {
        id: "components.widgets.potentialRiskWidget.potential",
        defaultMessage: "Potential",
    },
    risk: {
        id: "components.widgets.potentialRiskWidget.risk",
        defaultMessage: "Risk",
    },
    noMatchingData: {
        id: "components.widgets.potentialRiskWidget.noMatchingData",
        defaultMessage: "No data matching your filters was found.",
    },
    supplier: {
        id: "general.labels.supplier",
        defaultMessage: "Supplier",
        description: "Used for headers, field labels etc.",
    },
    spendLTM: {
        id: "general.labels.spendLTM",
        defaultMessage: "Spend last 12 months",
        description: "Used for headers, field labels etc.",
    },
});

export default messages;
