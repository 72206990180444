import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import React from "react";

import DashboardOverviewPage from "./DashboardOverview";

import { InfoMessage } from "@/components/InfoMessage";
import AvailableIndicesProvider from "@/contexts/AvailableIndicesContext";
import { EntityChangeEventContextProvider } from "@/contexts/EntityEventChangeContext";
import { EventContextWrapper } from "@/contexts/EventContext";
import GlobalFilterContextProvider from "@/contexts/GlobalFilterContext";
import { InfoMessageProvider } from "@/contexts/InfoMessageProvider";
import { useOryUserId } from "@/contexts/oryUserContext";
import { useSessionContext } from "@/contexts/SessionContextProvider";

const CustomDashboardPage: React.FC = () => {
    const { id: userId, tenant, email } = useSessionContext();
    const ensureOryUserId = useOryUserId();

    if (!tenant || !userId || !ensureOryUserId) return null;

    return (
        <EventContextWrapper>
            <FeatureToggleContextProvider userEmail={email} tenant={tenant}>
                <EntityChangeEventContextProvider userId={userId} channel={0}>
                    <AvailableIndicesProvider>
                        <GlobalFilterContextProvider>
                            <InfoMessageProvider>
                                <DashboardOverviewPage />
                                <InfoMessage />
                            </InfoMessageProvider>
                        </GlobalFilterContextProvider>
                    </AvailableIndicesProvider>
                </EntityChangeEventContextProvider>
            </FeatureToggleContextProvider>
        </EventContextWrapper>
    );
};

export default CustomDashboardPage;
