import { Box, Tooltip, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Cell } from "react-table";

import { TableCell } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/DataTableCell/interfaces";

interface Props {
    cell: Cell<Record<string, TableCell>>;
}

const TextCell: React.FC<Props> = ({ cell }) => {
    const ref = useRef<HTMLParagraphElement>();
    const isOverflowOfText = ref.current?.clientWidth !== ref.current?.scrollWidth;
    return (
        <Box sx={{ alignItems: cell.column.align || "left" }}>
            {isOverflowOfText ? (
                <Tooltip title={cell.render("Cell")}>
                    <Typography variant="subtitle2">{cell.render("Cell")}</Typography>
                </Tooltip>
            ) : (
                <Typography variant="subtitle2">{cell.render("Cell")}</Typography>
            )}
        </Box>
    );
};

export default TextCell;
