import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { useCallback } from "react";

export interface DashboardCollection {
    name: string;
    id: number;
    precedence: number;
}

const listCreateResource = createListResource<DashboardCollection>`/dashboards/dashboard-collections/`;

const detailResource = createDetailResource<DashboardCollection>`/dashboards/dashboard-collections/${(params) =>
    params.id}/`;

export const {
    Provider: DashboardCollectionContextProvider,
    useAll: useAllDashboardCollections,
    useObject: useDashboardCollection,
    useCreateAction: useCreateDashboardCollectionAction,
    useUpdateAction: useUpdateDashboardCollectionAction,
    useDeleteAction: useDeleteDashboardCollectionAction,
    useThisContext: useDashboardCollectionContext,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: true,
    name: "DashboardCollection",
});

export const useUpdateMultipleDashboardCollections = () => {
    const { addListObjs } = useDashboardCollectionContext();
    return useCallback(
        (dashboardCollectionsToUpdate: DashboardCollection[]) => {
            const url = `/dashboards/dashboard-collections/`;
            Api.patch<DashboardCollection[]>(url, dashboardCollectionsToUpdate, {
                service: "dashboards",
            }).then((updatedDashboardCollections: DashboardCollection[]) => {
                addListObjs(updatedDashboardCollections, url);
                return updatedDashboardCollections;
            });
        },
        [addListObjs]
    );
};
