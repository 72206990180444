import { defineMessages } from "react-intl";

const messages = defineMessages({
    change: {
        id: "components.widgets.largestFieldWidget.change",
        defaultMessage: "Change",
    },
    spend: {
        id: "components.widgets.largestFieldWidget.spend",
        defaultMessage: "Spend",
    },
    previousSpend: {
        id: "components.widgets.largestFieldWidget.prevSpend",
        defaultMessage: "Previous spend",
    },
    trend: {
        id: "components.widgets.largestFieldWidget.trend",
        defaultMessage: "Trend",
    },
    trendInSpendTooltip: {
        id: "components.widgets.largestFieldWidget.trendInSpend",
        defaultMessage: "Spend trend",
    },
    chartChangeInSpendTooltip: {
        id: "components.widgets.largestFieldWidget.chartChangeInSpendTooltip",
        defaultMessage: "Change in spend. \nColumns sorted after biggest change",
    },
    tableViewTitle: {
        id: "components.widgets.largestFieldWidget.tableViewTitle",
        defaultMessage: "Largest {field} last {timePeriod}",
    },
    chartViewTitle: {
        id: "components.widgets.largestFieldWidget.chartViewTitle",
        defaultMessage: "Change in spend {timePeriod} per {field}",
    },
    infinityLabel: {
        id: "components.widgets.components.trendArrowInfinity",
        defaultMessage: "Infinity",
    },
});

export default messages;
