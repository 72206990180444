import { toCamelCase } from "@ignite-analytics/api-client";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AggregationItem, LabelledAnalysisQuery, ValueConfiguration } from "@ignite-analytics/pivot-ts";
import { ColumnDef } from "@tanstack/react-table";
import { IntlShape } from "react-intl";

import { getAccessorKey, getColumnWidth } from "../helpers/columns";
import { PivotTableRow } from "../interfaces";

import messages from "./messages";

import { Labelled } from "@/lib/labelled";
import customAnalysisMessages from "@/lib/messages/customAnalysis";

export const getAggDescription = (
    aggItem: Labelled<AggregationItem | ValueConfiguration>,
    index: number,
    intl: IntlShape,
    config: ChartConfig
): {
    headerName: string;
    customHeaderName?: string;
} => {
    const customHeaderName = config?.optionsPerAgg[index]?.tableHeaderName;
    const aggMessageKey = toCamelCase(
        "aggregation" in aggItem && aggItem.aggregation
            ? aggItem.aggregation
            : aggItem.type === "script"
            ? "script"
            : "sum",
        true
    ) as keyof typeof customAnalysisMessages;
    const messageKey = customAnalysisMessages[aggMessageKey];
    const aggregation = messageKey ? intl.formatMessage(messageKey) : aggMessageKey;
    if (aggregation === "script") {
        return {
            customHeaderName,
            headerName: aggItem.label.toString(),
        };
    }
    return {
        customHeaderName,
        headerName: `${aggregation} ${intl.formatMessage(messages.of)} ${aggItem.label.toString().toLowerCase()}`,
    };
};

export const getAggregationHeaders = (
    query: LabelledAnalysisQuery,
    parentKey: string,
    intl: IntlShape,
    config: ChartConfig,
    width: number
): ColumnDef<PivotTableRow>[] => {
    return query.valueAggregationItems.flatMap((agg, aggIndex) => {
        if (config.optionsPerAgg[aggIndex]?.isHidden) {
            return [];
        }
        const aggDesc = getAggDescription(agg, aggIndex, intl, config);
        const accessorKey = getAccessorKey(`${parentKey}_${aggIndex}`);
        const size = getColumnWidth(config, accessorKey);
        return [
            {
                accessorKey,
                header: aggDesc.customHeaderName ?? aggDesc.headerName,
                size: size || width,
                meta: {
                    headerType: "aggregation",
                    defaultHeaderName: aggDesc.headerName,
                    valueAggregationIndex: aggIndex,
                    columnWidthId: accessorKey,
                },
            },
        ];
    });
};
