import { defineMessages } from "react-intl";

const messages = defineMessages({
    infoTextDefault: {
        id: "components.chart.infoTextDefault",
        defaultMessage: "<p>Description is coming</p>",
    },
    closeInfoModal: {
        id: "components.chart.closeInfoModal",
        defaultMessage: "Close",
        description: "buttonText",
    },
    tooltipShowInfo: {
        id: "components.chart.tooltipShowInfo",
        defaultMessage: "Info",
        description: "Tooltip",
    },
    tooltipExport: {
        id: "components.chart.tooltipExport",
        defaultMessage: "Export data to desired format",
        description: "Tooltip",
    },
    exportData: {
        id: "components.chart.exportData",
        defaultMessage: "Export data",
        description: "Menu item",
    },
    tooltipSaveImg: {
        id: "components.chart.tooltipSaveImg",
        defaultMessage: "Export as Image",
        description: "Tooltip",
    },
    tooltipSaveDataAsCsv: {
        id: "components.chart.tooltipSaveDataAsCsv",
        defaultMessage: "Export as CSV",
        description: "Tooltip",
    },
    tooltipSaveDataAsExcel: {
        id: "components.chart.tooltipSaveDataAsExcel",
        defaultMessage: "Export as Excel",
        description: "Tooltip",
    },
});

export default messages;
