import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFiltersWithIdNameMaps } from "@ignite-analytics/filters";
import { Typography } from "@mui/material";
import Parser from "html-react-parser";
import React from "react";
import { useIntl } from "react-intl";

import { getFilterFooterHTML } from "../../helpers";

import messages from "./messages";

import { OverflowTooltip } from "@/components/OverflowTooltip";
import { Options } from "@/components/Widgets/interfaces";
import { staticFormatMessage } from "@/contexts/IntlContext";
import { useWithLabels } from "@/hooks/useWithLabels";

const TEST_ID_PREFIX = "filterCardFooter-";

interface Props {
    analysisOptions?: Options;
    index?: number;
}
export const FilterCardFooter: React.FC<Props> = ({ analysisOptions, index }) => {
    const filters = useFiltersWithIdNameMaps();
    const elasticFields = useElasticFieldsInContext();
    const filtersWithLabels = useWithLabels(filters, false);
    const intl = useIntl();
    if (!elasticFields) return null;
    const html = getFilterFooterHTML(intl, elasticFields, filtersWithLabels, analysisOptions);

    if (html.trim().length === 0)
        return (
            <Typography pl={1} variant="textXs" data-testid={`${TEST_ID_PREFIX}widgetFooter-${index}`}>
                {staticFormatMessage(messages.noActiveFilters)}
            </Typography>
        );
    return (
        <OverflowTooltip title={Parser(html)} disableInteractive>
            <Typography pl={1} noWrap variant="textXs" data-testid={`${TEST_ID_PREFIX}widgetFooter-${index}`}>
                {Parser(html)}
            </Typography>
        </OverflowTooltip>
    );
};

export default FilterCardFooter;
