import { capitalCase } from "@ignite-analytics/general-tools";
import {
    X as CloseIcon,
    Trash as DeleteIcon,
    Check as DoneIcon,
    Drag as DragIndicatorIcon,
    Pen as EditIcon,
    ChevronUp as ExpandLessIcon,
    ChevronDown as ExpandMoreIcon,
    DotsVertical as MoreVertIcon,
} from "@ignite-analytics/icons";
import {
    Button,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { PropsWithChildren, useState } from "react";

import { DashboardList } from "./DashboardList";
import messages from "./messages";

import { fm } from "@/contexts/IntlContext";
import { useOryUserIdOrThrow } from "@/contexts/oryUserContext";
import {
    DashboardCollectionUserRelation,
    useCreateDashboardCollectionUserRelationAction,
    useUnknownIdUpdateDashboardCollectionRelation,
} from "@/entities/dashboardCollectionRelations";
import {
    DashboardCollection,
    useDeleteDashboardCollectionAction,
    useUpdateDashboardCollectionAction,
} from "@/entities/dashboardCollections";
import { CustomDashboardListObject, useUpdateMultipleCustomDashboards } from "@/entities/dashboards";

interface DashboardCollectionItemProps {
    collection: DashboardCollection;
    collectionDashboards: CustomDashboardListObject[];
    hasChangePermission: boolean;
    dashboardDragId: number | undefined;
    relation?: DashboardCollectionUserRelation;
    setDashboardDragId: (id: number | undefined) => void;
}

const DASHBOARD_COLLECTION_TESTID_PREFIX = "dashboardCollection-";

export const DashboardCollectionItem: React.FC<PropsWithChildren<DashboardCollectionItemProps>> = (
    props: DashboardCollectionItemProps
) => {
    // Returns the different categories/possible dashboard modules and their corresponding dashboards on the left-hand card
    const { collection, collectionDashboards, hasChangePermission, relation, dashboardDragId, setDashboardDragId } =
        props;
    const [editingType, setEditingType] = useState<"DELETE" | "RENAME" | "ADD_DASHBOARDS" | undefined>(undefined);
    const [nameInput, setNameInput] = useState<string | undefined>();
    const [isHoveringOver, setIsHoveringOver] = useState<boolean>(false);
    const [unmountListOnExit, setUnmountListOnExit] = useState<boolean>(false);
    const deleteCollection = useDeleteDashboardCollectionAction(collection);
    const updateCollection = useUpdateDashboardCollectionAction(collection);
    const updateDashboards = useUpdateMultipleCustomDashboards();
    const createCollectionRelation = useCreateDashboardCollectionUserRelationAction();
    const updateCollectionRelation = useUnknownIdUpdateDashboardCollectionRelation();
    const user = useOryUserIdOrThrow();
    const open = relation ? relation.open : true;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const collectionMenuOpen = Boolean(anchorEl);

    const handleClickOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseCollectionMenu = () => {
        setAnchorEl(null);
    };

    const handleCollectionClick = () => {
        if (editingType) return;
        setUnmountListOnExit(false);
        if (relation !== undefined) {
            updateCollectionRelation({ ...relation, open: !relation.open });
        } else {
            createCollectionRelation({ collection: collection.id, user, open: !open }, { service: "dashboards" });
        }
    };

    const handleDelete = () => {
        deleteCollection({ service: "dashboards" });
        updateDashboards(
            collectionDashboards.map((dashboard: CustomDashboardListObject) => {
                const newDashboard = dashboard;
                newDashboard.dashboardCollection = null;
                return newDashboard;
            })
        );

        setEditingType(undefined);
    };

    const handleNameChange = () => {
        if (!nameInput) return;
        const editedCollection = collection;
        editedCollection.name = nameInput;
        updateCollection(editedCollection, { service: "dashboards" });
        setEditingType(undefined);
        setNameInput(undefined);
    };

    const lengthString = ` ${collectionDashboards.length.toString()}`;

    return (
        <>
            <div onMouseEnter={() => setIsHoveringOver(true)} onMouseLeave={() => setIsHoveringOver(false)}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack
                        direction="row"
                        onClick={handleCollectionClick}
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            cursor: "pointer",
                            minWidth: "100%",
                            "&:hover": { color: (theme) => theme.palette.primary.main },
                        }}
                    >
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                            <Stack justifyContent="center">
                                {isHoveringOver && hasChangePermission ? (
                                    <DragIndicatorIcon fontSize="inherit" sx={{ cursor: "grab" }} />
                                ) : (
                                    <DragIndicatorIcon fontSize="inherit" sx={{ visibility: "hidden" }} />
                                )}
                            </Stack>
                            <IconButton size="small" onClick={handleCollectionClick}>
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            {editingType === "RENAME" ? (
                                <TextField
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    defaultValue={collection.name}
                                    onChange={(e) => {
                                        if (typeof e.target.value === "string") {
                                            setNameInput(e.target.value);
                                        }
                                    }}
                                    sx={(theme) => ({
                                        ".MuiInputBase-input": {
                                            fontWeight: 700,
                                            ...theme.typography.textMd,
                                        },
                                    })}
                                />
                            ) : (
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Typography
                                        data-testid={`${DASHBOARD_COLLECTION_TESTID_PREFIX}${collection.id}`}
                                        variant="textMd"
                                        fontWeight={700}
                                    >
                                        {capitalCase(collection.name)}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                            <Chip size="small" color="primary" label={lengthString} sx={{ fontWeight: 700 }} />
                            {editingType === "RENAME" ? (
                                <>
                                    <IconButton size="small" onClick={handleNameChange}>
                                        <DoneIcon fontSize="inherit" color="success" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setEditingType(undefined);
                                            setNameInput(undefined);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" color="error" />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    {isHoveringOver && hasChangePermission ? (
                                        <IconButton size="small" onClick={handleClickOpenMenu}>
                                            <MoreVertIcon fontSize="inherit" />
                                        </IconButton>
                                    ) : (
                                        <IconButton size="small" sx={{ visibility: "hidden" }}>
                                            <MoreVertIcon fontSize="inherit" />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Stack alignItems="flex-end">
                        <Menu anchorEl={anchorEl} onClose={handleCloseCollectionMenu} open={collectionMenuOpen}>
                            <MenuItem>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    onClick={() => {
                                        setEditingType("RENAME");
                                        handleCloseCollectionMenu();
                                    }}
                                    gap={1}
                                >
                                    <EditIcon fontSize="small" />
                                    <Typography>{fm(messages.renameCollection)}</Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem>
                                <Tooltip title={fm(messages.deleteCollectionExplanation).toString()}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        onClick={() => {
                                            setEditingType("DELETE");
                                            handleCloseCollectionMenu();
                                        }}
                                        gap={1}
                                    >
                                        <DeleteIcon fontSize="small" />
                                        <Typography>{fm(messages.deleteCollection)}</Typography>
                                    </Stack>
                                </Tooltip>
                            </MenuItem>
                        </Menu>
                    </Stack>
                </Stack>
            </div>
            <Divider variant="middle" />
            <Stack>
                <Collapse
                    in={open}
                    addEndListener={() => {
                        if (!open) {
                            setUnmountListOnExit(true);
                        }
                    }}
                >
                    {!unmountListOnExit && (
                        <DashboardList
                            data-testid={`${DASHBOARD_COLLECTION_TESTID_PREFIX}${collection.id}-children`}
                            dashboards={collectionDashboards}
                            dashboardDragId={dashboardDragId}
                            setDashboardDragId={setDashboardDragId}
                        />
                    )}
                </Collapse>
            </Stack>
            <Dialog open={editingType === "DELETE"} onClose={() => setEditingType(undefined)}>
                <DialogTitle variant="textXl">{fm(messages.deleteCollection)}</DialogTitle>
                <DialogContent>
                    <Typography variant="textMd">{fm(messages.deleteCollectionConfirmation)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditingType(undefined)} color="ghostGray">
                        {fm(messages.cancel)}
                    </Button>
                    <Button onClick={() => handleDelete()} color="destructive">
                        {fm(messages.deleteConfirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
