import { DEPARTMENT_GT, SUPPLIER_GT } from "@ignite-analytics/global-types";

import { useTransactionFieldsWithType } from "./hooks";
import messages from "./messages";

import { staticFormatMessage } from "@/contexts/IntlContext";
import pivotAnalysis from "@/hoc/PivotAnalysis";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

export default pivotAnalysis(
    function useInitialQuery() {
        const fields = useTransactionFieldsWithType(SUPPLIER_GT, DEPARTMENT_GT);
        if (fields instanceof Error) return fields;
        const [[supplierField, departmentField], elasticIndex] = fields;

        return {
            rowSplitItems: [],
            columnSplitItems: [
                {
                    field: supplierField.fieldId,
                    type: supplierField.type,
                    labelField: "labelField" in supplierField ? supplierField.labelField : undefined,
                    excludeOthers: true,
                    sortAggIndex: 0,
                    sortOrder: "desc",
                },
            ],
            valueAggregationItems: [
                {
                    field: departmentField.fieldId,
                    labelField: "labelField" in departmentField ? departmentField.labelField : undefined,
                    type: departmentField.type,
                    aggregation: "cardinality",
                },
            ],
            elasticIndex,
        };
    },
    () => {
        const dataSource = useTransactionsIndex();
        if (dataSource === undefined) return new Error(staticFormatMessage(messages.couldntFindTransactionsIndex));
        return dataSource;
    },
    {
        legends: false,
        optionsPerAgg: [
            {
                type: "bar",
                decimals: 0,
                labelDisplayMode: "value",
                fontSize: "10",
            },
        ],
    },
    () => staticFormatMessage(messages.chartTitle),
    {},
    true
);
