import { Filter } from "@ignite-analytics/filters";

const cachedGlobalFilterIndexKey = () => ["globalFilterIndex", localStorage.getItem("tenant")].join("_");

export const getCachedGlobalFilterIndex = () => localStorage.getItem(cachedGlobalFilterIndexKey());

export const setCachedGlobalFilterIndex = (elasticIndex: string) =>
    localStorage.setItem(cachedGlobalFilterIndexKey(), elasticIndex);

export const setFiltersInWindow = (filters: Filter[] | undefined, dataSources: string[] | undefined) => {
    const { tenant } = localStorage;
    if (!window.__dashboardFilters) window.__dashboardFilters = {};
    if (!window.__dashboardFilters[tenant]) window.__dashboardFilters[tenant] = {};
    if (filters !== undefined) {
        window.__dashboardFilters[tenant] = { ...window.__dashboardFilters[tenant], filters: JSON.stringify(filters) };
    }
    if (dataSources !== undefined) {
        window.__dashboardFilters[tenant] = { ...window.__dashboardFilters[tenant], dataSources };
    }
};
export const getFiltersInWindow = (): string | undefined => window.__dashboardFilters?.[localStorage.tenant]?.filters;
export const getDataSourcesInWindow = (): string[] | undefined =>
    window.__dashboardFilters?.[localStorage.tenant]?.dataSources;
