import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteCollection: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.deleteCollection",
        defaultMessage: "Delete Collection",
    },
    renameCollection: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.renameCollection",
        defaultMessage: "Rename Collection",
    },
    deleteCollectionReassurance: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.deleteCollectionReassurance",
        defaultMessage: "Are you sure you want to delete?",
    },
    deleteConfirm: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.deleteConfirm",
        defaultMessage: "Delete",
    },
    cancel: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.Cancel",
        defaultMessage: "Cancel",
    },
    addDashboards: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.addDashboards",
        defaultMessage: "Add dashboards",
    },
    saveName: {
        id: "dashboardOverview.dashboardOverviewPage.dashboardOverviewMenu.DashboardCollectionItem.saveName",
        defaultMessage: "Save name",
    },
    otherExplanation: {
        id: "dashboardOverview.dashboardOverviewPage.otherExplanation",
        defaultMessage: "Dashboards that do not belong to any collection",
    },
    deleteCollectionExplanation: {
        id: "dashboardOverview.dashboardOverviewPage.deleteCollectionExplanation",
        defaultMessage: "Deleting a collection will move all the belonging dashboards to the `Other` collection",
    },
    deleteCollectionConfirmation: {
        id: "dashboardOverview.dashboardOverviewPage.DashboardList.deleteCollectionConfirmation",
        defaultMessage:
            "Are you sure you want to delete this collection? This will move all dashboards in the collection to the 'Others' collection.",
    },
    otherCollection: {
        id: "dashboardOverview.dashboardOverviewPage.otherCollection",
        defaultMessage: "Other",
    },
    renameDashboardError: {
        id: "dashboardOverview.dashboardOverviewPage.renameDashboardError",
        defaultMessage: "Something went wrong when renaming the dashboard",
    },
});

export default messages;
