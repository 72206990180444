import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { arrayHelpers } from "@ignite-analytics/general-tools";
import { CATEGORY_GT } from "@ignite-analytics/global-types";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

import { RecursiveValueOf } from "./interfaces";
import messages from "./messages";

import { CATEGORY_LEVEL, MIN_SPEND_LTM, QUERY_SIZE, TIME_PERIOD, querySizes } from "@/components/Widgets/constants";
import { Options } from "@/components/Widgets/interfaces";
import { fm, staticFormatMessage } from "@/contexts/IntlContext";

export interface AnalysisOptionProps {
    activeOptions: Options;
    name: string;
    onChange?: (value: RecursiveValueOf<Options>) => void;
}

/**
 * Function and field key for category level custom filter
 */

export const CategoryLevel: React.FC<AnalysisOptionProps> = ({ activeOptions, name, onChange }) => {
    const elasticFields = useElasticFieldsInContext();
    const numCategoryLevels =
        elasticFields?.filter(({ field, globalTypeKey }) => field.endsWith("_id") && globalTypeKey === CATEGORY_GT)
            .length ?? 1;
    return (
        <FormControl>
            <InputLabel id="category-level-label">{fm(messages.categoryLevelLabel)}</InputLabel>
            <Select
                labelId="category-level-label"
                label={fm(messages.categoryLevelLabel)}
                defaultValue={activeOptions[CATEGORY_LEVEL] || numCategoryLevels}
                onChange={({ target: { value } }) => onChange && onChange(value)}
                name={name}
            >
                {arrayHelpers.range(numCategoryLevels).map((i) => (
                    <MenuItem key={i} value={i + 1}>
                        {i + 1}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

/**
 * Functions and field keys for number of suppliers
 */

export const SupplierCountOrNumber: React.FC<AnalysisOptionProps> = ({ activeOptions, name, onChange }) => (
    <TextField
        label={fm(messages.numSuppliersLabel)}
        placeholder={staticFormatMessage(messages.placeholderSupNum)}
        defaultValue={activeOptions[name as keyof Options] as number | undefined}
        onChange={({ target: { value } }) => onChange && onChange(Number(value))}
        type="number"
        name={name}
    />
);

/**
 * Functions and field keys for minimum spend
 */

const MinimumSpend = (field: keyof Options, message: string): React.FC<AnalysisOptionProps> =>
    function ({ activeOptions, onChange }) {
        return (
            <TextField
                name={field}
                type="number"
                label={fm(messages[message])}
                onChange={({ target: { value } }) => onChange && onChange(Number(value))}
                defaultValue={activeOptions[field] as number | undefined}
            />
        );
    };

export const MinimumSpendLTM = MinimumSpend(MIN_SPEND_LTM, "minimumSpendLTM");

/**
 * Function and field key for time period in years
 */

export const CagrTimePeriod: React.FC<AnalysisOptionProps> = ({ activeOptions, name, onChange }) => (
    <TextField
        type="number"
        label={fm(messages.cagrYearsLabel)}
        defaultValue={activeOptions[TIME_PERIOD]}
        onChange={({ target: { value } }) => onChange && onChange(Number(value))}
        name={name}
    />
);

/**
 * Render function for querySize filter
 */

export const QuerySize: React.FC<AnalysisOptionProps> = ({ activeOptions, name, onChange }) => (
    <FormControl>
        <InputLabel id="query-size-label">{fm(messages.numElementsLabel)}</InputLabel>
        <Select
            labelId="query-size-label"
            type="number"
            label={fm(messages.numElementsLabel)}
            defaultValue={activeOptions[QUERY_SIZE]}
            onChange={({ target: { value } }) => onChange && onChange(Number(value))}
            name={name}
        >
            {querySizes.map((n) => (
                <MenuItem key={n} value={n}>
                    {n}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
