import { isNotNullish } from "@ignite-analytics/general-tools";
import { Search as SearchIcon } from "@ignite-analytics/icons";
import {
    Paper,
    Stack,
    Card as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { EditDashboardModal } from "../../modals/EditDashboardModal";

import { DashboardRow } from "./DashboardRow";
import messages from "./messages";
import UserFilter from "./UserFilter";

import PermissionContainer from "@/components/PermissionContainer";
import PermissionModal from "@/components/PermissionModal";
import { fm } from "@/contexts/IntlContext";
import {
    CustomDashboardListObject,
    useAllCustomDashboards,
    useUpdateMultipleCustomDashboards,
} from "@/entities/dashboards";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { BACKEND_MODELS, ModelGuard } from "@/lib/permissions";

export const DashboardOverviewSettingsPage: React.FC = () => {
    const allDashboards = useAllCustomDashboards(undefined, {
        service: "dashboards",
    });
    const [dashboardForModal, setDashboardForModal] = useState<CustomDashboardListObject | undefined>(undefined);
    const [openModal, setOpenModal] = useState<"EDIT" | "SHARING" | false>(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterByUsers, setFilterByUsers] = useState<string[]>([]);
    const updateDashboards = useUpdateMultipleCustomDashboards();

    type TableColumn = {
        width: string;
        label: string;
        intoCompare?: (d: CustomDashboardListObject) => string | number;
    };
    const columns: TableColumn[] = [
        { width: "60%", label: fm(messages.columnTitle).toString(), intoCompare: (d) => d.name },
        { width: "20%", label: fm(messages.columnLastUpdated).toString(), intoCompare: (d) => Date.parse(d.updatedAt) },
        {
            width: "20%",
            label: fm(messages.columnCollection).toString(),
            intoCompare: (d) => d.dashboardCollection || 0,
        },
        { width: "20%", label: fm(messages.columnOwner).toString(), intoCompare: (d) => d.owner || "" },
    ];
    const [sort, setSort] = useState<TableColumn & { direction: "asc" | "desc" }>({ ...columns[1], direction: "desc" });
    const [page, setPage] = useState({ number: 0, rowsPerPage: 10 });

    const userIds = useMemo(() => allDashboards.map((d) => d.owner).filter(isNotNullish), [allDashboards]);

    const matchingDashboards = useMemo(() => {
        let dashboards = allDashboards;
        if (searchTerm !== "") {
            dashboards = dashboards.filter((d) => d.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
        }
        if (filterByUsers.length > 0) {
            dashboards = dashboards.filter((d) => filterByUsers.includes(d.owner));
        }
        dashboards.sort((a, b) => {
            if (!sort.intoCompare) {
                return 0;
            }
            const va = sort.intoCompare(a);
            const vb = sort.intoCompare(b);
            if (va < vb) {
                return sort.direction === "asc" ? -1 : 1;
            }
            if (va > vb) {
                return sort.direction === "asc" ? 1 : -1;
            }
            return 0;
        });
        // we mutate the array here, need to return a new object to trigger rerender
        return dashboards.slice(0);
    }, [allDashboards, searchTerm, sort, filterByUsers]);

    const displayedDashboards = useMemo(() => {
        return matchingDashboards.slice(page.number * page.rowsPerPage, (1 + page.number) * page.rowsPerPage);
    }, [matchingDashboards, page]);

    function sortTable(f: TableColumn) {
        if (f.intoCompare == null) return;
        if (sort.label === f.label) {
            setSort({ ...sort, direction: sort.direction === "asc" ? "desc" : "asc" });
        } else {
            setSort({ ...sort, ...f });
        }
    }

    useSetBreadcrumbs(fm(messages.dashboardOverviewHeader).toString());

    const handlePermissionGuardUpdate = useCallback(
        async (g: ModelGuard) => {
            if (!dashboardForModal) return;
            setDashboardForModal((prev) => prev && { ...prev, guard: g.id });
            await updateDashboards([{ ...dashboardForModal, guard: g.id }]);
        },
        [dashboardForModal, updateDashboards]
    );

    return (
        <Stack m={2} p={2} spacing={2} elevation={2} component={Paper}>
            {dashboardForModal && (
                <PermissionContainer
                    requiredPermissionTypes={["change"]}
                    equivalentUserPermission={{
                        namespace: "dashboards",
                        relation: { object: "general", relation: "write" },
                    }}
                >
                    <PermissionModal
                        name={dashboardForModal.name}
                        model={BACKEND_MODELS.CUSTOM_DASHBOARDS}
                        open={openModal === "SHARING"}
                        objectId={dashboardForModal.id}
                        guard={dashboardForModal.guard}
                        childModels={dashboardForModal.childModels || []}
                        onClose={() => setOpenModal("EDIT")}
                        objectOwners={[dashboardForModal.owner]}
                        onUpdate={handlePermissionGuardUpdate}
                    />
                    <EditDashboardModal
                        onManageAccessClick={() => {
                            setOpenModal("SHARING");
                        }}
                        dashboard={dashboardForModal}
                        open={openModal === "EDIT"}
                        setOpen={setOpenModal}
                        setDashboard={setDashboardForModal}
                    />
                </PermissionContainer>
            )}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <TextField
                    data-testid="list-search-field"
                    label={fm(messages.searchDashboardLabel)}
                    size="small"
                    value={searchTerm}
                    onChange={(e) => {
                        setPage((prev) => ({ ...prev, number: 0 }));
                        setSearchTerm(e.target.value);
                    }}
                    sx={{ width: "300px" }}
                    InputProps={{
                        endAdornment: <SearchIcon fontSize="small" />,
                    }}
                />

                <UserFilter
                    userIds={userIds}
                    onChange={(values) => {
                        setPage({ ...page, number: 0 });
                        setFilterByUsers(values.map((u) => u.id));
                    }}
                />
            </Stack>

            <TableContainer
                sx={{
                    "& .MuiTableBody-root": {
                        cursor: "pointer",
                    },
                    "thead > * > *": {
                        borderRight: "none",
                        borderLeft: "none",
                    },
                    "tbody > *:not(:last-child) > *": {
                        border: "none",
                    },
                    "tbody > *:last-child > *": {
                        borderRight: "none",
                        borderLeft: "none",
                    },
                    alignItems: "stretch",
                    position: "relative",
                    pb: 4,
                }}
            >
                <Table
                    sx={{
                        tableLayout: "fixed",
                        width: "100%",
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell
                                    sx={{ minWidth: col.width, width: col.width }}
                                    key={col.label}
                                    component="div"
                                >
                                    {col.intoCompare == null ? (
                                        <Typography variant="subtitle2">{col.label}</Typography>
                                    ) : (
                                        <TableSortLabel
                                            direction={sort.direction}
                                            active={sort.label === col.label}
                                            onClick={() => sortTable(col)}
                                        >
                                            <Typography variant="subtitle2">{col.label}</Typography>
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedDashboards.map((dashboard) => {
                            return (
                                <DashboardRow
                                    key={dashboard.id}
                                    dashboard={dashboard}
                                    onRowClick={() => {
                                        setDashboardForModal(dashboard);
                                        setOpenModal("EDIT");
                                    }}
                                />
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={matchingDashboards.length}
                            rowsPerPage={page.rowsPerPage}
                            page={page.number}
                            onPageChange={(_, pageNumber) => {
                                setPage({ ...page, number: pageNumber });
                            }}
                            onRowsPerPageChange={(e) => {
                                setPage({ ...page, rowsPerPage: parseInt(e.target.value, 10) });
                            }}
                        />
                    </TableFooter>
                </Table>
            </TableContainer>
        </Stack>
    );
};
