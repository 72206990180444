import React, { useContext } from "react";

const Context = React.createContext<string | null>(null);

type Props = {
    path: string;
    children: React.ReactNode;
};
const BasePathProvider: React.FC<Props> = ({ path, children }) => {
    return <Context.Provider value={path}>{children}</Context.Provider>;
};

function useBasePath(): string {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useBasePath hook must be used within a BasePathProvider");
    }
    return context;
}

function useBasePathPrefix(): "" | "/v2" {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useBasePath hook must be used within a BasePathProvider");
    }
    return context.startsWith("/v2") ? "/v2" : "";
}

export { BasePathProvider, useBasePath, useBasePathPrefix };
