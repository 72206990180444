import { usePromiseState, WAITING } from "@ignite-analytics/api-client";
import { isDateField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { useLastNonNullishValue } from "@ignite-analytics/general-tools";
import { TRANSACTION_DATE_GT, TRANSACTIONS_GT } from "@ignite-analytics/global-types";
import { ChartData } from "@ignite-analytics/pivot-charts";
import React, { useCallback, useEffect } from "react";

import { WidgetError } from "../Components/WidgetError";
import { WidgetLoader } from "../Components/WidgetLoader";
import { getErrorsFromPromise } from "../helpers";
import { DeprecatedWidget, WidgetProps } from "../interfaces";

import { getPlotLines, getSpendDevelopment } from "./helpers";
import { useChartConfig } from "./hooks";

import Chart from "@/components/Charts/Chart";
import { useExportDashboardContextOrThrow } from "@/contexts/exportDashboardContext";
import { widgetWrapper } from "@/hoc/widgetWrapper";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useExportWidget } from "@/hooks/useExport";
import { formatEpochTimestampToDateString } from "@/lib/dateHelpers";

/**
 *  CONTAINER - SpendDevelopmentWidget
 * ================================================
 *
 * Container for widget with rolling spend development
 *
 */

const SpendDevelopmentWidget: React.FC<WidgetProps<DeprecatedWidget, object>> = ({ activeWidget, widget }) => {
    const [dataPromise, setDataPromise] = usePromiseState<ChartData>();

    const [exportReady, setExportReady] = React.useState(false);
    const customExportRef = React.useRef<Highcharts.Chart | HTMLElement>();
    const { updateExportFuncForWidget } = useExportDashboardContextOrThrow();

    const filters = useFilters();
    const transactionIndex = useTransactionsIndex()?.name;
    const elasticFields = useElasticFields(transactionIndex);
    const dateField = elasticFields?.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT);
    const maxRelativeDate =
        dateField && isDateField(dateField) && dateField.maxDateForRelativeFilters?.value
            ? formatEpochTimestampToDateString(dateField.maxDateForRelativeFilters.value)
            : undefined;

    useEffect(() => {
        if (filters !== undefined) {
            setDataPromise(
                getSpendDevelopment(filters || [], maxRelativeDate, `spendDevelopment widget-${activeWidget.id}`)
            );
        }
    }, [activeWidget, filters, maxRelativeDate, setDataPromise]);
    const loading = dataPromise[WAITING];
    const data = useLastNonNullishValue(dataPromise.data);
    const { permissionDeniedMessage, userHasViewAccess, errors } = getErrorsFromPromise(dataPromise);

    const plotLines: Highcharts.XAxisPlotLinesOptions[] = React.useMemo(() => {
        if (!data) return [];
        const series = data.dataSeries[0];
        if (!series || !("plotLineDates" in series)) return [];
        return getPlotLines(series.plotLineDates, -0.5);
    }, [data]);

    const chartConfig = useChartConfig(plotLines);

    const exportFunc = useExportWidget(
        activeWidget.title,
        activeWidget.elasticIndex,
        undefined,
        undefined,
        customExportRef
    );
    useEffect(
        function propagateExportFunc() {
            if (!activeWidget.id || !customExportRef.current) return;
            updateExportFuncForWidget(exportFunc, activeWidget.id.toString());
        },
        [activeWidget.id, activeWidget.title, exportFunc, updateExportFuncForWidget, exportReady]
    );

    const handleOnRef = useCallback((el: Highcharts.Chart | HTMLElement) => {
        customExportRef.current = el;
        setExportReady(true);
    }, []);

    if (errors?.length && errors[0] !== "Cancel")
        return (
            <WidgetError
                onUpdateData={() =>
                    setDataPromise(
                        getSpendDevelopment(
                            filters || [],
                            maxRelativeDate,
                            `spendDevelopment widget-${activeWidget.id}`
                        )
                    )
                }
                activeWidget={activeWidget}
                props={{ permissionDeniedMessage, userHasViewAccess, errors }}
            />
        );

    return (
        <>
            {loading && <WidgetLoader />}
            {data && (
                <Chart
                    onRef={handleOnRef}
                    hideTitle
                    data={data}
                    title={widget.title}
                    config={chartConfig}
                    onDrilldown={undefined}
                    onSaveDrilldown={undefined}
                />
            )}
        </>
    );
};

export default widgetWrapper(SpendDevelopmentWidget, TRANSACTIONS_GT);
