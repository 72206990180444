import { defineMessages } from "react-intl";

const messages = defineMessages({
    week: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionWeek",
        defaultMessage: "Week",
    },
    othersLabel: {
        id: "customAnalysis.pivotTable.othersLabel",
        defaultMessage: "Others",
    },
    totalLabel: {
        id: "customAnalysis.pivotTable.totalLabel",
        defaultMessage: "Total",
    },
    tooManyRowSplitsError: {
        id: "hoc.pivotAnalysis.tooManyRowSplitsError",
        defaultMessage:
            "Too many row splits. In order to provide quick analyses, we only support up to 6 splits in each direction",
    },
    tooManyColumnSplitsError: {
        id: "hoc.pivotAnalysis..tooManyColumnSplitsError",
        defaultMessage:
            "Too many column splits. In order to provide quick analyses, we only support up to 6 splits in each direction",
    },
});

export default messages;
