import { defineMessages } from "react-intl";

const messages = defineMessages({
    createEmptyDashboard: {
        id: "HomeCard.GetStartedCard.createEmptyDashboard",
        defaultMessage: "Create empty dashboard",
    },
    createEmptyDashboardDescription: {
        id: "HomeCard.GetStartedCard.createEmptyDashboardDescription",
        defaultMessage: "Create an empty dashboard and fill it with analyses of your choice",
    },
    dashboardFromLibrary: {
        id: "HomeCard.GetStartedCard.dashboardFromLibrary",
        defaultMessage: "Use Ignite's dashboard library",
    },
    dashboardFromLibraryDescription: {
        id: "HomeCard.GetStartedCard.dashboardFromLibraryDescription",
        defaultMessage: "Create a dashboard by importing from Ignite's library of best practice dashboard",
    },
    customAnalysis: {
        id: "HomeCard.GetStartedCard.customAnalysis",
        defaultMessage: "Custom analysis",
    },
    customAnalysisDescription: {
        id: "HomeCard.GetStartedCard.customAnalysisDescription",
        defaultMessage: "Explore your data in detail using Ignite's own analysis engine",
    },
    uploadDataHeader: {
        id: "HomeCard.GetStartedCard.uploadDataHeader",
        defaultMessage: "Upload data",
    },
    uploadDataDescription: {
        id: "HomeCard.GetStartedCard.uploadDataDescription",
        defaultMessage: "Upload your spend, supplier or contract data to Ignite",
    },
    getStarted: {
        id: "HomeCard.GetStartedCard.getStarted",
        defaultMessage: "Welcome! What would you like to get started with?",
    },
});

export default messages;
