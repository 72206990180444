import { ArrowRight, ExclamationTriangle as WarningIcon } from "@ignite-analytics/icons";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { staticFormatMessage } from "../../../contexts/IntlContext";
import { RotateDiv } from "../LargestFieldWidget/style";

import messages from "./messages";

import { toPercent } from "@/lib/convert";

/**
 * Returns Trend-arrow for widget-table
 * @param trend {number}: trend-factor
 * @param percentageLimit {number}: percentage limit for arrow (Up/-Down)
 * @return {Element<Tooltip>}
 */
export const trendArrow = (
    trend: number,
    percentageLimit = 0.3,
    period: string | undefined = undefined,
    tooltipContent: string | undefined = undefined
): JSX.Element => {
    const colorSwitchLimit: number = percentageLimit / 3;
    const factor: number = -90 / percentageLimit;
    let degrees: number = trend * factor;

    degrees = degrees < -90 ? -90 : degrees;
    degrees = degrees > 90 ? 90 : degrees;

    let arrowColor: "success" | "warning" | "disabled" = "success"; // todo: check if disabled is correct color
    arrowColor = trend < colorSwitchLimit ? "disabled" : arrowColor;
    arrowColor = trend < -colorSwitchLimit ? "warning" : arrowColor;

    const isInfinity = trend === Infinity;
    const trendFormatted: number | string = isInfinity ? staticFormatMessage(messages.trendArrowInfinity) : trend;
    const tooltipTitle = period
        ? staticFormatMessage(messages.trendArrowTooltip, { period })
        : toPercent(trendFormatted);

    return (
        <Tooltip title={tooltipContent ?? tooltipTitle}>
            {!isInfinity ? (
                <RotateDiv degrees={degrees}>
                    <ArrowRight color={arrowColor} />
                </RotateDiv>
            ) : (
                <WarningIcon />
            )}
        </Tooltip>
    );
};

export const trendTag = (trend: number, percentageLimit = 0.3, period: string | undefined = undefined): JSX.Element => {
    return (
        <Box p={1} sx={{ borderRadius: (theme) => theme.shape.borderRadius }}>
            <Stack direction="row" alignItems="center">
                <Typography variant="body2" px={{ paddingRight: "5px" }}>
                    <b>{toPercent(trend)}</b>
                </Typography>
                {trendArrow(trend, percentageLimit, period)}
            </Stack>
        </Box>
    );
};
