import { Filter } from "@ignite-analytics/filters";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AnalysisConfiguration, AnalysisQuery, DateInterval } from "@ignite-analytics/pivot-ts";

import { allWidgetsOrdered } from ".";

import {
    ANALYSIS_WIDGET_MODEL,
    CATEGORY_LEVEL,
    CHART_VIEW,
    CUSTOM_PERIOD,
    DEPRECATED_WIDGET_MODEL,
    DISPLAY_COMPARISION,
    INTERVAL,
    LARGEST_FIELD_WIDGET_MODEL,
    MIN_DIFF,
    MIN_SPEND_LTM,
    PERCENTAGE_LIMIT,
    QUERY_SIZE,
    SELECTED_FIELD,
    SELECTED_FILTER_FIELD,
    SPEND_LIMIT,
    STATE_FIELD,
    SUPPLIER_COUNT,
    SUPPLIER_NUMBER,
    TABLE_VIEW,
    TEXT_ALIGNMENT,
    TIME_PERIOD,
    VIEW_MODE,
} from "@/components/Widgets/constants";
import { Alignment } from "@/components/Widgets/TextWidget";
import { ExportableDomElements } from "@/hooks/useExport/interfaces";

/**
 * Interface used both for widget config and for the options that user can choose in
 * the chart contiainer header (AnalysisOptions component).
 */
export interface Options {
    [CATEGORY_LEVEL]?: number;
    [MIN_SPEND_LTM]?: number;
    [TIME_PERIOD]?: number;
    [QUERY_SIZE]?: number;
    [CUSTOM_PERIOD]?: string;
    [SUPPLIER_COUNT]?: number;
    [SUPPLIER_NUMBER]?: number;
    [SPEND_LIMIT]?: number;
    [MIN_DIFF]?: number;
    [PERCENTAGE_LIMIT]?: number;
    [SELECTED_FIELD]?: string;
    [SELECTED_FILTER_FIELD]?: string;
    [VIEW_MODE]?: "table" | "chart";
    [QUERY_SIZE]?: number;
    [DISPLAY_COMPARISION]?: boolean;
    [INTERVAL]?: DateInterval;

    // Text widget
    [STATE_FIELD]?: string;
    [TEXT_ALIGNMENT]?: Alignment;

    query?: AnalysisQuery;
    chartConfig?: ChartConfig;
    type?: string;
}

export interface WidgetPreHOCProps<W extends Widget> {
    widget: W;
    dashboardId: number;
    index: number;
    disableDrag?: boolean;
    title: string;
    initialFilters: Filter[];
    hideButtons?: boolean;

    onDelete: () => void;
    onUpdate: (widget?: W) => void; // Changes the widget object in state (from API if called without argument)
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface WidgetProps<W extends Widget, D extends object> extends WidgetPreHOCProps<W> {
    activeWidget: W;
    data: null | D; // Data for the widget (from the server)
    elasticIndex?: string;
    visible?: boolean;

    updateActiveWidget: (config: W) => void; // Updates activeConfig, but not widget.config

    widgetRef: React.RefObject<ExportableDomElements>;
}

/**
 * Arguments for the data fetching function for widgets.
 * Accepts a generic string type argument that can be used to set required keys in the config object.
 */

type AvailableWidgetTypes = keyof ReturnType<typeof allWidgetsOrdered>;
export type AvailableWidget = ReturnType<typeof allWidgetsOrdered>[AvailableWidgetTypes];

/**
 * Shared base for all widget models
 */
interface WidgetBase {
    id?: number;
    staticId?: number;
    filters: Filter[];
    customDashboard: number;
    title: string;
    isNew?: boolean; // Used for copying in new widgets in dashboards. If true, the component will call getData when mounted

    // Indicator if a widget should open its configuration modal when it is created
    openConfig?: boolean;
    width?: number;
    height?: number;
    xPosition?: number;
    yPosition?: number;
    description?: string;
}

/**
 * Interface for widgets that don't have their own model yet, so they have a `config` JSON object instead
 */
export interface DeprecatedWidget extends WidgetBase {
    type:
        | "CONTRACT_LOYALTY"
        | "CONTRACTS"
        | "FIELD_HISTORY"
        | "NOTIFICATIONS"
        | "POTENTIAL"
        | "RISK"
        | "SPEND_DEVELOPMENT"
        | "TEXT_WIDGET";
    modelName: typeof DEPRECATED_WIDGET_MODEL;
    config: Options;
    elasticIndex: string;
}

export const isDeprecatedWidget = (widget: Widget): widget is DeprecatedWidget =>
    widget.modelName === DEPRECATED_WIDGET_MODEL;

/**
 * Interface for widgets created in the Custom Analysis module
 */

export interface AnalysisWidget extends AnalysisConfiguration, WidgetBase {
    type: "ANALYSIS_NEW" | "FIELD_DISTRIBUTION_NEW";
    modelName: typeof ANALYSIS_WIDGET_MODEL;
    chartConfiguration: ChartConfig;
    viewState: typeof TABLE_VIEW | typeof CHART_VIEW;
    redirectToAnalysis?: boolean;
}

export const isAnalysisWidget = (widget: Widget): widget is AnalysisWidget =>
    widget.modelName === ANALYSIS_WIDGET_MODEL;

/**
 * Interface for LARGEST_FIELD widget
 */
export interface LargestFieldWidget
    extends Omit<AnalysisConfiguration, "valueConfigurations" | "rowSplitItems">,
        WidgetBase {
    type: "LARGEST_FIELD_NEW";
    modelName: typeof LARGEST_FIELD_WIDGET_MODEL;
    viewState: typeof TABLE_VIEW | typeof CHART_VIEW;
    percentageLimit: number;
    chartConfiguration: ChartConfig;
    period: DateInterval;
}

/**
 * Union type for all widgets
 */
export type Widget = DeprecatedWidget | AnalysisWidget | LargestFieldWidget;
