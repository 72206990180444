import React from "react";
import { Cell, Row } from "react-table";

import { SELECTION_COLUMN_ACCESSOR } from "./constants";
import ProgressCell from "./ProgressCell";
import SelectCell from "./SelectCell";
import TextCell from "./TextCell";
import TrendCell from "./TrendCell";

import { TableCell } from "@/containers/PrioritizeModule/RiskAndPotentialPage/RatingListPage/PotentialAndRiskTable/DataTableCell/interfaces";

interface Props {
    cell: Cell<Record<string, TableCell>>;
    row: Row<Record<string, TableCell>>;
    isSelected?: boolean;
    onRowSelectionChange?: (key: string, selected: boolean) => void;
}
const DataTableCell: React.FC<Props> = ({ cell, row, onRowSelectionChange, isSelected }) => {
    if (cell.value !== undefined && cell.column.visual !== undefined) {
        switch (cell.column.visual.type) {
            case "progress":
                return (
                    <ProgressCell
                        color={cell.value.color}
                        progress={cell.value.percent * 100}
                        showProgressBar={cell.column.visual.showVisual}
                        value={cell.value.value}
                        showValue={cell.column.visual.showValue}
                        align={cell.column.align || "left"}
                    />
                );
            case "trend":
                return (
                    <TrendCell
                        progress={cell.value.percent}
                        showTrendArrow={cell.column.visual.showVisual}
                        value={cell.value.value}
                        showValue={cell.column.visual.showValue}
                        align={cell.column.align || "left"}
                    />
                );
            default:
                break;
        }
    }
    if (cell.column.id === SELECTION_COLUMN_ACCESSOR) {
        return (
            <SelectCell
                isSelected={isSelected || false}
                onSelectionChange={(selected) =>
                    onRowSelectionChange &&
                    typeof row.original._key === "string" &&
                    onRowSelectionChange(row.original._key, selected)
                }
            />
        );
    }
    return <TextCell cell={cell} />;
};

export default DataTableCell;
