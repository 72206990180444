import { ErrorBoundary } from "@sentry/react";
import React from "react";

import WidgetFallback from "../Fallbacks/WidgetErrorFallback";

import { Widget } from "@/components/Widgets/interfaces";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 *
 */
interface WidgetErrorProps {
    widget: Widget;
    children: React.ReactNode;
}
const WidgetErrorBoundary: React.FC<WidgetErrorProps> = ({ children, widget }) => {
    return (
        <ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("widgetId", widget.id);
                scope.setTag("widgetType", widget.type);
                scope.setTag("app", "dashboards-app");
            }}
            fallback={({ resetError }) => <WidgetFallback resetError={resetError} />}
        >
            {children}
        </ErrorBoundary>
    );
};

export default WidgetErrorBoundary;
