import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import {
    AnalysisQuery,
    createFiltersFromRowsOrColumns,
    PivotColumnData,
    PivotResponse,
    PivotRow,
    useInsightModalContext,
} from "@ignite-analytics/pivot-ts";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isNumber } from "lodash-es";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { HorizontalSortIcon } from "../HorizontalSortIcon";

import { getRegularColumns } from "./columnGenerators/regular";
import { getTopLeftColumns } from "./columnGenerators/split";
import { AggregationValueHeader } from "./components/AggregationValueHeader";
import { EMPTY_CELL_VALUE } from "./helpers/constants";
import { getAllRows } from "./helpers/rows";
import { Cell as InternalCell, PivotTableRow } from "./interfaces";
import messages from "./messages";
import { AggHeader, ResizeDiv, ScrollableDiv, Table, TBody, Td, Th, THead, Tr } from "./style";

import { SplitChangeSpec } from "@/components/PivotTable/interfaces";
import { fm } from "@/contexts/IntlContext";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";

interface Props {
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onRef: (el: HTMLTableElement) => void;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const InnerTable: React.FC<Props> = ({
    data,
    query,
    config,
    editable,
    onSplitChange,
    onHeaderChange,
    onRef,
}) => {
    let lastColumnSplitPlaceholderColumnId: string | undefined;
    const { setModalState } = useInsightModalContext();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const labelledQuery = useLabelledAnalysisQuery(query);
    const elasticFields = useElasticFieldsInContext();
    const filters = useFilters();

    const noData = !data || !data.rows.length || (labelledQuery?.columnSplitItems.length && !data.columns.length);

    const allRows = useMemo(
        () => (labelledQuery && !noData ? getAllRows(data, labelledQuery, config) : []),
        [data, config, labelledQuery, noData]
    );

    const sizeBeforeScrollbar = tableContainerRef.current?.offsetWidth ? tableContainerRef.current?.offsetWidth : 0;
    const numRows = useMemo(() => (allRows.length > 0 ? Object.keys(allRows[0]).length : 0), [allRows]);
    const paddingHeader = 15;
    const paddingCell = 5;
    const minWidth = 150;

    const width = useMemo(() => {
        return sizeBeforeScrollbar && numRows
            ? (sizeBeforeScrollbar - (paddingHeader + paddingCell) * 2 * numRows) / numRows > minWidth
                ? (sizeBeforeScrollbar - (paddingHeader + paddingCell) * 2 * numRows) / numRows
                : minWidth
            : minWidth;
    }, [sizeBeforeScrollbar, numRows]);

    const columns = useMemo<ColumnDef<PivotTableRow>[]>(
        () =>
            labelledQuery && !noData
                ? [
                      ...getTopLeftColumns(labelledQuery, 0, config, width),
                      ...getRegularColumns(labelledQuery, data.columns, intl, config, width),
                  ]
                : [],
        [config, data.columns, intl, labelledQuery, noData, width]
    );

    const table = useReactTable({
        data: allRows,
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    });

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        count: rows.length,
        estimateSize: () => 35,
        overscan: 1000,
    });
    const totalRowSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const handleCellClick = useCallback(
        (
            { pageX, pageY, timeStamp, currentTarget }: React.MouseEvent,
            columnPath?: PivotColumnData[],
            rowPath?: PivotRow[]
        ) => {
            if (currentTarget instanceof HTMLElement) {
                const columnFilters = columnPath
                    ? createFiltersFromRowsOrColumns(columnPath, elasticFields ?? [], filters)
                    : undefined;
                const rowFilters = rowPath
                    ? createFiltersFromRowsOrColumns(rowPath, elasticFields ?? [], filters)
                    : undefined;
                setModalState({
                    top: pageX,
                    left: pageY,
                    eventStamp: timeStamp,
                    target: currentTarget,
                    ...(columnFilters ? { selectedColumns: columnFilters } : {}),
                    ...(rowFilters ? { selectedRows: rowFilters } : {}),
                    positionWithTarget: true,
                });
            }
        },
        [elasticFields, filters, setModalState]
    );

    if (noData) {
        return (
            <Stack textAlign="center" pt={2} pl={6}>
                <Typography variant="subtitle2">{fm(messages.noData)}</Typography>
            </Stack>
        );
    }
    const stickyHeaders = !config.disableStickyHeaders;
    return (
        <ScrollableDiv ref={tableContainerRef}>
            <Table fillAvailable={config.fillAvailableSpace} ref={(el) => el && onRef(el)}>
                <THead sticky={stickyHeaders}>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Tr key={headerGroup.id} headerRow>
                                {headerGroup.headers.map((header) => {
                                    // Logic for skipping placeholder when having column splits and no row splits
                                    if (header.column.id === lastColumnSplitPlaceholderColumnId) return;
                                    if (header.isPlaceholder) {
                                        lastColumnSplitPlaceholderColumnId = header.column.id;
                                    }

                                    const {
                                        splitItem,
                                        splitItemIndex,
                                        splitType,
                                        rowSpan,
                                        headerType,
                                        columnPath,
                                        defaultHeaderName,
                                        valueAggregationIndex,
                                        isHiddenTotalRow,
                                    } = header.column.columnDef.meta ?? {};

                                    if (isHiddenTotalRow) return;

                                    const isClickable = !splitItem?.sortOrder;

                                    if (headerType === "normal") {
                                        return (
                                            <Th
                                                alignment={config.columnAlignment}
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                rowSpan={rowSpan ?? 1}
                                                style={{ width: header.getSize() }}
                                                wrapLabels={config.wrapHeaderLabels}
                                                onClick={(event: React.MouseEvent) =>
                                                    isClickable && handleCellClick(event, columnPath)
                                                }
                                                clickable={isClickable}
                                                horizontalPadding={paddingHeader}
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent={config.columnAlignment ?? "center"}
                                                    width={header.getSize()}
                                                >
                                                    <Typography
                                                        width={header.getSize()}
                                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                    >
                                                        {header.isPlaceholder && !query.columnSplitItems.length
                                                            ? null
                                                            : flexRender(
                                                                  header.column.columnDef.header,
                                                                  header.getContext()
                                                              )}
                                                    </Typography>
                                                </Stack>
                                                {splitItem?.sortOrder &&
                                                    isNumber(splitItemIndex) &&
                                                    splitType &&
                                                    labelledQuery && (
                                                        <HorizontalSortIcon
                                                            labelledQuery={labelledQuery}
                                                            intl={intl}
                                                            splitItem={splitItem}
                                                            config={config}
                                                            splitItemIndex={splitItemIndex}
                                                            splitType={splitType}
                                                            onSplitChange={onSplitChange}
                                                            columnAlignment={config.columnAlignment}
                                                            header={header}
                                                        />
                                                    )}
                                                <ResizeDiv
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    onMouseDown={header.getResizeHandler()}
                                                    onTouchStart={header.getResizeHandler()}
                                                />
                                            </Th>
                                        );
                                    }
                                    const headerName =
                                        flexRender(header.column.columnDef.header, header.getContext())?.toString() ??
                                        "";

                                    return (
                                        <AggHeader
                                            key={header.id}
                                            alignment={config.columnAlignment}
                                            colSpan={header.colSpan}
                                            style={{ width: header.getSize() }}
                                            wrapLabels={config.wrapHeaderLabels}
                                            horizontalPadding={paddingHeader}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <AggregationValueHeader
                                                    headerName={headerName}
                                                    editable={editable && !header.isPlaceholder}
                                                    onHeaderChange={onHeaderChange}
                                                    valueAggregationIndex={valueAggregationIndex ?? NaN}
                                                    defaultHeaderName={defaultHeaderName ?? ""}
                                                    header={header}
                                                />
                                            )}
                                            <ResizeDiv
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                            />
                                        </AggHeader>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </THead>
                <TBody>
                    {paddingTop > 0 && (
                        <tr>
                            <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                    )}
                    {virtualRows.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().reduce((acc: JSX.Element[], cell) => {
                                    const val = cell.getValue<InternalCell>();
                                    const isClickable = val?.value !== EMPTY_CELL_VALUE && val?.value !== "Total";
                                    if (val?.value === null || val?.value === undefined) {
                                        return acc;
                                    }
                                    if (val?.isHeader) {
                                        return [
                                            ...acc,
                                            <Th
                                                key={`${cell.id}`}
                                                rowSpan={val.rowSpan}
                                                colSpan={val.colSpan}
                                                alignment={config.columnAlignment ?? "center"}
                                                clickable={isClickable}
                                                wrapLabels={config.wrapHeaderLabels}
                                                onClick={(event: React.MouseEvent) =>
                                                    isClickable && handleCellClick(event, undefined, val.rowPath)
                                                }
                                                style={{
                                                    width: cell.column.getSize(),
                                                }}
                                                horizontalPadding={paddingHeader}
                                            >
                                                <Typography
                                                    width={cell.column.getSize()}
                                                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    {val.value.toString()}
                                                </Typography>
                                            </Th>,
                                        ];
                                    }
                                    return [
                                        ...acc,
                                        <Td
                                            key={`${cell.id}`}
                                            alignment={config.columnAlignment ?? "center"}
                                            clickable={isClickable}
                                            onClick={(event: React.MouseEvent) =>
                                                isClickable && handleCellClick(event, val.columnPath, val.rowPath)
                                            }
                                            style={{
                                                width: cell.column.getSize(),
                                            }}
                                            horizontalPadding={paddingCell}
                                        >
                                            <Typography
                                                width={cell.column.getSize()}
                                                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                            >
                                                {val?.value?.toString()}
                                            </Typography>
                                        </Td>,
                                    ];
                                }, [])}
                            </Tr>
                        );
                    })}
                    {paddingBottom > 0 && (
                        <tr>
                            <td style={{ height: `${paddingBottom}px` }} />
                        </tr>
                    )}
                </TBody>
            </Table>
        </ScrollableDiv>
    );
};
